import React, { FC, HTMLAttributes } from 'react'
import tw, { css } from 'twin.macro'

export type TextElement =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'span'
  | 'label'
export type TextPreset =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'p1'
  | 'p2'
  | 'caption'
  | 'tag'

export interface TextProps
  extends HTMLAttributes<
    HTMLHeadingElement &
      HTMLParagraphElement &
      HTMLSpanElement &
      HTMLLabelElement
  > {
  as?: TextElement
  preset?: TextPreset
  align?: 'left' | 'center' | 'right' | 'justify'
  weight?: 'light' | 'normal' | 'medium' | 'bold'
  transform?: 'uppercase' | 'lowercase' | 'capitalize'
  decoration?: 'underline' | 'line-through'
  htmlFor?: string
}

const ALIGNMENT = {
  left: tw`text-left`,
  center: tw`text-center`,
  right: tw`text-right`,
  justify: tw`text-justify`,
}

const WEIGHTS = {
  light: tw`font-light`,
  normal: tw`font-normal`,
  medium: tw`font-medium`,
  bold: tw`font-bold`,
}

const TRANSFORMATIONS = {
  uppercase: tw`uppercase`,
  lowercase: tw`lowercase`,
  capitalize: tw`capitalize`,
}

const DECORATIONS = {
  underline: tw`underline`,
  'line-through': tw`line-through`,
}

const PRESETS = {
  h1: `
    font-weight: 300;
    font-style: normal;
    font-size: 3rem;
    line-height: 1.3;
  `,
  h2: `
    font-weight: 300;
    font-style: normal;
    font-size: 2.125rem;
    line-height: 1.2;
  `,
  h3: `
    font-weight: 300;
    font-style: normal;
    font-size: 1.75rem;
    line-height: 1.3;
  `,
  h4: `
    font-weight: 500;
    font-style: normal;
    font-size: 1rem;
    line-height: 1.4;
  `,
  h5: `
    font-weight: 400;
    font-style: normal;
    font-size: 1rem;
    line-height: 1.4;
  `,
  p1: `
    font-weight: 400;
    font-style: normal;
    font-size: 1rem;
    line-height: 1.375;
  `,
  p2: `
    font-weight: 400;
    font-size: 0.75rem;
    font-style: normal;
    line-height: 1.5;
  `,
  caption: `
    font-weight: 500;
    font-size: 0.75rem;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 0.07rem;
    text-transform: uppercase;
  `,
  tag: `
    font-weight: 500;
    font-size: 0.75rem;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 0.07rem;
    text-transform: uppercase;
  `,
}

const Text: FC<TextProps> = ({
  as: Tag = 'p',
  preset = 'p1',
  align,
  weight,
  transform,
  decoration,
  children,
  ...props
}) => {
  let color = tw`text-charcoal`
  if (['h5', 'p2', 'caption'].includes(preset)) color = tw`text-dark-blue-gray`
  return (
    <Tag
      css={css`
        ${color}
        ${tw`block`}
        ${PRESETS[preset]}
        ${!!align && ALIGNMENT[align]}
        ${!!weight && WEIGHTS[weight]}
        ${!!transform && TRANSFORMATIONS[transform]}
        ${!!decoration && DECORATIONS[decoration]}
      `}
      {...props}
    >
      {children}
    </Tag>
  )
}

export default Text
