import React, { FC, Fragment, useMemo } from 'react'

import _ from 'lodash'
import tw from 'twin.macro'

import { useFormikContext } from 'formik'
import { object, string, array } from 'yup'

import Text from '../../../../../ui-blocks/text'
import Search from '../../../../../ui-blocks/search'
import Spinner from '../../../../../ui-blocks/spinner'
import Suspense from '../../../../../ui-blocks/suspense'
import PaginationNav from '../../../../../ui-blocks/pagination-nav'

import CreativeSelectCard from '../../../../../components/creative/select-card'

import usePagination from '../../../../../utils/use-pagination'
import { useSearchTerms } from '../../../../../utils/use-search'

import {
  useCountQuery,
  useListCreativesQuery,
  EnumCreativeStatus,
} from '../../../../../graphql/components'

import { CreateAdGroupFormikValues } from '..'

export const creativesValidationSchema = object().shape({
  creatives_ids: array(string()).min(1).required(),
})

const CreativesStep: FC = () => {
  const {
    values,
    setFieldValue,
  } = useFormikContext<CreateAdGroupFormikValues>()
  const { data: countData, loading: loadingCount } = useCountQuery({
    fetchPolicy: 'no-cache',
  })

  const creativesCount = countData?.creatives || 0
  const pagination = usePagination(creativesCount, 6)

  const { searchText, setSearchText, searchTextDebounced } = useSearchTerms()
  const {
    data: creativesData,
    loading: loadingCreatives,
  } = useListCreativesQuery({
    fetchPolicy: 'no-cache',
    variables: {
      limit: pagination.limit,
      offset: pagination.offset,
      filter: { search: searchTextDebounced },
    },
  })

  const handleSelectCreative = (creativeId: string) => {
    if (!values.creatives_ids.includes(creativeId))
      return setFieldValue('creatives_ids', [
        ...values.creatives_ids,
        creativeId,
      ])
    setFieldValue(
      'creatives_ids',
      _.pull([...values.creatives_ids], creativeId)
    )
  }

  const creatives = useMemo(
    () =>
      (creativesData?.creatives || []).filter(
        (creative) => creative.status !== EnumCreativeStatus.Archived
      ),
    [creativesData]
  )

  return (
    <Fragment>
      <div tw="flex flex-wrap items-end justify-between mb-6">
        <p tw="text-charcoal text-xs pt-3 uppercase">
          {loadingCount
            ? 'Loading...'
            : `${creativesCount} Creatives, ${values.creatives_ids.length} Selected`}
        </p>
        <div style={{ width: 304 }}>
          <Search
            value={searchText}
            loading={loadingCreatives}
            entries={creatives.map((creative) => creative.name)}
            onSelect={(value) =>
              setSearchText(!Array.isArray(value) ? value || '' : '')
            }
          />
        </div>
      </div>
      <Suspense ready={!loadingCreatives} fallback={<Spinner center />}>
        <div tw="flex flex-wrap justify-start -mx-1">
          {creatives?.map((creative, index) => (
            <div key={index} tw="w-1/2 p-2">
              <CreativeSelectCard
                name="creatives_ids"
                creative={creative}
                value={creative?._id}
                onClick={() => handleSelectCreative(creative?._id)}
                checked={values.creatives_ids.includes(creative?._id)}
              />
            </div>
          ))}
        </div>
      </Suspense>
      <div tw="flex flex-row items-center justify-between mt-6">
        <Text
          as="span"
          preset="p2"
          css={[tw`underline`, 'text-decoration-style: dashed;']}
        >
          NOTE: At least one creative MUST be selected!
        </Text>
        <PaginationNav
          page={pagination.page}
          count={pagination.count}
          limit={pagination.limit}
          setPage={pagination.setPage}
        />
      </div>
    </Fragment>
  )
}

export default CreativesStep
