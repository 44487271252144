import React, { FC } from 'react'
import tw, { css } from 'twin.macro'
// import { Link } from 'react-router-dom';

import Button from '../../../ui-blocks/button'

import { IStepProps } from './typings'

const StepPasswordResetSuccessfully: FC<IStepProps> = () => (
  <>
    <h1
      css={css`
        ${tw`text-charcoal m-0 mb-0 font-thin leading-tight text-3xl self-start mb-2`}
      `}
    >
      Password Updated
    </h1>
    <p
      css={css`
        ${tw`text-charcoal font-normal text-sm mb-20`}
      `}
    >
      Your password was successfully updated.
      <br />
      For security purposes we have sent an email to your
      <br />
      account confirming this change.
      <br />
    </p>
    <Button primary>Continue</Button>
  </>
)

export default StepPasswordResetSuccessfully
