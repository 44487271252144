import React, { FC } from 'react'

import { TabNavListProps } from 'rc-tabs/es/TabNavList'
import { TabBarExtraContent } from 'rc-tabs/es/interface'

import { Tabs, TabPane } from '../ui-blocks/tabs'

export interface StatusTabsProps {
  activeTab: string
  tabs: { key: string; label: string }[]
  onSelectTab: (key: string) => void
  tabBarExtraContent?: (props: TabNavListProps) => TabBarExtraContent
}

const StatusTabs: FC<StatusTabsProps> = ({
  tabs,
  activeTab,
  onSelectTab,
  tabBarExtraContent,
}) => (
  <Tabs
    activeKey={activeTab}
    onChange={onSelectTab}
    tabBarExtraContent={tabBarExtraContent}
  >
    {tabs.map((tab) => (
      <TabPane key={tab.key} tab={tab.label} />
    ))}
  </Tabs>
)

export default StatusTabs
