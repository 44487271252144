import React, { FC } from 'react'

import tw, { css } from 'twin.macro'

import WorkspaceInvite from '../../auth/invites/components/workspace-invite'

import { authentication } from '../../../stores'

const AccountWorkspaces: FC = () => {
  return (
    <>
      <span
        css={css`
          ${tw`text-2xl mb-8 font-light text-charcoal`}
        `}
      >
        Account Workspaces
      </span>

      {authentication.workspaces.length > 0 && (
        <div
          css={css`
            ${tw`flex flex-wrap`}
          `}
        >
          {authentication.workspaces.map((workspace) => (
            <div
              key={workspace._id}
              css={css`
                ${tw`m-1`}
              `}
            >
              <WorkspaceInvite name={workspace.name} members={1} />
            </div>
          ))}
        </div>
      )}

      {!authentication.workspaces && (
        <div
          css={css`
            ${tw`text-charcoal text-base font-medium py-4`}
          `}
        >
          You don't have any workspaces!
        </div>
      )}
    </>
  )
}

export default AccountWorkspaces
