import React, { FC } from 'react'
import 'twin.macro'

import Text from '../../../../ui-blocks/text'

import { ReactComponent as PlayerBindingIllustration } from '../../../../assets/illustrations/display_4_binding_1.svg'

import { PLAYER_BASE_URL } from '../../../../config'

const PlayerIntroStep: FC = () => (
  <div tw="flex flex-col">
    <Text as="h3" preset="h4" tw="mb-2">
      Setting up the Player
    </Text>
    <Text as="p" preset="p1" tw="text-dark-blue-gray mb-16">
      Turn on your screen and the Diffuse.tv box.
    </Text>
    <PlayerBindingIllustration tw="self-center" />
  </div>
)

export const PlayerIntroDescription: FC = () => (
  <Text as="h2" preset="p1" tw="text-dark-blue-gray">
    {'Connect to your TV with an HDMI cable or open '}
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={PLAYER_BASE_URL}
      tw="text-purple"
    >
      our web player
    </a>
    {'.'}
  </Text>
)

export default PlayerIntroStep
