import React, { FC } from 'react'
import 'twin.macro'

import Container from '../../../../ui-blocks/container'
import ButtonLink from '../../../../ui-blocks/button-link'

import { ReactComponent as NoCampaigns } from '../../../../assets/illustrations/campaign_empty_state.svg'

import { IoMdAdd } from 'react-icons/io'

const CampaignsEmpty: FC = () => (
  <Container>
    <div tw="flex flex-col items-center">
      <NoCampaigns tw="mb-10" />
      <h1 tw="font-light text-3xl text-charcoal leading-tight mb-4">
        Create your first campaign
      </h1>
      <p tw="font-normal text-charcoal text-center max-w-xl mb-6">
        All set! You are ready to create a new fresh campaign.
      </p>
      <ButtonLink to="/campaigns/create" iconLeft={IoMdAdd}>
        Add Campaign
      </ButtonLink>
    </div>
  </Container>
)

export default CampaignsEmpty
