import React, { FC, useEffect, useMemo, useState } from 'react'

import 'twin.macro'
import { useNavigate, useParams } from 'react-router-dom'

import {
  Informations,
  InformationsSection,
} from '../../../ui-blocks/information'
import Text from '../../../ui-blocks/text'
import Status from '../../../ui-blocks/status'
import Button from '../../../ui-blocks/button'
import Switch from '../../../ui-blocks/switch'
import KPICard from '../../../ui-blocks/kpi-card'
import Container from '../../../ui-blocks/container'
import BreadcrumbNav from '../../../ui-blocks/breadcrumb-nav'

import EditPlayerModal from './components/edit-player-modal'

import Address from '../../../components/address'
import NotFound from '../../../components/not-found'
import AreaType from '../../../components/area-type'
import VenueTypes from '../../../components/venue-types'
import InlineDate from '../../../components/inline-date'
import LoadingPage from '../../../components/loading-page'
import ArchiveWarningModal from '../../../components/archive-warning-modal'

import { IoIosTrash } from 'react-icons/io'

import {
  EnumPlayerStatus,
  useViewPlayerQuery,
  useArchivePlayerMutation,
  useUpdatePlayerActivationMutation,
} from '../../../graphql/components'

import {
  getKPIIntervals,
  groupStatsWithIntervals,
} from '../../../utils/stats-intervals'

import { alertsManager } from '../../../stores'
import { AlertVariant } from '../../../stores/alerts-manager'

const ViewPlayerPage: FC = () => {
  const navigate = useNavigate()
  const { player_id } = useParams()

  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false)
  const [isWarningModalOpen, setIsWarningModalOpen] = useState<boolean>(false)

  const [
    archivePlayer,
    { loading: archivingPlayer },
  ] = useArchivePlayerMutation()
  const [updatePlayerActivation] = useUpdatePlayerActivationMutation()

  const intervals = useMemo(getKPIIntervals, [])
  const {
    data: playerData,
    loading: loadingPlayer,
    refetch: refetchPlayer,
  } = useViewPlayerQuery({
    skip: !player_id,
    variables: {
      _id: player_id,
      intervals: intervals.map(({ starts_at, ends_at }) => ({
        starts_at,
        ends_at,
      })),
    },
  })

  // Don't render a player's page if it is archived
  const { player } = playerData || {}
  useEffect(() => {
    if (player?.status === EnumPlayerStatus.Archived)
      navigate('/players', { replace: true })
  }, [player])

  const stats = useMemo(
    () => groupStatsWithIntervals(intervals, player?.stats || []),
    [intervals, player]
  )

  if (loadingPlayer) return <LoadingPage />
  if (!player) return <NotFound />

  const handleUpdateError = (action: 'archive' | 'activate' | 'deactivate') => {
    alertsManager.emit({
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'update-player-status-error-alert',
      message: `We couldn't ${action} this player. Please try again later.`,
    })
  }

  const onArchivePlayer = async () => {
    try {
      const res = await archivePlayer({ variables: { ids: [player_id] } })
      if (!res.data?.areArchived) return handleUpdateError('archive')
      navigate('/players', { replace: true, state: { refetch: true } })
    } catch {
      handleUpdateError('archive')
    }
  }

  const onUpdatePlayerActivation = async (activate: boolean) => {
    try {
      const res = await updatePlayerActivation({
        variables: { ids: [player_id], is_active: activate },
      })
      if (!res.data?.areUpdated)
        return handleUpdateError(activate ? 'activate' : 'deactivate')
      document.dispatchEvent(new CustomEvent('refetch-players'))
      await refetchPlayer()
    } catch {
      handleUpdateError(activate ? 'activate' : 'deactivate')
    }
  }

  const isSwitchDisabled =
    player?.status === EnumPlayerStatus.Binded ||
    player?.status === EnumPlayerStatus.Registered ||
    player?.status === EnumPlayerStatus.Archived
  return (
    <Container>
      <BreadcrumbNav
        tw="mb-1"
        crumbs={[
          { label: 'Players', linkTo: '/players' },
          { label: player?.name || '' },
        ]}
      />

      <div tw="flex flex-row items-center justify-between mb-6">
        <div tw="flex flex-row items-center">
          <Switch
            disabled={isSwitchDisabled}
            initialChecked={player?.status === EnumPlayerStatus.Activated}
            onSelect={onUpdatePlayerActivation}
            tw="-ml-2 mr-2"
          />
          <Text as="h1" preset="h3">
            {player?.name}
          </Text>
        </div>
        <Button
          secondary
          type="button"
          iconLeft={IoIosTrash}
          loading={archivingPlayer}
          onClick={() => setIsWarningModalOpen(true)}
        >
          Archive
        </Button>
      </div>

      <div tw="whitespace-pre-line mb-14">
        <Informations
          title="Player Information"
          onEdit={() => setIsEditModalOpen(true)}
        >
          <InformationsSection label="STATUS">
            <Status value={player.status as EnumPlayerStatus} />
          </InformationsSection>
          <InformationsSection label="ADDRESS">
            <Address address={player.geographics?.address || undefined} />
          </InformationsSection>
          <InformationsSection label="LAST UPDATE">
            <InlineDate date={new Date(player.last_seen_at)} />
          </InformationsSection>
          <InformationsSection label="AREA TYPE">
            <AreaType areaTypeId={player?.demographics?.area_type_id} />
          </InformationsSection>
          <InformationsSection label="VENUE TYPES">
            <VenueTypes
              venueTypeIds={player?.demographics?.venue_type_ids || []}
            />
          </InformationsSection>
        </Informations>
      </div>

      <KPICard title="Player Analytics" intervals={stats} />

      <EditPlayerModal
        open={isEditModalOpen}
        playerId={player_id}
        refetchData={refetchPlayer}
        onClose={() => setIsEditModalOpen(false)}
      />
      <ArchiveWarningModal
        target="player"
        redirectPage="Players"
        open={isWarningModalOpen}
        loading={archivingPlayer}
        onConfirm={onArchivePlayer}
        onCancel={() => setIsWarningModalOpen(false)}
      />
    </Container>
  )
}

export default ViewPlayerPage
