import React, { FC, useState, useEffect } from 'react'

import tw from 'twin.macro'
import { FormikValues } from 'formik'
import { useNavigate } from 'react-router'

import Spinner from '../../../ui-blocks/spinner'
import Suspense from '../../../ui-blocks/suspense'
import Container from '../../../ui-blocks/container'

import SetupForm from '../../../components/setup-form'

import PlayerIntroStep, { PlayerIntroDescription } from './steps/1-intro'
import BindPlayerStep, {
  bindPlayerValidationSchema,
} from './steps/2-binding-code'
import PlayerInformationStep, {
  editPlayerValidationSchema,
} from './steps/3-save-player-data'

import {
  EnumPointType,
  useViewPlayerQuery,
  useBindPlayerMutation,
  useUpdatePlayerByIdMutation,
  EnumPlayerSettingsScreen_Orientation,
} from '../../../graphql/components'

import { alertsManager } from '../../../stores'
import { AlertVariant } from '../../../stores/alerts-manager'

import { cleanGraphqlTypenames } from '../../../utils/data-manipulation'

export interface BindPlayerFormikValues {
  id: string
  code: string
  name: string
  settings: {
    debug: boolean
    volume: number
    brightness: number
    screen_orientation: EnumPlayerSettingsScreen_Orientation
  }
  geographics: {
    location: {
      type: EnumPointType
      coordinates: number[]
    }
    address: {
      street: string
      number: string
      city: string
      state: string
      zip: string
      country_id: string
    }
  }
  demographics: {
    country_id: string
    area_type_id: string
    language_ids: string[]
    venue_type_ids: string[]
  }
}

const BindPlayerPage: FC = () => {
  const navigate = useNavigate()
  const viewPlayer = useViewPlayerQuery()

  const [bindPlayer] = useBindPlayerMutation()
  const [updatePlayer] = useUpdatePlayerByIdMutation()

  const [urlCode, setUrlCode] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)
  const [initialValues, setInitialValues] = useState<FormikValues>({
    id: '',
    code: '',
  })

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const code = params.get('code') ?? ''
    if (!!code) setUrlCode(code)
    setLoading(false)
  }, [])

  const handleError = () => {
    alertsManager.emit({
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'create-campaign-error-alert',
      message: "We couldn't bind the player. Please try again later.",
    })
  }

  const handleBindSubmit = async (values: BindPlayerFormikValues) => {
    try {
      const bindRes = await bindPlayer({ variables: { token: values.code } })
      if (!bindRes.data?.player?._id) {
        handleError()
        return false
      }

      const queryRes = await viewPlayer.refetch({
        _id: bindRes.data.player._id,
      })
      setInitialValues({
        ...initialValues,
        id: bindRes.data.player._id,
        name: queryRes.data.player?.name,
        settings: cleanGraphqlTypenames(queryRes.data.player?.settings),
        geographics: {
          location: {
            type: EnumPointType.Point,
            coordinates: [],
          },
          address: {
            street: '',
            number: '',
            city: '',
            state: '',
            zip: '',
            country_id: '',
          },
        },
        demographics: {
          country_id: null,
          area_type_id: '',
          language_ids: [],
          venue_type_ids: [],
        },
      })

      return true
    } catch {
      handleError()
      return false
    }
  }

  const handleEditSubmit = async (values: BindPlayerFormikValues) => {
    try {
      const res = await updatePlayer({
        variables: {
          _id: values.id,
          input: {
            name: values.name,
            settings: values.settings,
            geographics: values.geographics,
            demographics: values.demographics,
          },
        },
      })

      if ((res.errors || []).length > 0) {
        handleError()
        return false
      }

      navigate(`/players/${res.data?.player?._id}`)
      return true
    } catch {
      handleError()
      return false
    }
  }

  return (
    <Container>
      <div css={[tw`w-full self-center`, 'max-width: 68rem;']}>
        <Suspense ready={!loading} fallback={<Spinner center />}>
          <SetupForm
            title="Bind Player"
            description="This process binds a freshly installed player to your workspace."
            onSubmit={{ 1: handleBindSubmit, 2: handleEditSubmit }}
            initialStepIndex={!!urlCode ? 1 : 0}
            initialValues={initialValues}
            steps={[
              {
                canGoBack: true,
                title: 'Setup your Player',
                content: <PlayerIntroStep />,
                description: <PlayerIntroDescription />,
              },
              {
                canGoBack: true,
                title: 'Connect your Player',
                content: <BindPlayerStep urlCode={urlCode} />,
                validationSchema: bindPlayerValidationSchema,
              },
              {
                canGoBack: false,
                title: 'Player information',
                content: <PlayerInformationStep />,
                validationSchema: editPlayerValidationSchema,
                subSteps: ['Name', 'Address', 'Geolocation', 'Settings'],
              },
            ]}
          />
        </Suspense>
      </div>
    </Container>
  )
}

export default BindPlayerPage
