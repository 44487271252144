import React, { FC } from 'react'

import ModalAction from '../../../../../ui-blocks/modal-action'

import {
  Webhook,
  useArchiveWebhookMutation,
} from '../../../../../graphql/components'

import {
  AlertVariant,
  alertsManager,
} from '../../../../../stores/alerts-manager'

export interface RemoveWebhookModalProps {
  webhook: Webhook | null
  onClose?: () => void
  refetchData?: () => void
}

const RemoveWebhookModal: FC<RemoveWebhookModalProps> = ({
  webhook,
  onClose,
  refetchData,
}) => {
  const [
    archiveWebhook,
    { loading: removingWebhook },
  ] = useArchiveWebhookMutation()

  const onRemoveWebhook = async () => {
    try {
      const res = await archiveWebhook({ variables: { ids: [webhook?._id] } })
      if (res.data?.areArchived) {
        refetchData && refetchData()
        onClose && onClose()
      } else handleError()
    } catch {
      handleError()
    }
  }

  const handleError = () => {
    alertsManager.emit({
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'remove-webhook-error-alert',
      message: "We couldn't remove this webhook. Please try again later.",
    })
  }

  return (
    <ModalAction
      open={!!webhook}
      onCancel={onClose}
      onBackdropClick={onClose}
      onConfirm={onRemoveWebhook}
      loading={removingWebhook}
      title={`Webhook: ${webhook ? webhook.url : ''}`}
      content="Are you sure you want to delete this webhook?"
    />
  )
}

export default RemoveWebhookModal
