import React, { FC, useState } from 'react'

import 'twin.macro'
import { useParams } from 'react-router-dom'

import Search from '../../../../ui-blocks/search'
import Avatar from '../../../../ui-blocks/avatar'
import ButtonLink from '../../../../ui-blocks/button-link'

import NoSearchResults from './components/no-search-results'
import RemoveMemberModal from './components/remove-member-modal'

import LoadingPage from '../../../../components/loading-page'
import AlertMessage, {
  AlertVariant,
} from '../../../../components/alert-component'

import {
  WorkspaceMembers,
  useListInvitesQuery,
  useViewWorkspaceQuery,
} from '../../../../graphql/components'
import { MutationResult } from '../../../../typings'

import { useSearchTerms } from '../../../../utils/use-search'

import { AiOutlineClose } from 'react-icons/ai'

import { authentication } from '../../../../stores'

export type Member = WorkspaceMembers & { status: string }

const WorkspaceSettingsMembersPage: FC = () => {
  const { workspace_id } = useParams()

  const [memberEmail, setMemberEmail] = useState<string>('')
  const [mutationResult, setMutationResult] = useState<MutationResult>(null)

  const {
    data: workspaceData,
    loading: loadingWorkspace,
    refetch: refetchWorkspace,
  } = useViewWorkspaceQuery({
    skip: !workspace_id,
    fetchPolicy: 'cache-and-network',
    variables: { _id: workspace_id },
  })
  const workspaceMembers = (workspaceData?.workspace?.members || []).filter(
    (member) => !member?.removed?.at
  )

  const { data: invitesData, loading: loadingInvites } = useListInvitesQuery({
    fetchPolicy: 'cache-and-network',
  })
  const pendingInvites = (invitesData?.invites || []).filter(
    (invite) => invite.status === 'PENDING'
  )

  const onOpenModal = (email: string) => {
    setMemberEmail(email)
  }

  const onCloseModal = async (result: MutationResult) => {
    setMemberEmail('')
    setMutationResult(result)
    result === 'success' && (await refetchWorkspace())
  }

  const allMembers = [
    ...workspaceMembers,
    ...pendingInvites.map((invite) => ({
      status: invite.status || '',
      user_id: invite.target?.user_id || '',
      user: {
        _id: invite.target?.user_id || '',
        emails: [{ address: invite.target?.email || '' }],
        name: {
          first: invite.target?.name?.first || '',
          last: invite.target?.name?.last || '',
        },
      },
    })),
  ]

  const { searchText, setSearchText, searchTextDebounced } = useSearchTerms()
  const filteredMembers: any[] = allMembers.filter((member) => {
    const firstName = member?.user?.name?.first || ''
    const lastName = member?.user?.name?.last || ''

    const emails = member?.user?.emails || []
    const email = (emails.length > 0 && emails[0]?.address) || ''

    const regex = new RegExp(searchTextDebounced, 'i')
    return regex.test(firstName) || regex.test(lastName) || regex.test(email)
  })

  const renderMemberRow = (member: Member) => {
    const { user, status } = member
    const names = [user?.name?.first || '', user?.name?.last || ''].filter(
      Boolean
    )

    const fullName = names.length ? names.join(' ') : 'Unknown'
    const initials = names.length
      ? names.map((name) => name.charAt(0)).join('')
      : 'U'

    const email = (user?.emails?.length && user?.emails[0]?.address) || ''
    const canRemove = !authentication.user?.emails.find(
      (userEmail) => userEmail?.address === email
    )

    return (
      <tr tw="border-b border-platinum">
        <td tw="py-2">
          <div tw="flex flex-row items-center">
            <Avatar initials={initials} variant="profile" tw="mr-2" />
            <div tw="flex flex-col">
              <p tw="text-charcoal font-medium">
                {`${fullName}${status ? ` (${status})` : ''}`}
              </p>
              <p tw="text-xs text-charcoal">{email}</p>
            </div>
          </div>
        </td>
        <td tw="py-2">
          {canRemove && !status && (
            <div tw="flex flex-row justify-end">
              <a
                tw="text-dark-blue-gray leading-tight cursor-pointer hover:text-purple"
                onClick={() => onOpenModal(email)}
              >
                <span tw="flex flex-row items-center">
                  <AiOutlineClose size={14} tw="mr-1" />
                  <span tw="leading-tight">Remove</span>
                </span>
              </a>
            </div>
          )}
        </td>
      </tr>
    )
  }

  if (loadingWorkspace || loadingInvites) return <LoadingPage />
  return (
    <div>
      {mutationResult && (
        <div tw="mb-6">
          <AlertMessage
            alert={{
              id: 'remove-member-update-alert',
              variant:
                mutationResult === 'error'
                  ? AlertVariant.ERROR
                  : AlertVariant.SUCCESS,
              message:
                mutationResult === 'error'
                  ? "We couldn't remove your team member. Please try again later."
                  : 'Team member removed successfully.',
            }}
          />
        </div>
      )}
      <h2 tw="font-medium text-charcoal leading-tight mb-2">Manage members</h2>
      <p tw="font-normal text-dark-blue-gray leading-tight mb-6">
        Manage members that collaborate in this workspace.
      </p>
      <div tw="flex flex-row items-end justify-between mb-8">
        <span tw="text-dark-blue-gray">
          {workspaceMembers.length} Member(s), {pendingInvites.length} Invite(s)
        </span>
        <div tw="flex flex-row items-center">
          <div tw="max-w-xs w-full mr-4">
            <Search
              value={searchText}
              onSelect={(value) =>
                setSearchText(!Array.isArray(value) ? value || '' : '')
              }
            />
          </div>
          <ButtonLink to={`/workspaces/${workspace_id}/settings/members/add`}>
            Add member
          </ButtonLink>
        </div>
      </div>
      {filteredMembers.length ? (
        <table tw="w-full">
          <thead>
            <tr tw="border-b border-platinum font-medium text-sm text-dark-blue-gray leading-tight tracking-wider uppercase">
              <th tw="pb-4 text-left">User</th>
              {/* <th tw="pb-4 text-left">Access Level</th> */}
              <th tw="pb-4"></th>
            </tr>
          </thead>
          <tbody>{filteredMembers.map(renderMemberRow)}</tbody>
        </table>
      ) : (
        <NoSearchResults searchQuery={searchTextDebounced} />
      )}
      <RemoveMemberModal
        open={!!memberEmail}
        memberEmail={memberEmail}
        onClose={onCloseModal}
      />
    </div>
  )
}

export default WorkspaceSettingsMembersPage
