import React, { FC } from 'react'

import 'twin.macro'
import { IconType } from 'react-icons'

import Card from '../../../../ui-blocks/card'

const SummaryCard: FC<{ title: string; count: number; icon: IconType }> = ({
  icon: Icon,
  title,
  count,
}) => (
  <Card tw="py-4 px-6">
    <div tw="w-12 h-12 mb-4 bg-cultured rounded-full flex justify-center items-center">
      <Icon size={24} tw="text-purple" />
    </div>
    <h2 tw="text-charcoal font-light leading-tight text-5xl">{count}</h2>
    <h3 tw="text-charcoal font-normal leading-tight text-base">{title}</h3>
  </Card>
)

export default SummaryCard
