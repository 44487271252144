import React, { FC } from 'react'

import Dots from '../ui-blocks/dots'

import NotDefined from './not-defined'

import { useViewAreaTypeQuery } from '../graphql/components'

export interface AreaTypeProps {
  areaTypeId: string
}

const AreaType: FC<AreaTypeProps> = ({ areaTypeId }) => {
  const { data, loading } = useViewAreaTypeQuery({
    skip: !areaTypeId,
    variables: { _id: areaTypeId },
  })

  if (loading) return <Dots />
  return !!data?.areaType?.name ? (
    <span>{data.areaType.name}</span>
  ) : (
    <NotDefined />
  )
}

export default AreaType
