import React, { FC, useState } from 'react'

import { useObserver } from 'mobx-react-lite'
import { useLocation, useNavigate } from 'react-router-dom'
import Menu from './components/menu'
import AlertEmitter from '../../components/alerts-manager'
import UploadsDashboard from '../../ui-blocks/uploads-dashboard'
import { authentication } from '../../stores'
import withEndTrialModal from '../../utils/with-end-trial-modal'
import { ReactComponent as DiffuseLogo } from '../../assets/diffuse-logo.svg'
import SignOutModal from '../../components/sign-out-modal'
import { NODE_ENV } from '../../config'

const MainLayout: FC = ({ children }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [signOutModalOpen, setSignOutModalOpen] = useState<boolean>(false)

  // redirect to /workspaces/create in case we don't have one yet
  // if (!authentication.error && authentication.workspaces.length === 0) {
  //   return <Navigate to="/workspaces/create" />
  // }

  // useEffect(() => {
  //   if (
  //     authentication.is_authenticated &&
  //     !authentication.has_a_verified_email
  //   ) {
  //     alertsManager.emit({
  //       id: 'unverified-email-alert',
  //       dismissable: true,
  //       timeout: 0,
  //       variant: AlertVariant.ERROR,
  //       actionText: 'Resend it here.',
  //       message:
  //         "It seems that your email is not yet verified. Didn't receive the verification email?",
  //       onAction: () => console.log('@TODO: resending verification email...'),
  //     })
  //   }
  // }, [authentication.is_authenticated, authentication.has_a_verified_email])

  // useEffect(() => {
  //   if (
  //     authentication.is_authenticated &&
  //     !authentication.has_a_verified_phone_number
  //   ) {
  //     alertsManager.emit({
  //       id: 'unverified-phone-number-alert',
  //       dismissable: true,
  //       timeout: 0,
  //       variant: AlertVariant.ERROR,
  //       actionText: 'Resend it here.',
  //       message:
  //         "It seems that your phone number is not yet verified. Didn't receive the verification email?",
  //       onAction: () => console.log('@TODO: resending verification email...'),
  //     })
  //   }
  // }, [
  //   authentication.is_authenticated,
  //   authentication.has_a_verified_phone_number,
  // ])

  return useObserver(() => (
    <>
      <Menu
        logo={{
          to: '/',
          alt: 'diffuse',
          src: DiffuseLogo,
        }}
        navItems={[
          {
            name: 'Overview',
            to: `/workspaces/${
              authentication.selected_workspace?._id || ''
            }/overview`,
            isActive: location.pathname.startsWith(
              `/workspaces/${
                authentication.selected_workspace?._id || ''
              }/overview`
            ),
          },
          {
            name: 'Campaigns',
            to: '/campaigns',
            isActive: location.pathname.startsWith('/campaigns'),
          },
          {
            name: 'Creatives',
            to: '/creatives',
            isActive: location.pathname.startsWith('/creatives'),
          },
          {
            name: 'Networks',
            to: '/networks',
            isActive: location.pathname.startsWith('/networks'),
          },
          {
            name: 'Players',
            to: '/players',
            isActive: location.pathname.startsWith('/players'),
          },
        ]}
        showWorkspaceMenu
        workspaces={authentication.workspaces}
        activeWorkspace={authentication.selected_workspace}
        onSelectWorkspace={async (ev, _id) => {
          ev.preventDefault()
          ev.stopPropagation()
          await authentication.selectWorkspace(_id)
          navigate(`workspaces/${_id}/overview`, {
            replace: true,
          })
        }}
        actions={[
          {
            name: 'Account Settings',
            isActive: location.pathname.includes('/account/'),
            onClick: () => navigate('/account/personal-information'),
          },
          {
            name: 'Workspace Settings',
            isActive: location.pathname.includes(
              `/workspaces/${authentication.selected_workspace._id}/settings/`
            ),
            onClick: () =>
              navigate(
                `/workspaces/${authentication.selected_workspace._id}/settings/details`
              ),
          },
          ...(NODE_ENV !== 'development'
            ? []
            : [
                {
                  name: 'Dev Components',
                  isActive: location.pathname.includes(`/dev/`),
                  onClick: () => navigate(`/dev/components`),
                },
              ]),
          {
            name: 'Logout',
            onClick: () => setSignOutModalOpen(true),
          },
        ]}
      />
      {children}
      <AlertEmitter />
      <UploadsDashboard />
      <SignOutModal
        open={signOutModalOpen}
        onCancel={() => setSignOutModalOpen(false)}
      />
    </>
  ))
}

export default withEndTrialModal(MainLayout)
