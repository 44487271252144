import React, { FC } from 'react'

import NotDefined from '../not-defined'

import { Upload } from '../../graphql/components'

import { formatDuration } from '../../utils/formaters'

export interface UploadDurationProps {
  upload?: Upload
}

const UploadDuration: FC<UploadDurationProps> = ({ upload }) => {
  const versions = upload?.versions || []
  const original = versions.find((v) => v?.name === 'original')
  const thumbnail = versions.find((v) => v?.name === 'thumbnail') || original
  return thumbnail && upload && upload.type === 'video' ? (
    <span>{formatDuration(thumbnail.duration ?? 0)}</span>
  ) : (
    <NotDefined />
  )
}

export default UploadDuration
