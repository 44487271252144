import { useState, useEffect } from 'react'

export default function usePagination(count: number, limit = 25) {
  const [page, setPage] = useState<number>(0)
  const offset = page * limit

  const hasPrevious = page > 0
  const hasNext = count / limit > page + 1

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [page])

  return {
    count,
    offset,
    limit,
    page,
    setPage,
    hasPrevious,
    hasNext,
  }
}

export type Pagination = ReturnType<typeof usePagination>
