import React, { FC } from 'react'
import 'twin.macro'

import ModalAction from '../ui-blocks/modal-action'

export interface ArchiveWarningModalProps {
  target: string
  open?: boolean
  loading?: boolean
  redirectPage?: string
  onCancel: () => void
  onConfirm: () => void
}

const ArchiveWarningModal: FC<ArchiveWarningModalProps> = ({
  target,
  open,
  loading,
  redirectPage,
  onCancel,
  onConfirm,
}) => (
  <ModalAction
    open={open}
    loading={loading}
    onCancel={onCancel}
    onConfirm={onConfirm}
    title={`WARNING: Archive ${target}`}
  >
    <p tw="leading-tight mb-1">
      Once you archive a {target} there is no going back. Are you sure you want
      to do this?
    </p>
    {redirectPage && (
      <span tw="text-dark-blue-gray text-xs font-normal leading-tight">
        {`NOTE: Once the ${target} has been archived you will be redirected back to the `}
        <b>{redirectPage}</b>
        {' page.'}
      </span>
    )}
  </ModalAction>
)

export default ArchiveWarningModal
