import React, { FC, useEffect } from 'react'
import 'twin.macro'

import { useFormik } from 'formik'
import { object, string } from 'yup'

import Text from '../../../ui-blocks/text'
import Button from '../../../ui-blocks/button'
import Spacer from '../../../ui-blocks/spacer'
import { InputFormik } from '../../../ui-blocks/input'

import AlertMessage, { AlertVariant } from '../../../components/alert-component'

import { trimValues } from '../../../utils/data-manipulation'

import { useUpdateAccountDetailsMutation } from '../../../graphql/components'
import { MutationResult } from '../../../typings'

import { authentication } from '../../../stores'

interface AccountDetailsFormikValues {
  name: {
    first: string
    last: string
  }
}

const accountDetailsValidationSchema = object().shape({
  name: object()
    .shape({
      first: string()
        .trim('Value cannot have leading or trailing white spaces')
        .required('First name is required')
        .strict(true),
      last: string()
        .trim('Value cannot have leading or trailing white spaces')
        .required('Last name is required')
        .strict(true),
    })
    .required(),
})

let accountDetailsResult: MutationResult = null
const AccountDetails: FC = () => {
  useEffect(() => {
    if (!!accountDetailsResult) accountDetailsResult = null
    return () => {
      accountDetailsResult = null
    }
  }, [])

  const [updateAccountDetails] = useUpdateAccountDetailsMutation()
  const formik = useFormik<AccountDetailsFormikValues>({
    validateOnChange: false,
    validationSchema: accountDetailsValidationSchema,
    initialValues: {
      name: {
        first: authentication.user?.name?.first || '',
        last: authentication.user?.name?.last || '',
      },
    },
    async onSubmit(values) {
      const newValues = trimValues(values, ['name.first', 'name.last'])
      accountDetailsResult = null
      try {
        const res = await updateAccountDetails({
          variables: { details: newValues },
        })
        if (!!(res.errors || []).length) {
          accountDetailsResult = 'error'
          return
        }

        accountDetailsResult = 'success'
        await authentication.loadUserData()
      } catch {
        accountDetailsResult = 'error'
      }
    },
  })

  return (
    <section>
      {!!accountDetailsResult && (
        <div tw="mb-4">
          <AlertMessage
            alert={{
              dismissable: false,
              id: 'account-details-update-error-alert',
              variant:
                accountDetailsResult === 'error'
                  ? AlertVariant.ERROR
                  : AlertVariant.SUCCESS,
              message:
                accountDetailsResult === 'error'
                  ? "We couldn't update your account details. Please try again later."
                  : 'Account details updated successfully!',
            }}
          />
        </div>
      )}
      <Text as="h2" preset="h4" tw="mb-2">
        Personal information
      </Text>
      <Text as="h3" preset="p2" tw="mb-6">
        Your account information is confidential and is stored on an encrypted
        database
      </Text>
      <form
        onChange={formik.handleChange}
        onSubmit={formik.handleSubmit}
        tw="max-w-xs"
      >
        <InputFormik
          required
          type="text"
          name="name.first"
          label="First Name"
          formik={formik}
        />
        <Spacer size="1.5rem" />
        <InputFormik
          required
          type="text"
          name="name.last"
          label="Last Name"
          formik={formik}
        />
        <Spacer size="2.5rem" />
        <Button
          type="submit"
          disabled={!formik.dirty}
          loading={formik.isSubmitting}
        >
          Update account details
        </Button>
      </form>
    </section>
  )
}

export default AccountDetails
