import React, { FC } from 'react'
import 'twin.macro'

import _ from 'lodash'
import { useFormik } from 'formik'
import { string, object, number, array } from 'yup'

import Map from '../../../../ui-blocks/map'
import Radio from '../../../../ui-blocks/radio'
import Checkbox from '../../../../ui-blocks/checkbox'
import Suspense from '../../../../ui-blocks/suspense'
import ModalAction from '../../../../ui-blocks/modal-action'
import CardResourceRadio from '../../../../ui-blocks/card-resource-radio'
import { CardResourceProps } from '../../../../ui-blocks/card-resource'
import { InputFormik } from '../../../../ui-blocks/input'

import LoadingPage from '../../../../components/loading-page'
import CountryInput from '../../../../components/country-input'

import {
  Player,
  PlayerSettings,
  PlayerGeographics,
  PlayerDemographics,
  useViewPlayerQuery,
  useListAreaTypesQuery,
  useListVenueTypesQuery,
  useUpdatePlayerByIdMutation,
  EnumPointType,
  EnumPlayerSettingsScreen_Orientation,
} from '../../../../graphql/components'

import { alertsManager } from '../../../../stores'
import { AlertVariant } from '../../../../stores/alerts-manager'

import { IMapPoint } from '../../../../typings'

import {
  trimValues,
  cleanGraphqlTypenames,
} from '../../../../utils/data-manipulation'

interface EditPlayerFormikValues {
  name: string
  settings: PlayerSettings
  geographics: PlayerGeographics
  demographics: PlayerDemographics
}

const editPlayerValidationSchema = object().shape({
  name: string()
    .trim('Value cannot have leading or trailing white spaces')
    .required('Name is required')
    .strict(true),
  settings: object()
    .shape({
      screen_orientation: string()
        .oneOf([
          EnumPlayerSettingsScreen_Orientation.Landscape,
          EnumPlayerSettingsScreen_Orientation.Portrait,
        ])
        .required(),
    })
    .required(),
  geographics: object()
    .shape({
      location: object()
        .shape({
          coordinates: array().of(number()).required('Location is required'),
        })
        .required(),
      address: object()
        .shape({
          street: string()
            .trim('Value cannot have leading or trailing white spaces')
            .required('Street name is required')
            .strict(true),
          number: number()
            .positive('Must be a number greater than zero')
            .required('Suite, Apt. is required'),
          city: string()
            .trim('Value cannot have leading or trailing white spaces')
            .required('City is required')
            .strict(true),
          state: string()
            .trim('Value cannot have leading or trailing white spaces')
            .required('State is required')
            .strict(true),
          zip: string()
            .trim('Value cannot have leading or trailing white spaces')
            .required('ZIP Code is required')
            .strict(true),
          country_id: string().required('Country is required'),
        })
        .required(),
    })
    .required(),
  demographics: object().shape({
    language_ids: array(string()),
    country_id: string().nullable(),
    area_type_id: string().required(),
    venue_type_ids: array(string()),
  }),
})

export interface EditPlayerModalProps {
  open?: boolean
  playerId: string
  onClose?: () => void
  refetchData?: () => void
}

const formikCoordinates = (player: Player): IMapPoint[] => {
  const coords = player?.geographics?.location?.coordinates || []
  return coords.length ? [{ lng: coords[0], lat: coords[1] } as IMapPoint] : []
}

const EditPlayerModal: FC<EditPlayerModalProps> = ({
  open,
  playerId,
  onClose,
  refetchData,
}) => {
  const { data: areaTypesData } = useListAreaTypesQuery()
  const { data: venueTypesData } = useListVenueTypesQuery()

  const [
    updatePlayer,
    { loading: updatingPlayer },
  ] = useUpdatePlayerByIdMutation()
  const {
    data: playerData,
    loading: loadingPlayer,
    refetch: refetchPlayer,
  } = useViewPlayerQuery({
    skip: !playerId,
    variables: { _id: playerId },
  })

  const player = playerData?.player
  const formik = useFormik<EditPlayerFormikValues>({
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: editPlayerValidationSchema,
    initialValues: {
      name: player?.name || '',
      demographics: { ...player?.demographics },
      geographics: {
        location: player?.geographics?.location || {},
        address: player?.geographics?.address || {
          street: '',
          number: '',
          city: '',
          state: '',
          zip: '',
          country_id: '',
        },
      },
      settings: {
        screen_orientation:
          player?.settings?.screen_orientation ||
          EnumPlayerSettingsScreen_Orientation.Landscape,
      },
    },
    async onSubmit(values) {
      const newValues = trimValues(
        cleanGraphqlTypenames({
          name: values.name,
          settings: values.settings,
          geographics: values.geographics,
          demographics: values.demographics,
        }),
        [
          'name',
          'geographics.address.street',
          'geographics.address.city',
          'geographics.address.state',
          'geographics.address.zip',
        ]
      )

      try {
        const res = await updatePlayer({
          variables: { _id: playerId, input: newValues },
        })
        if ((res.errors || []).length > 0) {
          handleError()
        } else {
          refetchData?.()
          refetchPlayer()
          onCloseModal(false)
        }
      } catch {
        handleError()
      }
    },
  })

  const onCloseModal = (reset = true) => {
    if (updatingPlayer) return
    reset && formik.resetForm()
    onClose?.()
  }

  const handleError = () => {
    alertsManager.emit({
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'update-network-error-alert',
      message: "We couldn't update this network. Please try again later.",
    })
  }

  const setCoordinates = (point: IMapPoint) => {
    formik.setValues({
      ...formik.values,
      geographics: {
        ...(formik.values.geographics || {}),
        location: {
          ...((formik.values.geographics || {}).location || {}),
          type: EnumPointType.Point,
          coordinates: [point.lng, point.lat],
        },
      },
    })
  }

  const setCountry = (value: string) => {
    formik.setValues({
      ...formik.values,
      geographics: {
        ...(formik.values.geographics || {}),
        address: {
          ...((formik.values.geographics || {}).address || {}),
          country_id: value,
        },
      },
    })
  }

  const setAreaType = (areaTypeId: string) => {
    formik.setValues({
      ...formik.values,
      demographics: {
        ...formik.values.demographics,
        area_type_id: areaTypeId,
      },
    })
  }

  const setVenueType = (venueTypeId: string) => {
    const newVenueTypeIds: any[] = [
      ...(formik.values.demographics?.venue_type_ids || []),
    ]
    const idIndex = newVenueTypeIds.findIndex(
      (venue_type_id) => venue_type_id === venueTypeId
    )

    if (idIndex === -1) newVenueTypeIds.push(venueTypeId)
    else newVenueTypeIds.splice(idIndex, 1)

    formik.setValues({
      ...formik.values,
      demographics: {
        ...formik.values.demographics,
        venue_type_ids: newVenueTypeIds,
      },
    })
  }

  const isVenueTypeChecked = (venueTypeId: string) =>
    (formik.values.demographics?.venue_type_ids || []).includes(venueTypeId)

  const splitVenueTypes = _.chunk(venueTypesData?.venueTypes || [], 4)
  const areaTypes: CardResourceProps[] = (areaTypesData?.areaTypes || []).map(
    (areaType) => ({
      value: areaType._id,
      title: areaType.name || '',
      description: areaType.description || '',
    })
  )

  const { errors, touched }: any = formik
  return (
    <ModalAction
      open={open}
      loading={updatingPlayer}
      onConfirm={formik.submitForm}
      confirmDisabled={!formik.dirty}
      onCancel={() => onCloseModal()}
      onBackdropClick={() => onCloseModal()}
      confirmButtonText="Accept changes"
      width="42rem"
    >
      <Suspense ready={!loadingPlayer} fallback={<LoadingPage />}>
        <form onChange={formik.handleChange}>
          <h1 tw="font-light text-3xl text-charcoal leading-tight mb-10">
            Player details
          </h1>
          <section tw="mb-12">
            <span tw="font-medium text-charcoal leading-tight block mb-4">
              Information
            </span>
            <InputFormik
              required
              type="text"
              name="name"
              label="Name"
              formik={formik}
            />
          </section>
          <section tw="mb-12">
            <span tw="font-medium text-charcoal leading-tight block mb-4">
              Address
            </span>
            <div tw="w-full flex mb-4">
              <div tw="flex-auto pr-10">
                <InputFormik
                  label="Street Name"
                  name="geographics.address.street"
                  formik={formik}
                />
              </div>
              <div tw="w-24">
                <InputFormik
                  label="Suite, Apt."
                  name="geographics.address.number"
                  formik={formik}
                />
              </div>
            </div>
            <div tw="w-full flex mb-4">
              <div tw="flex-auto pr-10">
                <InputFormik
                  label="City"
                  name="geographics.address.city"
                  formik={formik}
                />
              </div>
              <div tw="w-32 pr-10">
                <InputFormik
                  label="State"
                  name="geographics.address.state"
                  formik={formik}
                />
              </div>
              <div tw="w-32">
                <InputFormik
                  label="ZIP Code"
                  name="geographics.address.zip"
                  formik={formik}
                />
              </div>
            </div>
            <div tw="w-1/2">
              <CountryInput
                showLabel
                onSelect={setCountry}
                error={errors.geographics?.address?.country_id}
                initialValue={formik.values.geographics?.address?.country_id}
                success={
                  touched.geographics?.address?.country_id &&
                  !errors.geographics?.address?.country_id
                }
              />
            </div>
          </section>
          <section tw="mb-12">
            <span tw="font-medium text-charcoal leading-tight block mb-4">
              Geolocation
            </span>
            <Map
              height="18rem"
              onSetPoint={setCoordinates}
              points={formikCoordinates(formik.values as any)}
              error={errors.geographics?.location?.coordinates}
            />
          </section>
          <section>
            <span tw="font-medium text-charcoal leading-tight block mb-4">
              Settings
            </span>

            <span tw="mb-2 text-charcoal font-medium text-xs tracking-wider block uppercase">
              Orientation
            </span>
            <div tw="mt-2 mb-8">
              {Object.values(EnumPlayerSettingsScreen_Orientation).map(
                (value, index) => (
                  <Radio
                    value={value}
                    label={value}
                    key={`radio#${index}`}
                    name="settings.screen_orientation"
                    checked={
                      formik.values.settings?.screen_orientation === value
                    }
                    onChange={() =>
                      formik.setFieldValue('settings.screen_orientation', value)
                    }
                  />
                )
              )}
            </div>

            <span tw="mb-2 text-charcoal font-medium text-xs tracking-wider block uppercase">
              Area Type
            </span>
            <div tw="text-charcoal text-base mb-6">
              Improve your player's reachability by defining the Area Type.
            </div>
            <CardResourceRadio
              cards={areaTypes}
              onChange={setAreaType}
              value={formik.values.demographics?.area_type_id}
            />
            {!!errors.demographics?.area_type_id && (
              <span tw="font-normal text-xs text-brick-red leading-tight block mt-2">
                {errors.demographics?.area_type_id}
              </span>
            )}

            <span tw="mt-8 mb-2 text-charcoal font-medium text-xs tracking-wider block uppercase">
              Venue Type
            </span>
            <div tw="flex">
              {splitVenueTypes.map((venues, index) => (
                <div tw="w-40" key={`venue-types#${index}`}>
                  {venues.map((venue) => (
                    <Checkbox
                      value={venue._id}
                      label={venue.name}
                      key={`venue-${venue._id}`}
                      checked={isVenueTypeChecked(venue._id)}
                      onChange={() => setVenueType(venue._id)}
                    />
                  ))}
                </div>
              ))}
            </div>
          </section>
        </form>
      </Suspense>
    </ModalAction>
  )
}

export default EditPlayerModal
