import React, { useState } from 'react'
import tw, { css } from 'twin.macro'

import Button from '../../../ui-blocks/button'
import CodeInput from '../../../ui-blocks/code-input'

export default function SignUpValidationCode() {
  const [
    disabled,
    // setDisabled
  ] = useState<boolean>(false)

  return (
    <div style={{ maxWidth: '368px' }}>
      <form>
        <h1
          css={css`
            ${tw`text-charcoal m-0 mb-0 font-light leading-tight text-4xl self-start`}
          `}
        >
          Check your inbox!
        </h1>
        <p
          css={css`
            ${tw`text-charcoal`}
          `}
        >
          We emailed a six-digit code to{' '}
          <span
            css={css`
              ${tw`text-charcoal`}
            `}
          >
            email.
          </span>
        </p>
        <p
          css={css`
            ${tw`text-charcoal`}
          `}
        >
          Enter the code bellow to confirm your email address.
        </p>
        <div
          css={css`
            ${tw`flex-col `}
          `}
        >
          <div
            css={css`
              ${tw`flex justify-between mt-10 text-charcoal`}max-width: 21.5rem
            `}
          >
            <label
              css={css`
                ${tw`mt-2`}
              `}
            >
              VERIFICATION CODE
            </label>
            <Button secondary ghost disabled={disabled}>
              Resend Code
            </Button>
          </div>
          <div
            css={css`
              ${tw`mb-16`}
            `}
          >
            <CodeInput
              onChange={() => {
                // console.log('valid code: ', code);
              }}
            />
          </div>
        </div>
        <p
          css={css`
            ${tw`text-charcoal m-0 mb-10`}
          `}
        >
          Make sure to keep this window open while you check your inbox.
        </p>
        <Button secondary disabled>
          Confirm
        </Button>
      </form>
    </div>
  )
}
