import React, { FC } from 'react'
import tw, { css, TwStyle } from 'twin.macro'
import moment from 'moment'

import Spacer from '../../../../../ui-blocks/spacer'
import { Invoice } from '../sections/invoices'
import { EnumWorkspacePlatform_Type } from '../../../../../graphql/components'
import startCase from 'lodash/startCase'
import camelCase from 'lodash/camelCase'

import { ReactComponent as OpenEye } from '../../../../../assets/icon/open-eye.svg'
import { ReactComponent as Download } from '../../../../../assets/icon/download.svg'

export interface InvoicesTableProps {
  invoices: Invoice[]
  onSelectInvoice: (invoice: Invoice) => void
  platform_type: EnumWorkspacePlatform_Type | string
}

const status: { [key: string]: { style: TwStyle; text: string } } = {
  open: {
    style: tw`py-4 text-golden-brown`,
    text: 'Due',
  },
  draft: {
    style: tw`py-4 text-dark-blue-gray italic`,
    text: 'In process',
  },
  paid: {
    style: tw`py-4 text-metallic-seaweed`,
    text: 'Paid',
  },
}

const InvoicesTable: FC<InvoicesTableProps> = ({
  invoices,
  onSelectInvoice,
  platform_type,
}) => (
  <div
    css={css`
      ${tw`w-full`}
    `}
  >
    <table
      css={css`
        ${tw`w-full`}
      `}
    >
      <thead
        css={css`
          ${tw`font-medium text-xs text-dark-blue-gray leading-tight text-left uppercase`}
        `}
      >
        <tr
          css={css`
            ${tw`border-b border-platinum`}
          `}
        >
          <th
            css={css`
              ${tw`pb-4 tracking-widest leading-3 font-medium`}
            `}
          >
            Date
          </th>
          <th
            css={css`
              ${tw`pb-4 tracking-widest leading-3 font-medium`}
            `}
          >
            Subscription Details
          </th>
          <th
            css={css`
              ${tw`pb-4 tracking-widest leading-3 font-medium`}
            `}
          >
            Payment
          </th>
          <th
            css={css`
              ${tw`pb-4 tracking-widest leading-3 font-medium`}
            `}
          >
            Amount
          </th>
          <th
            css={css`
              ${tw`pb-4`}
            `}
          ></th>
        </tr>
      </thead>
      <tbody
        css={css`
          ${tw`font-normal text-charcoal leading-tight`}
        `}
      >
        {invoices.map((invoice: Invoice) => (
          <tr
            key={`invoice-${invoice.id}`}
            css={css`
              ${tw`border-b border-platinum`}
            `}
          >
            <td
              css={css`
                ${tw`py-4`}
              `}
            >
              {moment(invoice.created * 1000).format('DD / MM / YYYY')}
            </td>
            <td
              css={css`
                ${tw`py-4 flex flex-col`}
              `}
            >
              <span
                css={css`
                  ${tw`font-normal leading-snug`}
                `}
              >
                {startCase(camelCase(platform_type))}
              </span>
              <span
                css={css`
                  ${tw`pt-4 text-dark-blue-gray text-xs`}
                `}
              >
                Usefull info
              </span>
            </td>
            <td
              css={css`
                ${status[invoice.status].style}
              `}
            >
              {status[invoice.status].text}
            </td>
            <td
              css={css`
                ${tw`py-4 leading-tight`}
              `}
            >
              € {invoice.total / 100}
            </td>
            <td
              css={css`
                ${tw`py-4`}
              `}
            >
              <div
                css={css`
                  ${tw`flex flex-row items-center justify-end`}
                `}
              >
                <div
                  onClick={() => onSelectInvoice(invoice)}
                  css={css`
                    ${tw`flex flex-row items-center text-purple cursor-pointer`}
                  `}
                >
                  <OpenEye width={12} height={12} />
                  <span
                    css={css`
                      ${tw`ml-2`}
                    `}
                  >
                    View
                  </span>
                </div>
                <Spacer size="2.125rem" direction="horizontal" />
                {/* <div
                  css={css`
                    ${tw`flex flex-row items-center text-dark-blue-gray cursor-pointer`}
                  `}
                >
                  <Download width={12} height={12} />
                  <a
                    css={css`
                      ${tw`ml-2`}
                    `}
                  >
                    Download PDF
                  </a>
                </div> */}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    {/* <PaginationNav /> */}
  </div>
)

export default InvoicesTable
