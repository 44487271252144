import 'twin.macro'
import React, { FC } from 'react'

import Sidebar from './components/sidebar'
import TextSection from './components/text-section'
import ButtonSection from './components/button-section'
import SwitchSection from './components/switch-section'
import CheckboxRadioSection from './components/checkbox-radio-section'
import InputSection from './components/input-section'
import CodeInputSection from './components/code-input-section'
import SearchInputSection from './components/search-input-section'
import DropdownSection from './components/dropdown-section'
import DatePickerSection from './components/date-picker-section'
import HourPickerSection from './components/hour-picker-section'
import ViewControlsSection from './components/view-controls-section'
import MapSection from './components/map-section'

import Container from '../../../../ui-blocks/container'

const elements = [
  { id: 'text', title: 'Text', section: TextSection },
  { id: 'button', title: 'Button', section: ButtonSection },
  {
    id: 'checkbox-radio',
    title: 'Checkbox & Radio',
    section: CheckboxRadioSection,
  },
  { id: 'switch', title: 'Switch', section: SwitchSection },
  { id: 'input', title: 'Input', section: InputSection },
  { id: 'code-input', title: 'Code Input', section: CodeInputSection },
  { id: 'search-input', title: 'Search Input', section: SearchInputSection },
  { id: 'dropdown', title: 'Dropdown', section: DropdownSection },
  { id: 'date-picker', title: 'Date Picker', section: DatePickerSection },
  { id: 'hour-picker', title: 'Hour Picker', section: HourPickerSection },
  { id: 'view-controls', title: 'View Controls', section: ViewControlsSection },
  { id: 'map', title: 'Map', section: MapSection },
]

const Elements: FC = () => (
  <Container>
    <div tw="flex flex-row justify-between">
      <div>
        <h1 tw="font-light leading-tight text-4xl mb-4">Elements</h1>
        <p tw="font-normal mb-10">
          Elements are components we can reutilize within the entire web-app.
          This page lists all the components, one by one, along with all the
          possible states.
        </p>
        {elements.map(({ id, title, section: Section }, index) => (
          <Section
            id={id}
            title={title}
            last={index === elements.length - 1}
            key={`component#${id}`}
          />
        ))}
      </div>
      <div tw="w-full max-w-xs ml-6">
        <Sidebar anchors={elements.map(({ id, title }) => ({ id, title }))} />
      </div>
    </div>
  </Container>
)

export default Elements
