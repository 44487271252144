import React, { FC, InputHTMLAttributes } from 'react'
import tw, { css } from 'twin.macro'

export interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  error?: string
}

const Radio: FC<RadioProps> = ({
  id,
  label,
  error,
  checked,
  disabled,
  ...props
}) => {
  const inputId = id || `radio-${Math.random().toString(36).substring(2, 9)}`
  return (
    <div tw="w-full">
      <div
        css={css`
          ${tw`flex flex-row items-center`}
          &:hover {
            .input-circle {
              ${!disabled && tw`border-purple`}
            }
          }
        `}
      >
        <input
          tw="hidden"
          type="radio"
          id={inputId}
          checked={checked}
          disabled={disabled}
          {...props}
        />
        <div
          className="input-circle"
          css={[
            tw`flex items-center justify-center w-4 h-4 bg-white rounded-full border border-dark-blue-gray transition duration-200 hover:cursor-pointer`,
            !!checked && tw`bg-purple border-purple`,
            !!disabled && tw`hover:cursor-not-allowed`,
            !!checked &&
              !!disabled &&
              tw`bg-dark-blue-gray border-dark-blue-gray`,
          ]}
        >
          {!!checked && (
            <div
              css={[
                'width: 0.375rem;',
                'height: 0.375rem;',
                tw`bg-white rounded-full`,
              ]}
            />
          )}
        </div>
        {!!label && (
          <label
            htmlFor={inputId}
            css={[
              tw`font-normal text-charcoal leading-tight pl-2 hover:cursor-pointer`,
              !!disabled &&
                tw`font-light text-dark-blue-gray hover:cursor-not-allowed`,
            ]}
          >
            {label}
          </label>
        )}
      </div>
      {!!error && (
        <span tw="font-normal text-xs text-brick-red leading-tight block mt-1">
          {error}
        </span>
      )}
    </div>
  )
}

export default Radio
