import React, { ComponentType } from 'react'

import EndTrialModal from '../components/end-trial-modal'

import { authentication } from '../stores'

const withEndTrialModal = (Component: ComponentType) => (props: any) => (
  <>
    <Component {...props} />
    <EndTrialModal
      open={
        authentication.is_authenticated &&
        authentication.is_trial_active &&
        authentication.trial_remaining_days === 0
      }
    />
  </>
)

export default withEndTrialModal
