import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import 'twin.macro'

import Container from '../../../../ui-blocks/container'
import SetupCard from '../../../../ui-blocks/setup-card'

import { ReactComponent as OnboardingDisplays } from '../../../../assets/illustrations/display_empty_state.svg'
import { ReactComponent as OnboardingCreatives } from '../../../../assets/illustrations/creative_empty_state.svg'

export interface InitialSetupProps {
  hasPlayers?: boolean
  hasCreatives?: boolean
}

const InitialSetup: FC<InitialSetupProps> = ({ hasPlayers, hasCreatives }) => {
  const navigate = useNavigate()
  return (
    <Container>
      <div tw="flex flex-col items-center justify-center">
        <h1 tw="font-light text-3xl text-charcoal leading-tight self-center mb-4">
          Campaigns Rock
        </h1>
        <p tw="font-normal text-charcoal self-center text-center max-w-xl">
          Get live by setting your campaigns. Make sure you have your Players
          ready and creatives available to play.
        </p>
      </div>
      <div tw="flex flex-row flex-wrap justify-center mt-14">
        <div tw="m-4">
          <SetupCard
            title="Set up your diffuse box"
            description="Campaigns need a channel to play. Set your Player to get action."
            disabled={!!hasPlayers}
            Image={OnboardingDisplays}
            onButtonClick={() => navigate('/players/bind')}
            buttonText={
              hasPlayers ? 'You already have a player' : 'Bind a Player'
            }
          />
        </div>
        <div tw="m-4">
          <SetupCard
            title="Feed us with your amazing content"
            description="Your first step to get ready to create a campaign."
            disabled={!!hasCreatives}
            Image={OnboardingCreatives}
            onButtonClick={() => navigate('/creatives')}
            buttonText={
              hasCreatives ? 'You already have creatives' : 'Upload Creatives'
            }
          />
        </div>
      </div>
    </Container>
  )
}

export default InitialSetup
