import { string, ref, object } from 'yup'
import { ForgotPasswordMethod } from '../../../graphql/components'
import { Step } from './typings'

const passwordSchema = string()
  .required('You need to fill in a password')
  .min(6, 'Your password must be have at least 6 characters')
  .matches(
    /[A-Z]/,
    'Your password must be have at least a upper case character'
  )
  .matches(
    /[a-z]/,
    'Your password must be have at least a lower case character'
  )
  .matches(/[0-9]/, 'Your password must be have at least a number')

const validationSchema = object().shape({
  step: string().required().oneOf(Object.values(Step)),
  method: string().required().oneOf(Object.values(ForgotPasswordMethod)),

  // SendCode
  email: string().when('type', {
    is: ForgotPasswordMethod.Email,
    then: string().email('Must be a valid email address').required(),
  }),
  phoneNumber: string().when('type', {
    is: ForgotPasswordMethod.Phone,
    then: string()
      .matches(/9[1236]\d{7}/, 'Must have 9 digits')
      .required(),
  }),

  // VerifyCode
  code: string().when('step', {
    is: Step.VerifyCode,
    then: string()
      .required()
      .matches(/\d{6}/, 'Must have 6 alpha numeric digits')
      .required(),
  }),

  // ResetPassword
  recoveryToken: string().when('step', {
    is: Step.ResetPassword,
    then: string().required(),
  }),
  password: string().when('step', {
    is: Step.ResetPassword,
    then: passwordSchema.clone(),
  }),
  passwordConfirmation: string().when('step', {
    is: Step.ResetPassword,
    then: passwordSchema
      .clone()
      .oneOf([ref('password')], "Passwords don't match"),
  }),
})

export default validationSchema
