import { useState } from 'react'

const useElementStates = (names: string[]) => {
  const [values, setValues] = useState(
    names.reduce<{ [key: string]: boolean }>((acc, name) => {
      acc[name] = false
      return acc
    }, {})
  )

  const states = Object.keys(values).map((key) => ({
    name: key,
    value: values[key],
    setValue: () => setValues({ ...values, [key]: !values[key] }),
  }))

  return {
    states,
    values,
    setValues,
  }
}

export default useElementStates
