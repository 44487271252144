import React, { FC } from 'react'
import 'twin.macro'

import { useNavigate, useParams } from 'react-router-dom'

import WebhookForm, { WebhookFormikValues } from '../components/webhook-form'

import {
  useViewWebhookQuery,
  useUpdateWebhookByIdMutation,
} from '../../../../../graphql/components'

import { alertsManager } from '../../../../../stores'
import { AlertVariant } from '../../../../../stores/alerts-manager'

import { trimValues } from '../../../../../utils/data-manipulation'

const WorkspaceSettingsEditWebhookPage: FC = () => {
  const navigate = useNavigate()
  const { webhook_id, workspace_id } = useParams()

  const [
    updateWebhook,
    { loading: updatingWebhook },
  ] = useUpdateWebhookByIdMutation()
  const { data: webhookData, loading: loadingWebhook } = useViewWebhookQuery({
    variables: { filter: { _id: webhook_id } },
  })

  const onSubmit = async (values: WebhookFormikValues) => {
    try {
      const newValues = trimValues(values, ['url'])
      const res = await updateWebhook({
        variables: { _id: webhook_id, input: newValues },
      })
      if ((res.errors || []).length > 0) return handleError()
      navigate(`/workspaces/${workspace_id}/settings/webhooks`, {
        state: { refetch: true },
      })
    } catch {
      handleError()
    }
  }

  const handleError = () => {
    alertsManager.emit({
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'update-webhook-error-alert',
      message: "We couldn't update this webhook. Please try again later.",
    })
  }

  return (
    <section>
      <h2 tw="font-medium text-charcoal leading-tight mb-2">Edit webhook</h2>
      <p tw="font-normal text-xs text-dark-blue-gray leading-tight mb-6">
        Edit an existing webhook.
      </p>
      <WebhookForm
        onSubmit={onSubmit}
        disabled={loadingWebhook}
        submitting={updatingWebhook}
        initialValues={{ url: webhookData?.webhook?.url || '' }}
      />
    </section>
  )
}

export default WorkspaceSettingsEditWebhookPage
