import React, { FC } from 'react'
import tw, { css } from 'twin.macro'

const Container: FC = ({ children }) => {
  return (
    <div
      css={css`
        ${tw`w-full flex flex-col px-16 py-10`}
      `}
    >
      {children}
    </div>
  )
}

export default Container

// div
//       css={css`
//         ${tw`w-full flex flex-col px-16 py-12`}
//       `}
