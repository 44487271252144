import React, { FC, useMemo } from 'react'

import 'twin.macro'
import _ from 'lodash'

import { useFormik } from 'formik'
import { string, object, array } from 'yup'

import Search from '../ui-blocks/search'
import Spinner from '../ui-blocks/spinner'
import Suspense from '../ui-blocks/suspense'
import ModalAction from '../ui-blocks/modal-action'
import PaginationNav from '../ui-blocks/pagination-nav'

import LoadingPage from './loading-page'
import CreativeSelectCard from './creative/select-card'

import { ReactComponent as NoSearchResults } from '../assets/illustrations/campaign_3-11_new_5_add_creative_no_results.svg'

import {
  EnumCreativeStatus,
  useListCreativesQuery,
  useViewCampaignAdGroupQuery,
  useUpdateCampaignAdGroupByIdMutation,
} from '../graphql/components'

import usePagination from '../utils/use-pagination'
import { useSearchTerms } from '../utils/use-search'

import { alertsManager } from '../stores'
import { AlertVariant } from '../stores/alerts-manager'

interface ManageCreativesFormikValues {
  creatives_ids: string[]
}

const manageCreativesValidationSchema = object().shape({
  creatives_ids: array().ensure().of(string()).min(1),
})

export interface ManageCreativesModalProps {
  open?: boolean
  adGroupId: string
  onClose?: () => void
  refetchData?: () => void
}

const ManageCreativesModal: FC<ManageCreativesModalProps> = ({
  open,
  adGroupId,
  onClose,
  refetchData,
}) => {
  const [
    updateAdGroup,
    { loading: updatingAdGroup },
  ] = useUpdateCampaignAdGroupByIdMutation()
  const {
    data: adGroupData,
    loading: loadingAdGroup,
    refetch: refetchAdGroup,
  } = useViewCampaignAdGroupQuery({
    skip: !adGroupId,
    variables: { _id: adGroupId },
  })

  const adGroup = adGroupData?.campaignAdGroup
  const formik = useFormik<ManageCreativesFormikValues>({
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: manageCreativesValidationSchema,
    initialValues: {
      creatives_ids: adGroup?.creatives?.map((creative) => creative._id) || [],
    },
    async onSubmit({ creatives_ids }) {
      try {
        const res = await updateAdGroup({
          variables: {
            _id: adGroupId,
            input: { creatives_ids },
          },
        })
        if ((res.errors || []).length > 0) {
          handleError()
        } else {
          refetchData?.()
          refetchAdGroup()
          onCloseModal(false)
        }
      } catch {
        handleError()
      }
    },
  })

  const { searchText, setSearchText, searchTextDebounced } = useSearchTerms()
  const {
    data: creativesData,
    loading: loadingCreatives,
  } = useListCreativesQuery({
    variables: {
      filter: { search: searchTextDebounced },
    },
  })

  const allCreatives = useMemo(
    () =>
      (creativesData?.creatives || []).filter(
        (creative) => creative.status !== EnumCreativeStatus.Archived
      ),
    [creativesData]
  )

  const pagination = usePagination(allCreatives.length, 9)
  const creativeGroups = useMemo(
    () => _.chunk(allCreatives, pagination.limit),
    [allCreatives, pagination]
  )
  const visibleCreatives = useMemo(
    () => creativeGroups[pagination.page] || [],
    [creativeGroups, pagination]
  )

  const onCloseModal = (reset = true) => {
    if (updatingAdGroup) return
    reset && formik.resetForm()
    onClose?.()
  }

  const handleError = () => {
    alertsManager.emit({
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'update-ad-group-error-alert',
      message: "We couldn't update this ad group. Please try again later.",
    })
  }

  const renderCreatives = () => (
    <div tw="pb-4">
      <div tw="flex flex-row flex-wrap">
        {visibleCreatives.map((creative) => (
          <div key={creative._id} tw="w-full max-w-1/3 px-2 mb-4">
            <CreativeSelectCard
              name="creatives_ids"
              value={creative._id}
              creative={creative}
              formik={formik}
            />
          </div>
        ))}
      </div>
      <PaginationNav
        page={pagination.page}
        count={pagination.count}
        limit={pagination.limit}
        setPage={pagination.setPage}
      />
    </div>
  )

  const renderNoCreatives = () => (
    <div tw="flex flex-col items-center pb-4">
      <NoSearchResults />
      <p tw="font-medium text-charcoal leading-tight mt-6 mb-2">
        No data to show
      </p>
      <span tw="font-normal text-charcoal leading-tight">
        {!!searchTextDebounced
          ? `We can’t find any creative for "${searchTextDebounced}". Please check your spelling and try again.`
          : 'We can’t find any creative.'}
      </span>
    </div>
  )

  return (
    <ModalAction
      open={open}
      loading={updatingAdGroup}
      onConfirm={formik.submitForm}
      confirmDisabled={!formik.dirty}
      onCancel={() => onCloseModal()}
      onBackdropClick={() => onCloseModal()}
      confirmButtonText="Accept changes"
      width="72rem"
    >
      <Suspense ready={!loadingAdGroup} fallback={<LoadingPage />}>
        <form onChange={formik.handleChange}>
          <h1 tw="font-light text-3xl text-charcoal leading-tight mb-2">
            Manage creatives
          </h1>
          <p tw="font-normal text-dark-blue-gray leading-tight mb-10">
            Add or remove creatives.
          </p>
          <div tw="flex flex-row items-end justify-between mb-6">
            <span tw="font-normal text-xs text-dark-blue-gray leading-tight tracking-wider uppercase">
              {`${pagination.count} Creative(s), ${formik.values.creatives_ids.length} Selected`}
            </span>
            <div tw="w-full max-w-xs">
              <Search
                value={searchText}
                loading={loadingCreatives}
                entries={allCreatives.map((creative) => creative.name)}
                onSelect={(value) =>
                  setSearchText(!Array.isArray(value) ? value || '' : '')
                }
              />
            </div>
          </div>
          <div tw="mb-6">
            <Suspense ready={!loadingCreatives} fallback={<Spinner center />}>
              <Suspense
                ready={allCreatives.length > 0}
                fallback={renderNoCreatives()}
              >
                {renderCreatives()}
              </Suspense>
            </Suspense>
          </div>
        </form>
      </Suspense>
    </ModalAction>
  )
}

export default ManageCreativesModal
