import React, { FC } from 'react'

import 'twin.macro'
import { Link } from 'react-router-dom'

import { ReactComponent as Caret } from '../assets/icon/caret/caret_gray.svg'

interface Breadcrumb {
  label: string
  linkTo?: string
}

export interface BreadcrumbNavProps {
  crumbs: Breadcrumb[]
}

const BreadcrumbNav: FC<BreadcrumbNavProps> = ({ crumbs, ...props }) => (
  <ul tw="w-full flex flex-row items-center mb-2 font-medium text-xs text-dark-blue-gray">
    {crumbs.map(({ label, linkTo }, index) => (
      <li key={`crumb#${index}`} tw="flex flex-row items-center">
        {!!linkTo ? (
          <Link
            to={linkTo}
            tw="transition duration-200 no-underline hover:text-purple hover:underline"
          >
            {label}
          </Link>
        ) : (
          <p>{label}</p>
        )}
        {index !== crumbs.length - 1 && (
          <Caret height={12} tw="transform -rotate-90" />
        )}
      </li>
    ))}
  </ul>
)

export default BreadcrumbNav
