import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

import tw, { css } from 'twin.macro'

import AlertMessage from './alert-component'
import AlertsManager from '../stores/alerts-manager'

@inject('alertsManager')
@observer
export default class AlertsManagerComponent extends Component<{
  alertsManager?: AlertsManager
}> {
  public render() {
    const { alertsManager } = this.props
    const alerts = alertsManager?.alerts || []

    return (
      <div
        css={css`
          z-index: 999;
          ${tw`fixed bottom-0 right-0 mr-4 mb-4`}
        `}
      >
        {alerts.map((alert, index) => (
          <div
            key={alert.id}
            css={css`
              ${index !== alerts.length - 1 && tw`mb-4`}
            `}
          >
            <AlertMessage alert={alert} />
          </div>
        ))}
      </div>
    )
  }
}
