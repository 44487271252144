import React, { FC, useState } from 'react'

import 'twin.macro'
import { useNavigate } from 'react-router'

import Button from '../../../ui-blocks/button'
import Spinner from '../../../ui-blocks/spinner'
import WorkspaceInvite from './components/workspace-invite'

import {
  Workspace,
  useListWorkspacesQuery,
  useAcceptWorkspaceInvitesMutation,
  useRejectWorkspaceInvitesMutation,
} from '../../../graphql/components'

import { authentication } from '../../../stores'

type InviteAction = 'accept' | 'decline' | ''
const InviteManagement: FC = () => {
  const navigate = useNavigate()

  const invites = authentication.user?.invites || []
  const workspacesIds = invites.map((invite) => invite?.workspace_id)

  // @NOTE:
  // - This probably won't stay this way
  // - The info should probaly be on the invites themselves
  const {
    data: workspacesData,
    loading: loadingWorkspaces,
  } = useListWorkspacesQuery({
    skip: !workspacesIds.length,
    variables: { _ids: workspacesIds },
  })
  const workspaces = (workspacesData?.workspaces || []).reduce<{
    [key: string]: Workspace
  }>((acc, workspace) => ({ ...acc, [workspace._id]: workspace }), {})

  const [errors, setErrors] = useState<{ [key: string]: string }>({})
  const [loaders, setLoaders] = useState<{ [key: string]: InviteAction }>({})

  const [acceptWorkspaceInvites] = useAcceptWorkspaceInvitesMutation()
  const [rejectWorkspaceInvites] = useRejectWorkspaceInvitesMutation()

  const onProcessInvite = async (
    action: InviteAction,
    inviteId: string,
    mutation: (options?: any) => Promise<any>,
    errorMessage: string
  ) => {
    setErrors({ ...errors, [inviteId]: '' })
    setLoaders({ ...loaders, [inviteId]: action })
    const { data } = await mutation({ variables: { ids: [inviteId] } })

    setLoaders({ ...loaders, [inviteId]: '' })
    if (data?.invites.length === 0)
      setErrors({ ...errors, [inviteId]: errorMessage })
  }

  const onAcceptInvite = async (inviteId: string) => {
    await onProcessInvite(
      'accept',
      inviteId,
      acceptWorkspaceInvites,
      'A problem occurred while accepting the invite. Please try again'
    )
    await authentication.loadUserData()
  }

  const onRejectInvite = async (inviteId: string) => {
    await onProcessInvite(
      'decline',
      inviteId,
      rejectWorkspaceInvites,
      'A problem occurred while rejecting the invite. Please try again'
    )
    await authentication.loadUserData()
  }

  return (
    <form>
      <h1 tw="text-charcoal m-0 mb-0 font-light leading-tight text-4xl self-start">
        You've been invited
      </h1>
      <div tw="text-charcoal mb-10">
        <p>
          While you were gone the following teams invited you to colaborate.
        </p>
      </div>
      <p tw="text-charcoal font-bold">Workspaces</p>
      <p tw="text-charcoal mb-4 mt-4">{invites.length} PENDING INVITATIONS</p>
      <div>
        {loadingWorkspaces ? (
          <div tw="w-full flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          invites.map(
            (invite, index) =>
              invite && (
                <div key={`invite#${index}`} tw="mt-4">
                  <WorkspaceInvite
                    name={workspaces[invite?.workspace_id].name}
                    loadingAccept={loaders[invite._id] === 'accept'}
                    loadingDecline={loaders[invite._id] === 'decline'}
                    members={workspaces[invite?.workspace_id].members?.length}
                    onAccept={() => onAcceptInvite(invite._id)}
                    onDecline={() => onRejectInvite(invite._id)}
                  />
                  {errors[invite._id] && (
                    <span tw="font-normal text-xs text-brick-red leading-tight block mt-1">
                      {errors[invite._id]}
                    </span>
                  )}
                </div>
              )
          )
        )}
      </div>
      <div tw="mb-6 mt-6">
        {/* ACRESCENTAR PATH */}
        <Button
          type="button"
          tw="w-auto inline p-0 mt-10"
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
      </div>
    </form>
  )
}

export default InviteManagement
