import React, { FC } from 'react'
import 'twin.macro'

import ModalBase from '../../../../ui-blocks/modal-base'

import UploadPreview from '../../../../components/upload/preview'

import { Upload } from '../../../../graphql/components'

export interface PreviewModalProps {
  upload?: Upload
  onClose?: () => void
}

const PreviewModal: FC<PreviewModalProps> = ({ upload, onClose }) => (
  <ModalBase open={!!upload} fillContent onBackdropClick={onClose}>
    <div tw="rounded overflow-hidden">
      <UploadPreview upload={upload} />
    </div>
  </ModalBase>
)

export default PreviewModal
