import React, { FC, useEffect, useMemo, useState } from 'react'

import tw from 'twin.macro'
import { useNavigate } from 'react-router'

import { ObjectSchema } from 'yup'
import { Form, Formik, FormikHelpers, FormikValues } from 'formik'

import Text from '../../ui-blocks/text'
import Card from '../../ui-blocks/card'
import Button from '../../ui-blocks/button'

import StepSection from './components/step-section'
import StepIndicator from './components/step-indicator'

export interface Step {
  title: string
  content: React.ReactNode
  validationSchema?: ObjectSchema
  description?: string | React.ReactNode
  disabledTriggers?: string[]
  subSteps?: string[]
  canGoBack: boolean
}

export interface SetupFormProps {
  title: string
  steps: Step[]
  initialValues: FormikValues
  initialStepIndex?: number
  description?: string | React.ReactNode
  onSubmit?: { [key: number]: (values: any) => Promise<boolean> }
}

const SetupForm: FC<SetupFormProps> = ({
  title,
  steps,
  description,
  initialValues,
  initialStepIndex,
  onSubmit,
}) => {
  const navigate = useNavigate()

  const [currentStepIndex, setCurrentStepIndex] = useState<number>(
    initialStepIndex ?? 0
  )
  useEffect(() => setCurrentStepIndex(initialStepIndex ?? 0), [
    initialStepIndex,
  ])

  const handleClickBack = () => {
    if (currentStepIndex === 0) return navigate(-1)
    setCurrentStepIndex(currentStepIndex - 1)
  }

  const handleSubmit = async (values: any, helpers: FormikHelpers<any>) => {
    let canProceed = true
    if (!!onSubmit?.[currentStepIndex]) {
      canProceed = await onSubmit[currentStepIndex](values)
    }

    if (canProceed) {
      setCurrentStepIndex(currentStepIndex + 1)
      helpers.setSubmitting(false)
      helpers.setTouched({})
    }
  }

  const currentStep = useMemo(() => steps[currentStepIndex], [
    currentStepIndex,
    steps[currentStepIndex].disabledTriggers,
  ])
  const isSubmitDisabled = (values: FormikValues) =>
    !!currentStep.disabledTriggers?.some((trigger) =>
      Array.isArray(values[trigger])
        ? !values[trigger].length
        : !values[trigger]
    )

  return (
    <Card tw="h-full flex flex-row">
      <aside
        css={[
          'flex-basis: 19rem;',
          tw`flex flex-col px-8 py-10 bg-lighter-blue flex-shrink-0 flex-grow-0`,
        ]}
      >
        <Text as="h1" preset="h3" tw="mb-2">
          {title}
        </Text>
        {typeof description === 'string' ? (
          <Text as="h2" preset="p1" tw="text-dark-blue-gray mb-14">
            {description}
          </Text>
        ) : (
          description
        )}
        {steps.map((step, index) => (
          <StepIndicator
            key={`indicator#${index}`}
            index={index + 1}
            label={step.title}
            subSteps={step.subSteps}
            isLast={index === steps.length - 1}
            state={
              (index < currentStepIndex && 'done') ||
              (index === currentStepIndex && 'current') ||
              'todo'
            }
          />
        ))}
      </aside>
      {!!currentStep && (
        <Formik
          enableReinitialize
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={currentStep.validationSchema}
        >
          {({ dirty, values, isSubmitting, isValidating }) => (
            <Form tw="px-8 py-10 flex flex-col flex-grow justify-between">
              <StepSection
                index={currentStepIndex + 1}
                title={currentStep.title}
                content={currentStep.content}
                description={currentStep.description}
              />
              <div tw="flex flex-row items-center justify-between mt-10">
                <Button
                  ghost
                  type="button"
                  tw="p-0 min-w-0"
                  onClick={handleClickBack}
                  disabled={isSubmitting || !currentStep.canGoBack}
                >
                  {currentStepIndex === 0 ? 'Cancel' : 'Back'}
                </Button>
                <Button
                  type="submit"
                  loading={isValidating || isSubmitting}
                  disabled={
                    !!currentStep.validationSchema &&
                    (!dirty || isSubmitDisabled(values))
                  }
                >
                  {currentStepIndex === steps.length - 1 ? 'Done' : 'Next'}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </Card>
  )
}

export default SetupForm
