import React, { FC } from 'react'
import tw, { css } from 'twin.macro'
import { IoMdClose } from 'react-icons/io'

const Pill: FC<{
  text: string
  onDelete?: () => void
  disabled?: boolean
}> = ({ text, onDelete, disabled }) => (
  <div
    css={css`
      ${tw`flex items-center bg-dark-blue-gray text-white rounded h-8 p-2 `}
      ${(!!disabled && tw`opacity-75 cursor-not-allowed`) || ``}
    `}
  >
    <span
      css={css`
        ${tw`select-none`}
      `}
    >
      {text}
    </span>
    <IoMdClose
      onClick={() => {
        if (!disabled && onDelete != null) {
          onDelete()
        }
      }}
      css={css`
        ${tw` ml-1 right-0`}
      `}
    />
  </div>
)

export default Pill
