import React, { FC, useEffect } from 'react'
import 'twin.macro'

import { useFormik } from 'formik'
import { string, object } from 'yup'

import Button from '../../../../ui-blocks/button'
import { InputFormik } from '../../../../ui-blocks/input'

import AlertMessage, {
  AlertVariant,
} from '../../../../components/alert-component'

import { useUpdateWorkspaceByIdMutation } from '../../../../graphql/components'
import { MutationResult } from '../../../../typings'

import { trimValues } from '../../../../utils/data-manipulation'

import { authentication } from '../../../../stores'

interface UpdateDetailsFormikValues {
  name: string
}

const updateDetailsValidationSchema = object().shape({
  name: string()
    .trim('Value cannot have leading or trailing white spaces')
    .required('Name is required')
    .strict(true),
})

let workspaceDetailsResult: MutationResult = null
const WorkspaceSettingsDetailsPage: FC = () => {
  useEffect(() => {
    if (!!workspaceDetailsResult) workspaceDetailsResult = null
    return () => {
      workspaceDetailsResult = null
    }
  }, [])

  const [updateWorkspace] = useUpdateWorkspaceByIdMutation()
  const formik = useFormik<UpdateDetailsFormikValues>({
    validateOnChange: false,
    validationSchema: updateDetailsValidationSchema,
    initialValues: { name: authentication.selected_workspace.name },
    async onSubmit(values) {
      const newValues = trimValues(values, ['name'])
      workspaceDetailsResult = null

      try {
        const { errors } = await updateWorkspace({
          variables: {
            _id: authentication.selected_workspace._id,
            details: newValues,
          },
        })
        if ((errors || []).length > 0) {
          workspaceDetailsResult = 'error'
          return
        }

        workspaceDetailsResult = 'success'
        await authentication.loadUserData()
      } catch {
        workspaceDetailsResult = 'error'
      }
    },
  })

  return (
    <form onChange={formik.handleChange} onSubmit={formik.handleSubmit}>
      {!!workspaceDetailsResult && (
        <div tw="mb-4">
          <AlertMessage
            alert={{
              dismissable: false,
              id: 'update-workspace-details-error-alert',
              variant:
                workspaceDetailsResult === 'error'
                  ? AlertVariant.ERROR
                  : AlertVariant.SUCCESS,
              message:
                workspaceDetailsResult === 'error'
                  ? "We couldn't update your workspace details. Please try again later."
                  : 'Workspace details successfully updated!',
            }}
          />
        </div>
      )}
      <h2 tw="font-medium text-charcoal leading-tight mb-2">
        Workspace information
      </h2>
      <p tw="font-normal text-xs text-dark-blue-gray leading-tight mb-6">
        You can use your name (best suited for solo) or the name of your
        company.
      </p>
      <div tw="max-w-xs mb-10">
        <InputFormik
          required
          type="text"
          name="name"
          label="Name"
          formik={formik}
        />
      </div>
      <Button
        type="submit"
        disabled={!formik.dirty}
        loading={formik.isSubmitting}
      >
        Update workspace details
      </Button>
    </form>
  )
}

export default WorkspaceSettingsDetailsPage
