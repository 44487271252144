import React, { Fragment, useState } from 'react'

import 'twin.macro'
import _ from 'lodash'

import {
  Row,
  RowCell,
  FullRow,
  TableHeader,
  TableColumn,
  HeaderColumn,
  generateTableComponent,
} from '../../ui-blocks/table'
import Status from '../../ui-blocks/status'
import Switch from '../../ui-blocks/switch'

import Address from '../address'
import AreaType from '../area-type'
import NoResults from '../no-results'
import InlineDate from '../inline-date'
import NotDefined from '../not-defined'
import VenueTypes from '../venue-types'
import LoadingPage from '../loading-page'

import { ReactComponent as NoResultsIllustration } from '../../assets/illustrations/campaign_2-1_no_results.svg'

import {
  Player,
  EnumPlayerStatus,
  useUpdatePlayerActivationMutation,
} from '../../graphql/components'
import { SortSettings } from '../../typings'

import { alertsManager } from '../../stores'
import { AlertVariant } from '../../stores/alerts-manager'

const COLUMNS: TableColumn[] = [
  { key: 'switch', label: '', isSortable: false, component: Fragment },
  { key: 'name', label: 'Name', isSortable: true, component: Fragment },
  {
    key: 'status',
    label: 'Status',
    isSortable: true,
    prop: 'value',
    component: Status,
  },
  {
    key: 'stats[0].reach',
    label: 'Impressions',
    isSortable: true,
    component: Fragment,
  },
  {
    key: 'stats[0].engagement',
    label: 'Scans',
    isSortable: true,
    component: Fragment,
  },
  {
    key: 'geographics.address',
    label: 'Address',
    isSortable: false,
    prop: 'address',
    component: Address,
  },
  {
    key: 'settings.screen_orientation',
    label: 'Orientation',
    isSortable: true,
    component: Fragment,
  },
  {
    key: 'demographics.area_type_id',
    label: 'Area Type',
    isSortable: true,
    prop: 'areaTypeId',
    component: AreaType,
  },
  {
    key: 'demographics.venue_type_ids',
    label: 'Venue Types',
    isSortable: false,
    prop: 'venueTypeIds',
    component: VenueTypes,
  },
  {
    key: 'created.at',
    label: 'Created On',
    isSortable: true,
    prop: 'date',
    component: InlineDate,
  },
]

type PartialPlayer = Pick<
  Player,
  | '_id'
  | 'name'
  | 'status'
  | 'geographics'
  | 'demographics'
  | 'settings'
  | 'telemetry'
  | 'created'
>

export const PlayersListTable = generateTableComponent<PartialPlayer>({
  HeaderComponent({ onSort }) {
    const [selectedColumn, setSelectedColumn] = useState<string>('')

    const handleClick = (settings: SortSettings) => {
      setSelectedColumn(settings.column)
      onSort?.(settings)
    }

    return (
      <TableHeader>
        {COLUMNS.map((col, index) => (
          <HeaderColumn
            key={`header-column#${index}`}
            isSortable={col.isSortable}
            isSelected={selectedColumn === col.key}
            onClick={(isAscending) =>
              handleClick({ column: col.key, isAscending })
            }
          >
            {col.label}
          </HeaderColumn>
        ))}
      </TableHeader>
    )
  },

  EmptyComponent({ searchQuery }) {
    return (
      <FullRow>
        <NoResults
          tw="mt-8"
          model="Player"
          searchQuery={searchQuery}
          Illustration={NoResultsIllustration}
        />
      </FullRow>
    )
  },

  LoadingComponent() {
    return (
      <FullRow>
        <LoadingPage />
      </FullRow>
    )
  },

  ErrorComponent() {
    return null
  },

  RowComponent({ datum: player }) {
    const { _id, status } = player

    const handleUpdateError = (activate: boolean) => {
      alertsManager.emit({
        dismissable: true,
        variant: AlertVariant.ERROR,
        id: 'update-player-status-error-alert',
        message: `We couldn't ${
          activate ? 'activate' : 'deactivate'
        } this player. Please try again.`,
      })
    }

    const [updatePlayerActivation] = useUpdatePlayerActivationMutation()
    const onUpdatePlayerActivation = async (activate: boolean) => {
      try {
        const res = await updatePlayerActivation({
          variables: { ids: [_id], is_active: activate },
        })
        if (!res.data?.areUpdated) return handleUpdateError(activate)
        document.dispatchEvent(new CustomEvent('refetch-players'))
      } catch {
        handleUpdateError(activate)
      }
    }

    const isSwitchDisabled =
      status === EnumPlayerStatus.Binded ||
      status === EnumPlayerStatus.Registered ||
      status === EnumPlayerStatus.Archived
    return (
      <Row>
        {COLUMNS.map(
          ({ key, prop, transform, component: Component }, index) => {
            const value = _.get(player, key, undefined)
            return (
              <RowCell
                key={`row-cell#${index}`}
                linkTo={
                  key === 'name' && status !== EnumPlayerStatus.Archived
                    ? `/players/${_id}`
                    : undefined
                }
              >
                {key === 'switch' ? (
                  <Switch
                    disabled={isSwitchDisabled}
                    initialChecked={status === EnumPlayerStatus.Activated}
                    onSelect={onUpdatePlayerActivation}
                  />
                ) : (
                  <Component
                    {...(!!prop ? { [prop]: value } : {})}
                    {...(!!transform
                      ? { css: [`text-transform: ${transform};`] }
                      : {})}
                  >
                    {!prop &&
                      ((Array.isArray(value) ? value.length : value) ?? (
                        <NotDefined />
                      ))}
                  </Component>
                )}
              </RowCell>
            )
          }
        )}
      </Row>
    )
  },
})
