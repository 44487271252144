import React, { FC } from 'react'
import tw, { css } from 'twin.macro'

interface StateToggle {
  name: string
  value: boolean
  setValue: () => void
}

const StateToggles: FC<{ toggles: StateToggle[] }> = ({ toggles }) => {
  const renderToggle = (toggle: StateToggle) => {
    const id = `input-${toggle.name}-${Math.random()
      .toString(36)
      .substring(2, 9)}`
    return (
      <div key={id} tw="flex flex-row items-center mr-4">
        <input
          id={id}
          tw="mr-1"
          type="checkbox"
          checked={toggle.value}
          onChange={toggle.setValue}
        />
        <label htmlFor={id} tw="capitalize font-normal leading-tight">
          {toggle.name}
        </label>
      </div>
    )
  }

  return (
    <div
      css={css`
        ${tw`flex flex-row flex-wrap`}
        margin-bottom: 2.75rem;
      `}
    >
      {toggles.length
        ? toggles.map((toggle) => renderToggle(toggle))
        : 'No states available!'}
    </div>
  )
}

export default StateToggles
