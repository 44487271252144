import React, { FC, useEffect, useState } from 'react'

import tw from 'twin.macro'
import moment from 'moment'

import { useFormik } from 'formik'
import { date, string, object, number } from 'yup'

import Spacer from '../../../../../ui-blocks/spacer'
import Suspense from '../../../../../ui-blocks/suspense'
import Checkbox from '../../../../../ui-blocks/checkbox'
import Dropdown from '../../../../../ui-blocks/dropdown'
import ModalAction from '../../../../../ui-blocks/modal-action'
import CardResource from '../../../../../ui-blocks/card-resource'
import Input, { InputFormik } from '../../../../../ui-blocks/input'
import { DateTimeRangePicker } from '../../../../../ui-blocks/datetime-picker'

import LoadingPage from '../../../../../components/loading-page'

import {
  SATURATION_TYPES,
  SATURATION_LABELS,
  SATURATION_HELPER_TEXTS,
} from '../../create/steps/schedule'
import { AUDIENCE_AREA_TYPES } from '../../create/steps/audience'

import {
  TimeframeInput,
  CampaignAdGroupAudienceInput,
  useViewCampaignAdGroupQuery,
  useUpdateCampaignAdGroupByIdMutation,
  EnumCampaignAdGroupAudienceArea_Type,
} from '../../../../../graphql/components'

import { alertsManager } from '../../../../../stores'
import { AlertVariant } from '../../../../../stores/alerts-manager'

import { trimValues } from '../../../../../utils/data-manipulation'

interface EditAdGroupFormikValues {
  name: string
  redirect_to: string
  saturation?: number | string
  timeframe: TimeframeInput
  audience: CampaignAdGroupAudienceInput
}

const editAdGroupValidationSchema = object().shape({
  name: string()
    .trim('Value cannot have leading or trailing white spaces')
    .required('Name is required')
    .strict(true),
  saturation: number()
    .positive('Must be a number greater than zero')
    .nullable(),
  redirect_to: string()
    .trim('Value cannot have leading or trailing white spaces')
    .url('Must be a valid URL')
    .required('Redirect to is required')
    .strict(true),
  timeframe: object()
    .shape({
      starts_at: date().required('Start date is required'),
      ends_at: date().required('End date is required'),
    })
    .required(),
  audience: object().shape({
    area_type: string()
      .required()
      .oneOf([
        EnumCampaignAdGroupAudienceArea_Type.Indoor,
        EnumCampaignAdGroupAudienceArea_Type.Outdoor,
        EnumCampaignAdGroupAudienceArea_Type.Both,
      ]),
  }),
})

export interface EditCampaignModalProps {
  open?: boolean
  adGroupId: string
  onClose?: () => void
  refetchData?: () => void
}

const EditAdGroupModal: FC<EditCampaignModalProps> = ({
  open,
  adGroupId,
  onClose,
  refetchData,
}) => {
  const [
    updateAdGroup,
    { loading: updatingAdGroup },
  ] = useUpdateCampaignAdGroupByIdMutation()
  const {
    data: adGroupData,
    loading: loadingAdGroup,
    refetch: refetchAdGroup,
  } = useViewCampaignAdGroupQuery({
    skip: !adGroupId,
    variables: { _id: adGroupId },
  })

  const [multiplierValue, setMultiplierValue] = useState<number | ''>('')
  const [percentageValue, setPercentageValue] = useState<number | ''>('')
  const [selectedSaturation, setSelectedSaturation] = useState<string>(
    SATURATION_TYPES.MULTIPLIER
  )

  const adGroup = adGroupData?.campaignAdGroup
  const [isSaturationEnabled, setIsSaturationEnabled] = useState<boolean>(
    !!adGroup?.saturation
  )

  useEffect(() => {
    if (!!adGroup) {
      setIsSaturationEnabled(!!adGroup.saturation)
      if (!!adGroup.saturation) {
        setMultiplierValue(adGroup.saturation)
        setPercentageValue(adGroup.saturation * 100)
      }
    }
  }, [adGroup])

  const formik = useFormik<EditAdGroupFormikValues>({
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: editAdGroupValidationSchema,
    initialValues: {
      name: adGroup?.name || '',
      saturation: adGroup?.saturation || '',
      redirect_to: adGroup?.redirect_to || '',
      timeframe: {
        starts_at: adGroup?.timeframe?.starts_at || undefined,
        ends_at: adGroup?.timeframe?.ends_at || undefined,
      },
      audience: {
        geofences: [],
        venue_type_ids: [],
        area_type:
          adGroup?.audience?.area_type ||
          EnumCampaignAdGroupAudienceArea_Type.Indoor,
      },
    },
    async onSubmit(values) {
      try {
        const newValues = trimValues(values, ['name', 'redirect_to'])
        const res = await updateAdGroup({
          variables: {
            _id: adGroupId,
            input: {
              ...newValues,
              saturation:
                !!newValues.saturation &&
                typeof newValues.saturation === 'number'
                  ? newValues.saturation
                  : null,
            },
          },
        })
        if ((res.errors || []).length > 0) {
          handleError()
        } else {
          refetchData?.()
          refetchAdGroup()
          onCloseModal(false)
        }
      } catch {
        handleError()
      }
    },
  })

  const onCloseModal = (reset = true) => {
    if (updatingAdGroup) return
    reset && formik.resetForm()
    onClose?.()
  }

  const handleError = () => {
    alertsManager.emit({
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'update-ad-group-error-alert',
      message: "We couldn't update this ad group. Please try again later.",
    })
  }

  const handleSaturation = (prevSaturation: boolean) => {
    prevSaturation && formik.setFieldValue('saturation', '')
    return !prevSaturation
  }

  const handleChangeSaturation = (e: React.ChangeEvent<HTMLInputElement>) => {
    let actualValue: number | '' = '',
      percentageValue: number | '' = ''
    if (!!e.target.value) {
      const inputValue = Number(e.target.value)
      actualValue =
        selectedSaturation === SATURATION_TYPES.MULTIPLIER
          ? inputValue
          : inputValue / 100
      percentageValue =
        selectedSaturation === SATURATION_TYPES.MULTIPLIER
          ? actualValue * 100
          : inputValue
    }

    setMultiplierValue(actualValue)
    setPercentageValue(percentageValue)
    formik.setValues({ ...formik.values, saturation: actualValue })
  }

  useEffect(() => {
    if (!formik.values.saturation) {
      setMultiplierValue('')
      setPercentageValue('')
    }
  }, [formik.values.saturation])

  const saturationOptions = Object.keys(SATURATION_LABELS).map((type) => ({
    value: type,
    label: SATURATION_LABELS[type],
  }))

  return (
    <ModalAction
      open={open}
      loading={updatingAdGroup}
      onConfirm={formik.submitForm}
      confirmDisabled={
        (isSaturationEnabled && !formik.values.saturation) || !formik.dirty
      }
      onCancel={() => onCloseModal()}
      onBackdropClick={() => onCloseModal()}
      confirmButtonText="Accept changes"
      width="42rem"
    >
      <Suspense ready={!loadingAdGroup} fallback={<LoadingPage />}>
        <form>
          <h1 tw="font-light text-3xl text-charcoal leading-tight mb-10">
            Ad Group details
          </h1>
          <FormSection title="Details">
            <InputFormik
              required
              type="text"
              name="name"
              label="Name"
              formik={formik}
            />
            <Spacer size="1rem" />
            <InputFormik
              required
              type="text"
              name="redirect_to"
              label="URL"
              placeholder="https://your-domain.com"
              helperText="Insert the URL that you desire your target audience to interact with."
              formik={formik}
            />
          </FormSection>
          <FormSection title="Schedule">
            <DateTimeRangePicker
              label="Date"
              minDate={
                !!formik.values.timeframe.starts_at
                  ? moment(formik.values.timeframe.starts_at)
                  : moment()
              }
              startDate={
                !!formik.values.timeframe.starts_at
                  ? moment(formik.values.timeframe.starts_at)
                  : undefined
              }
              endDate={
                !!formik.values.timeframe.ends_at
                  ? moment(formik.values.timeframe.ends_at)
                  : undefined
              }
              success={
                !!formik.touched.timeframe?.starts_at &&
                !!formik.touched.timeframe?.ends_at &&
                !formik.errors.timeframe?.starts_at &&
                !formik.errors.timeframe?.ends_at
              }
              error={
                formik.errors.timeframe?.starts_at?.toString() ||
                formik.errors.timeframe?.ends_at?.toString()
              }
              onChange={(start, end) =>
                formik.setValues({
                  ...formik.values,
                  timeframe: { starts_at: start, ends_at: end },
                })
              }
            />
          </FormSection>
          <FormSection
            title="Saturation"
            subtitle="The Ad Group's saturation will define how many times your Ad Group will be displayed related to the other Ad Groups inside your campaign. For instance, if you choose a multiplier of 2, this means your Ad Group will be shown two times more than a Ad Group with a multiplier of 1."
          >
            <Checkbox
              checked={isSaturationEnabled}
              onChange={() => setIsSaturationEnabled(handleSaturation)}
              label="Assign saturation"
            />
            <div
              css={[
                tw`mt-4`,
                !isSaturationEnabled && tw`opacity-50 cursor-not-allowed`,
              ]}
            >
              <div tw="flex flex-row items-center mb-2">
                <div css={['max-width: 12.5rem;', tw`flex-grow mr-2`]}>
                  <Dropdown
                    isClearable={false}
                    isSearchable={false}
                    placeholder="Choose type"
                    options={saturationOptions}
                    isDisabled={!isSaturationEnabled}
                    success={
                      formik.touched.saturation && !formik.errors.saturation
                    }
                    defaultValue={saturationOptions.find(
                      (option) => option.value === selectedSaturation
                    )}
                    onChange={(option) =>
                      setSelectedSaturation(option ? option.value : '')
                    }
                  />
                </div>
                <div css={['max-width: 7.5rem;', tw`flex-grow`]}>
                  {selectedSaturation === SATURATION_TYPES.MULTIPLIER && (
                    <Input
                      step="0.01"
                      type="number"
                      placeholder="x"
                      hideSteppers
                      value={multiplierValue}
                      disabled={!isSaturationEnabled}
                      required={isSaturationEnabled && !!selectedSaturation}
                      success={
                        formik.touched.saturation && !formik.errors.saturation
                      }
                      onChange={handleChangeSaturation}
                    />
                  )}
                  {selectedSaturation === SATURATION_TYPES.PERCENTAGE && (
                    <Input
                      step="1"
                      type="number"
                      placeholder="%"
                      hideSteppers
                      value={percentageValue}
                      disabled={!isSaturationEnabled}
                      required={isSaturationEnabled && !!selectedSaturation}
                      success={
                        formik.touched.saturation && !formik.errors.saturation
                      }
                      onChange={handleChangeSaturation}
                    />
                  )}
                </div>
              </div>
              <span tw="font-normal text-xs text-dark-blue-gray leading-tight">
                {SATURATION_HELPER_TEXTS[selectedSaturation] ||
                  'Choose a number based on a multiplier (ex: 1.5x, 1.0x, 0.5x) or a percentage (ex: 100%, 200%, 50%)'}
              </span>
              {!!formik.errors.saturation && (
                <span tw="font-normal text-xs text-brick-red leading-tight block mt-1">
                  {formik.errors.saturation}
                </span>
              )}
            </div>
          </FormSection>
          <FormSection
            last
            title="Audience"
            subtitle="Improve your Ad Group by defining your Target Audience."
          >
            <h4 tw="font-medium text-xs text-dark-blue-gray leading-tight tracking-wider uppercase mb-6">
              Area type
            </h4>
            <div tw="flex flex-row justify-around">
              {AUDIENCE_AREA_TYPES.map((areaType, index) => (
                <div
                  key={`area-type#${index}`}
                  tw="flex flex-1 flex-row justify-center"
                >
                  <CardResource
                    type="radio"
                    name="audience.area_type"
                    icon={areaType.icon}
                    title={areaType.title}
                    value={areaType.value}
                    description={areaType.description}
                    checked={
                      areaType.value === formik.values.audience.area_type
                    }
                    onClick={() =>
                      formik.setFieldValue('audience.area_type', areaType.value)
                    }
                  />
                </div>
              ))}
            </div>
          </FormSection>
        </form>
      </Suspense>
    </ModalAction>
  )
}

interface FormSectionProps {
  title?: string
  subtitle?: string
  last?: boolean
}

const FormSection: FC<FormSectionProps> = ({
  title,
  subtitle,
  last,
  children,
}) => (
  <section>
    {title && (
      <h2 tw="font-medium text-charcoal leading-tight mb-2">{title}</h2>
    )}
    {subtitle && (
      <h3 tw="font-normal text-dark-blue-gray leading-tight mb-4">
        {subtitle}
      </h3>
    )}
    {children}
    {!last && <hr tw="bg-platinum my-8" />}
  </section>
)

export default EditAdGroupModal
