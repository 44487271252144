import React, { FC, useEffect, useState } from 'react'
import tw, { css } from 'twin.macro'
import { Link, useNavigate } from 'react-router-dom'
import ButtonLink from '../../ui-blocks/button-link'
import SignOutModal from '../../components/sign-out-modal'
import { authentication } from '../../stores'

const AuthSignOutPage: FC = () => {
  const navigate = useNavigate()
  const [timer, setTimer] = useState<number>(30)

  useEffect(() => {
    const interval = setInterval(() => setTimer((timer) => timer - 1), 1000)
    return () => clearInterval(interval)
  }, [])

  if (timer <= 0) navigate('/', { replace: true })
  return authentication.is_authenticated ? (
    <SignOutModal open />
  ) : (
    <div>
      <p
        css={css`
          ${tw`mb-8`}
        `}
      >
        You are not currently signed in to any Diffuse.tv account. We'll be
        redirecting you to the{' '}
        <Link
          to="/auth/sign-in"
          css={css`
            ${tw`font-bold text-red-700 hover:text-red-600`}
          `}
        >
          Sign In
        </Link>{' '}
        page in {timer} second(s).
      </p>
      <p
        css={css`
          ${tw`mb-4`}
        `}
      >
        Or you can choose one of the actions below:
      </p>
      <div
        css={css`
          ${tw`flex items-center justify-center`}
        `}
      >
        <ButtonLink
          to="/"
          css={css`
            ${tw`mr-4`}
          `}
        >
          Go to Sign In page
        </ButtonLink>
        <ButtonLink secondary to="/" title="Diffuse.tv website">
          Go to the Website
        </ButtonLink>
      </div>
    </div>
  )
}

export default AuthSignOutPage
