import tw, { css } from 'twin.macro'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { GoMail } from 'react-icons/go'
import { IoMdText } from 'react-icons/io'

import CardResourceRadio from '../../../ui-blocks/card-resource-radio'
import { InputFormik } from '../../../ui-blocks/input'
import Button from '../../../ui-blocks/button'
import { ForgotPasswordMethod } from '../../../graphql/components'

import { IStepProps } from './typings'

const methods = [
  {
    key: ForgotPasswordMethod.Email,
    value: ForgotPasswordMethod.Email,
    name: 'Email',
    description: `Send code to your email`,
    icon: GoMail,
  },
  {
    key: ForgotPasswordMethod.Phone,
    value: ForgotPasswordMethod.Phone,
    name: 'Text',
    description: `Send code to your phone number`,
    icon: IoMdText,
  },
]

const StepSendCode: FC<IStepProps> = ({ formik }) => (
  <>
    <h1
      css={css`
        ${tw`text-charcoal m-0 mb-0 font-light leading-tight text-4xl self-start`}
      `}
    >
      Forgot your password?
    </h1>

    <p
      css={css`
        ${tw`text-charcoal mb-10`}
      `}
    >
      We’ve got you covered. Choose a method to receive a verification code.
    </p>

    <CardResourceRadio
      value={formik.values.method}
      onChange={(method) => formik.setFieldValue('method', method)}
      cards={methods}
    />

    {formik.values.method === ForgotPasswordMethod.Phone && (
      <div style={{ maxWidth: '22.3rem' }}>
        <InputFormik
          formik={formik}
          type="phone"
          name="phoneNumber"
          label="PHONE NUMBER"
          placeholder="+351 912345678"
        />
      </div>
    )}

    {formik.values.method === ForgotPasswordMethod.Email && (
      <div style={{ maxWidth: '22.3rem' }}>
        <InputFormik
          formik={formik}
          formNoValidate
          type="email"
          name="email"
          label="EMAIL ADDRESS"
          placeholder="your-email@domain.eu"
        />
      </div>
    )}

    <div
      css={css`
        ${tw`mt-12`}
      `}
    >
      <Button
        type="submit"
        disabled={!formik.isValid}
        loading={formik.isSubmitting}
        secondary
        css={css`
          ${tw`px-10`}
        `}
      >
        Send verification code
      </Button>
    </div>

    <div
      css={css`
        ${tw`mt-6`}
      `}
    >
      <Link
        to="/auth/sign-in"
        css={css`
          ${tw`text-purple hover:text-spanish-violet`}
        `}
      >
        Back to sign in
      </Link>
    </div>
  </>
)

export default StepSendCode
