import moment from 'moment'
import { StatsInterval, StatsIntervalInput } from '../graphql/components'

export const MIN_EVER = new Date(0)

const NOW = moment()
const BEGIN_OF_DAY = NOW.clone().endOf('day')
export const END_OF_DAY = NOW.clone().endOf('day')

const START_OF_THIS_WEEK = BEGIN_OF_DAY.clone().startOf('week').toDate()
const START_OF_LAST_WEEK = BEGIN_OF_DAY.clone()
  .startOf('week')
  .subtract(1, 'week')
  .toDate()
const START_OF_WEEK_BEFORE = BEGIN_OF_DAY.clone()
  .startOf('week')
  .subtract(2, 'weeks')
  .toDate()

export function getPastDaysIntervals(days = 7, startsAt = END_OF_DAY) {
  const intervals: Array<StatsIntervalInput & { title?: string }> = []

  const pointerDate = startsAt.clone()
  while (startsAt.diff(pointerDate, 'days') <= days) {
    const ends_at = pointerDate.toDate()
    const starts_at = pointerDate.subtract(1, 'day').toDate()
    const title = pointerDate.format('MMM Do')

    intervals.push({
      starts_at,
      ends_at,
      title,
    })
  }

  return intervals
}

export function getKPIIntervals(): Array<
  StatsIntervalInput & { title?: string }
> {
  return [
    { title: 'All time total', starts_at: MIN_EVER, ends_at: END_OF_DAY }, // total
    { title: 'This week', starts_at: START_OF_THIS_WEEK, ends_at: END_OF_DAY }, // this week
    {
      title: 'Last week',
      starts_at: START_OF_LAST_WEEK,
      ends_at: START_OF_THIS_WEEK,
    }, // last week
    {
      title: 'Week before last',
      starts_at: START_OF_WEEK_BEFORE,
      ends_at: START_OF_LAST_WEEK,
    }, // week before last
  ]
}

export type KPIStatsInterval = StatsIntervalInput &
  StatsInterval & { title?: string }

export function groupStatsWithIntervals(
  intervals: Array<StatsIntervalInput>,
  stats: Array<StatsInterval> = []
) {
  const intervalStats: Array<KPIStatsInterval> = []

  for (const index in intervals) {
    intervalStats.push({
      ...intervals[index],
      ...stats[index],
    })
  }

  return intervalStats
}
