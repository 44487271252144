import React, { FC, useState, useMemo, useEffect } from 'react'

import 'twin.macro'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import {
  Informations,
  InformationsSection,
} from '../../../ui-blocks/information'
import Text from '../../../ui-blocks/text'
import Button from '../../../ui-blocks/button'
import Search from '../../../ui-blocks/search'
import Status from '../../../ui-blocks/status'
import Switch from '../../../ui-blocks/switch'
import KPICard from '../../../ui-blocks/kpi-card'
import Container from '../../../ui-blocks/container'
import ButtonLink from '../../../ui-blocks/button-link'
import BreadcrumbNav from '../../../ui-blocks/breadcrumb-nav'
import PaginationNav from '../../../ui-blocks/pagination-nav'
import { Tabs, TabPane } from '../../../ui-blocks/tabs'

import EditCampaignModal from './components/edit-campaign-modal'

import NotFound from '../../../components/not-found'
import StatusTabs from '../../../components/status-tabs'
import InlineDate from '../../../components/inline-date'
import LoadingPage from '../../../components/loading-page'
import ManagePlayersModal from '../../../components/manage-players-modal'
import ManageNetworksModal from '../../../components/manage-networks-modal'
import ArchiveWarningModal from '../../../components/archive-warning-modal'

import { PlayersListTable } from '../../../components/player/table'
import { NetworksListTable } from '../../../components/network/table'
import { CampaignAdGroupsListTable } from '../../../components/campaign-ad-group/table'

import {
  EnumPlayerStatus,
  EnumNetworkStatus,
  EnumCampaignStatus,
  EnumCampaignAdGroupStatus,
  useViewCampaignQuery,
  useArchiveCampaignMutation,
  useUpdateCampaignActivationMutation,
} from '../../../graphql/components'
import { SortSettings } from '../../../typings'

import { IoIosTrash, IoMdAdd } from 'react-icons/io'

import {
  getKPIIntervals,
  groupStatsWithIntervals,
} from '../../../utils/stats-intervals'
import usePagination from '../../../utils/use-pagination'
import { useSearchTerms } from '../../../utils/use-search'
import { getCampaignTimeframe } from '../../../utils/data-manipulation'

import { alertsManager } from '../../../stores'
import { AlertVariant } from '../../../stores/alerts-manager'

const ViewCampaignPage: FC = () => {
  const navigate = useNavigate()
  const { campaign_id } = useParams()

  const location = useLocation()
  const shouldRefetch = (location.state || ({} as any)).refetch as boolean

  const [
    archiveCampaign,
    { loading: archivingCampaign },
  ] = useArchiveCampaignMutation()
  const [updateCampaignActivation] = useUpdateCampaignActivationMutation()

  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false)
  const [
    isManagePlayersModalOpen,
    setIsManagePlayersModalOpen,
  ] = useState<boolean>(false)
  const [
    isManageNetworksModalOpen,
    setIsManageNetworksModalOpen,
  ] = useState<boolean>(false)

  const [currentPlayersTab, setCurrentPlayersTab] = useState<string>('all')
  const [currentNetworksTab, setCurrentNetworksTab] = useState<string>('all')
  const [currentAdGroupsTab, setCurrentAdGroupsTab] = useState<string>('all')

  const [sortByAdGroup, setSortByAdGroup] = useState<SortSettings>({
    column: '',
    isAscending: false,
  })
  const [sortByNetwork, setSortByNetwork] = useState<SortSettings>({
    column: '',
    isAscending: false,
  })
  const [sortByPlayer, setSortByPlayer] = useState<SortSettings>({
    column: '',
    isAscending: false,
  })

  const [isWarningModalOpen, setIsWarningModalOpen] = useState<boolean>(false)
  const { searchText, setSearchText, searchTextDebounced } = useSearchTerms()

  const intervals = useMemo(getKPIIntervals, [])
  const {
    data: campaignData,
    loading: loadingCampaign,
    refetch: refetchCampaign,
  } = useViewCampaignQuery({
    skip: !campaign_id,
    variables: {
      _id: campaign_id,
      intervals: intervals.map(({ starts_at, ends_at }) => ({
        starts_at,
        ends_at,
      })),
    },
  })

  // Don't render a campaign's ad group page if it is archived
  const { campaign } = campaignData || {}
  useEffect(() => {
    if (campaign?.status === EnumCampaignStatus.Archived)
      navigate('/campaigns', { replace: true })
  }, [campaign])

  useEffect(() => {
    shouldRefetch && refetchCampaign()
  }, [shouldRefetch])

  const refetchCampaignListener = () => refetchCampaign()
  useEffect(() => {
    document.addEventListener(
      'refetch-campaign-ad-groups',
      refetchCampaignListener
    )
    return () => {
      document.removeEventListener(
        'refetch-campaign-ad-groups',
        refetchCampaignListener
      )
    }
  }, [])

  const stats = useMemo(
    () => groupStatsWithIntervals(intervals, campaign?.stats || []),
    [intervals, campaign]
  )

  // Retrieve the earliest start date and the latest end date
  const allAdGroups = useMemo(() => campaign?.ad_groups || [], [campaign])
  const [campaignStart, campaignEnd] = getCampaignTimeframe(allAdGroups as any)

  const filteredAdGroups = useMemo(
    () =>
      allAdGroups // List the ad gropus first
        .filter((adGroup) =>
          currentAdGroupsTab === 'all'
            ? adGroup
            : adGroup.status === currentAdGroupsTab
        ) // Filter by status second
        .filter((adGroup) =>
          searchTextDebounced
            ? new RegExp(searchTextDebounced, 'i').test(adGroup.name)
            : adGroup
        ), // Filter by search query last
    [allAdGroups, currentAdGroupsTab, searchTextDebounced]
  )

  const allNetworks = useMemo(
    () =>
      campaign?.broadcasts_to?.networks
        ?.filter((networkLink) => !!networkLink)
        .map((networkLink) => networkLink?.network) || [],
    [campaign]
  )
  const filteredNetworks = useMemo(
    () =>
      allNetworks // List the networks first
        .filter((network) =>
          currentNetworksTab === 'all'
            ? network
            : network?.status === currentNetworksTab
        ) // Filter by status second
        .filter((network) =>
          searchTextDebounced
            ? new RegExp(searchTextDebounced, 'i').test(network?.name || '')
            : network
        ), // Filter by search query last
    [allNetworks, currentNetworksTab, searchTextDebounced]
  )

  const allPlayers = useMemo(
    () =>
      campaign?.broadcasts_to?.players
        ?.filter((playerLink) => !!playerLink)
        .map((playerLink) => playerLink?.player) || [],
    [campaign]
  )
  const filteredPlayers = useMemo(
    () =>
      allPlayers // List the players first
        .filter((player) =>
          currentPlayersTab === 'all'
            ? player
            : player?.status === currentPlayersTab
        ) // Filter by status second
        .filter((player) =>
          searchTextDebounced
            ? new RegExp(searchTextDebounced, 'i').test(player?.name || '')
            : player
        ), // Filter by search query last
    [allPlayers, currentPlayersTab, searchTextDebounced]
  )

  const adGroupsPagination = usePagination(filteredAdGroups.length, 15)
  const networksPagination = usePagination(filteredNetworks.length, 15)
  const playersPagination = usePagination(filteredPlayers.length, 15)

  const searchEntries: { [key: string]: string[] } = useMemo(
    () => ({
      adgroups: filteredAdGroups.map((adGroup) => adGroup.name),
      networks: filteredNetworks.map((network) => network?.name || ''),
      players: filteredPlayers.map((player) => player?.name || ''),
    }),
    [filteredAdGroups, filteredNetworks, filteredPlayers]
  )

  const handleUpdateError = (action: 'archive' | 'activate' | 'deactivate') => {
    alertsManager.emit({
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'update-campaign-status-error-alert',
      message: `We couldn't ${action} this campaign. Please try again later.`,
    })
  }

  const onArchiveCampaign = async () => {
    try {
      const res = await archiveCampaign({ variables: { ids: [campaign_id] } })
      if (!res.data?.areArchived) return handleUpdateError('archive')
      navigate('/campaigns', { replace: true, state: { refetch: true } })
    } catch {
      handleUpdateError('archive')
    }
  }

  const onUpdateCampaignActivation = async (activate: boolean) => {
    try {
      const res = await updateCampaignActivation({
        variables: { ids: [campaign_id], is_active: activate },
      })
      if (!res.data?.areUpdated)
        return handleUpdateError(activate ? 'activate' : 'deactivate')
      document.dispatchEvent(new CustomEvent('refetch-campaigns'))
      await refetchCampaign()
    } catch {
      handleUpdateError(activate ? 'activate' : 'deactivate')
    }
  }

  const isSwitchDisabled =
    campaign?.status === EnumCampaignStatus.Waiting ||
    campaign?.status === EnumCampaignStatus.Pending ||
    campaign?.status === EnumCampaignStatus.Finished ||
    campaign?.status === EnumCampaignStatus.Archived

  if (loadingCampaign) return <LoadingPage />
  if (!campaign) return <NotFound />

  return (
    <Container>
      <BreadcrumbNav
        tw="mb-1"
        crumbs={[
          { label: 'Campaigns', linkTo: '/campaigns' },
          { label: campaign.name },
        ]}
      />

      <div tw="flex flex-row items-center justify-between mb-6">
        <div tw="flex flex-row items-center">
          <Switch
            disabled={isSwitchDisabled}
            initialChecked={campaign.status === EnumCampaignStatus.Activated}
            onSelect={onUpdateCampaignActivation}
            tw="-ml-2 mr-2"
          />
          <Text as="h1" preset="h3">
            {campaign.name}
          </Text>
        </div>
        <Button
          secondary
          type="button"
          iconLeft={IoIosTrash}
          onClick={() => setIsWarningModalOpen(true)}
        >
          Archive
        </Button>
      </div>

      <div tw="whitespace-pre-line mb-14">
        <Informations
          title="Campaign Information"
          onEdit={() => setIsEditModalOpen(true)}
        >
          <InformationsSection label="STATUS">
            <Status value={campaign.status as EnumCampaignStatus}>
              {campaign.status === EnumCampaignStatus.Pending && (
                <>
                  <p tw="font-medium text-charcoal leading-tight mb-4">
                    Your campaign is still pending.
                  </p>
                  <span tw="font-normal text-dark-blue-gray leading-tight block mb-6">
                    You need to add at least one ad group to your campaign to
                    activate it.
                  </span>
                  <ButtonLink
                    iconLeft={IoMdAdd}
                    to={`/campaigns/${campaign_id}/ad-group/create`}
                  >
                    Add Ad Group
                  </ButtonLink>
                </>
              )}
            </Status>
          </InformationsSection>
          <InformationsSection label="AD GROUPS">
            {allAdGroups.length}
          </InformationsSection>
          <InformationsSection label="NETWORKS">
            {allNetworks.length}
          </InformationsSection>
          <InformationsSection label="PLAYERS">
            {allPlayers.length}
          </InformationsSection>
          <InformationsSection label="START">
            <InlineDate date={campaignStart} />
          </InformationsSection>
          <InformationsSection label="END">
            <InlineDate date={campaignEnd} />
          </InformationsSection>
          <InformationsSection label="CREATED">
            <InlineDate date={new Date(campaign.created?.at)} />
          </InformationsSection>
        </Informations>
      </div>

      <KPICard title="Campaign Analytics" intervals={stats} tw="mb-20" />

      <Tabs
        tabBarExtraContent={({ activeKey }) => (
          <div tw="flex">
            <Search
              value={searchText}
              entries={searchEntries[activeKey]}
              onSelect={(value) =>
                setSearchText(!Array.isArray(value) ? value || '' : '')
              }
            />
            <Button
              primary
              tw="ml-10 w-72"
              iconLeft={IoMdAdd}
              onClick={() => {
                switch (activeKey) {
                  case 'adgroups':
                    navigate(`/campaigns/${campaign_id}/ad-group/create`)
                    break
                  case 'players':
                    setIsManagePlayersModalOpen(true)
                    break
                  case 'networks':
                    setIsManageNetworksModalOpen(true)
                    break
                }
              }}
            >
              {(activeKey === 'adgroups' && `Add Ad Group`) ||
                (activeKey === 'players' && `Manage Players`) ||
                (activeKey === 'networks' && `Manage Networks`)}
            </Button>
          </div>
        )}
      >
        <TabPane key="adgroups" tab={`Ad Groups (${filteredAdGroups.length})`}>
          <StatusTabs
            activeTab={currentAdGroupsTab}
            onSelectTab={setCurrentAdGroupsTab}
            tabs={[
              { key: 'all', label: 'All' },
              { key: EnumCampaignAdGroupStatus.Activated, label: 'Active' },
              {
                key: EnumCampaignAdGroupStatus.Deactivated,
                label: 'Deactivated',
              },
              { key: EnumCampaignAdGroupStatus.Archived, label: 'Archived' },
            ]}
          />
          <CampaignAdGroupsListTable
            sortBy={sortByAdGroup}
            data={filteredAdGroups}
            loading={loadingCampaign}
            onSort={setSortByAdGroup}
          />
          <PaginationNav
            page={adGroupsPagination.page}
            count={adGroupsPagination.count}
            limit={adGroupsPagination.limit}
            setPage={adGroupsPagination.setPage}
            tw="mt-8"
          />
        </TabPane>
        <TabPane key="networks" tab={`Networks (${filteredNetworks.length})`}>
          <StatusTabs
            activeTab={currentNetworksTab}
            onSelectTab={setCurrentNetworksTab}
            tabs={[
              { key: 'all', label: 'All' },
              { key: EnumNetworkStatus.Activated, label: 'Active' },
              { key: EnumNetworkStatus.Deactivated, label: 'Deactivated' },
              { key: EnumNetworkStatus.Archived, label: 'Archived' },
            ]}
          />
          <NetworksListTable
            sortBy={sortByNetwork}
            data={filteredNetworks}
            loading={loadingCampaign}
            onSort={setSortByNetwork}
          />
          <PaginationNav
            page={networksPagination.page}
            count={networksPagination.count}
            limit={networksPagination.limit}
            setPage={networksPagination.setPage}
            tw="mt-8"
          />
        </TabPane>
        <TabPane key="players" tab={`Players (${filteredPlayers.length})`}>
          <StatusTabs
            activeTab={currentPlayersTab}
            onSelectTab={setCurrentPlayersTab}
            tabs={[
              { key: 'all', label: 'All' },
              { key: EnumPlayerStatus.Activated, label: 'Active' },
              { key: EnumPlayerStatus.Deactivated, label: 'Deactivated' },
              { key: EnumPlayerStatus.Archived, label: 'Archived' },
            ]}
          />
          <PlayersListTable
            sortBy={sortByPlayer}
            data={filteredPlayers}
            loading={loadingCampaign}
            onSort={setSortByPlayer}
          />
          <PaginationNav
            page={playersPagination.page}
            count={playersPagination.count}
            limit={playersPagination.limit}
            setPage={playersPagination.setPage}
            tw="mt-8"
          />
        </TabPane>
      </Tabs>

      <EditCampaignModal
        open={isEditModalOpen}
        campaignId={campaign_id}
        refetchData={refetchCampaign}
        onClose={() => setIsEditModalOpen(false)}
      />
      <ManagePlayersModal
        showPreChecked
        open={isManagePlayersModalOpen}
        asset={{ id: campaign_id, type: 'Campaign' }}
        networkIds={allNetworks.map((network) => network?._id)}
        refetchData={refetchCampaign}
        onClose={() => setIsManagePlayersModalOpen(false)}
      />
      <ManageNetworksModal
        open={isManageNetworksModalOpen}
        campaignId={campaign_id}
        refetchData={refetchCampaign}
        onClose={() => setIsManageNetworksModalOpen(false)}
      />
      <ArchiveWarningModal
        target="campaign"
        redirectPage="Campaigns"
        open={isWarningModalOpen}
        loading={archivingCampaign}
        onConfirm={onArchiveCampaign}
        onCancel={() => setIsWarningModalOpen(false)}
      />
    </Container>
  )
}

export default ViewCampaignPage
