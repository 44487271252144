import React, { FC, useEffect, useMemo, useState } from 'react'

import 'twin.macro'
import _ from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'

import {
  Informations,
  InformationsSection,
} from '../../../ui-blocks/information'
import Text from '../../../ui-blocks/text'
import Button from '../../../ui-blocks/button'
import Status from '../../../ui-blocks/status'
import Search from '../../../ui-blocks/search'
import KPICard from '../../../ui-blocks/kpi-card'
import Container from '../../../ui-blocks/container'
import BreadcrumbNav from '../../../ui-blocks/breadcrumb-nav'
import { Tabs, TabPane } from '../../../ui-blocks/tabs'

import EditCreativeModal from './components/edit-creative-modal'

import NotFound from '../../../components/not-found'
import Categories from '../../../components/categories'
import InlineDate from '../../../components/inline-date'
import StatusTabs from '../../../components/status-tabs'
import LoadingPage from '../../../components/loading-page'
import UploadDuration from '../../../components/upload/duration'
import UploadThumbnail from '../../../components/upload/thumbnail'
import ArchiveWarningModal from '../../../components/archive-warning-modal'
import { CampaignsListTable } from '../../../components/campaign/table'
import { CampaignAdGroupsListTable } from '../../../components/campaign-ad-group/table'

import {
  EnumCreativeStatus,
  EnumCampaignStatus,
  EnumCampaignAdGroupStatus,
  useViewCreativeQuery,
  useListCampaignsQuery,
  useArchiveCreativeMutation,
} from '../../../graphql/components'
import { SortSettings } from '../../../typings'

import { alertsManager } from '../../../stores'
import { AlertVariant } from '../../../stores/alerts-manager'

import {
  getKPIIntervals,
  groupStatsWithIntervals,
} from '../../../utils/stats-intervals'
import { useSearchTerms } from '../../../utils/use-search'

import { IoIosTrash } from 'react-icons/io'
import Card from '../../../ui-blocks/card'

const ViewAdCreativePage: FC = () => {
  const navigate = useNavigate()
  const { creative_id } = useParams()

  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false)
  const [isWarningModalOpen, setIsWarningModalOpen] = useState<boolean>(false)

  const [currentAdGroupsTab, setCurrentAdGroupsTab] = useState<string>('all')
  const [currentCampaignsTab, setCurrentCampaignsTab] = useState<string>('all')

  const [sortByCampaign, setSortByCampaign] = useState<SortSettings>({
    column: '',
    isAscending: false,
  })
  const [sortByAdGroup, setSortByAdGroup] = useState<SortSettings>({
    column: '',
    isAscending: false,
  })

  const [
    archiveCreative,
    { loading: archivingCreative },
  ] = useArchiveCreativeMutation()

  const intervals = useMemo(getKPIIntervals, [])
  const { searchText, setSearchText, searchTextDebounced } = useSearchTerms()

  const {
    data: creativeData,
    error: creativeError,
    loading: loadingCreative,
    refetch: refetchCreative,
  } = useViewCreativeQuery({
    skip: !creative_id,
    variables: {
      _id: creative_id,
      intervals: intervals.map(({ starts_at, ends_at }) => ({
        starts_at,
        ends_at,
      })),
    },
  })

  const { creative } = creativeData || {}
  const relatedAdGroups = creative?.ad_groups || []

  // Remove duplicate campaign ids in case several ad groups belong to the same campaign
  const relatedCampaignIds = _.uniq(
    relatedAdGroups.map((adGroup) => adGroup.campaign_id)
  )
  const filteredAdGroups = relatedAdGroups
    .filter((adGroup) =>
      currentAdGroupsTab === 'all'
        ? adGroup
        : adGroup.status === currentAdGroupsTab
    )
    .filter((adGroup) =>
      searchTextDebounced
        ? new RegExp(searchTextDebounced, 'i').test(adGroup.name)
        : adGroup
    )

  const {
    data: campaignsData,
    error: campaignsError,
    loading: loadingCampaigns,
  } = useListCampaignsQuery({
    skip: !creative_id || relatedCampaignIds.length === 0,
    variables: {
      filter: { _ids: relatedCampaignIds },
      intervals: intervals.map(({ starts_at, ends_at }) => ({
        starts_at,
        ends_at,
      })),
    },
  })

  const relatedCampaigns = campaignsData?.campaigns || []
  const filteredCampaigns = relatedCampaigns
    .filter((campaign) =>
      currentCampaignsTab === 'all'
        ? campaign
        : campaign.status === currentCampaignsTab
    )
    .filter((campaign) =>
      searchTextDebounced
        ? new RegExp(searchTextDebounced, 'i').test(campaign.name)
        : campaign
    )

  // Don't render a player's page if it is archived
  useEffect(() => {
    if (creative?.status === EnumCreativeStatus.Archived)
      navigate('/creatives', { replace: true })
  }, [creative])

  const stats = useMemo(
    () => groupStatsWithIntervals(intervals, creative?.stats || []),
    [intervals, creative]
  )

  if (loadingCreative) return <LoadingPage />
  if (!creative) return <NotFound />

  const handleUpdateError = () => {
    alertsManager.emit({
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'update-creative-status-error-alert',
      message: "We couldn't archive this creative. Please try again.",
    })
  }

  const onArchiveCreative = async () => {
    try {
      const res = await archiveCreative({ variables: { ids: [creative_id] } })
      if (!res.data?.areArchived) return handleUpdateError()
      navigate('/creatives', { replace: true, state: { refetch: true } })
    } catch {
      handleUpdateError()
    }
  }

  const searchEntries: { [key: string]: string[] } = {
    campaigns: filteredCampaigns.map((campaign) => campaign.name),
    adgroups: filteredAdGroups.map((adGroup) => adGroup.name),
  }

  return (
    <Container>
      <BreadcrumbNav
        tw="mb-1"
        crumbs={[
          { label: 'Creatives', linkTo: '/creatives' },
          { label: creative.name },
        ]}
      />

      <div tw="flex flex-row items-center justify-between mb-6">
        <Text as="h1" preset="h3">
          {creative.name}
        </Text>
        <Button
          secondary
          type="button"
          iconLeft={IoIosTrash}
          loading={archivingCreative}
          onClick={() => setIsWarningModalOpen(true)}
        >
          Archive
        </Button>
      </div>

      <div tw="whitespace-pre-line mb-14">
        <Informations
          title="Creative Information"
          onEdit={() => setIsEditModalOpen(true)}
        >
          <InformationsSection
            label="PREVIEW"
            css={['width: 19rem;', 'margin-right: 3rem;', 'flex-grow: unset;']}
          >
            <div css={['width: 100%;', 'height: 11rem;']}>
              <UploadThumbnail upload={creative.upload || undefined} />
            </div>
          </InformationsSection>
          <div tw="flex flex-col flex-grow">
            <div tw="w-full flex flex-row">
              <InformationsSection label="STATUS">
                <Status value={creative.status as EnumCreativeStatus} />
              </InformationsSection>
              <InformationsSection label="TYPE">
                {creative.upload?.type.toUpperCase()}
              </InformationsSection>
              <InformationsSection label="UPLOAD DATE">
                <InlineDate date={new Date(creative.upload?.created?.at)} />
              </InformationsSection>
              <InformationsSection label="TIME">
                <UploadDuration upload={creative?.upload as any} />
              </InformationsSection>
              <InformationsSection label="CATEGORY">
                <Categories categoryIds={creative.categories_ids || []} />
              </InformationsSection>
            </div>
          </div>
        </Informations>
      </div>

      <KPICard title="Creative Analytics" intervals={stats} tw="mb-20" />

      <Tabs
        tabBarExtraContent={({ activeKey }) => (
          <div tw="min-w-80">
            <Search
              value={searchText}
              entries={searchEntries[activeKey]}
              onSelect={(value) =>
                setSearchText(!Array.isArray(value) ? value || '' : '')
              }
            />
          </div>
        )}
      >
        <TabPane
          key="campaigns"
          tab={`Campaigns (${filteredCampaigns.length})`}
        >
          <StatusTabs
            activeTab={currentCampaignsTab}
            onSelectTab={setCurrentCampaignsTab}
            tabs={[
              { key: 'all', label: 'All' },
              { key: EnumCampaignStatus.Activated, label: 'Active' },
              { key: EnumCampaignStatus.Deactivated, label: 'Deactivated' },
              { key: EnumCampaignStatus.Archived, label: 'Archived' },
            ]}
          />
          <CampaignsListTable
            sortBy={sortByCampaign}
            error={campaignsError}
            data={filteredCampaigns}
            loading={loadingCampaigns}
            onSort={setSortByCampaign}
          />
        </TabPane>
        <TabPane key="adgroups" tab={`Ad Groups (${filteredAdGroups.length})`}>
          <StatusTabs
            activeTab={currentAdGroupsTab}
            onSelectTab={setCurrentAdGroupsTab}
            tabs={[
              { key: 'all', label: 'All' },
              { key: EnumCampaignAdGroupStatus.Activated, label: 'Active' },
              {
                key: EnumCampaignAdGroupStatus.Deactivated,
                label: 'Deactivated',
              },
              { key: EnumCampaignAdGroupStatus.Archived, label: 'Archived' },
            ]}
          />
          <CampaignAdGroupsListTable
            sortBy={sortByAdGroup}
            error={creativeError}
            data={filteredAdGroups}
            loading={loadingCreative}
            onSort={setSortByAdGroup}
          />
        </TabPane>
      </Tabs>

      <EditCreativeModal
        open={isEditModalOpen}
        creativeId={creative_id}
        refetchData={refetchCreative}
        onClose={() => setIsEditModalOpen(false)}
      />
      <ArchiveWarningModal
        target="creative"
        redirectPage="Creatives"
        open={isWarningModalOpen}
        loading={archivingCreative}
        onConfirm={onArchiveCreative}
        onCancel={() => setIsWarningModalOpen(false)}
      />
    </Container>
  )
}

export default ViewAdCreativePage
