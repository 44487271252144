import React, { FC, useEffect } from 'react'

import 'twin.macro'
import { useParams } from 'react-router-dom'

import Container from '../../../ui-blocks/container'

import SummaryCard from './components/summary-card'
import SummaryChart from './components/summary-chart'
import PaymentFailAlert from './components/payment-fail-alert'
import LoadingPage from '../../../components/loading-page'
import WorkspaceOnboardingPage, { useOnboardingPage } from './onboarding'
import { Invoice } from '../settings/billing/sections/invoices'

import { BsDisplay } from 'react-icons/bs'
import { FaBullhorn } from 'react-icons/fa'
import { BiPlayCircle, BiNetworkChart } from 'react-icons/bi'

import {
  useCountQuery,
  useViewWorkspaceQuery,
} from '../../../graphql/components'

const WorkspaceSummaryPage: FC = () => {
  const count = useCountQuery({ fetchPolicy: 'no-cache' })
  const { renderOnboardingPage } = useOnboardingPage(count)

  const refetchData = async () => await count.refetch()
  useEffect(() => {
    document.addEventListener('refetch-players', refetchData)
    document.addEventListener('refetch-networks', refetchData)
    document.addEventListener('refetch-creatives', refetchData)
    document.addEventListener('refetch-campaigns', refetchData)
    document.addEventListener('refetch-campaign-ad-groups', refetchData)
    return () => {
      document.removeEventListener('refetch-players', refetchData)
      document.removeEventListener('refetch-networks', refetchData)
      document.removeEventListener('refetch-creatives', refetchData)
      document.removeEventListener('refetch-campaigns', refetchData)
      document.removeEventListener('refetch-campaign-ad-groups', refetchData)
    }
  }, [])

  const { workspace_id } = useParams()
  const { data } = useViewWorkspaceQuery({
    skip: !workspace_id,
    variables: { _id: workspace_id },
  })

  if (count.loading) return <LoadingPage />
  if (renderOnboardingPage) return <WorkspaceOnboardingPage count={count} />

  const invoices = data?.workspace?.invoices?.data || []
  return (
    <Container>
      <PaymentFailAlert
        workspaceId={workspace_id}
        invoices={invoices as Invoice[]}
      />
      <h1 tw="text-charcoal font-light leading-tight text-3xl mb-6">
        Workspace Summary
      </h1>
      <div tw="flex flex-row flex-wrap items-center justify-between -mx-2 mb-16">
        <div tw="px-2 mb-2 w-full max-w-xs">
          <SummaryCard
            icon={FaBullhorn}
            title="Total Campaigns"
            count={count.data?.campaigns || 0}
          />
        </div>
        <div tw="px-2 mb-2 w-full max-w-xs">
          <SummaryCard
            icon={BiPlayCircle}
            title="Total Creatives"
            count={count.data?.creatives || 0}
          />
        </div>
        <div tw="px-2 mb-2 w-full max-w-xs">
          <SummaryCard
            icon={BiNetworkChart}
            title="Total Networks"
            count={count.data?.networks || 0}
          />
        </div>
        <div tw="px-2 mb-2 w-full max-w-xs">
          <SummaryCard
            icon={BsDisplay}
            title="Total Players"
            count={count.data?.players || 0}
          />
        </div>
      </div>
      <div tw="flex flex-row flex-wrap items-center justify-between -mx-2">
        <div tw="px-2 mb-4 w-full lg:max-w-2/4">
          <SummaryChart title="Reach" metric="reach" />
        </div>
        <div tw="px-2 mb-4 w-full lg:max-w-2/4">
          <SummaryChart title="Engagement" metric="engagement" />
        </div>
      </div>
    </Container>
  )
}

export default WorkspaceSummaryPage
