import React, { FC, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import ModalAction from '../ui-blocks/modal-action'

import { authentication } from '../stores'

const SignOutModal: FC<{ open?: boolean; onCancel?: () => void }> = ({
  open,
  onCancel,
}) => {
  const navigate = useNavigate()
  const [loggingOut, setLoggingOut] = useState<boolean>(false)

  const onGoBack = () => {
    navigate('/', { replace: true })
  }

  const onSignOut = async () => {
    setLoggingOut(true)
    await authentication.logout()
    setLoggingOut(false)
    onGoBack()
  }

  return (
    <ModalAction
      title="Sign Out"
      cancelButtonText="Go Back"
      confirmButtonText="Sign Out"
      content="Are you sure you want to sign out?"
      open={open}
      loading={loggingOut}
      onConfirm={onSignOut}
      onCancel={onCancel || onGoBack}
    />
  )
}

export default SignOutModal
