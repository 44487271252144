import React, { FC, useState } from 'react'

import moment from 'moment'
import tw, { css } from 'twin.macro'
import { useParams } from 'react-router-dom'

import Button from '../../../../ui-blocks/button'
import { Tabs, TabPane } from '../../../../ui-blocks/tabs'

import UpcomingBill from './components/upcoming-bill'

import Invoices from './sections/invoices'
import PaymentMethods from './sections/payment-methods'

import {
  useCountQuery,
  useViewWorkspaceQuery,
} from '../../../../graphql/components'

import { authentication } from '../../../../stores'

const WorkspaceSettingsBillingPage: FC = () => {
  const { workspace_id } = useParams()
  const [selectedTab, setSelectedTab] = useState<string>('payment-information')

  const count = useCountQuery({ fetchPolicy: 'no-cache' })
  const { data } = useViewWorkspaceQuery({
    skip: !workspace_id,
    variables: { _id: workspace_id },
  })

  const upcomingInvoice = data?.workspace?.upcoming_invoice
  const lastChargedInvoice = data?.workspace?.invoices?.data?.find(
    (invoice) => invoice?.status === 'paid'
  )
  const lastFailedPayment = data?.workspace?.invoices?.data?.find(
    (invoice) => invoice?.status === 'open'
  )

  const { is_trial_active, trial_remaining_days } = authentication
  const renderRemainingTrialTime = () => (
    <div
      css={css`
        ${tw`flex flex-row font-normal text-xs leading-tight`}
      `}
    >
      <span
        css={css`
          ${tw`text-dark-blue-gray mr-2`}
        `}
      >
        Ending: 24 August 2020
      </span>
      <span
        css={css`
          ${tw`text-2xl mb-8 font-light text-charcoal`}
        `}
      >
        {trial_remaining_days} days left
      </span>
    </div>
  )

  const renderPlanFeatures = () => (
    <>
      <p
        css={css`
          ${tw`font-normal text-xs text-charcoal leading-tight mb-1`}
        `}
      >
        All the outstanding functionality of the platform
      </p>
      <span
        css={css`
          ${tw`font-normal text-xs text-dark-blue-gray leading-tight block mb-4`}
        `}
      >
        €30 billed monthly
      </span>
      <Button
        menu
        type="button"
        css={css`
          min-width: auto;
          ${tw`underline p-0`}
        `}
      >
        Change plan
      </Button>
      <p
        css={css`
          ${tw`font-normal text-charcoal leading-tight mt-8 mb-1`}
        `}
      >
        Current active players: {count.data?.players}
      </p>
    </>
  )

  return (
    <div>
      <div
        css={css`
          ${tw`flex flex-row mb-14`}
        `}
      >
        <div
          css={css`
            ${tw`w-2/4`}
          `}
        >
          <h1
            css={css`
              ${tw`font-medium text-xl text-charcoal leading-tight mb-2`}
            `}
          >
            {is_trial_active ? 'Free Trial' : 'Enterprise'}
          </h1>
          <p
            css={css`
              ${tw`font-normal text-charcoal leading-tight mb-2`}
            `}
          >
            Workspace created on{' '}
            {moment(authentication.selected_workspace.added_at).format('LL')}
          </p>
          {is_trial_active ? renderRemainingTrialTime() : renderPlanFeatures()}
        </div>
        <div
          css={css`
            ${tw`w-2/4`}
          `}
        >
          <UpcomingBill
            hasFailedPayments={!!lastFailedPayment}
            freePlan={is_trial_active || !upcomingInvoice}
            nextChargeAmount={(upcomingInvoice?.amount_due as number) / 100}
            nextChargeDate={
              new Date((upcomingInvoice?.next_payment_attempt as number) * 1000)
            }
            lastChargeAmount={
              !!lastFailedPayment
                ? lastFailedPayment.total
                : lastChargedInvoice?.total
            }
            onDetailsClick={() => setSelectedTab('payment-history')}
          />
        </div>
      </div>
      <Tabs activeKey={selectedTab} onTabClick={setSelectedTab}>
        <TabPane key="payment-information" tab="Payment Information">
          <PaymentMethods />
        </TabPane>
        <TabPane key="payment-history" tab="Payment History">
          <Invoices />
        </TabPane>
      </Tabs>
    </div>
  )
}

export default WorkspaceSettingsBillingPage
