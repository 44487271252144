import React, { FC, useEffect, useState } from 'react'
import tw from 'twin.macro'

import { useDropzone } from 'react-dropzone'
import { useLocation } from 'react-router-dom'

import Search from '../../../ui-blocks/search'
import Spacer from '../../../ui-blocks/spacer'
import Suspense from '../../../ui-blocks/suspense'
import Container from '../../../ui-blocks/container'
import ViewControls from '../../../ui-blocks/view-controls'
import PaginationNav from '../../../ui-blocks/pagination-nav'

import UploadsArea from './components/uploads-area'
import PreviewModal from './components/preview-modal'
import CreativesEmpty from './components/creatives-empty'
import CreativesNoResults from './components/creatives-no-results'

import StatusTabs from '../../../components/status-tabs'
import LoadingPage from '../../../components/loading-page'
import CreativeCard from '../../../components/creative/card'
import { CreativesListTable } from '../../../components/creative/table'

import {
  Upload,
  Creative,
  useCountQuery,
  useListCreativesQuery,
  EnumCreativeType,
  EnumCreativeStatus,
} from '../../../graphql/components'
import { SortSettings } from '../../../typings'

import { MdViewComfy } from 'react-icons/md'
import { FiAlignJustify } from 'react-icons/fi'

import usePagination from '../../../utils/use-pagination'
import { useSearchTerms } from '../../../utils/use-search'
import { MIN_EVER, END_OF_DAY } from '../../../utils/stats-intervals'

import { uploadsManager } from '../../../stores'

const ListCreativesPage: FC = () => {
  const location = useLocation()
  const shouldRefetch = (location.state || ({} as any)).refetch as boolean

  const [view, setView] = useState<string>('card-view')
  const [currentTab, setCurrentTab] = useState<string>('all')

  const [preview, setPreview] = useState<Upload | undefined>(undefined)
  const [sortBy, setSortBy] = useState<SortSettings>({
    column: '',
    isAscending: false,
  })

  const {
    data: countData,
    loading: loadingCount,
    refetch: refetchCount,
  } = useCountQuery({ fetchPolicy: 'no-cache' })
  const creativesCount = countData?.creatives || 0
  const pagination = usePagination(creativesCount, 15)

  const { searchText, setSearchText, searchTextDebounced } = useSearchTerms()
  const {
    data: creativesData,
    error: creativesError,
    loading: loadingCreatives,
    refetch: refetchCreatives,
  } = useListCreativesQuery({
    fetchPolicy: 'no-cache',
    variables: {
      limit: pagination.limit,
      offset: pagination.offset,
      filter: { search: searchTextDebounced },
      intervals: [{ starts_at: MIN_EVER, ends_at: END_OF_DAY }],
    },
  })

  const refetchData = async () => {
    await refetchCount()
    await refetchCreatives()
  }

  useEffect(() => {
    shouldRefetch && refetchData()
  }, [shouldRefetch])

  useEffect(() => {
    document.addEventListener('refetch-creatives', refetchData)
    return () => {
      document.removeEventListener('refetch-creatives', refetchData)
    }
  }, [])

  const onDrop = async (acceptedFiles: File[]) => {
    try {
      await Promise.all(
        acceptedFiles.map((file) =>
          uploadsManager.upload({ file, type: EnumCreativeType.Ad })
        )
      )
    } catch (error) {
      console.error('Upload failed:', error)
    } finally {
      await refetchData()
    }
  }

  const {
    isDragActive,
    getRootProps,
    getInputProps,
    inputRef: uploadInputRef,
  } = useDropzone({
    onDrop,
    accept: 'video/*, image/jpeg, image/png',
  })

  const allCreatives = creativesData?.creatives || []
  const { archivedCreatives, remainingCreatives } = allCreatives.reduce<{
    archivedCreatives: typeof allCreatives
    remainingCreatives: typeof allCreatives
  }>(
    (acc, creative) => {
      const key =
        creative.status === EnumCreativeStatus.Archived
          ? 'archivedCreatives'
          : 'remainingCreatives'
      return {
        ...acc,
        [key]: [...acc[key], creative],
      }
    },
    { archivedCreatives: [], remainingCreatives: [] }
  )

  const sortedCreatives = [...remainingCreatives, ...archivedCreatives]
  const filteredCreatives = sortedCreatives.filter((creative) =>
    currentTab === 'all' ? creative : creative.status === currentTab
  )

  return (
    <Container>
      <Suspense ready={!loadingCount} fallback={<LoadingPage />}>
        <div tw="flex flex-row items-center justify-between mb-10">
          <div tw="flex flex-row items-end">
            <h1 tw="text-charcoal font-light leading-tight text-3xl">
              Creatives
            </h1>
            {!!creativesCount && (
              <span tw="text-charcoal text-sm tracking-wide ml-4">
                {loadingCreatives ? 'Loading...' : `${creativesCount} TOTAL`}
              </span>
            )}
          </div>
          {!!creativesCount && (
            <div tw="flex flex-row items-center">
              <ViewControls
                initialOptionId="card-view"
                onSelect={setView}
                options={[
                  { id: 'card-view', label: 'Card View', icon: MdViewComfy },
                  { id: 'list-view', label: 'List View', icon: FiAlignJustify },
                ]}
              />
              <Spacer size="2.5rem" direction="horizontal" />
              <div tw="w-80">
                <Search
                  value={searchText}
                  loading={loadingCreatives}
                  entries={filteredCreatives.map((creative) => creative.name)}
                  onSelect={(value) =>
                    setSearchText(!Array.isArray(value) ? value || '' : '')
                  }
                />
              </div>
            </div>
          )}
        </div>
        <Suspense
          ready={!!creativesCount}
          fallback={
            <CreativesEmpty
              onUploadClick={() => uploadInputRef.current?.click()}
            />
          }
        >
          <StatusTabs
            activeTab={currentTab}
            onSelectTab={setCurrentTab}
            tabs={[
              { key: 'all', label: 'All' },
              { key: EnumCreativeStatus.Activated, label: 'Active' },
              {
                key: EnumCreativeStatus.Deactivated,
                label: 'Deactivated',
              },
              { key: EnumCreativeStatus.Archived, label: 'Archived' },
            ]}
          />
          <Spacer size="2.5rem" />
          {view === 'card-view' && (
            <Suspense
              ready={!!allCreatives.length}
              fallback={
                <Suspense ready={!loadingCreatives} fallback={<LoadingPage />}>
                  <CreativesNoResults searchQuery={searchTextDebounced} />
                </Suspense>
              }
            >
              <div tw="mt-8 flex flex-wrap -m-4">
                <div css={['height: 19rem;', tw`m-4 w-full max-w-xs`]}>
                  <UploadsArea
                    rootProps={getRootProps()}
                    isDragActive={isDragActive}
                  />
                </div>
                {filteredCreatives.map((creative) => (
                  <div tw="m-4 w-full max-w-xs" key={creative._id}>
                    <CreativeCard
                      creative={creative}
                      onClickThumbnail={() =>
                        setPreview(creative?.upload as any)
                      }
                    />
                  </div>
                ))}
              </div>
            </Suspense>
          )}
          {view === 'list-view' && (
            <div tw="flex flex-col">
              <div tw="w-full h-48 max-w-4xl mb-8 self-center">
                <UploadsArea
                  rootProps={getRootProps()}
                  isDragActive={isDragActive}
                />
              </div>
              <CreativesListTable
                sortBy={sortBy}
                error={creativesError}
                data={filteredCreatives}
                loading={loadingCreatives}
                searchQuery={searchTextDebounced}
                onSort={setSortBy}
                onSelect={(creative: Creative) =>
                  setPreview(creative?.upload || undefined)
                }
              />
              <PaginationNav
                page={pagination.page}
                count={pagination.count}
                limit={pagination.limit}
                setPage={pagination.setPage}
                tw="mt-8"
              />
            </div>
          )}
        </Suspense>
      </Suspense>
      <PreviewModal upload={preview} onClose={() => setPreview(undefined)} />
      <input {...getInputProps()} />
    </Container>
  )
}

export default ListCreativesPage
