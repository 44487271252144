import React, { FC } from 'react'

import tw, { css } from 'twin.macro'
import ReactPaginate from 'react-paginate'

import { ReactComponent as Caret } from '../assets/icon/caret/caret_gray.svg'

export interface PaginationNavProps {
  page: number
  count: number
  limit?: number
  setPage?: (page: number) => void
}

const PaginationNav: FC<PaginationNavProps> = ({
  page,
  count,
  limit = 15,
  setPage,
  ...props
}) => (
  <div
    css={css`
      ${tw` flex flex-row flex-grow items-center justify-end`}
      ul {
        ${tw`select-none flex flex-row items-center`}
        & > li {
          ${tw`text-dark-blue-gray`}
          & > a {
            outline: none;
          }
          &.selected {
            ${tw`text-charcoal`}
          }
          &.disabled {
            ${tw`text-platinum`}
            & svg {
              ${tw`hover:cursor-not-allowed`}
            }
          }
          &.page {
            ${tw`mx-1`}
          }
        }
      }
    `}
    {...props}
  >
    <span tw="font-normal text-dark-blue-gray leading-tight mr-6">
      {`${limit * page + 1} - ${Math.min(
        limit * page + limit,
        count
      )} out of ${count} total`}
    </span>
    <ReactPaginate
      initialPage={page}
      pageClassName="page"
      pageRangeDisplayed={5}
      marginPagesDisplayed={5}
      pageCount={Math.ceil(count / limit)}
      onPageChange={({ selected }) => setPage?.(selected)}
      nextLabel={
        <Caret
          width={16}
          className="fill-current"
          tw="transform -rotate-90"
          title="Next page"
        />
      }
      previousLabel={
        <Caret
          width={16}
          className="fill-current"
          tw="transform rotate-90"
          title="Previous page"
        />
      }
    />
  </div>
)

PaginationNav.defaultProps = {
  limit: 15,
}

export default PaginationNav
