import React, { Fragment, useState } from 'react'

import 'twin.macro'
import _ from 'lodash'

import {
  Row,
  RowCell,
  FullRow,
  TableHeader,
  TableColumn,
  HeaderColumn,
  generateTableComponent,
} from '../../ui-blocks/table'
import Status from '../../ui-blocks/status'
import Switch from '../../ui-blocks/switch'

import NoResults from '../no-results'
import NotDefined from '../not-defined'
import InlineDate from '../inline-date'
import LoadingPage from '../loading-page'

import { ReactComponent as NoResultsIllustration } from '../../assets/illustrations/campaign_no_search_result.svg'

import {
  Campaign,
  EnumCampaignStatus,
  useUpdateCampaignActivationMutation,
} from '../../graphql/components'
import { SortSettings } from '../../typings'

import { alertsManager } from '../../stores'
import { AlertVariant } from '../../stores/alerts-manager'

import { getCampaignTimeframe } from '../../utils/data-manipulation'

const COLUMNS: TableColumn[] = [
  { key: 'switch', label: '', isSortable: false, component: Fragment },
  { key: 'name', label: 'Name', isSortable: true, component: Fragment },
  {
    key: 'status',
    label: 'Status',
    isSortable: true,
    prop: 'value',
    component: Status,
  },
  {
    key: 'stats[0].reach',
    label: 'Impressions',
    isSortable: true,
    component: Fragment,
  },
  {
    key: 'stats[0].engagement',
    label: 'Scans',
    isSortable: true,
    component: Fragment,
  },
  {
    key: 'ad_groups',
    label: 'Ad Groups',
    isSortable: true,
    component: Fragment,
  },
  {
    key: 'campaign_start',
    label: 'Start Date',
    isSortable: true,
    prop: 'date',
    component: InlineDate,
  },
  {
    key: 'campaign_end',
    label: 'End Date',
    isSortable: true,
    prop: 'date',
    component: InlineDate,
  },
  {
    key: 'created.at',
    label: 'Created On',
    isSortable: true,
    prop: 'date',
    component: InlineDate,
  },
]

type PartialCampaign = Pick<
  Campaign,
  | '_id'
  | 'name'
  | 'status'
  | 'ad_groups'
  | 'broadcasts_to'
  | 'created'
  | 'timeframe'
>

export const CampaignsListTable = generateTableComponent<PartialCampaign>({
  HeaderComponent({ onSort }) {
    const [selectedColumn, setSelectedColumn] = useState<string>('')

    const handleClick = (settings: SortSettings) => {
      setSelectedColumn(settings.column)
      onSort?.(settings)
    }

    return (
      <TableHeader>
        {COLUMNS.map((col, index) => (
          <HeaderColumn
            key={`header-column#${index}`}
            isSortable={col.isSortable}
            isSelected={selectedColumn === col.key}
            onClick={(isAscending) =>
              handleClick({ column: col.key, isAscending })
            }
          >
            {col.label}
          </HeaderColumn>
        ))}
      </TableHeader>
    )
  },

  EmptyComponent({ searchQuery }) {
    return (
      <FullRow>
        <NoResults
          tw="mt-8"
          model="Campaign"
          searchQuery={searchQuery}
          Illustration={NoResultsIllustration}
        />
      </FullRow>
    )
  },

  LoadingComponent() {
    return (
      <FullRow>
        <LoadingPage />
      </FullRow>
    )
  },

  ErrorComponent() {
    return null
  },

  RowComponent({ datum: campaign }) {
    const { _id, status, ad_groups } = campaign

    const handleUpdateError = (activate: boolean) => {
      alertsManager.emit({
        dismissable: true,
        variant: AlertVariant.ERROR,
        id: 'update-campaign-status-error-alert',
        message: `We couldn't ${
          activate ? 'activate' : 'deactivate'
        } this campaign. Please try again.`,
      })
    }

    const [updateCampaignActivation] = useUpdateCampaignActivationMutation()
    const onUpdateCampaignActivation = async (activate: boolean) => {
      try {
        const res = await updateCampaignActivation({
          variables: { ids: [_id], is_active: activate },
        })
        if (!res.data?.areUpdated) return handleUpdateError(activate)
        document.dispatchEvent(new CustomEvent('refetch-campaigns'))
      } catch {
        handleUpdateError(activate)
      }
    }

    const [campaignStart, campaignEnd] = getCampaignTimeframe(ad_groups)
    const isSwitchDisabled =
      status === EnumCampaignStatus.Waiting ||
      status === EnumCampaignStatus.Pending ||
      status === EnumCampaignStatus.Finished ||
      status === EnumCampaignStatus.Archived

    return (
      <Row>
        {COLUMNS.map(
          ({ key, prop, transform, component: Component }, index) => {
            const value =
              (key === 'campaign_start' && campaignStart) ||
              (key === 'campaign_end' && campaignEnd) ||
              _.get(campaign, key, undefined)
            return (
              <RowCell
                key={`row-cell#${index}`}
                linkTo={
                  key === 'name' && status !== EnumCampaignStatus.Archived
                    ? `/campaigns/${_id}`
                    : undefined
                }
              >
                {key === 'switch' ? (
                  <Switch
                    disabled={isSwitchDisabled}
                    initialChecked={status === EnumCampaignStatus.Activated}
                    onSelect={onUpdateCampaignActivation}
                  />
                ) : (
                  <Component
                    {...(!!prop ? { [prop]: value } : {})}
                    {...(!!transform
                      ? { css: [`text-transform: ${transform};`] }
                      : {})}
                  >
                    {!prop &&
                      ((Array.isArray(value) ? value.length : value) ?? (
                        <NotDefined />
                      ))}
                  </Component>
                )}
              </RowCell>
            )
          }
        )}
      </Row>
    )
  },
})
