import React, { FC, useState, useEffect, useMemo } from 'react'

import 'twin.macro'
import { useParams, useNavigate } from 'react-router-dom'

import {
  Informations,
  InformationsSection,
} from '../../../ui-blocks/information'
import Text from '../../../ui-blocks/text'
import Status from '../../../ui-blocks/status'
import Button from '../../../ui-blocks/button'
import Search from '../../../ui-blocks/search'
import Switch from '../../../ui-blocks/switch'
import KPICard from '../../../ui-blocks/kpi-card'
import Container from '../../../ui-blocks/container'
import BreadcrumbNav from '../../../ui-blocks/breadcrumb-nav'

import EditNetworkModal from './components/edit-network-modal'

import NotFound from '../../../components/not-found'
import InlineDate from '../../../components/inline-date'
import StatusTabs from '../../../components/status-tabs'
import LoadingPage from '../../../components/loading-page'
import ManagePlayersModal from '../../../components/manage-players-modal'
import ArchiveWarningModal from '../../../components/archive-warning-modal'
import { PlayersListTable } from '../../../components/player/table'

import {
  EnumPlayerStatus,
  EnumNetworkStatus,
  useViewNetworkQuery,
  useArchiveNetworkMutation,
  useUpdateNetworkActivationMutation,
} from '../../../graphql/components'
import { SortSettings } from '../../../typings'

import {
  getKPIIntervals,
  groupStatsWithIntervals,
} from '../../../utils/stats-intervals'
import { useSearchTerms } from '../../../utils/use-search'

import { IoIosTrash, IoMdAdd } from 'react-icons/io'

import { alertsManager } from '../../../stores'
import { AlertVariant } from '../../../stores/alerts-manager'

const ViewNetworkPage: FC = () => {
  const navigate = useNavigate()
  const { network_id } = useParams()

  const [
    archiveNetwork,
    { loading: archivingNetwork },
  ] = useArchiveNetworkMutation()
  const [updateNetworkActivation] = useUpdateNetworkActivationMutation()

  const intervals = useMemo(getKPIIntervals, [])
  const { searchText, setSearchText, searchTextDebounced } = useSearchTerms()

  const [currentTab, setCurrentTab] = useState<string>('all')
  const [sortBy, setSortBy] = useState<SortSettings>({
    column: '',
    isAscending: false,
  })

  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false)
  const [isWarningModalOpen, setIsWarningModalOpen] = useState<boolean>(false)
  const [
    isManagePlayersModalOpen,
    setIsManagePlayersModalOpen,
  ] = useState<boolean>(false)

  const {
    data: networkData,
    loading: loadingNetwork,
    refetch: refetchNetwork,
  } = useViewNetworkQuery({
    skip: !network_id,
    variables: {
      _id: network_id,
      intervals: intervals.map(({ starts_at, ends_at }) => ({
        starts_at,
        ends_at,
      })),
    },
  })

  // Don't render a network's page if it is archived
  const { network } = networkData || {}
  useEffect(() => {
    if (network?.status === EnumNetworkStatus.Archived)
      navigate('/networks', { replace: true })
  }, [network])

  const stats = useMemo(
    () => groupStatsWithIntervals(intervals, network?.stats || []),
    [intervals, network]
  )

  if (loadingNetwork) return <LoadingPage />
  if (!network) return <NotFound />

  const filteredPlayers = (network?.players || [])
    .filter((player) =>
      currentTab === 'all' ? player : player?.player?.status === currentTab
    )
    .map((player) => player?.player)
  const playerList = filteredPlayers.filter((player) =>
    searchTextDebounced
      ? new RegExp(searchTextDebounced, 'i').test(player?.name || '')
      : player
  )

  const handleUpdateError = (action: 'archive' | 'activate' | 'deactivate') => {
    alertsManager.emit({
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'update-network-status-error-alert',
      message: `We couldn't ${action} this network. Please try again later.`,
    })
  }

  const onArchiveNetwork = async () => {
    try {
      const res = await archiveNetwork({ variables: { ids: [network_id] } })
      if (!res.data?.areArchived) return handleUpdateError('archive')
      navigate('/networks', { replace: true, state: { refetch: true } })
    } catch {
      handleUpdateError('archive')
    }
  }

  const onUpdateNetworkActivation = async (activate: boolean) => {
    try {
      const res = await updateNetworkActivation({
        variables: { ids: [network_id], is_active: activate },
      })
      if (!res.data?.areUpdated)
        return handleUpdateError(activate ? 'activate' : 'deactivate')
      document.dispatchEvent(new CustomEvent('refetch-networks'))
      await refetchNetwork()
    } catch {
      handleUpdateError(activate ? 'activate' : 'deactivate')
    }
  }

  return (
    <Container>
      <BreadcrumbNav
        tw="mb-1"
        crumbs={[
          { label: 'Networks', linkTo: '/networks' },
          { label: network.name },
        ]}
      />

      <div tw="flex flex-row items-center justify-between mb-6">
        <div tw="flex flex-row items-center">
          <Switch
            disabled={network.status === EnumNetworkStatus.Archived}
            initialChecked={network.status === EnumNetworkStatus.Activated}
            onSelect={onUpdateNetworkActivation}
            tw="-ml-2 mr-2"
          />
          <Text as="h1" preset="h3">
            {network.name}
          </Text>
        </div>
        <Button
          secondary
          type="button"
          iconLeft={IoIosTrash}
          onClick={() => setIsWarningModalOpen(true)}
        >
          Archive
        </Button>
      </div>

      <div tw="whitespace-pre-line mb-14">
        <Informations
          title="Network Information"
          onEdit={() => setIsEditModalOpen(true)}
        >
          <InformationsSection label="STATUS">
            <Status value={network.status as EnumNetworkStatus} />
          </InformationsSection>
          <InformationsSection label="PLAYERS">
            {network.players?.length}
          </InformationsSection>
          <InformationsSection label="LAST UPDATE">
            <InlineDate date={new Date(network?.created?.at)} />
          </InformationsSection>
        </Informations>
      </div>

      <KPICard title="Network Analytics" intervals={stats} tw="mb-20" />

      <StatusTabs
        activeTab={currentTab}
        onSelectTab={setCurrentTab}
        tabs={[
          { key: 'all', label: 'All' },
          { key: EnumPlayerStatus.Activated, label: 'Active' },
          { key: EnumPlayerStatus.Deactivated, label: 'Deactivated' },
          { key: EnumPlayerStatus.Archived, label: 'Archived' },
        ]}
        tabBarExtraContent={() => (
          <div tw="flex">
            <Search
              value={searchText}
              entries={playerList.map((player) => player?.name || '')}
              onSelect={(value) =>
                setSearchText(!Array.isArray(value) ? value || '' : '')
              }
            />
            <Button
              primary
              iconLeft={IoMdAdd}
              onClick={() => setIsManagePlayersModalOpen(true)}
              tw="ml-10 w-72"
            >
              Manage Players
            </Button>
          </div>
        )}
      />
      <PlayersListTable
        sortBy={sortBy}
        data={playerList}
        loading={loadingNetwork}
        onSort={setSortBy}
      />

      <EditNetworkModal
        open={isEditModalOpen}
        networkId={network_id}
        refetchData={refetchNetwork}
        onClose={() => setIsEditModalOpen(false)}
      />
      <ManagePlayersModal
        open={isManagePlayersModalOpen}
        asset={{ id: network_id, type: 'Network' }}
        refetchData={refetchNetwork}
        onClose={() => setIsManagePlayersModalOpen(false)}
      />
      <ArchiveWarningModal
        target="network"
        redirectPage="Networks"
        open={isWarningModalOpen}
        loading={archivingNetwork}
        onConfirm={onArchiveNetwork}
        onCancel={() => setIsWarningModalOpen(false)}
      />
    </Container>
  )
}

export default ViewNetworkPage
