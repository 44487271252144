import React, { FC } from 'react'
import tw, { css } from 'twin.macro'

const Tag: FC<{ text: string }> = ({ text }) => (
  <div
    css={css`
      ${tw`text-white
      text-xs
      leading-tight
      bg-dark-blue-gray
      border
      rounded
      h-6
      px-2
      py-1
    `}
    `}
  >
    {text}
  </div>
)
export default Tag
