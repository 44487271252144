import React, { Component, FC, useState } from 'react'
import { inject, observer } from 'mobx-react'
import tw, { css } from 'twin.macro'
import {
  IoIosArrowDown,
  IoIosArrowUp,
  IoMdClose,
  IoMdRefresh,
  IoIosCheckmarkCircle,
  IoIosAlert,
} from 'react-icons/io'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import { formatFileSize } from '../utils/formaters'
import Text from './text'
import Card from './card'
import ModalAction from './modal-action'

import UploadsManager, { UploadState, Upload } from '../stores/uploads-manager'

@inject('uploadsManager')
@observer
export default class UploadsDashboard extends Component<{
  uploadsManager?: UploadsManager
}> {
  public render() {
    const { uploadsManager } = this.props

    return (
      <div
        css={css`
          ${tw`fixed flex-no-wrap flex-row w-0 overflow-visible`}
          bottom: 2rem;
          left: 2rem;
        `}
      >
        {!!uploadsManager?.uploadsBar.length &&
          uploadsManager?.uploadsBar.length > 0 && (
            <UploadBar uploadsManager={uploadsManager} />
          )}
        {/* TODO: In case we have files being uploaded, prevent closing the window */}
      </div>
    )
  }
}

const UploadBar: FC<{ uploadsManager: UploadsManager }> = ({
  uploadsManager,
}) => {
  const [dismissModalOpen, setDismissModalOpen] = useState(false)
  const [open, setOpen] = useState(false)
  const Arrow = open ? IoIosArrowDown : IoIosArrowUp

  let minProgress = 100
  let currentState = UploadState.WAITING
  for (const upload of uploadsManager.uploadsBar) {
    minProgress = Math.min(minProgress, upload.progress || 0)
    currentState = upload.state
  }

  const dismissAll = () => {
    uploadsManager.uploadsBar.forEach((upload) =>
      uploadsManager.dismiss(upload.id)
    )
  }

  const requestDismissAll = () => {
    const fails = !!uploadsManager.uploadsBar.find(
      (upload) => upload.state === UploadState.FAILED
    )
    if (fails) {
      setDismissModalOpen(true)
    } else {
      dismissAll()
    }
  }

  return (
    <Card
      css={css`
        width: 26rem;
        z-index: 100;
      `}
    >
      <div
        css={css`
          ${tw`px-4 py-3 bg-lighter-blue flex flex-row items-center justify-between`}
        `}
      >
        <div tw="flex flex-row items-center">
          <Text as="span" preset="h4" transform="uppercase">
            Upload
          </Text>
          <Text
            as="span"
            preset="p1"
            tw="ml-6"
          >{`${uploadsManager.uploadsBar.length} files`}</Text>
        </div>

        {currentState !== UploadState.UPLOADING &&
          currentState !== UploadState.FAILED && (
            <span
              css={css`
                ${tw`flex-auto text-metallic-seaweed ml-6`}
              `}
            >
              {`${Math.round(minProgress * 100)} %`}
            </span>
          )}

        {currentState === UploadState.UPLOADING &&
          Math.round(minProgress * 100) === 100 && (
            <span
              css={css`
                ${tw`flex-auto text-metallic-seaweed ml-6`}
              `}
            >
              {`Converting...`}
            </span>
          )}

        {currentState === UploadState.FAILED && (
          <span
            css={css`
              ${tw`flex-auto text-brick-red ml-6`}
            `}
          >
            {`Failed to Upload...`}
          </span>
        )}

        <div tw="flex flex-row items-center">
          <Arrow
            onClick={() => setOpen(!open)}
            css={css`
              ${tw`text-charcoal mr-2 cursor-pointer`}
            `}
          />
          <IoMdClose
            onClick={requestDismissAll}
            css={css`
              ${tw`text-charcoal cursor-pointer`}
            `}
          />
        </div>
      </div>
      {open && (
        <>
          {uploadsManager.uploadsBar.map((upload, i) => (
            <UploadComponent
              key={i}
              upload={upload}
              uploadsManager={uploadsManager}
            />
          ))}
          <div
            css={css`
              display: flex;
              justify-content: space-between;

              & > * {
                flex: 1 0 auto;
              }
            `}
          >
            <button
              css={css`
                ${tw`p-4 text-charcoal focus:underline focus:outline-none`}
                &:hover {
                  ${tw`underline`}
                }
              `}
              onClick={requestDismissAll}
            >
              Dismiss All
            </button>
          </div>
        </>
      )}
      <ModalAction
        open={dismissModalOpen}
        title="Dismiss errors?"
        content="Some of your files weren't successfully uploaded. Do you still want to dismiss all files?"
        cancelButtonText="Go back"
        confirmButtonText="Dismiss"
        onCancel={() => setDismissModalOpen(false)}
        onConfirm={() => {
          dismissAll()
          setDismissModalOpen(false)
        }}
      />
    </Card>
  )
}

const UploadComponent: FC<{
  upload: Upload
  uploadsManager: UploadsManager
}> = ({ upload, uploadsManager }) => {
  const progress = Math.round((upload?.progress || 0) * 100)

  return (
    <div
      css={css`
        ${tw`px-4 pt-4 w-full`}
      `}
    >
      <div
        css={css`
          ${tw`flex justify-between text-charcoal w-full items-center flex-shrink-0`}
          white-space: nowrap;
          & > * {
            margin: 0 0.5em;

            &:last-of-type {
              margin-right: 0;
            }
          }
        `}
      >
        <span
          css={css`
            margin-left: 0;
            overflow: hidden;
            text-overflow: ellipsis;
          `}
        >
          {upload.file.name}
        </span>
        <span
          css={css`
            ${tw`text-xs`}
          `}
        >
          {formatFileSize(upload.file.size)}
        </span>

        {upload.state === UploadState.UPLOADED && (
          <div
            css={css`
              ${tw`text-metallic-seaweed text-xs inline-flex items-center`}
              & > * {
                margin-right: 2px;
              }
            `}
          >
            <IoIosCheckmarkCircle />
            Uploaded
          </div>
        )}

        {upload.state === UploadState.FAILED && (
          <div
            css={css`
              ${tw`text-brick-red text-xs inline-flex items-center`}
              & > * {
                margin-right: 2px;
              }
            `}
          >
            <IoIosAlert />
            Error
          </div>
        )}

        {upload.state === UploadState.CANCELLED && (
          <div
            css={css`
              ${tw`text-flickr-blue text-xs inline-flex items-center`}
              & > * {
                margin-right: 2px;
              }
            `}
          >
            <IoIosAlert />
            Cancelled
          </div>
        )}

        {upload.state === UploadState.WAITING && (
          <AiOutlineLoading3Quarters
            css={css`
              ${tw`inline text-center animate-spin`}
            `}
          />
        )}

        {(upload.state === UploadState.FAILED ||
          upload.state === UploadState.CANCELLED) && (
          <IoMdRefresh
            css={css`
              ${tw`text-charcoal cursor-pointer`}
              min-width: 1em;
            `}
            onClick={() => uploadsManager.retry(upload.id)}
          />
        )}

        {upload.state !== UploadState.UPLOADED && (
          <IoMdClose
            css={css`
              ${tw`text-charcoal cursor-pointer`}
              min-width: 1em;
            `}
            onClick={() => uploadsManager.cancel(upload.id)}
          />
        )}

        {upload.state === UploadState.UPLOADED && (
          <button
            css={css`
              ${tw`text-xs text-charcoal focus:underline focus:outline-none`}
              &:hover {
                ${tw`underline`}
              }
            `}
            onClick={() => uploadsManager.dismiss(upload.id)}
          >
            dismiss
          </button>
        )}
      </div>

      <div
        css={css`
          height: 2px;
          ${tw`bg-dark-blue-gray`}
          ${upload.state === UploadState.FAILED && tw`bg-brick-red`}
          ${upload.state === UploadState.UPLOADED && tw`bg-transparent`}
          ${upload.state === UploadState.CANCELLED && tw`bg-flickr-blue`}
        `}
      >
        <div
          css={css`
            height: 2px;
            width: ${progress}%;
            ${upload.state === UploadState.UPLOADING && tw`bg-metallic-seaweed`}
            ${upload.state === UploadState.UPLOADED && tw`bg-transparent`}
          `}
        />
      </div>
    </div>
  )
}
