import React, { FC } from 'react'
import 'twin.macro'

import Text from '../../../ui-blocks/text'

export interface StepSectionProps {
  index: number
  title: string
  content: React.ReactNode
  description?: string | React.ReactNode
}

const StepSection: FC<StepSectionProps> = ({
  index,
  title,
  description,
  content,
}) => (
  <section tw="h-full">
    <div tw="w-full mb-10">
      <p tw="font-normal text-xs text-purple uppercase leading-tight mb-2">
        Step {index}
      </p>
      <Text as="h1" preset="h3" tw="mb-2">
        {title}
      </Text>
      {typeof description === 'string' ? (
        <Text as="h2" preset="p1" tw="text-dark-blue-gray">
          {description}
        </Text>
      ) : (
        description
      )}
    </div>
    {content}
  </section>
)

export default StepSection
