import React, { FC, useMemo, useState } from 'react'
import tw from 'twin.macro'

import moment from 'moment'
import { useParams } from 'react-router-dom'
import { Chart, LineAdvance } from 'bizcharts'

import Card from '../../../../ui-blocks/card'
import Spinner from '../../../../ui-blocks/spinner'
import ViewControls from '../../../../ui-blocks/view-controls'

import { useGetWorkspaceStatsQuery } from '../../../../graphql/components'

import {
  getPastDaysIntervals,
  groupStatsWithIntervals,
} from '../../../../utils/stats-intervals'

const OPTION_TO_DAYS: {
  [optionId: string]: { days: number; label: string }
} = {
  'last-7-days': { days: 7, label: 'Last 7 days' },
  'last-30-days': { days: 30, label: 'Last 30 days' },
}

export type StatsMetric = 'reach' | 'engagement'

export interface SummaryChartProps {
  title: string
  metric?: StatsMetric
}

const SummaryChart: FC<SummaryChartProps> = ({ title, metric = 'reach' }) => {
  const { workspace_id } = useParams()
  const [days, setDays] = useState<number>(7)

  const intervals = useMemo(() => getPastDaysIntervals(days), [days])
  const { data, loading } = useGetWorkspaceStatsQuery({
    fetchPolicy: 'network-only',
    skip: !workspace_id || intervals.length === 0,
    variables: {
      _id: workspace_id,
      intervals: intervals.map(({ starts_at, ends_at }) => ({
        starts_at,
        ends_at,
      })),
    },
  })

  const chartData = groupStatsWithIntervals(
    intervals,
    data?.workspace?.stats || []
  )
    .map((interval) => ({
      day: interval.title || '',
      value: interval[metric],
    }))
    .reverse()

  return (
    <Card tw="py-8 px-6">
      <h3 tw="uppercase text-dark-blue-gray font-normal leading-tight mb-4">
        {title}
      </h3>
      <div tw="flex items-center justify-between mb-10">
        <span tw="text-dark-blue-gray font-normal text-sm leading-tight">
          {`Time played during ${moment().format(
            'MMM D, YYYY'
          )} to ${moment().subtract(days, 'days').format('MMM D, YYYY')}`}
        </span>
        <ViewControls
          initialOptionId="last-7-days"
          onSelect={(optionId) => setDays(OPTION_TO_DAYS[optionId].days)}
          options={Object.keys(OPTION_TO_DAYS).map((optionKey) => ({
            id: optionKey,
            label: OPTION_TO_DAYS[optionKey].label,
          }))}
        />
      </div>
      {loading ? (
        <div css={['min-height: 300px;', tw`flex items-center justify-center`]}>
          <Spinner />
        </div>
      ) : (
        <Chart autoFit height={300} data={chartData}>
          <LineAdvance
            area
            type="smooth"
            color="#6D0FB1"
            position={`day*value`}
          />
        </Chart>
      )}
    </Card>
  )
}

export default SummaryChart
