import '@openfonts/fira-sans_all'
import './index.css'
import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider } from '@apollo/react-hooks'
import { Provider as MobXProvider } from 'mobx-react'
import { GlobalStyles } from 'twin.macro'
import Router from './router'
import * as serviceWorker from './service-worker'
import apolloClient from './graphql/client'
import * as stores from './stores'
import { Elements } from '@stripe/react-stripe-js'
import stripe from './stripe'

ReactDOM.render(
  <ApolloProvider client={apolloClient}>
    <MobXProvider {...stores}>
      <GlobalStyles />
      <Elements stripe={stripe}>
        <Router />
      </Elements>
    </MobXProvider>
  </ApolloProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
