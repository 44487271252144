import React, { FC, Fragment } from 'react'
import 'twin.macro'

import { object, string } from 'yup'
import { useFormikContext } from 'formik'

import Input from '../../../../ui-blocks/input'

import { CreateCampaignFormikValues } from '..'

export const campaignDetailsValidationSchema = object().shape({
  name: string()
    .trim('Value cannot have leading or trailing white spaces')
    .required('Name is required')
    .strict(true),
})

const CampaignDetailsStep: FC = () => {
  const {
    values,
    errors,
    isSubmitting,
    getFieldMeta,
    handleChange,
  } = useFormikContext<CreateCampaignFormikValues>()
  const meta = getFieldMeta<CreateCampaignFormikValues>('name')

  return (
    <Fragment>
      <p tw="font-medium mb-4">Information</p>
      <div tw="max-w-xs">
        <Input
          type="text"
          name="name"
          label="Name"
          value={values.name}
          error={errors.name}
          disabled={isSubmitting}
          success={meta.touched && !meta.error}
          onChange={handleChange}
        />
      </div>
    </Fragment>
  )
}

export default CampaignDetailsStep
