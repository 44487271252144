import React, { FC, Ref, useState } from 'react'
import tw, { css } from 'twin.macro'

import Text from './text'

import { GoPrimitiveDot } from 'react-icons/go'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'

import { IFormikObject } from '../typings'
import { IconBaseProps, IconType } from 'react-icons/lib'

type InputBaseProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

interface InputProps extends InputBaseProps {
  inputRef?: Ref<HTMLInputElement>
  id?: string
  initialValue?: string
  hideSteppers?: boolean
  disabled?: boolean
  placeholder?: string
  label?: string | React.ReactNode
  labelExtra?: string
  leftLabel?: string
  helperText?: string
  success?: boolean
  error?: string
}

const Input: FC<InputProps> = ({
  inputRef,
  id,
  initialValue,
  hideSteppers,
  type,
  placeholder,
  disabled,
  label,
  labelExtra,
  leftLabel,
  helperText,
  success,
  error,
  className,
  ...props
}) => {
  const [isHidden, setIsHidden] = useState<boolean>(true)

  const inputId = id || `input-${Math.random().toString(36).substring(2, 9)}`
  return (
    <div tw="w-full" className={className}>
      {label && (
        <Text
          as="label"
          tw="flex flex-row justify-between pb-2"
          htmlFor={inputId}
        >
          <Text as="span" preset="caption">
            {label}
          </Text>
          {labelExtra && (
            <Text as="span" preset="caption" transform="lowercase">
              {labelExtra}
            </Text>
          )}
        </Text>
      )}
      <div tw="relative">
        <div tw="flex flex-row items-center">
          {leftLabel && (
            <Text as="span" preset="p1" tw="mr-3">
              {leftLabel}
            </Text>
          )}
          <input
            id={inputId}
            ref={inputRef}
            defaultValue={initialValue}
            type={isHidden ? type || 'text' : 'text'}
            disabled={disabled}
            placeholder={placeholder}
            css={css`
              transition: all 100ms ease-in-out;
              ${tw`bg-white text-charcoal border border-platinum rounded py-2 px-4 block w-full appearance-none leading-normal`}
              &:hover {
                ${(!disabled && tw`border-light-peri`) || ``}
              }
              ${tw`focus:outline-none focus:border-spanish-violet`}
              ${(success && tw`border-metallic-seaweed`) || ``}
              ${(!!disabled && tw`opacity-75 cursor-not-allowed`) || ``}
              ${(!!error && tw`border-brick-red bg-white`) || ``}
              ${!!hideSteppers &&
              `
                &::-webkit-inner-spin-button, 
                &::-webkit-outer-spin-button { 
                  -webkit-appearance: none; 
                  margin: 0; 
                }
              `}
            `}
            {...props}
          />
        </div>
        {type !== 'password' && (error || success) && (
          <InputIcon
            size={8}
            error={!!error}
            success={success}
            icon={GoPrimitiveDot}
          />
        )}
        {type === 'password' &&
          (isHidden ? (
            <InputIcon
              size={16}
              error={!!error}
              success={success}
              icon={AiFillEye}
              onClick={() => setIsHidden(false)}
            />
          ) : (
            <InputIcon
              size={16}
              error={!!error}
              success={success}
              icon={AiFillEyeInvisible}
              onClick={() => setIsHidden(true)}
            />
          ))}
      </div>
      {!!helperText && (
        <span tw="font-normal text-xs text-dark-blue-gray leading-tight block mt-1">
          {helperText}
        </span>
      )}
      {!!error && (
        <span tw="font-normal text-xs text-brick-red leading-tight block mt-1">
          {error}
        </span>
      )}
    </div>
  )
}

interface InputIconProps extends IconBaseProps {
  icon: IconType
  error?: boolean
  success?: boolean
}

const InputIcon: FC<InputIconProps> = ({
  size,
  error,
  success,
  onClick,
  icon: Icon,
  ...props
}) => (
  <Icon
    size={size}
    onClick={onClick}
    css={css`
      top: 50%;
      right: 1rem;
      transform: translateY(-50%);
      ${tw`absolute text-dark-blue-gray`}
      ${success && tw`text-metallic-seaweed`}
      ${error && tw`text-purple`}
      ${!!onClick && tw`cursor-pointer`}
    `}
    {...props}
  />
)

export default Input

export const InputFormik: FC<
  InputProps & {
    formik: IFormikObject<any>
    name: string
  }
> = ({ formik, ...props }) => {
  const meta = formik.getFieldMeta(props.name)
  return (
    <Input
      success={meta.touched && !meta.error}
      error={meta.error}
      value={meta.value}
      {...props}
    />
  )
}
