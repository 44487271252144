import React, { FC } from 'react'
import { useNavigate } from 'react-router'
import Button, { ButtonProps } from './button'

const ButtonLink: FC<
  ButtonProps & {
    to: string
  }
> = ({ to, ...props }) => {
  const navigate = useNavigate()
  return <Button {...props} onClick={() => navigate(to)} />
}

export default ButtonLink
