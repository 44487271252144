import { FormikHelpers } from 'formik'
import apolloClient from '../../../graphql/client'
import { IFormikValues, Step } from './typings'
import {
  AuthForgotPasswordVerifyCodeMutation,
  AuthForgotPasswordVerifyCodeMutationVariables,
  AuthForgotPasswordVerifyCodeDocument,
  AuthForgotPasswordSendCodeMutation,
  AuthForgotPasswordSendCodeMutationVariables,
  AuthForgotPasswordSendCodeDocument,
  AuthForgotPasswordResetMutation,
  AuthForgotPasswordResetMutationVariables,
  AuthForgotPasswordResetDocument,
  ForgotPasswordMethod,
  Dashboard__AuthAccess,
} from '../../../graphql/components'
import { authentication } from '../../../stores'

export async function sendCode(
  method: ForgotPasswordMethod,
  identifier: string
): Promise<boolean> {
  const res = await apolloClient.mutate<
    AuthForgotPasswordSendCodeMutation,
    AuthForgotPasswordSendCodeMutationVariables
  >({
    mutation: AuthForgotPasswordSendCodeDocument,
    variables: {
      method,
      identifier,
    },
  })

  return res.data?.codeSent || false
}

export async function verifyCode(
  method: ForgotPasswordMethod,
  identifier: string,
  code: string
): Promise<string | undefined> {
  const res = await apolloClient.mutate<
    AuthForgotPasswordVerifyCodeMutation,
    AuthForgotPasswordVerifyCodeMutationVariables
  >({
    mutation: AuthForgotPasswordVerifyCodeDocument,
    variables: {
      method,
      identifier,
      code,
    },
  })

  return res.data?.recoveryToken || undefined
}

export async function resetPassword(
  recoveryToken: string,
  password: string
): Promise<Dashboard__AuthAccess> {
  const res = await apolloClient.mutate<
    AuthForgotPasswordResetMutation,
    AuthForgotPasswordResetMutationVariables
  >({
    mutation: AuthForgotPasswordResetDocument,
    variables: {
      recoveryToken,
      password,
    },
  })

  if (!res.data?.authAccess) {
    throw new Error('unable to get userAuthAccess object')
  }

  return res.data.authAccess
}

export async function onSubmit(
  values: IFormikValues,
  helpers: FormikHelpers<IFormikValues>
) {
  const {
    step,
    method,
    email,
    phoneNumber,
    code,
    password,
    recoveryToken,
  } = values
  const identifier =
    (method === ForgotPasswordMethod.Email && email) ||
    (method === ForgotPasswordMethod.Phone && phoneNumber) ||
    ''

  if (step === Step.SendCode) {
    await sendCode(method, identifier)

    helpers.setFieldValue('step', Step.VerifyCode)
  }

  if (step === Step.VerifyCode) {
    if (!code) {
      helpers.setFieldError('code', 'Invalid code')
      return false
    }

    const newRecToken = await verifyCode(method, identifier, code)

    if (!newRecToken) {
      helpers.setFieldError('code', 'Invalid code')
      return false
    }

    helpers.setFieldValue('recoveryToken', newRecToken)
    helpers.setFieldValue('step', Step.ResetPassword)
  }

  if (step === Step.ResetPassword) {
    if (!recoveryToken) {
      helpers.setFieldError('recoveryToken', 'Invalid recovery token')
      return false
    }

    if (!password) {
      helpers.setFieldError('password', 'Invalid password')
      return false
    }

    const authAccess = await resetPassword(recoveryToken, password)
    authentication.setAuthAccess(authAccess)

    helpers.setFieldValue('step', Step.PasswordResetSuccessfully)
  }

  return true
}
