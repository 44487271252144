import React, { FC, useEffect, useState } from 'react'

import 'twin.macro'
import { useLocation } from 'react-router-dom'

import Search from '../../../ui-blocks/search'
import Spacer from '../../../ui-blocks/spacer'
import Suspense from '../../../ui-blocks/suspense'
import Container from '../../../ui-blocks/container'
import ButtonLink from '../../../ui-blocks/button-link'
import PaginationNav from '../../../ui-blocks/pagination-nav'

import NetworksEmpty from './components/networks-empty'

import StatusTabs from '../../../components/status-tabs'
import LoadingPage from '../../../components/loading-page'
import { NetworksListTable } from '../../../components/network/table'

import {
  useCountQuery,
  useListNetworksQuery,
  EnumNetworkStatus,
} from '../../../graphql/components'
import { SortSettings } from '../../../typings'

import { IoMdAdd } from 'react-icons/io'

import usePagination from '../../../utils/use-pagination'
import { useSearchTerms } from '../../../utils/use-search'
import { MIN_EVER, END_OF_DAY } from '../../../utils/stats-intervals'

const ListNetworksPage: FC = () => {
  const location = useLocation()
  const shouldRefetch = (location.state || ({} as any)).refetch as boolean

  const {
    data: countData,
    loading: loadingCount,
    refetch: refetchCount,
  } = useCountQuery({ fetchPolicy: 'no-cache' })
  const networksCount = countData?.networks || 0
  const pagination = usePagination(networksCount, 15)

  const [currentTab, setCurrentTab] = useState<string>('all')
  const [sortBy, setSortBy] = useState<SortSettings>({
    column: '',
    isAscending: false,
  })

  const { searchText, setSearchText, searchTextDebounced } = useSearchTerms()
  const {
    data: networksData,
    error: networksError,
    loading: loadingNetworks,
    refetch: refetchNetworks,
  } = useListNetworksQuery({
    fetchPolicy: 'no-cache',
    variables: {
      limit: pagination.limit,
      offset: pagination.offset,
      filter: { search: searchTextDebounced },
      intervals: [{ starts_at: MIN_EVER, ends_at: END_OF_DAY }],
    },
  })

  const refetchData = async () => {
    await refetchCount()
    await refetchNetworks()
  }

  useEffect(() => {
    shouldRefetch && refetchData()
  }, [shouldRefetch])

  useEffect(() => {
    document.addEventListener('refetch-networks', refetchData)
    return () => {
      document.removeEventListener('refetch-networks', refetchData)
    }
  }, [])

  const allNetworks = networksData?.networks || []
  const filteredNetworks = allNetworks.filter((network) =>
    currentTab === 'all' ? network : network.status === currentTab
  )

  return (
    <Container>
      <Suspense ready={!loadingCount} fallback={<LoadingPage />}>
        <div tw="flex flex-row items-center justify-between mb-10">
          <div tw="flex flex-row items-end">
            <h1 tw="text-charcoal font-light leading-tight text-3xl">
              Networks
            </h1>
            {!!networksCount && (
              <span tw="text-charcoal text-sm tracking-wide ml-4">
                {loadingNetworks ? 'Loading...' : `${networksCount} TOTAL`}
              </span>
            )}
          </div>
          {!!networksCount && (
            <div tw="flex flex-row items-center">
              <div tw="w-80">
                <Search
                  value={searchText}
                  loading={loadingNetworks}
                  entries={filteredNetworks.map((network) => network.name)}
                  onSelect={(value) =>
                    setSearchText(!Array.isArray(value) ? value || '' : '')
                  }
                />
              </div>
              <Spacer size="2.5rem" direction="horizontal" />
              <ButtonLink iconLeft={IoMdAdd} to="/networks/create">
                Add Network
              </ButtonLink>
            </div>
          )}
        </div>
        <Suspense ready={!!networksCount} fallback={<NetworksEmpty />}>
          <StatusTabs
            activeTab={currentTab}
            onSelectTab={setCurrentTab}
            tabs={[
              { key: 'all', label: 'All' },
              { key: EnumNetworkStatus.Activated, label: 'Active' },
              {
                key: EnumNetworkStatus.Deactivated,
                label: 'Deactivated',
              },
              { key: EnumNetworkStatus.Archived, label: 'Archived' },
            ]}
          />
          <NetworksListTable
            sortBy={sortBy}
            error={networksError}
            data={filteredNetworks}
            loading={loadingNetworks}
            searchQuery={searchTextDebounced}
            onSort={setSortBy}
          />
          <PaginationNav
            page={pagination.page}
            count={pagination.count}
            limit={pagination.limit}
            setPage={pagination.setPage}
            tw="mt-8"
          />
        </Suspense>
      </Suspense>
    </Container>
  )
}

export default ListNetworksPage
