import React, { FC } from 'react'
import 'twin.macro'

export interface NoResultsProps {
  model: string
  searchQuery?: string
  Illustration?: React.FunctionComponent
}

const NoResults: FC<NoResultsProps> = ({
  model,
  searchQuery,
  Illustration,
  ...props
}) => (
  <div tw="flex flex-col items-center" {...props}>
    {!!Illustration && <Illustration tw="mb-10" />}
    <h2 tw="font-light text-3xl text-charcoal leading-tight">
      No data to show
    </h2>
    <p tw="text-charcoal font-light pt-4 pb-6 w-1/2 text-center">
      {searchQuery
        ? `We can't find any ${model} for "${searchQuery}". Please check your spelling and try again.`
        : `We can't find any ${model}.`}
    </p>
  </div>
)

export default NoResults
