import React, { FC, useState } from 'react'
import 'twin.macro'

import StateToggles from './state-toggles'
import ElementSection, { ElementSectionProps } from './element-section'

import Map from '../../../../../ui-blocks/map'

import { IMapPoint } from '../../../../../typings'

import useElementStates from '../../../../../utils/use-element-states'

const getRandomNumberInRange = (min: number, max: number) =>
  Math.random() * (max - min) + min

const POINTS = [
  {
    lng: getRandomNumberInRange(-100, 100),
    lat: getRandomNumberInRange(-40, 40),
  },
  {
    lng: getRandomNumberInRange(-100, 100),
    lat: getRandomNumberInRange(-40, 40),
  },
  {
    lng: getRandomNumberInRange(-100, 100),
    lat: getRandomNumberInRange(-40, 40),
  },
  {
    lng: getRandomNumberInRange(-100, 100),
    lat: getRandomNumberInRange(-40, 40),
  },
]

const MapSection: FC<ElementSectionProps> = ({
  id,
  title,
  description,
  last,
}) => {
  const {
    states,
    values: { error, ...values },
  } = useElementStates(['error'])
  const [coords, setCoords] = useState<IMapPoint | null>(null)

  return (
    <ElementSection
      id={id}
      last={last}
      title={title}
      description={
        description || `These are the states supported by the ${title} element:`
      }
    >
      <StateToggles toggles={states} />
      <label tw="block mb-2">Editable Map</label>
      <Map
        points={!!coords ? [coords] : []}
        onSetPoint={setCoords}
        error={error ? 'Error message!' : ''}
        {...values}
      />
      <label tw="block mt-8 mb-2">Pre-filled Map</label>
      <Map
        averagePoints
        points={POINTS}
        error={error ? 'Error message!' : ''}
        {...values}
      />
    </ElementSection>
  )
}

export default MapSection
