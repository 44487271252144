import React, { FC, useState } from 'react'

import 'twin.macro'
import moment, { Moment } from 'moment'

import {
  DatePicker,
  DateRangePicker,
} from '../../../../../ui-blocks/date-picker'
import {
  DateTimePicker,
  DateTimeRangePicker,
} from '../../../../../ui-blocks/datetime-picker'
import Spacer from '../../../../../ui-blocks/spacer'

import StateToggles from './state-toggles'
import ElementSection, { ElementSectionProps } from './element-section'

import useElementStates from '../../../../../utils/use-element-states'

const DatePickerSection: FC<ElementSectionProps> = ({
  id,
  title,
  description,
  last,
}) => {
  const {
    states,
    values: { error, ...values },
  } = useElementStates(['error', 'success', 'disabled'])

  const [singleDate, setSingleDate] = useState<Moment | undefined>(undefined)
  const [singleDateTime, setSingleDateTime] = useState<Moment | undefined>(
    undefined
  )

  const [dateRange, setDateRange] = useState<{
    startDate?: Moment
    endDate?: Moment
  }>({})
  const [dateTimeRange, setDateTimeRange] = useState<{
    startDate?: Moment
    endDate?: Moment
  }>({})

  return (
    <ElementSection
      id={id}
      last={last}
      title={title}
      description={
        description || `These are the states supported by the ${title} element:`
      }
    >
      <StateToggles toggles={states} />
      <div tw="flex flex-row items-end mb-6">
        <DatePicker
          date={singleDate}
          onChange={setSingleDate}
          error={error ? 'Error message!' : ''}
          {...values}
        />
        <Spacer size="1.5rem" direction="horizontal" />
        <DatePicker
          label="Single Date Picker"
          date={singleDate}
          onChange={setSingleDate}
          error={error ? 'Error message!' : ''}
          {...values}
        />
        <Spacer size="1.5rem" direction="horizontal" />
        <DatePicker
          label="Single Date Picker with Min&Max Dates"
          date={singleDate}
          minDate={moment().subtract(1, 'month')}
          maxDate={moment().add(1, 'month')}
          onChange={setSingleDate}
          error={error ? 'Error message!' : ''}
          {...values}
        />
      </div>
      <div tw="flex flex-row items-end mb-6">
        <DateTimePicker
          date={singleDateTime}
          onChange={setSingleDateTime}
          error={error ? 'Error message!' : ''}
          {...values}
        />
        <Spacer size="1.5rem" direction="horizontal" />
        <DateTimePicker
          label="Single Date-Time Picker"
          date={singleDateTime}
          onChange={setSingleDateTime}
          error={error ? 'Error message!' : ''}
          {...values}
        />
        <Spacer size="1.5rem" direction="horizontal" />
        <DateTimePicker
          label="Single Date-Time Picker with Min&Max Dates"
          date={singleDateTime}
          minDate={moment().subtract(1, 'month')}
          maxDate={moment().add(1, 'month')}
          onChange={setSingleDateTime}
          error={error ? 'Error message!' : ''}
          {...values}
        />
      </div>
      <div tw="flex flex-row items-end mb-6">
        <DateRangePicker
          startDate={dateRange.startDate}
          endDate={dateRange.endDate}
          onChange={(startDate, endDate) =>
            setDateRange({
              startDate,
              endDate,
            })
          }
          error={error ? 'Error message!' : ''}
          {...values}
        />
        <Spacer size="1.5rem" direction="horizontal" />
        <DateRangePicker
          label="Date range Picker"
          startDate={dateRange.startDate}
          endDate={dateRange.endDate}
          onChange={(startDate, endDate) =>
            setDateRange({
              startDate,
              endDate,
            })
          }
          error={error ? 'Error message!' : ''}
          {...values}
        />
        <Spacer size="1.5rem" direction="horizontal" />
        <DateRangePicker
          label="Date range Picker with Min&Max Dates"
          startDate={dateRange.startDate}
          endDate={dateRange.endDate}
          minDate={moment().subtract(1, 'month')}
          maxDate={moment().add(1, 'month')}
          onChange={(startDate, endDate) =>
            setDateRange({
              startDate,
              endDate,
            })
          }
          error={error ? 'Error message!' : ''}
          {...values}
        />
      </div>
      <div tw="flex flex-row items-end mb-6">
        <DateTimeRangePicker
          startDate={dateTimeRange.startDate}
          endDate={dateTimeRange.endDate}
          onChange={(startDate, endDate) =>
            setDateTimeRange({
              startDate,
              endDate,
            })
          }
          error={error ? 'Error message!' : ''}
          {...values}
        />
        <Spacer size="1.5rem" direction="horizontal" />
        <DateTimeRangePicker
          label="Date-Time range Picker"
          startDate={dateTimeRange.startDate}
          endDate={dateTimeRange.endDate}
          onChange={(startDate, endDate) =>
            setDateTimeRange({
              startDate,
              endDate,
            })
          }
          error={error ? 'Error message!' : ''}
          {...values}
        />
        <Spacer size="1.5rem" direction="horizontal" />
        <DateTimeRangePicker
          label="Date-Time range Picker with Min&Max Dates"
          startDate={dateTimeRange.startDate}
          endDate={dateTimeRange.endDate}
          minDate={moment().subtract(1, 'month')}
          maxDate={moment().add(1, 'month')}
          onChange={(startDate, endDate) =>
            setDateTimeRange({
              startDate,
              endDate,
            })
          }
          error={error ? 'Error message!' : ''}
          {...values}
        />
      </div>
    </ElementSection>
  )
}

export default DatePickerSection
