import React, { FC, useState } from 'react'
import 'twin.macro'

import { useFormik } from 'formik'
import { string, object } from 'yup'

import Button from '../../../ui-blocks/button'
import Spacer from '../../../ui-blocks/spacer'
import ButtonLink from '../../../ui-blocks/button-link'
import { InputFormik } from '../../../ui-blocks/input'

import AlertMessage, { AlertVariant } from '../../../components/alert-component'

import { useAccountChangePasswordMutation } from '../../../graphql/components'

import { authentication } from '../../../stores'
import { MutationResult } from '../../../typings'

interface ChangePasswordFormikValues {
  newPassword: string
  currentPassword: string
  confirmPassword: string
}

const changePasswordValidationSchema = object().shape({
  newPassword: string().required('New password is required'),
  currentPassword: string().required('Current password is required'),
  confirmPassword: string().required('Confirm password is required'),
})

const AccountSecurity: FC = () => {
  const [alertMessage, setAlertMessage] = useState<string>('')
  const [mutationResult, setMutationResult] = useState<MutationResult>(null)

  const [changePassword] = useAccountChangePasswordMutation()
  const formik = useFormik<ChangePasswordFormikValues>({
    validateOnChange: false,
    validationSchema: changePasswordValidationSchema,
    initialValues: {
      newPassword: '',
      currentPassword: '',
      confirmPassword: '',
    },
    async onSubmit({ newPassword, currentPassword, confirmPassword }) {
      if (!authentication.refresh_token) return
      setMutationResult(null)

      if (newPassword === currentPassword) {
        setMutationResult('error')
        setAlertMessage('New password can’t be the same as current password.')
        return
      }
      if (newPassword !== confirmPassword) {
        setMutationResult('error')
        setAlertMessage('Password confirmation doesn’t match new password.')
        return
      }

      try {
        const res = await changePassword({
          variables: {
            newPassword,
            currentPassword,
            refreshToken: authentication.refresh_token,
          },
        })

        if (!res.data?.isUpdated) {
          setMutationResult('error')
          setAlertMessage(
            'An error occurred while changing your password. Please try again.'
          )
          return
        }

        setMutationResult('success')
        setAlertMessage('Password successfully updated.')
      } catch (e) {
        setMutationResult('error')
        if (
          String(e).includes(
            'the provided password does not match the current one'
          )
        ) {
          return setAlertMessage(
            'The provided current password does not match your actual current password. Please check if you typed the password correctly.'
          )
        }
        setAlertMessage(
          'An error occurred while changing your password. Please try again later.'
        )
      }
    },
  })

  return (
    <div tw="max-w-sm">
      {mutationResult && alertMessage && (
        <div tw="mb-4">
          <AlertMessage
            alert={{
              message: alertMessage,
              id: 'change-password-alert',
              variant:
                mutationResult === 'success'
                  ? AlertVariant.SUCCESS
                  : AlertVariant.ERROR,
            }}
          />
        </div>
      )}
      <h1 tw="font-medium text-charcoal leading-tight mb-2">Change password</h1>
      <p tw="font-normal text-xs text-dark-blue-gray mb-6">
        After a successful password update, you will be redirected to the login
        page where you can log in with your new password.
      </p>
      <form onSubmit={formik.handleSubmit} onChange={formik.handleChange}>
        <Field>
          <InputFormik
            required
            type="password"
            name="currentPassword"
            label="CURRENT PASSWORD"
            placeholder="*************"
            formik={formik}
          />
        </Field>
        <Field>
          <InputFormik
            required
            type="password"
            name="newPassword"
            label="NEW PASSWORD"
            placeholder="*************"
            formik={formik}
          />
        </Field>
        <Field>
          <InputFormik
            required
            type="password"
            name="confirmPassword"
            label="PASSWORD CONFIRMATION"
            placeholder="*************"
            formik={formik}
          />
        </Field>
        <div tw="flex flex-row">
          <Button
            type="submit"
            disabled={!formik.dirty}
            loading={formik.isSubmitting}
          >
            Update password
          </Button>
          <Spacer size="0.5rem" direction="horizontal" />
          <ButtonLink ghost to="/auth/forgot-password">
            Forgot my password
          </ButtonLink>
        </div>
      </form>
    </div>
  )
}

const Field: FC = ({ children }) => <div tw="max-w-xs mb-6">{children}</div>

export default AccountSecurity
