import React, { FC } from 'react'

import 'twin.macro'
import { Link } from 'react-router-dom'

import Card from '../../ui-blocks/card'
import Status from '../../ui-blocks/status'

import InlineDate from '../inline-date'
import UploadThumbnail from '../upload/thumbnail'

import {
  EnumCreativeStatus,
  CreativeListFragmentFragment,
} from '../../graphql/components'

export interface CreativeCardProps {
  creative: CreativeListFragmentFragment
  onClickThumbnail?: () => void
}

const CreativeCard: FC<CreativeCardProps> = ({
  creative,
  onClickThumbnail,
}) => {
  let title = (
    <span tw="font-medium text-charcoal truncate mb-1">{creative.name}</span>
  )
  if (creative.status !== EnumCreativeStatus.Archived) {
    title = <Link to={`/creatives/${creative._id}`}>{title}</Link>
  }

  return (
    <Card tw="overflow-hidden">
      <div tw="w-full">
        <UploadThumbnail
          upload={(creative.upload as any) || undefined}
          onClick={onClickThumbnail}
        />
      </div>
      <div tw="p-4 truncate">
        {title}
        <div tw="flex flex-row justify-between mb-2">
          <div tw="flex flex-col">
            <span tw="font-normal text-xs text-dark-blue-gray">
              {creative.stats?.[0]?.reach || 0} Impressions
            </span>
            <span tw="font-normal text-xs text-dark-blue-gray">
              {creative.stats?.[0]?.engagement || 0} Scans
            </span>
          </div>
          <InlineDate
            date={creative?.upload?.created?.at}
            tw="font-normal text-xs text-dark-blue-gray"
          />
        </div>
        <Status compact value={creative.status as EnumCreativeStatus} />
      </div>
    </Card>
  )
}

export default CreativeCard
