import React, { FC, useMemo } from 'react'

import tw from 'twin.macro'
import { useNavigate } from 'react-router'

import Spinner from '../../../ui-blocks/spinner'
import Suspense from '../../../ui-blocks/suspense'
import Container from '../../../ui-blocks/container'

import SetupForm, { Step } from '../../../components/setup-form'

import CampaignDetailsStep, {
  campaignDetailsValidationSchema,
} from './steps/details'
import TargetNetworksStep, {
  targetNetworksValidationSchema,
} from './steps/target-networks'
import TargetPlayersStep, {
  targetPlayersValidationSchema,
} from './steps/target-players'

import {
  useCountQuery,
  useCreateCampaignMutation,
} from '../../../graphql/components'

import { alertsManager } from '../../../stores'
import { AlertVariant } from '../../../stores/alerts-manager'

export interface CreateCampaignFormikValues {
  name: string
  networks_ids: string[]
  players_ids: string[]
}

const CreateCampaignPage: FC = () => {
  const navigate = useNavigate()
  const { data: countData, loading: loadingCount } = useCountQuery({
    fetchPolicy: 'no-cache',
  })

  const handleError = () => {
    alertsManager.emit({
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'create-campaign-error-alert',
      message: "We couldn't create the campaign. Please try again later.",
    })
  }

  const [createCampaign] = useCreateCampaignMutation()
  const handleSubmit = async (values: CreateCampaignFormikValues) => {
    try {
      const res = await createCampaign({
        variables: {
          input: {
            name: values.name,
            broadcasts_to: {
              marketplace: false,
              players: values.players_ids.map((player_id) => ({ player_id })),
              networks: values.networks_ids.map((network_id) => ({
                network_id,
              })),
            },
          },
        },
      })

      if (!res.data?.campaign?._id) {
        handleError()
        return false
      }

      navigate(`/campaigns/${res.data?.campaign?._id}`)
      return true
    } catch {
      handleError()
      return false
    }
  }

  const hasNetworks = useMemo(() => !!countData?.networks, [countData])
  const hasPlayers = useMemo(() => !!countData?.players, [countData])

  const steps: Step[] = [
    {
      canGoBack: true,
      title: 'Details',
      description: 'Enter the Campaign details.',
      content: <CampaignDetailsStep />,
      validationSchema: campaignDetailsValidationSchema,
    },
    ...(hasNetworks
      ? [
          {
            canGoBack: true,
            title: 'Target networks',
            description: 'Select one or more networks for your Campaign.',
            content: <TargetNetworksStep />,
            disabledTriggers: ['networks_ids'],
            validationSchema: targetNetworksValidationSchema,
          },
        ]
      : []),
    ...(hasPlayers
      ? [
          {
            canGoBack: true,
            title: 'Target players',
            description: 'Select one or more players for your Campaign.',
            content: <TargetPlayersStep />,
            disabledTriggers: ['players_ids'],
            validationSchema: targetPlayersValidationSchema,
          },
        ]
      : []),
  ]

  return (
    <Container>
      <div css={[tw`w-full self-center`, 'max-width: 68rem;']}>
        <Suspense ready={!loadingCount} fallback={<Spinner center />}>
          <SetupForm
            title="New Campaign"
            description="Create a new campaign and chose your target audience."
            initialValues={{ name: '', networks_ids: [], players_ids: [] }}
            onSubmit={{ [steps.length - 1]: handleSubmit }}
            steps={steps}
          />
        </Suspense>
      </div>
    </Container>
  )
}

export default CreateCampaignPage
