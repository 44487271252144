import React, { FC } from 'react'
import tw, { css } from 'twin.macro'

import Text from './text'
import Card from './card'
import Button from './button'

const SetupCard: FC<{
  Image?: React.FunctionComponent
  title?: string
  description?: string
  buttonText?: string
  onButtonClick?: () => void
  disabled?: boolean
}> = ({ Image, title, description, buttonText, onButtonClick, disabled }) => (
  <Card>
    <div
      css={css`
        width: 33rem;
        ${tw`p-6 flex flex-col items-center`}
        ${(!!disabled && tw`opacity-50 cursor-not-allowed`) || ``}
      `}
    >
      {!!Image && (
        <Image
          css={css`
            ${tw`object-contain`} width: 25rem;
          `}
        />
      )}
      {!!title && (
        <Text as="h1" preset="h4" tw="mt-3">
          {title}
        </Text>
      )}
      {!!description && (
        <Text as="p" preset="p1" tw="mt-2" align="center">
          {description}
        </Text>
      )}
      <div
        css={css`
          ${tw`mt-8 mb-2`}
        `}
      >
        <Button secondary disabled={disabled} onClick={onButtonClick}>
          {buttonText}
        </Button>
      </div>
    </div>
  </Card>
)

export default SetupCard
