import React, { FC, useState } from 'react'

import tw, { css } from 'twin.macro'
import { Link } from 'react-router-dom'
import OutsideClickHandler from 'react-outside-click-handler'

import Text from '../../../ui-blocks/text'
import Avatar from '../../../ui-blocks/avatar'
import ButtonLink from '../../../ui-blocks/button-link'

import { IoIosArrowDown } from 'react-icons/io'
import { AiOutlineCheck } from 'react-icons/ai'

import { authentication } from '../../../stores'
import getInitials from '../../../utils/get-initials'

import { Dashboard__WhoAmIUserWorkspaceResult } from '../../../graphql/components'

const Menu: FC<{
  logo?: {
    alt?: string
    to: string
    src: React.FunctionComponent
  }
  //Icon: React.FunctionComponent
  navItems?: Array<{
    name: string
    to: string
    isActive?: boolean
  }>
  showWorkspaceMenu?: boolean
  activeWorkspace?: Dashboard__WhoAmIUserWorkspaceResult
  workspaces?: Dashboard__WhoAmIUserWorkspaceResult[]
  onSelectWorkspace?: (
    ev: React.MouseEvent<HTMLAnchorElement>,
    id: string
  ) => void
  actions?: Array<{
    name: string
    isActive?: boolean
    onClick: () => void
  }>
}> = ({
  logo,
  navItems,
  showWorkspaceMenu,
  activeWorkspace,
  workspaces,
  onSelectWorkspace,
  actions,
}) => {
  const [open, setOpen] = useState(false)

  return (
    <header
      css={css`
        ${tw`
          bg-white
          border-b
          border-platinum
          w-full
          px-16
          py-5
          flex
          items-center
          text-charcoal
          sticky
          top-0
        `}
        z-index: 100
      `}
    >
      {!!logo && (
        <Link to={logo.to}>
          <logo.src
            css={css`
              ${tw`h-10 mr-8`}
            `}
          />
        </Link>
      )}

      <nav
        css={css`
          ${tw`flex-auto flex flex-row`}
        `}
      >
        {!!navItems &&
          navItems.map((item, index) => (
            <Link
              key={index}
              to={item.to}
              css={css`
                padding: 0 16px;
                ${tw`text-charcoal focus:text-purple`}
                &:hover {
                  ${tw`text-purple`}
                }
                ${(item.isActive && tw`text-purple`) || ''}
              `}
            >
              {item.name}
            </Link>
          ))}
      </nav>

      {authentication.is_trial_active && (
        <Text
          as="span"
          preset="p1"
          decoration="underline"
          tw="text-purple mr-8"
        >
          {authentication.trial_remaining_days
            ? `${authentication.trial_remaining_days} days left in your trial`
            : 'Your trial reached the end'}
        </Text>
      )}

      {authentication.is_trial_active && (
        <ButtonLink
          to="#"
          css={css`
            ${tw`mr-12`}
          `}
        >
          Select Plan
        </ButtonLink>
      )}

      {!!showWorkspaceMenu && (
        <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
          <div
            onClick={() => setOpen(true)}
            css={css`
              ${tw`flex items-center h-10 cursor-pointer`}
              justify-self: flex-end;
            `}
          >
            {activeWorkspace ? (
              <WorkspaceItem
                avatarSize="2.5rem"
                title={activeWorkspace.name}
                // logo={activeWorkspace.logo}
                initials={getInitials(activeWorkspace.name)}
                subtitle="1 Members"
              />
            ) : (
              <WorkspaceItem
                avatarSize="2.5rem"
                title="Empty Workspace"
                initials="?"
              />
            )}
            <IoIosArrowDown
              css={css`
                ${tw`flex ml-2 self-end text-charcoal`}
              `}
            />
          </div>

          {open && (
            <div
              css={css`
                ${tw`
                  absolute
                  z-40
                  bg-white
                  border
                  border-platinum
                  shadow-menu
                  mr-2
                `}
                top: -4px;
                right: 10px;
                margin-top: 5.25rem;
                width: 22rem;
              `}
            >
              <ul
                css={css`
                  ${tw`mt-2 w-full`}
                  overflow-y: scroll;
                  max-height: 200px;
                `}
              >
                {workspaces &&
                  workspaces.map((workspace) => (
                    <a
                      key={`workspace-${workspace._id}`}
                      onClick={(ev) => {
                        if (onSelectWorkspace)
                          onSelectWorkspace(ev, workspace._id)
                        setOpen(false)
                      }}
                    >
                      <li
                        css={css`
                          ${tw`flex items-center h-20 px-6 cursor-pointer cursor-pointer`}
                          &:hover {
                            ${tw`bg-cultured`}
                          }
                        `}
                      >
                        <WorkspaceItem
                          subtitle="1 Members"
                          title={workspace.name}
                          // logo={workspace.logo}
                          initials={getInitials(workspace.name)}
                          isActive={
                            !!activeWorkspace &&
                            String(activeWorkspace._id) ===
                              String(workspace._id)
                          }
                        />
                      </li>
                    </a>
                  ))}

                <Link
                  key="workspace-add"
                  to="/workspaces/create"
                  onClick={() => {
                    setOpen(false)
                  }}
                >
                  <li
                    css={css`
                      ${tw`flex items-center h-20 pl-6 cursor-pointer cursor-pointer`}
                      &:hover {
                        ${tw`bg-cultured`}
                      }
                    `}
                  >
                    <Avatar add />
                    <span
                      css={css`
                        ${tw`ml-2 text-charcoal font-medium`}
                      `}
                    >
                      Create or Join Workspace
                    </span>
                  </li>
                </Link>
              </ul>
              <ul
                css={css`
                  ${tw`py-2 border-t border-platinum`}
                `}
              >
                {!!actions &&
                  actions.map((action, index) => (
                    <a key={index} onClick={action.onClick}>
                      <li
                        onClick={() => setOpen(false)}
                        css={css`
                          ${tw`flex items-center p-4 cursor-pointer`}
                          ${action.isActive && tw`text-midnight-blue`}
                          &:hover {
                            ${tw`bg-cultured`}
                          }
                        `}
                      >
                        {action.name}
                      </li>
                    </a>
                  ))}
              </ul>
            </div>
          )}
        </OutsideClickHandler>
      )}
    </header>
  )
}

const WorkspaceItem: FC<{
  title: string
  logo?: string
  initials?: string
  isActive?: boolean
  avatarSize?: string
  subtitle?: string
}> = ({ title, subtitle, logo, initials, isActive, avatarSize }) => (
  <>
    <Avatar initials={initials} src={logo} size={avatarSize} />
    <div
      css={css`
        ${tw`flex ml-2 flex-auto`}
      `}
    >
      <div
        css={css`
          ${tw`flex flex-col`} min-width: 9rem;
        `}
      >
        <span
          css={css`
            ${tw`text-charcoal font-medium truncate`}
          `}
        >
          {title}
        </span>
        {!!subtitle && (
          <span
            css={css`
              ${tw`text-charcoal text-xs`}
            `}
          >
            {subtitle}
          </span>
        )}
      </div>
    </div>
    {!!isActive && (
      <AiOutlineCheck
        css={css`
          ${tw`flex ml-2 text-charcoal`}
        `}
      />
    )}
  </>
)

export default Menu
