import React, { FC } from 'react'
import tw, { css } from 'twin.macro'
import { IconBaseProps } from 'react-icons'

import CircularLoading from './circular-loading'

// @TODO: ensure that the onClick never gets called if it is disabled

type BaseTypeProps = Partial<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > &
    React.DetailedHTMLProps<
      React.AnchorHTMLAttributes<HTMLAnchorElement>,
      HTMLAnchorElement
    > &
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLSpanElement>,
      HTMLSpanElement
    >
>

export interface ButtonProps extends BaseTypeProps {
  tag?: 'button' | 'a' | 'span'
  primary?: boolean
  secondary?: boolean
  menu?: boolean
  tab?: boolean
  ghost?: boolean
  selected?: boolean
  disabled?: boolean
  loading?: boolean
  iconLeft?: FC<IconBaseProps>
  iconRight?: FC<IconBaseProps>
}

const Button: FC<ButtonProps> = ({
  primary,
  secondary,
  menu,
  tab,
  ghost,
  ...props
}) => {
  if (ghost) {
    return <Ghost secondary={secondary} {...props} />
  }
  if (secondary) {
    return <Secondary {...props} />
  }
  if (menu) {
    return <Menu {...props} />
  }
  if (tab) {
    return <Tab {...props} />
  }

  return <Primary {...props} />
}

const Content: FC<{
  iconLeft: ButtonProps['iconLeft']
  iconRight: ButtonProps['iconRight']
}> = ({ children, iconLeft: IconLeft, iconRight: IconRight }) => {
  const iconLeft = !!IconLeft && (
    <IconLeft
      css={css`
        ${tw`w-4 mr-2`}
      `}
    />
  )
  const iconRight = !!IconRight && (
    <IconRight
      css={css`
        ${tw`w-4 ml-2`}
      `}
    />
  )

  return (
    <>
      {iconLeft}
      {children}
      {iconRight}
    </>
  )
}

const baseStyle = tw`cursor-pointer rounded px-4 flex items-center justify-center`
const focusStyle = tw`focus:outline-none focus:border-flickr-blue focus:border-2`
const transition = 'transition: all 0.15s ease-in-out;'
const size =
  'height: 40px; min-width: 9rem; width: auto; box-sizing: border-box;'

const Primary: FC<ButtonProps> = ({
  tag: Tag = 'button',
  selected,
  disabled,
  loading,
  iconLeft,
  iconRight,
  children,
  ...props
}) => (
  <Tag
    css={css`
      ${baseStyle}
      ${size}
      ${transition}
      ${tw`bg-purple text-white text-center font-medium`}
      &:hover {
        ${!(selected || disabled || loading) &&
        tw`bg-spanish-violet shadow-button-primary`}
      }
      &:active,
      &:visited {
        ${tw`bg-midnight-blue`}
      }
      ${focusStyle}
      ${(loading || disabled) && tw`cursor-not-allowed`}
      ${(loading || selected) && tw`bg-midnight-blue`}
      ${disabled &&
      tw`text-lighter-purple bg-off-white border border-lighter-purple`}
    `}
    {...{ disabled: disabled || loading }}
    {...props}
  >
    {loading ? (
      <CircularLoading />
    ) : (
      <Content iconLeft={iconLeft} iconRight={iconRight}>
        {children}
      </Content>
    )}
  </Tag>
)

const Secondary: FC<ButtonProps> = ({
  tag: Tag = 'button',
  selected,
  disabled,
  loading,
  iconLeft,
  iconRight,
  children,
  ...props
}) => (
  <Tag
    css={css`
      ${baseStyle}
      ${size}
      ${transition}
      ${tw`bg-white text-charcoal border border-dark-blue-gray font-medium`}
      &:hover {
        ${!(selected || disabled || loading) &&
        tw`bg-white shadow-button-secondary`}
      }
      ${focusStyle}
      &:active,
      &:visited {
        ${tw`bg-light-purple`}
      }
      ${(loading || disabled) && tw`cursor-not-allowed`}
      ${(loading || selected) && tw`bg-light-purple`}
      ${disabled && tw`bg-white text-charcoal border border-platinum`}
    `}
    {...{ disabled: disabled || loading }}
    {...props}
  >
    {loading ? (
      <CircularLoading />
    ) : (
      <Content iconLeft={iconLeft} iconRight={iconRight}>
        {children}
      </Content>
    )}
  </Tag>
)

const Menu: FC<ButtonProps> = ({
  tag: Tag = 'button',
  selected,
  disabled,
  iconLeft,
  iconRight,
  children,
  ...props
}) => (
  <Tag
    css={css`
      ${baseStyle}
      ${size}
      ${transition}
      ${tw`text-charcoal focus:text-purple`}
      &:hover {
        ${(!disabled && tw`text-purple`) || ''}
      }
      ${focusStyle}
      ${(selected && tw`text-midnight-blue`) || ''}
      ${(disabled && tw`bg-white text-charcoal cursor-not-allowed`) || ''}
    `}
    {...{ disabled }}
    {...props}
  >
    <Content iconLeft={iconLeft} iconRight={iconRight}>
      {children}
    </Content>
  </Tag>
)

const Tab: FC<ButtonProps> = ({
  tag: Tag = 'button',
  selected,
  disabled,
  iconLeft,
  iconRight,
  children,
  ...props
}) => (
  <Tag
    css={css`
      ${baseStyle}
      ${size}
      ${transition}
      height: 56px;
      ${focusStyle}
      ${tw`text-charcoal focus:text-purple focus:border-b-4`}
      &:hover {
        ${(!selected &&
          !disabled &&
          tw`text-purple rounded-none border-0 border-b-4 border-purple py-4`) ||
        ''}
      }
      &:active,
      &:visited {
        ${tw`text-midnight-blue`}
      }
      ${(selected &&
        tw`text-midnight-blue text-purple rounded-none border-0 border-b-4 border-purple py-4`) ||
      ''}
      ${(disabled && tw`bg-white text-charcoal cursor-not-allowed`) || ''}
    `}
    {...{ disabled }}
    {...props}
  >
    <Content iconLeft={iconLeft} iconRight={iconRight}>
      {children}
    </Content>
  </Tag>
)

const Ghost: FC<ButtonProps> = ({
  tag: Tag = 'button',
  secondary,
  selected,
  disabled,
  iconLeft,
  iconRight,
  children,
  ...props
}) => {
  return (
    <Tag
      css={css`
        ${baseStyle}
        ${size}
      ${transition}
      ${tw`text-center focus:underline`}
      ${secondary ? tw`text-charcoal` : tw`text-purple`}
      &:hover {
          ${(!selected && !disabled && tw`underline`) || ''}
        }
        ${focusStyle}
        &:active,
      &:visited {
          ${secondary ? tw`text-charcoal` : tw`text-midnight-blue`}
        }
        ${(selected && tw`text-midnight-blue`) || ``}
        ${(disabled && tw`bg-white text-charcoal cursor-not-allowed`) || ''}
      `}
      {...{ disabled }}
      {...props}
    >
      <Content iconLeft={iconLeft} iconRight={iconRight}>
        {children}
      </Content>
    </Tag>
  )
}

export default Button
