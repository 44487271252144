/* eslint-disable */
import gql from 'graphql-tag'
import * as ApolloReactCommon from '@apollo/react-common'
import * as React from 'react'
import * as ApolloReactComponents from '@apollo/react-components'
import * as ApolloReactHoc from '@apollo/react-hoc'
import * as ApolloReactHooks from '@apollo/react-hooks'
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** The `ID` scalar type represents a unique MongoDB identifier in collection. MongoDB by default use 12-byte ObjectId value (https://docs.mongodb.com/manual/reference/bson-types/#objectid). But MongoDB also may accepts string or integer as correct values for _id field. */
  MongoID: any
  Date: any
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any
}

export type _IdOperatorsFilterAreaTypeInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterBroadcastPlanInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterBroadcastReportInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterCampaignAdGroupInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterCampaignInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterCategoryInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterCountryInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterCreativeInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterCurrencyInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterDistanceUnitInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindManyAreaTypeInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindManyBroadcastPlanInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindManyBroadcastReportInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindManyCampaignAdGroupInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindManyCampaignInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindManyCategoryInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindManyCountryInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindManyCreativeInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindManyCurrencyInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindManyDistanceUnitInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindManyInviteInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindManyLanguageInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindManyNetworkInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindManyPlayerInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindManyUploadInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindManyUserInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindManyVenueTypeInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindManyWebhookEventInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindManyWebhookEventResponseInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindManyWebhookInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindOneBroadcastPlanInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindOneBroadcastReportInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindOneCampaignAdGroupInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindOneCampaignInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindOneCategoryInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindOneCountryInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindOneCreativeInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindOneCurrencyInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindOneDistanceUnitInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindOneInviteInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindOneLanguageInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindOneNetworkInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindOnePlayerInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindOneUploadInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindOneUserInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterFindOneWebhookInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterInviteInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterLanguageInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterNetworkInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterPlayerInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterUpdateManyCampaignAdGroupInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterUpdateManyCampaignInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterUpdateManyCategoryInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterUpdateManyCountryInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterUpdateManyCreativeInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterUpdateManyCurrencyInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterUpdateManyDistanceUnitInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterUpdateManyLanguageInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterUpdateManyNetworkInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterUpdateManyUserInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterUpdateManyVenueTypeInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterUpdateManyWebhookInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterUpdateOneCampaignAdGroupInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterUpdateOneCampaignInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterUpdateOneCategoryInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterUpdateOneCountryInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterUpdateOneCreativeInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterUpdateOneCurrencyInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterUpdateOneDistanceUnitInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterUpdateOneLanguageInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterUpdateOneNetworkInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterUpdateOnePlayerInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterUpdateOneUserInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterUpdateOneVenueTypeInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterUpdateOneWebhookInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterUploadInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterUserInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterVenueTypeInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterWebhookInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type _IdOperatorsFilterWorkspaceInput = {
  gt?: Maybe<Scalars['MongoID']>
  gte?: Maybe<Scalars['MongoID']>
  lt?: Maybe<Scalars['MongoID']>
  lte?: Maybe<Scalars['MongoID']>
  ne?: Maybe<Scalars['MongoID']>
  in?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  nin?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type ActionDateAndUser = {
  __typename?: 'ActionDateAndUser'
  by_user_id?: Maybe<Scalars['MongoID']>
  at?: Maybe<Scalars['Date']>
}

export type ActionDateAndUserInput = {
  by_user_id?: Maybe<Scalars['MongoID']>
  at?: Maybe<Scalars['Date']>
}

export type Address = {
  __typename?: 'Address'
  street?: Maybe<Scalars['String']>
  number?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  zip?: Maybe<Scalars['String']>
  country_id?: Maybe<Scalars['MongoID']>
}

export type AddressInput = {
  street?: Maybe<Scalars['String']>
  number?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  zip?: Maybe<Scalars['String']>
  country_id?: Maybe<Scalars['MongoID']>
}

export type AreaType = {
  __typename?: 'AreaType'
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  _id: Scalars['MongoID']
}

export type BillingDetails = {
  __typename?: 'BillingDetails'
  address?: Maybe<BillingDetailsAddress>
  name?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
}

export type BillingDetailsAddress = {
  __typename?: 'BillingDetailsAddress'
  city?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  line1?: Maybe<Scalars['String']>
  postal_code?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
}

export type BroadcastPlan = {
  __typename?: 'BroadcastPlan'
  generated_at: Scalars['Date']
  player_id: Scalars['MongoID']
  duration: Scalars['Float']
  network_id?: Maybe<Scalars['MongoID']>
  campaign_ad_group_id: Scalars['MongoID']
  creatives_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  _id: Scalars['MongoID']
  creatives?: Maybe<Array<Creative>>
  campaignAdGroup?: Maybe<CampaignAdGroup>
  player?: Maybe<Player>
}

export type BroadcastPlanCreativesArgs = {
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindByIdsCreativeInput>
}

export type BroadcastReport = {
  __typename?: 'BroadcastReport'
  broadcast_status: EnumBroadcastReportBroadcast_Status
  plan_acquisition: EnumBroadcastReportPlan_Acquisition
  broadcast_plan_id: Scalars['MongoID']
  network_id?: Maybe<Scalars['MongoID']>
  player_id: Scalars['MongoID']
  duration: Scalars['Float']
  broadcasted_at: Scalars['Date']
  reported_at: Scalars['Date']
  analytics?: Maybe<BroadcastReportAnalytics>
  _id: Scalars['MongoID']
}

export type BroadcastReportAnalytics = {
  __typename?: 'BroadcastReportAnalytics'
  impressions: Scalars['Float']
}

export type BroadcastReportAnalyticsInput = {
  impressions: Scalars['Float']
}

export type BroadcastReportInput = {
  broadcast_plan_id: Scalars['MongoID']
  broadcast_status: Scalars['String']
  duration: Scalars['Int']
  plan_acquisition: Scalars['String']
  broadcasted_at: Scalars['String']
  reported_at: Scalars['String']
}

export type Campaign = {
  __typename?: 'Campaign'
  name: Scalars['String']
  is_active?: Maybe<Scalars['Boolean']>
  ownership?: Maybe<Ownership>
  broadcasts_to?: Maybe<CampaignBroadcasts_To>
  created?: Maybe<ActionDateAndUser>
  archived?: Maybe<ActionDateAndUser>
  _id: Scalars['MongoID']
  getStats: Array<StatsInterval>
  timeframe?: Maybe<Timeframe>
  ad_groups?: Maybe<Array<CampaignAdGroup>>
  status?: Maybe<EnumCampaignStatus>
}

export type CampaignGetStatsArgs = {
  intervals: Array<StatsIntervalInput>
}

export type CampaignAd_GroupsArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyCampaignAdGroupInput>
}

export type CampaignAdGroup = {
  __typename?: 'CampaignAdGroup'
  name: Scalars['String']
  is_active?: Maybe<Scalars['Boolean']>
  ownership?: Maybe<Ownership>
  campaign_id?: Maybe<Scalars['MongoID']>
  audiences_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  creatives_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  redirect_to: Scalars['String']
  timeframe?: Maybe<Timeframe>
  created?: Maybe<ActionDateAndUser>
  audience?: Maybe<CampaignAdGroupAudience>
  archived?: Maybe<ActionDateAndUser>
  saturation?: Maybe<Scalars['Float']>
  _id: Scalars['MongoID']
  getStats: Array<StatsInterval>
  campaign?: Maybe<Campaign>
  creatives?: Maybe<Array<Creative>>
  status?: Maybe<EnumCampaignAdGroupStatus>
}

export type CampaignAdGroupGetStatsArgs = {
  intervals: Array<StatsIntervalInput>
}

export type CampaignAdGroupCreativesArgs = {
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindByIdsCreativeInput>
}

export type CampaignAdGroupAudience = {
  __typename?: 'CampaignAdGroupAudience'
  area_type: EnumCampaignAdGroupAudienceArea_Type
  venue_type_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  geofences?: Maybe<Array<Maybe<Geofence>>>
}

export type CampaignAdGroupAudienceInput = {
  area_type: EnumCampaignAdGroupAudienceArea_Type
  venue_type_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  geofences?: Maybe<Array<Maybe<GeofenceInput>>>
}

export type CampaignBroadcasts_To = {
  __typename?: 'CampaignBroadcasts_to'
  marketplace?: Maybe<Scalars['Boolean']>
  networks?: Maybe<Array<Maybe<CampaignBroadcasts_ToNetworks>>>
  players?: Maybe<Array<Maybe<CampaignBroadcasts_ToPlayers>>>
}

export type CampaignBroadcasts_ToInput = {
  marketplace?: Maybe<Scalars['Boolean']>
  networks?: Maybe<Array<Maybe<CampaignBroadcasts_ToNetworksInput>>>
  players?: Maybe<Array<Maybe<CampaignBroadcasts_ToPlayersInput>>>
}

export type CampaignBroadcasts_ToNetworks = {
  __typename?: 'CampaignBroadcasts_toNetworks'
  network_id?: Maybe<Scalars['MongoID']>
  added?: Maybe<ActionDateAndUser>
  network?: Maybe<Network>
}

export type CampaignBroadcasts_ToNetworksInput = {
  network_id?: Maybe<Scalars['MongoID']>
  added?: Maybe<ActionDateAndUserInput>
}

export type CampaignBroadcasts_ToPlayers = {
  __typename?: 'CampaignBroadcasts_toPlayers'
  player_id?: Maybe<Scalars['MongoID']>
  added?: Maybe<ActionDateAndUser>
  player?: Maybe<Player>
}

export type CampaignBroadcasts_ToPlayersInput = {
  player_id?: Maybe<Scalars['MongoID']>
  added?: Maybe<ActionDateAndUserInput>
}

export type Card = {
  __typename?: 'Card'
  brand?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  exp_month?: Maybe<Scalars['Int']>
  exp_year?: Maybe<Scalars['Int']>
  last4?: Maybe<Scalars['String']>
}

export type Category = {
  __typename?: 'Category'
  name: Scalars['String']
  _id: Scalars['MongoID']
}

export type Country = {
  __typename?: 'Country'
  name: Scalars['String']
  code?: Maybe<Scalars['String']>
  _id: Scalars['MongoID']
}

export type CreateManyCampaignAdGroupInput = {
  name: Scalars['String']
  is_active?: Maybe<Scalars['Boolean']>
  campaign_id?: Maybe<Scalars['MongoID']>
  audiences_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  creatives_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  redirect_to: Scalars['String']
  timeframe?: Maybe<TimeframeInput>
  created?: Maybe<ActionDateAndUserInput>
  audience?: Maybe<CampaignAdGroupAudienceInput>
  saturation?: Maybe<Scalars['Float']>
}

export type CreateManyCampaignAdGroupPayload = {
  __typename?: 'CreateManyCampaignAdGroupPayload'
  /** Created document ID */
  recordIds: Array<Scalars['MongoID']>
  /** Created documents */
  records: Array<CampaignAdGroup>
  /** Count of all documents created */
  createCount: Scalars['Int']
}

export type CreateManyCampaignInput = {
  name: Scalars['String']
  is_active?: Maybe<Scalars['Boolean']>
  broadcasts_to?: Maybe<CampaignBroadcasts_ToInput>
  created?: Maybe<ActionDateAndUserInput>
}

export type CreateManyCampaignPayload = {
  __typename?: 'CreateManyCampaignPayload'
  /** Created document ID */
  recordIds: Array<Scalars['MongoID']>
  /** Created documents */
  records: Array<Campaign>
  /** Count of all documents created */
  createCount: Scalars['Int']
}

export type CreateManyCategoryInput = {
  name: Scalars['String']
}

export type CreateManyCategoryPayload = {
  __typename?: 'CreateManyCategoryPayload'
  /** Created document ID */
  recordIds: Array<Scalars['MongoID']>
  /** Created documents */
  records: Array<Category>
  /** Count of all documents created */
  createCount: Scalars['Int']
}

export type CreateManyCountryInput = {
  name: Scalars['String']
  code?: Maybe<Scalars['String']>
}

export type CreateManyCountryPayload = {
  __typename?: 'CreateManyCountryPayload'
  /** Created document ID */
  recordIds: Array<Scalars['MongoID']>
  /** Created documents */
  records: Array<Country>
  /** Count of all documents created */
  createCount: Scalars['Int']
}

export type CreateManyCreativeInput = {
  name: Scalars['String']
  is_active?: Maybe<Scalars['Boolean']>
  type: EnumCreativeType
  upload_id: Scalars['MongoID']
  categories_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  created?: Maybe<ActionDateAndUserInput>
}

export type CreateManyCreativePayload = {
  __typename?: 'CreateManyCreativePayload'
  /** Created document ID */
  recordIds: Array<Scalars['MongoID']>
  /** Created documents */
  records: Array<Creative>
  /** Count of all documents created */
  createCount: Scalars['Int']
}

export type CreateManyCurrencyInput = {
  name: Scalars['String']
  symbol?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  payment_gateways?: Maybe<Array<Maybe<EnumCurrencyPayment_Gateways>>>
}

export type CreateManyCurrencyPayload = {
  __typename?: 'CreateManyCurrencyPayload'
  /** Created document ID */
  recordIds: Array<Scalars['MongoID']>
  /** Created documents */
  records: Array<Currency>
  /** Count of all documents created */
  createCount: Scalars['Int']
}

export type CreateManyDistanceUnitInput = {
  name: Scalars['String']
  code?: Maybe<Scalars['String']>
  meters_convert_index?: Maybe<Scalars['Float']>
}

export type CreateManyDistanceUnitPayload = {
  __typename?: 'CreateManyDistanceUnitPayload'
  /** Created document ID */
  recordIds: Array<Scalars['MongoID']>
  /** Created documents */
  records: Array<DistanceUnit>
  /** Count of all documents created */
  createCount: Scalars['Int']
}

export type CreateManyLanguageInput = {
  name: Scalars['String']
  code?: Maybe<Scalars['String']>
}

export type CreateManyLanguagePayload = {
  __typename?: 'CreateManyLanguagePayload'
  /** Created document ID */
  recordIds: Array<Scalars['MongoID']>
  /** Created documents */
  records: Array<Language>
  /** Count of all documents created */
  createCount: Scalars['Int']
}

export type CreateManyNetworkInput = {
  name: Scalars['String']
  is_active?: Maybe<Scalars['Boolean']>
  players: Array<Maybe<NetworkPlayersInput>>
  created?: Maybe<ActionDateAndUserInput>
}

export type CreateManyNetworkPayload = {
  __typename?: 'CreateManyNetworkPayload'
  /** Created document ID */
  recordIds: Array<Scalars['MongoID']>
  /** Created documents */
  records: Array<Network>
  /** Count of all documents created */
  createCount: Scalars['Int']
}

export type CreateManyUserInput = {
  is_active?: Maybe<Scalars['Boolean']>
  name?: Maybe<UserNameInput>
  dob?: Maybe<Scalars['Date']>
  gender?: Maybe<EnumUserGender>
  address?: Maybe<AddressInput>
  emails?: Maybe<Array<Maybe<UserEmailInput>>>
  primary_email_index?: Maybe<Scalars['Float']>
  phones?: Maybe<Array<Maybe<UserPhoneInput>>>
  primary_phone_index?: Maybe<Scalars['Float']>
  platform_roles?: Maybe<UserPlatform_RolesInput>
  last_seen_at?: Maybe<Scalars['Date']>
  created?: Maybe<ActionDateAndUserInput>
}

export type CreateManyUserPayload = {
  __typename?: 'CreateManyUserPayload'
  /** Created document ID */
  recordIds: Array<Scalars['MongoID']>
  /** Created documents */
  records: Array<User>
  /** Count of all documents created */
  createCount: Scalars['Int']
}

export type CreateManyVenueTypeInput = {
  name: Scalars['String']
  categories_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type CreateManyVenueTypePayload = {
  __typename?: 'CreateManyVenueTypePayload'
  /** Created document ID */
  recordIds: Array<Scalars['MongoID']>
  /** Created documents */
  records: Array<VenueType>
  /** Count of all documents created */
  createCount: Scalars['Int']
}

export type CreateManyWebhookInput = {
  url?: Maybe<Scalars['String']>
  retries?: Maybe<Scalars['Float']>
  type?: Maybe<EnumWebhookType>
  event_types?: Maybe<WebhookEvent_TypesInput>
  created?: Maybe<ActionDateAndUserInput>
}

export type CreateManyWebhookPayload = {
  __typename?: 'CreateManyWebhookPayload'
  /** Created document ID */
  recordIds: Array<Scalars['MongoID']>
  /** Created documents */
  records: Array<Webhook>
  /** Count of all documents created */
  createCount: Scalars['Int']
}

export type CreateOneCampaignAdGroupInput = {
  name: Scalars['String']
  is_active?: Maybe<Scalars['Boolean']>
  campaign_id?: Maybe<Scalars['MongoID']>
  audiences_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  creatives_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  redirect_to: Scalars['String']
  timeframe?: Maybe<TimeframeInput>
  created?: Maybe<ActionDateAndUserInput>
  audience?: Maybe<CampaignAdGroupAudienceInput>
  saturation?: Maybe<Scalars['Float']>
}

export type CreateOneCampaignAdGroupPayload = {
  __typename?: 'CreateOneCampaignAdGroupPayload'
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>
  /** Created document */
  record?: Maybe<CampaignAdGroup>
}

export type CreateOneCampaignInput = {
  name: Scalars['String']
  is_active?: Maybe<Scalars['Boolean']>
  broadcasts_to?: Maybe<CampaignBroadcasts_ToInput>
  created?: Maybe<ActionDateAndUserInput>
}

export type CreateOneCampaignPayload = {
  __typename?: 'CreateOneCampaignPayload'
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>
  /** Created document */
  record?: Maybe<Campaign>
}

export type CreateOneCategoryInput = {
  name: Scalars['String']
}

export type CreateOneCategoryPayload = {
  __typename?: 'CreateOneCategoryPayload'
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>
  /** Created document */
  record?: Maybe<Category>
}

export type CreateOneCountryInput = {
  name: Scalars['String']
  code?: Maybe<Scalars['String']>
}

export type CreateOneCountryPayload = {
  __typename?: 'CreateOneCountryPayload'
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>
  /** Created document */
  record?: Maybe<Country>
}

export type CreateOneCreativeInput = {
  name: Scalars['String']
  is_active?: Maybe<Scalars['Boolean']>
  type: EnumCreativeType
  upload_id: Scalars['MongoID']
  categories_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  created?: Maybe<ActionDateAndUserInput>
}

export type CreateOneCreativePayload = {
  __typename?: 'CreateOneCreativePayload'
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>
  /** Created document */
  record?: Maybe<Creative>
}

export type CreateOneCurrencyInput = {
  name: Scalars['String']
  symbol?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  payment_gateways?: Maybe<Array<Maybe<EnumCurrencyPayment_Gateways>>>
}

export type CreateOneCurrencyPayload = {
  __typename?: 'CreateOneCurrencyPayload'
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>
  /** Created document */
  record?: Maybe<Currency>
}

export type CreateOneDistanceUnitInput = {
  name: Scalars['String']
  code?: Maybe<Scalars['String']>
  meters_convert_index?: Maybe<Scalars['Float']>
}

export type CreateOneDistanceUnitPayload = {
  __typename?: 'CreateOneDistanceUnitPayload'
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>
  /** Created document */
  record?: Maybe<DistanceUnit>
}

export type CreateOneLanguageInput = {
  name: Scalars['String']
  code?: Maybe<Scalars['String']>
}

export type CreateOneLanguagePayload = {
  __typename?: 'CreateOneLanguagePayload'
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>
  /** Created document */
  record?: Maybe<Language>
}

export type CreateOneNetworkInput = {
  name: Scalars['String']
  is_active?: Maybe<Scalars['Boolean']>
  players: Array<Maybe<NetworkPlayersInput>>
  created?: Maybe<ActionDateAndUserInput>
}

export type CreateOneNetworkPayload = {
  __typename?: 'CreateOneNetworkPayload'
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>
  /** Created document */
  record?: Maybe<Network>
}

export type CreateOneUserInput = {
  is_active?: Maybe<Scalars['Boolean']>
  name?: Maybe<UserNameInput>
  dob?: Maybe<Scalars['Date']>
  gender?: Maybe<EnumUserGender>
  address?: Maybe<AddressInput>
  emails?: Maybe<Array<Maybe<UserEmailInput>>>
  primary_email_index?: Maybe<Scalars['Float']>
  phones?: Maybe<Array<Maybe<UserPhoneInput>>>
  primary_phone_index?: Maybe<Scalars['Float']>
  platform_roles?: Maybe<UserPlatform_RolesInput>
  last_seen_at?: Maybe<Scalars['Date']>
  created?: Maybe<ActionDateAndUserInput>
}

export type CreateOneUserPayload = {
  __typename?: 'CreateOneUserPayload'
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>
  /** Created document */
  record?: Maybe<User>
}

export type CreateOneVenueTypeInput = {
  name: Scalars['String']
  categories_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type CreateOneVenueTypePayload = {
  __typename?: 'CreateOneVenueTypePayload'
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>
  /** Created document */
  record?: Maybe<VenueType>
}

export type CreateOneWebhookInput = {
  url?: Maybe<Scalars['String']>
  retries?: Maybe<Scalars['Float']>
  type?: Maybe<EnumWebhookType>
  event_types?: Maybe<WebhookEvent_TypesInput>
  created?: Maybe<ActionDateAndUserInput>
}

export type CreateOneWebhookPayload = {
  __typename?: 'CreateOneWebhookPayload'
  /** Created document ID */
  recordId?: Maybe<Scalars['MongoID']>
  /** Created document */
  record?: Maybe<Webhook>
}

export type Creative = {
  __typename?: 'Creative'
  name: Scalars['String']
  is_active?: Maybe<Scalars['Boolean']>
  ownership?: Maybe<Ownership>
  type: EnumCreativeType
  upload_id: Scalars['MongoID']
  categories_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  created?: Maybe<ActionDateAndUser>
  archived?: Maybe<ActionDateAndUser>
  _id: Scalars['MongoID']
  getStats: Array<StatsInterval>
  ad_groups?: Maybe<Array<CampaignAdGroup>>
  upload?: Maybe<Upload>
  status?: Maybe<EnumCreativeStatus>
}

export type CreativeGetStatsArgs = {
  intervals: Array<StatsIntervalInput>
}

export type CreativeAd_GroupsArgs = {
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyCampaignAdGroupInput>
}

export type Currency = {
  __typename?: 'Currency'
  name: Scalars['String']
  symbol?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  payment_gateways?: Maybe<Array<Maybe<EnumCurrencyPayment_Gateways>>>
  _id: Scalars['MongoID']
}

export type Dashboard__AccountUpdateDetailsInput = {
  name: Dashboard__AccountUpdateDetailsNameInput
}

export type Dashboard__AccountUpdateDetailsNameInput = {
  first: Scalars['String']
  last: Scalars['String']
}

export type Dashboard__AuthAccess = {
  __typename?: 'Dashboard__AuthAccess'
  user_id: Scalars['String']
  client_id: Scalars['String']
  access_token: Scalars['String']
  refresh_token: Scalars['String']
}

export type Dashboard__AuthRefreshResult = {
  __typename?: 'Dashboard__AuthRefreshResult'
  accessToken: Scalars['String']
  refreshToken: Scalars['String']
}

export type Dashboard__PreSignCreativeUploadResponse = {
  __typename?: 'Dashboard__PreSignCreativeUploadResponse'
  creative_id?: Maybe<Scalars['MongoID']>
  presigned_url?: Maybe<Scalars['String']>
}

export type Dashboard__WhoAmIUserInviteResult = {
  __typename?: 'Dashboard__WhoAmIUserInviteResult'
  _id: Scalars['MongoID']
  workspace_id: Scalars['MongoID']
  status: Scalars['String']
}

export type Dashboard__WhoAmIUserResult = {
  __typename?: 'Dashboard__WhoAmIUserResult'
  _id: Scalars['MongoID']
  is_active?: Maybe<Scalars['Boolean']>
  name?: Maybe<UserName>
  platform_roles?: Maybe<UserPlatform_Roles>
  workspaces: Array<Maybe<Dashboard__WhoAmIUserWorkspaceResult>>
  emails: Array<Maybe<UserEmail>>
  phones: Array<Maybe<UserPhone>>
  primary_email_index?: Maybe<Scalars['Int']>
  primary_phone_index?: Maybe<Scalars['Int']>
  invites: Array<Maybe<Dashboard__WhoAmIUserInviteResult>>
}

export type Dashboard__WhoAmIUserWorkspaceResult = {
  __typename?: 'Dashboard__WhoAmIUserWorkspaceResult'
  _id: Scalars['MongoID']
  name: Scalars['String']
  role: Scalars['String']
  added_at: Scalars['Date']
}

export type Dashboard__WorkspaceUpdateDetailsInput = {
  name: Scalars['String']
}

export type DistanceUnit = {
  __typename?: 'DistanceUnit'
  name: Scalars['String']
  code?: Maybe<Scalars['String']>
  meters_convert_index?: Maybe<Scalars['Float']>
  _id: Scalars['MongoID']
}

export enum EnumBroadcastReportBroadcast_Status {
  FullyBroadcasted = 'FULLY_BROADCASTED',
  PartiallyBroadcasted = 'PARTIALLY_BROADCASTED',
  NotBroadcasted = 'NOT_BROADCASTED',
}

export enum EnumBroadcastReportPlan_Acquisition {
  Direct = 'DIRECT',
  InNetwork = 'IN_NETWORK',
  Cache = 'CACHE',
}

export enum EnumCampaignAdGroupAudienceArea_Type {
  Indoor = 'INDOOR',
  Outdoor = 'OUTDOOR',
  Both = 'BOTH',
}

export enum EnumCampaignAdGroupStatus {
  Activated = 'ACTIVATED',
  Deactivated = 'DEACTIVATED',
  Waiting = 'WAITING',
  Finished = 'FINISHED',
  Archived = 'ARCHIVED',
  Pending = 'PENDING',
}

export enum EnumCampaignStatus {
  Activated = 'ACTIVATED',
  Deactivated = 'DEACTIVATED',
  Waiting = 'WAITING',
  Finished = 'FINISHED',
  Archived = 'ARCHIVED',
  Pending = 'PENDING',
}

export enum EnumCreativeStatus {
  Activated = 'ACTIVATED',
  Deactivated = 'DEACTIVATED',
  Inactive = 'INACTIVE',
  Archived = 'ARCHIVED',
  Uploaded = 'UPLOADED',
  Submitted = 'SUBMITTED',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
}

export enum EnumCreativeType {
  Ad = 'AD',
  Content = 'CONTENT',
}

export enum EnumCurrencyPayment_Gateways {
  Credit = 'credit',
  Stripe = 'stripe',
  Ifthenpay = 'ifthenpay',
  Bitpay = 'bitpay',
  Paypal = 'paypal',
}

export enum EnumInviteStatus {
  Pending = 'PENDING',
  Revoked = 'REVOKED',
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
}

export enum EnumNetworkStatus {
  Activated = 'ACTIVATED',
  Deactivated = 'DEACTIVATED',
  Archived = 'ARCHIVED',
}

export enum EnumPlayerSettingsScreen_Orientation {
  Landscape = 'LANDSCAPE',
  Portrait = 'PORTRAIT',
}

export enum EnumPlayerStatus {
  Activated = 'ACTIVATED',
  Deactivated = 'DEACTIVATED',
  Binded = 'BINDED',
  Registered = 'REGISTERED',
  Archived = 'ARCHIVED',
}

export enum EnumPointType {
  Point = 'Point',
}

export enum EnumUploadStatus {
  Created = 'CREATED',
  Uploaded = 'UPLOADED',
  Processing = 'PROCESSING',
  Ready = 'READY',
}

export enum EnumUploadType {
  Image = 'image',
  Video = 'video',
}

export enum EnumUserGender {
  Female = 'female',
  Male = 'male',
  None = 'none',
}

export enum EnumWebhookEventType {
  PlayerBinded = 'player_binded',
  PlayerActivated = 'player_activated',
  PlayerArchived = 'player_archived',
  CreativeUploaded = 'creative_uploaded',
  CreativeArchived = 'creative_archived',
  NetworkCreated = 'network_created',
  NetworkChanged = 'network_changed',
  NetworkArchived = 'network_archived',
  CampaignCreated = 'campaign_created',
  CampaignChanged = 'campaign_changed',
  CampaignArchived = 'campaign_archived',
  CampaignAdGroupCreated = 'campaign_ad_group_created',
  CampaignAdGroupChanged = 'campaign_ad_group_changed',
  CampaignAdGroupArchived = 'campaign_ad_group_archived',
  WebhookArchived = 'webhook_archived',
  Engagement = 'engagement',
}

export enum EnumWebhookType {
  Ids = 'ids',
  SlackMessage = 'slack_message',
}

export enum EnumWorkspaceMembersRole {
  Administrator = 'ADMINISTRATOR',
  Publisher = 'PUBLISHER',
  Editor = 'EDITOR',
  Guest = 'GUEST',
}

export enum EnumWorkspacePlatform_Type {
  Enterprise = 'ENTERPRISE',
  Advertiser = 'ADVERTISER',
  ContentCreator = 'CONTENT_CREATOR',
  Venue = 'VENUE',
}

export enum EnumWorkspaceStatus {
  Active = 'ACTIVE',
  Deactivated = 'DEACTIVATED',
  Archived = 'ARCHIVED',
}

export type FilterAreaTypeInput = {
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterAreaTypeInput>
  OR?: Maybe<Array<FilterAreaTypeInput>>
  AND?: Maybe<Array<FilterAreaTypeInput>>
}

export type FilterBroadcastPlanInput = {
  generated_at?: Maybe<Scalars['Date']>
  player_id?: Maybe<Scalars['MongoID']>
  duration?: Maybe<Scalars['Float']>
  network_id?: Maybe<Scalars['MongoID']>
  campaign_ad_group_id?: Maybe<Scalars['MongoID']>
  creatives_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterBroadcastPlanInput>
  OR?: Maybe<Array<FilterBroadcastPlanInput>>
  AND?: Maybe<Array<FilterBroadcastPlanInput>>
}

export type FilterBroadcastReportInput = {
  broadcast_status?: Maybe<EnumBroadcastReportBroadcast_Status>
  plan_acquisition?: Maybe<EnumBroadcastReportPlan_Acquisition>
  broadcast_plan_id?: Maybe<Scalars['MongoID']>
  network_id?: Maybe<Scalars['MongoID']>
  player_id?: Maybe<Scalars['MongoID']>
  duration?: Maybe<Scalars['Float']>
  broadcasted_at?: Maybe<Scalars['Date']>
  reported_at?: Maybe<Scalars['Date']>
  analytics?: Maybe<BroadcastReportAnalyticsInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterBroadcastReportInput>
  OR?: Maybe<Array<FilterBroadcastReportInput>>
  AND?: Maybe<Array<FilterBroadcastReportInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterCampaignAdGroupInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  campaign_id?: Maybe<Scalars['MongoID']>
  audiences_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  creatives_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  redirect_to?: Maybe<Scalars['String']>
  timeframe?: Maybe<TimeframeInput>
  created?: Maybe<ActionDateAndUserInput>
  audience?: Maybe<CampaignAdGroupAudienceInput>
  saturation?: Maybe<Scalars['Float']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterCampaignAdGroupInput>
  OR?: Maybe<Array<FilterCampaignAdGroupInput>>
  AND?: Maybe<Array<FilterCampaignAdGroupInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterCampaignInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  broadcasts_to?: Maybe<CampaignBroadcasts_ToInput>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterCampaignInput>
  OR?: Maybe<Array<FilterCampaignInput>>
  AND?: Maybe<Array<FilterCampaignInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterCategoryInput = {
  name?: Maybe<Scalars['String']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterCategoryInput>
  OR?: Maybe<Array<FilterCategoryInput>>
  AND?: Maybe<Array<FilterCategoryInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterCountryInput = {
  name?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterCountryInput>
  OR?: Maybe<Array<FilterCountryInput>>
  AND?: Maybe<Array<FilterCountryInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterCreativeInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  type?: Maybe<EnumCreativeType>
  upload_id?: Maybe<Scalars['MongoID']>
  categories_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterCreativeInput>
  OR?: Maybe<Array<FilterCreativeInput>>
  AND?: Maybe<Array<FilterCreativeInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterCurrencyInput = {
  name?: Maybe<Scalars['String']>
  symbol?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  payment_gateways?: Maybe<Array<Maybe<EnumCurrencyPayment_Gateways>>>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterCurrencyInput>
  OR?: Maybe<Array<FilterCurrencyInput>>
  AND?: Maybe<Array<FilterCurrencyInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterDistanceUnitInput = {
  name?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  meters_convert_index?: Maybe<Scalars['Float']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterDistanceUnitInput>
  OR?: Maybe<Array<FilterDistanceUnitInput>>
  AND?: Maybe<Array<FilterDistanceUnitInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindManyAreaTypeInput = {
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyAreaTypeInput>
  OR?: Maybe<Array<FilterFindManyAreaTypeInput>>
  AND?: Maybe<Array<FilterFindManyAreaTypeInput>>
}

export type FilterFindManyBroadcastPlanInput = {
  generated_at?: Maybe<Scalars['Date']>
  player_id?: Maybe<Scalars['MongoID']>
  duration?: Maybe<Scalars['Float']>
  network_id?: Maybe<Scalars['MongoID']>
  campaign_ad_group_id?: Maybe<Scalars['MongoID']>
  creatives_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyBroadcastPlanInput>
  OR?: Maybe<Array<FilterFindManyBroadcastPlanInput>>
  AND?: Maybe<Array<FilterFindManyBroadcastPlanInput>>
}

export type FilterFindManyBroadcastReportInput = {
  broadcast_status?: Maybe<EnumBroadcastReportBroadcast_Status>
  plan_acquisition?: Maybe<EnumBroadcastReportPlan_Acquisition>
  broadcast_plan_id?: Maybe<Scalars['MongoID']>
  network_id?: Maybe<Scalars['MongoID']>
  player_id?: Maybe<Scalars['MongoID']>
  duration?: Maybe<Scalars['Float']>
  broadcasted_at?: Maybe<Scalars['Date']>
  reported_at?: Maybe<Scalars['Date']>
  analytics?: Maybe<BroadcastReportAnalyticsInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyBroadcastReportInput>
  OR?: Maybe<Array<FilterFindManyBroadcastReportInput>>
  AND?: Maybe<Array<FilterFindManyBroadcastReportInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindManyCampaignAdGroupInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  campaign_id?: Maybe<Scalars['MongoID']>
  audiences_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  creatives_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  redirect_to?: Maybe<Scalars['String']>
  timeframe?: Maybe<TimeframeInput>
  created?: Maybe<ActionDateAndUserInput>
  audience?: Maybe<CampaignAdGroupAudienceInput>
  saturation?: Maybe<Scalars['Float']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyCampaignAdGroupInput>
  OR?: Maybe<Array<FilterFindManyCampaignAdGroupInput>>
  AND?: Maybe<Array<FilterFindManyCampaignAdGroupInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindManyCampaignInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  broadcasts_to?: Maybe<CampaignBroadcasts_ToInput>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyCampaignInput>
  OR?: Maybe<Array<FilterFindManyCampaignInput>>
  AND?: Maybe<Array<FilterFindManyCampaignInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindManyCategoryInput = {
  name?: Maybe<Scalars['String']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyCategoryInput>
  OR?: Maybe<Array<FilterFindManyCategoryInput>>
  AND?: Maybe<Array<FilterFindManyCategoryInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindManyCountryInput = {
  name?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyCountryInput>
  OR?: Maybe<Array<FilterFindManyCountryInput>>
  AND?: Maybe<Array<FilterFindManyCountryInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindManyCreativeInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  type?: Maybe<EnumCreativeType>
  upload_id?: Maybe<Scalars['MongoID']>
  categories_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyCreativeInput>
  OR?: Maybe<Array<FilterFindManyCreativeInput>>
  AND?: Maybe<Array<FilterFindManyCreativeInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindManyCurrencyInput = {
  name?: Maybe<Scalars['String']>
  symbol?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  payment_gateways?: Maybe<Array<Maybe<EnumCurrencyPayment_Gateways>>>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyCurrencyInput>
  OR?: Maybe<Array<FilterFindManyCurrencyInput>>
  AND?: Maybe<Array<FilterFindManyCurrencyInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindManyDistanceUnitInput = {
  name?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  meters_convert_index?: Maybe<Scalars['Float']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyDistanceUnitInput>
  OR?: Maybe<Array<FilterFindManyDistanceUnitInput>>
  AND?: Maybe<Array<FilterFindManyDistanceUnitInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindManyInviteInput = {
  status?: Maybe<EnumInviteStatus>
  accepted?: Maybe<ActionDateAndUserInput>
  rejected?: Maybe<ActionDateAndUserInput>
  revoked?: Maybe<ActionDateAndUserInput>
  created?: Maybe<ActionDateAndUserInput>
  target?: Maybe<InviteTargetInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyInviteInput>
  OR?: Maybe<Array<FilterFindManyInviteInput>>
  AND?: Maybe<Array<FilterFindManyInviteInput>>
}

export type FilterFindManyLanguageInput = {
  name?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyLanguageInput>
  OR?: Maybe<Array<FilterFindManyLanguageInput>>
  AND?: Maybe<Array<FilterFindManyLanguageInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindManyNetworkInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  players?: Maybe<Array<Maybe<NetworkPlayersInput>>>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyNetworkInput>
  OR?: Maybe<Array<FilterFindManyNetworkInput>>
  AND?: Maybe<Array<FilterFindManyNetworkInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindManyPlayerInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  unique_id?: Maybe<Scalars['String']>
  capabilities?: Maybe<PlayerCapabilitiesInput>
  demographics?: Maybe<PlayerDemographicsInput>
  geographics?: Maybe<PlayerGeographicsInput>
  settings?: Maybe<PlayerSettingsInput>
  telemetry?: Maybe<PlayerTelemetryInput>
  binded?: Maybe<ActionDateAndUserInput>
  activated?: Maybe<ActionDateAndUserInput>
  secret?: Maybe<Scalars['String']>
  last_seen_at?: Maybe<Scalars['Date']>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyPlayerInput>
  OR?: Maybe<Array<FilterFindManyPlayerInput>>
  AND?: Maybe<Array<FilterFindManyPlayerInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindManyUploadInput = {
  type?: Maybe<EnumUploadType>
  mimetype?: Maybe<Scalars['String']>
  status?: Maybe<EnumUploadStatus>
  name?: Maybe<Scalars['String']>
  versions?: Maybe<Array<Maybe<UploadVersionsInput>>>
  created?: Maybe<ActionDateAndUserInput>
  uploaded?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyUploadInput>
  OR?: Maybe<Array<FilterFindManyUploadInput>>
  AND?: Maybe<Array<FilterFindManyUploadInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindManyUserInput = {
  is_active?: Maybe<Scalars['Boolean']>
  name?: Maybe<UserNameInput>
  dob?: Maybe<Scalars['Date']>
  gender?: Maybe<EnumUserGender>
  address?: Maybe<AddressInput>
  emails?: Maybe<Array<Maybe<UserEmailInput>>>
  primary_email_index?: Maybe<Scalars['Float']>
  phones?: Maybe<Array<Maybe<UserPhoneInput>>>
  primary_phone_index?: Maybe<Scalars['Float']>
  platform_roles?: Maybe<UserPlatform_RolesInput>
  last_seen_at?: Maybe<Scalars['Date']>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyUserInput>
  OR?: Maybe<Array<FilterFindManyUserInput>>
  AND?: Maybe<Array<FilterFindManyUserInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindManyVenueTypeInput = {
  name?: Maybe<Scalars['String']>
  categories_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyVenueTypeInput>
  OR?: Maybe<Array<FilterFindManyVenueTypeInput>>
  AND?: Maybe<Array<FilterFindManyVenueTypeInput>>
}

export type FilterFindManyWebhookEventInput = {
  webhook_id?: Maybe<Scalars['MongoID']>
  type?: Maybe<EnumWebhookEventType>
  at?: Maybe<Scalars['Date']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyWebhookEventInput>
  OR?: Maybe<Array<FilterFindManyWebhookEventInput>>
  AND?: Maybe<Array<FilterFindManyWebhookEventInput>>
}

export type FilterFindManyWebhookEventResponseInput = {
  webhook_event_id?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  statusCode?: Maybe<Scalars['Float']>
  body?: Maybe<Scalars['String']>
  headers?: Maybe<Scalars['JSON']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyWebhookEventResponseInput>
  OR?: Maybe<Array<FilterFindManyWebhookEventResponseInput>>
  AND?: Maybe<Array<FilterFindManyWebhookEventResponseInput>>
}

export type FilterFindManyWebhookInput = {
  url?: Maybe<Scalars['String']>
  retries?: Maybe<Scalars['Float']>
  type?: Maybe<EnumWebhookType>
  event_types?: Maybe<WebhookEvent_TypesInput>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindManyWebhookInput>
  OR?: Maybe<Array<FilterFindManyWebhookInput>>
  AND?: Maybe<Array<FilterFindManyWebhookInput>>
}

export type FilterFindOneBroadcastPlanInput = {
  generated_at?: Maybe<Scalars['Date']>
  player_id?: Maybe<Scalars['MongoID']>
  duration?: Maybe<Scalars['Float']>
  network_id?: Maybe<Scalars['MongoID']>
  campaign_ad_group_id?: Maybe<Scalars['MongoID']>
  creatives_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneBroadcastPlanInput>
  OR?: Maybe<Array<FilterFindOneBroadcastPlanInput>>
  AND?: Maybe<Array<FilterFindOneBroadcastPlanInput>>
}

export type FilterFindOneBroadcastReportInput = {
  broadcast_status?: Maybe<EnumBroadcastReportBroadcast_Status>
  plan_acquisition?: Maybe<EnumBroadcastReportPlan_Acquisition>
  broadcast_plan_id?: Maybe<Scalars['MongoID']>
  network_id?: Maybe<Scalars['MongoID']>
  player_id?: Maybe<Scalars['MongoID']>
  duration?: Maybe<Scalars['Float']>
  broadcasted_at?: Maybe<Scalars['Date']>
  reported_at?: Maybe<Scalars['Date']>
  analytics?: Maybe<BroadcastReportAnalyticsInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneBroadcastReportInput>
  OR?: Maybe<Array<FilterFindOneBroadcastReportInput>>
  AND?: Maybe<Array<FilterFindOneBroadcastReportInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindOneCampaignAdGroupInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  campaign_id?: Maybe<Scalars['MongoID']>
  audiences_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  creatives_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  redirect_to?: Maybe<Scalars['String']>
  timeframe?: Maybe<TimeframeInput>
  created?: Maybe<ActionDateAndUserInput>
  audience?: Maybe<CampaignAdGroupAudienceInput>
  saturation?: Maybe<Scalars['Float']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneCampaignAdGroupInput>
  OR?: Maybe<Array<FilterFindOneCampaignAdGroupInput>>
  AND?: Maybe<Array<FilterFindOneCampaignAdGroupInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindOneCampaignInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  broadcasts_to?: Maybe<CampaignBroadcasts_ToInput>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneCampaignInput>
  OR?: Maybe<Array<FilterFindOneCampaignInput>>
  AND?: Maybe<Array<FilterFindOneCampaignInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindOneCategoryInput = {
  name?: Maybe<Scalars['String']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneCategoryInput>
  OR?: Maybe<Array<FilterFindOneCategoryInput>>
  AND?: Maybe<Array<FilterFindOneCategoryInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindOneCountryInput = {
  name?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneCountryInput>
  OR?: Maybe<Array<FilterFindOneCountryInput>>
  AND?: Maybe<Array<FilterFindOneCountryInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindOneCreativeInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  type?: Maybe<EnumCreativeType>
  upload_id?: Maybe<Scalars['MongoID']>
  categories_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneCreativeInput>
  OR?: Maybe<Array<FilterFindOneCreativeInput>>
  AND?: Maybe<Array<FilterFindOneCreativeInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindOneCurrencyInput = {
  name?: Maybe<Scalars['String']>
  symbol?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  payment_gateways?: Maybe<Array<Maybe<EnumCurrencyPayment_Gateways>>>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneCurrencyInput>
  OR?: Maybe<Array<FilterFindOneCurrencyInput>>
  AND?: Maybe<Array<FilterFindOneCurrencyInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindOneDistanceUnitInput = {
  name?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  meters_convert_index?: Maybe<Scalars['Float']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneDistanceUnitInput>
  OR?: Maybe<Array<FilterFindOneDistanceUnitInput>>
  AND?: Maybe<Array<FilterFindOneDistanceUnitInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindOneInviteInput = {
  status?: Maybe<EnumInviteStatus>
  accepted?: Maybe<ActionDateAndUserInput>
  rejected?: Maybe<ActionDateAndUserInput>
  revoked?: Maybe<ActionDateAndUserInput>
  created?: Maybe<ActionDateAndUserInput>
  target?: Maybe<InviteTargetInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneInviteInput>
  OR?: Maybe<Array<FilterFindOneInviteInput>>
  AND?: Maybe<Array<FilterFindOneInviteInput>>
}

export type FilterFindOneLanguageInput = {
  name?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneLanguageInput>
  OR?: Maybe<Array<FilterFindOneLanguageInput>>
  AND?: Maybe<Array<FilterFindOneLanguageInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindOneNetworkInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  players?: Maybe<Array<Maybe<NetworkPlayersInput>>>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneNetworkInput>
  OR?: Maybe<Array<FilterFindOneNetworkInput>>
  AND?: Maybe<Array<FilterFindOneNetworkInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindOnePlayerInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  unique_id?: Maybe<Scalars['String']>
  capabilities?: Maybe<PlayerCapabilitiesInput>
  demographics?: Maybe<PlayerDemographicsInput>
  geographics?: Maybe<PlayerGeographicsInput>
  settings?: Maybe<PlayerSettingsInput>
  telemetry?: Maybe<PlayerTelemetryInput>
  binded?: Maybe<ActionDateAndUserInput>
  activated?: Maybe<ActionDateAndUserInput>
  secret?: Maybe<Scalars['String']>
  last_seen_at?: Maybe<Scalars['Date']>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOnePlayerInput>
  OR?: Maybe<Array<FilterFindOnePlayerInput>>
  AND?: Maybe<Array<FilterFindOnePlayerInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindOneUploadInput = {
  type?: Maybe<EnumUploadType>
  mimetype?: Maybe<Scalars['String']>
  status?: Maybe<EnumUploadStatus>
  name?: Maybe<Scalars['String']>
  versions?: Maybe<Array<Maybe<UploadVersionsInput>>>
  created?: Maybe<ActionDateAndUserInput>
  uploaded?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneUploadInput>
  OR?: Maybe<Array<FilterFindOneUploadInput>>
  AND?: Maybe<Array<FilterFindOneUploadInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindOneUserInput = {
  is_active?: Maybe<Scalars['Boolean']>
  name?: Maybe<UserNameInput>
  dob?: Maybe<Scalars['Date']>
  gender?: Maybe<EnumUserGender>
  address?: Maybe<AddressInput>
  emails?: Maybe<Array<Maybe<UserEmailInput>>>
  primary_email_index?: Maybe<Scalars['Float']>
  phones?: Maybe<Array<Maybe<UserPhoneInput>>>
  primary_phone_index?: Maybe<Scalars['Float']>
  platform_roles?: Maybe<UserPlatform_RolesInput>
  last_seen_at?: Maybe<Scalars['Date']>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneUserInput>
  OR?: Maybe<Array<FilterFindOneUserInput>>
  AND?: Maybe<Array<FilterFindOneUserInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterFindOneWebhookInput = {
  url?: Maybe<Scalars['String']>
  retries?: Maybe<Scalars['Float']>
  type?: Maybe<EnumWebhookType>
  event_types?: Maybe<WebhookEvent_TypesInput>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterFindOneWebhookInput>
  OR?: Maybe<Array<FilterFindOneWebhookInput>>
  AND?: Maybe<Array<FilterFindOneWebhookInput>>
}

export type FilterInviteInput = {
  status?: Maybe<EnumInviteStatus>
  accepted?: Maybe<ActionDateAndUserInput>
  rejected?: Maybe<ActionDateAndUserInput>
  revoked?: Maybe<ActionDateAndUserInput>
  created?: Maybe<ActionDateAndUserInput>
  target?: Maybe<InviteTargetInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterInviteInput>
  OR?: Maybe<Array<FilterInviteInput>>
  AND?: Maybe<Array<FilterInviteInput>>
}

export type FilterLanguageInput = {
  name?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterLanguageInput>
  OR?: Maybe<Array<FilterLanguageInput>>
  AND?: Maybe<Array<FilterLanguageInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterNetworkInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  players?: Maybe<Array<Maybe<NetworkPlayersInput>>>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterNetworkInput>
  OR?: Maybe<Array<FilterNetworkInput>>
  AND?: Maybe<Array<FilterNetworkInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterPlayerInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  unique_id?: Maybe<Scalars['String']>
  capabilities?: Maybe<PlayerCapabilitiesInput>
  demographics?: Maybe<PlayerDemographicsInput>
  geographics?: Maybe<PlayerGeographicsInput>
  settings?: Maybe<PlayerSettingsInput>
  telemetry?: Maybe<PlayerTelemetryInput>
  binded?: Maybe<ActionDateAndUserInput>
  activated?: Maybe<ActionDateAndUserInput>
  secret?: Maybe<Scalars['String']>
  last_seen_at?: Maybe<Scalars['Date']>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterPlayerInput>
  OR?: Maybe<Array<FilterPlayerInput>>
  AND?: Maybe<Array<FilterPlayerInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterUpdateManyCampaignAdGroupInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  campaign_id?: Maybe<Scalars['MongoID']>
  audiences_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  creatives_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  redirect_to?: Maybe<Scalars['String']>
  timeframe?: Maybe<TimeframeInput>
  created?: Maybe<ActionDateAndUserInput>
  audience?: Maybe<CampaignAdGroupAudienceInput>
  saturation?: Maybe<Scalars['Float']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateManyCampaignAdGroupInput>
  OR?: Maybe<Array<FilterUpdateManyCampaignAdGroupInput>>
  AND?: Maybe<Array<FilterUpdateManyCampaignAdGroupInput>>
}

export type FilterUpdateManyCampaignInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  broadcasts_to?: Maybe<CampaignBroadcasts_ToInput>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateManyCampaignInput>
  OR?: Maybe<Array<FilterUpdateManyCampaignInput>>
  AND?: Maybe<Array<FilterUpdateManyCampaignInput>>
}

export type FilterUpdateManyCategoryInput = {
  name?: Maybe<Scalars['String']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateManyCategoryInput>
  OR?: Maybe<Array<FilterUpdateManyCategoryInput>>
  AND?: Maybe<Array<FilterUpdateManyCategoryInput>>
}

export type FilterUpdateManyCountryInput = {
  name?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateManyCountryInput>
  OR?: Maybe<Array<FilterUpdateManyCountryInput>>
  AND?: Maybe<Array<FilterUpdateManyCountryInput>>
}

export type FilterUpdateManyCreativeInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  type?: Maybe<EnumCreativeType>
  upload_id?: Maybe<Scalars['MongoID']>
  categories_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateManyCreativeInput>
  OR?: Maybe<Array<FilterUpdateManyCreativeInput>>
  AND?: Maybe<Array<FilterUpdateManyCreativeInput>>
}

export type FilterUpdateManyCurrencyInput = {
  name?: Maybe<Scalars['String']>
  symbol?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  payment_gateways?: Maybe<Array<Maybe<EnumCurrencyPayment_Gateways>>>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateManyCurrencyInput>
  OR?: Maybe<Array<FilterUpdateManyCurrencyInput>>
  AND?: Maybe<Array<FilterUpdateManyCurrencyInput>>
}

export type FilterUpdateManyDistanceUnitInput = {
  name?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  meters_convert_index?: Maybe<Scalars['Float']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateManyDistanceUnitInput>
  OR?: Maybe<Array<FilterUpdateManyDistanceUnitInput>>
  AND?: Maybe<Array<FilterUpdateManyDistanceUnitInput>>
}

export type FilterUpdateManyLanguageInput = {
  name?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateManyLanguageInput>
  OR?: Maybe<Array<FilterUpdateManyLanguageInput>>
  AND?: Maybe<Array<FilterUpdateManyLanguageInput>>
}

export type FilterUpdateManyNetworkInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  players?: Maybe<Array<Maybe<NetworkPlayersInput>>>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateManyNetworkInput>
  OR?: Maybe<Array<FilterUpdateManyNetworkInput>>
  AND?: Maybe<Array<FilterUpdateManyNetworkInput>>
}

export type FilterUpdateManyUserInput = {
  is_active?: Maybe<Scalars['Boolean']>
  name?: Maybe<UserNameInput>
  dob?: Maybe<Scalars['Date']>
  gender?: Maybe<EnumUserGender>
  address?: Maybe<AddressInput>
  emails?: Maybe<Array<Maybe<UserEmailInput>>>
  primary_email_index?: Maybe<Scalars['Float']>
  phones?: Maybe<Array<Maybe<UserPhoneInput>>>
  primary_phone_index?: Maybe<Scalars['Float']>
  platform_roles?: Maybe<UserPlatform_RolesInput>
  last_seen_at?: Maybe<Scalars['Date']>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateManyUserInput>
  OR?: Maybe<Array<FilterUpdateManyUserInput>>
  AND?: Maybe<Array<FilterUpdateManyUserInput>>
}

export type FilterUpdateManyVenueTypeInput = {
  name?: Maybe<Scalars['String']>
  categories_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateManyVenueTypeInput>
  OR?: Maybe<Array<FilterUpdateManyVenueTypeInput>>
  AND?: Maybe<Array<FilterUpdateManyVenueTypeInput>>
}

export type FilterUpdateManyWebhookInput = {
  url?: Maybe<Scalars['String']>
  retries?: Maybe<Scalars['Float']>
  type?: Maybe<EnumWebhookType>
  event_types?: Maybe<WebhookEvent_TypesInput>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateManyWebhookInput>
  OR?: Maybe<Array<FilterUpdateManyWebhookInput>>
  AND?: Maybe<Array<FilterUpdateManyWebhookInput>>
}

export type FilterUpdateOneCampaignAdGroupInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  campaign_id?: Maybe<Scalars['MongoID']>
  audiences_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  creatives_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  redirect_to?: Maybe<Scalars['String']>
  timeframe?: Maybe<TimeframeInput>
  created?: Maybe<ActionDateAndUserInput>
  audience?: Maybe<CampaignAdGroupAudienceInput>
  saturation?: Maybe<Scalars['Float']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneCampaignAdGroupInput>
  OR?: Maybe<Array<FilterUpdateOneCampaignAdGroupInput>>
  AND?: Maybe<Array<FilterUpdateOneCampaignAdGroupInput>>
}

export type FilterUpdateOneCampaignInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  broadcasts_to?: Maybe<CampaignBroadcasts_ToInput>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneCampaignInput>
  OR?: Maybe<Array<FilterUpdateOneCampaignInput>>
  AND?: Maybe<Array<FilterUpdateOneCampaignInput>>
}

export type FilterUpdateOneCategoryInput = {
  name?: Maybe<Scalars['String']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneCategoryInput>
  OR?: Maybe<Array<FilterUpdateOneCategoryInput>>
  AND?: Maybe<Array<FilterUpdateOneCategoryInput>>
}

export type FilterUpdateOneCountryInput = {
  name?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneCountryInput>
  OR?: Maybe<Array<FilterUpdateOneCountryInput>>
  AND?: Maybe<Array<FilterUpdateOneCountryInput>>
}

export type FilterUpdateOneCreativeInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  type?: Maybe<EnumCreativeType>
  upload_id?: Maybe<Scalars['MongoID']>
  categories_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneCreativeInput>
  OR?: Maybe<Array<FilterUpdateOneCreativeInput>>
  AND?: Maybe<Array<FilterUpdateOneCreativeInput>>
}

export type FilterUpdateOneCurrencyInput = {
  name?: Maybe<Scalars['String']>
  symbol?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  payment_gateways?: Maybe<Array<Maybe<EnumCurrencyPayment_Gateways>>>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneCurrencyInput>
  OR?: Maybe<Array<FilterUpdateOneCurrencyInput>>
  AND?: Maybe<Array<FilterUpdateOneCurrencyInput>>
}

export type FilterUpdateOneDistanceUnitInput = {
  name?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  meters_convert_index?: Maybe<Scalars['Float']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneDistanceUnitInput>
  OR?: Maybe<Array<FilterUpdateOneDistanceUnitInput>>
  AND?: Maybe<Array<FilterUpdateOneDistanceUnitInput>>
}

export type FilterUpdateOneLanguageInput = {
  name?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneLanguageInput>
  OR?: Maybe<Array<FilterUpdateOneLanguageInput>>
  AND?: Maybe<Array<FilterUpdateOneLanguageInput>>
}

export type FilterUpdateOneNetworkInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  players?: Maybe<Array<Maybe<NetworkPlayersInput>>>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneNetworkInput>
  OR?: Maybe<Array<FilterUpdateOneNetworkInput>>
  AND?: Maybe<Array<FilterUpdateOneNetworkInput>>
}

export type FilterUpdateOnePlayerInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  unique_id?: Maybe<Scalars['String']>
  capabilities?: Maybe<PlayerCapabilitiesInput>
  demographics?: Maybe<PlayerDemographicsInput>
  geographics?: Maybe<PlayerGeographicsInput>
  settings?: Maybe<PlayerSettingsInput>
  telemetry?: Maybe<PlayerTelemetryInput>
  binded?: Maybe<ActionDateAndUserInput>
  activated?: Maybe<ActionDateAndUserInput>
  secret?: Maybe<Scalars['String']>
  last_seen_at?: Maybe<Scalars['Date']>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOnePlayerInput>
  OR?: Maybe<Array<FilterUpdateOnePlayerInput>>
  AND?: Maybe<Array<FilterUpdateOnePlayerInput>>
}

export type FilterUpdateOneUserInput = {
  is_active?: Maybe<Scalars['Boolean']>
  name?: Maybe<UserNameInput>
  dob?: Maybe<Scalars['Date']>
  gender?: Maybe<EnumUserGender>
  address?: Maybe<AddressInput>
  emails?: Maybe<Array<Maybe<UserEmailInput>>>
  primary_email_index?: Maybe<Scalars['Float']>
  phones?: Maybe<Array<Maybe<UserPhoneInput>>>
  primary_phone_index?: Maybe<Scalars['Float']>
  platform_roles?: Maybe<UserPlatform_RolesInput>
  last_seen_at?: Maybe<Scalars['Date']>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneUserInput>
  OR?: Maybe<Array<FilterUpdateOneUserInput>>
  AND?: Maybe<Array<FilterUpdateOneUserInput>>
}

export type FilterUpdateOneVenueTypeInput = {
  name?: Maybe<Scalars['String']>
  categories_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneVenueTypeInput>
  OR?: Maybe<Array<FilterUpdateOneVenueTypeInput>>
  AND?: Maybe<Array<FilterUpdateOneVenueTypeInput>>
}

export type FilterUpdateOneWebhookInput = {
  url?: Maybe<Scalars['String']>
  retries?: Maybe<Scalars['Float']>
  type?: Maybe<EnumWebhookType>
  event_types?: Maybe<WebhookEvent_TypesInput>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUpdateOneWebhookInput>
  OR?: Maybe<Array<FilterUpdateOneWebhookInput>>
  AND?: Maybe<Array<FilterUpdateOneWebhookInput>>
}

export type FilterUploadInput = {
  type?: Maybe<EnumUploadType>
  mimetype?: Maybe<Scalars['String']>
  status?: Maybe<EnumUploadStatus>
  name?: Maybe<Scalars['String']>
  versions?: Maybe<Array<Maybe<UploadVersionsInput>>>
  created?: Maybe<ActionDateAndUserInput>
  uploaded?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUploadInput>
  OR?: Maybe<Array<FilterUploadInput>>
  AND?: Maybe<Array<FilterUploadInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterUserInput = {
  is_active?: Maybe<Scalars['Boolean']>
  name?: Maybe<UserNameInput>
  dob?: Maybe<Scalars['Date']>
  gender?: Maybe<EnumUserGender>
  address?: Maybe<AddressInput>
  emails?: Maybe<Array<Maybe<UserEmailInput>>>
  primary_email_index?: Maybe<Scalars['Float']>
  phones?: Maybe<Array<Maybe<UserPhoneInput>>>
  primary_phone_index?: Maybe<Scalars['Float']>
  platform_roles?: Maybe<UserPlatform_RolesInput>
  last_seen_at?: Maybe<Scalars['Date']>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterUserInput>
  OR?: Maybe<Array<FilterUserInput>>
  AND?: Maybe<Array<FilterUserInput>>
  /** Search by keyword in regExp */
  search?: Maybe<Scalars['String']>
}

export type FilterVenueTypeInput = {
  name?: Maybe<Scalars['String']>
  categories_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterVenueTypeInput>
  OR?: Maybe<Array<FilterVenueTypeInput>>
  AND?: Maybe<Array<FilterVenueTypeInput>>
}

export type FilterWebhookInput = {
  url?: Maybe<Scalars['String']>
  retries?: Maybe<Scalars['Float']>
  type?: Maybe<EnumWebhookType>
  event_types?: Maybe<WebhookEvent_TypesInput>
  created?: Maybe<ActionDateAndUserInput>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterWebhookInput>
  OR?: Maybe<Array<FilterWebhookInput>>
  AND?: Maybe<Array<FilterWebhookInput>>
}

export type FilterWorkspaceInput = {
  name?: Maybe<Scalars['String']>
  platform_type?: Maybe<EnumWorkspacePlatform_Type>
  is_active?: Maybe<Scalars['Boolean']>
  members?: Maybe<Array<Maybe<WorkspaceMembersInput>>>
  invoice_id?: Maybe<Scalars['String']>
  _id?: Maybe<Scalars['MongoID']>
  _ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: Maybe<OperatorsFilterWorkspaceInput>
  OR?: Maybe<Array<FilterWorkspaceInput>>
  AND?: Maybe<Array<FilterWorkspaceInput>>
}

export enum ForgotPasswordMethod {
  Email = 'EMAIL',
  Phone = 'PHONE',
}

export type Geofence = {
  __typename?: 'Geofence'
  location?: Maybe<Point>
  range?: Maybe<Scalars['Float']>
  distance_unit_id?: Maybe<Scalars['MongoID']>
}

export type GeofenceInput = {
  location?: Maybe<PointInput>
  range?: Maybe<Scalars['Float']>
  distance_unit_id?: Maybe<Scalars['MongoID']>
}

export type Invite = {
  __typename?: 'Invite'
  ownership?: Maybe<Ownership>
  status?: Maybe<EnumInviteStatus>
  accepted?: Maybe<ActionDateAndUser>
  rejected?: Maybe<ActionDateAndUser>
  revoked?: Maybe<ActionDateAndUser>
  created?: Maybe<ActionDateAndUser>
  target?: Maybe<InviteTarget>
  _id: Scalars['MongoID']
}

export type InviteTarget = {
  __typename?: 'InviteTarget'
  email?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['MongoID']>
  name?: Maybe<InviteTargetName>
}

export type InviteTargetInput = {
  email?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['MongoID']>
  name?: Maybe<InviteTargetNameInput>
}

export type InviteTargetName = {
  __typename?: 'InviteTargetName'
  first?: Maybe<Scalars['String']>
  last?: Maybe<Scalars['String']>
}

export type InviteTargetNameInput = {
  first?: Maybe<Scalars['String']>
  last?: Maybe<Scalars['String']>
}

export type InvoiceData = {
  __typename?: 'InvoiceData'
  id?: Maybe<Scalars['String']>
  created?: Maybe<Scalars['Int']>
  total?: Maybe<Scalars['Int']>
  invoice_pdf?: Maybe<Scalars['String']>
  attempt_count?: Maybe<Scalars['Int']>
  attemped?: Maybe<Scalars['Boolean']>
  charge?: Maybe<Scalars['String']>
  currency?: Maybe<Scalars['String']>
  default_payment_method?: Maybe<Scalars['String']>
  default_source?: Maybe<Scalars['String']>
  days_until_due?: Maybe<Scalars['Int']>
  hosted_invoice_url?: Maybe<Scalars['String']>
  subscription?: Maybe<Scalars['String']>
  paid?: Maybe<Scalars['Boolean']>
  status?: Maybe<Scalars['String']>
  payment_intent?: Maybe<Scalars['String']>
}

export type Language = {
  __typename?: 'Language'
  name: Scalars['String']
  code?: Maybe<Scalars['String']>
  _id: Scalars['MongoID']
}

export type Mutation = {
  __typename?: 'Mutation'
  Player__CreateBroadcastReports: Array<Scalars['Boolean']>
  Player__GenerateBroadcastPlans: Array<Maybe<BroadcastPlan>>
  Player__Register?: Maybe<Player>
  Dashboard__AccountChangePassword?: Maybe<Scalars['Boolean']>
  Dashboard__DisableAccount?: Maybe<Scalars['Boolean']>
  Dashboard__AccountEmailAdd?: Maybe<Scalars['Boolean']>
  Dashboard__AccountEmailRemove?: Maybe<Scalars['Boolean']>
  Dashboard__AccountEmailSendVerificationCode?: Maybe<Scalars['Boolean']>
  Dashboard__AccountEmailSetPrimary?: Maybe<Scalars['Boolean']>
  Dashboard__AccountEmailVerifyCode?: Maybe<Scalars['Boolean']>
  Dashboard__AccountAcceptWorkspaceInvites: Array<Invite>
  Dashboard__AccountRejectWorkspaceInvites: Array<Invite>
  Dashboard__AccountPhoneAdd?: Maybe<Scalars['Boolean']>
  Dashboard__AccountPhoneRemove?: Maybe<Scalars['Boolean']>
  Dashboard__AccountPhoneSendVerificationCode?: Maybe<Scalars['Boolean']>
  Dashboard__AccountPhoneSetPrimary?: Maybe<Scalars['Boolean']>
  Dashboard__AccountPhoneVerifyCode?: Maybe<Scalars['Boolean']>
  Dashboard__UpdateAccountDetails?: Maybe<User>
  Dashboard__AuthForgotPasswordReset?: Maybe<Dashboard__AuthAccess>
  Dashboard__AuthForgotPasswordSendCode?: Maybe<Scalars['Boolean']>
  Dashboard__AuthForgotPasswordVerifyCode: Scalars['String']
  Dashboard__AuthRefresh?: Maybe<Dashboard__AuthRefreshResult>
  Dashboard__AuthSignIn?: Maybe<Dashboard__AuthAccess>
  Dashboard__AuthSignOut: Scalars['Boolean']
  Dashboard__AuthSignUp?: Maybe<UserSignUpResult>
  /** Create one document with mongoose defaults, setters, hooks and validation */
  Dashboard__CreateVenueType?: Maybe<CreateOneVenueTypePayload>
  /** Creates Many documents with mongoose defaults, setters, hooks and validation */
  Dashboard__BulkCreateVenueTypes?: Maybe<CreateManyVenueTypePayload>
  /**
   * Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  Dashboard__UpdateVenueType?: Maybe<UpdateOneVenueTypePayload>
  /**
   * Update many documents without returning them: Use Query.update mongoose
   * method. Do not apply mongoose defaults, setters, hooks and validation.
   */
  Dashboard__BulkUpdateVenueTypes?: Maybe<UpdateManyVenueTypePayload>
  /** Create one document with mongoose defaults, setters, hooks and validation */
  Dashboard__CreateCategory?: Maybe<CreateOneCategoryPayload>
  /** Creates Many documents with mongoose defaults, setters, hooks and validation */
  Dashboard__BulkCreateCategories?: Maybe<CreateManyCategoryPayload>
  /**
   * Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  Dashboard__UpdateCategory?: Maybe<UpdateOneCategoryPayload>
  /**
   * Update many documents without returning them: Use Query.update mongoose
   * method. Do not apply mongoose defaults, setters, hooks and validation.
   */
  Dashboard__BulkUpdateCategories?: Maybe<UpdateManyCategoryPayload>
  /** Create one document with mongoose defaults, setters, hooks and validation */
  Dashboard__CreateCurrency?: Maybe<CreateOneCurrencyPayload>
  /** Creates Many documents with mongoose defaults, setters, hooks and validation */
  Dashboard__BulkCreateCurrencies?: Maybe<CreateManyCurrencyPayload>
  /**
   * Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  Dashboard__UpdateCurrency?: Maybe<UpdateOneCurrencyPayload>
  /**
   * Update many documents without returning them: Use Query.update mongoose
   * method. Do not apply mongoose defaults, setters, hooks and validation.
   */
  Dashboard__BulkUpdateCurrencies?: Maybe<UpdateManyCurrencyPayload>
  /** Create one document with mongoose defaults, setters, hooks and validation */
  Dashboard__CreateCountry?: Maybe<CreateOneCountryPayload>
  /** Creates Many documents with mongoose defaults, setters, hooks and validation */
  Dashboard__BulkCreateCountries?: Maybe<CreateManyCountryPayload>
  /**
   * Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  Dashboard__UpdateCountry?: Maybe<UpdateOneCountryPayload>
  /**
   * Update many documents without returning them: Use Query.update mongoose
   * method. Do not apply mongoose defaults, setters, hooks and validation.
   */
  Dashboard__BulkUpdateCountries?: Maybe<UpdateManyCountryPayload>
  /** Create one document with mongoose defaults, setters, hooks and validation */
  Dashboard__CreateDistanceUnit?: Maybe<CreateOneDistanceUnitPayload>
  /** Creates Many documents with mongoose defaults, setters, hooks and validation */
  Dashboard__BulkCreateDistanceUnits?: Maybe<CreateManyDistanceUnitPayload>
  /**
   * Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  Dashboard__UpdateDistanceUnit?: Maybe<UpdateOneDistanceUnitPayload>
  /**
   * Update many documents without returning them: Use Query.update mongoose
   * method. Do not apply mongoose defaults, setters, hooks and validation.
   */
  Dashboard__BulkUpdateDistanceUnits?: Maybe<UpdateManyDistanceUnitPayload>
  /** Create one document with mongoose defaults, setters, hooks and validation */
  Dashboard__CreateLanguage?: Maybe<CreateOneLanguagePayload>
  /** Creates Many documents with mongoose defaults, setters, hooks and validation */
  Dashboard__BulkCreateLanguages?: Maybe<CreateManyLanguagePayload>
  /**
   * Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  Dashboard__UpdateLanguage?: Maybe<UpdateOneLanguagePayload>
  /**
   * Update many documents without returning them: Use Query.update mongoose
   * method. Do not apply mongoose defaults, setters, hooks and validation.
   */
  Dashboard__BulkUpdateLanguages?: Maybe<UpdateManyLanguagePayload>
  /** Create one document with mongoose defaults, setters, hooks and validation */
  Dashboard__CreateCampaign?: Maybe<CreateOneCampaignPayload>
  /** Creates Many documents with mongoose defaults, setters, hooks and validation */
  Dashboard__BulkCreateCampaigns?: Maybe<CreateManyCampaignPayload>
  /**
   * Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  Dashboard__UpdateCampaign?: Maybe<UpdateOneCampaignPayload>
  /**
   * Update many documents without returning them: Use Query.update mongoose
   * method. Do not apply mongoose defaults, setters, hooks and validation.
   */
  Dashboard__BulkUpdateCampaigns?: Maybe<UpdateManyCampaignPayload>
  /** Create one document with mongoose defaults, setters, hooks and validation */
  Dashboard__CreateCampaignAdGroup?: Maybe<CreateOneCampaignAdGroupPayload>
  /** Creates Many documents with mongoose defaults, setters, hooks and validation */
  Dashboard__BulkCreateCampaignAdGroups?: Maybe<CreateManyCampaignAdGroupPayload>
  /**
   * Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  Dashboard__UpdateCampaignAdGroup?: Maybe<UpdateOneCampaignAdGroupPayload>
  /**
   * Update many documents without returning them: Use Query.update mongoose
   * method. Do not apply mongoose defaults, setters, hooks and validation.
   */
  Dashboard__BulkUpdateCampaignAdGroups?: Maybe<UpdateManyCampaignAdGroupPayload>
  /** Create one document with mongoose defaults, setters, hooks and validation */
  Dashboard__CreateUser?: Maybe<CreateOneUserPayload>
  /** Creates Many documents with mongoose defaults, setters, hooks and validation */
  Dashboard__BulkCreateUsers?: Maybe<CreateManyUserPayload>
  /**
   * Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  Dashboard__UpdateUser?: Maybe<UpdateOneUserPayload>
  /**
   * Update many documents without returning them: Use Query.update mongoose
   * method. Do not apply mongoose defaults, setters, hooks and validation.
   */
  Dashboard__BulkUpdateUsers?: Maybe<UpdateManyUserPayload>
  /** Create one document with mongoose defaults, setters, hooks and validation */
  Dashboard__CreateCreative?: Maybe<CreateOneCreativePayload>
  /** Creates Many documents with mongoose defaults, setters, hooks and validation */
  Dashboard__BulkCreateCreatives?: Maybe<CreateManyCreativePayload>
  /**
   * Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  Dashboard__UpdateCreative?: Maybe<UpdateOneCreativePayload>
  /**
   * Update many documents without returning them: Use Query.update mongoose
   * method. Do not apply mongoose defaults, setters, hooks and validation.
   */
  Dashboard__BulkUpdateCreatives?: Maybe<UpdateManyCreativePayload>
  /** Create one document with mongoose defaults, setters, hooks and validation */
  Dashboard__CreateWebhook?: Maybe<CreateOneWebhookPayload>
  /** Creates Many documents with mongoose defaults, setters, hooks and validation */
  Dashboard__BulkCreateWebhooks?: Maybe<CreateManyWebhookPayload>
  /**
   * Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  Dashboard__UpdateWebhook?: Maybe<UpdateOneWebhookPayload>
  /**
   * Update many documents without returning them: Use Query.update mongoose
   * method. Do not apply mongoose defaults, setters, hooks and validation.
   */
  Dashboard__BulkCreateWebhook?: Maybe<UpdateManyWebhookPayload>
  Dashboard__PreSignCreativeUpload?: Maybe<Dashboard__PreSignCreativeUploadResponse>
  Dashboard__NotifyCreativeUploadCompleted?: Maybe<Scalars['Boolean']>
  Dashboard__ArchiveCreatives?: Maybe<Scalars['Boolean']>
  Dashboard__SetIsActiveCreatives?: Maybe<Scalars['Boolean']>
  Dashboard__ArchiveNetworks?: Maybe<Scalars['Boolean']>
  /** Create one document with mongoose defaults, setters, hooks and validation */
  Dashboard__CreateNetwork?: Maybe<CreateOneNetworkPayload>
  /** Creates Many documents with mongoose defaults, setters, hooks and validation */
  Dashboard__BulkCreateNetworks?: Maybe<CreateManyNetworkPayload>
  /**
   * Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  Dashboard__UpdateNetwork?: Maybe<UpdateOneNetworkPayload>
  /**
   * Update many documents without returning them: Use Query.update mongoose
   * method. Do not apply mongoose defaults, setters, hooks and validation.
   */
  Dashboard__BulkUpdateNetworks?: Maybe<UpdateManyNetworkPayload>
  Dashboard__SetIsActiveNetworks?: Maybe<Scalars['Boolean']>
  Dashboard__ArchiveCampaignAdGroups?: Maybe<Scalars['Boolean']>
  Dashboard__SetIsActiveCampaignAdGroups?: Maybe<Scalars['Boolean']>
  Dashboard__ArchiveCampaigns?: Maybe<Scalars['Boolean']>
  Dashboard__SetIsActiveCampaigns?: Maybe<Scalars['Boolean']>
  Dashboard__PlayerBind?: Maybe<Player>
  /**
   * Update one document: 1) Retrieve one document via findOne. 2) Apply updates to
   * mongoose document. 3) Mongoose applies defaults, setters, hooks and
   * validation. 4) And save it.
   */
  Dashboard__UpdatePlayer?: Maybe<UpdateOnePlayerPayload>
  Dashboard__ArchivePlayers?: Maybe<Scalars['Boolean']>
  Dashboard__SetIsActivePlayers?: Maybe<Scalars['Boolean']>
  Dashboard__DisableWorkspace?: Maybe<Scalars['Boolean']>
  Dashboard__InviteWorkspaceMember: Array<Invite>
  Dashboard__RemoveWorkspaceMember?: Maybe<Scalars['Boolean']>
  Dashboard__RevokeWorkspaceMemberInvite: Array<Invite>
  Dashboard__ArchiveWebhooks?: Maybe<Scalars['Boolean']>
  Dashboard__SetupWorkspace?: Maybe<Workspace>
  Dashboard__UpdateWorkspaceDetails?: Maybe<Workspace>
  Dashboard__WorkspaceAddPaymentMethod?: Maybe<Scalars['String']>
  Dashboard__WorkspaceSetDefaultPaymentMethod?: Maybe<Scalars['Boolean']>
  Dashboard__WorkspaceSetupIntent: SetupIntentObject
  Dashboard__WorkspaceRemovePaymentMethod?: Maybe<Scalars['Boolean']>
  Dashboard__WorkspacePayInvoice?: Maybe<Scalars['Boolean']>
}

export type MutationPlayer__CreateBroadcastReportsArgs = {
  broadcastReports: Array<BroadcastReportInput>
}

export type MutationPlayer__GenerateBroadcastPlansArgs = {
  broadcastPlansLimit?: Maybe<Scalars['Int']>
}

export type MutationPlayer__RegisterArgs = {
  unique_id: Scalars['String']
}

export type MutationDashboard__AccountChangePasswordArgs = {
  refreshToken: Scalars['String']
  currentPassword: Scalars['String']
  newPassword: Scalars['String']
}

export type MutationDashboard__AccountEmailAddArgs = {
  email: Scalars['String']
}

export type MutationDashboard__AccountEmailRemoveArgs = {
  email: Scalars['String']
}

export type MutationDashboard__AccountEmailSendVerificationCodeArgs = {
  email: Scalars['String']
}

export type MutationDashboard__AccountEmailSetPrimaryArgs = {
  email: Scalars['String']
}

export type MutationDashboard__AccountEmailVerifyCodeArgs = {
  email: Scalars['String']
  code: Scalars['String']
}

export type MutationDashboard__AccountAcceptWorkspaceInvitesArgs = {
  ids: Array<Scalars['MongoID']>
}

export type MutationDashboard__AccountRejectWorkspaceInvitesArgs = {
  ids: Array<Scalars['MongoID']>
}

export type MutationDashboard__AccountPhoneAddArgs = {
  phone: Scalars['String']
}

export type MutationDashboard__AccountPhoneRemoveArgs = {
  phone: Scalars['String']
}

export type MutationDashboard__AccountPhoneSendVerificationCodeArgs = {
  phone: Scalars['String']
}

export type MutationDashboard__AccountPhoneSetPrimaryArgs = {
  phone: Scalars['String']
}

export type MutationDashboard__AccountPhoneVerifyCodeArgs = {
  phone: Scalars['String']
  code: Scalars['String']
}

export type MutationDashboard__UpdateAccountDetailsArgs = {
  details?: Maybe<Dashboard__AccountUpdateDetailsInput>
}

export type MutationDashboard__AuthForgotPasswordResetArgs = {
  recoveryToken: Scalars['String']
  password: Scalars['String']
}

export type MutationDashboard__AuthForgotPasswordSendCodeArgs = {
  method?: Maybe<ForgotPasswordMethod>
  identifier?: Maybe<Scalars['String']>
}

export type MutationDashboard__AuthForgotPasswordVerifyCodeArgs = {
  method?: Maybe<ForgotPasswordMethod>
  identifier: Scalars['String']
  code: Scalars['String']
}

export type MutationDashboard__AuthRefreshArgs = {
  refreshToken: Scalars['String']
}

export type MutationDashboard__AuthSignInArgs = {
  _id?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  password: Scalars['String']
}

export type MutationDashboard__AuthSignOutArgs = {
  refreshToken: Scalars['String']
}

export type MutationDashboard__AuthSignUpArgs = {
  first_name?: Maybe<Scalars['String']>
  last_name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  password: Scalars['String']
}

export type MutationDashboard__CreateVenueTypeArgs = {
  record: CreateOneVenueTypeInput
}

export type MutationDashboard__BulkCreateVenueTypesArgs = {
  records: Array<CreateManyVenueTypeInput>
}

export type MutationDashboard__UpdateVenueTypeArgs = {
  record: UpdateOneVenueTypeInput
  filter?: Maybe<FilterUpdateOneVenueTypeInput>
  sort?: Maybe<SortUpdateOneVenueTypeInput>
  skip?: Maybe<Scalars['Int']>
}

export type MutationDashboard__BulkUpdateVenueTypesArgs = {
  record: UpdateManyVenueTypeInput
  filter?: Maybe<FilterUpdateManyVenueTypeInput>
  sort?: Maybe<SortUpdateManyVenueTypeInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
}

export type MutationDashboard__CreateCategoryArgs = {
  record: CreateOneCategoryInput
}

export type MutationDashboard__BulkCreateCategoriesArgs = {
  records: Array<CreateManyCategoryInput>
}

export type MutationDashboard__UpdateCategoryArgs = {
  record: UpdateOneCategoryInput
  filter?: Maybe<FilterUpdateOneCategoryInput>
  sort?: Maybe<SortUpdateOneCategoryInput>
  skip?: Maybe<Scalars['Int']>
}

export type MutationDashboard__BulkUpdateCategoriesArgs = {
  record: UpdateManyCategoryInput
  filter?: Maybe<FilterUpdateManyCategoryInput>
  sort?: Maybe<SortUpdateManyCategoryInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
}

export type MutationDashboard__CreateCurrencyArgs = {
  record: CreateOneCurrencyInput
}

export type MutationDashboard__BulkCreateCurrenciesArgs = {
  records: Array<CreateManyCurrencyInput>
}

export type MutationDashboard__UpdateCurrencyArgs = {
  record: UpdateOneCurrencyInput
  filter?: Maybe<FilterUpdateOneCurrencyInput>
  sort?: Maybe<SortUpdateOneCurrencyInput>
  skip?: Maybe<Scalars['Int']>
}

export type MutationDashboard__BulkUpdateCurrenciesArgs = {
  record: UpdateManyCurrencyInput
  filter?: Maybe<FilterUpdateManyCurrencyInput>
  sort?: Maybe<SortUpdateManyCurrencyInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
}

export type MutationDashboard__CreateCountryArgs = {
  record: CreateOneCountryInput
}

export type MutationDashboard__BulkCreateCountriesArgs = {
  records: Array<CreateManyCountryInput>
}

export type MutationDashboard__UpdateCountryArgs = {
  record: UpdateOneCountryInput
  filter?: Maybe<FilterUpdateOneCountryInput>
  sort?: Maybe<SortUpdateOneCountryInput>
  skip?: Maybe<Scalars['Int']>
}

export type MutationDashboard__BulkUpdateCountriesArgs = {
  record: UpdateManyCountryInput
  filter?: Maybe<FilterUpdateManyCountryInput>
  sort?: Maybe<SortUpdateManyCountryInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
}

export type MutationDashboard__CreateDistanceUnitArgs = {
  record: CreateOneDistanceUnitInput
}

export type MutationDashboard__BulkCreateDistanceUnitsArgs = {
  records: Array<CreateManyDistanceUnitInput>
}

export type MutationDashboard__UpdateDistanceUnitArgs = {
  record: UpdateOneDistanceUnitInput
  filter?: Maybe<FilterUpdateOneDistanceUnitInput>
  sort?: Maybe<SortUpdateOneDistanceUnitInput>
  skip?: Maybe<Scalars['Int']>
}

export type MutationDashboard__BulkUpdateDistanceUnitsArgs = {
  record: UpdateManyDistanceUnitInput
  filter?: Maybe<FilterUpdateManyDistanceUnitInput>
  sort?: Maybe<SortUpdateManyDistanceUnitInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
}

export type MutationDashboard__CreateLanguageArgs = {
  record: CreateOneLanguageInput
}

export type MutationDashboard__BulkCreateLanguagesArgs = {
  records: Array<CreateManyLanguageInput>
}

export type MutationDashboard__UpdateLanguageArgs = {
  record: UpdateOneLanguageInput
  filter?: Maybe<FilterUpdateOneLanguageInput>
  sort?: Maybe<SortUpdateOneLanguageInput>
  skip?: Maybe<Scalars['Int']>
}

export type MutationDashboard__BulkUpdateLanguagesArgs = {
  record: UpdateManyLanguageInput
  filter?: Maybe<FilterUpdateManyLanguageInput>
  sort?: Maybe<SortUpdateManyLanguageInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
}

export type MutationDashboard__CreateCampaignArgs = {
  record: CreateOneCampaignInput
}

export type MutationDashboard__BulkCreateCampaignsArgs = {
  records: Array<CreateManyCampaignInput>
}

export type MutationDashboard__UpdateCampaignArgs = {
  record: UpdateOneCampaignInput
  filter?: Maybe<FilterUpdateOneCampaignInput>
  sort?: Maybe<SortUpdateOneCampaignInput>
  skip?: Maybe<Scalars['Int']>
}

export type MutationDashboard__BulkUpdateCampaignsArgs = {
  record: UpdateManyCampaignInput
  filter?: Maybe<FilterUpdateManyCampaignInput>
  sort?: Maybe<SortUpdateManyCampaignInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
}

export type MutationDashboard__CreateCampaignAdGroupArgs = {
  record: CreateOneCampaignAdGroupInput
}

export type MutationDashboard__BulkCreateCampaignAdGroupsArgs = {
  records: Array<CreateManyCampaignAdGroupInput>
}

export type MutationDashboard__UpdateCampaignAdGroupArgs = {
  record: UpdateOneCampaignAdGroupInput
  filter?: Maybe<FilterUpdateOneCampaignAdGroupInput>
  sort?: Maybe<SortUpdateOneCampaignAdGroupInput>
  skip?: Maybe<Scalars['Int']>
}

export type MutationDashboard__BulkUpdateCampaignAdGroupsArgs = {
  record: UpdateManyCampaignAdGroupInput
  filter?: Maybe<FilterUpdateManyCampaignAdGroupInput>
  sort?: Maybe<SortUpdateManyCampaignAdGroupInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
}

export type MutationDashboard__CreateUserArgs = {
  record: CreateOneUserInput
}

export type MutationDashboard__BulkCreateUsersArgs = {
  records: Array<CreateManyUserInput>
}

export type MutationDashboard__UpdateUserArgs = {
  record: UpdateOneUserInput
  filter?: Maybe<FilterUpdateOneUserInput>
  sort?: Maybe<SortUpdateOneUserInput>
  skip?: Maybe<Scalars['Int']>
}

export type MutationDashboard__BulkUpdateUsersArgs = {
  record: UpdateManyUserInput
  filter?: Maybe<FilterUpdateManyUserInput>
  sort?: Maybe<SortUpdateManyUserInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
}

export type MutationDashboard__CreateCreativeArgs = {
  record: CreateOneCreativeInput
}

export type MutationDashboard__BulkCreateCreativesArgs = {
  records: Array<CreateManyCreativeInput>
}

export type MutationDashboard__UpdateCreativeArgs = {
  record: UpdateOneCreativeInput
  filter?: Maybe<FilterUpdateOneCreativeInput>
  sort?: Maybe<SortUpdateOneCreativeInput>
  skip?: Maybe<Scalars['Int']>
}

export type MutationDashboard__BulkUpdateCreativesArgs = {
  record: UpdateManyCreativeInput
  filter?: Maybe<FilterUpdateManyCreativeInput>
  sort?: Maybe<SortUpdateManyCreativeInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
}

export type MutationDashboard__CreateWebhookArgs = {
  record: CreateOneWebhookInput
}

export type MutationDashboard__BulkCreateWebhooksArgs = {
  records: Array<CreateManyWebhookInput>
}

export type MutationDashboard__UpdateWebhookArgs = {
  record: UpdateOneWebhookInput
  filter?: Maybe<FilterUpdateOneWebhookInput>
  sort?: Maybe<SortUpdateOneWebhookInput>
  skip?: Maybe<Scalars['Int']>
}

export type MutationDashboard__BulkCreateWebhookArgs = {
  record: UpdateManyWebhookInput
  filter?: Maybe<FilterUpdateManyWebhookInput>
  sort?: Maybe<SortUpdateManyWebhookInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
}

export type MutationDashboard__PreSignCreativeUploadArgs = {
  categories_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  filename?: Maybe<Scalars['String']>
  mimetype?: Maybe<Scalars['String']>
  creative_type?: Maybe<EnumCreativeType>
}

export type MutationDashboard__NotifyCreativeUploadCompletedArgs = {
  creative_id?: Maybe<Scalars['MongoID']>
}

export type MutationDashboard__ArchiveCreativesArgs = {
  ids: Array<Scalars['MongoID']>
}

export type MutationDashboard__SetIsActiveCreativesArgs = {
  ids: Array<Scalars['MongoID']>
  is_active: Scalars['Boolean']
}

export type MutationDashboard__ArchiveNetworksArgs = {
  ids: Array<Scalars['MongoID']>
}

export type MutationDashboard__CreateNetworkArgs = {
  record: CreateOneNetworkInput
}

export type MutationDashboard__BulkCreateNetworksArgs = {
  records: Array<CreateManyNetworkInput>
}

export type MutationDashboard__UpdateNetworkArgs = {
  record: UpdateOneNetworkInput
  filter?: Maybe<FilterUpdateOneNetworkInput>
  sort?: Maybe<SortUpdateOneNetworkInput>
  skip?: Maybe<Scalars['Int']>
}

export type MutationDashboard__BulkUpdateNetworksArgs = {
  record: UpdateManyNetworkInput
  filter?: Maybe<FilterUpdateManyNetworkInput>
  sort?: Maybe<SortUpdateManyNetworkInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
}

export type MutationDashboard__SetIsActiveNetworksArgs = {
  ids: Array<Scalars['MongoID']>
  is_active: Scalars['Boolean']
}

export type MutationDashboard__ArchiveCampaignAdGroupsArgs = {
  ids: Array<Scalars['MongoID']>
}

export type MutationDashboard__SetIsActiveCampaignAdGroupsArgs = {
  ids: Array<Scalars['MongoID']>
  is_active: Scalars['Boolean']
}

export type MutationDashboard__ArchiveCampaignsArgs = {
  ids: Array<Scalars['MongoID']>
}

export type MutationDashboard__SetIsActiveCampaignsArgs = {
  ids: Array<Scalars['MongoID']>
  is_active: Scalars['Boolean']
}

export type MutationDashboard__PlayerBindArgs = {
  token?: Maybe<Scalars['String']>
}

export type MutationDashboard__UpdatePlayerArgs = {
  record: UpdateOnePlayerInput
  filter?: Maybe<FilterUpdateOnePlayerInput>
  sort?: Maybe<SortUpdateOnePlayerInput>
  skip?: Maybe<Scalars['Int']>
}

export type MutationDashboard__ArchivePlayersArgs = {
  ids: Array<Scalars['MongoID']>
}

export type MutationDashboard__SetIsActivePlayersArgs = {
  ids: Array<Scalars['MongoID']>
  is_active: Scalars['Boolean']
}

export type MutationDashboard__DisableWorkspaceArgs = {
  _id: Scalars['String']
}

export type MutationDashboard__InviteWorkspaceMemberArgs = {
  emails: Array<Scalars['String']>
}

export type MutationDashboard__RemoveWorkspaceMemberArgs = {
  email: Scalars['String']
}

export type MutationDashboard__RevokeWorkspaceMemberInviteArgs = {
  ids: Array<Scalars['MongoID']>
}

export type MutationDashboard__ArchiveWebhooksArgs = {
  ids: Array<Scalars['MongoID']>
}

export type MutationDashboard__SetupWorkspaceArgs = {
  name?: Maybe<Scalars['String']>
  platform_type?: Maybe<EnumWorkspacePlatform_Type>
}

export type MutationDashboard__UpdateWorkspaceDetailsArgs = {
  _id: Scalars['String']
  details?: Maybe<Dashboard__WorkspaceUpdateDetailsInput>
}

export type MutationDashboard__WorkspaceAddPaymentMethodArgs = {
  paymentMethod?: Maybe<PaymentMethodInput>
}

export type MutationDashboard__WorkspaceSetDefaultPaymentMethodArgs = {
  paymentMethodId: Scalars['String']
}

export type MutationDashboard__WorkspaceSetupIntentArgs = {
  setupIntentArgs?: Maybe<SetupIntentInput>
}

export type MutationDashboard__WorkspaceRemovePaymentMethodArgs = {
  paymentMethodId?: Maybe<Scalars['String']>
}

export type MutationDashboard__WorkspacePayInvoiceArgs = {
  invoiceId: Scalars['String']
}

export type NameOperatorsFilterCampaignAdGroupInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterCampaignInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterCategoryInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterCountryInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterCreativeInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterCurrencyInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterDistanceUnitInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindManyCampaignAdGroupInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindManyCampaignInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindManyCategoryInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindManyCountryInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindManyCreativeInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindManyCurrencyInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindManyDistanceUnitInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindManyLanguageInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindManyNetworkInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindManyPlayerInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindManyUploadInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindManyVenueTypeInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindOneCampaignAdGroupInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindOneCampaignInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindOneCategoryInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindOneCountryInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindOneCreativeInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindOneCurrencyInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindOneDistanceUnitInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindOneLanguageInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindOneNetworkInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindOnePlayerInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterFindOneUploadInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterLanguageInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterNetworkInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterPlayerInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterUpdateManyCampaignAdGroupInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterUpdateManyCampaignInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterUpdateManyCategoryInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterUpdateManyCountryInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterUpdateManyCreativeInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterUpdateManyCurrencyInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterUpdateManyDistanceUnitInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterUpdateManyLanguageInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterUpdateManyNetworkInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterUpdateManyVenueTypeInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterUpdateOneCampaignAdGroupInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterUpdateOneCampaignInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterUpdateOneCategoryInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterUpdateOneCountryInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterUpdateOneCreativeInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterUpdateOneCurrencyInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterUpdateOneDistanceUnitInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterUpdateOneLanguageInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterUpdateOneNetworkInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterUpdateOnePlayerInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterUpdateOneVenueTypeInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterUploadInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterVenueTypeInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type NameOperatorsFilterWorkspaceInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type Network = {
  __typename?: 'Network'
  name: Scalars['String']
  is_active?: Maybe<Scalars['Boolean']>
  ownership?: Maybe<Ownership>
  players: Array<Maybe<NetworkPlayers>>
  created?: Maybe<ActionDateAndUser>
  archived?: Maybe<ActionDateAndUser>
  _id: Scalars['MongoID']
  getStats: Array<StatsInterval>
  status?: Maybe<EnumNetworkStatus>
}

export type NetworkGetStatsArgs = {
  intervals: Array<StatsIntervalInput>
}

export type NetworkPlayers = {
  __typename?: 'NetworkPlayers'
  player_id?: Maybe<Scalars['MongoID']>
  added?: Maybe<ActionDateAndUser>
  player?: Maybe<Player>
}

export type NetworkPlayersInput = {
  player_id?: Maybe<Scalars['MongoID']>
  added?: Maybe<ActionDateAndUserInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterAreaTypeInput = {
  _id?: Maybe<_IdOperatorsFilterAreaTypeInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterBroadcastPlanInput = {
  _id?: Maybe<_IdOperatorsFilterBroadcastPlanInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterBroadcastReportInput = {
  _id?: Maybe<_IdOperatorsFilterBroadcastReportInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterCampaignAdGroupInput = {
  name?: Maybe<NameOperatorsFilterCampaignAdGroupInput>
  _id?: Maybe<_IdOperatorsFilterCampaignAdGroupInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterCampaignInput = {
  name?: Maybe<NameOperatorsFilterCampaignInput>
  _id?: Maybe<_IdOperatorsFilterCampaignInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterCategoryInput = {
  name?: Maybe<NameOperatorsFilterCategoryInput>
  _id?: Maybe<_IdOperatorsFilterCategoryInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterCountryInput = {
  name?: Maybe<NameOperatorsFilterCountryInput>
  _id?: Maybe<_IdOperatorsFilterCountryInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterCreativeInput = {
  name?: Maybe<NameOperatorsFilterCreativeInput>
  _id?: Maybe<_IdOperatorsFilterCreativeInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterCurrencyInput = {
  name?: Maybe<NameOperatorsFilterCurrencyInput>
  _id?: Maybe<_IdOperatorsFilterCurrencyInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterDistanceUnitInput = {
  name?: Maybe<NameOperatorsFilterDistanceUnitInput>
  _id?: Maybe<_IdOperatorsFilterDistanceUnitInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyAreaTypeInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyAreaTypeInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyBroadcastPlanInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyBroadcastPlanInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyBroadcastReportInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyBroadcastReportInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyCampaignAdGroupInput = {
  name?: Maybe<NameOperatorsFilterFindManyCampaignAdGroupInput>
  _id?: Maybe<_IdOperatorsFilterFindManyCampaignAdGroupInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyCampaignInput = {
  name?: Maybe<NameOperatorsFilterFindManyCampaignInput>
  _id?: Maybe<_IdOperatorsFilterFindManyCampaignInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyCategoryInput = {
  name?: Maybe<NameOperatorsFilterFindManyCategoryInput>
  _id?: Maybe<_IdOperatorsFilterFindManyCategoryInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyCountryInput = {
  name?: Maybe<NameOperatorsFilterFindManyCountryInput>
  _id?: Maybe<_IdOperatorsFilterFindManyCountryInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyCreativeInput = {
  name?: Maybe<NameOperatorsFilterFindManyCreativeInput>
  _id?: Maybe<_IdOperatorsFilterFindManyCreativeInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyCurrencyInput = {
  name?: Maybe<NameOperatorsFilterFindManyCurrencyInput>
  _id?: Maybe<_IdOperatorsFilterFindManyCurrencyInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyDistanceUnitInput = {
  name?: Maybe<NameOperatorsFilterFindManyDistanceUnitInput>
  _id?: Maybe<_IdOperatorsFilterFindManyDistanceUnitInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyInviteInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyInviteInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyLanguageInput = {
  name?: Maybe<NameOperatorsFilterFindManyLanguageInput>
  _id?: Maybe<_IdOperatorsFilterFindManyLanguageInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyNetworkInput = {
  name?: Maybe<NameOperatorsFilterFindManyNetworkInput>
  _id?: Maybe<_IdOperatorsFilterFindManyNetworkInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyPlayerInput = {
  name?: Maybe<NameOperatorsFilterFindManyPlayerInput>
  secret?: Maybe<SecretOperatorsFilterFindManyPlayerInput>
  _id?: Maybe<_IdOperatorsFilterFindManyPlayerInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyUploadInput = {
  name?: Maybe<NameOperatorsFilterFindManyUploadInput>
  _id?: Maybe<_IdOperatorsFilterFindManyUploadInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyUserInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyUserInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyVenueTypeInput = {
  name?: Maybe<NameOperatorsFilterFindManyVenueTypeInput>
  _id?: Maybe<_IdOperatorsFilterFindManyVenueTypeInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyWebhookEventInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyWebhookEventInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyWebhookEventResponseInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyWebhookEventResponseInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindManyWebhookInput = {
  _id?: Maybe<_IdOperatorsFilterFindManyWebhookInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneBroadcastPlanInput = {
  _id?: Maybe<_IdOperatorsFilterFindOneBroadcastPlanInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneBroadcastReportInput = {
  _id?: Maybe<_IdOperatorsFilterFindOneBroadcastReportInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneCampaignAdGroupInput = {
  name?: Maybe<NameOperatorsFilterFindOneCampaignAdGroupInput>
  _id?: Maybe<_IdOperatorsFilterFindOneCampaignAdGroupInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneCampaignInput = {
  name?: Maybe<NameOperatorsFilterFindOneCampaignInput>
  _id?: Maybe<_IdOperatorsFilterFindOneCampaignInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneCategoryInput = {
  name?: Maybe<NameOperatorsFilterFindOneCategoryInput>
  _id?: Maybe<_IdOperatorsFilterFindOneCategoryInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneCountryInput = {
  name?: Maybe<NameOperatorsFilterFindOneCountryInput>
  _id?: Maybe<_IdOperatorsFilterFindOneCountryInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneCreativeInput = {
  name?: Maybe<NameOperatorsFilterFindOneCreativeInput>
  _id?: Maybe<_IdOperatorsFilterFindOneCreativeInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneCurrencyInput = {
  name?: Maybe<NameOperatorsFilterFindOneCurrencyInput>
  _id?: Maybe<_IdOperatorsFilterFindOneCurrencyInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneDistanceUnitInput = {
  name?: Maybe<NameOperatorsFilterFindOneDistanceUnitInput>
  _id?: Maybe<_IdOperatorsFilterFindOneDistanceUnitInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneInviteInput = {
  _id?: Maybe<_IdOperatorsFilterFindOneInviteInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneLanguageInput = {
  name?: Maybe<NameOperatorsFilterFindOneLanguageInput>
  _id?: Maybe<_IdOperatorsFilterFindOneLanguageInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneNetworkInput = {
  name?: Maybe<NameOperatorsFilterFindOneNetworkInput>
  _id?: Maybe<_IdOperatorsFilterFindOneNetworkInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOnePlayerInput = {
  name?: Maybe<NameOperatorsFilterFindOnePlayerInput>
  secret?: Maybe<SecretOperatorsFilterFindOnePlayerInput>
  _id?: Maybe<_IdOperatorsFilterFindOnePlayerInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneUploadInput = {
  name?: Maybe<NameOperatorsFilterFindOneUploadInput>
  _id?: Maybe<_IdOperatorsFilterFindOneUploadInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneUserInput = {
  _id?: Maybe<_IdOperatorsFilterFindOneUserInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterFindOneWebhookInput = {
  _id?: Maybe<_IdOperatorsFilterFindOneWebhookInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterInviteInput = {
  _id?: Maybe<_IdOperatorsFilterInviteInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterLanguageInput = {
  name?: Maybe<NameOperatorsFilterLanguageInput>
  _id?: Maybe<_IdOperatorsFilterLanguageInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterNetworkInput = {
  name?: Maybe<NameOperatorsFilterNetworkInput>
  _id?: Maybe<_IdOperatorsFilterNetworkInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterPlayerInput = {
  name?: Maybe<NameOperatorsFilterPlayerInput>
  secret?: Maybe<SecretOperatorsFilterPlayerInput>
  _id?: Maybe<_IdOperatorsFilterPlayerInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateManyCampaignAdGroupInput = {
  name?: Maybe<NameOperatorsFilterUpdateManyCampaignAdGroupInput>
  _id?: Maybe<_IdOperatorsFilterUpdateManyCampaignAdGroupInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateManyCampaignInput = {
  name?: Maybe<NameOperatorsFilterUpdateManyCampaignInput>
  _id?: Maybe<_IdOperatorsFilterUpdateManyCampaignInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateManyCategoryInput = {
  name?: Maybe<NameOperatorsFilterUpdateManyCategoryInput>
  _id?: Maybe<_IdOperatorsFilterUpdateManyCategoryInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateManyCountryInput = {
  name?: Maybe<NameOperatorsFilterUpdateManyCountryInput>
  _id?: Maybe<_IdOperatorsFilterUpdateManyCountryInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateManyCreativeInput = {
  name?: Maybe<NameOperatorsFilterUpdateManyCreativeInput>
  _id?: Maybe<_IdOperatorsFilterUpdateManyCreativeInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateManyCurrencyInput = {
  name?: Maybe<NameOperatorsFilterUpdateManyCurrencyInput>
  _id?: Maybe<_IdOperatorsFilterUpdateManyCurrencyInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateManyDistanceUnitInput = {
  name?: Maybe<NameOperatorsFilterUpdateManyDistanceUnitInput>
  _id?: Maybe<_IdOperatorsFilterUpdateManyDistanceUnitInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateManyLanguageInput = {
  name?: Maybe<NameOperatorsFilterUpdateManyLanguageInput>
  _id?: Maybe<_IdOperatorsFilterUpdateManyLanguageInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateManyNetworkInput = {
  name?: Maybe<NameOperatorsFilterUpdateManyNetworkInput>
  _id?: Maybe<_IdOperatorsFilterUpdateManyNetworkInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateManyUserInput = {
  _id?: Maybe<_IdOperatorsFilterUpdateManyUserInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateManyVenueTypeInput = {
  name?: Maybe<NameOperatorsFilterUpdateManyVenueTypeInput>
  _id?: Maybe<_IdOperatorsFilterUpdateManyVenueTypeInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateManyWebhookInput = {
  _id?: Maybe<_IdOperatorsFilterUpdateManyWebhookInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneCampaignAdGroupInput = {
  name?: Maybe<NameOperatorsFilterUpdateOneCampaignAdGroupInput>
  _id?: Maybe<_IdOperatorsFilterUpdateOneCampaignAdGroupInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneCampaignInput = {
  name?: Maybe<NameOperatorsFilterUpdateOneCampaignInput>
  _id?: Maybe<_IdOperatorsFilterUpdateOneCampaignInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneCategoryInput = {
  name?: Maybe<NameOperatorsFilterUpdateOneCategoryInput>
  _id?: Maybe<_IdOperatorsFilterUpdateOneCategoryInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneCountryInput = {
  name?: Maybe<NameOperatorsFilterUpdateOneCountryInput>
  _id?: Maybe<_IdOperatorsFilterUpdateOneCountryInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneCreativeInput = {
  name?: Maybe<NameOperatorsFilterUpdateOneCreativeInput>
  _id?: Maybe<_IdOperatorsFilterUpdateOneCreativeInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneCurrencyInput = {
  name?: Maybe<NameOperatorsFilterUpdateOneCurrencyInput>
  _id?: Maybe<_IdOperatorsFilterUpdateOneCurrencyInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneDistanceUnitInput = {
  name?: Maybe<NameOperatorsFilterUpdateOneDistanceUnitInput>
  _id?: Maybe<_IdOperatorsFilterUpdateOneDistanceUnitInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneLanguageInput = {
  name?: Maybe<NameOperatorsFilterUpdateOneLanguageInput>
  _id?: Maybe<_IdOperatorsFilterUpdateOneLanguageInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneNetworkInput = {
  name?: Maybe<NameOperatorsFilterUpdateOneNetworkInput>
  _id?: Maybe<_IdOperatorsFilterUpdateOneNetworkInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOnePlayerInput = {
  name?: Maybe<NameOperatorsFilterUpdateOnePlayerInput>
  secret?: Maybe<SecretOperatorsFilterUpdateOnePlayerInput>
  _id?: Maybe<_IdOperatorsFilterUpdateOnePlayerInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneUserInput = {
  _id?: Maybe<_IdOperatorsFilterUpdateOneUserInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneVenueTypeInput = {
  name?: Maybe<NameOperatorsFilterUpdateOneVenueTypeInput>
  _id?: Maybe<_IdOperatorsFilterUpdateOneVenueTypeInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUpdateOneWebhookInput = {
  _id?: Maybe<_IdOperatorsFilterUpdateOneWebhookInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUploadInput = {
  name?: Maybe<NameOperatorsFilterUploadInput>
  _id?: Maybe<_IdOperatorsFilterUploadInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterUserInput = {
  _id?: Maybe<_IdOperatorsFilterUserInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterVenueTypeInput = {
  name?: Maybe<NameOperatorsFilterVenueTypeInput>
  _id?: Maybe<_IdOperatorsFilterVenueTypeInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterWebhookInput = {
  _id?: Maybe<_IdOperatorsFilterWebhookInput>
}

/** For performance reason this type contains only *indexed* fields. */
export type OperatorsFilterWorkspaceInput = {
  name?: Maybe<NameOperatorsFilterWorkspaceInput>
  _id?: Maybe<_IdOperatorsFilterWorkspaceInput>
}

export type Ownership = {
  __typename?: 'Ownership'
  workspace_id?: Maybe<Scalars['MongoID']>
}

export type PaymentMethodBillingDetails = {
  city?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  line1?: Maybe<Scalars['String']>
  postal_code?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  company?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  vat?: Maybe<Scalars['String']>
}

export type PaymentMethodCard = {
  card_number?: Maybe<Scalars['String']>
  exp_month?: Maybe<Scalars['Int']>
  exp_year?: Maybe<Scalars['Int']>
  cvc?: Maybe<Scalars['String']>
}

export type PaymentMethodData = {
  __typename?: 'paymentMethodData'
  id?: Maybe<Scalars['String']>
  object?: Maybe<Scalars['String']>
  card?: Maybe<Card>
  billing_details?: Maybe<BillingDetails>
  metadata?: Maybe<PaymentMethodsMetada>
}

export type PaymentMethodInput = {
  setupIntentId?: Maybe<Scalars['String']>
  makeDefault?: Maybe<Scalars['Boolean']>
}

export type PaymentMethodsMetada = {
  __typename?: 'PaymentMethodsMetada'
  vat?: Maybe<Scalars['String']>
  company?: Maybe<Scalars['String']>
}

export type Player = {
  __typename?: 'Player'
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  unique_id?: Maybe<Scalars['String']>
  ownership?: Maybe<Ownership>
  capabilities?: Maybe<PlayerCapabilities>
  demographics?: Maybe<PlayerDemographics>
  geographics?: Maybe<PlayerGeographics>
  settings?: Maybe<PlayerSettings>
  telemetry?: Maybe<PlayerTelemetry>
  binded?: Maybe<ActionDateAndUser>
  activated?: Maybe<ActionDateAndUser>
  secret?: Maybe<Scalars['String']>
  last_seen_at?: Maybe<Scalars['Date']>
  created?: Maybe<ActionDateAndUser>
  archived?: Maybe<ActionDateAndUser>
  _id: Scalars['MongoID']
  getStats: Array<StatsInterval>
  status?: Maybe<EnumPlayerStatus>
}

export type PlayerGetStatsArgs = {
  intervals: Array<StatsIntervalInput>
}

export type PlayerCapabilities = {
  __typename?: 'PlayerCapabilities'
  playback?: Maybe<PlayerCapabilitiesPlayback>
}

export type PlayerCapabilitiesInput = {
  playback?: Maybe<PlayerCapabilitiesPlaybackInput>
}

export type PlayerCapabilitiesPlayback = {
  __typename?: 'PlayerCapabilitiesPlayback'
  videos?: Maybe<Scalars['Boolean']>
  audios?: Maybe<Scalars['Boolean']>
  images?: Maybe<Scalars['Boolean']>
}

export type PlayerCapabilitiesPlaybackInput = {
  videos?: Maybe<Scalars['Boolean']>
  audios?: Maybe<Scalars['Boolean']>
  images?: Maybe<Scalars['Boolean']>
}

export type PlayerDemographics = {
  __typename?: 'PlayerDemographics'
  language_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  country_id?: Maybe<Scalars['MongoID']>
  area_type_id?: Maybe<Scalars['MongoID']>
  venue_type_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type PlayerDemographicsInput = {
  language_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  country_id?: Maybe<Scalars['MongoID']>
  area_type_id?: Maybe<Scalars['MongoID']>
  venue_type_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type PlayerGeographics = {
  __typename?: 'PlayerGeographics'
  location?: Maybe<Point>
  address?: Maybe<Address>
}

export type PlayerGeographicsInput = {
  location?: Maybe<PointInput>
  address?: Maybe<AddressInput>
}

export type PlayerSettings = {
  __typename?: 'PlayerSettings'
  debug?: Maybe<Scalars['Boolean']>
  volume?: Maybe<Scalars['Float']>
  brightness?: Maybe<Scalars['Float']>
  screen_orientation?: Maybe<EnumPlayerSettingsScreen_Orientation>
}

export type PlayerSettingsInput = {
  debug?: Maybe<Scalars['Boolean']>
  volume?: Maybe<Scalars['Float']>
  brightness?: Maybe<Scalars['Float']>
  screen_orientation?: Maybe<EnumPlayerSettingsScreen_Orientation>
}

export type PlayerTelemetry = {
  __typename?: 'PlayerTelemetry'
  scans?: Maybe<Scalars['Float']>
  impressions?: Maybe<Scalars['Float']>
}

export type PlayerTelemetryInput = {
  scans?: Maybe<Scalars['Float']>
  impressions?: Maybe<Scalars['Float']>
}

export type Point = {
  __typename?: 'Point'
  type?: Maybe<EnumPointType>
  coordinates?: Maybe<Array<Maybe<Scalars['Float']>>>
}

export type PointInput = {
  type?: Maybe<EnumPointType>
  coordinates?: Maybe<Array<Maybe<Scalars['Float']>>>
}

export type Query = {
  __typename?: 'Query'
  Player__Whoami?: Maybe<Player>
  Dashboard__FindWorkspace?: Maybe<Workspace>
  Dashboard__FindWorkspaces?: Maybe<Array<Workspace>>
  Dashboard__CountWorkspaces?: Maybe<Scalars['Int']>
  Dashboard__FindVenueType?: Maybe<VenueType>
  Dashboard__FindVenueTypes?: Maybe<Array<VenueType>>
  Dashboard__CountVenueTypes?: Maybe<Scalars['Int']>
  Dashboard__FindAreaType?: Maybe<AreaType>
  Dashboard__FindAreaTypes?: Maybe<Array<AreaType>>
  Dashboard__CountAreaTypes?: Maybe<Scalars['Int']>
  Dashboard__FindCategory?: Maybe<Category>
  Dashboard__FindCategories?: Maybe<Array<Category>>
  Dashboard__CountCategories?: Maybe<Scalars['Int']>
  Dashboard__FindCurrency?: Maybe<Currency>
  Dashboard__FindCurrencies?: Maybe<Array<Currency>>
  Dashboard__CountCurrencies?: Maybe<Scalars['Int']>
  Dashboard__FindCountry?: Maybe<Country>
  Dashboard__FindCountries?: Maybe<Array<Country>>
  Dashboard__CountCountries?: Maybe<Scalars['Int']>
  Dashboard__FindDistanceUnit?: Maybe<DistanceUnit>
  Dashboard__FindDistanceUnits?: Maybe<Array<DistanceUnit>>
  Dashboard__CountDistanceUnits?: Maybe<Scalars['Int']>
  Dashboard__FindLanguage?: Maybe<Language>
  Dashboard__FindLanguages?: Maybe<Array<Language>>
  Dashboard__CountLanguages?: Maybe<Scalars['Int']>
  Dashboard__FindBroadcastPlan?: Maybe<BroadcastPlan>
  Dashboard__FindBroadcastPlans?: Maybe<Array<BroadcastPlan>>
  Dashboard__CountBroadcastPlans?: Maybe<Scalars['Int']>
  Dashboard__FindBroadcastReport?: Maybe<BroadcastReport>
  Dashboard__FindBroadcastReports?: Maybe<Array<BroadcastReport>>
  Dashboard__CountBroadcastReports?: Maybe<Scalars['Int']>
  Dashboard__FindCampaign?: Maybe<Campaign>
  Dashboard__FindCampaigns?: Maybe<Array<Campaign>>
  Dashboard__CountCampaigns?: Maybe<Scalars['Int']>
  Dashboard__FindCampaignAdGroup?: Maybe<CampaignAdGroup>
  Dashboard__FindCampaignAdGroups?: Maybe<Array<CampaignAdGroup>>
  Dashboard__CountCampaignAdGroups?: Maybe<Scalars['Int']>
  Dashboard__FindNetwork?: Maybe<Network>
  Dashboard__FindNetworks?: Maybe<Array<Network>>
  Dashboard__CountNetworks?: Maybe<Scalars['Int']>
  Dashboard__FindPlayer?: Maybe<Player>
  Dashboard__FindPlayers?: Maybe<Array<Player>>
  Dashboard__CountPlayers?: Maybe<Scalars['Int']>
  Dashboard__FindUser?: Maybe<User>
  Dashboard__FindUsers?: Maybe<Array<User>>
  Dashboard__CountUsers?: Maybe<Scalars['Int']>
  Dashboard__FindUpload?: Maybe<Upload>
  Dashboard__FindUploads?: Maybe<Array<Upload>>
  Dashboard__CountUploads?: Maybe<Scalars['Int']>
  Dashboard__FindCreative?: Maybe<Creative>
  Dashboard__FindCreatives?: Maybe<Array<Creative>>
  Dashboard__CountCreatives?: Maybe<Scalars['Int']>
  Dashboard__FindInvite?: Maybe<Invite>
  Dashboard__FindInvites?: Maybe<Array<Invite>>
  Dashboard__CountInvites?: Maybe<Scalars['Int']>
  Dashboard__FindWebhook?: Maybe<Webhook>
  Dashboard__FindWebhooks?: Maybe<Array<Webhook>>
  Dashboard__CountWebhooks?: Maybe<Scalars['Int']>
  Dashboard__WhoAmI?: Maybe<Dashboard__WhoAmIUserResult>
}

export type QueryDashboard__FindWorkspaceArgs = {
  _id: Scalars['MongoID']
}

export type QueryDashboard__FindWorkspacesArgs = {
  _ids: Array<Maybe<Scalars['MongoID']>>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindByIdsWorkspaceInput>
}

export type QueryDashboard__CountWorkspacesArgs = {
  filter?: Maybe<FilterWorkspaceInput>
}

export type QueryDashboard__FindVenueTypeArgs = {
  _id: Scalars['MongoID']
}

export type QueryDashboard__FindVenueTypesArgs = {
  filter?: Maybe<FilterFindManyVenueTypeInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyVenueTypeInput>
}

export type QueryDashboard__CountVenueTypesArgs = {
  filter?: Maybe<FilterVenueTypeInput>
}

export type QueryDashboard__FindAreaTypeArgs = {
  _id: Scalars['MongoID']
}

export type QueryDashboard__FindAreaTypesArgs = {
  filter?: Maybe<FilterFindManyAreaTypeInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyAreaTypeInput>
}

export type QueryDashboard__CountAreaTypesArgs = {
  filter?: Maybe<FilterAreaTypeInput>
}

export type QueryDashboard__FindCategoryArgs = {
  filter?: Maybe<FilterFindOneCategoryInput>
  skip?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindOneCategoryInput>
}

export type QueryDashboard__FindCategoriesArgs = {
  filter?: Maybe<FilterFindManyCategoryInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyCategoryInput>
}

export type QueryDashboard__CountCategoriesArgs = {
  filter?: Maybe<FilterCategoryInput>
}

export type QueryDashboard__FindCurrencyArgs = {
  filter?: Maybe<FilterFindOneCurrencyInput>
  skip?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindOneCurrencyInput>
}

export type QueryDashboard__FindCurrenciesArgs = {
  filter?: Maybe<FilterFindManyCurrencyInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyCurrencyInput>
}

export type QueryDashboard__CountCurrenciesArgs = {
  filter?: Maybe<FilterCurrencyInput>
}

export type QueryDashboard__FindCountryArgs = {
  filter?: Maybe<FilterFindOneCountryInput>
  skip?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindOneCountryInput>
}

export type QueryDashboard__FindCountriesArgs = {
  filter?: Maybe<FilterFindManyCountryInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyCountryInput>
}

export type QueryDashboard__CountCountriesArgs = {
  filter?: Maybe<FilterCountryInput>
}

export type QueryDashboard__FindDistanceUnitArgs = {
  filter?: Maybe<FilterFindOneDistanceUnitInput>
  skip?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindOneDistanceUnitInput>
}

export type QueryDashboard__FindDistanceUnitsArgs = {
  filter?: Maybe<FilterFindManyDistanceUnitInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyDistanceUnitInput>
}

export type QueryDashboard__CountDistanceUnitsArgs = {
  filter?: Maybe<FilterDistanceUnitInput>
}

export type QueryDashboard__FindLanguageArgs = {
  filter?: Maybe<FilterFindOneLanguageInput>
  skip?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindOneLanguageInput>
}

export type QueryDashboard__FindLanguagesArgs = {
  filter?: Maybe<FilterFindManyLanguageInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyLanguageInput>
}

export type QueryDashboard__CountLanguagesArgs = {
  filter?: Maybe<FilterLanguageInput>
}

export type QueryDashboard__FindBroadcastPlanArgs = {
  filter?: Maybe<FilterFindOneBroadcastPlanInput>
  skip?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindOneBroadcastPlanInput>
}

export type QueryDashboard__FindBroadcastPlansArgs = {
  filter?: Maybe<FilterFindManyBroadcastPlanInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyBroadcastPlanInput>
}

export type QueryDashboard__CountBroadcastPlansArgs = {
  filter?: Maybe<FilterBroadcastPlanInput>
}

export type QueryDashboard__FindBroadcastReportArgs = {
  filter?: Maybe<FilterFindOneBroadcastReportInput>
  skip?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindOneBroadcastReportInput>
}

export type QueryDashboard__FindBroadcastReportsArgs = {
  filter?: Maybe<FilterFindManyBroadcastReportInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyBroadcastReportInput>
}

export type QueryDashboard__CountBroadcastReportsArgs = {
  filter?: Maybe<FilterBroadcastReportInput>
}

export type QueryDashboard__FindCampaignArgs = {
  filter?: Maybe<FilterFindOneCampaignInput>
  skip?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindOneCampaignInput>
}

export type QueryDashboard__FindCampaignsArgs = {
  filter?: Maybe<FilterFindManyCampaignInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyCampaignInput>
}

export type QueryDashboard__CountCampaignsArgs = {
  filter?: Maybe<FilterCampaignInput>
}

export type QueryDashboard__FindCampaignAdGroupArgs = {
  filter?: Maybe<FilterFindOneCampaignAdGroupInput>
  skip?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindOneCampaignAdGroupInput>
}

export type QueryDashboard__FindCampaignAdGroupsArgs = {
  filter?: Maybe<FilterFindManyCampaignAdGroupInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyCampaignAdGroupInput>
}

export type QueryDashboard__CountCampaignAdGroupsArgs = {
  filter?: Maybe<FilterCampaignAdGroupInput>
}

export type QueryDashboard__FindNetworkArgs = {
  filter?: Maybe<FilterFindOneNetworkInput>
  skip?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindOneNetworkInput>
}

export type QueryDashboard__FindNetworksArgs = {
  filter?: Maybe<FilterFindManyNetworkInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyNetworkInput>
}

export type QueryDashboard__CountNetworksArgs = {
  filter?: Maybe<FilterNetworkInput>
}

export type QueryDashboard__FindPlayerArgs = {
  filter?: Maybe<FilterFindOnePlayerInput>
  skip?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindOnePlayerInput>
}

export type QueryDashboard__FindPlayersArgs = {
  filter?: Maybe<FilterFindManyPlayerInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyPlayerInput>
}

export type QueryDashboard__CountPlayersArgs = {
  filter?: Maybe<FilterPlayerInput>
}

export type QueryDashboard__FindUserArgs = {
  filter?: Maybe<FilterFindOneUserInput>
  skip?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindOneUserInput>
}

export type QueryDashboard__FindUsersArgs = {
  filter?: Maybe<FilterFindManyUserInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyUserInput>
}

export type QueryDashboard__CountUsersArgs = {
  filter?: Maybe<FilterUserInput>
}

export type QueryDashboard__FindUploadArgs = {
  filter?: Maybe<FilterFindOneUploadInput>
  skip?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindOneUploadInput>
}

export type QueryDashboard__FindUploadsArgs = {
  filter?: Maybe<FilterFindManyUploadInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyUploadInput>
}

export type QueryDashboard__CountUploadsArgs = {
  filter?: Maybe<FilterUploadInput>
}

export type QueryDashboard__FindCreativeArgs = {
  filter?: Maybe<FilterFindOneCreativeInput>
  skip?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindOneCreativeInput>
}

export type QueryDashboard__FindCreativesArgs = {
  filter?: Maybe<FilterFindManyCreativeInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyCreativeInput>
}

export type QueryDashboard__CountCreativesArgs = {
  filter?: Maybe<FilterCreativeInput>
}

export type QueryDashboard__FindInviteArgs = {
  filter?: Maybe<FilterFindOneInviteInput>
  skip?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindOneInviteInput>
}

export type QueryDashboard__FindInvitesArgs = {
  filter?: Maybe<FilterFindManyInviteInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyInviteInput>
}

export type QueryDashboard__CountInvitesArgs = {
  filter?: Maybe<FilterInviteInput>
}

export type QueryDashboard__FindWebhookArgs = {
  filter?: Maybe<FilterFindOneWebhookInput>
  skip?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindOneWebhookInput>
}

export type QueryDashboard__FindWebhooksArgs = {
  filter?: Maybe<FilterFindManyWebhookInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyWebhookInput>
}

export type QueryDashboard__CountWebhooksArgs = {
  filter?: Maybe<FilterWebhookInput>
}

export type SecretOperatorsFilterFindManyPlayerInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type SecretOperatorsFilterFindOnePlayerInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type SecretOperatorsFilterPlayerInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type SecretOperatorsFilterUpdateOnePlayerInput = {
  gt?: Maybe<Scalars['String']>
  gte?: Maybe<Scalars['String']>
  lt?: Maybe<Scalars['String']>
  lte?: Maybe<Scalars['String']>
  ne?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type SetupIntentInput = {
  card?: Maybe<PaymentMethodCard>
  billing_details?: Maybe<PaymentMethodBillingDetails>
}

export type SetupIntentObject = {
  __typename?: 'SetupIntentObject'
  id: Scalars['String']
  client_secret: Scalars['String']
  payment_method: Scalars['String']
  status: Scalars['String']
}

export enum SortFindByIdsCreativeInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindByIdsWorkspaceInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindManyAreaTypeInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
}

export enum SortFindManyBroadcastPlanInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
}

export enum SortFindManyBroadcastReportInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
}

export enum SortFindManyCampaignAdGroupInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindManyCampaignInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindManyCategoryInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindManyCountryInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindManyCreativeInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindManyCurrencyInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindManyDistanceUnitInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindManyInviteInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
}

export enum SortFindManyLanguageInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindManyNetworkInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindManyPlayerInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  GeographicsLocationAsc = 'GEOGRAPHICS__LOCATION_ASC',
  GeographicsLocationDesc = 'GEOGRAPHICS__LOCATION_DESC',
  SecretAsc = 'SECRET_ASC',
  SecretDesc = 'SECRET_DESC',
}

export enum SortFindManyUploadInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindManyUserInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameFirstAsc = 'NAME__FIRST_ASC',
  NameFirstDesc = 'NAME__FIRST_DESC',
  NameLastAsc = 'NAME__LAST_ASC',
  NameLastDesc = 'NAME__LAST_DESC',
}

export enum SortFindManyVenueTypeInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindManyWebhookEventInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
}

export enum SortFindManyWebhookEventResponseInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
}

export enum SortFindManyWebhookInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
}

export enum SortFindOneBroadcastPlanInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
}

export enum SortFindOneBroadcastReportInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
}

export enum SortFindOneCampaignAdGroupInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindOneCampaignInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindOneCategoryInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindOneCountryInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindOneCreativeInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindOneCurrencyInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindOneDistanceUnitInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindOneInviteInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
}

export enum SortFindOneLanguageInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindOneNetworkInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindOnePlayerInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  GeographicsLocationAsc = 'GEOGRAPHICS__LOCATION_ASC',
  GeographicsLocationDesc = 'GEOGRAPHICS__LOCATION_DESC',
  SecretAsc = 'SECRET_ASC',
  SecretDesc = 'SECRET_DESC',
}

export enum SortFindOneUploadInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortFindOneUserInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameFirstAsc = 'NAME__FIRST_ASC',
  NameFirstDesc = 'NAME__FIRST_DESC',
  NameLastAsc = 'NAME__LAST_ASC',
  NameLastDesc = 'NAME__LAST_DESC',
}

export enum SortFindOneWebhookInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
}

export enum SortUpdateManyCampaignAdGroupInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortUpdateManyCampaignInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortUpdateManyCategoryInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortUpdateManyCountryInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortUpdateManyCreativeInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortUpdateManyCurrencyInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortUpdateManyDistanceUnitInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortUpdateManyLanguageInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortUpdateManyNetworkInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortUpdateManyUserInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameFirstAsc = 'NAME__FIRST_ASC',
  NameFirstDesc = 'NAME__FIRST_DESC',
  NameLastAsc = 'NAME__LAST_ASC',
  NameLastDesc = 'NAME__LAST_DESC',
}

export enum SortUpdateManyVenueTypeInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortUpdateManyWebhookInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
}

export enum SortUpdateOneCampaignAdGroupInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortUpdateOneCampaignInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortUpdateOneCategoryInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortUpdateOneCountryInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortUpdateOneCreativeInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortUpdateOneCurrencyInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortUpdateOneDistanceUnitInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortUpdateOneLanguageInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortUpdateOneNetworkInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortUpdateOnePlayerInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  GeographicsLocationAsc = 'GEOGRAPHICS__LOCATION_ASC',
  GeographicsLocationDesc = 'GEOGRAPHICS__LOCATION_DESC',
  SecretAsc = 'SECRET_ASC',
  SecretDesc = 'SECRET_DESC',
}

export enum SortUpdateOneUserInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameFirstAsc = 'NAME__FIRST_ASC',
  NameFirstDesc = 'NAME__FIRST_DESC',
  NameLastAsc = 'NAME__LAST_ASC',
  NameLastDesc = 'NAME__LAST_DESC',
}

export enum SortUpdateOneVenueTypeInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum SortUpdateOneWebhookInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC',
}

export type StatsInterval = {
  __typename?: 'StatsInterval'
  engagement: Scalars['Int']
  reach: Scalars['Int']
}

export type StatsIntervalInput = {
  starts_at: Scalars['Date']
  ends_at: Scalars['Date']
}

export type StripeCustomerIncomingInvoice = {
  __typename?: 'StripeCustomerIncomingInvoice'
  amount_due?: Maybe<Scalars['Int']>
  status?: Maybe<Scalars['String']>
  subscription?: Maybe<Scalars['String']>
  next_payment_attempt?: Maybe<Scalars['Int']>
}

export type StripeCustomerInvoices = {
  __typename?: 'StripeCustomerInvoices'
  data?: Maybe<Array<Maybe<InvoiceData>>>
}

export type StripeCustomerPaymentMethods = {
  __typename?: 'StripeCustomerPaymentMethods'
  data?: Maybe<Array<Maybe<PaymentMethodData>>>
}

export type Subscription = {
  __typename?: 'Subscription'
  Player__Whoami?: Maybe<Player>
}

export type Timeframe = {
  __typename?: 'Timeframe'
  starts_at?: Maybe<Scalars['Date']>
  ends_at?: Maybe<Scalars['Date']>
}

export type TimeframeInput = {
  starts_at?: Maybe<Scalars['Date']>
  ends_at?: Maybe<Scalars['Date']>
}

export type UpdateManyCampaignAdGroupInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  campaign_id?: Maybe<Scalars['MongoID']>
  audiences_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  creatives_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  redirect_to?: Maybe<Scalars['String']>
  timeframe?: Maybe<TimeframeInput>
  created?: Maybe<ActionDateAndUserInput>
  audience?: Maybe<CampaignAdGroupAudienceInput>
  saturation?: Maybe<Scalars['Float']>
}

export type UpdateManyCampaignAdGroupPayload = {
  __typename?: 'UpdateManyCampaignAdGroupPayload'
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']>
}

export type UpdateManyCampaignInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  broadcasts_to?: Maybe<CampaignBroadcasts_ToInput>
  created?: Maybe<ActionDateAndUserInput>
}

export type UpdateManyCampaignPayload = {
  __typename?: 'UpdateManyCampaignPayload'
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']>
}

export type UpdateManyCategoryInput = {
  name?: Maybe<Scalars['String']>
}

export type UpdateManyCategoryPayload = {
  __typename?: 'UpdateManyCategoryPayload'
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']>
}

export type UpdateManyCountryInput = {
  name?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
}

export type UpdateManyCountryPayload = {
  __typename?: 'UpdateManyCountryPayload'
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']>
}

export type UpdateManyCreativeInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  type?: Maybe<EnumCreativeType>
  upload_id?: Maybe<Scalars['MongoID']>
  categories_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  created?: Maybe<ActionDateAndUserInput>
}

export type UpdateManyCreativePayload = {
  __typename?: 'UpdateManyCreativePayload'
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']>
}

export type UpdateManyCurrencyInput = {
  name?: Maybe<Scalars['String']>
  symbol?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  payment_gateways?: Maybe<Array<Maybe<EnumCurrencyPayment_Gateways>>>
}

export type UpdateManyCurrencyPayload = {
  __typename?: 'UpdateManyCurrencyPayload'
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']>
}

export type UpdateManyDistanceUnitInput = {
  name?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  meters_convert_index?: Maybe<Scalars['Float']>
}

export type UpdateManyDistanceUnitPayload = {
  __typename?: 'UpdateManyDistanceUnitPayload'
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']>
}

export type UpdateManyLanguageInput = {
  name?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
}

export type UpdateManyLanguagePayload = {
  __typename?: 'UpdateManyLanguagePayload'
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']>
}

export type UpdateManyNetworkInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  players?: Maybe<Array<Maybe<NetworkPlayersInput>>>
  created?: Maybe<ActionDateAndUserInput>
}

export type UpdateManyNetworkPayload = {
  __typename?: 'UpdateManyNetworkPayload'
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']>
}

export type UpdateManyUserInput = {
  is_active?: Maybe<Scalars['Boolean']>
  name?: Maybe<UserNameInput>
  dob?: Maybe<Scalars['Date']>
  gender?: Maybe<EnumUserGender>
  address?: Maybe<AddressInput>
  emails?: Maybe<Array<Maybe<UserEmailInput>>>
  primary_email_index?: Maybe<Scalars['Float']>
  phones?: Maybe<Array<Maybe<UserPhoneInput>>>
  primary_phone_index?: Maybe<Scalars['Float']>
  platform_roles?: Maybe<UserPlatform_RolesInput>
  last_seen_at?: Maybe<Scalars['Date']>
  created?: Maybe<ActionDateAndUserInput>
}

export type UpdateManyUserPayload = {
  __typename?: 'UpdateManyUserPayload'
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']>
}

export type UpdateManyVenueTypeInput = {
  name?: Maybe<Scalars['String']>
  categories_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type UpdateManyVenueTypePayload = {
  __typename?: 'UpdateManyVenueTypePayload'
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']>
}

export type UpdateManyWebhookInput = {
  url?: Maybe<Scalars['String']>
  retries?: Maybe<Scalars['Float']>
  type?: Maybe<EnumWebhookType>
  event_types?: Maybe<WebhookEvent_TypesInput>
  created?: Maybe<ActionDateAndUserInput>
}

export type UpdateManyWebhookPayload = {
  __typename?: 'UpdateManyWebhookPayload'
  /** Affected documents number */
  numAffected?: Maybe<Scalars['Int']>
}

export type UpdateOneCampaignAdGroupInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  campaign_id?: Maybe<Scalars['MongoID']>
  audiences_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  creatives_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  redirect_to?: Maybe<Scalars['String']>
  timeframe?: Maybe<TimeframeInput>
  created?: Maybe<ActionDateAndUserInput>
  audience?: Maybe<CampaignAdGroupAudienceInput>
  saturation?: Maybe<Scalars['Float']>
}

export type UpdateOneCampaignAdGroupPayload = {
  __typename?: 'UpdateOneCampaignAdGroupPayload'
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>
  /** Updated document */
  record?: Maybe<CampaignAdGroup>
}

export type UpdateOneCampaignInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  broadcasts_to?: Maybe<CampaignBroadcasts_ToInput>
  created?: Maybe<ActionDateAndUserInput>
}

export type UpdateOneCampaignPayload = {
  __typename?: 'UpdateOneCampaignPayload'
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>
  /** Updated document */
  record?: Maybe<Campaign>
}

export type UpdateOneCategoryInput = {
  name?: Maybe<Scalars['String']>
}

export type UpdateOneCategoryPayload = {
  __typename?: 'UpdateOneCategoryPayload'
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>
  /** Updated document */
  record?: Maybe<Category>
}

export type UpdateOneCountryInput = {
  name?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
}

export type UpdateOneCountryPayload = {
  __typename?: 'UpdateOneCountryPayload'
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>
  /** Updated document */
  record?: Maybe<Country>
}

export type UpdateOneCreativeInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  type?: Maybe<EnumCreativeType>
  upload_id?: Maybe<Scalars['MongoID']>
  categories_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  created?: Maybe<ActionDateAndUserInput>
}

export type UpdateOneCreativePayload = {
  __typename?: 'UpdateOneCreativePayload'
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>
  /** Updated document */
  record?: Maybe<Creative>
}

export type UpdateOneCurrencyInput = {
  name?: Maybe<Scalars['String']>
  symbol?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  payment_gateways?: Maybe<Array<Maybe<EnumCurrencyPayment_Gateways>>>
}

export type UpdateOneCurrencyPayload = {
  __typename?: 'UpdateOneCurrencyPayload'
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>
  /** Updated document */
  record?: Maybe<Currency>
}

export type UpdateOneDistanceUnitInput = {
  name?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  meters_convert_index?: Maybe<Scalars['Float']>
}

export type UpdateOneDistanceUnitPayload = {
  __typename?: 'UpdateOneDistanceUnitPayload'
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>
  /** Updated document */
  record?: Maybe<DistanceUnit>
}

export type UpdateOneLanguageInput = {
  name?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
}

export type UpdateOneLanguagePayload = {
  __typename?: 'UpdateOneLanguagePayload'
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>
  /** Updated document */
  record?: Maybe<Language>
}

export type UpdateOneNetworkInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  players?: Maybe<Array<Maybe<NetworkPlayersInput>>>
  created?: Maybe<ActionDateAndUserInput>
}

export type UpdateOneNetworkPayload = {
  __typename?: 'UpdateOneNetworkPayload'
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>
  /** Updated document */
  record?: Maybe<Network>
}

export type UpdateOnePlayerInput = {
  name?: Maybe<Scalars['String']>
  is_active?: Maybe<Scalars['Boolean']>
  unique_id?: Maybe<Scalars['String']>
  capabilities?: Maybe<PlayerCapabilitiesInput>
  demographics?: Maybe<PlayerDemographicsInput>
  geographics?: Maybe<PlayerGeographicsInput>
  settings?: Maybe<PlayerSettingsInput>
  telemetry?: Maybe<PlayerTelemetryInput>
  binded?: Maybe<ActionDateAndUserInput>
  activated?: Maybe<ActionDateAndUserInput>
  secret?: Maybe<Scalars['String']>
  last_seen_at?: Maybe<Scalars['Date']>
  created?: Maybe<ActionDateAndUserInput>
}

export type UpdateOnePlayerPayload = {
  __typename?: 'UpdateOnePlayerPayload'
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>
  /** Updated document */
  record?: Maybe<Player>
}

export type UpdateOneUserInput = {
  is_active?: Maybe<Scalars['Boolean']>
  name?: Maybe<UserNameInput>
  dob?: Maybe<Scalars['Date']>
  gender?: Maybe<EnumUserGender>
  address?: Maybe<AddressInput>
  emails?: Maybe<Array<Maybe<UserEmailInput>>>
  primary_email_index?: Maybe<Scalars['Float']>
  phones?: Maybe<Array<Maybe<UserPhoneInput>>>
  primary_phone_index?: Maybe<Scalars['Float']>
  platform_roles?: Maybe<UserPlatform_RolesInput>
  last_seen_at?: Maybe<Scalars['Date']>
  created?: Maybe<ActionDateAndUserInput>
}

export type UpdateOneUserPayload = {
  __typename?: 'UpdateOneUserPayload'
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>
  /** Updated document */
  record?: Maybe<User>
}

export type UpdateOneVenueTypeInput = {
  name?: Maybe<Scalars['String']>
  categories_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
}

export type UpdateOneVenueTypePayload = {
  __typename?: 'UpdateOneVenueTypePayload'
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>
  /** Updated document */
  record?: Maybe<VenueType>
}

export type UpdateOneWebhookInput = {
  url?: Maybe<Scalars['String']>
  retries?: Maybe<Scalars['Float']>
  type?: Maybe<EnumWebhookType>
  event_types?: Maybe<WebhookEvent_TypesInput>
  created?: Maybe<ActionDateAndUserInput>
}

export type UpdateOneWebhookPayload = {
  __typename?: 'UpdateOneWebhookPayload'
  /** Updated document ID */
  recordId?: Maybe<Scalars['MongoID']>
  /** Updated document */
  record?: Maybe<Webhook>
}

export type Upload = {
  __typename?: 'Upload'
  type: EnumUploadType
  mimetype: Scalars['String']
  status: EnumUploadStatus
  name: Scalars['String']
  versions?: Maybe<Array<Maybe<UploadVersions>>>
  created?: Maybe<ActionDateAndUser>
  uploaded?: Maybe<ActionDateAndUser>
  _id: Scalars['MongoID']
}

export type UploadVersions = {
  __typename?: 'UploadVersions'
  name: Scalars['String']
  mimetype: Scalars['String']
  format?: Maybe<Scalars['String']>
  metadata?: Maybe<UploadVersionsMetadata>
  bytes?: Maybe<Scalars['Float']>
  duration?: Maybe<Scalars['Float']>
  storage_path: Scalars['String']
  public_url?: Maybe<Scalars['String']>
  ipfs_hash?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['Date']>
  processed_at?: Maybe<Scalars['Date']>
}

export type UploadVersionsInput = {
  name: Scalars['String']
  mimetype: Scalars['String']
  format?: Maybe<Scalars['String']>
  metadata?: Maybe<UploadVersionsMetadataInput>
  bytes?: Maybe<Scalars['Float']>
  duration?: Maybe<Scalars['Float']>
  storage_path: Scalars['String']
  public_url?: Maybe<Scalars['String']>
  ipfs_hash?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['Date']>
  processed_at?: Maybe<Scalars['Date']>
}

export type UploadVersionsMetadata = {
  __typename?: 'UploadVersionsMetadata'
  video?: Maybe<UploadVersionsMetadataVideo>
  audio?: Maybe<UploadVersionsMetadataAudio>
}

export type UploadVersionsMetadataAudio = {
  __typename?: 'UploadVersionsMetadataAudio'
  codec?: Maybe<Scalars['String']>
  bitrate?: Maybe<Scalars['Float']>
  frequency?: Maybe<Scalars['Float']>
}

export type UploadVersionsMetadataAudioInput = {
  codec?: Maybe<Scalars['String']>
  bitrate?: Maybe<Scalars['Float']>
  frequency?: Maybe<Scalars['Float']>
}

export type UploadVersionsMetadataInput = {
  video?: Maybe<UploadVersionsMetadataVideoInput>
  audio?: Maybe<UploadVersionsMetadataAudioInput>
}

export type UploadVersionsMetadataVideo = {
  __typename?: 'UploadVersionsMetadataVideo'
  codec?: Maybe<Scalars['String']>
  bitrate?: Maybe<Scalars['Float']>
  resolution?: Maybe<Scalars['String']>
  fps?: Maybe<Scalars['Float']>
}

export type UploadVersionsMetadataVideoInput = {
  codec?: Maybe<Scalars['String']>
  bitrate?: Maybe<Scalars['Float']>
  resolution?: Maybe<Scalars['String']>
  fps?: Maybe<Scalars['Float']>
}

export type User = {
  __typename?: 'User'
  is_active?: Maybe<Scalars['Boolean']>
  name?: Maybe<UserName>
  dob?: Maybe<Scalars['Date']>
  gender?: Maybe<EnumUserGender>
  address?: Maybe<Address>
  emails?: Maybe<Array<Maybe<UserEmail>>>
  primary_email_index?: Maybe<Scalars['Float']>
  phones?: Maybe<Array<Maybe<UserPhone>>>
  primary_phone_index?: Maybe<Scalars['Float']>
  platform_roles?: Maybe<UserPlatform_Roles>
  last_seen_at?: Maybe<Scalars['Date']>
  created?: Maybe<ActionDateAndUser>
  _id: Scalars['MongoID']
}

export type UserEmail = {
  __typename?: 'UserEmail'
  address?: Maybe<Scalars['String']>
  verified_at?: Maybe<Scalars['Date']>
}

export type UserEmailInput = {
  address?: Maybe<Scalars['String']>
  verified_at?: Maybe<Scalars['Date']>
}

export type UserName = {
  __typename?: 'UserName'
  first: Scalars['String']
  last: Scalars['String']
}

export type UserNameInput = {
  first: Scalars['String']
  last: Scalars['String']
}

export type UserPhone = {
  __typename?: 'UserPhone'
  number?: Maybe<Scalars['String']>
  verified_at?: Maybe<Scalars['Date']>
}

export type UserPhoneInput = {
  number?: Maybe<Scalars['String']>
  verified_at?: Maybe<Scalars['Date']>
}

export type UserPlatform_Roles = {
  __typename?: 'UserPlatform_roles'
  admin?: Maybe<Scalars['Boolean']>
  curator?: Maybe<Scalars['Boolean']>
}

export type UserPlatform_RolesInput = {
  admin?: Maybe<Scalars['Boolean']>
  curator?: Maybe<Scalars['Boolean']>
}

export type UserSignUpResult = {
  __typename?: 'UserSignUpResult'
  user_id: Scalars['String']
  client_id: Scalars['String']
  access_token: Scalars['String']
  refresh_token: Scalars['String']
}

export type VenueType = {
  __typename?: 'VenueType'
  name: Scalars['String']
  categories_ids?: Maybe<Array<Maybe<Scalars['MongoID']>>>
  _id: Scalars['MongoID']
}

export type Webhook = {
  __typename?: 'Webhook'
  url?: Maybe<Scalars['String']>
  ownership?: Maybe<Ownership>
  retries?: Maybe<Scalars['Float']>
  type?: Maybe<EnumWebhookType>
  event_types?: Maybe<WebhookEvent_Types>
  created?: Maybe<ActionDateAndUser>
  archived?: Maybe<ActionDateAndUser>
  _id: Scalars['MongoID']
  events?: Maybe<Array<WebhookEvent>>
}

export type WebhookEventsArgs = {
  filter?: Maybe<FilterFindManyWebhookEventInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyWebhookEventInput>
}

export type WebhookEvent = {
  __typename?: 'WebhookEvent'
  webhook_id: Scalars['MongoID']
  type?: Maybe<EnumWebhookEventType>
  at?: Maybe<Scalars['Date']>
  _id: Scalars['MongoID']
  responses?: Maybe<Array<WebhookEventResponse>>
}

export type WebhookEventResponsesArgs = {
  filter?: Maybe<FilterFindManyWebhookEventResponseInput>
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyWebhookEventResponseInput>
}

export type WebhookEvent_Types = {
  __typename?: 'WebhookEvent_types'
  player_binded?: Maybe<Scalars['Boolean']>
  player_activated?: Maybe<Scalars['Boolean']>
  player_archived?: Maybe<Scalars['Boolean']>
  creative_uploaded?: Maybe<Scalars['Boolean']>
  creative_archived?: Maybe<Scalars['Boolean']>
  network_created?: Maybe<Scalars['Boolean']>
  network_changed?: Maybe<Scalars['Boolean']>
  network_archived?: Maybe<Scalars['Boolean']>
  campaign_created?: Maybe<Scalars['Boolean']>
  campaign_changed?: Maybe<Scalars['Boolean']>
  campaign_archived?: Maybe<Scalars['Boolean']>
  campaign_ad_group_created?: Maybe<Scalars['Boolean']>
  campaign_ad_group_changed?: Maybe<Scalars['Boolean']>
  campaign_ad_group_archived?: Maybe<Scalars['Boolean']>
  webhook_archived?: Maybe<Scalars['Boolean']>
  engagement?: Maybe<Scalars['Boolean']>
}

export type WebhookEvent_TypesInput = {
  player_binded?: Maybe<Scalars['Boolean']>
  player_activated?: Maybe<Scalars['Boolean']>
  player_archived?: Maybe<Scalars['Boolean']>
  creative_uploaded?: Maybe<Scalars['Boolean']>
  creative_archived?: Maybe<Scalars['Boolean']>
  network_created?: Maybe<Scalars['Boolean']>
  network_changed?: Maybe<Scalars['Boolean']>
  network_archived?: Maybe<Scalars['Boolean']>
  campaign_created?: Maybe<Scalars['Boolean']>
  campaign_changed?: Maybe<Scalars['Boolean']>
  campaign_archived?: Maybe<Scalars['Boolean']>
  campaign_ad_group_created?: Maybe<Scalars['Boolean']>
  campaign_ad_group_changed?: Maybe<Scalars['Boolean']>
  campaign_ad_group_archived?: Maybe<Scalars['Boolean']>
  webhook_archived?: Maybe<Scalars['Boolean']>
  engagement?: Maybe<Scalars['Boolean']>
}

export type WebhookEventResponse = {
  __typename?: 'WebhookEventResponse'
  webhook_event_id: Scalars['String']
  url: Scalars['String']
  statusCode?: Maybe<Scalars['Float']>
  body?: Maybe<Scalars['String']>
  headers?: Maybe<Scalars['JSON']>
  _id: Scalars['MongoID']
}

export type Workspace = {
  __typename?: 'Workspace'
  name: Scalars['String']
  platform_type?: Maybe<EnumWorkspacePlatform_Type>
  is_active?: Maybe<Scalars['Boolean']>
  members?: Maybe<Array<Maybe<WorkspaceMembers>>>
  invoice_id?: Maybe<Scalars['String']>
  _id: Scalars['MongoID']
  getStats: Array<StatsInterval>
  payment_methods?: Maybe<StripeCustomerPaymentMethods>
  invoices?: Maybe<StripeCustomerInvoices>
  upcoming_invoice?: Maybe<StripeCustomerIncomingInvoice>
  default_payment_method?: Maybe<Scalars['String']>
  status?: Maybe<EnumWorkspaceStatus>
}

export type WorkspaceGetStatsArgs = {
  intervals: Array<StatsIntervalInput>
}

export type WorkspaceMembers = {
  __typename?: 'WorkspaceMembers'
  role?: Maybe<EnumWorkspaceMembersRole>
  user_id?: Maybe<Scalars['MongoID']>
  added?: Maybe<ActionDateAndUser>
  removed?: Maybe<ActionDateAndUser>
  user?: Maybe<User>
  added_by_user?: Maybe<User>
  removed_by_user?: Maybe<User>
}

export type WorkspaceMembersInput = {
  role?: Maybe<EnumWorkspaceMembersRole>
  user_id?: Maybe<Scalars['MongoID']>
  added?: Maybe<ActionDateAndUserInput>
  removed?: Maybe<ActionDateAndUserInput>
}

export type AreaTypeFullFragmentFragment = { __typename?: 'AreaType' } & Pick<
  AreaType,
  '_id' | 'name' | 'description'
>

export type VenueTypeFullFragmentFragment = { __typename?: 'VenueType' } & Pick<
  VenueType,
  '_id' | 'name'
>

export type CampaignFullFragmentFragment = { __typename?: 'Campaign' } & Pick<
  Campaign,
  '_id' | 'name' | 'status'
> & {
    timeframe?: Maybe<
      { __typename?: 'Timeframe' } & Pick<Timeframe, 'starts_at' | 'ends_at'>
    >
    broadcasts_to?: Maybe<
      { __typename?: 'CampaignBroadcasts_to' } & Pick<
        CampaignBroadcasts_To,
        'marketplace'
      > & {
          networks?: Maybe<
            Array<
              Maybe<
                { __typename?: 'CampaignBroadcasts_toNetworks' } & {
                  network?: Maybe<
                    { __typename?: 'Network' } & NetworkListFragmentFragment
                  >
                }
              >
            >
          >
          players?: Maybe<
            Array<
              Maybe<
                { __typename?: 'CampaignBroadcasts_toPlayers' } & {
                  player?: Maybe<
                    { __typename?: 'Player' } & PlayerListFragmentFragment
                  >
                }
              >
            >
          >
        }
    >
    ad_groups?: Maybe<
      Array<
        { __typename?: 'CampaignAdGroup' } & CampaignAdGroupFullFragmentFragment
      >
    >
    created?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'at' | 'by_user_id'
      >
    >
    archived?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'at' | 'by_user_id'
      >
    >
    stats: Array<
      { __typename?: 'StatsInterval' } & Pick<
        StatsInterval,
        'reach' | 'engagement'
      >
    >
  }

export type CampaignListFragmentFragment = { __typename?: 'Campaign' } & Pick<
  Campaign,
  '_id' | 'name' | 'status'
> & {
    timeframe?: Maybe<
      { __typename?: 'Timeframe' } & Pick<Timeframe, 'starts_at' | 'ends_at'>
    >
    broadcasts_to?: Maybe<
      { __typename?: 'CampaignBroadcasts_to' } & Pick<
        CampaignBroadcasts_To,
        'marketplace'
      > & {
          networks?: Maybe<
            Array<
              Maybe<
                { __typename?: 'CampaignBroadcasts_toNetworks' } & Pick<
                  CampaignBroadcasts_ToNetworks,
                  'network_id'
                >
              >
            >
          >
          players?: Maybe<
            Array<
              Maybe<
                { __typename?: 'CampaignBroadcasts_toPlayers' } & Pick<
                  CampaignBroadcasts_ToPlayers,
                  'player_id'
                >
              >
            >
          >
        }
    >
    ad_groups?: Maybe<
      Array<
        { __typename?: 'CampaignAdGroup' } & {
          campaign_adgroup_id: CampaignAdGroup['_id']
        }
      >
    >
    created?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<ActionDateAndUser, 'at'>
    >
    stats: Array<
      { __typename?: 'StatsInterval' } & Pick<
        StatsInterval,
        'reach' | 'engagement'
      >
    >
  }

export type CampaignAdGroupFullFragmentFragment = {
  __typename?: 'CampaignAdGroup'
} & Pick<
  CampaignAdGroup,
  | '_id'
  | 'is_active'
  | 'name'
  | 'status'
  | 'campaign_id'
  | 'audiences_ids'
  | 'creatives_ids'
  | 'redirect_to'
  | 'saturation'
> & {
    campaign?: Maybe<{ __typename?: 'Campaign' } & Pick<Campaign, 'name'>>
    timeframe?: Maybe<
      { __typename?: 'Timeframe' } & Pick<Timeframe, 'starts_at' | 'ends_at'>
    >
    audience?: Maybe<
      { __typename?: 'CampaignAdGroupAudience' } & Pick<
        CampaignAdGroupAudience,
        'area_type' | 'venue_type_ids'
      > & {
          geofences?: Maybe<
            Array<
              Maybe<
                { __typename?: 'Geofence' } & Pick<
                  Geofence,
                  'range' | 'distance_unit_id'
                > & {
                    location?: Maybe<
                      { __typename?: 'Point' } & Pick<
                        Point,
                        'type' | 'coordinates'
                      >
                    >
                  }
              >
            >
          >
        }
    >
    created?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'at' | 'by_user_id'
      >
    >
    archived?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'at' | 'by_user_id'
      >
    >
    stats: Array<
      { __typename?: 'StatsInterval' } & Pick<
        StatsInterval,
        'reach' | 'engagement'
      >
    >
  }

export type CampaignAdGroupListFragmentFragment = {
  __typename?: 'CampaignAdGroup'
} & Pick<
  CampaignAdGroup,
  | '_id'
  | 'name'
  | 'status'
  | 'is_active'
  | 'campaign_id'
  | 'saturation'
  | 'creatives_ids'
> & {
    timeframe?: Maybe<
      { __typename?: 'Timeframe' } & Pick<Timeframe, 'starts_at' | 'ends_at'>
    >
    created?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<ActionDateAndUser, 'at'>
    >
    audience?: Maybe<
      { __typename?: 'CampaignAdGroupAudience' } & Pick<
        CampaignAdGroupAudience,
        'area_type'
      >
    >
    stats: Array<
      { __typename?: 'StatsInterval' } & Pick<
        StatsInterval,
        'reach' | 'engagement'
      >
    >
  }

export type CreativeFullFragmentFragment = { __typename?: 'Creative' } & Pick<
  Creative,
  '_id' | 'name' | 'type' | 'status' | 'upload_id' | 'categories_ids'
> & {
    ownership?: Maybe<
      { __typename?: 'Ownership' } & Pick<Ownership, 'workspace_id'>
    >
    created?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'at' | 'by_user_id'
      >
    >
    upload?: Maybe<{ __typename?: 'Upload' } & UploadFullFragmentFragment>
    ad_groups?: Maybe<
      Array<
        { __typename?: 'CampaignAdGroup' } & CampaignAdGroupFullFragmentFragment
      >
    >
    stats: Array<
      { __typename?: 'StatsInterval' } & Pick<
        StatsInterval,
        'reach' | 'engagement'
      >
    >
  }

export type CreativeListFragmentFragment = { __typename?: 'Creative' } & Pick<
  Creative,
  '_id' | 'name' | 'type' | 'status'
> & {
    upload?: Maybe<{ __typename?: 'Upload' } & UploadMinimumFragmentFragment>
    created?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<ActionDateAndUser, 'at'>
    >
    stats: Array<
      { __typename?: 'StatsInterval' } & Pick<
        StatsInterval,
        'reach' | 'engagement'
      >
    >
  }

export type NetworkFullFragmentFragment = { __typename?: 'Network' } & Pick<
  Network,
  '_id' | 'name' | 'status'
> & {
    players: Array<
      Maybe<
        { __typename?: 'NetworkPlayers' } & {
          added?: Maybe<
            { __typename?: 'ActionDateAndUser' } & Pick<
              ActionDateAndUser,
              'at' | 'by_user_id'
            >
          >
          player?: Maybe<{ __typename?: 'Player' } & PlayerListFragmentFragment>
        }
      >
    >
    created?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'by_user_id' | 'at'
      >
    >
    archived?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'at' | 'by_user_id'
      >
    >
    stats: Array<
      { __typename?: 'StatsInterval' } & Pick<
        StatsInterval,
        'reach' | 'engagement'
      >
    >
  }

export type NetworkListFragmentFragment = { __typename?: 'Network' } & Pick<
  Network,
  '_id' | 'name' | 'status'
> & {
    players: Array<
      Maybe<
        { __typename?: 'NetworkPlayers' } & Pick<NetworkPlayers, 'player_id'>
      >
    >
    created?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<ActionDateAndUser, 'at'>
    >
    stats: Array<
      { __typename?: 'StatsInterval' } & Pick<
        StatsInterval,
        'reach' | 'engagement'
      >
    >
  }

export type PlayerFullFragmentFragment = { __typename?: 'Player' } & Pick<
  Player,
  '_id' | 'unique_id' | 'name' | 'status' | 'last_seen_at'
> & {
    capabilities?: Maybe<
      { __typename?: 'PlayerCapabilities' } & {
        playback?: Maybe<
          { __typename?: 'PlayerCapabilitiesPlayback' } & Pick<
            PlayerCapabilitiesPlayback,
            'videos' | 'audios' | 'images'
          >
        >
      }
    >
    demographics?: Maybe<
      { __typename?: 'PlayerDemographics' } & Pick<
        PlayerDemographics,
        'language_ids' | 'country_id' | 'area_type_id' | 'venue_type_ids'
      >
    >
    geographics?: Maybe<
      { __typename?: 'PlayerGeographics' } & {
        location?: Maybe<
          { __typename?: 'Point' } & Pick<Point, 'type' | 'coordinates'>
        >
        address?: Maybe<
          { __typename?: 'Address' } & Pick<
            Address,
            'street' | 'number' | 'zip' | 'city' | 'state' | 'country_id'
          >
        >
      }
    >
    settings?: Maybe<
      { __typename?: 'PlayerSettings' } & Pick<
        PlayerSettings,
        'debug' | 'volume' | 'brightness' | 'screen_orientation'
      >
    >
    telemetry?: Maybe<
      { __typename?: 'PlayerTelemetry' } & Pick<
        PlayerTelemetry,
        'scans' | 'impressions'
      >
    >
    created?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'at' | 'by_user_id'
      >
    >
    stats: Array<
      { __typename?: 'StatsInterval' } & Pick<
        StatsInterval,
        'reach' | 'engagement'
      >
    >
  }

export type PlayerListFragmentFragment = { __typename?: 'Player' } & Pick<
  Player,
  '_id' | 'name' | 'status'
> & {
    demographics?: Maybe<
      { __typename?: 'PlayerDemographics' } & Pick<
        PlayerDemographics,
        'language_ids' | 'country_id' | 'area_type_id' | 'venue_type_ids'
      >
    >
    geographics?: Maybe<
      { __typename?: 'PlayerGeographics' } & {
        location?: Maybe<
          { __typename?: 'Point' } & Pick<Point, 'type' | 'coordinates'>
        >
        address?: Maybe<
          { __typename?: 'Address' } & Pick<
            Address,
            'street' | 'number' | 'zip' | 'city' | 'state' | 'country_id'
          >
        >
      }
    >
    settings?: Maybe<
      { __typename?: 'PlayerSettings' } & Pick<
        PlayerSettings,
        'screen_orientation'
      >
    >
    created?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<ActionDateAndUser, 'at'>
    >
    stats: Array<
      { __typename?: 'StatsInterval' } & Pick<
        StatsInterval,
        'reach' | 'engagement'
      >
    >
  }

export type UploadMinimumFragmentFragment = { __typename?: 'Upload' } & Pick<
  Upload,
  'type'
> & {
    versions?: Maybe<
      Array<
        Maybe<
          { __typename?: 'UploadVersions' } & Pick<
            UploadVersions,
            'name' | 'public_url'
          >
        >
      >
    >
    created?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<ActionDateAndUser, 'at'>
    >
  }

export type UploadFullFragmentFragment = { __typename?: 'Upload' } & Pick<
  Upload,
  '_id' | 'type' | 'name'
> & {
    created?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'at' | 'by_user_id'
      >
    >
    versions?: Maybe<
      Array<
        Maybe<
          { __typename?: 'UploadVersions' } & UploadVersionsFullFragmentFragment
        >
      >
    >
  }

export type UploadVersionsFullFragmentFragment = {
  __typename?: 'UploadVersions'
} & Pick<
  UploadVersions,
  | 'name'
  | 'bytes'
  | 'format'
  | 'mimetype'
  | 'duration'
  | 'ipfs_hash'
  | 'public_url'
  | 'storage_path'
> & {
    metadata?: Maybe<
      {
        __typename?: 'UploadVersionsMetadata'
      } & UploadVersionsMetadataFullFragmentFragment
    >
  }

export type UploadVersionsMetadataFullFragmentFragment = {
  __typename?: 'UploadVersionsMetadata'
} & {
  audio?: Maybe<
    { __typename?: 'UploadVersionsMetadataAudio' } & Pick<
      UploadVersionsMetadataAudio,
      'codec' | 'bitrate' | 'frequency'
    >
  >
  video?: Maybe<
    { __typename?: 'UploadVersionsMetadataVideo' } & Pick<
      UploadVersionsMetadataVideo,
      'fps' | 'codec' | 'bitrate' | 'resolution'
    >
  >
}

export type CountryFullFragmentFragment = { __typename?: 'Country' } & Pick<
  Country,
  '_id' | 'name' | 'code'
>

export type WorkspaceFullFragmentFragment = { __typename?: 'Workspace' } & Pick<
  Workspace,
  '_id' | 'name' | 'is_active' | 'platform_type' | 'default_payment_method'
> & {
    members?: Maybe<
      Array<
        Maybe<
          { __typename?: 'WorkspaceMembers' } & Pick<
            WorkspaceMembers,
            'role' | 'user_id'
          > & {
              user?: Maybe<{ __typename?: 'User' } & UserListFragmentFragment>
              added?: Maybe<
                { __typename?: 'ActionDateAndUser' } & Pick<
                  ActionDateAndUser,
                  'at' | 'by_user_id'
                >
              >
              removed?: Maybe<
                { __typename?: 'ActionDateAndUser' } & Pick<
                  ActionDateAndUser,
                  'at' | 'by_user_id'
                >
              >
            }
        >
      >
    >
    stats: Array<
      { __typename?: 'StatsInterval' } & Pick<
        StatsInterval,
        'reach' | 'engagement'
      >
    >
    payment_methods?: Maybe<
      { __typename?: 'StripeCustomerPaymentMethods' } & {
        data?: Maybe<
          Array<
            Maybe<
              { __typename?: 'paymentMethodData' } & Pick<
                PaymentMethodData,
                'id'
              > & {
                  card?: Maybe<
                    { __typename?: 'Card' } & Pick<
                      Card,
                      'brand' | 'country' | 'exp_year' | 'exp_month' | 'last4'
                    >
                  >
                  billing_details?: Maybe<
                    { __typename?: 'BillingDetails' } & Pick<
                      BillingDetails,
                      'name'
                    > & {
                        address?: Maybe<
                          { __typename?: 'BillingDetailsAddress' } & Pick<
                            BillingDetailsAddress,
                            | 'city'
                            | 'country'
                            | 'line1'
                            | 'postal_code'
                            | 'state'
                          >
                        >
                      }
                  >
                  metadata?: Maybe<
                    { __typename?: 'PaymentMethodsMetada' } & Pick<
                      PaymentMethodsMetada,
                      'vat' | 'company'
                    >
                  >
                }
            >
          >
        >
      }
    >
    invoices?: Maybe<
      { __typename?: 'StripeCustomerInvoices' } & {
        data?: Maybe<
          Array<
            Maybe<
              { __typename?: 'InvoiceData' } & Pick<
                InvoiceData,
                | 'id'
                | 'created'
                | 'invoice_pdf'
                | 'hosted_invoice_url'
                | 'total'
                | 'status'
              >
            >
          >
        >
      }
    >
    upcoming_invoice?: Maybe<
      { __typename?: 'StripeCustomerIncomingInvoice' } & Pick<
        StripeCustomerIncomingInvoice,
        'amount_due' | 'status' | 'next_payment_attempt'
      >
    >
  }

export type UserListFragmentFragment = { __typename?: 'User' } & Pick<
  User,
  '_id'
> & {
    name?: Maybe<{ __typename?: 'UserName' } & Pick<UserName, 'first' | 'last'>>
    emails?: Maybe<
      Array<
        Maybe<
          { __typename?: 'UserEmail' } & Pick<
            UserEmail,
            'address' | 'verified_at'
          >
        >
      >
    >
  }

export type UserFullFragmentFragment = { __typename?: 'User' } & Pick<
  User,
  '_id' | 'is_active' | 'dob' | 'gender' | 'last_seen_at'
> & {
    name?: Maybe<{ __typename?: 'UserName' } & Pick<UserName, 'first' | 'last'>>
    address?: Maybe<
      { __typename?: 'Address' } & Pick<
        Address,
        'street' | 'number' | 'city' | 'state' | 'zip' | 'country_id'
      >
    >
    phones?: Maybe<
      Array<
        Maybe<
          { __typename?: 'UserPhone' } & Pick<
            UserPhone,
            'number' | 'verified_at'
          >
        >
      >
    >
    platform_roles?: Maybe<
      { __typename?: 'UserPlatform_roles' } & Pick<
        UserPlatform_Roles,
        'admin' | 'curator'
      >
    >
    created?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'at' | 'by_user_id'
      >
    >
    emails?: Maybe<
      Array<
        Maybe<
          { __typename?: 'UserEmail' } & Pick<
            UserEmail,
            'address' | 'verified_at'
          >
        >
      >
    >
  }

export type InviteFullFragmentFragment = { __typename?: 'Invite' } & Pick<
  Invite,
  '_id' | 'status'
> & {
    ownership?: Maybe<
      { __typename?: 'Ownership' } & Pick<Ownership, 'workspace_id'>
    >
    target?: Maybe<
      { __typename?: 'InviteTarget' } & Pick<
        InviteTarget,
        'email' | 'user_id'
      > & {
          name?: Maybe<
            { __typename?: 'InviteTargetName' } & Pick<
              InviteTargetName,
              'first' | 'last'
            >
          >
        }
    >
    created?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'at' | 'by_user_id'
      >
    >
    revoked?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'at' | 'by_user_id'
      >
    >
    rejected?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'at' | 'by_user_id'
      >
    >
    accepted?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'at' | 'by_user_id'
      >
    >
  }

export type WebhookFullFragmentFragment = { __typename?: 'Webhook' } & Pick<
  Webhook,
  '_id' | 'url' | 'type' | 'retries'
> & {
    ownership?: Maybe<
      { __typename?: 'Ownership' } & Pick<Ownership, 'workspace_id'>
    >
    archived?: Maybe<
      { __typename?: 'ActionDateAndUser' } & Pick<
        ActionDateAndUser,
        'at' | 'by_user_id'
      >
    >
    events?: Maybe<
      Array<
        { __typename?: 'WebhookEvent' } & Pick<
          WebhookEvent,
          '_id' | 'at' | 'type' | 'webhook_id'
        > & {
            responses?: Maybe<
              Array<
                { __typename?: 'WebhookEventResponse' } & Pick<
                  WebhookEventResponse,
                  '_id' | 'url' | 'body' | 'statusCode' | 'webhook_event_id'
                >
              >
            >
          }
      >
    >
    event_types?: Maybe<
      { __typename?: 'WebhookEvent_types' } & Pick<
        WebhookEvent_Types,
        | 'player_binded'
        | 'player_activated'
        | 'player_archived'
        | 'creative_uploaded'
        | 'creative_archived'
        | 'network_created'
        | 'network_changed'
        | 'network_archived'
        | 'campaign_created'
        | 'campaign_changed'
        | 'campaign_archived'
        | 'campaign_ad_group_created'
        | 'campaign_ad_group_changed'
        | 'campaign_ad_group_archived'
        | 'webhook_archived'
        | 'engagement'
      >
    >
  }

export type SendEmailVerificationCodeMutationVariables = Exact<{
  email: Scalars['String']
}>

export type SendEmailVerificationCodeMutation = { __typename?: 'Mutation' } & {
  isCodeSent: Mutation['Dashboard__AccountEmailSendVerificationCode']
}

export type VerifyEmailCodeMutationVariables = Exact<{
  code: Scalars['String']
  email: Scalars['String']
}>

export type VerifyEmailCodeMutation = { __typename?: 'Mutation' } & {
  isCodeValid: Mutation['Dashboard__AccountEmailVerifyCode']
}

export type UpdateAccountDetailsMutationVariables = Exact<{
  details: Dashboard__AccountUpdateDetailsInput
}>

export type UpdateAccountDetailsMutation = { __typename?: 'Mutation' } & {
  workspace?: Maybe<
    { __typename?: 'User' } & Pick<User, '_id'> & {
        name?: Maybe<
          { __typename?: 'UserName' } & Pick<UserName, 'first' | 'last'>
        >
      }
  >
}

export type AuthForgotPasswordSendCodeMutationVariables = Exact<{
  method: ForgotPasswordMethod
  identifier: Scalars['String']
}>

export type AuthForgotPasswordSendCodeMutation = { __typename?: 'Mutation' } & {
  codeSent: Mutation['Dashboard__AuthForgotPasswordSendCode']
}

export type AuthForgotPasswordVerifyCodeMutationVariables = Exact<{
  method: ForgotPasswordMethod
  identifier: Scalars['String']
  code: Scalars['String']
}>

export type AuthForgotPasswordVerifyCodeMutation = {
  __typename?: 'Mutation'
} & { recoveryToken: Mutation['Dashboard__AuthForgotPasswordVerifyCode'] }

export type AuthForgotPasswordResetMutationVariables = Exact<{
  recoveryToken: Scalars['String']
  password: Scalars['String']
}>

export type AuthForgotPasswordResetMutation = { __typename?: 'Mutation' } & {
  authAccess?: Maybe<
    { __typename?: 'Dashboard__AuthAccess' } & Pick<
      Dashboard__AuthAccess,
      'access_token' | 'refresh_token' | 'user_id' | 'client_id'
    >
  >
}

export type AuthRefreshMutationVariables = Exact<{
  refreshToken: Scalars['String']
}>

export type AuthRefreshMutation = { __typename?: 'Mutation' } & {
  authRefreshResult?: Maybe<
    { __typename?: 'Dashboard__AuthRefreshResult' } & Pick<
      Dashboard__AuthRefreshResult,
      'accessToken' | 'refreshToken'
    >
  >
}

export type AuthSignInMutationVariables = Exact<{
  email: Scalars['String']
  password: Scalars['String']
}>

export type AuthSignInMutation = { __typename?: 'Mutation' } & {
  tokens?: Maybe<
    { __typename?: 'Dashboard__AuthAccess' } & Pick<
      Dashboard__AuthAccess,
      'access_token' | 'refresh_token' | 'user_id' | 'client_id'
    >
  >
}

export type AuthSignOutMutationVariables = Exact<{
  refreshToken: Scalars['String']
}>

export type AuthSignOutMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'Dashboard__AuthSignOut'
>

export type AuthSignUpMutationVariables = Exact<{
  first_name: Scalars['String']
  last_name: Scalars['String']
  email: Scalars['String']
  password: Scalars['String']
}>

export type AuthSignUpMutation = { __typename?: 'Mutation' } & {
  tokens?: Maybe<
    { __typename?: 'UserSignUpResult' } & Pick<
      UserSignUpResult,
      'access_token' | 'refresh_token' | 'user_id' | 'client_id'
    >
  >
}

export type ArchiveCreativeMutationVariables = Exact<{
  ids: Array<Scalars['MongoID']>
}>

export type ArchiveCreativeMutation = { __typename?: 'Mutation' } & {
  areArchived: Mutation['Dashboard__ArchiveCreatives']
}

export type PreSignCreativeUploadMutationVariables = Exact<{
  filename: Scalars['String']
  mimetype: Scalars['String']
  creative_type: EnumCreativeType
}>

export type PreSignCreativeUploadMutation = { __typename?: 'Mutation' } & {
  Dashboard__PreSignCreativeUpload?: Maybe<
    { __typename?: 'Dashboard__PreSignCreativeUploadResponse' } & Pick<
      Dashboard__PreSignCreativeUploadResponse,
      'creative_id' | 'presigned_url'
    >
  >
}

export type NotifyCreativeUploadCompletedMutationVariables = Exact<{
  creative_id: Scalars['MongoID']
}>

export type NotifyCreativeUploadCompletedMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'Dashboard__NotifyCreativeUploadCompleted'>

export type CreateCampaignMutationVariables = Exact<{
  input: CreateOneCampaignInput
}>

export type CreateCampaignMutation = { __typename?: 'Mutation' } & {
  campaign?: Maybe<
    { __typename?: 'CreateOneCampaignPayload' } & {
      _id: CreateOneCampaignPayload['recordId']
    }
  >
}

export type UpdateCampaignByIdMutationVariables = Exact<{
  _id: Scalars['MongoID']
  input: UpdateOneCampaignInput
}>

export type UpdateCampaignByIdMutation = { __typename?: 'Mutation' } & {
  campaign?: Maybe<
    { __typename?: 'UpdateOneCampaignPayload' } & {
      _id: UpdateOneCampaignPayload['recordId']
    }
  >
}

export type UpdateCampaignActivationMutationVariables = Exact<{
  ids: Array<Scalars['MongoID']>
  is_active: Scalars['Boolean']
}>

export type UpdateCampaignActivationMutation = { __typename?: 'Mutation' } & {
  areUpdated: Mutation['Dashboard__SetIsActiveCampaigns']
}

export type ArchiveCampaignMutationVariables = Exact<{
  ids: Array<Scalars['MongoID']>
}>

export type ArchiveCampaignMutation = { __typename?: 'Mutation' } & {
  areArchived: Mutation['Dashboard__ArchiveCampaigns']
}

export type UpdateCampaignAdGroupByIdMutationVariables = Exact<{
  _id: Scalars['MongoID']
  input: UpdateOneCampaignAdGroupInput
}>

export type UpdateCampaignAdGroupByIdMutation = { __typename?: 'Mutation' } & {
  campaignAdGroup?: Maybe<
    { __typename?: 'UpdateOneCampaignAdGroupPayload' } & {
      _id: UpdateOneCampaignAdGroupPayload['recordId']
    }
  >
}

export type UpdateCampaignAdGroupActivationMutationVariables = Exact<{
  ids: Array<Scalars['MongoID']>
  is_active: Scalars['Boolean']
}>

export type UpdateCampaignAdGroupActivationMutation = {
  __typename?: 'Mutation'
} & { areUpdated: Mutation['Dashboard__SetIsActiveCampaignAdGroups'] }

export type ArchiveCampaignAdGroupMutationVariables = Exact<{
  ids: Array<Scalars['MongoID']>
}>

export type ArchiveCampaignAdGroupMutation = { __typename?: 'Mutation' } & {
  areArchived: Mutation['Dashboard__ArchiveCampaignAdGroups']
}

export type CreateCampaignAdGroupMutationVariables = Exact<{
  input: CreateOneCampaignAdGroupInput
}>

export type CreateCampaignAdGroupMutation = { __typename?: 'Mutation' } & {
  campaignAdGroup?: Maybe<
    { __typename?: 'CreateOneCampaignAdGroupPayload' } & {
      _id: CreateOneCampaignAdGroupPayload['recordId']
    }
  >
}

export type CreateNetworkMutationVariables = Exact<{
  input: CreateOneNetworkInput
}>

export type CreateNetworkMutation = { __typename?: 'Mutation' } & {
  network?: Maybe<
    { __typename?: 'CreateOneNetworkPayload' } & {
      _id: CreateOneNetworkPayload['recordId']
    }
  >
}

export type UpdateNetworkByIdMutationVariables = Exact<{
  _id: Scalars['MongoID']
  input: UpdateOneNetworkInput
}>

export type UpdateNetworkByIdMutation = { __typename?: 'Mutation' } & {
  network?: Maybe<
    { __typename?: 'UpdateOneNetworkPayload' } & {
      _id: UpdateOneNetworkPayload['recordId']
    }
  >
}

export type UpdateNetworkActivationMutationVariables = Exact<{
  ids: Array<Scalars['MongoID']>
  is_active: Scalars['Boolean']
}>

export type UpdateNetworkActivationMutation = { __typename?: 'Mutation' } & {
  areUpdated: Mutation['Dashboard__SetIsActiveNetworks']
}

export type ArchiveNetworkMutationVariables = Exact<{
  ids: Array<Scalars['MongoID']>
}>

export type ArchiveNetworkMutation = { __typename?: 'Mutation' } & {
  areArchived: Mutation['Dashboard__ArchiveNetworks']
}

export type BindPlayerMutationVariables = Exact<{
  token?: Maybe<Scalars['String']>
}>

export type BindPlayerMutation = { __typename?: 'Mutation' } & {
  player?: Maybe<{ __typename?: 'Player' } & Pick<Player, '_id' | 'name'>>
}

export type UpdatePlayerByIdMutationVariables = Exact<{
  skip?: Maybe<Scalars['Int']>
  _id: Scalars['MongoID']
  input: UpdateOnePlayerInput
}>

export type UpdatePlayerByIdMutation = { __typename?: 'Mutation' } & {
  player?: Maybe<
    { __typename?: 'UpdateOnePlayerPayload' } & {
      _id: UpdateOnePlayerPayload['recordId']
    }
  >
}

export type UpdatePlayerActivationMutationVariables = Exact<{
  ids: Array<Scalars['MongoID']>
  is_active: Scalars['Boolean']
}>

export type UpdatePlayerActivationMutation = { __typename?: 'Mutation' } & {
  areUpdated: Mutation['Dashboard__SetIsActivePlayers']
}

export type ArchivePlayerMutationVariables = Exact<{
  ids: Array<Scalars['MongoID']>
}>

export type ArchivePlayerMutation = { __typename?: 'Mutation' } & {
  areArchived: Mutation['Dashboard__ArchivePlayers']
}

export type SetupWorkspaceMutationVariables = Exact<{
  name?: Maybe<Scalars['String']>
  platform_type?: Maybe<EnumWorkspacePlatform_Type>
}>

export type SetupWorkspaceMutation = { __typename?: 'Mutation' } & {
  workspace?: Maybe<
    { __typename?: 'Workspace' } & Pick<
      Workspace,
      '_id' | 'platform_type' | 'name'
    >
  >
}

export type UpdateWorkspaceByIdMutationVariables = Exact<{
  _id: Scalars['String']
  details: Dashboard__WorkspaceUpdateDetailsInput
}>

export type UpdateWorkspaceByIdMutation = { __typename?: 'Mutation' } & {
  workspace?: Maybe<
    { __typename?: 'Workspace' } & Pick<
      Workspace,
      '_id' | 'platform_type' | 'name'
    >
  >
}

export type AccountEmailAddMutationVariables = Exact<{
  email: Scalars['String']
}>

export type AccountEmailAddMutation = { __typename?: 'Mutation' } & {
  added: Mutation['Dashboard__AccountEmailAdd']
}

export type AccountEmailVerifyCodeMutationVariables = Exact<{
  email: Scalars['String']
  code: Scalars['String']
}>

export type AccountEmailVerifyCodeMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'Dashboard__AccountEmailVerifyCode'
>

export type AccountEmailSendVerificationCodeMutationVariables = Exact<{
  email: Scalars['String']
}>

export type AccountEmailSendVerificationCodeMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'Dashboard__AccountEmailSendVerificationCode'>

export type AccountEmailSetPrimaryMutationVariables = Exact<{
  email: Scalars['String']
}>

export type AccountEmailSetPrimaryMutation = { __typename?: 'Mutation' } & {
  isPrimary: Mutation['Dashboard__AccountEmailSetPrimary']
}

export type AccountEmailRemoveMutationVariables = Exact<{
  email: Scalars['String']
}>

export type AccountEmailRemoveMutation = { __typename?: 'Mutation' } & {
  removed: Mutation['Dashboard__AccountEmailRemove']
}

export type AccountChangePasswordMutationVariables = Exact<{
  refreshToken: Scalars['String']
  currentPassword: Scalars['String']
  newPassword: Scalars['String']
}>

export type AccountChangePasswordMutation = { __typename?: 'Mutation' } & {
  isUpdated: Mutation['Dashboard__AccountChangePassword']
}

export type AddWorkspaceMemberMutationVariables = Exact<{
  emails: Array<Scalars['String']>
}>

export type AddWorkspaceMemberMutation = { __typename?: 'Mutation' } & {
  addedMember: Array<{ __typename?: 'Invite' } & Pick<Invite, '_id'>>
}

export type RemoveWorkspaceMemberMutationVariables = Exact<{
  email: Scalars['String']
}>

export type RemoveWorkspaceMemberMutation = { __typename?: 'Mutation' } & {
  removedMember: Mutation['Dashboard__RemoveWorkspaceMember']
}

export type InviteToWorkspaceMutationVariables = Exact<{
  emails: Array<Scalars['String']>
}>

export type InviteToWorkspaceMutation = { __typename?: 'Mutation' } & {
  isInviteSent: Array<{ __typename?: 'Invite' } & InviteFullFragmentFragment>
}

export type AcceptWorkspaceInvitesMutationVariables = Exact<{
  ids: Array<Scalars['MongoID']>
}>

export type AcceptWorkspaceInvitesMutation = { __typename?: 'Mutation' } & {
  invites: Array<{ __typename?: 'Invite' } & InviteFullFragmentFragment>
}

export type RejectWorkspaceInvitesMutationVariables = Exact<{
  ids: Array<Scalars['MongoID']>
}>

export type RejectWorkspaceInvitesMutation = { __typename?: 'Mutation' } & {
  invites: Array<{ __typename?: 'Invite' } & InviteFullFragmentFragment>
}

export type WorkspaceSetupIntentMutationVariables = Exact<{
  setupIntentArgs?: Maybe<SetupIntentInput>
}>

export type WorkspaceSetupIntentMutation = { __typename?: 'Mutation' } & {
  setupIntent: { __typename?: 'SetupIntentObject' } & Pick<
    SetupIntentObject,
    'id' | 'client_secret' | 'payment_method' | 'status'
  >
}

export type WorkspaceAddPaymentMethodMutationVariables = Exact<{
  paymentMethod?: Maybe<PaymentMethodInput>
}>

export type WorkspaceAddPaymentMethodMutation = { __typename?: 'Mutation' } & {
  paymentMethod: Mutation['Dashboard__WorkspaceAddPaymentMethod']
}

export type WorkspaceSetDefaultPaymentMethodMutationVariables = Exact<{
  paymentMethodId: Scalars['String']
}>

export type WorkspaceSetDefaultPaymentMethodMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'Dashboard__WorkspaceSetDefaultPaymentMethod'>

export type WorkspaceRemovePaymentMethodMutationVariables = Exact<{
  paymentMethodId: Scalars['String']
}>

export type WorkspaceRemovePaymentMethodMutation = {
  __typename?: 'Mutation'
} & Pick<Mutation, 'Dashboard__WorkspaceRemovePaymentMethod'>

export type WorkspacePayInvoiceMutationVariables = Exact<{
  invoiceId: Scalars['String']
}>

export type WorkspacePayInvoiceMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'Dashboard__WorkspacePayInvoice'
>

export type CreateWebhookMutationVariables = Exact<{
  input: CreateOneWebhookInput
}>

export type CreateWebhookMutation = { __typename?: 'Mutation' } & {
  webhook?: Maybe<
    { __typename?: 'CreateOneWebhookPayload' } & {
      _id: CreateOneWebhookPayload['recordId']
    }
  >
}

export type UpdateWebhookByIdMutationVariables = Exact<{
  _id: Scalars['MongoID']
  input: UpdateOneWebhookInput
}>

export type UpdateWebhookByIdMutation = { __typename?: 'Mutation' } & {
  webhook?: Maybe<
    { __typename?: 'UpdateOneWebhookPayload' } & {
      _id: UpdateOneWebhookPayload['recordId']
    }
  >
}

export type ArchiveWebhookMutationVariables = Exact<{
  ids: Array<Scalars['MongoID']>
}>

export type ArchiveWebhookMutation = { __typename?: 'Mutation' } & {
  areArchived: Mutation['Dashboard__ArchiveWebhooks']
}

export type UpdateCreativeByIdMutationVariables = Exact<{
  _id: Scalars['MongoID']
  input: UpdateOneCreativeInput
}>

export type UpdateCreativeByIdMutation = { __typename?: 'Mutation' } & {
  creative?: Maybe<
    { __typename?: 'UpdateOneCreativePayload' } & {
      _id: UpdateOneCreativePayload['recordId']
    }
  >
}

export type CountQueryVariables = Exact<{ [key: string]: never }>

export type CountQuery = { __typename?: 'Query' } & {
  campaigns: Query['Dashboard__CountCampaigns']
  creatives: Query['Dashboard__CountCreatives']
  networks: Query['Dashboard__CountNetworks']
  players: Query['Dashboard__CountPlayers']
}

export type WhoAmIQueryVariables = Exact<{ [key: string]: never }>

export type WhoAmIQuery = { __typename?: 'Query' } & {
  user?: Maybe<
    { __typename?: 'Dashboard__WhoAmIUserResult' } & Pick<
      Dashboard__WhoAmIUserResult,
      '_id' | 'is_active' | 'primary_email_index' | 'primary_phone_index'
    > & {
        name?: Maybe<
          { __typename?: 'UserName' } & Pick<UserName, 'first' | 'last'>
        >
        platform_roles?: Maybe<
          { __typename?: 'UserPlatform_roles' } & Pick<
            UserPlatform_Roles,
            'admin' | 'curator'
          >
        >
        workspaces: Array<
          Maybe<
            { __typename?: 'Dashboard__WhoAmIUserWorkspaceResult' } & Pick<
              Dashboard__WhoAmIUserWorkspaceResult,
              '_id' | 'name' | 'role' | 'added_at'
            >
          >
        >
        invites: Array<
          Maybe<
            { __typename?: 'Dashboard__WhoAmIUserInviteResult' } & Pick<
              Dashboard__WhoAmIUserInviteResult,
              '_id' | 'status' | 'workspace_id'
            >
          >
        >
        phones: Array<
          Maybe<
            { __typename?: 'UserPhone' } & Pick<
              UserPhone,
              'number' | 'verified_at'
            >
          >
        >
        emails: Array<
          Maybe<
            { __typename?: 'UserEmail' } & Pick<
              UserEmail,
              'address' | 'verified_at'
            >
          >
        >
      }
  >
}

export type ViewAreaTypeQueryVariables = Exact<{
  _id: Scalars['MongoID']
}>

export type ViewAreaTypeQuery = { __typename?: 'Query' } & {
  areaType?: Maybe<{ __typename?: 'AreaType' } & AreaTypeFullFragmentFragment>
}

export type ListAreaTypesQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyAreaTypeInput>
  filter?: Maybe<FilterFindManyAreaTypeInput>
}>

export type ListAreaTypesQuery = { __typename?: 'Query' } & {
  areaTypes?: Maybe<
    Array<{ __typename?: 'AreaType' } & AreaTypeFullFragmentFragment>
  >
}

export type ViewVenueTypeQueryVariables = Exact<{
  _id: Scalars['MongoID']
}>

export type ViewVenueTypeQuery = { __typename?: 'Query' } & {
  venueType?: Maybe<
    { __typename?: 'VenueType' } & VenueTypeFullFragmentFragment
  >
}

export type ListVenueTypesQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyVenueTypeInput>
  filter?: Maybe<FilterFindManyVenueTypeInput>
}>

export type ListVenueTypesQuery = { __typename?: 'Query' } & {
  venueTypes?: Maybe<
    Array<{ __typename?: 'VenueType' } & VenueTypeFullFragmentFragment>
  >
}

export type ViewCampaignQueryVariables = Exact<{
  _id: Scalars['MongoID']
  intervals?: Array<StatsIntervalInput>
}>

export type ViewCampaignQuery = { __typename?: 'Query' } & {
  campaign?: Maybe<{ __typename?: 'Campaign' } & CampaignFullFragmentFragment>
}

export type ListCampaignsQueryVariables = Exact<{
  offset?: Scalars['Int']
  limit?: Scalars['Int']
  filter?: FilterFindManyCampaignInput
  intervals?: Array<StatsIntervalInput>
}>

export type ListCampaignsQuery = { __typename?: 'Query' } & {
  campaigns?: Maybe<
    Array<{ __typename?: 'Campaign' } & CampaignFullFragmentFragment>
  >
}

export type ViewCampaignAdGroupQueryVariables = Exact<{
  _id: Scalars['MongoID']
  intervals?: Array<StatsIntervalInput>
}>

export type ViewCampaignAdGroupQuery = { __typename?: 'Query' } & {
  campaignAdGroup?: Maybe<
    { __typename?: 'CampaignAdGroup' } & {
      creatives?: Maybe<
        Array<{ __typename?: 'Creative' } & CreativeListFragmentFragment>
      >
    } & CampaignAdGroupFullFragmentFragment
  >
}

export type ListCampaignAdGroupsQueryVariables = Exact<{
  offset?: Scalars['Int']
  limit?: Scalars['Int']
  filter?: FilterFindManyCampaignAdGroupInput
  intervals?: Array<StatsIntervalInput>
}>

export type ListCampaignAdGroupsQuery = { __typename?: 'Query' } & {
  campaignAdGroups?: Maybe<
    Array<
      { __typename?: 'CampaignAdGroup' } & CampaignAdGroupListFragmentFragment
    >
  >
}

export type ViewCreativeQueryVariables = Exact<{
  _id: Scalars['MongoID']
  intervals?: Array<StatsIntervalInput>
}>

export type ViewCreativeQuery = { __typename?: 'Query' } & {
  creative?: Maybe<{ __typename?: 'Creative' } & CreativeFullFragmentFragment>
}

export type ListCreativesQueryVariables = Exact<{
  offset?: Scalars['Int']
  limit?: Scalars['Int']
  filter?: FilterFindManyCreativeInput
  intervals?: Array<StatsIntervalInput>
}>

export type ListCreativesQuery = { __typename?: 'Query' } & {
  creatives?: Maybe<
    Array<{ __typename?: 'Creative' } & CreativeFullFragmentFragment>
  >
}

export type ViewNetworkQueryVariables = Exact<{
  _id: Scalars['MongoID']
  intervals?: Array<StatsIntervalInput>
}>

export type ViewNetworkQuery = { __typename?: 'Query' } & {
  network?: Maybe<{ __typename?: 'Network' } & NetworkFullFragmentFragment>
}

export type ListNetworksQueryVariables = Exact<{
  offset?: Scalars['Int']
  limit?: Scalars['Int']
  filter?: FilterFindManyNetworkInput
  intervals?: Array<StatsIntervalInput>
}>

export type ListNetworksQuery = { __typename?: 'Query' } & {
  networks?: Maybe<
    Array<{ __typename?: 'Network' } & NetworkListFragmentFragment>
  >
}

export type ViewPlayerQueryVariables = Exact<{
  _id: Scalars['MongoID']
  intervals?: Array<StatsIntervalInput>
}>

export type ViewPlayerQuery = { __typename?: 'Query' } & {
  player?: Maybe<{ __typename?: 'Player' } & PlayerFullFragmentFragment>
}

export type ListPlayersQueryVariables = Exact<{
  offset?: Scalars['Int']
  limit?: Scalars['Int']
  filter?: FilterFindManyPlayerInput
  intervals?: Array<StatsIntervalInput>
}>

export type ListPlayersQuery = { __typename?: 'Query' } & {
  players?: Maybe<Array<{ __typename?: 'Player' } & PlayerFullFragmentFragment>>
}

export type ViewCountryQueryVariables = Exact<{
  _id?: Maybe<Scalars['MongoID']>
  code?: Maybe<Scalars['String']>
}>

export type ViewCountryQuery = { __typename?: 'Query' } & {
  country?: Maybe<{ __typename?: 'Country' } & CountryFullFragmentFragment>
}

export type ListCountriesQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>
  filter?: Maybe<FilterFindManyCountryInput>
}>

export type ListCountriesQuery = { __typename?: 'Query' } & {
  countries?: Maybe<
    Array<{ __typename?: 'Country' } & CountryFullFragmentFragment>
  >
}

export type ViewWorkspaceQueryVariables = Exact<{
  _id: Scalars['MongoID']
  intervals?: Array<StatsIntervalInput>
}>

export type ViewWorkspaceQuery = { __typename?: 'Query' } & {
  workspace?: Maybe<
    { __typename?: 'Workspace' } & WorkspaceFullFragmentFragment
  >
}

export type ListWorkspacesQueryVariables = Exact<{
  _ids: Array<Maybe<Scalars['MongoID']>>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindByIdsWorkspaceInput>
  intervals?: Array<StatsIntervalInput>
}>

export type ListWorkspacesQuery = { __typename?: 'Query' } & {
  workspaces?: Maybe<
    Array<{ __typename?: 'Workspace' } & WorkspaceFullFragmentFragment>
  >
}

export type GetWorkspaceStatsQueryVariables = Exact<{
  _id: Scalars['MongoID']
  intervals: Array<StatsIntervalInput>
}>

export type GetWorkspaceStatsQuery = { __typename?: 'Query' } & {
  workspace?: Maybe<
    { __typename?: 'Workspace' } & {
      stats: Array<
        { __typename?: 'StatsInterval' } & Pick<
          StatsInterval,
          'reach' | 'engagement'
        >
      >
    }
  >
}

export type ViewUsersQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyUserInput>
  filter?: Maybe<FilterFindManyUserInput>
}>

export type ViewUsersQuery = { __typename?: 'Query' } & {
  users?: Maybe<Array<{ __typename?: 'User' } & UserFullFragmentFragment>>
}

export type ListInvitesQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyInviteInput>
  filter?: Maybe<FilterFindManyInviteInput>
}>

export type ListInvitesQuery = { __typename?: 'Query' } & {
  invites?: Maybe<Array<{ __typename?: 'Invite' } & InviteFullFragmentFragment>>
}

export type ViewWebhookQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindOneWebhookInput>
  filter?: Maybe<FilterFindOneWebhookInput>
}>

export type ViewWebhookQuery = { __typename?: 'Query' } & {
  webhook?: Maybe<{ __typename?: 'Webhook' } & WebhookFullFragmentFragment>
}

export type ListWebhooksQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyWebhookInput>
  filter?: Maybe<FilterFindManyWebhookInput>
}>

export type ListWebhooksQuery = { __typename?: 'Query' } & {
  webhooks?: Maybe<
    Array<{ __typename?: 'Webhook' } & WebhookFullFragmentFragment>
  >
}

export type ViewCategoryQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindOneCategoryInput>
  filter?: Maybe<FilterFindOneCategoryInput>
}>

export type ViewCategoryQuery = { __typename?: 'Query' } & {
  category?: Maybe<{ __typename?: 'Category' } & Pick<Category, '_id' | 'name'>>
}

export type ListCategoriesQueryVariables = Exact<{
  skip?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sort?: Maybe<SortFindManyCategoryInput>
  filter?: Maybe<FilterFindManyCategoryInput>
}>

export type ListCategoriesQuery = { __typename?: 'Query' } & {
  categories?: Maybe<
    Array<{ __typename?: 'Category' } & Pick<Category, '_id' | 'name'>>
  >
}

export const AreaTypeFullFragmentFragmentDoc = gql`
  fragment AreaTypeFullFragment on AreaType {
    _id
    name
    description
  }
`
export const VenueTypeFullFragmentFragmentDoc = gql`
  fragment VenueTypeFullFragment on VenueType {
    _id
    name
  }
`
export const NetworkListFragmentFragmentDoc = gql`
  fragment NetworkListFragment on Network {
    _id
    name
    status
    players {
      player_id
    }
    created {
      at
    }
    stats: getStats(intervals: $intervals) {
      reach
      engagement
    }
  }
`
export const PlayerListFragmentFragmentDoc = gql`
  fragment PlayerListFragment on Player {
    _id
    name
    status
    demographics {
      language_ids
      country_id
      area_type_id
      venue_type_ids
    }
    geographics {
      location {
        type
        coordinates
      }
      address {
        street
        number
        zip
        city
        state
        country_id
      }
    }
    settings {
      screen_orientation
    }
    created {
      at
    }
    stats: getStats(intervals: $intervals) {
      reach
      engagement
    }
  }
`
export const CampaignAdGroupFullFragmentFragmentDoc = gql`
  fragment CampaignAdGroupFullFragment on CampaignAdGroup {
    _id
    is_active
    name
    status
    campaign_id
    audiences_ids
    creatives_ids
    redirect_to
    saturation
    campaign {
      name
    }
    timeframe {
      starts_at
      ends_at
    }
    audience {
      area_type
      venue_type_ids
      geofences {
        location {
          type
          coordinates
        }
        range
        distance_unit_id
      }
    }
    created {
      at
      by_user_id
    }
    archived {
      at
      by_user_id
    }
    stats: getStats(intervals: $intervals) {
      reach
      engagement
    }
  }
`
export const CampaignFullFragmentFragmentDoc = gql`
  fragment CampaignFullFragment on Campaign {
    _id
    name
    status
    timeframe {
      starts_at
      ends_at
    }
    broadcasts_to {
      marketplace
      networks {
        network {
          ...NetworkListFragment
        }
      }
      players {
        player {
          ...PlayerListFragment
        }
      }
    }
    ad_groups {
      ...CampaignAdGroupFullFragment
    }
    created {
      at
      by_user_id
    }
    archived {
      at
      by_user_id
    }
    stats: getStats(intervals: $intervals) {
      reach
      engagement
    }
  }
  ${NetworkListFragmentFragmentDoc}
  ${PlayerListFragmentFragmentDoc}
  ${CampaignAdGroupFullFragmentFragmentDoc}
`
export const CampaignListFragmentFragmentDoc = gql`
  fragment CampaignListFragment on Campaign {
    _id
    name
    status
    timeframe {
      starts_at
      ends_at
    }
    broadcasts_to {
      marketplace
      networks {
        network_id
      }
      players {
        player_id
      }
    }
    ad_groups {
      campaign_adgroup_id: _id
    }
    created {
      at
    }
    stats: getStats(intervals: $intervals) {
      reach
      engagement
    }
  }
`
export const CampaignAdGroupListFragmentFragmentDoc = gql`
  fragment CampaignAdGroupListFragment on CampaignAdGroup {
    _id
    name
    status
    is_active
    campaign_id
    saturation
    timeframe {
      starts_at
      ends_at
    }
    created {
      at
    }
    creatives_ids
    audience {
      area_type
    }
    stats: getStats(intervals: $intervals) {
      reach
      engagement
    }
  }
`
export const UploadVersionsMetadataFullFragmentFragmentDoc = gql`
  fragment UploadVersionsMetadataFullFragment on UploadVersionsMetadata {
    audio {
      codec
      bitrate
      frequency
    }
    video {
      fps
      codec
      bitrate
      resolution
    }
  }
`
export const UploadVersionsFullFragmentFragmentDoc = gql`
  fragment UploadVersionsFullFragment on UploadVersions {
    name
    bytes
    format
    mimetype
    duration
    ipfs_hash
    public_url
    storage_path
    metadata {
      ...UploadVersionsMetadataFullFragment
    }
  }
  ${UploadVersionsMetadataFullFragmentFragmentDoc}
`
export const UploadFullFragmentFragmentDoc = gql`
  fragment UploadFullFragment on Upload {
    _id
    type
    name
    created {
      at
      by_user_id
    }
    versions {
      ...UploadVersionsFullFragment
    }
  }
  ${UploadVersionsFullFragmentFragmentDoc}
`
export const CreativeFullFragmentFragmentDoc = gql`
  fragment CreativeFullFragment on Creative {
    _id
    name
    type
    status
    upload_id
    categories_ids
    ownership {
      workspace_id
    }
    created {
      at
      by_user_id
    }
    upload {
      ...UploadFullFragment
    }
    ad_groups {
      ...CampaignAdGroupFullFragment
    }
    stats: getStats(intervals: $intervals) {
      reach
      engagement
    }
  }
  ${UploadFullFragmentFragmentDoc}
  ${CampaignAdGroupFullFragmentFragmentDoc}
`
export const UploadMinimumFragmentFragmentDoc = gql`
  fragment UploadMinimumFragment on Upload {
    type
    versions {
      name
      public_url
    }
    created {
      at
    }
  }
`
export const CreativeListFragmentFragmentDoc = gql`
  fragment CreativeListFragment on Creative {
    _id
    name
    type
    status
    upload {
      ...UploadMinimumFragment
    }
    created {
      at
    }
    stats: getStats(intervals: $intervals) {
      reach
      engagement
    }
  }
  ${UploadMinimumFragmentFragmentDoc}
`
export const NetworkFullFragmentFragmentDoc = gql`
  fragment NetworkFullFragment on Network {
    _id
    name
    status
    players {
      added {
        at
        by_user_id
      }
      player {
        ...PlayerListFragment
      }
    }
    created {
      by_user_id
      at
    }
    archived {
      at
      by_user_id
    }
    stats: getStats(intervals: $intervals) {
      reach
      engagement
    }
  }
  ${PlayerListFragmentFragmentDoc}
`
export const PlayerFullFragmentFragmentDoc = gql`
  fragment PlayerFullFragment on Player {
    _id
    unique_id
    name
    status
    capabilities {
      playback {
        videos
        audios
        images
      }
    }
    demographics {
      language_ids
      country_id
      area_type_id
      venue_type_ids
    }
    geographics {
      location {
        type
        coordinates
      }
      address {
        street
        number
        zip
        city
        state
        country_id
      }
    }
    settings {
      debug
      volume
      brightness
      screen_orientation
    }
    telemetry {
      scans
      impressions
    }
    last_seen_at
    created {
      at
      by_user_id
    }
    stats: getStats(intervals: $intervals) {
      reach
      engagement
    }
  }
`
export const CountryFullFragmentFragmentDoc = gql`
  fragment CountryFullFragment on Country {
    _id
    name
    code
  }
`
export const UserListFragmentFragmentDoc = gql`
  fragment UserListFragment on User {
    _id
    name {
      first
      last
    }
    emails {
      address
      verified_at
    }
  }
`
export const WorkspaceFullFragmentFragmentDoc = gql`
  fragment WorkspaceFullFragment on Workspace {
    _id
    name
    is_active
    platform_type
    members {
      role
      user_id
      user {
        ...UserListFragment
      }
      added {
        at
        by_user_id
      }
      removed {
        at
        by_user_id
      }
    }
    stats: getStats(intervals: $intervals) {
      reach
      engagement
    }
    payment_methods {
      data {
        id
        card {
          brand
          country
          exp_year
          exp_month
          last4
        }
        billing_details {
          address {
            city
            country
            line1
            postal_code
            state
          }
          name
        }
        metadata {
          vat
          company
        }
      }
    }
    default_payment_method
    invoices {
      data {
        id
        created
        invoice_pdf
        hosted_invoice_url
        total
        status
      }
    }
    upcoming_invoice {
      amount_due
      status
      next_payment_attempt
    }
  }
  ${UserListFragmentFragmentDoc}
`
export const UserFullFragmentFragmentDoc = gql`
  fragment UserFullFragment on User {
    _id
    is_active
    name {
      first
      last
    }
    dob
    gender
    address {
      street
      number
      city
      state
      zip
      country_id
    }
    phones {
      number
      verified_at
    }
    platform_roles {
      admin
      curator
    }
    last_seen_at
    created {
      at
      by_user_id
    }
    emails {
      address
      verified_at
    }
  }
`
export const InviteFullFragmentFragmentDoc = gql`
  fragment InviteFullFragment on Invite {
    _id
    status
    ownership {
      workspace_id
    }
    target {
      email
      user_id
      name {
        first
        last
      }
    }
    created {
      at
      by_user_id
    }
    revoked {
      at
      by_user_id
    }
    rejected {
      at
      by_user_id
    }
    accepted {
      at
      by_user_id
    }
  }
`
export const WebhookFullFragmentFragmentDoc = gql`
  fragment WebhookFullFragment on Webhook {
    _id
    url
    type
    retries
    ownership {
      workspace_id
    }
    archived {
      at
      by_user_id
    }
    events {
      _id
      at
      type
      webhook_id
      responses {
        _id
        url
        body
        statusCode
        webhook_event_id
      }
    }
    event_types {
      player_binded
      player_activated
      player_archived
      creative_uploaded
      creative_archived
      network_created
      network_changed
      network_archived
      campaign_created
      campaign_changed
      campaign_archived
      campaign_ad_group_created
      campaign_ad_group_changed
      campaign_ad_group_archived
      webhook_archived
      engagement
    }
  }
`
export const SendEmailVerificationCodeDocument = gql`
  mutation SendEmailVerificationCode($email: String!) {
    isCodeSent: Dashboard__AccountEmailSendVerificationCode(email: $email)
  }
`
export type SendEmailVerificationCodeMutationFn = ApolloReactCommon.MutationFunction<
  SendEmailVerificationCodeMutation,
  SendEmailVerificationCodeMutationVariables
>
export type SendEmailVerificationCodeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    SendEmailVerificationCodeMutation,
    SendEmailVerificationCodeMutationVariables
  >,
  'mutation'
>

export const SendEmailVerificationCodeComponent = (
  props: SendEmailVerificationCodeComponentProps
) => (
  <ApolloReactComponents.Mutation<
    SendEmailVerificationCodeMutation,
    SendEmailVerificationCodeMutationVariables
  >
    mutation={SendEmailVerificationCodeDocument}
    {...props}
  />
)

export type SendEmailVerificationCodeProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    SendEmailVerificationCodeMutation,
    SendEmailVerificationCodeMutationVariables
  >
} &
  TChildProps
export function withSendEmailVerificationCode<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SendEmailVerificationCodeMutation,
    SendEmailVerificationCodeMutationVariables,
    SendEmailVerificationCodeProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    SendEmailVerificationCodeMutation,
    SendEmailVerificationCodeMutationVariables,
    SendEmailVerificationCodeProps<TChildProps, TDataName>
  >(SendEmailVerificationCodeDocument, {
    alias: 'sendEmailVerificationCode',
    ...operationOptions,
  })
}

/**
 * __useSendEmailVerificationCodeMutation__
 *
 * To run a mutation, you first call `useSendEmailVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailVerificationCodeMutation, { data, loading, error }] = useSendEmailVerificationCodeMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendEmailVerificationCodeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SendEmailVerificationCodeMutation,
    SendEmailVerificationCodeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SendEmailVerificationCodeMutation,
    SendEmailVerificationCodeMutationVariables
  >(SendEmailVerificationCodeDocument, baseOptions)
}
export type SendEmailVerificationCodeMutationHookResult = ReturnType<
  typeof useSendEmailVerificationCodeMutation
>
export type SendEmailVerificationCodeMutationResult = ApolloReactCommon.MutationResult<SendEmailVerificationCodeMutation>
export type SendEmailVerificationCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SendEmailVerificationCodeMutation,
  SendEmailVerificationCodeMutationVariables
>
export const VerifyEmailCodeDocument = gql`
  mutation VerifyEmailCode($code: String!, $email: String!) {
    isCodeValid: Dashboard__AccountEmailVerifyCode(code: $code, email: $email)
  }
`
export type VerifyEmailCodeMutationFn = ApolloReactCommon.MutationFunction<
  VerifyEmailCodeMutation,
  VerifyEmailCodeMutationVariables
>
export type VerifyEmailCodeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    VerifyEmailCodeMutation,
    VerifyEmailCodeMutationVariables
  >,
  'mutation'
>

export const VerifyEmailCodeComponent = (
  props: VerifyEmailCodeComponentProps
) => (
  <ApolloReactComponents.Mutation<
    VerifyEmailCodeMutation,
    VerifyEmailCodeMutationVariables
  >
    mutation={VerifyEmailCodeDocument}
    {...props}
  />
)

export type VerifyEmailCodeProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    VerifyEmailCodeMutation,
    VerifyEmailCodeMutationVariables
  >
} &
  TChildProps
export function withVerifyEmailCode<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    VerifyEmailCodeMutation,
    VerifyEmailCodeMutationVariables,
    VerifyEmailCodeProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    VerifyEmailCodeMutation,
    VerifyEmailCodeMutationVariables,
    VerifyEmailCodeProps<TChildProps, TDataName>
  >(VerifyEmailCodeDocument, {
    alias: 'verifyEmailCode',
    ...operationOptions,
  })
}

/**
 * __useVerifyEmailCodeMutation__
 *
 * To run a mutation, you first call `useVerifyEmailCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailCodeMutation, { data, loading, error }] = useVerifyEmailCodeMutation({
 *   variables: {
 *      code: // value for 'code'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useVerifyEmailCodeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    VerifyEmailCodeMutation,
    VerifyEmailCodeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    VerifyEmailCodeMutation,
    VerifyEmailCodeMutationVariables
  >(VerifyEmailCodeDocument, baseOptions)
}
export type VerifyEmailCodeMutationHookResult = ReturnType<
  typeof useVerifyEmailCodeMutation
>
export type VerifyEmailCodeMutationResult = ApolloReactCommon.MutationResult<VerifyEmailCodeMutation>
export type VerifyEmailCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  VerifyEmailCodeMutation,
  VerifyEmailCodeMutationVariables
>
export const UpdateAccountDetailsDocument = gql`
  mutation UpdateAccountDetails(
    $details: Dashboard__AccountUpdateDetailsInput!
  ) {
    workspace: Dashboard__UpdateAccountDetails(details: $details) {
      _id
      name {
        first
        last
      }
    }
  }
`
export type UpdateAccountDetailsMutationFn = ApolloReactCommon.MutationFunction<
  UpdateAccountDetailsMutation,
  UpdateAccountDetailsMutationVariables
>
export type UpdateAccountDetailsComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateAccountDetailsMutation,
    UpdateAccountDetailsMutationVariables
  >,
  'mutation'
>

export const UpdateAccountDetailsComponent = (
  props: UpdateAccountDetailsComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateAccountDetailsMutation,
    UpdateAccountDetailsMutationVariables
  >
    mutation={UpdateAccountDetailsDocument}
    {...props}
  />
)

export type UpdateAccountDetailsProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    UpdateAccountDetailsMutation,
    UpdateAccountDetailsMutationVariables
  >
} &
  TChildProps
export function withUpdateAccountDetails<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateAccountDetailsMutation,
    UpdateAccountDetailsMutationVariables,
    UpdateAccountDetailsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateAccountDetailsMutation,
    UpdateAccountDetailsMutationVariables,
    UpdateAccountDetailsProps<TChildProps, TDataName>
  >(UpdateAccountDetailsDocument, {
    alias: 'updateAccountDetails',
    ...operationOptions,
  })
}

/**
 * __useUpdateAccountDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateAccountDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountDetailsMutation, { data, loading, error }] = useUpdateAccountDetailsMutation({
 *   variables: {
 *      details: // value for 'details'
 *   },
 * });
 */
export function useUpdateAccountDetailsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateAccountDetailsMutation,
    UpdateAccountDetailsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateAccountDetailsMutation,
    UpdateAccountDetailsMutationVariables
  >(UpdateAccountDetailsDocument, baseOptions)
}
export type UpdateAccountDetailsMutationHookResult = ReturnType<
  typeof useUpdateAccountDetailsMutation
>
export type UpdateAccountDetailsMutationResult = ApolloReactCommon.MutationResult<UpdateAccountDetailsMutation>
export type UpdateAccountDetailsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateAccountDetailsMutation,
  UpdateAccountDetailsMutationVariables
>
export const AuthForgotPasswordSendCodeDocument = gql`
  mutation AuthForgotPasswordSendCode(
    $method: ForgotPasswordMethod!
    $identifier: String!
  ) {
    codeSent: Dashboard__AuthForgotPasswordSendCode(
      method: $method
      identifier: $identifier
    )
  }
`
export type AuthForgotPasswordSendCodeMutationFn = ApolloReactCommon.MutationFunction<
  AuthForgotPasswordSendCodeMutation,
  AuthForgotPasswordSendCodeMutationVariables
>
export type AuthForgotPasswordSendCodeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AuthForgotPasswordSendCodeMutation,
    AuthForgotPasswordSendCodeMutationVariables
  >,
  'mutation'
>

export const AuthForgotPasswordSendCodeComponent = (
  props: AuthForgotPasswordSendCodeComponentProps
) => (
  <ApolloReactComponents.Mutation<
    AuthForgotPasswordSendCodeMutation,
    AuthForgotPasswordSendCodeMutationVariables
  >
    mutation={AuthForgotPasswordSendCodeDocument}
    {...props}
  />
)

export type AuthForgotPasswordSendCodeProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    AuthForgotPasswordSendCodeMutation,
    AuthForgotPasswordSendCodeMutationVariables
  >
} &
  TChildProps
export function withAuthForgotPasswordSendCode<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AuthForgotPasswordSendCodeMutation,
    AuthForgotPasswordSendCodeMutationVariables,
    AuthForgotPasswordSendCodeProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AuthForgotPasswordSendCodeMutation,
    AuthForgotPasswordSendCodeMutationVariables,
    AuthForgotPasswordSendCodeProps<TChildProps, TDataName>
  >(AuthForgotPasswordSendCodeDocument, {
    alias: 'authForgotPasswordSendCode',
    ...operationOptions,
  })
}

/**
 * __useAuthForgotPasswordSendCodeMutation__
 *
 * To run a mutation, you first call `useAuthForgotPasswordSendCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthForgotPasswordSendCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authForgotPasswordSendCodeMutation, { data, loading, error }] = useAuthForgotPasswordSendCodeMutation({
 *   variables: {
 *      method: // value for 'method'
 *      identifier: // value for 'identifier'
 *   },
 * });
 */
export function useAuthForgotPasswordSendCodeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AuthForgotPasswordSendCodeMutation,
    AuthForgotPasswordSendCodeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AuthForgotPasswordSendCodeMutation,
    AuthForgotPasswordSendCodeMutationVariables
  >(AuthForgotPasswordSendCodeDocument, baseOptions)
}
export type AuthForgotPasswordSendCodeMutationHookResult = ReturnType<
  typeof useAuthForgotPasswordSendCodeMutation
>
export type AuthForgotPasswordSendCodeMutationResult = ApolloReactCommon.MutationResult<AuthForgotPasswordSendCodeMutation>
export type AuthForgotPasswordSendCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AuthForgotPasswordSendCodeMutation,
  AuthForgotPasswordSendCodeMutationVariables
>
export const AuthForgotPasswordVerifyCodeDocument = gql`
  mutation AuthForgotPasswordVerifyCode(
    $method: ForgotPasswordMethod!
    $identifier: String!
    $code: String!
  ) {
    recoveryToken: Dashboard__AuthForgotPasswordVerifyCode(
      method: $method
      identifier: $identifier
      code: $code
    )
  }
`
export type AuthForgotPasswordVerifyCodeMutationFn = ApolloReactCommon.MutationFunction<
  AuthForgotPasswordVerifyCodeMutation,
  AuthForgotPasswordVerifyCodeMutationVariables
>
export type AuthForgotPasswordVerifyCodeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AuthForgotPasswordVerifyCodeMutation,
    AuthForgotPasswordVerifyCodeMutationVariables
  >,
  'mutation'
>

export const AuthForgotPasswordVerifyCodeComponent = (
  props: AuthForgotPasswordVerifyCodeComponentProps
) => (
  <ApolloReactComponents.Mutation<
    AuthForgotPasswordVerifyCodeMutation,
    AuthForgotPasswordVerifyCodeMutationVariables
  >
    mutation={AuthForgotPasswordVerifyCodeDocument}
    {...props}
  />
)

export type AuthForgotPasswordVerifyCodeProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    AuthForgotPasswordVerifyCodeMutation,
    AuthForgotPasswordVerifyCodeMutationVariables
  >
} &
  TChildProps
export function withAuthForgotPasswordVerifyCode<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AuthForgotPasswordVerifyCodeMutation,
    AuthForgotPasswordVerifyCodeMutationVariables,
    AuthForgotPasswordVerifyCodeProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AuthForgotPasswordVerifyCodeMutation,
    AuthForgotPasswordVerifyCodeMutationVariables,
    AuthForgotPasswordVerifyCodeProps<TChildProps, TDataName>
  >(AuthForgotPasswordVerifyCodeDocument, {
    alias: 'authForgotPasswordVerifyCode',
    ...operationOptions,
  })
}

/**
 * __useAuthForgotPasswordVerifyCodeMutation__
 *
 * To run a mutation, you first call `useAuthForgotPasswordVerifyCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthForgotPasswordVerifyCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authForgotPasswordVerifyCodeMutation, { data, loading, error }] = useAuthForgotPasswordVerifyCodeMutation({
 *   variables: {
 *      method: // value for 'method'
 *      identifier: // value for 'identifier'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useAuthForgotPasswordVerifyCodeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AuthForgotPasswordVerifyCodeMutation,
    AuthForgotPasswordVerifyCodeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AuthForgotPasswordVerifyCodeMutation,
    AuthForgotPasswordVerifyCodeMutationVariables
  >(AuthForgotPasswordVerifyCodeDocument, baseOptions)
}
export type AuthForgotPasswordVerifyCodeMutationHookResult = ReturnType<
  typeof useAuthForgotPasswordVerifyCodeMutation
>
export type AuthForgotPasswordVerifyCodeMutationResult = ApolloReactCommon.MutationResult<AuthForgotPasswordVerifyCodeMutation>
export type AuthForgotPasswordVerifyCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AuthForgotPasswordVerifyCodeMutation,
  AuthForgotPasswordVerifyCodeMutationVariables
>
export const AuthForgotPasswordResetDocument = gql`
  mutation AuthForgotPasswordReset(
    $recoveryToken: String!
    $password: String!
  ) {
    authAccess: Dashboard__AuthForgotPasswordReset(
      recoveryToken: $recoveryToken
      password: $password
    ) {
      access_token
      refresh_token
      user_id
      client_id
    }
  }
`
export type AuthForgotPasswordResetMutationFn = ApolloReactCommon.MutationFunction<
  AuthForgotPasswordResetMutation,
  AuthForgotPasswordResetMutationVariables
>
export type AuthForgotPasswordResetComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AuthForgotPasswordResetMutation,
    AuthForgotPasswordResetMutationVariables
  >,
  'mutation'
>

export const AuthForgotPasswordResetComponent = (
  props: AuthForgotPasswordResetComponentProps
) => (
  <ApolloReactComponents.Mutation<
    AuthForgotPasswordResetMutation,
    AuthForgotPasswordResetMutationVariables
  >
    mutation={AuthForgotPasswordResetDocument}
    {...props}
  />
)

export type AuthForgotPasswordResetProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    AuthForgotPasswordResetMutation,
    AuthForgotPasswordResetMutationVariables
  >
} &
  TChildProps
export function withAuthForgotPasswordReset<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AuthForgotPasswordResetMutation,
    AuthForgotPasswordResetMutationVariables,
    AuthForgotPasswordResetProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AuthForgotPasswordResetMutation,
    AuthForgotPasswordResetMutationVariables,
    AuthForgotPasswordResetProps<TChildProps, TDataName>
  >(AuthForgotPasswordResetDocument, {
    alias: 'authForgotPasswordReset',
    ...operationOptions,
  })
}

/**
 * __useAuthForgotPasswordResetMutation__
 *
 * To run a mutation, you first call `useAuthForgotPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthForgotPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authForgotPasswordResetMutation, { data, loading, error }] = useAuthForgotPasswordResetMutation({
 *   variables: {
 *      recoveryToken: // value for 'recoveryToken'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAuthForgotPasswordResetMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AuthForgotPasswordResetMutation,
    AuthForgotPasswordResetMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AuthForgotPasswordResetMutation,
    AuthForgotPasswordResetMutationVariables
  >(AuthForgotPasswordResetDocument, baseOptions)
}
export type AuthForgotPasswordResetMutationHookResult = ReturnType<
  typeof useAuthForgotPasswordResetMutation
>
export type AuthForgotPasswordResetMutationResult = ApolloReactCommon.MutationResult<AuthForgotPasswordResetMutation>
export type AuthForgotPasswordResetMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AuthForgotPasswordResetMutation,
  AuthForgotPasswordResetMutationVariables
>
export const AuthRefreshDocument = gql`
  mutation AuthRefresh($refreshToken: String!) {
    authRefreshResult: Dashboard__AuthRefresh(refreshToken: $refreshToken) {
      accessToken
      refreshToken
    }
  }
`
export type AuthRefreshMutationFn = ApolloReactCommon.MutationFunction<
  AuthRefreshMutation,
  AuthRefreshMutationVariables
>
export type AuthRefreshComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AuthRefreshMutation,
    AuthRefreshMutationVariables
  >,
  'mutation'
>

export const AuthRefreshComponent = (props: AuthRefreshComponentProps) => (
  <ApolloReactComponents.Mutation<
    AuthRefreshMutation,
    AuthRefreshMutationVariables
  >
    mutation={AuthRefreshDocument}
    {...props}
  />
)

export type AuthRefreshProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    AuthRefreshMutation,
    AuthRefreshMutationVariables
  >
} &
  TChildProps
export function withAuthRefresh<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AuthRefreshMutation,
    AuthRefreshMutationVariables,
    AuthRefreshProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AuthRefreshMutation,
    AuthRefreshMutationVariables,
    AuthRefreshProps<TChildProps, TDataName>
  >(AuthRefreshDocument, {
    alias: 'authRefresh',
    ...operationOptions,
  })
}

/**
 * __useAuthRefreshMutation__
 *
 * To run a mutation, you first call `useAuthRefreshMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthRefreshMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authRefreshMutation, { data, loading, error }] = useAuthRefreshMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useAuthRefreshMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AuthRefreshMutation,
    AuthRefreshMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AuthRefreshMutation,
    AuthRefreshMutationVariables
  >(AuthRefreshDocument, baseOptions)
}
export type AuthRefreshMutationHookResult = ReturnType<
  typeof useAuthRefreshMutation
>
export type AuthRefreshMutationResult = ApolloReactCommon.MutationResult<AuthRefreshMutation>
export type AuthRefreshMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AuthRefreshMutation,
  AuthRefreshMutationVariables
>
export const AuthSignInDocument = gql`
  mutation AuthSignIn($email: String!, $password: String!) {
    tokens: Dashboard__AuthSignIn(email: $email, password: $password) {
      access_token
      refresh_token
      user_id
      client_id
    }
  }
`
export type AuthSignInMutationFn = ApolloReactCommon.MutationFunction<
  AuthSignInMutation,
  AuthSignInMutationVariables
>
export type AuthSignInComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AuthSignInMutation,
    AuthSignInMutationVariables
  >,
  'mutation'
>

export const AuthSignInComponent = (props: AuthSignInComponentProps) => (
  <ApolloReactComponents.Mutation<
    AuthSignInMutation,
    AuthSignInMutationVariables
  >
    mutation={AuthSignInDocument}
    {...props}
  />
)

export type AuthSignInProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    AuthSignInMutation,
    AuthSignInMutationVariables
  >
} &
  TChildProps
export function withAuthSignIn<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AuthSignInMutation,
    AuthSignInMutationVariables,
    AuthSignInProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AuthSignInMutation,
    AuthSignInMutationVariables,
    AuthSignInProps<TChildProps, TDataName>
  >(AuthSignInDocument, {
    alias: 'authSignIn',
    ...operationOptions,
  })
}

/**
 * __useAuthSignInMutation__
 *
 * To run a mutation, you first call `useAuthSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authSignInMutation, { data, loading, error }] = useAuthSignInMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAuthSignInMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AuthSignInMutation,
    AuthSignInMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AuthSignInMutation,
    AuthSignInMutationVariables
  >(AuthSignInDocument, baseOptions)
}
export type AuthSignInMutationHookResult = ReturnType<
  typeof useAuthSignInMutation
>
export type AuthSignInMutationResult = ApolloReactCommon.MutationResult<AuthSignInMutation>
export type AuthSignInMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AuthSignInMutation,
  AuthSignInMutationVariables
>
export const AuthSignOutDocument = gql`
  mutation AuthSignOut($refreshToken: String!) {
    Dashboard__AuthSignOut(refreshToken: $refreshToken)
  }
`
export type AuthSignOutMutationFn = ApolloReactCommon.MutationFunction<
  AuthSignOutMutation,
  AuthSignOutMutationVariables
>
export type AuthSignOutComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AuthSignOutMutation,
    AuthSignOutMutationVariables
  >,
  'mutation'
>

export const AuthSignOutComponent = (props: AuthSignOutComponentProps) => (
  <ApolloReactComponents.Mutation<
    AuthSignOutMutation,
    AuthSignOutMutationVariables
  >
    mutation={AuthSignOutDocument}
    {...props}
  />
)

export type AuthSignOutProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    AuthSignOutMutation,
    AuthSignOutMutationVariables
  >
} &
  TChildProps
export function withAuthSignOut<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AuthSignOutMutation,
    AuthSignOutMutationVariables,
    AuthSignOutProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AuthSignOutMutation,
    AuthSignOutMutationVariables,
    AuthSignOutProps<TChildProps, TDataName>
  >(AuthSignOutDocument, {
    alias: 'authSignOut',
    ...operationOptions,
  })
}

/**
 * __useAuthSignOutMutation__
 *
 * To run a mutation, you first call `useAuthSignOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthSignOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authSignOutMutation, { data, loading, error }] = useAuthSignOutMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useAuthSignOutMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AuthSignOutMutation,
    AuthSignOutMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AuthSignOutMutation,
    AuthSignOutMutationVariables
  >(AuthSignOutDocument, baseOptions)
}
export type AuthSignOutMutationHookResult = ReturnType<
  typeof useAuthSignOutMutation
>
export type AuthSignOutMutationResult = ApolloReactCommon.MutationResult<AuthSignOutMutation>
export type AuthSignOutMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AuthSignOutMutation,
  AuthSignOutMutationVariables
>
export const AuthSignUpDocument = gql`
  mutation AuthSignUp(
    $first_name: String!
    $last_name: String!
    $email: String!
    $password: String!
  ) {
    tokens: Dashboard__AuthSignUp(
      first_name: $first_name
      last_name: $last_name
      email: $email
      password: $password
    ) {
      access_token
      refresh_token
      user_id
      client_id
    }
  }
`
export type AuthSignUpMutationFn = ApolloReactCommon.MutationFunction<
  AuthSignUpMutation,
  AuthSignUpMutationVariables
>
export type AuthSignUpComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AuthSignUpMutation,
    AuthSignUpMutationVariables
  >,
  'mutation'
>

export const AuthSignUpComponent = (props: AuthSignUpComponentProps) => (
  <ApolloReactComponents.Mutation<
    AuthSignUpMutation,
    AuthSignUpMutationVariables
  >
    mutation={AuthSignUpDocument}
    {...props}
  />
)

export type AuthSignUpProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    AuthSignUpMutation,
    AuthSignUpMutationVariables
  >
} &
  TChildProps
export function withAuthSignUp<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AuthSignUpMutation,
    AuthSignUpMutationVariables,
    AuthSignUpProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AuthSignUpMutation,
    AuthSignUpMutationVariables,
    AuthSignUpProps<TChildProps, TDataName>
  >(AuthSignUpDocument, {
    alias: 'authSignUp',
    ...operationOptions,
  })
}

/**
 * __useAuthSignUpMutation__
 *
 * To run a mutation, you first call `useAuthSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authSignUpMutation, { data, loading, error }] = useAuthSignUpMutation({
 *   variables: {
 *      first_name: // value for 'first_name'
 *      last_name: // value for 'last_name'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAuthSignUpMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AuthSignUpMutation,
    AuthSignUpMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AuthSignUpMutation,
    AuthSignUpMutationVariables
  >(AuthSignUpDocument, baseOptions)
}
export type AuthSignUpMutationHookResult = ReturnType<
  typeof useAuthSignUpMutation
>
export type AuthSignUpMutationResult = ApolloReactCommon.MutationResult<AuthSignUpMutation>
export type AuthSignUpMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AuthSignUpMutation,
  AuthSignUpMutationVariables
>
export const ArchiveCreativeDocument = gql`
  mutation ArchiveCreative($ids: [MongoID!]!) {
    areArchived: Dashboard__ArchiveCreatives(ids: $ids)
  }
`
export type ArchiveCreativeMutationFn = ApolloReactCommon.MutationFunction<
  ArchiveCreativeMutation,
  ArchiveCreativeMutationVariables
>
export type ArchiveCreativeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ArchiveCreativeMutation,
    ArchiveCreativeMutationVariables
  >,
  'mutation'
>

export const ArchiveCreativeComponent = (
  props: ArchiveCreativeComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ArchiveCreativeMutation,
    ArchiveCreativeMutationVariables
  >
    mutation={ArchiveCreativeDocument}
    {...props}
  />
)

export type ArchiveCreativeProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    ArchiveCreativeMutation,
    ArchiveCreativeMutationVariables
  >
} &
  TChildProps
export function withArchiveCreative<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ArchiveCreativeMutation,
    ArchiveCreativeMutationVariables,
    ArchiveCreativeProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ArchiveCreativeMutation,
    ArchiveCreativeMutationVariables,
    ArchiveCreativeProps<TChildProps, TDataName>
  >(ArchiveCreativeDocument, {
    alias: 'archiveCreative',
    ...operationOptions,
  })
}

/**
 * __useArchiveCreativeMutation__
 *
 * To run a mutation, you first call `useArchiveCreativeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveCreativeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveCreativeMutation, { data, loading, error }] = useArchiveCreativeMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useArchiveCreativeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ArchiveCreativeMutation,
    ArchiveCreativeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ArchiveCreativeMutation,
    ArchiveCreativeMutationVariables
  >(ArchiveCreativeDocument, baseOptions)
}
export type ArchiveCreativeMutationHookResult = ReturnType<
  typeof useArchiveCreativeMutation
>
export type ArchiveCreativeMutationResult = ApolloReactCommon.MutationResult<ArchiveCreativeMutation>
export type ArchiveCreativeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ArchiveCreativeMutation,
  ArchiveCreativeMutationVariables
>
export const PreSignCreativeUploadDocument = gql`
  mutation PreSignCreativeUpload(
    $filename: String!
    $mimetype: String!
    $creative_type: EnumCreativeType!
  ) {
    Dashboard__PreSignCreativeUpload(
      filename: $filename
      mimetype: $mimetype
      creative_type: $creative_type
    ) {
      creative_id
      presigned_url
    }
  }
`
export type PreSignCreativeUploadMutationFn = ApolloReactCommon.MutationFunction<
  PreSignCreativeUploadMutation,
  PreSignCreativeUploadMutationVariables
>
export type PreSignCreativeUploadComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    PreSignCreativeUploadMutation,
    PreSignCreativeUploadMutationVariables
  >,
  'mutation'
>

export const PreSignCreativeUploadComponent = (
  props: PreSignCreativeUploadComponentProps
) => (
  <ApolloReactComponents.Mutation<
    PreSignCreativeUploadMutation,
    PreSignCreativeUploadMutationVariables
  >
    mutation={PreSignCreativeUploadDocument}
    {...props}
  />
)

export type PreSignCreativeUploadProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    PreSignCreativeUploadMutation,
    PreSignCreativeUploadMutationVariables
  >
} &
  TChildProps
export function withPreSignCreativeUpload<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    PreSignCreativeUploadMutation,
    PreSignCreativeUploadMutationVariables,
    PreSignCreativeUploadProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    PreSignCreativeUploadMutation,
    PreSignCreativeUploadMutationVariables,
    PreSignCreativeUploadProps<TChildProps, TDataName>
  >(PreSignCreativeUploadDocument, {
    alias: 'preSignCreativeUpload',
    ...operationOptions,
  })
}

/**
 * __usePreSignCreativeUploadMutation__
 *
 * To run a mutation, you first call `usePreSignCreativeUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePreSignCreativeUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [preSignCreativeUploadMutation, { data, loading, error }] = usePreSignCreativeUploadMutation({
 *   variables: {
 *      filename: // value for 'filename'
 *      mimetype: // value for 'mimetype'
 *      creative_type: // value for 'creative_type'
 *   },
 * });
 */
export function usePreSignCreativeUploadMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    PreSignCreativeUploadMutation,
    PreSignCreativeUploadMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    PreSignCreativeUploadMutation,
    PreSignCreativeUploadMutationVariables
  >(PreSignCreativeUploadDocument, baseOptions)
}
export type PreSignCreativeUploadMutationHookResult = ReturnType<
  typeof usePreSignCreativeUploadMutation
>
export type PreSignCreativeUploadMutationResult = ApolloReactCommon.MutationResult<PreSignCreativeUploadMutation>
export type PreSignCreativeUploadMutationOptions = ApolloReactCommon.BaseMutationOptions<
  PreSignCreativeUploadMutation,
  PreSignCreativeUploadMutationVariables
>
export const NotifyCreativeUploadCompletedDocument = gql`
  mutation NotifyCreativeUploadCompleted($creative_id: MongoID!) {
    Dashboard__NotifyCreativeUploadCompleted(creative_id: $creative_id)
  }
`
export type NotifyCreativeUploadCompletedMutationFn = ApolloReactCommon.MutationFunction<
  NotifyCreativeUploadCompletedMutation,
  NotifyCreativeUploadCompletedMutationVariables
>
export type NotifyCreativeUploadCompletedComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    NotifyCreativeUploadCompletedMutation,
    NotifyCreativeUploadCompletedMutationVariables
  >,
  'mutation'
>

export const NotifyCreativeUploadCompletedComponent = (
  props: NotifyCreativeUploadCompletedComponentProps
) => (
  <ApolloReactComponents.Mutation<
    NotifyCreativeUploadCompletedMutation,
    NotifyCreativeUploadCompletedMutationVariables
  >
    mutation={NotifyCreativeUploadCompletedDocument}
    {...props}
  />
)

export type NotifyCreativeUploadCompletedProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    NotifyCreativeUploadCompletedMutation,
    NotifyCreativeUploadCompletedMutationVariables
  >
} &
  TChildProps
export function withNotifyCreativeUploadCompleted<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    NotifyCreativeUploadCompletedMutation,
    NotifyCreativeUploadCompletedMutationVariables,
    NotifyCreativeUploadCompletedProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    NotifyCreativeUploadCompletedMutation,
    NotifyCreativeUploadCompletedMutationVariables,
    NotifyCreativeUploadCompletedProps<TChildProps, TDataName>
  >(NotifyCreativeUploadCompletedDocument, {
    alias: 'notifyCreativeUploadCompleted',
    ...operationOptions,
  })
}

/**
 * __useNotifyCreativeUploadCompletedMutation__
 *
 * To run a mutation, you first call `useNotifyCreativeUploadCompletedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotifyCreativeUploadCompletedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notifyCreativeUploadCompletedMutation, { data, loading, error }] = useNotifyCreativeUploadCompletedMutation({
 *   variables: {
 *      creative_id: // value for 'creative_id'
 *   },
 * });
 */
export function useNotifyCreativeUploadCompletedMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    NotifyCreativeUploadCompletedMutation,
    NotifyCreativeUploadCompletedMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    NotifyCreativeUploadCompletedMutation,
    NotifyCreativeUploadCompletedMutationVariables
  >(NotifyCreativeUploadCompletedDocument, baseOptions)
}
export type NotifyCreativeUploadCompletedMutationHookResult = ReturnType<
  typeof useNotifyCreativeUploadCompletedMutation
>
export type NotifyCreativeUploadCompletedMutationResult = ApolloReactCommon.MutationResult<NotifyCreativeUploadCompletedMutation>
export type NotifyCreativeUploadCompletedMutationOptions = ApolloReactCommon.BaseMutationOptions<
  NotifyCreativeUploadCompletedMutation,
  NotifyCreativeUploadCompletedMutationVariables
>
export const CreateCampaignDocument = gql`
  mutation CreateCampaign($input: CreateOneCampaignInput!) {
    campaign: Dashboard__CreateCampaign(record: $input) {
      _id: recordId
    }
  }
`
export type CreateCampaignMutationFn = ApolloReactCommon.MutationFunction<
  CreateCampaignMutation,
  CreateCampaignMutationVariables
>
export type CreateCampaignComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateCampaignMutation,
    CreateCampaignMutationVariables
  >,
  'mutation'
>

export const CreateCampaignComponent = (
  props: CreateCampaignComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateCampaignMutation,
    CreateCampaignMutationVariables
  >
    mutation={CreateCampaignDocument}
    {...props}
  />
)

export type CreateCampaignProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    CreateCampaignMutation,
    CreateCampaignMutationVariables
  >
} &
  TChildProps
export function withCreateCampaign<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateCampaignMutation,
    CreateCampaignMutationVariables,
    CreateCampaignProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateCampaignMutation,
    CreateCampaignMutationVariables,
    CreateCampaignProps<TChildProps, TDataName>
  >(CreateCampaignDocument, {
    alias: 'createCampaign',
    ...operationOptions,
  })
}

/**
 * __useCreateCampaignMutation__
 *
 * To run a mutation, you first call `useCreateCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCampaignMutation, { data, loading, error }] = useCreateCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCampaignMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCampaignMutation,
    CreateCampaignMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateCampaignMutation,
    CreateCampaignMutationVariables
  >(CreateCampaignDocument, baseOptions)
}
export type CreateCampaignMutationHookResult = ReturnType<
  typeof useCreateCampaignMutation
>
export type CreateCampaignMutationResult = ApolloReactCommon.MutationResult<CreateCampaignMutation>
export type CreateCampaignMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCampaignMutation,
  CreateCampaignMutationVariables
>
export const UpdateCampaignByIdDocument = gql`
  mutation UpdateCampaignById($_id: MongoID!, $input: UpdateOneCampaignInput!) {
    campaign: Dashboard__UpdateCampaign(filter: { _id: $_id }, record: $input) {
      _id: recordId
    }
  }
`
export type UpdateCampaignByIdMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCampaignByIdMutation,
  UpdateCampaignByIdMutationVariables
>
export type UpdateCampaignByIdComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateCampaignByIdMutation,
    UpdateCampaignByIdMutationVariables
  >,
  'mutation'
>

export const UpdateCampaignByIdComponent = (
  props: UpdateCampaignByIdComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateCampaignByIdMutation,
    UpdateCampaignByIdMutationVariables
  >
    mutation={UpdateCampaignByIdDocument}
    {...props}
  />
)

export type UpdateCampaignByIdProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    UpdateCampaignByIdMutation,
    UpdateCampaignByIdMutationVariables
  >
} &
  TChildProps
export function withUpdateCampaignById<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateCampaignByIdMutation,
    UpdateCampaignByIdMutationVariables,
    UpdateCampaignByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateCampaignByIdMutation,
    UpdateCampaignByIdMutationVariables,
    UpdateCampaignByIdProps<TChildProps, TDataName>
  >(UpdateCampaignByIdDocument, {
    alias: 'updateCampaignById',
    ...operationOptions,
  })
}

/**
 * __useUpdateCampaignByIdMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignByIdMutation, { data, loading, error }] = useUpdateCampaignByIdMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCampaignByIdMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCampaignByIdMutation,
    UpdateCampaignByIdMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateCampaignByIdMutation,
    UpdateCampaignByIdMutationVariables
  >(UpdateCampaignByIdDocument, baseOptions)
}
export type UpdateCampaignByIdMutationHookResult = ReturnType<
  typeof useUpdateCampaignByIdMutation
>
export type UpdateCampaignByIdMutationResult = ApolloReactCommon.MutationResult<UpdateCampaignByIdMutation>
export type UpdateCampaignByIdMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCampaignByIdMutation,
  UpdateCampaignByIdMutationVariables
>
export const UpdateCampaignActivationDocument = gql`
  mutation UpdateCampaignActivation($ids: [MongoID!]!, $is_active: Boolean!) {
    areUpdated: Dashboard__SetIsActiveCampaigns(
      ids: $ids
      is_active: $is_active
    )
  }
`
export type UpdateCampaignActivationMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCampaignActivationMutation,
  UpdateCampaignActivationMutationVariables
>
export type UpdateCampaignActivationComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateCampaignActivationMutation,
    UpdateCampaignActivationMutationVariables
  >,
  'mutation'
>

export const UpdateCampaignActivationComponent = (
  props: UpdateCampaignActivationComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateCampaignActivationMutation,
    UpdateCampaignActivationMutationVariables
  >
    mutation={UpdateCampaignActivationDocument}
    {...props}
  />
)

export type UpdateCampaignActivationProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    UpdateCampaignActivationMutation,
    UpdateCampaignActivationMutationVariables
  >
} &
  TChildProps
export function withUpdateCampaignActivation<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateCampaignActivationMutation,
    UpdateCampaignActivationMutationVariables,
    UpdateCampaignActivationProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateCampaignActivationMutation,
    UpdateCampaignActivationMutationVariables,
    UpdateCampaignActivationProps<TChildProps, TDataName>
  >(UpdateCampaignActivationDocument, {
    alias: 'updateCampaignActivation',
    ...operationOptions,
  })
}

/**
 * __useUpdateCampaignActivationMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignActivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignActivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignActivationMutation, { data, loading, error }] = useUpdateCampaignActivationMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      is_active: // value for 'is_active'
 *   },
 * });
 */
export function useUpdateCampaignActivationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCampaignActivationMutation,
    UpdateCampaignActivationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateCampaignActivationMutation,
    UpdateCampaignActivationMutationVariables
  >(UpdateCampaignActivationDocument, baseOptions)
}
export type UpdateCampaignActivationMutationHookResult = ReturnType<
  typeof useUpdateCampaignActivationMutation
>
export type UpdateCampaignActivationMutationResult = ApolloReactCommon.MutationResult<UpdateCampaignActivationMutation>
export type UpdateCampaignActivationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCampaignActivationMutation,
  UpdateCampaignActivationMutationVariables
>
export const ArchiveCampaignDocument = gql`
  mutation ArchiveCampaign($ids: [MongoID!]!) {
    areArchived: Dashboard__ArchiveCampaigns(ids: $ids)
  }
`
export type ArchiveCampaignMutationFn = ApolloReactCommon.MutationFunction<
  ArchiveCampaignMutation,
  ArchiveCampaignMutationVariables
>
export type ArchiveCampaignComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ArchiveCampaignMutation,
    ArchiveCampaignMutationVariables
  >,
  'mutation'
>

export const ArchiveCampaignComponent = (
  props: ArchiveCampaignComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ArchiveCampaignMutation,
    ArchiveCampaignMutationVariables
  >
    mutation={ArchiveCampaignDocument}
    {...props}
  />
)

export type ArchiveCampaignProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    ArchiveCampaignMutation,
    ArchiveCampaignMutationVariables
  >
} &
  TChildProps
export function withArchiveCampaign<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ArchiveCampaignMutation,
    ArchiveCampaignMutationVariables,
    ArchiveCampaignProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ArchiveCampaignMutation,
    ArchiveCampaignMutationVariables,
    ArchiveCampaignProps<TChildProps, TDataName>
  >(ArchiveCampaignDocument, {
    alias: 'archiveCampaign',
    ...operationOptions,
  })
}

/**
 * __useArchiveCampaignMutation__
 *
 * To run a mutation, you first call `useArchiveCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveCampaignMutation, { data, loading, error }] = useArchiveCampaignMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useArchiveCampaignMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ArchiveCampaignMutation,
    ArchiveCampaignMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ArchiveCampaignMutation,
    ArchiveCampaignMutationVariables
  >(ArchiveCampaignDocument, baseOptions)
}
export type ArchiveCampaignMutationHookResult = ReturnType<
  typeof useArchiveCampaignMutation
>
export type ArchiveCampaignMutationResult = ApolloReactCommon.MutationResult<ArchiveCampaignMutation>
export type ArchiveCampaignMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ArchiveCampaignMutation,
  ArchiveCampaignMutationVariables
>
export const UpdateCampaignAdGroupByIdDocument = gql`
  mutation UpdateCampaignAdGroupById(
    $_id: MongoID!
    $input: UpdateOneCampaignAdGroupInput!
  ) {
    campaignAdGroup: Dashboard__UpdateCampaignAdGroup(
      filter: { _id: $_id }
      record: $input
    ) {
      _id: recordId
    }
  }
`
export type UpdateCampaignAdGroupByIdMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCampaignAdGroupByIdMutation,
  UpdateCampaignAdGroupByIdMutationVariables
>
export type UpdateCampaignAdGroupByIdComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateCampaignAdGroupByIdMutation,
    UpdateCampaignAdGroupByIdMutationVariables
  >,
  'mutation'
>

export const UpdateCampaignAdGroupByIdComponent = (
  props: UpdateCampaignAdGroupByIdComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateCampaignAdGroupByIdMutation,
    UpdateCampaignAdGroupByIdMutationVariables
  >
    mutation={UpdateCampaignAdGroupByIdDocument}
    {...props}
  />
)

export type UpdateCampaignAdGroupByIdProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    UpdateCampaignAdGroupByIdMutation,
    UpdateCampaignAdGroupByIdMutationVariables
  >
} &
  TChildProps
export function withUpdateCampaignAdGroupById<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateCampaignAdGroupByIdMutation,
    UpdateCampaignAdGroupByIdMutationVariables,
    UpdateCampaignAdGroupByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateCampaignAdGroupByIdMutation,
    UpdateCampaignAdGroupByIdMutationVariables,
    UpdateCampaignAdGroupByIdProps<TChildProps, TDataName>
  >(UpdateCampaignAdGroupByIdDocument, {
    alias: 'updateCampaignAdGroupById',
    ...operationOptions,
  })
}

/**
 * __useUpdateCampaignAdGroupByIdMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignAdGroupByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignAdGroupByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignAdGroupByIdMutation, { data, loading, error }] = useUpdateCampaignAdGroupByIdMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCampaignAdGroupByIdMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCampaignAdGroupByIdMutation,
    UpdateCampaignAdGroupByIdMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateCampaignAdGroupByIdMutation,
    UpdateCampaignAdGroupByIdMutationVariables
  >(UpdateCampaignAdGroupByIdDocument, baseOptions)
}
export type UpdateCampaignAdGroupByIdMutationHookResult = ReturnType<
  typeof useUpdateCampaignAdGroupByIdMutation
>
export type UpdateCampaignAdGroupByIdMutationResult = ApolloReactCommon.MutationResult<UpdateCampaignAdGroupByIdMutation>
export type UpdateCampaignAdGroupByIdMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCampaignAdGroupByIdMutation,
  UpdateCampaignAdGroupByIdMutationVariables
>
export const UpdateCampaignAdGroupActivationDocument = gql`
  mutation UpdateCampaignAdGroupActivation(
    $ids: [MongoID!]!
    $is_active: Boolean!
  ) {
    areUpdated: Dashboard__SetIsActiveCampaignAdGroups(
      ids: $ids
      is_active: $is_active
    )
  }
`
export type UpdateCampaignAdGroupActivationMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCampaignAdGroupActivationMutation,
  UpdateCampaignAdGroupActivationMutationVariables
>
export type UpdateCampaignAdGroupActivationComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateCampaignAdGroupActivationMutation,
    UpdateCampaignAdGroupActivationMutationVariables
  >,
  'mutation'
>

export const UpdateCampaignAdGroupActivationComponent = (
  props: UpdateCampaignAdGroupActivationComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateCampaignAdGroupActivationMutation,
    UpdateCampaignAdGroupActivationMutationVariables
  >
    mutation={UpdateCampaignAdGroupActivationDocument}
    {...props}
  />
)

export type UpdateCampaignAdGroupActivationProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    UpdateCampaignAdGroupActivationMutation,
    UpdateCampaignAdGroupActivationMutationVariables
  >
} &
  TChildProps
export function withUpdateCampaignAdGroupActivation<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateCampaignAdGroupActivationMutation,
    UpdateCampaignAdGroupActivationMutationVariables,
    UpdateCampaignAdGroupActivationProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateCampaignAdGroupActivationMutation,
    UpdateCampaignAdGroupActivationMutationVariables,
    UpdateCampaignAdGroupActivationProps<TChildProps, TDataName>
  >(UpdateCampaignAdGroupActivationDocument, {
    alias: 'updateCampaignAdGroupActivation',
    ...operationOptions,
  })
}

/**
 * __useUpdateCampaignAdGroupActivationMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignAdGroupActivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignAdGroupActivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignAdGroupActivationMutation, { data, loading, error }] = useUpdateCampaignAdGroupActivationMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      is_active: // value for 'is_active'
 *   },
 * });
 */
export function useUpdateCampaignAdGroupActivationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCampaignAdGroupActivationMutation,
    UpdateCampaignAdGroupActivationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateCampaignAdGroupActivationMutation,
    UpdateCampaignAdGroupActivationMutationVariables
  >(UpdateCampaignAdGroupActivationDocument, baseOptions)
}
export type UpdateCampaignAdGroupActivationMutationHookResult = ReturnType<
  typeof useUpdateCampaignAdGroupActivationMutation
>
export type UpdateCampaignAdGroupActivationMutationResult = ApolloReactCommon.MutationResult<UpdateCampaignAdGroupActivationMutation>
export type UpdateCampaignAdGroupActivationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCampaignAdGroupActivationMutation,
  UpdateCampaignAdGroupActivationMutationVariables
>
export const ArchiveCampaignAdGroupDocument = gql`
  mutation ArchiveCampaignAdGroup($ids: [MongoID!]!) {
    areArchived: Dashboard__ArchiveCampaignAdGroups(ids: $ids)
  }
`
export type ArchiveCampaignAdGroupMutationFn = ApolloReactCommon.MutationFunction<
  ArchiveCampaignAdGroupMutation,
  ArchiveCampaignAdGroupMutationVariables
>
export type ArchiveCampaignAdGroupComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ArchiveCampaignAdGroupMutation,
    ArchiveCampaignAdGroupMutationVariables
  >,
  'mutation'
>

export const ArchiveCampaignAdGroupComponent = (
  props: ArchiveCampaignAdGroupComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ArchiveCampaignAdGroupMutation,
    ArchiveCampaignAdGroupMutationVariables
  >
    mutation={ArchiveCampaignAdGroupDocument}
    {...props}
  />
)

export type ArchiveCampaignAdGroupProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    ArchiveCampaignAdGroupMutation,
    ArchiveCampaignAdGroupMutationVariables
  >
} &
  TChildProps
export function withArchiveCampaignAdGroup<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ArchiveCampaignAdGroupMutation,
    ArchiveCampaignAdGroupMutationVariables,
    ArchiveCampaignAdGroupProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ArchiveCampaignAdGroupMutation,
    ArchiveCampaignAdGroupMutationVariables,
    ArchiveCampaignAdGroupProps<TChildProps, TDataName>
  >(ArchiveCampaignAdGroupDocument, {
    alias: 'archiveCampaignAdGroup',
    ...operationOptions,
  })
}

/**
 * __useArchiveCampaignAdGroupMutation__
 *
 * To run a mutation, you first call `useArchiveCampaignAdGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveCampaignAdGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveCampaignAdGroupMutation, { data, loading, error }] = useArchiveCampaignAdGroupMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useArchiveCampaignAdGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ArchiveCampaignAdGroupMutation,
    ArchiveCampaignAdGroupMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ArchiveCampaignAdGroupMutation,
    ArchiveCampaignAdGroupMutationVariables
  >(ArchiveCampaignAdGroupDocument, baseOptions)
}
export type ArchiveCampaignAdGroupMutationHookResult = ReturnType<
  typeof useArchiveCampaignAdGroupMutation
>
export type ArchiveCampaignAdGroupMutationResult = ApolloReactCommon.MutationResult<ArchiveCampaignAdGroupMutation>
export type ArchiveCampaignAdGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ArchiveCampaignAdGroupMutation,
  ArchiveCampaignAdGroupMutationVariables
>
export const CreateCampaignAdGroupDocument = gql`
  mutation CreateCampaignAdGroup($input: CreateOneCampaignAdGroupInput!) {
    campaignAdGroup: Dashboard__CreateCampaignAdGroup(record: $input) {
      _id: recordId
    }
  }
`
export type CreateCampaignAdGroupMutationFn = ApolloReactCommon.MutationFunction<
  CreateCampaignAdGroupMutation,
  CreateCampaignAdGroupMutationVariables
>
export type CreateCampaignAdGroupComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateCampaignAdGroupMutation,
    CreateCampaignAdGroupMutationVariables
  >,
  'mutation'
>

export const CreateCampaignAdGroupComponent = (
  props: CreateCampaignAdGroupComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateCampaignAdGroupMutation,
    CreateCampaignAdGroupMutationVariables
  >
    mutation={CreateCampaignAdGroupDocument}
    {...props}
  />
)

export type CreateCampaignAdGroupProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    CreateCampaignAdGroupMutation,
    CreateCampaignAdGroupMutationVariables
  >
} &
  TChildProps
export function withCreateCampaignAdGroup<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateCampaignAdGroupMutation,
    CreateCampaignAdGroupMutationVariables,
    CreateCampaignAdGroupProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateCampaignAdGroupMutation,
    CreateCampaignAdGroupMutationVariables,
    CreateCampaignAdGroupProps<TChildProps, TDataName>
  >(CreateCampaignAdGroupDocument, {
    alias: 'createCampaignAdGroup',
    ...operationOptions,
  })
}

/**
 * __useCreateCampaignAdGroupMutation__
 *
 * To run a mutation, you first call `useCreateCampaignAdGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCampaignAdGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCampaignAdGroupMutation, { data, loading, error }] = useCreateCampaignAdGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCampaignAdGroupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCampaignAdGroupMutation,
    CreateCampaignAdGroupMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateCampaignAdGroupMutation,
    CreateCampaignAdGroupMutationVariables
  >(CreateCampaignAdGroupDocument, baseOptions)
}
export type CreateCampaignAdGroupMutationHookResult = ReturnType<
  typeof useCreateCampaignAdGroupMutation
>
export type CreateCampaignAdGroupMutationResult = ApolloReactCommon.MutationResult<CreateCampaignAdGroupMutation>
export type CreateCampaignAdGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCampaignAdGroupMutation,
  CreateCampaignAdGroupMutationVariables
>
export const CreateNetworkDocument = gql`
  mutation CreateNetwork($input: CreateOneNetworkInput!) {
    network: Dashboard__CreateNetwork(record: $input) {
      _id: recordId
    }
  }
`
export type CreateNetworkMutationFn = ApolloReactCommon.MutationFunction<
  CreateNetworkMutation,
  CreateNetworkMutationVariables
>
export type CreateNetworkComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateNetworkMutation,
    CreateNetworkMutationVariables
  >,
  'mutation'
>

export const CreateNetworkComponent = (props: CreateNetworkComponentProps) => (
  <ApolloReactComponents.Mutation<
    CreateNetworkMutation,
    CreateNetworkMutationVariables
  >
    mutation={CreateNetworkDocument}
    {...props}
  />
)

export type CreateNetworkProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    CreateNetworkMutation,
    CreateNetworkMutationVariables
  >
} &
  TChildProps
export function withCreateNetwork<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateNetworkMutation,
    CreateNetworkMutationVariables,
    CreateNetworkProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateNetworkMutation,
    CreateNetworkMutationVariables,
    CreateNetworkProps<TChildProps, TDataName>
  >(CreateNetworkDocument, {
    alias: 'createNetwork',
    ...operationOptions,
  })
}

/**
 * __useCreateNetworkMutation__
 *
 * To run a mutation, you first call `useCreateNetworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNetworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNetworkMutation, { data, loading, error }] = useCreateNetworkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNetworkMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateNetworkMutation,
    CreateNetworkMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateNetworkMutation,
    CreateNetworkMutationVariables
  >(CreateNetworkDocument, baseOptions)
}
export type CreateNetworkMutationHookResult = ReturnType<
  typeof useCreateNetworkMutation
>
export type CreateNetworkMutationResult = ApolloReactCommon.MutationResult<CreateNetworkMutation>
export type CreateNetworkMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateNetworkMutation,
  CreateNetworkMutationVariables
>
export const UpdateNetworkByIdDocument = gql`
  mutation UpdateNetworkById($_id: MongoID!, $input: UpdateOneNetworkInput!) {
    network: Dashboard__UpdateNetwork(filter: { _id: $_id }, record: $input) {
      _id: recordId
    }
  }
`
export type UpdateNetworkByIdMutationFn = ApolloReactCommon.MutationFunction<
  UpdateNetworkByIdMutation,
  UpdateNetworkByIdMutationVariables
>
export type UpdateNetworkByIdComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateNetworkByIdMutation,
    UpdateNetworkByIdMutationVariables
  >,
  'mutation'
>

export const UpdateNetworkByIdComponent = (
  props: UpdateNetworkByIdComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateNetworkByIdMutation,
    UpdateNetworkByIdMutationVariables
  >
    mutation={UpdateNetworkByIdDocument}
    {...props}
  />
)

export type UpdateNetworkByIdProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    UpdateNetworkByIdMutation,
    UpdateNetworkByIdMutationVariables
  >
} &
  TChildProps
export function withUpdateNetworkById<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateNetworkByIdMutation,
    UpdateNetworkByIdMutationVariables,
    UpdateNetworkByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateNetworkByIdMutation,
    UpdateNetworkByIdMutationVariables,
    UpdateNetworkByIdProps<TChildProps, TDataName>
  >(UpdateNetworkByIdDocument, {
    alias: 'updateNetworkById',
    ...operationOptions,
  })
}

/**
 * __useUpdateNetworkByIdMutation__
 *
 * To run a mutation, you first call `useUpdateNetworkByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNetworkByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNetworkByIdMutation, { data, loading, error }] = useUpdateNetworkByIdMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNetworkByIdMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateNetworkByIdMutation,
    UpdateNetworkByIdMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateNetworkByIdMutation,
    UpdateNetworkByIdMutationVariables
  >(UpdateNetworkByIdDocument, baseOptions)
}
export type UpdateNetworkByIdMutationHookResult = ReturnType<
  typeof useUpdateNetworkByIdMutation
>
export type UpdateNetworkByIdMutationResult = ApolloReactCommon.MutationResult<UpdateNetworkByIdMutation>
export type UpdateNetworkByIdMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateNetworkByIdMutation,
  UpdateNetworkByIdMutationVariables
>
export const UpdateNetworkActivationDocument = gql`
  mutation UpdateNetworkActivation($ids: [MongoID!]!, $is_active: Boolean!) {
    areUpdated: Dashboard__SetIsActiveNetworks(ids: $ids, is_active: $is_active)
  }
`
export type UpdateNetworkActivationMutationFn = ApolloReactCommon.MutationFunction<
  UpdateNetworkActivationMutation,
  UpdateNetworkActivationMutationVariables
>
export type UpdateNetworkActivationComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateNetworkActivationMutation,
    UpdateNetworkActivationMutationVariables
  >,
  'mutation'
>

export const UpdateNetworkActivationComponent = (
  props: UpdateNetworkActivationComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateNetworkActivationMutation,
    UpdateNetworkActivationMutationVariables
  >
    mutation={UpdateNetworkActivationDocument}
    {...props}
  />
)

export type UpdateNetworkActivationProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    UpdateNetworkActivationMutation,
    UpdateNetworkActivationMutationVariables
  >
} &
  TChildProps
export function withUpdateNetworkActivation<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateNetworkActivationMutation,
    UpdateNetworkActivationMutationVariables,
    UpdateNetworkActivationProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateNetworkActivationMutation,
    UpdateNetworkActivationMutationVariables,
    UpdateNetworkActivationProps<TChildProps, TDataName>
  >(UpdateNetworkActivationDocument, {
    alias: 'updateNetworkActivation',
    ...operationOptions,
  })
}

/**
 * __useUpdateNetworkActivationMutation__
 *
 * To run a mutation, you first call `useUpdateNetworkActivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNetworkActivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNetworkActivationMutation, { data, loading, error }] = useUpdateNetworkActivationMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      is_active: // value for 'is_active'
 *   },
 * });
 */
export function useUpdateNetworkActivationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateNetworkActivationMutation,
    UpdateNetworkActivationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateNetworkActivationMutation,
    UpdateNetworkActivationMutationVariables
  >(UpdateNetworkActivationDocument, baseOptions)
}
export type UpdateNetworkActivationMutationHookResult = ReturnType<
  typeof useUpdateNetworkActivationMutation
>
export type UpdateNetworkActivationMutationResult = ApolloReactCommon.MutationResult<UpdateNetworkActivationMutation>
export type UpdateNetworkActivationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateNetworkActivationMutation,
  UpdateNetworkActivationMutationVariables
>
export const ArchiveNetworkDocument = gql`
  mutation ArchiveNetwork($ids: [MongoID!]!) {
    areArchived: Dashboard__ArchiveNetworks(ids: $ids)
  }
`
export type ArchiveNetworkMutationFn = ApolloReactCommon.MutationFunction<
  ArchiveNetworkMutation,
  ArchiveNetworkMutationVariables
>
export type ArchiveNetworkComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ArchiveNetworkMutation,
    ArchiveNetworkMutationVariables
  >,
  'mutation'
>

export const ArchiveNetworkComponent = (
  props: ArchiveNetworkComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ArchiveNetworkMutation,
    ArchiveNetworkMutationVariables
  >
    mutation={ArchiveNetworkDocument}
    {...props}
  />
)

export type ArchiveNetworkProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    ArchiveNetworkMutation,
    ArchiveNetworkMutationVariables
  >
} &
  TChildProps
export function withArchiveNetwork<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ArchiveNetworkMutation,
    ArchiveNetworkMutationVariables,
    ArchiveNetworkProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ArchiveNetworkMutation,
    ArchiveNetworkMutationVariables,
    ArchiveNetworkProps<TChildProps, TDataName>
  >(ArchiveNetworkDocument, {
    alias: 'archiveNetwork',
    ...operationOptions,
  })
}

/**
 * __useArchiveNetworkMutation__
 *
 * To run a mutation, you first call `useArchiveNetworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveNetworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveNetworkMutation, { data, loading, error }] = useArchiveNetworkMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useArchiveNetworkMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ArchiveNetworkMutation,
    ArchiveNetworkMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ArchiveNetworkMutation,
    ArchiveNetworkMutationVariables
  >(ArchiveNetworkDocument, baseOptions)
}
export type ArchiveNetworkMutationHookResult = ReturnType<
  typeof useArchiveNetworkMutation
>
export type ArchiveNetworkMutationResult = ApolloReactCommon.MutationResult<ArchiveNetworkMutation>
export type ArchiveNetworkMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ArchiveNetworkMutation,
  ArchiveNetworkMutationVariables
>
export const BindPlayerDocument = gql`
  mutation BindPlayer($token: String) {
    player: Dashboard__PlayerBind(token: $token) {
      _id
      name
    }
  }
`
export type BindPlayerMutationFn = ApolloReactCommon.MutationFunction<
  BindPlayerMutation,
  BindPlayerMutationVariables
>
export type BindPlayerComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    BindPlayerMutation,
    BindPlayerMutationVariables
  >,
  'mutation'
>

export const BindPlayerComponent = (props: BindPlayerComponentProps) => (
  <ApolloReactComponents.Mutation<
    BindPlayerMutation,
    BindPlayerMutationVariables
  >
    mutation={BindPlayerDocument}
    {...props}
  />
)

export type BindPlayerProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    BindPlayerMutation,
    BindPlayerMutationVariables
  >
} &
  TChildProps
export function withBindPlayer<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    BindPlayerMutation,
    BindPlayerMutationVariables,
    BindPlayerProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    BindPlayerMutation,
    BindPlayerMutationVariables,
    BindPlayerProps<TChildProps, TDataName>
  >(BindPlayerDocument, {
    alias: 'bindPlayer',
    ...operationOptions,
  })
}

/**
 * __useBindPlayerMutation__
 *
 * To run a mutation, you first call `useBindPlayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBindPlayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bindPlayerMutation, { data, loading, error }] = useBindPlayerMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useBindPlayerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    BindPlayerMutation,
    BindPlayerMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    BindPlayerMutation,
    BindPlayerMutationVariables
  >(BindPlayerDocument, baseOptions)
}
export type BindPlayerMutationHookResult = ReturnType<
  typeof useBindPlayerMutation
>
export type BindPlayerMutationResult = ApolloReactCommon.MutationResult<BindPlayerMutation>
export type BindPlayerMutationOptions = ApolloReactCommon.BaseMutationOptions<
  BindPlayerMutation,
  BindPlayerMutationVariables
>
export const UpdatePlayerByIdDocument = gql`
  mutation UpdatePlayerById(
    $skip: Int
    $_id: MongoID!
    $input: UpdateOnePlayerInput!
  ) {
    player: Dashboard__UpdatePlayer(
      skip: $skip
      filter: { _id: $_id }
      record: $input
    ) {
      _id: recordId
    }
  }
`
export type UpdatePlayerByIdMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePlayerByIdMutation,
  UpdatePlayerByIdMutationVariables
>
export type UpdatePlayerByIdComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdatePlayerByIdMutation,
    UpdatePlayerByIdMutationVariables
  >,
  'mutation'
>

export const UpdatePlayerByIdComponent = (
  props: UpdatePlayerByIdComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdatePlayerByIdMutation,
    UpdatePlayerByIdMutationVariables
  >
    mutation={UpdatePlayerByIdDocument}
    {...props}
  />
)

export type UpdatePlayerByIdProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    UpdatePlayerByIdMutation,
    UpdatePlayerByIdMutationVariables
  >
} &
  TChildProps
export function withUpdatePlayerById<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdatePlayerByIdMutation,
    UpdatePlayerByIdMutationVariables,
    UpdatePlayerByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdatePlayerByIdMutation,
    UpdatePlayerByIdMutationVariables,
    UpdatePlayerByIdProps<TChildProps, TDataName>
  >(UpdatePlayerByIdDocument, {
    alias: 'updatePlayerById',
    ...operationOptions,
  })
}

/**
 * __useUpdatePlayerByIdMutation__
 *
 * To run a mutation, you first call `useUpdatePlayerByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlayerByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlayerByIdMutation, { data, loading, error }] = useUpdatePlayerByIdMutation({
 *   variables: {
 *      skip: // value for 'skip'
 *      _id: // value for '_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlayerByIdMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePlayerByIdMutation,
    UpdatePlayerByIdMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdatePlayerByIdMutation,
    UpdatePlayerByIdMutationVariables
  >(UpdatePlayerByIdDocument, baseOptions)
}
export type UpdatePlayerByIdMutationHookResult = ReturnType<
  typeof useUpdatePlayerByIdMutation
>
export type UpdatePlayerByIdMutationResult = ApolloReactCommon.MutationResult<UpdatePlayerByIdMutation>
export type UpdatePlayerByIdMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePlayerByIdMutation,
  UpdatePlayerByIdMutationVariables
>
export const UpdatePlayerActivationDocument = gql`
  mutation UpdatePlayerActivation($ids: [MongoID!]!, $is_active: Boolean!) {
    areUpdated: Dashboard__SetIsActivePlayers(ids: $ids, is_active: $is_active)
  }
`
export type UpdatePlayerActivationMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePlayerActivationMutation,
  UpdatePlayerActivationMutationVariables
>
export type UpdatePlayerActivationComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdatePlayerActivationMutation,
    UpdatePlayerActivationMutationVariables
  >,
  'mutation'
>

export const UpdatePlayerActivationComponent = (
  props: UpdatePlayerActivationComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdatePlayerActivationMutation,
    UpdatePlayerActivationMutationVariables
  >
    mutation={UpdatePlayerActivationDocument}
    {...props}
  />
)

export type UpdatePlayerActivationProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    UpdatePlayerActivationMutation,
    UpdatePlayerActivationMutationVariables
  >
} &
  TChildProps
export function withUpdatePlayerActivation<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdatePlayerActivationMutation,
    UpdatePlayerActivationMutationVariables,
    UpdatePlayerActivationProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdatePlayerActivationMutation,
    UpdatePlayerActivationMutationVariables,
    UpdatePlayerActivationProps<TChildProps, TDataName>
  >(UpdatePlayerActivationDocument, {
    alias: 'updatePlayerActivation',
    ...operationOptions,
  })
}

/**
 * __useUpdatePlayerActivationMutation__
 *
 * To run a mutation, you first call `useUpdatePlayerActivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlayerActivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlayerActivationMutation, { data, loading, error }] = useUpdatePlayerActivationMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      is_active: // value for 'is_active'
 *   },
 * });
 */
export function useUpdatePlayerActivationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePlayerActivationMutation,
    UpdatePlayerActivationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdatePlayerActivationMutation,
    UpdatePlayerActivationMutationVariables
  >(UpdatePlayerActivationDocument, baseOptions)
}
export type UpdatePlayerActivationMutationHookResult = ReturnType<
  typeof useUpdatePlayerActivationMutation
>
export type UpdatePlayerActivationMutationResult = ApolloReactCommon.MutationResult<UpdatePlayerActivationMutation>
export type UpdatePlayerActivationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePlayerActivationMutation,
  UpdatePlayerActivationMutationVariables
>
export const ArchivePlayerDocument = gql`
  mutation ArchivePlayer($ids: [MongoID!]!) {
    areArchived: Dashboard__ArchivePlayers(ids: $ids)
  }
`
export type ArchivePlayerMutationFn = ApolloReactCommon.MutationFunction<
  ArchivePlayerMutation,
  ArchivePlayerMutationVariables
>
export type ArchivePlayerComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ArchivePlayerMutation,
    ArchivePlayerMutationVariables
  >,
  'mutation'
>

export const ArchivePlayerComponent = (props: ArchivePlayerComponentProps) => (
  <ApolloReactComponents.Mutation<
    ArchivePlayerMutation,
    ArchivePlayerMutationVariables
  >
    mutation={ArchivePlayerDocument}
    {...props}
  />
)

export type ArchivePlayerProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    ArchivePlayerMutation,
    ArchivePlayerMutationVariables
  >
} &
  TChildProps
export function withArchivePlayer<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ArchivePlayerMutation,
    ArchivePlayerMutationVariables,
    ArchivePlayerProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ArchivePlayerMutation,
    ArchivePlayerMutationVariables,
    ArchivePlayerProps<TChildProps, TDataName>
  >(ArchivePlayerDocument, {
    alias: 'archivePlayer',
    ...operationOptions,
  })
}

/**
 * __useArchivePlayerMutation__
 *
 * To run a mutation, you first call `useArchivePlayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchivePlayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archivePlayerMutation, { data, loading, error }] = useArchivePlayerMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useArchivePlayerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ArchivePlayerMutation,
    ArchivePlayerMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ArchivePlayerMutation,
    ArchivePlayerMutationVariables
  >(ArchivePlayerDocument, baseOptions)
}
export type ArchivePlayerMutationHookResult = ReturnType<
  typeof useArchivePlayerMutation
>
export type ArchivePlayerMutationResult = ApolloReactCommon.MutationResult<ArchivePlayerMutation>
export type ArchivePlayerMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ArchivePlayerMutation,
  ArchivePlayerMutationVariables
>
export const SetupWorkspaceDocument = gql`
  mutation SetupWorkspace(
    $name: String
    $platform_type: EnumWorkspacePlatform_type
  ) {
    workspace: Dashboard__SetupWorkspace(
      name: $name
      platform_type: $platform_type
    ) {
      _id
      platform_type
      name
    }
  }
`
export type SetupWorkspaceMutationFn = ApolloReactCommon.MutationFunction<
  SetupWorkspaceMutation,
  SetupWorkspaceMutationVariables
>
export type SetupWorkspaceComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    SetupWorkspaceMutation,
    SetupWorkspaceMutationVariables
  >,
  'mutation'
>

export const SetupWorkspaceComponent = (
  props: SetupWorkspaceComponentProps
) => (
  <ApolloReactComponents.Mutation<
    SetupWorkspaceMutation,
    SetupWorkspaceMutationVariables
  >
    mutation={SetupWorkspaceDocument}
    {...props}
  />
)

export type SetupWorkspaceProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    SetupWorkspaceMutation,
    SetupWorkspaceMutationVariables
  >
} &
  TChildProps
export function withSetupWorkspace<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SetupWorkspaceMutation,
    SetupWorkspaceMutationVariables,
    SetupWorkspaceProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    SetupWorkspaceMutation,
    SetupWorkspaceMutationVariables,
    SetupWorkspaceProps<TChildProps, TDataName>
  >(SetupWorkspaceDocument, {
    alias: 'setupWorkspace',
    ...operationOptions,
  })
}

/**
 * __useSetupWorkspaceMutation__
 *
 * To run a mutation, you first call `useSetupWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetupWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setupWorkspaceMutation, { data, loading, error }] = useSetupWorkspaceMutation({
 *   variables: {
 *      name: // value for 'name'
 *      platform_type: // value for 'platform_type'
 *   },
 * });
 */
export function useSetupWorkspaceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetupWorkspaceMutation,
    SetupWorkspaceMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SetupWorkspaceMutation,
    SetupWorkspaceMutationVariables
  >(SetupWorkspaceDocument, baseOptions)
}
export type SetupWorkspaceMutationHookResult = ReturnType<
  typeof useSetupWorkspaceMutation
>
export type SetupWorkspaceMutationResult = ApolloReactCommon.MutationResult<SetupWorkspaceMutation>
export type SetupWorkspaceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetupWorkspaceMutation,
  SetupWorkspaceMutationVariables
>
export const UpdateWorkspaceByIdDocument = gql`
  mutation UpdateWorkspaceById(
    $_id: String!
    $details: Dashboard__WorkspaceUpdateDetailsInput!
  ) {
    workspace: Dashboard__UpdateWorkspaceDetails(_id: $_id, details: $details) {
      _id
      platform_type
      name
    }
  }
`
export type UpdateWorkspaceByIdMutationFn = ApolloReactCommon.MutationFunction<
  UpdateWorkspaceByIdMutation,
  UpdateWorkspaceByIdMutationVariables
>
export type UpdateWorkspaceByIdComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateWorkspaceByIdMutation,
    UpdateWorkspaceByIdMutationVariables
  >,
  'mutation'
>

export const UpdateWorkspaceByIdComponent = (
  props: UpdateWorkspaceByIdComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateWorkspaceByIdMutation,
    UpdateWorkspaceByIdMutationVariables
  >
    mutation={UpdateWorkspaceByIdDocument}
    {...props}
  />
)

export type UpdateWorkspaceByIdProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    UpdateWorkspaceByIdMutation,
    UpdateWorkspaceByIdMutationVariables
  >
} &
  TChildProps
export function withUpdateWorkspaceById<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateWorkspaceByIdMutation,
    UpdateWorkspaceByIdMutationVariables,
    UpdateWorkspaceByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateWorkspaceByIdMutation,
    UpdateWorkspaceByIdMutationVariables,
    UpdateWorkspaceByIdProps<TChildProps, TDataName>
  >(UpdateWorkspaceByIdDocument, {
    alias: 'updateWorkspaceById',
    ...operationOptions,
  })
}

/**
 * __useUpdateWorkspaceByIdMutation__
 *
 * To run a mutation, you first call `useUpdateWorkspaceByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkspaceByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkspaceByIdMutation, { data, loading, error }] = useUpdateWorkspaceByIdMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      details: // value for 'details'
 *   },
 * });
 */
export function useUpdateWorkspaceByIdMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateWorkspaceByIdMutation,
    UpdateWorkspaceByIdMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateWorkspaceByIdMutation,
    UpdateWorkspaceByIdMutationVariables
  >(UpdateWorkspaceByIdDocument, baseOptions)
}
export type UpdateWorkspaceByIdMutationHookResult = ReturnType<
  typeof useUpdateWorkspaceByIdMutation
>
export type UpdateWorkspaceByIdMutationResult = ApolloReactCommon.MutationResult<UpdateWorkspaceByIdMutation>
export type UpdateWorkspaceByIdMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateWorkspaceByIdMutation,
  UpdateWorkspaceByIdMutationVariables
>
export const AccountEmailAddDocument = gql`
  mutation AccountEmailAdd($email: String!) {
    added: Dashboard__AccountEmailAdd(email: $email)
  }
`
export type AccountEmailAddMutationFn = ApolloReactCommon.MutationFunction<
  AccountEmailAddMutation,
  AccountEmailAddMutationVariables
>
export type AccountEmailAddComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AccountEmailAddMutation,
    AccountEmailAddMutationVariables
  >,
  'mutation'
>

export const AccountEmailAddComponent = (
  props: AccountEmailAddComponentProps
) => (
  <ApolloReactComponents.Mutation<
    AccountEmailAddMutation,
    AccountEmailAddMutationVariables
  >
    mutation={AccountEmailAddDocument}
    {...props}
  />
)

export type AccountEmailAddProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    AccountEmailAddMutation,
    AccountEmailAddMutationVariables
  >
} &
  TChildProps
export function withAccountEmailAdd<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AccountEmailAddMutation,
    AccountEmailAddMutationVariables,
    AccountEmailAddProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AccountEmailAddMutation,
    AccountEmailAddMutationVariables,
    AccountEmailAddProps<TChildProps, TDataName>
  >(AccountEmailAddDocument, {
    alias: 'accountEmailAdd',
    ...operationOptions,
  })
}

/**
 * __useAccountEmailAddMutation__
 *
 * To run a mutation, you first call `useAccountEmailAddMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountEmailAddMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountEmailAddMutation, { data, loading, error }] = useAccountEmailAddMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAccountEmailAddMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AccountEmailAddMutation,
    AccountEmailAddMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AccountEmailAddMutation,
    AccountEmailAddMutationVariables
  >(AccountEmailAddDocument, baseOptions)
}
export type AccountEmailAddMutationHookResult = ReturnType<
  typeof useAccountEmailAddMutation
>
export type AccountEmailAddMutationResult = ApolloReactCommon.MutationResult<AccountEmailAddMutation>
export type AccountEmailAddMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AccountEmailAddMutation,
  AccountEmailAddMutationVariables
>
export const AccountEmailVerifyCodeDocument = gql`
  mutation AccountEmailVerifyCode($email: String!, $code: String!) {
    Dashboard__AccountEmailVerifyCode(email: $email, code: $code)
  }
`
export type AccountEmailVerifyCodeMutationFn = ApolloReactCommon.MutationFunction<
  AccountEmailVerifyCodeMutation,
  AccountEmailVerifyCodeMutationVariables
>
export type AccountEmailVerifyCodeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AccountEmailVerifyCodeMutation,
    AccountEmailVerifyCodeMutationVariables
  >,
  'mutation'
>

export const AccountEmailVerifyCodeComponent = (
  props: AccountEmailVerifyCodeComponentProps
) => (
  <ApolloReactComponents.Mutation<
    AccountEmailVerifyCodeMutation,
    AccountEmailVerifyCodeMutationVariables
  >
    mutation={AccountEmailVerifyCodeDocument}
    {...props}
  />
)

export type AccountEmailVerifyCodeProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    AccountEmailVerifyCodeMutation,
    AccountEmailVerifyCodeMutationVariables
  >
} &
  TChildProps
export function withAccountEmailVerifyCode<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AccountEmailVerifyCodeMutation,
    AccountEmailVerifyCodeMutationVariables,
    AccountEmailVerifyCodeProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AccountEmailVerifyCodeMutation,
    AccountEmailVerifyCodeMutationVariables,
    AccountEmailVerifyCodeProps<TChildProps, TDataName>
  >(AccountEmailVerifyCodeDocument, {
    alias: 'accountEmailVerifyCode',
    ...operationOptions,
  })
}

/**
 * __useAccountEmailVerifyCodeMutation__
 *
 * To run a mutation, you first call `useAccountEmailVerifyCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountEmailVerifyCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountEmailVerifyCodeMutation, { data, loading, error }] = useAccountEmailVerifyCodeMutation({
 *   variables: {
 *      email: // value for 'email'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useAccountEmailVerifyCodeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AccountEmailVerifyCodeMutation,
    AccountEmailVerifyCodeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AccountEmailVerifyCodeMutation,
    AccountEmailVerifyCodeMutationVariables
  >(AccountEmailVerifyCodeDocument, baseOptions)
}
export type AccountEmailVerifyCodeMutationHookResult = ReturnType<
  typeof useAccountEmailVerifyCodeMutation
>
export type AccountEmailVerifyCodeMutationResult = ApolloReactCommon.MutationResult<AccountEmailVerifyCodeMutation>
export type AccountEmailVerifyCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AccountEmailVerifyCodeMutation,
  AccountEmailVerifyCodeMutationVariables
>
export const AccountEmailSendVerificationCodeDocument = gql`
  mutation AccountEmailSendVerificationCode($email: String!) {
    Dashboard__AccountEmailSendVerificationCode(email: $email)
  }
`
export type AccountEmailSendVerificationCodeMutationFn = ApolloReactCommon.MutationFunction<
  AccountEmailSendVerificationCodeMutation,
  AccountEmailSendVerificationCodeMutationVariables
>
export type AccountEmailSendVerificationCodeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AccountEmailSendVerificationCodeMutation,
    AccountEmailSendVerificationCodeMutationVariables
  >,
  'mutation'
>

export const AccountEmailSendVerificationCodeComponent = (
  props: AccountEmailSendVerificationCodeComponentProps
) => (
  <ApolloReactComponents.Mutation<
    AccountEmailSendVerificationCodeMutation,
    AccountEmailSendVerificationCodeMutationVariables
  >
    mutation={AccountEmailSendVerificationCodeDocument}
    {...props}
  />
)

export type AccountEmailSendVerificationCodeProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    AccountEmailSendVerificationCodeMutation,
    AccountEmailSendVerificationCodeMutationVariables
  >
} &
  TChildProps
export function withAccountEmailSendVerificationCode<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AccountEmailSendVerificationCodeMutation,
    AccountEmailSendVerificationCodeMutationVariables,
    AccountEmailSendVerificationCodeProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AccountEmailSendVerificationCodeMutation,
    AccountEmailSendVerificationCodeMutationVariables,
    AccountEmailSendVerificationCodeProps<TChildProps, TDataName>
  >(AccountEmailSendVerificationCodeDocument, {
    alias: 'accountEmailSendVerificationCode',
    ...operationOptions,
  })
}

/**
 * __useAccountEmailSendVerificationCodeMutation__
 *
 * To run a mutation, you first call `useAccountEmailSendVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountEmailSendVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountEmailSendVerificationCodeMutation, { data, loading, error }] = useAccountEmailSendVerificationCodeMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAccountEmailSendVerificationCodeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AccountEmailSendVerificationCodeMutation,
    AccountEmailSendVerificationCodeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AccountEmailSendVerificationCodeMutation,
    AccountEmailSendVerificationCodeMutationVariables
  >(AccountEmailSendVerificationCodeDocument, baseOptions)
}
export type AccountEmailSendVerificationCodeMutationHookResult = ReturnType<
  typeof useAccountEmailSendVerificationCodeMutation
>
export type AccountEmailSendVerificationCodeMutationResult = ApolloReactCommon.MutationResult<AccountEmailSendVerificationCodeMutation>
export type AccountEmailSendVerificationCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AccountEmailSendVerificationCodeMutation,
  AccountEmailSendVerificationCodeMutationVariables
>
export const AccountEmailSetPrimaryDocument = gql`
  mutation AccountEmailSetPrimary($email: String!) {
    isPrimary: Dashboard__AccountEmailSetPrimary(email: $email)
  }
`
export type AccountEmailSetPrimaryMutationFn = ApolloReactCommon.MutationFunction<
  AccountEmailSetPrimaryMutation,
  AccountEmailSetPrimaryMutationVariables
>
export type AccountEmailSetPrimaryComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AccountEmailSetPrimaryMutation,
    AccountEmailSetPrimaryMutationVariables
  >,
  'mutation'
>

export const AccountEmailSetPrimaryComponent = (
  props: AccountEmailSetPrimaryComponentProps
) => (
  <ApolloReactComponents.Mutation<
    AccountEmailSetPrimaryMutation,
    AccountEmailSetPrimaryMutationVariables
  >
    mutation={AccountEmailSetPrimaryDocument}
    {...props}
  />
)

export type AccountEmailSetPrimaryProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    AccountEmailSetPrimaryMutation,
    AccountEmailSetPrimaryMutationVariables
  >
} &
  TChildProps
export function withAccountEmailSetPrimary<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AccountEmailSetPrimaryMutation,
    AccountEmailSetPrimaryMutationVariables,
    AccountEmailSetPrimaryProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AccountEmailSetPrimaryMutation,
    AccountEmailSetPrimaryMutationVariables,
    AccountEmailSetPrimaryProps<TChildProps, TDataName>
  >(AccountEmailSetPrimaryDocument, {
    alias: 'accountEmailSetPrimary',
    ...operationOptions,
  })
}

/**
 * __useAccountEmailSetPrimaryMutation__
 *
 * To run a mutation, you first call `useAccountEmailSetPrimaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountEmailSetPrimaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountEmailSetPrimaryMutation, { data, loading, error }] = useAccountEmailSetPrimaryMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAccountEmailSetPrimaryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AccountEmailSetPrimaryMutation,
    AccountEmailSetPrimaryMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AccountEmailSetPrimaryMutation,
    AccountEmailSetPrimaryMutationVariables
  >(AccountEmailSetPrimaryDocument, baseOptions)
}
export type AccountEmailSetPrimaryMutationHookResult = ReturnType<
  typeof useAccountEmailSetPrimaryMutation
>
export type AccountEmailSetPrimaryMutationResult = ApolloReactCommon.MutationResult<AccountEmailSetPrimaryMutation>
export type AccountEmailSetPrimaryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AccountEmailSetPrimaryMutation,
  AccountEmailSetPrimaryMutationVariables
>
export const AccountEmailRemoveDocument = gql`
  mutation AccountEmailRemove($email: String!) {
    removed: Dashboard__AccountEmailRemove(email: $email)
  }
`
export type AccountEmailRemoveMutationFn = ApolloReactCommon.MutationFunction<
  AccountEmailRemoveMutation,
  AccountEmailRemoveMutationVariables
>
export type AccountEmailRemoveComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AccountEmailRemoveMutation,
    AccountEmailRemoveMutationVariables
  >,
  'mutation'
>

export const AccountEmailRemoveComponent = (
  props: AccountEmailRemoveComponentProps
) => (
  <ApolloReactComponents.Mutation<
    AccountEmailRemoveMutation,
    AccountEmailRemoveMutationVariables
  >
    mutation={AccountEmailRemoveDocument}
    {...props}
  />
)

export type AccountEmailRemoveProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    AccountEmailRemoveMutation,
    AccountEmailRemoveMutationVariables
  >
} &
  TChildProps
export function withAccountEmailRemove<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AccountEmailRemoveMutation,
    AccountEmailRemoveMutationVariables,
    AccountEmailRemoveProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AccountEmailRemoveMutation,
    AccountEmailRemoveMutationVariables,
    AccountEmailRemoveProps<TChildProps, TDataName>
  >(AccountEmailRemoveDocument, {
    alias: 'accountEmailRemove',
    ...operationOptions,
  })
}

/**
 * __useAccountEmailRemoveMutation__
 *
 * To run a mutation, you first call `useAccountEmailRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountEmailRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountEmailRemoveMutation, { data, loading, error }] = useAccountEmailRemoveMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAccountEmailRemoveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AccountEmailRemoveMutation,
    AccountEmailRemoveMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AccountEmailRemoveMutation,
    AccountEmailRemoveMutationVariables
  >(AccountEmailRemoveDocument, baseOptions)
}
export type AccountEmailRemoveMutationHookResult = ReturnType<
  typeof useAccountEmailRemoveMutation
>
export type AccountEmailRemoveMutationResult = ApolloReactCommon.MutationResult<AccountEmailRemoveMutation>
export type AccountEmailRemoveMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AccountEmailRemoveMutation,
  AccountEmailRemoveMutationVariables
>
export const AccountChangePasswordDocument = gql`
  mutation AccountChangePassword(
    $refreshToken: String!
    $currentPassword: String!
    $newPassword: String!
  ) {
    isUpdated: Dashboard__AccountChangePassword(
      refreshToken: $refreshToken
      currentPassword: $currentPassword
      newPassword: $newPassword
    )
  }
`
export type AccountChangePasswordMutationFn = ApolloReactCommon.MutationFunction<
  AccountChangePasswordMutation,
  AccountChangePasswordMutationVariables
>
export type AccountChangePasswordComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AccountChangePasswordMutation,
    AccountChangePasswordMutationVariables
  >,
  'mutation'
>

export const AccountChangePasswordComponent = (
  props: AccountChangePasswordComponentProps
) => (
  <ApolloReactComponents.Mutation<
    AccountChangePasswordMutation,
    AccountChangePasswordMutationVariables
  >
    mutation={AccountChangePasswordDocument}
    {...props}
  />
)

export type AccountChangePasswordProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    AccountChangePasswordMutation,
    AccountChangePasswordMutationVariables
  >
} &
  TChildProps
export function withAccountChangePassword<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AccountChangePasswordMutation,
    AccountChangePasswordMutationVariables,
    AccountChangePasswordProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AccountChangePasswordMutation,
    AccountChangePasswordMutationVariables,
    AccountChangePasswordProps<TChildProps, TDataName>
  >(AccountChangePasswordDocument, {
    alias: 'accountChangePassword',
    ...operationOptions,
  })
}

/**
 * __useAccountChangePasswordMutation__
 *
 * To run a mutation, you first call `useAccountChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAccountChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [accountChangePasswordMutation, { data, loading, error }] = useAccountChangePasswordMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *      currentPassword: // value for 'currentPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useAccountChangePasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AccountChangePasswordMutation,
    AccountChangePasswordMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AccountChangePasswordMutation,
    AccountChangePasswordMutationVariables
  >(AccountChangePasswordDocument, baseOptions)
}
export type AccountChangePasswordMutationHookResult = ReturnType<
  typeof useAccountChangePasswordMutation
>
export type AccountChangePasswordMutationResult = ApolloReactCommon.MutationResult<AccountChangePasswordMutation>
export type AccountChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AccountChangePasswordMutation,
  AccountChangePasswordMutationVariables
>
export const AddWorkspaceMemberDocument = gql`
  mutation AddWorkspaceMember($emails: [String!]!) {
    addedMember: Dashboard__InviteWorkspaceMember(emails: $emails) {
      _id
    }
  }
`
export type AddWorkspaceMemberMutationFn = ApolloReactCommon.MutationFunction<
  AddWorkspaceMemberMutation,
  AddWorkspaceMemberMutationVariables
>
export type AddWorkspaceMemberComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AddWorkspaceMemberMutation,
    AddWorkspaceMemberMutationVariables
  >,
  'mutation'
>

export const AddWorkspaceMemberComponent = (
  props: AddWorkspaceMemberComponentProps
) => (
  <ApolloReactComponents.Mutation<
    AddWorkspaceMemberMutation,
    AddWorkspaceMemberMutationVariables
  >
    mutation={AddWorkspaceMemberDocument}
    {...props}
  />
)

export type AddWorkspaceMemberProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    AddWorkspaceMemberMutation,
    AddWorkspaceMemberMutationVariables
  >
} &
  TChildProps
export function withAddWorkspaceMember<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AddWorkspaceMemberMutation,
    AddWorkspaceMemberMutationVariables,
    AddWorkspaceMemberProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AddWorkspaceMemberMutation,
    AddWorkspaceMemberMutationVariables,
    AddWorkspaceMemberProps<TChildProps, TDataName>
  >(AddWorkspaceMemberDocument, {
    alias: 'addWorkspaceMember',
    ...operationOptions,
  })
}

/**
 * __useAddWorkspaceMemberMutation__
 *
 * To run a mutation, you first call `useAddWorkspaceMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWorkspaceMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWorkspaceMemberMutation, { data, loading, error }] = useAddWorkspaceMemberMutation({
 *   variables: {
 *      emails: // value for 'emails'
 *   },
 * });
 */
export function useAddWorkspaceMemberMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddWorkspaceMemberMutation,
    AddWorkspaceMemberMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddWorkspaceMemberMutation,
    AddWorkspaceMemberMutationVariables
  >(AddWorkspaceMemberDocument, baseOptions)
}
export type AddWorkspaceMemberMutationHookResult = ReturnType<
  typeof useAddWorkspaceMemberMutation
>
export type AddWorkspaceMemberMutationResult = ApolloReactCommon.MutationResult<AddWorkspaceMemberMutation>
export type AddWorkspaceMemberMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddWorkspaceMemberMutation,
  AddWorkspaceMemberMutationVariables
>
export const RemoveWorkspaceMemberDocument = gql`
  mutation RemoveWorkspaceMember($email: String!) {
    removedMember: Dashboard__RemoveWorkspaceMember(email: $email)
  }
`
export type RemoveWorkspaceMemberMutationFn = ApolloReactCommon.MutationFunction<
  RemoveWorkspaceMemberMutation,
  RemoveWorkspaceMemberMutationVariables
>
export type RemoveWorkspaceMemberComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RemoveWorkspaceMemberMutation,
    RemoveWorkspaceMemberMutationVariables
  >,
  'mutation'
>

export const RemoveWorkspaceMemberComponent = (
  props: RemoveWorkspaceMemberComponentProps
) => (
  <ApolloReactComponents.Mutation<
    RemoveWorkspaceMemberMutation,
    RemoveWorkspaceMemberMutationVariables
  >
    mutation={RemoveWorkspaceMemberDocument}
    {...props}
  />
)

export type RemoveWorkspaceMemberProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    RemoveWorkspaceMemberMutation,
    RemoveWorkspaceMemberMutationVariables
  >
} &
  TChildProps
export function withRemoveWorkspaceMember<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RemoveWorkspaceMemberMutation,
    RemoveWorkspaceMemberMutationVariables,
    RemoveWorkspaceMemberProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RemoveWorkspaceMemberMutation,
    RemoveWorkspaceMemberMutationVariables,
    RemoveWorkspaceMemberProps<TChildProps, TDataName>
  >(RemoveWorkspaceMemberDocument, {
    alias: 'removeWorkspaceMember',
    ...operationOptions,
  })
}

/**
 * __useRemoveWorkspaceMemberMutation__
 *
 * To run a mutation, you first call `useRemoveWorkspaceMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveWorkspaceMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeWorkspaceMemberMutation, { data, loading, error }] = useRemoveWorkspaceMemberMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRemoveWorkspaceMemberMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveWorkspaceMemberMutation,
    RemoveWorkspaceMemberMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RemoveWorkspaceMemberMutation,
    RemoveWorkspaceMemberMutationVariables
  >(RemoveWorkspaceMemberDocument, baseOptions)
}
export type RemoveWorkspaceMemberMutationHookResult = ReturnType<
  typeof useRemoveWorkspaceMemberMutation
>
export type RemoveWorkspaceMemberMutationResult = ApolloReactCommon.MutationResult<RemoveWorkspaceMemberMutation>
export type RemoveWorkspaceMemberMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveWorkspaceMemberMutation,
  RemoveWorkspaceMemberMutationVariables
>
export const InviteToWorkspaceDocument = gql`
  mutation InviteToWorkspace($emails: [String!]!) {
    isInviteSent: Dashboard__InviteWorkspaceMember(emails: $emails) {
      ...InviteFullFragment
    }
  }
  ${InviteFullFragmentFragmentDoc}
`
export type InviteToWorkspaceMutationFn = ApolloReactCommon.MutationFunction<
  InviteToWorkspaceMutation,
  InviteToWorkspaceMutationVariables
>
export type InviteToWorkspaceComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    InviteToWorkspaceMutation,
    InviteToWorkspaceMutationVariables
  >,
  'mutation'
>

export const InviteToWorkspaceComponent = (
  props: InviteToWorkspaceComponentProps
) => (
  <ApolloReactComponents.Mutation<
    InviteToWorkspaceMutation,
    InviteToWorkspaceMutationVariables
  >
    mutation={InviteToWorkspaceDocument}
    {...props}
  />
)

export type InviteToWorkspaceProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    InviteToWorkspaceMutation,
    InviteToWorkspaceMutationVariables
  >
} &
  TChildProps
export function withInviteToWorkspace<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    InviteToWorkspaceMutation,
    InviteToWorkspaceMutationVariables,
    InviteToWorkspaceProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    InviteToWorkspaceMutation,
    InviteToWorkspaceMutationVariables,
    InviteToWorkspaceProps<TChildProps, TDataName>
  >(InviteToWorkspaceDocument, {
    alias: 'inviteToWorkspace',
    ...operationOptions,
  })
}

/**
 * __useInviteToWorkspaceMutation__
 *
 * To run a mutation, you first call `useInviteToWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteToWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteToWorkspaceMutation, { data, loading, error }] = useInviteToWorkspaceMutation({
 *   variables: {
 *      emails: // value for 'emails'
 *   },
 * });
 */
export function useInviteToWorkspaceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    InviteToWorkspaceMutation,
    InviteToWorkspaceMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    InviteToWorkspaceMutation,
    InviteToWorkspaceMutationVariables
  >(InviteToWorkspaceDocument, baseOptions)
}
export type InviteToWorkspaceMutationHookResult = ReturnType<
  typeof useInviteToWorkspaceMutation
>
export type InviteToWorkspaceMutationResult = ApolloReactCommon.MutationResult<InviteToWorkspaceMutation>
export type InviteToWorkspaceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  InviteToWorkspaceMutation,
  InviteToWorkspaceMutationVariables
>
export const AcceptWorkspaceInvitesDocument = gql`
  mutation AcceptWorkspaceInvites($ids: [MongoID!]!) {
    invites: Dashboard__AccountAcceptWorkspaceInvites(ids: $ids) {
      ...InviteFullFragment
    }
  }
  ${InviteFullFragmentFragmentDoc}
`
export type AcceptWorkspaceInvitesMutationFn = ApolloReactCommon.MutationFunction<
  AcceptWorkspaceInvitesMutation,
  AcceptWorkspaceInvitesMutationVariables
>
export type AcceptWorkspaceInvitesComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AcceptWorkspaceInvitesMutation,
    AcceptWorkspaceInvitesMutationVariables
  >,
  'mutation'
>

export const AcceptWorkspaceInvitesComponent = (
  props: AcceptWorkspaceInvitesComponentProps
) => (
  <ApolloReactComponents.Mutation<
    AcceptWorkspaceInvitesMutation,
    AcceptWorkspaceInvitesMutationVariables
  >
    mutation={AcceptWorkspaceInvitesDocument}
    {...props}
  />
)

export type AcceptWorkspaceInvitesProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    AcceptWorkspaceInvitesMutation,
    AcceptWorkspaceInvitesMutationVariables
  >
} &
  TChildProps
export function withAcceptWorkspaceInvites<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AcceptWorkspaceInvitesMutation,
    AcceptWorkspaceInvitesMutationVariables,
    AcceptWorkspaceInvitesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AcceptWorkspaceInvitesMutation,
    AcceptWorkspaceInvitesMutationVariables,
    AcceptWorkspaceInvitesProps<TChildProps, TDataName>
  >(AcceptWorkspaceInvitesDocument, {
    alias: 'acceptWorkspaceInvites',
    ...operationOptions,
  })
}

/**
 * __useAcceptWorkspaceInvitesMutation__
 *
 * To run a mutation, you first call `useAcceptWorkspaceInvitesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptWorkspaceInvitesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptWorkspaceInvitesMutation, { data, loading, error }] = useAcceptWorkspaceInvitesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useAcceptWorkspaceInvitesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AcceptWorkspaceInvitesMutation,
    AcceptWorkspaceInvitesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AcceptWorkspaceInvitesMutation,
    AcceptWorkspaceInvitesMutationVariables
  >(AcceptWorkspaceInvitesDocument, baseOptions)
}
export type AcceptWorkspaceInvitesMutationHookResult = ReturnType<
  typeof useAcceptWorkspaceInvitesMutation
>
export type AcceptWorkspaceInvitesMutationResult = ApolloReactCommon.MutationResult<AcceptWorkspaceInvitesMutation>
export type AcceptWorkspaceInvitesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AcceptWorkspaceInvitesMutation,
  AcceptWorkspaceInvitesMutationVariables
>
export const RejectWorkspaceInvitesDocument = gql`
  mutation RejectWorkspaceInvites($ids: [MongoID!]!) {
    invites: Dashboard__AccountRejectWorkspaceInvites(ids: $ids) {
      ...InviteFullFragment
    }
  }
  ${InviteFullFragmentFragmentDoc}
`
export type RejectWorkspaceInvitesMutationFn = ApolloReactCommon.MutationFunction<
  RejectWorkspaceInvitesMutation,
  RejectWorkspaceInvitesMutationVariables
>
export type RejectWorkspaceInvitesComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RejectWorkspaceInvitesMutation,
    RejectWorkspaceInvitesMutationVariables
  >,
  'mutation'
>

export const RejectWorkspaceInvitesComponent = (
  props: RejectWorkspaceInvitesComponentProps
) => (
  <ApolloReactComponents.Mutation<
    RejectWorkspaceInvitesMutation,
    RejectWorkspaceInvitesMutationVariables
  >
    mutation={RejectWorkspaceInvitesDocument}
    {...props}
  />
)

export type RejectWorkspaceInvitesProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    RejectWorkspaceInvitesMutation,
    RejectWorkspaceInvitesMutationVariables
  >
} &
  TChildProps
export function withRejectWorkspaceInvites<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RejectWorkspaceInvitesMutation,
    RejectWorkspaceInvitesMutationVariables,
    RejectWorkspaceInvitesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RejectWorkspaceInvitesMutation,
    RejectWorkspaceInvitesMutationVariables,
    RejectWorkspaceInvitesProps<TChildProps, TDataName>
  >(RejectWorkspaceInvitesDocument, {
    alias: 'rejectWorkspaceInvites',
    ...operationOptions,
  })
}

/**
 * __useRejectWorkspaceInvitesMutation__
 *
 * To run a mutation, you first call `useRejectWorkspaceInvitesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectWorkspaceInvitesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectWorkspaceInvitesMutation, { data, loading, error }] = useRejectWorkspaceInvitesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRejectWorkspaceInvitesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RejectWorkspaceInvitesMutation,
    RejectWorkspaceInvitesMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RejectWorkspaceInvitesMutation,
    RejectWorkspaceInvitesMutationVariables
  >(RejectWorkspaceInvitesDocument, baseOptions)
}
export type RejectWorkspaceInvitesMutationHookResult = ReturnType<
  typeof useRejectWorkspaceInvitesMutation
>
export type RejectWorkspaceInvitesMutationResult = ApolloReactCommon.MutationResult<RejectWorkspaceInvitesMutation>
export type RejectWorkspaceInvitesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RejectWorkspaceInvitesMutation,
  RejectWorkspaceInvitesMutationVariables
>
export const WorkspaceSetupIntentDocument = gql`
  mutation WorkspaceSetupIntent($setupIntentArgs: setupIntentInput) {
    setupIntent: Dashboard__WorkspaceSetupIntent(
      setupIntentArgs: $setupIntentArgs
    ) {
      id
      client_secret
      payment_method
      status
    }
  }
`
export type WorkspaceSetupIntentMutationFn = ApolloReactCommon.MutationFunction<
  WorkspaceSetupIntentMutation,
  WorkspaceSetupIntentMutationVariables
>
export type WorkspaceSetupIntentComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    WorkspaceSetupIntentMutation,
    WorkspaceSetupIntentMutationVariables
  >,
  'mutation'
>

export const WorkspaceSetupIntentComponent = (
  props: WorkspaceSetupIntentComponentProps
) => (
  <ApolloReactComponents.Mutation<
    WorkspaceSetupIntentMutation,
    WorkspaceSetupIntentMutationVariables
  >
    mutation={WorkspaceSetupIntentDocument}
    {...props}
  />
)

export type WorkspaceSetupIntentProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    WorkspaceSetupIntentMutation,
    WorkspaceSetupIntentMutationVariables
  >
} &
  TChildProps
export function withWorkspaceSetupIntent<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    WorkspaceSetupIntentMutation,
    WorkspaceSetupIntentMutationVariables,
    WorkspaceSetupIntentProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    WorkspaceSetupIntentMutation,
    WorkspaceSetupIntentMutationVariables,
    WorkspaceSetupIntentProps<TChildProps, TDataName>
  >(WorkspaceSetupIntentDocument, {
    alias: 'workspaceSetupIntent',
    ...operationOptions,
  })
}

/**
 * __useWorkspaceSetupIntentMutation__
 *
 * To run a mutation, you first call `useWorkspaceSetupIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceSetupIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [workspaceSetupIntentMutation, { data, loading, error }] = useWorkspaceSetupIntentMutation({
 *   variables: {
 *      setupIntentArgs: // value for 'setupIntentArgs'
 *   },
 * });
 */
export function useWorkspaceSetupIntentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    WorkspaceSetupIntentMutation,
    WorkspaceSetupIntentMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    WorkspaceSetupIntentMutation,
    WorkspaceSetupIntentMutationVariables
  >(WorkspaceSetupIntentDocument, baseOptions)
}
export type WorkspaceSetupIntentMutationHookResult = ReturnType<
  typeof useWorkspaceSetupIntentMutation
>
export type WorkspaceSetupIntentMutationResult = ApolloReactCommon.MutationResult<WorkspaceSetupIntentMutation>
export type WorkspaceSetupIntentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  WorkspaceSetupIntentMutation,
  WorkspaceSetupIntentMutationVariables
>
export const WorkspaceAddPaymentMethodDocument = gql`
  mutation WorkspaceAddPaymentMethod($paymentMethod: PaymentMethodInput) {
    paymentMethod: Dashboard__WorkspaceAddPaymentMethod(
      paymentMethod: $paymentMethod
    )
  }
`
export type WorkspaceAddPaymentMethodMutationFn = ApolloReactCommon.MutationFunction<
  WorkspaceAddPaymentMethodMutation,
  WorkspaceAddPaymentMethodMutationVariables
>
export type WorkspaceAddPaymentMethodComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    WorkspaceAddPaymentMethodMutation,
    WorkspaceAddPaymentMethodMutationVariables
  >,
  'mutation'
>

export const WorkspaceAddPaymentMethodComponent = (
  props: WorkspaceAddPaymentMethodComponentProps
) => (
  <ApolloReactComponents.Mutation<
    WorkspaceAddPaymentMethodMutation,
    WorkspaceAddPaymentMethodMutationVariables
  >
    mutation={WorkspaceAddPaymentMethodDocument}
    {...props}
  />
)

export type WorkspaceAddPaymentMethodProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    WorkspaceAddPaymentMethodMutation,
    WorkspaceAddPaymentMethodMutationVariables
  >
} &
  TChildProps
export function withWorkspaceAddPaymentMethod<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    WorkspaceAddPaymentMethodMutation,
    WorkspaceAddPaymentMethodMutationVariables,
    WorkspaceAddPaymentMethodProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    WorkspaceAddPaymentMethodMutation,
    WorkspaceAddPaymentMethodMutationVariables,
    WorkspaceAddPaymentMethodProps<TChildProps, TDataName>
  >(WorkspaceAddPaymentMethodDocument, {
    alias: 'workspaceAddPaymentMethod',
    ...operationOptions,
  })
}

/**
 * __useWorkspaceAddPaymentMethodMutation__
 *
 * To run a mutation, you first call `useWorkspaceAddPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceAddPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [workspaceAddPaymentMethodMutation, { data, loading, error }] = useWorkspaceAddPaymentMethodMutation({
 *   variables: {
 *      paymentMethod: // value for 'paymentMethod'
 *   },
 * });
 */
export function useWorkspaceAddPaymentMethodMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    WorkspaceAddPaymentMethodMutation,
    WorkspaceAddPaymentMethodMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    WorkspaceAddPaymentMethodMutation,
    WorkspaceAddPaymentMethodMutationVariables
  >(WorkspaceAddPaymentMethodDocument, baseOptions)
}
export type WorkspaceAddPaymentMethodMutationHookResult = ReturnType<
  typeof useWorkspaceAddPaymentMethodMutation
>
export type WorkspaceAddPaymentMethodMutationResult = ApolloReactCommon.MutationResult<WorkspaceAddPaymentMethodMutation>
export type WorkspaceAddPaymentMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<
  WorkspaceAddPaymentMethodMutation,
  WorkspaceAddPaymentMethodMutationVariables
>
export const WorkspaceSetDefaultPaymentMethodDocument = gql`
  mutation WorkspaceSetDefaultPaymentMethod($paymentMethodId: String!) {
    Dashboard__WorkspaceSetDefaultPaymentMethod(
      paymentMethodId: $paymentMethodId
    )
  }
`
export type WorkspaceSetDefaultPaymentMethodMutationFn = ApolloReactCommon.MutationFunction<
  WorkspaceSetDefaultPaymentMethodMutation,
  WorkspaceSetDefaultPaymentMethodMutationVariables
>
export type WorkspaceSetDefaultPaymentMethodComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    WorkspaceSetDefaultPaymentMethodMutation,
    WorkspaceSetDefaultPaymentMethodMutationVariables
  >,
  'mutation'
>

export const WorkspaceSetDefaultPaymentMethodComponent = (
  props: WorkspaceSetDefaultPaymentMethodComponentProps
) => (
  <ApolloReactComponents.Mutation<
    WorkspaceSetDefaultPaymentMethodMutation,
    WorkspaceSetDefaultPaymentMethodMutationVariables
  >
    mutation={WorkspaceSetDefaultPaymentMethodDocument}
    {...props}
  />
)

export type WorkspaceSetDefaultPaymentMethodProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    WorkspaceSetDefaultPaymentMethodMutation,
    WorkspaceSetDefaultPaymentMethodMutationVariables
  >
} &
  TChildProps
export function withWorkspaceSetDefaultPaymentMethod<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    WorkspaceSetDefaultPaymentMethodMutation,
    WorkspaceSetDefaultPaymentMethodMutationVariables,
    WorkspaceSetDefaultPaymentMethodProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    WorkspaceSetDefaultPaymentMethodMutation,
    WorkspaceSetDefaultPaymentMethodMutationVariables,
    WorkspaceSetDefaultPaymentMethodProps<TChildProps, TDataName>
  >(WorkspaceSetDefaultPaymentMethodDocument, {
    alias: 'workspaceSetDefaultPaymentMethod',
    ...operationOptions,
  })
}

/**
 * __useWorkspaceSetDefaultPaymentMethodMutation__
 *
 * To run a mutation, you first call `useWorkspaceSetDefaultPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceSetDefaultPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [workspaceSetDefaultPaymentMethodMutation, { data, loading, error }] = useWorkspaceSetDefaultPaymentMethodMutation({
 *   variables: {
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useWorkspaceSetDefaultPaymentMethodMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    WorkspaceSetDefaultPaymentMethodMutation,
    WorkspaceSetDefaultPaymentMethodMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    WorkspaceSetDefaultPaymentMethodMutation,
    WorkspaceSetDefaultPaymentMethodMutationVariables
  >(WorkspaceSetDefaultPaymentMethodDocument, baseOptions)
}
export type WorkspaceSetDefaultPaymentMethodMutationHookResult = ReturnType<
  typeof useWorkspaceSetDefaultPaymentMethodMutation
>
export type WorkspaceSetDefaultPaymentMethodMutationResult = ApolloReactCommon.MutationResult<WorkspaceSetDefaultPaymentMethodMutation>
export type WorkspaceSetDefaultPaymentMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<
  WorkspaceSetDefaultPaymentMethodMutation,
  WorkspaceSetDefaultPaymentMethodMutationVariables
>
export const WorkspaceRemovePaymentMethodDocument = gql`
  mutation WorkspaceRemovePaymentMethod($paymentMethodId: String!) {
    Dashboard__WorkspaceRemovePaymentMethod(paymentMethodId: $paymentMethodId)
  }
`
export type WorkspaceRemovePaymentMethodMutationFn = ApolloReactCommon.MutationFunction<
  WorkspaceRemovePaymentMethodMutation,
  WorkspaceRemovePaymentMethodMutationVariables
>
export type WorkspaceRemovePaymentMethodComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    WorkspaceRemovePaymentMethodMutation,
    WorkspaceRemovePaymentMethodMutationVariables
  >,
  'mutation'
>

export const WorkspaceRemovePaymentMethodComponent = (
  props: WorkspaceRemovePaymentMethodComponentProps
) => (
  <ApolloReactComponents.Mutation<
    WorkspaceRemovePaymentMethodMutation,
    WorkspaceRemovePaymentMethodMutationVariables
  >
    mutation={WorkspaceRemovePaymentMethodDocument}
    {...props}
  />
)

export type WorkspaceRemovePaymentMethodProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    WorkspaceRemovePaymentMethodMutation,
    WorkspaceRemovePaymentMethodMutationVariables
  >
} &
  TChildProps
export function withWorkspaceRemovePaymentMethod<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    WorkspaceRemovePaymentMethodMutation,
    WorkspaceRemovePaymentMethodMutationVariables,
    WorkspaceRemovePaymentMethodProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    WorkspaceRemovePaymentMethodMutation,
    WorkspaceRemovePaymentMethodMutationVariables,
    WorkspaceRemovePaymentMethodProps<TChildProps, TDataName>
  >(WorkspaceRemovePaymentMethodDocument, {
    alias: 'workspaceRemovePaymentMethod',
    ...operationOptions,
  })
}

/**
 * __useWorkspaceRemovePaymentMethodMutation__
 *
 * To run a mutation, you first call `useWorkspaceRemovePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceRemovePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [workspaceRemovePaymentMethodMutation, { data, loading, error }] = useWorkspaceRemovePaymentMethodMutation({
 *   variables: {
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useWorkspaceRemovePaymentMethodMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    WorkspaceRemovePaymentMethodMutation,
    WorkspaceRemovePaymentMethodMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    WorkspaceRemovePaymentMethodMutation,
    WorkspaceRemovePaymentMethodMutationVariables
  >(WorkspaceRemovePaymentMethodDocument, baseOptions)
}
export type WorkspaceRemovePaymentMethodMutationHookResult = ReturnType<
  typeof useWorkspaceRemovePaymentMethodMutation
>
export type WorkspaceRemovePaymentMethodMutationResult = ApolloReactCommon.MutationResult<WorkspaceRemovePaymentMethodMutation>
export type WorkspaceRemovePaymentMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<
  WorkspaceRemovePaymentMethodMutation,
  WorkspaceRemovePaymentMethodMutationVariables
>
export const WorkspacePayInvoiceDocument = gql`
  mutation WorkspacePayInvoice($invoiceId: String!) {
    Dashboard__WorkspacePayInvoice(invoiceId: $invoiceId)
  }
`
export type WorkspacePayInvoiceMutationFn = ApolloReactCommon.MutationFunction<
  WorkspacePayInvoiceMutation,
  WorkspacePayInvoiceMutationVariables
>
export type WorkspacePayInvoiceComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    WorkspacePayInvoiceMutation,
    WorkspacePayInvoiceMutationVariables
  >,
  'mutation'
>

export const WorkspacePayInvoiceComponent = (
  props: WorkspacePayInvoiceComponentProps
) => (
  <ApolloReactComponents.Mutation<
    WorkspacePayInvoiceMutation,
    WorkspacePayInvoiceMutationVariables
  >
    mutation={WorkspacePayInvoiceDocument}
    {...props}
  />
)

export type WorkspacePayInvoiceProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    WorkspacePayInvoiceMutation,
    WorkspacePayInvoiceMutationVariables
  >
} &
  TChildProps
export function withWorkspacePayInvoice<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    WorkspacePayInvoiceMutation,
    WorkspacePayInvoiceMutationVariables,
    WorkspacePayInvoiceProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    WorkspacePayInvoiceMutation,
    WorkspacePayInvoiceMutationVariables,
    WorkspacePayInvoiceProps<TChildProps, TDataName>
  >(WorkspacePayInvoiceDocument, {
    alias: 'workspacePayInvoice',
    ...operationOptions,
  })
}

/**
 * __useWorkspacePayInvoiceMutation__
 *
 * To run a mutation, you first call `useWorkspacePayInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWorkspacePayInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [workspacePayInvoiceMutation, { data, loading, error }] = useWorkspacePayInvoiceMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useWorkspacePayInvoiceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    WorkspacePayInvoiceMutation,
    WorkspacePayInvoiceMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    WorkspacePayInvoiceMutation,
    WorkspacePayInvoiceMutationVariables
  >(WorkspacePayInvoiceDocument, baseOptions)
}
export type WorkspacePayInvoiceMutationHookResult = ReturnType<
  typeof useWorkspacePayInvoiceMutation
>
export type WorkspacePayInvoiceMutationResult = ApolloReactCommon.MutationResult<WorkspacePayInvoiceMutation>
export type WorkspacePayInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  WorkspacePayInvoiceMutation,
  WorkspacePayInvoiceMutationVariables
>
export const CreateWebhookDocument = gql`
  mutation CreateWebhook($input: CreateOneWebhookInput!) {
    webhook: Dashboard__CreateWebhook(record: $input) {
      _id: recordId
    }
  }
`
export type CreateWebhookMutationFn = ApolloReactCommon.MutationFunction<
  CreateWebhookMutation,
  CreateWebhookMutationVariables
>
export type CreateWebhookComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateWebhookMutation,
    CreateWebhookMutationVariables
  >,
  'mutation'
>

export const CreateWebhookComponent = (props: CreateWebhookComponentProps) => (
  <ApolloReactComponents.Mutation<
    CreateWebhookMutation,
    CreateWebhookMutationVariables
  >
    mutation={CreateWebhookDocument}
    {...props}
  />
)

export type CreateWebhookProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    CreateWebhookMutation,
    CreateWebhookMutationVariables
  >
} &
  TChildProps
export function withCreateWebhook<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateWebhookMutation,
    CreateWebhookMutationVariables,
    CreateWebhookProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateWebhookMutation,
    CreateWebhookMutationVariables,
    CreateWebhookProps<TChildProps, TDataName>
  >(CreateWebhookDocument, {
    alias: 'createWebhook',
    ...operationOptions,
  })
}

/**
 * __useCreateWebhookMutation__
 *
 * To run a mutation, you first call `useCreateWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWebhookMutation, { data, loading, error }] = useCreateWebhookMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWebhookMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateWebhookMutation,
    CreateWebhookMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateWebhookMutation,
    CreateWebhookMutationVariables
  >(CreateWebhookDocument, baseOptions)
}
export type CreateWebhookMutationHookResult = ReturnType<
  typeof useCreateWebhookMutation
>
export type CreateWebhookMutationResult = ApolloReactCommon.MutationResult<CreateWebhookMutation>
export type CreateWebhookMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateWebhookMutation,
  CreateWebhookMutationVariables
>
export const UpdateWebhookByIdDocument = gql`
  mutation UpdateWebhookById($_id: MongoID!, $input: UpdateOneWebhookInput!) {
    webhook: Dashboard__UpdateWebhook(filter: { _id: $_id }, record: $input) {
      _id: recordId
    }
  }
`
export type UpdateWebhookByIdMutationFn = ApolloReactCommon.MutationFunction<
  UpdateWebhookByIdMutation,
  UpdateWebhookByIdMutationVariables
>
export type UpdateWebhookByIdComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateWebhookByIdMutation,
    UpdateWebhookByIdMutationVariables
  >,
  'mutation'
>

export const UpdateWebhookByIdComponent = (
  props: UpdateWebhookByIdComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateWebhookByIdMutation,
    UpdateWebhookByIdMutationVariables
  >
    mutation={UpdateWebhookByIdDocument}
    {...props}
  />
)

export type UpdateWebhookByIdProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    UpdateWebhookByIdMutation,
    UpdateWebhookByIdMutationVariables
  >
} &
  TChildProps
export function withUpdateWebhookById<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateWebhookByIdMutation,
    UpdateWebhookByIdMutationVariables,
    UpdateWebhookByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateWebhookByIdMutation,
    UpdateWebhookByIdMutationVariables,
    UpdateWebhookByIdProps<TChildProps, TDataName>
  >(UpdateWebhookByIdDocument, {
    alias: 'updateWebhookById',
    ...operationOptions,
  })
}

/**
 * __useUpdateWebhookByIdMutation__
 *
 * To run a mutation, you first call `useUpdateWebhookByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebhookByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebhookByIdMutation, { data, loading, error }] = useUpdateWebhookByIdMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWebhookByIdMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateWebhookByIdMutation,
    UpdateWebhookByIdMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateWebhookByIdMutation,
    UpdateWebhookByIdMutationVariables
  >(UpdateWebhookByIdDocument, baseOptions)
}
export type UpdateWebhookByIdMutationHookResult = ReturnType<
  typeof useUpdateWebhookByIdMutation
>
export type UpdateWebhookByIdMutationResult = ApolloReactCommon.MutationResult<UpdateWebhookByIdMutation>
export type UpdateWebhookByIdMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateWebhookByIdMutation,
  UpdateWebhookByIdMutationVariables
>
export const ArchiveWebhookDocument = gql`
  mutation ArchiveWebhook($ids: [MongoID!]!) {
    areArchived: Dashboard__ArchiveWebhooks(ids: $ids)
  }
`
export type ArchiveWebhookMutationFn = ApolloReactCommon.MutationFunction<
  ArchiveWebhookMutation,
  ArchiveWebhookMutationVariables
>
export type ArchiveWebhookComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ArchiveWebhookMutation,
    ArchiveWebhookMutationVariables
  >,
  'mutation'
>

export const ArchiveWebhookComponent = (
  props: ArchiveWebhookComponentProps
) => (
  <ApolloReactComponents.Mutation<
    ArchiveWebhookMutation,
    ArchiveWebhookMutationVariables
  >
    mutation={ArchiveWebhookDocument}
    {...props}
  />
)

export type ArchiveWebhookProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    ArchiveWebhookMutation,
    ArchiveWebhookMutationVariables
  >
} &
  TChildProps
export function withArchiveWebhook<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ArchiveWebhookMutation,
    ArchiveWebhookMutationVariables,
    ArchiveWebhookProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ArchiveWebhookMutation,
    ArchiveWebhookMutationVariables,
    ArchiveWebhookProps<TChildProps, TDataName>
  >(ArchiveWebhookDocument, {
    alias: 'archiveWebhook',
    ...operationOptions,
  })
}

/**
 * __useArchiveWebhookMutation__
 *
 * To run a mutation, you first call `useArchiveWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveWebhookMutation, { data, loading, error }] = useArchiveWebhookMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useArchiveWebhookMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ArchiveWebhookMutation,
    ArchiveWebhookMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ArchiveWebhookMutation,
    ArchiveWebhookMutationVariables
  >(ArchiveWebhookDocument, baseOptions)
}
export type ArchiveWebhookMutationHookResult = ReturnType<
  typeof useArchiveWebhookMutation
>
export type ArchiveWebhookMutationResult = ApolloReactCommon.MutationResult<ArchiveWebhookMutation>
export type ArchiveWebhookMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ArchiveWebhookMutation,
  ArchiveWebhookMutationVariables
>
export const UpdateCreativeByIdDocument = gql`
  mutation UpdateCreativeById($_id: MongoID!, $input: UpdateOneCreativeInput!) {
    creative: Dashboard__UpdateCreative(filter: { _id: $_id }, record: $input) {
      _id: recordId
    }
  }
`
export type UpdateCreativeByIdMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCreativeByIdMutation,
  UpdateCreativeByIdMutationVariables
>
export type UpdateCreativeByIdComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateCreativeByIdMutation,
    UpdateCreativeByIdMutationVariables
  >,
  'mutation'
>

export const UpdateCreativeByIdComponent = (
  props: UpdateCreativeByIdComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateCreativeByIdMutation,
    UpdateCreativeByIdMutationVariables
  >
    mutation={UpdateCreativeByIdDocument}
    {...props}
  />
)

export type UpdateCreativeByIdProps<
  TChildProps = {},
  TDataName extends string = 'mutate'
> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<
    UpdateCreativeByIdMutation,
    UpdateCreativeByIdMutationVariables
  >
} &
  TChildProps
export function withUpdateCreativeById<
  TProps,
  TChildProps = {},
  TDataName extends string = 'mutate'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateCreativeByIdMutation,
    UpdateCreativeByIdMutationVariables,
    UpdateCreativeByIdProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateCreativeByIdMutation,
    UpdateCreativeByIdMutationVariables,
    UpdateCreativeByIdProps<TChildProps, TDataName>
  >(UpdateCreativeByIdDocument, {
    alias: 'updateCreativeById',
    ...operationOptions,
  })
}

/**
 * __useUpdateCreativeByIdMutation__
 *
 * To run a mutation, you first call `useUpdateCreativeByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCreativeByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCreativeByIdMutation, { data, loading, error }] = useUpdateCreativeByIdMutation({
 *   variables: {
 *      _id: // value for '_id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCreativeByIdMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCreativeByIdMutation,
    UpdateCreativeByIdMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateCreativeByIdMutation,
    UpdateCreativeByIdMutationVariables
  >(UpdateCreativeByIdDocument, baseOptions)
}
export type UpdateCreativeByIdMutationHookResult = ReturnType<
  typeof useUpdateCreativeByIdMutation
>
export type UpdateCreativeByIdMutationResult = ApolloReactCommon.MutationResult<UpdateCreativeByIdMutation>
export type UpdateCreativeByIdMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCreativeByIdMutation,
  UpdateCreativeByIdMutationVariables
>
export const CountDocument = gql`
  query count {
    campaigns: Dashboard__CountCampaigns
    creatives: Dashboard__CountCreatives
    networks: Dashboard__CountNetworks
    players: Dashboard__CountPlayers
  }
`
export type CountComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<CountQuery, CountQueryVariables>,
  'query'
>

export const CountComponent = (props: CountComponentProps) => (
  <ApolloReactComponents.Query<CountQuery, CountQueryVariables>
    query={CountDocument}
    {...props}
  />
)

export type CountProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<CountQuery, CountQueryVariables>
} &
  TChildProps
export function withCount<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CountQuery,
    CountQueryVariables,
    CountProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CountQuery,
    CountQueryVariables,
    CountProps<TChildProps, TDataName>
  >(CountDocument, {
    alias: 'count',
    ...operationOptions,
  })
}

/**
 * __useCountQuery__
 *
 * To run a query within a React component, call `useCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CountQuery,
    CountQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<CountQuery, CountQueryVariables>(
    CountDocument,
    baseOptions
  )
}
export function useCountLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CountQuery,
    CountQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<CountQuery, CountQueryVariables>(
    CountDocument,
    baseOptions
  )
}
export type CountQueryHookResult = ReturnType<typeof useCountQuery>
export type CountLazyQueryHookResult = ReturnType<typeof useCountLazyQuery>
export type CountQueryResult = ApolloReactCommon.QueryResult<
  CountQuery,
  CountQueryVariables
>
export const WhoAmIDocument = gql`
  query WhoAmI {
    user: Dashboard__WhoAmI {
      _id
      is_active
      name {
        first
        last
      }
      platform_roles {
        admin
        curator
      }
      workspaces {
        _id
        name
        role
        added_at
      }
      invites {
        _id
        status
        workspace_id
      }
      phones {
        number
        verified_at
      }
      emails {
        address
        verified_at
      }
      phones {
        number
        verified_at
      }
      primary_email_index
      primary_phone_index
    }
  }
`
export type WhoAmIComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    WhoAmIQuery,
    WhoAmIQueryVariables
  >,
  'query'
>

export const WhoAmIComponent = (props: WhoAmIComponentProps) => (
  <ApolloReactComponents.Query<WhoAmIQuery, WhoAmIQueryVariables>
    query={WhoAmIDocument}
    {...props}
  />
)

export type WhoAmIProps<TChildProps = {}, TDataName extends string = 'data'> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    WhoAmIQuery,
    WhoAmIQueryVariables
  >
} &
  TChildProps
export function withWhoAmI<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    WhoAmIQuery,
    WhoAmIQueryVariables,
    WhoAmIProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    WhoAmIQuery,
    WhoAmIQueryVariables,
    WhoAmIProps<TChildProps, TDataName>
  >(WhoAmIDocument, {
    alias: 'whoAmI',
    ...operationOptions,
  })
}

/**
 * __useWhoAmIQuery__
 *
 * To run a query within a React component, call `useWhoAmIQuery` and pass it any options that fit your needs.
 * When your component renders, `useWhoAmIQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWhoAmIQuery({
 *   variables: {
 *   },
 * });
 */
export function useWhoAmIQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    WhoAmIQuery,
    WhoAmIQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<WhoAmIQuery, WhoAmIQueryVariables>(
    WhoAmIDocument,
    baseOptions
  )
}
export function useWhoAmILazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    WhoAmIQuery,
    WhoAmIQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<WhoAmIQuery, WhoAmIQueryVariables>(
    WhoAmIDocument,
    baseOptions
  )
}
export type WhoAmIQueryHookResult = ReturnType<typeof useWhoAmIQuery>
export type WhoAmILazyQueryHookResult = ReturnType<typeof useWhoAmILazyQuery>
export type WhoAmIQueryResult = ApolloReactCommon.QueryResult<
  WhoAmIQuery,
  WhoAmIQueryVariables
>
export const ViewAreaTypeDocument = gql`
  query ViewAreaType($_id: MongoID!) {
    areaType: Dashboard__FindAreaType(_id: $_id) {
      ...AreaTypeFullFragment
    }
  }
  ${AreaTypeFullFragmentFragmentDoc}
`
export type ViewAreaTypeComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ViewAreaTypeQuery,
    ViewAreaTypeQueryVariables
  >,
  'query'
> &
  (
    | { variables: ViewAreaTypeQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const ViewAreaTypeComponent = (props: ViewAreaTypeComponentProps) => (
  <ApolloReactComponents.Query<ViewAreaTypeQuery, ViewAreaTypeQueryVariables>
    query={ViewAreaTypeDocument}
    {...props}
  />
)

export type ViewAreaTypeProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ViewAreaTypeQuery,
    ViewAreaTypeQueryVariables
  >
} &
  TChildProps
export function withViewAreaType<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ViewAreaTypeQuery,
    ViewAreaTypeQueryVariables,
    ViewAreaTypeProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ViewAreaTypeQuery,
    ViewAreaTypeQueryVariables,
    ViewAreaTypeProps<TChildProps, TDataName>
  >(ViewAreaTypeDocument, {
    alias: 'viewAreaType',
    ...operationOptions,
  })
}

/**
 * __useViewAreaTypeQuery__
 *
 * To run a query within a React component, call `useViewAreaTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewAreaTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewAreaTypeQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useViewAreaTypeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ViewAreaTypeQuery,
    ViewAreaTypeQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ViewAreaTypeQuery,
    ViewAreaTypeQueryVariables
  >(ViewAreaTypeDocument, baseOptions)
}
export function useViewAreaTypeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ViewAreaTypeQuery,
    ViewAreaTypeQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ViewAreaTypeQuery,
    ViewAreaTypeQueryVariables
  >(ViewAreaTypeDocument, baseOptions)
}
export type ViewAreaTypeQueryHookResult = ReturnType<
  typeof useViewAreaTypeQuery
>
export type ViewAreaTypeLazyQueryHookResult = ReturnType<
  typeof useViewAreaTypeLazyQuery
>
export type ViewAreaTypeQueryResult = ApolloReactCommon.QueryResult<
  ViewAreaTypeQuery,
  ViewAreaTypeQueryVariables
>
export const ListAreaTypesDocument = gql`
  query ListAreaTypes(
    $skip: Int
    $limit: Int
    $sort: SortFindManyAreaTypeInput
    $filter: FilterFindManyAreaTypeInput
  ) {
    areaTypes: Dashboard__FindAreaTypes(
      skip: $skip
      limit: $limit
      sort: $sort
      filter: $filter
    ) {
      ...AreaTypeFullFragment
    }
  }
  ${AreaTypeFullFragmentFragmentDoc}
`
export type ListAreaTypesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ListAreaTypesQuery,
    ListAreaTypesQueryVariables
  >,
  'query'
>

export const ListAreaTypesComponent = (props: ListAreaTypesComponentProps) => (
  <ApolloReactComponents.Query<ListAreaTypesQuery, ListAreaTypesQueryVariables>
    query={ListAreaTypesDocument}
    {...props}
  />
)

export type ListAreaTypesProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ListAreaTypesQuery,
    ListAreaTypesQueryVariables
  >
} &
  TChildProps
export function withListAreaTypes<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ListAreaTypesQuery,
    ListAreaTypesQueryVariables,
    ListAreaTypesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ListAreaTypesQuery,
    ListAreaTypesQueryVariables,
    ListAreaTypesProps<TChildProps, TDataName>
  >(ListAreaTypesDocument, {
    alias: 'listAreaTypes',
    ...operationOptions,
  })
}

/**
 * __useListAreaTypesQuery__
 *
 * To run a query within a React component, call `useListAreaTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAreaTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAreaTypesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListAreaTypesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListAreaTypesQuery,
    ListAreaTypesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ListAreaTypesQuery,
    ListAreaTypesQueryVariables
  >(ListAreaTypesDocument, baseOptions)
}
export function useListAreaTypesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListAreaTypesQuery,
    ListAreaTypesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ListAreaTypesQuery,
    ListAreaTypesQueryVariables
  >(ListAreaTypesDocument, baseOptions)
}
export type ListAreaTypesQueryHookResult = ReturnType<
  typeof useListAreaTypesQuery
>
export type ListAreaTypesLazyQueryHookResult = ReturnType<
  typeof useListAreaTypesLazyQuery
>
export type ListAreaTypesQueryResult = ApolloReactCommon.QueryResult<
  ListAreaTypesQuery,
  ListAreaTypesQueryVariables
>
export const ViewVenueTypeDocument = gql`
  query ViewVenueType($_id: MongoID!) {
    venueType: Dashboard__FindVenueType(_id: $_id) {
      ...VenueTypeFullFragment
    }
  }
  ${VenueTypeFullFragmentFragmentDoc}
`
export type ViewVenueTypeComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ViewVenueTypeQuery,
    ViewVenueTypeQueryVariables
  >,
  'query'
> &
  (
    | { variables: ViewVenueTypeQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const ViewVenueTypeComponent = (props: ViewVenueTypeComponentProps) => (
  <ApolloReactComponents.Query<ViewVenueTypeQuery, ViewVenueTypeQueryVariables>
    query={ViewVenueTypeDocument}
    {...props}
  />
)

export type ViewVenueTypeProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ViewVenueTypeQuery,
    ViewVenueTypeQueryVariables
  >
} &
  TChildProps
export function withViewVenueType<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ViewVenueTypeQuery,
    ViewVenueTypeQueryVariables,
    ViewVenueTypeProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ViewVenueTypeQuery,
    ViewVenueTypeQueryVariables,
    ViewVenueTypeProps<TChildProps, TDataName>
  >(ViewVenueTypeDocument, {
    alias: 'viewVenueType',
    ...operationOptions,
  })
}

/**
 * __useViewVenueTypeQuery__
 *
 * To run a query within a React component, call `useViewVenueTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewVenueTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewVenueTypeQuery({
 *   variables: {
 *      _id: // value for '_id'
 *   },
 * });
 */
export function useViewVenueTypeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ViewVenueTypeQuery,
    ViewVenueTypeQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ViewVenueTypeQuery,
    ViewVenueTypeQueryVariables
  >(ViewVenueTypeDocument, baseOptions)
}
export function useViewVenueTypeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ViewVenueTypeQuery,
    ViewVenueTypeQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ViewVenueTypeQuery,
    ViewVenueTypeQueryVariables
  >(ViewVenueTypeDocument, baseOptions)
}
export type ViewVenueTypeQueryHookResult = ReturnType<
  typeof useViewVenueTypeQuery
>
export type ViewVenueTypeLazyQueryHookResult = ReturnType<
  typeof useViewVenueTypeLazyQuery
>
export type ViewVenueTypeQueryResult = ApolloReactCommon.QueryResult<
  ViewVenueTypeQuery,
  ViewVenueTypeQueryVariables
>
export const ListVenueTypesDocument = gql`
  query ListVenueTypes(
    $skip: Int
    $limit: Int
    $sort: SortFindManyVenueTypeInput
    $filter: FilterFindManyVenueTypeInput
  ) {
    venueTypes: Dashboard__FindVenueTypes(
      skip: $skip
      limit: $limit
      sort: $sort
      filter: $filter
    ) {
      ...VenueTypeFullFragment
    }
  }
  ${VenueTypeFullFragmentFragmentDoc}
`
export type ListVenueTypesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ListVenueTypesQuery,
    ListVenueTypesQueryVariables
  >,
  'query'
>

export const ListVenueTypesComponent = (
  props: ListVenueTypesComponentProps
) => (
  <ApolloReactComponents.Query<
    ListVenueTypesQuery,
    ListVenueTypesQueryVariables
  >
    query={ListVenueTypesDocument}
    {...props}
  />
)

export type ListVenueTypesProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ListVenueTypesQuery,
    ListVenueTypesQueryVariables
  >
} &
  TChildProps
export function withListVenueTypes<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ListVenueTypesQuery,
    ListVenueTypesQueryVariables,
    ListVenueTypesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ListVenueTypesQuery,
    ListVenueTypesQueryVariables,
    ListVenueTypesProps<TChildProps, TDataName>
  >(ListVenueTypesDocument, {
    alias: 'listVenueTypes',
    ...operationOptions,
  })
}

/**
 * __useListVenueTypesQuery__
 *
 * To run a query within a React component, call `useListVenueTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListVenueTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListVenueTypesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListVenueTypesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListVenueTypesQuery,
    ListVenueTypesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ListVenueTypesQuery,
    ListVenueTypesQueryVariables
  >(ListVenueTypesDocument, baseOptions)
}
export function useListVenueTypesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListVenueTypesQuery,
    ListVenueTypesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ListVenueTypesQuery,
    ListVenueTypesQueryVariables
  >(ListVenueTypesDocument, baseOptions)
}
export type ListVenueTypesQueryHookResult = ReturnType<
  typeof useListVenueTypesQuery
>
export type ListVenueTypesLazyQueryHookResult = ReturnType<
  typeof useListVenueTypesLazyQuery
>
export type ListVenueTypesQueryResult = ApolloReactCommon.QueryResult<
  ListVenueTypesQuery,
  ListVenueTypesQueryVariables
>
export const ViewCampaignDocument = gql`
  query ViewCampaign($_id: MongoID!, $intervals: [StatsIntervalInput!]! = []) {
    campaign: Dashboard__FindCampaign(filter: { _id: $_id }) {
      ...CampaignFullFragment
    }
  }
  ${CampaignFullFragmentFragmentDoc}
`
export type ViewCampaignComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ViewCampaignQuery,
    ViewCampaignQueryVariables
  >,
  'query'
> &
  (
    | { variables: ViewCampaignQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const ViewCampaignComponent = (props: ViewCampaignComponentProps) => (
  <ApolloReactComponents.Query<ViewCampaignQuery, ViewCampaignQueryVariables>
    query={ViewCampaignDocument}
    {...props}
  />
)

export type ViewCampaignProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ViewCampaignQuery,
    ViewCampaignQueryVariables
  >
} &
  TChildProps
export function withViewCampaign<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ViewCampaignQuery,
    ViewCampaignQueryVariables,
    ViewCampaignProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ViewCampaignQuery,
    ViewCampaignQueryVariables,
    ViewCampaignProps<TChildProps, TDataName>
  >(ViewCampaignDocument, {
    alias: 'viewCampaign',
    ...operationOptions,
  })
}

/**
 * __useViewCampaignQuery__
 *
 * To run a query within a React component, call `useViewCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewCampaignQuery({
 *   variables: {
 *      _id: // value for '_id'
 *      intervals: // value for 'intervals'
 *   },
 * });
 */
export function useViewCampaignQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ViewCampaignQuery,
    ViewCampaignQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ViewCampaignQuery,
    ViewCampaignQueryVariables
  >(ViewCampaignDocument, baseOptions)
}
export function useViewCampaignLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ViewCampaignQuery,
    ViewCampaignQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ViewCampaignQuery,
    ViewCampaignQueryVariables
  >(ViewCampaignDocument, baseOptions)
}
export type ViewCampaignQueryHookResult = ReturnType<
  typeof useViewCampaignQuery
>
export type ViewCampaignLazyQueryHookResult = ReturnType<
  typeof useViewCampaignLazyQuery
>
export type ViewCampaignQueryResult = ApolloReactCommon.QueryResult<
  ViewCampaignQuery,
  ViewCampaignQueryVariables
>
export const ListCampaignsDocument = gql`
  query ListCampaigns(
    $offset: Int! = 0
    $limit: Int! = 25
    $filter: FilterFindManyCampaignInput! = {}
    $intervals: [StatsIntervalInput!]! = []
  ) {
    campaigns: Dashboard__FindCampaigns(
      skip: $offset
      limit: $limit
      sort: _ID_DESC
      filter: $filter
    ) {
      ...CampaignFullFragment
    }
  }
  ${CampaignFullFragmentFragmentDoc}
`
export type ListCampaignsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ListCampaignsQuery,
    ListCampaignsQueryVariables
  >,
  'query'
> &
  (
    | { variables: ListCampaignsQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const ListCampaignsComponent = (props: ListCampaignsComponentProps) => (
  <ApolloReactComponents.Query<ListCampaignsQuery, ListCampaignsQueryVariables>
    query={ListCampaignsDocument}
    {...props}
  />
)

export type ListCampaignsProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ListCampaignsQuery,
    ListCampaignsQueryVariables
  >
} &
  TChildProps
export function withListCampaigns<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ListCampaignsQuery,
    ListCampaignsQueryVariables,
    ListCampaignsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ListCampaignsQuery,
    ListCampaignsQueryVariables,
    ListCampaignsProps<TChildProps, TDataName>
  >(ListCampaignsDocument, {
    alias: 'listCampaigns',
    ...operationOptions,
  })
}

/**
 * __useListCampaignsQuery__
 *
 * To run a query within a React component, call `useListCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCampaignsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      intervals: // value for 'intervals'
 *   },
 * });
 */
export function useListCampaignsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListCampaignsQuery,
    ListCampaignsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ListCampaignsQuery,
    ListCampaignsQueryVariables
  >(ListCampaignsDocument, baseOptions)
}
export function useListCampaignsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListCampaignsQuery,
    ListCampaignsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ListCampaignsQuery,
    ListCampaignsQueryVariables
  >(ListCampaignsDocument, baseOptions)
}
export type ListCampaignsQueryHookResult = ReturnType<
  typeof useListCampaignsQuery
>
export type ListCampaignsLazyQueryHookResult = ReturnType<
  typeof useListCampaignsLazyQuery
>
export type ListCampaignsQueryResult = ApolloReactCommon.QueryResult<
  ListCampaignsQuery,
  ListCampaignsQueryVariables
>
export const ViewCampaignAdGroupDocument = gql`
  query ViewCampaignAdGroup(
    $_id: MongoID!
    $intervals: [StatsIntervalInput!]! = []
  ) {
    campaignAdGroup: Dashboard__FindCampaignAdGroup(filter: { _id: $_id }) {
      ...CampaignAdGroupFullFragment
      creatives {
        ...CreativeListFragment
      }
    }
  }
  ${CampaignAdGroupFullFragmentFragmentDoc}
  ${CreativeListFragmentFragmentDoc}
`
export type ViewCampaignAdGroupComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ViewCampaignAdGroupQuery,
    ViewCampaignAdGroupQueryVariables
  >,
  'query'
> &
  (
    | { variables: ViewCampaignAdGroupQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const ViewCampaignAdGroupComponent = (
  props: ViewCampaignAdGroupComponentProps
) => (
  <ApolloReactComponents.Query<
    ViewCampaignAdGroupQuery,
    ViewCampaignAdGroupQueryVariables
  >
    query={ViewCampaignAdGroupDocument}
    {...props}
  />
)

export type ViewCampaignAdGroupProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ViewCampaignAdGroupQuery,
    ViewCampaignAdGroupQueryVariables
  >
} &
  TChildProps
export function withViewCampaignAdGroup<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ViewCampaignAdGroupQuery,
    ViewCampaignAdGroupQueryVariables,
    ViewCampaignAdGroupProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ViewCampaignAdGroupQuery,
    ViewCampaignAdGroupQueryVariables,
    ViewCampaignAdGroupProps<TChildProps, TDataName>
  >(ViewCampaignAdGroupDocument, {
    alias: 'viewCampaignAdGroup',
    ...operationOptions,
  })
}

/**
 * __useViewCampaignAdGroupQuery__
 *
 * To run a query within a React component, call `useViewCampaignAdGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewCampaignAdGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewCampaignAdGroupQuery({
 *   variables: {
 *      _id: // value for '_id'
 *      intervals: // value for 'intervals'
 *   },
 * });
 */
export function useViewCampaignAdGroupQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ViewCampaignAdGroupQuery,
    ViewCampaignAdGroupQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ViewCampaignAdGroupQuery,
    ViewCampaignAdGroupQueryVariables
  >(ViewCampaignAdGroupDocument, baseOptions)
}
export function useViewCampaignAdGroupLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ViewCampaignAdGroupQuery,
    ViewCampaignAdGroupQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ViewCampaignAdGroupQuery,
    ViewCampaignAdGroupQueryVariables
  >(ViewCampaignAdGroupDocument, baseOptions)
}
export type ViewCampaignAdGroupQueryHookResult = ReturnType<
  typeof useViewCampaignAdGroupQuery
>
export type ViewCampaignAdGroupLazyQueryHookResult = ReturnType<
  typeof useViewCampaignAdGroupLazyQuery
>
export type ViewCampaignAdGroupQueryResult = ApolloReactCommon.QueryResult<
  ViewCampaignAdGroupQuery,
  ViewCampaignAdGroupQueryVariables
>
export const ListCampaignAdGroupsDocument = gql`
  query ListCampaignAdGroups(
    $offset: Int! = 0
    $limit: Int! = 25
    $filter: FilterFindManyCampaignAdGroupInput! = {}
    $intervals: [StatsIntervalInput!]! = []
  ) {
    campaignAdGroups: Dashboard__FindCampaignAdGroups(
      skip: $offset
      limit: $limit
      sort: _ID_DESC
      filter: $filter
    ) {
      ...CampaignAdGroupListFragment
    }
  }
  ${CampaignAdGroupListFragmentFragmentDoc}
`
export type ListCampaignAdGroupsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ListCampaignAdGroupsQuery,
    ListCampaignAdGroupsQueryVariables
  >,
  'query'
> &
  (
    | { variables: ListCampaignAdGroupsQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const ListCampaignAdGroupsComponent = (
  props: ListCampaignAdGroupsComponentProps
) => (
  <ApolloReactComponents.Query<
    ListCampaignAdGroupsQuery,
    ListCampaignAdGroupsQueryVariables
  >
    query={ListCampaignAdGroupsDocument}
    {...props}
  />
)

export type ListCampaignAdGroupsProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ListCampaignAdGroupsQuery,
    ListCampaignAdGroupsQueryVariables
  >
} &
  TChildProps
export function withListCampaignAdGroups<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ListCampaignAdGroupsQuery,
    ListCampaignAdGroupsQueryVariables,
    ListCampaignAdGroupsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ListCampaignAdGroupsQuery,
    ListCampaignAdGroupsQueryVariables,
    ListCampaignAdGroupsProps<TChildProps, TDataName>
  >(ListCampaignAdGroupsDocument, {
    alias: 'listCampaignAdGroups',
    ...operationOptions,
  })
}

/**
 * __useListCampaignAdGroupsQuery__
 *
 * To run a query within a React component, call `useListCampaignAdGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCampaignAdGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCampaignAdGroupsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      intervals: // value for 'intervals'
 *   },
 * });
 */
export function useListCampaignAdGroupsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListCampaignAdGroupsQuery,
    ListCampaignAdGroupsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ListCampaignAdGroupsQuery,
    ListCampaignAdGroupsQueryVariables
  >(ListCampaignAdGroupsDocument, baseOptions)
}
export function useListCampaignAdGroupsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListCampaignAdGroupsQuery,
    ListCampaignAdGroupsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ListCampaignAdGroupsQuery,
    ListCampaignAdGroupsQueryVariables
  >(ListCampaignAdGroupsDocument, baseOptions)
}
export type ListCampaignAdGroupsQueryHookResult = ReturnType<
  typeof useListCampaignAdGroupsQuery
>
export type ListCampaignAdGroupsLazyQueryHookResult = ReturnType<
  typeof useListCampaignAdGroupsLazyQuery
>
export type ListCampaignAdGroupsQueryResult = ApolloReactCommon.QueryResult<
  ListCampaignAdGroupsQuery,
  ListCampaignAdGroupsQueryVariables
>
export const ViewCreativeDocument = gql`
  query ViewCreative($_id: MongoID!, $intervals: [StatsIntervalInput!]! = []) {
    creative: Dashboard__FindCreative(filter: { _id: $_id }) {
      ...CreativeFullFragment
    }
  }
  ${CreativeFullFragmentFragmentDoc}
`
export type ViewCreativeComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ViewCreativeQuery,
    ViewCreativeQueryVariables
  >,
  'query'
> &
  (
    | { variables: ViewCreativeQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const ViewCreativeComponent = (props: ViewCreativeComponentProps) => (
  <ApolloReactComponents.Query<ViewCreativeQuery, ViewCreativeQueryVariables>
    query={ViewCreativeDocument}
    {...props}
  />
)

export type ViewCreativeProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ViewCreativeQuery,
    ViewCreativeQueryVariables
  >
} &
  TChildProps
export function withViewCreative<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ViewCreativeQuery,
    ViewCreativeQueryVariables,
    ViewCreativeProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ViewCreativeQuery,
    ViewCreativeQueryVariables,
    ViewCreativeProps<TChildProps, TDataName>
  >(ViewCreativeDocument, {
    alias: 'viewCreative',
    ...operationOptions,
  })
}

/**
 * __useViewCreativeQuery__
 *
 * To run a query within a React component, call `useViewCreativeQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewCreativeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewCreativeQuery({
 *   variables: {
 *      _id: // value for '_id'
 *      intervals: // value for 'intervals'
 *   },
 * });
 */
export function useViewCreativeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ViewCreativeQuery,
    ViewCreativeQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ViewCreativeQuery,
    ViewCreativeQueryVariables
  >(ViewCreativeDocument, baseOptions)
}
export function useViewCreativeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ViewCreativeQuery,
    ViewCreativeQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ViewCreativeQuery,
    ViewCreativeQueryVariables
  >(ViewCreativeDocument, baseOptions)
}
export type ViewCreativeQueryHookResult = ReturnType<
  typeof useViewCreativeQuery
>
export type ViewCreativeLazyQueryHookResult = ReturnType<
  typeof useViewCreativeLazyQuery
>
export type ViewCreativeQueryResult = ApolloReactCommon.QueryResult<
  ViewCreativeQuery,
  ViewCreativeQueryVariables
>
export const ListCreativesDocument = gql`
  query ListCreatives(
    $offset: Int! = 0
    $limit: Int! = 25
    $filter: FilterFindManyCreativeInput! = {}
    $intervals: [StatsIntervalInput!]! = []
  ) {
    creatives: Dashboard__FindCreatives(
      skip: $offset
      limit: $limit
      sort: _ID_DESC
      filter: $filter
    ) {
      ...CreativeFullFragment
    }
  }
  ${CreativeFullFragmentFragmentDoc}
`
export type ListCreativesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ListCreativesQuery,
    ListCreativesQueryVariables
  >,
  'query'
> &
  (
    | { variables: ListCreativesQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const ListCreativesComponent = (props: ListCreativesComponentProps) => (
  <ApolloReactComponents.Query<ListCreativesQuery, ListCreativesQueryVariables>
    query={ListCreativesDocument}
    {...props}
  />
)

export type ListCreativesProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ListCreativesQuery,
    ListCreativesQueryVariables
  >
} &
  TChildProps
export function withListCreatives<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ListCreativesQuery,
    ListCreativesQueryVariables,
    ListCreativesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ListCreativesQuery,
    ListCreativesQueryVariables,
    ListCreativesProps<TChildProps, TDataName>
  >(ListCreativesDocument, {
    alias: 'listCreatives',
    ...operationOptions,
  })
}

/**
 * __useListCreativesQuery__
 *
 * To run a query within a React component, call `useListCreativesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCreativesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCreativesQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      intervals: // value for 'intervals'
 *   },
 * });
 */
export function useListCreativesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListCreativesQuery,
    ListCreativesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ListCreativesQuery,
    ListCreativesQueryVariables
  >(ListCreativesDocument, baseOptions)
}
export function useListCreativesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListCreativesQuery,
    ListCreativesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ListCreativesQuery,
    ListCreativesQueryVariables
  >(ListCreativesDocument, baseOptions)
}
export type ListCreativesQueryHookResult = ReturnType<
  typeof useListCreativesQuery
>
export type ListCreativesLazyQueryHookResult = ReturnType<
  typeof useListCreativesLazyQuery
>
export type ListCreativesQueryResult = ApolloReactCommon.QueryResult<
  ListCreativesQuery,
  ListCreativesQueryVariables
>
export const ViewNetworkDocument = gql`
  query ViewNetwork($_id: MongoID!, $intervals: [StatsIntervalInput!]! = []) {
    network: Dashboard__FindNetwork(filter: { _id: $_id }) {
      ...NetworkFullFragment
    }
  }
  ${NetworkFullFragmentFragmentDoc}
`
export type ViewNetworkComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ViewNetworkQuery,
    ViewNetworkQueryVariables
  >,
  'query'
> &
  ({ variables: ViewNetworkQueryVariables; skip?: boolean } | { skip: boolean })

export const ViewNetworkComponent = (props: ViewNetworkComponentProps) => (
  <ApolloReactComponents.Query<ViewNetworkQuery, ViewNetworkQueryVariables>
    query={ViewNetworkDocument}
    {...props}
  />
)

export type ViewNetworkProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ViewNetworkQuery,
    ViewNetworkQueryVariables
  >
} &
  TChildProps
export function withViewNetwork<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ViewNetworkQuery,
    ViewNetworkQueryVariables,
    ViewNetworkProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ViewNetworkQuery,
    ViewNetworkQueryVariables,
    ViewNetworkProps<TChildProps, TDataName>
  >(ViewNetworkDocument, {
    alias: 'viewNetwork',
    ...operationOptions,
  })
}

/**
 * __useViewNetworkQuery__
 *
 * To run a query within a React component, call `useViewNetworkQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewNetworkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewNetworkQuery({
 *   variables: {
 *      _id: // value for '_id'
 *      intervals: // value for 'intervals'
 *   },
 * });
 */
export function useViewNetworkQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ViewNetworkQuery,
    ViewNetworkQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ViewNetworkQuery, ViewNetworkQueryVariables>(
    ViewNetworkDocument,
    baseOptions
  )
}
export function useViewNetworkLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ViewNetworkQuery,
    ViewNetworkQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ViewNetworkQuery,
    ViewNetworkQueryVariables
  >(ViewNetworkDocument, baseOptions)
}
export type ViewNetworkQueryHookResult = ReturnType<typeof useViewNetworkQuery>
export type ViewNetworkLazyQueryHookResult = ReturnType<
  typeof useViewNetworkLazyQuery
>
export type ViewNetworkQueryResult = ApolloReactCommon.QueryResult<
  ViewNetworkQuery,
  ViewNetworkQueryVariables
>
export const ListNetworksDocument = gql`
  query ListNetworks(
    $offset: Int! = 0
    $limit: Int! = 25
    $filter: FilterFindManyNetworkInput! = {}
    $intervals: [StatsIntervalInput!]! = []
  ) {
    networks: Dashboard__FindNetworks(
      skip: $offset
      limit: $limit
      sort: _ID_DESC
      filter: $filter
    ) {
      ...NetworkListFragment
    }
  }
  ${NetworkListFragmentFragmentDoc}
`
export type ListNetworksComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ListNetworksQuery,
    ListNetworksQueryVariables
  >,
  'query'
> &
  (
    | { variables: ListNetworksQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const ListNetworksComponent = (props: ListNetworksComponentProps) => (
  <ApolloReactComponents.Query<ListNetworksQuery, ListNetworksQueryVariables>
    query={ListNetworksDocument}
    {...props}
  />
)

export type ListNetworksProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ListNetworksQuery,
    ListNetworksQueryVariables
  >
} &
  TChildProps
export function withListNetworks<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ListNetworksQuery,
    ListNetworksQueryVariables,
    ListNetworksProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ListNetworksQuery,
    ListNetworksQueryVariables,
    ListNetworksProps<TChildProps, TDataName>
  >(ListNetworksDocument, {
    alias: 'listNetworks',
    ...operationOptions,
  })
}

/**
 * __useListNetworksQuery__
 *
 * To run a query within a React component, call `useListNetworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useListNetworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListNetworksQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      intervals: // value for 'intervals'
 *   },
 * });
 */
export function useListNetworksQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListNetworksQuery,
    ListNetworksQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ListNetworksQuery,
    ListNetworksQueryVariables
  >(ListNetworksDocument, baseOptions)
}
export function useListNetworksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListNetworksQuery,
    ListNetworksQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ListNetworksQuery,
    ListNetworksQueryVariables
  >(ListNetworksDocument, baseOptions)
}
export type ListNetworksQueryHookResult = ReturnType<
  typeof useListNetworksQuery
>
export type ListNetworksLazyQueryHookResult = ReturnType<
  typeof useListNetworksLazyQuery
>
export type ListNetworksQueryResult = ApolloReactCommon.QueryResult<
  ListNetworksQuery,
  ListNetworksQueryVariables
>
export const ViewPlayerDocument = gql`
  query ViewPlayer($_id: MongoID!, $intervals: [StatsIntervalInput!]! = []) {
    player: Dashboard__FindPlayer(filter: { _id: $_id }) {
      ...PlayerFullFragment
    }
  }
  ${PlayerFullFragmentFragmentDoc}
`
export type ViewPlayerComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ViewPlayerQuery,
    ViewPlayerQueryVariables
  >,
  'query'
> &
  ({ variables: ViewPlayerQueryVariables; skip?: boolean } | { skip: boolean })

export const ViewPlayerComponent = (props: ViewPlayerComponentProps) => (
  <ApolloReactComponents.Query<ViewPlayerQuery, ViewPlayerQueryVariables>
    query={ViewPlayerDocument}
    {...props}
  />
)

export type ViewPlayerProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ViewPlayerQuery,
    ViewPlayerQueryVariables
  >
} &
  TChildProps
export function withViewPlayer<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ViewPlayerQuery,
    ViewPlayerQueryVariables,
    ViewPlayerProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ViewPlayerQuery,
    ViewPlayerQueryVariables,
    ViewPlayerProps<TChildProps, TDataName>
  >(ViewPlayerDocument, {
    alias: 'viewPlayer',
    ...operationOptions,
  })
}

/**
 * __useViewPlayerQuery__
 *
 * To run a query within a React component, call `useViewPlayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewPlayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewPlayerQuery({
 *   variables: {
 *      _id: // value for '_id'
 *      intervals: // value for 'intervals'
 *   },
 * });
 */
export function useViewPlayerQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ViewPlayerQuery,
    ViewPlayerQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ViewPlayerQuery, ViewPlayerQueryVariables>(
    ViewPlayerDocument,
    baseOptions
  )
}
export function useViewPlayerLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ViewPlayerQuery,
    ViewPlayerQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ViewPlayerQuery,
    ViewPlayerQueryVariables
  >(ViewPlayerDocument, baseOptions)
}
export type ViewPlayerQueryHookResult = ReturnType<typeof useViewPlayerQuery>
export type ViewPlayerLazyQueryHookResult = ReturnType<
  typeof useViewPlayerLazyQuery
>
export type ViewPlayerQueryResult = ApolloReactCommon.QueryResult<
  ViewPlayerQuery,
  ViewPlayerQueryVariables
>
export const ListPlayersDocument = gql`
  query ListPlayers(
    $offset: Int! = 0
    $limit: Int! = 25
    $filter: FilterFindManyPlayerInput! = {}
    $intervals: [StatsIntervalInput!]! = []
  ) {
    players: Dashboard__FindPlayers(
      filter: $filter
      skip: $offset
      limit: $limit
      sort: _ID_DESC
    ) {
      ...PlayerFullFragment
    }
  }
  ${PlayerFullFragmentFragmentDoc}
`
export type ListPlayersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ListPlayersQuery,
    ListPlayersQueryVariables
  >,
  'query'
> &
  ({ variables: ListPlayersQueryVariables; skip?: boolean } | { skip: boolean })

export const ListPlayersComponent = (props: ListPlayersComponentProps) => (
  <ApolloReactComponents.Query<ListPlayersQuery, ListPlayersQueryVariables>
    query={ListPlayersDocument}
    {...props}
  />
)

export type ListPlayersProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ListPlayersQuery,
    ListPlayersQueryVariables
  >
} &
  TChildProps
export function withListPlayers<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ListPlayersQuery,
    ListPlayersQueryVariables,
    ListPlayersProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ListPlayersQuery,
    ListPlayersQueryVariables,
    ListPlayersProps<TChildProps, TDataName>
  >(ListPlayersDocument, {
    alias: 'listPlayers',
    ...operationOptions,
  })
}

/**
 * __useListPlayersQuery__
 *
 * To run a query within a React component, call `useListPlayersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPlayersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPlayersQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      intervals: // value for 'intervals'
 *   },
 * });
 */
export function useListPlayersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListPlayersQuery,
    ListPlayersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ListPlayersQuery, ListPlayersQueryVariables>(
    ListPlayersDocument,
    baseOptions
  )
}
export function useListPlayersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListPlayersQuery,
    ListPlayersQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ListPlayersQuery,
    ListPlayersQueryVariables
  >(ListPlayersDocument, baseOptions)
}
export type ListPlayersQueryHookResult = ReturnType<typeof useListPlayersQuery>
export type ListPlayersLazyQueryHookResult = ReturnType<
  typeof useListPlayersLazyQuery
>
export type ListPlayersQueryResult = ApolloReactCommon.QueryResult<
  ListPlayersQuery,
  ListPlayersQueryVariables
>
export const ViewCountryDocument = gql`
  query ViewCountry($_id: MongoID, $code: String) {
    country: Dashboard__FindCountry(
      filter: { OR: [{ _id: $_id }, { code: $code }] }
    ) {
      ...CountryFullFragment
    }
  }
  ${CountryFullFragmentFragmentDoc}
`
export type ViewCountryComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ViewCountryQuery,
    ViewCountryQueryVariables
  >,
  'query'
>

export const ViewCountryComponent = (props: ViewCountryComponentProps) => (
  <ApolloReactComponents.Query<ViewCountryQuery, ViewCountryQueryVariables>
    query={ViewCountryDocument}
    {...props}
  />
)

export type ViewCountryProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ViewCountryQuery,
    ViewCountryQueryVariables
  >
} &
  TChildProps
export function withViewCountry<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ViewCountryQuery,
    ViewCountryQueryVariables,
    ViewCountryProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ViewCountryQuery,
    ViewCountryQueryVariables,
    ViewCountryProps<TChildProps, TDataName>
  >(ViewCountryDocument, {
    alias: 'viewCountry',
    ...operationOptions,
  })
}

/**
 * __useViewCountryQuery__
 *
 * To run a query within a React component, call `useViewCountryQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewCountryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewCountryQuery({
 *   variables: {
 *      _id: // value for '_id'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useViewCountryQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ViewCountryQuery,
    ViewCountryQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ViewCountryQuery, ViewCountryQueryVariables>(
    ViewCountryDocument,
    baseOptions
  )
}
export function useViewCountryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ViewCountryQuery,
    ViewCountryQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ViewCountryQuery,
    ViewCountryQueryVariables
  >(ViewCountryDocument, baseOptions)
}
export type ViewCountryQueryHookResult = ReturnType<typeof useViewCountryQuery>
export type ViewCountryLazyQueryHookResult = ReturnType<
  typeof useViewCountryLazyQuery
>
export type ViewCountryQueryResult = ApolloReactCommon.QueryResult<
  ViewCountryQuery,
  ViewCountryQueryVariables
>
export const ListCountriesDocument = gql`
  query ListCountries(
    $skip: Int = 0
    $filter: FilterFindManyCountryInput = {}
  ) {
    countries: Dashboard__FindCountries(
      filter: $filter
      skip: $skip
      sort: NAME_ASC
    ) {
      ...CountryFullFragment
    }
  }
  ${CountryFullFragmentFragmentDoc}
`
export type ListCountriesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ListCountriesQuery,
    ListCountriesQueryVariables
  >,
  'query'
>

export const ListCountriesComponent = (props: ListCountriesComponentProps) => (
  <ApolloReactComponents.Query<ListCountriesQuery, ListCountriesQueryVariables>
    query={ListCountriesDocument}
    {...props}
  />
)

export type ListCountriesProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ListCountriesQuery,
    ListCountriesQueryVariables
  >
} &
  TChildProps
export function withListCountries<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ListCountriesQuery,
    ListCountriesQueryVariables,
    ListCountriesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ListCountriesQuery,
    ListCountriesQueryVariables,
    ListCountriesProps<TChildProps, TDataName>
  >(ListCountriesDocument, {
    alias: 'listCountries',
    ...operationOptions,
  })
}

/**
 * __useListCountriesQuery__
 *
 * To run a query within a React component, call `useListCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCountriesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListCountriesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListCountriesQuery,
    ListCountriesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ListCountriesQuery,
    ListCountriesQueryVariables
  >(ListCountriesDocument, baseOptions)
}
export function useListCountriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListCountriesQuery,
    ListCountriesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ListCountriesQuery,
    ListCountriesQueryVariables
  >(ListCountriesDocument, baseOptions)
}
export type ListCountriesQueryHookResult = ReturnType<
  typeof useListCountriesQuery
>
export type ListCountriesLazyQueryHookResult = ReturnType<
  typeof useListCountriesLazyQuery
>
export type ListCountriesQueryResult = ApolloReactCommon.QueryResult<
  ListCountriesQuery,
  ListCountriesQueryVariables
>
export const ViewWorkspaceDocument = gql`
  query ViewWorkspace($_id: MongoID!, $intervals: [StatsIntervalInput!]! = []) {
    workspace: Dashboard__FindWorkspace(_id: $_id) {
      ...WorkspaceFullFragment
    }
  }
  ${WorkspaceFullFragmentFragmentDoc}
`
export type ViewWorkspaceComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ViewWorkspaceQuery,
    ViewWorkspaceQueryVariables
  >,
  'query'
> &
  (
    | { variables: ViewWorkspaceQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const ViewWorkspaceComponent = (props: ViewWorkspaceComponentProps) => (
  <ApolloReactComponents.Query<ViewWorkspaceQuery, ViewWorkspaceQueryVariables>
    query={ViewWorkspaceDocument}
    {...props}
  />
)

export type ViewWorkspaceProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ViewWorkspaceQuery,
    ViewWorkspaceQueryVariables
  >
} &
  TChildProps
export function withViewWorkspace<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ViewWorkspaceQuery,
    ViewWorkspaceQueryVariables,
    ViewWorkspaceProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ViewWorkspaceQuery,
    ViewWorkspaceQueryVariables,
    ViewWorkspaceProps<TChildProps, TDataName>
  >(ViewWorkspaceDocument, {
    alias: 'viewWorkspace',
    ...operationOptions,
  })
}

/**
 * __useViewWorkspaceQuery__
 *
 * To run a query within a React component, call `useViewWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewWorkspaceQuery({
 *   variables: {
 *      _id: // value for '_id'
 *      intervals: // value for 'intervals'
 *   },
 * });
 */
export function useViewWorkspaceQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ViewWorkspaceQuery,
    ViewWorkspaceQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ViewWorkspaceQuery,
    ViewWorkspaceQueryVariables
  >(ViewWorkspaceDocument, baseOptions)
}
export function useViewWorkspaceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ViewWorkspaceQuery,
    ViewWorkspaceQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ViewWorkspaceQuery,
    ViewWorkspaceQueryVariables
  >(ViewWorkspaceDocument, baseOptions)
}
export type ViewWorkspaceQueryHookResult = ReturnType<
  typeof useViewWorkspaceQuery
>
export type ViewWorkspaceLazyQueryHookResult = ReturnType<
  typeof useViewWorkspaceLazyQuery
>
export type ViewWorkspaceQueryResult = ApolloReactCommon.QueryResult<
  ViewWorkspaceQuery,
  ViewWorkspaceQueryVariables
>
export const ListWorkspacesDocument = gql`
  query ListWorkspaces(
    $_ids: [MongoID]!
    $limit: Int
    $sort: SortFindByIdsWorkspaceInput
    $intervals: [StatsIntervalInput!]! = []
  ) {
    workspaces: Dashboard__FindWorkspaces(
      _ids: $_ids
      limit: $limit
      sort: $sort
    ) {
      ...WorkspaceFullFragment
    }
  }
  ${WorkspaceFullFragmentFragmentDoc}
`
export type ListWorkspacesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ListWorkspacesQuery,
    ListWorkspacesQueryVariables
  >,
  'query'
> &
  (
    | { variables: ListWorkspacesQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const ListWorkspacesComponent = (
  props: ListWorkspacesComponentProps
) => (
  <ApolloReactComponents.Query<
    ListWorkspacesQuery,
    ListWorkspacesQueryVariables
  >
    query={ListWorkspacesDocument}
    {...props}
  />
)

export type ListWorkspacesProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ListWorkspacesQuery,
    ListWorkspacesQueryVariables
  >
} &
  TChildProps
export function withListWorkspaces<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ListWorkspacesQuery,
    ListWorkspacesQueryVariables,
    ListWorkspacesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ListWorkspacesQuery,
    ListWorkspacesQueryVariables,
    ListWorkspacesProps<TChildProps, TDataName>
  >(ListWorkspacesDocument, {
    alias: 'listWorkspaces',
    ...operationOptions,
  })
}

/**
 * __useListWorkspacesQuery__
 *
 * To run a query within a React component, call `useListWorkspacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListWorkspacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListWorkspacesQuery({
 *   variables: {
 *      _ids: // value for '_ids'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      intervals: // value for 'intervals'
 *   },
 * });
 */
export function useListWorkspacesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListWorkspacesQuery,
    ListWorkspacesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ListWorkspacesQuery,
    ListWorkspacesQueryVariables
  >(ListWorkspacesDocument, baseOptions)
}
export function useListWorkspacesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListWorkspacesQuery,
    ListWorkspacesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ListWorkspacesQuery,
    ListWorkspacesQueryVariables
  >(ListWorkspacesDocument, baseOptions)
}
export type ListWorkspacesQueryHookResult = ReturnType<
  typeof useListWorkspacesQuery
>
export type ListWorkspacesLazyQueryHookResult = ReturnType<
  typeof useListWorkspacesLazyQuery
>
export type ListWorkspacesQueryResult = ApolloReactCommon.QueryResult<
  ListWorkspacesQuery,
  ListWorkspacesQueryVariables
>
export const GetWorkspaceStatsDocument = gql`
  query GetWorkspaceStats($_id: MongoID!, $intervals: [StatsIntervalInput!]!) {
    workspace: Dashboard__FindWorkspace(_id: $_id) {
      stats: getStats(intervals: $intervals) {
        reach
        engagement
      }
    }
  }
`
export type GetWorkspaceStatsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    GetWorkspaceStatsQuery,
    GetWorkspaceStatsQueryVariables
  >,
  'query'
> &
  (
    | { variables: GetWorkspaceStatsQueryVariables; skip?: boolean }
    | { skip: boolean }
  )

export const GetWorkspaceStatsComponent = (
  props: GetWorkspaceStatsComponentProps
) => (
  <ApolloReactComponents.Query<
    GetWorkspaceStatsQuery,
    GetWorkspaceStatsQueryVariables
  >
    query={GetWorkspaceStatsDocument}
    {...props}
  />
)

export type GetWorkspaceStatsProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    GetWorkspaceStatsQuery,
    GetWorkspaceStatsQueryVariables
  >
} &
  TChildProps
export function withGetWorkspaceStats<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetWorkspaceStatsQuery,
    GetWorkspaceStatsQueryVariables,
    GetWorkspaceStatsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetWorkspaceStatsQuery,
    GetWorkspaceStatsQueryVariables,
    GetWorkspaceStatsProps<TChildProps, TDataName>
  >(GetWorkspaceStatsDocument, {
    alias: 'getWorkspaceStats',
    ...operationOptions,
  })
}

/**
 * __useGetWorkspaceStatsQuery__
 *
 * To run a query within a React component, call `useGetWorkspaceStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkspaceStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkspaceStatsQuery({
 *   variables: {
 *      _id: // value for '_id'
 *      intervals: // value for 'intervals'
 *   },
 * });
 */
export function useGetWorkspaceStatsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetWorkspaceStatsQuery,
    GetWorkspaceStatsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    GetWorkspaceStatsQuery,
    GetWorkspaceStatsQueryVariables
  >(GetWorkspaceStatsDocument, baseOptions)
}
export function useGetWorkspaceStatsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetWorkspaceStatsQuery,
    GetWorkspaceStatsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetWorkspaceStatsQuery,
    GetWorkspaceStatsQueryVariables
  >(GetWorkspaceStatsDocument, baseOptions)
}
export type GetWorkspaceStatsQueryHookResult = ReturnType<
  typeof useGetWorkspaceStatsQuery
>
export type GetWorkspaceStatsLazyQueryHookResult = ReturnType<
  typeof useGetWorkspaceStatsLazyQuery
>
export type GetWorkspaceStatsQueryResult = ApolloReactCommon.QueryResult<
  GetWorkspaceStatsQuery,
  GetWorkspaceStatsQueryVariables
>
export const ViewUsersDocument = gql`
  query ViewUsers(
    $skip: Int
    $limit: Int
    $sort: SortFindManyUserInput
    $filter: FilterFindManyUserInput
  ) {
    users: Dashboard__FindUsers(
      skip: $skip
      limit: $limit
      sort: $sort
      filter: $filter
    ) {
      ...UserFullFragment
    }
  }
  ${UserFullFragmentFragmentDoc}
`
export type ViewUsersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ViewUsersQuery,
    ViewUsersQueryVariables
  >,
  'query'
>

export const ViewUsersComponent = (props: ViewUsersComponentProps) => (
  <ApolloReactComponents.Query<ViewUsersQuery, ViewUsersQueryVariables>
    query={ViewUsersDocument}
    {...props}
  />
)

export type ViewUsersProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ViewUsersQuery,
    ViewUsersQueryVariables
  >
} &
  TChildProps
export function withViewUsers<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ViewUsersQuery,
    ViewUsersQueryVariables,
    ViewUsersProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ViewUsersQuery,
    ViewUsersQueryVariables,
    ViewUsersProps<TChildProps, TDataName>
  >(ViewUsersDocument, {
    alias: 'viewUsers',
    ...operationOptions,
  })
}

/**
 * __useViewUsersQuery__
 *
 * To run a query within a React component, call `useViewUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewUsersQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useViewUsersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ViewUsersQuery,
    ViewUsersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ViewUsersQuery, ViewUsersQueryVariables>(
    ViewUsersDocument,
    baseOptions
  )
}
export function useViewUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ViewUsersQuery,
    ViewUsersQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<ViewUsersQuery, ViewUsersQueryVariables>(
    ViewUsersDocument,
    baseOptions
  )
}
export type ViewUsersQueryHookResult = ReturnType<typeof useViewUsersQuery>
export type ViewUsersLazyQueryHookResult = ReturnType<
  typeof useViewUsersLazyQuery
>
export type ViewUsersQueryResult = ApolloReactCommon.QueryResult<
  ViewUsersQuery,
  ViewUsersQueryVariables
>
export const ListInvitesDocument = gql`
  query ListInvites(
    $skip: Int
    $limit: Int
    $sort: SortFindManyInviteInput
    $filter: FilterFindManyInviteInput
  ) {
    invites: Dashboard__FindInvites(
      skip: $skip
      limit: $limit
      sort: $sort
      filter: $filter
    ) {
      ...InviteFullFragment
    }
  }
  ${InviteFullFragmentFragmentDoc}
`
export type ListInvitesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ListInvitesQuery,
    ListInvitesQueryVariables
  >,
  'query'
>

export const ListInvitesComponent = (props: ListInvitesComponentProps) => (
  <ApolloReactComponents.Query<ListInvitesQuery, ListInvitesQueryVariables>
    query={ListInvitesDocument}
    {...props}
  />
)

export type ListInvitesProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ListInvitesQuery,
    ListInvitesQueryVariables
  >
} &
  TChildProps
export function withListInvites<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ListInvitesQuery,
    ListInvitesQueryVariables,
    ListInvitesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ListInvitesQuery,
    ListInvitesQueryVariables,
    ListInvitesProps<TChildProps, TDataName>
  >(ListInvitesDocument, {
    alias: 'listInvites',
    ...operationOptions,
  })
}

/**
 * __useListInvitesQuery__
 *
 * To run a query within a React component, call `useListInvitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListInvitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListInvitesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListInvitesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListInvitesQuery,
    ListInvitesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ListInvitesQuery, ListInvitesQueryVariables>(
    ListInvitesDocument,
    baseOptions
  )
}
export function useListInvitesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListInvitesQuery,
    ListInvitesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ListInvitesQuery,
    ListInvitesQueryVariables
  >(ListInvitesDocument, baseOptions)
}
export type ListInvitesQueryHookResult = ReturnType<typeof useListInvitesQuery>
export type ListInvitesLazyQueryHookResult = ReturnType<
  typeof useListInvitesLazyQuery
>
export type ListInvitesQueryResult = ApolloReactCommon.QueryResult<
  ListInvitesQuery,
  ListInvitesQueryVariables
>
export const ViewWebhookDocument = gql`
  query ViewWebhook(
    $skip: Int
    $sort: SortFindOneWebhookInput
    $filter: FilterFindOneWebhookInput
  ) {
    webhook: Dashboard__FindWebhook(skip: $skip, sort: $sort, filter: $filter) {
      ...WebhookFullFragment
    }
  }
  ${WebhookFullFragmentFragmentDoc}
`
export type ViewWebhookComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ViewWebhookQuery,
    ViewWebhookQueryVariables
  >,
  'query'
>

export const ViewWebhookComponent = (props: ViewWebhookComponentProps) => (
  <ApolloReactComponents.Query<ViewWebhookQuery, ViewWebhookQueryVariables>
    query={ViewWebhookDocument}
    {...props}
  />
)

export type ViewWebhookProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ViewWebhookQuery,
    ViewWebhookQueryVariables
  >
} &
  TChildProps
export function withViewWebhook<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ViewWebhookQuery,
    ViewWebhookQueryVariables,
    ViewWebhookProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ViewWebhookQuery,
    ViewWebhookQueryVariables,
    ViewWebhookProps<TChildProps, TDataName>
  >(ViewWebhookDocument, {
    alias: 'viewWebhook',
    ...operationOptions,
  })
}

/**
 * __useViewWebhookQuery__
 *
 * To run a query within a React component, call `useViewWebhookQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewWebhookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewWebhookQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useViewWebhookQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ViewWebhookQuery,
    ViewWebhookQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ViewWebhookQuery, ViewWebhookQueryVariables>(
    ViewWebhookDocument,
    baseOptions
  )
}
export function useViewWebhookLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ViewWebhookQuery,
    ViewWebhookQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ViewWebhookQuery,
    ViewWebhookQueryVariables
  >(ViewWebhookDocument, baseOptions)
}
export type ViewWebhookQueryHookResult = ReturnType<typeof useViewWebhookQuery>
export type ViewWebhookLazyQueryHookResult = ReturnType<
  typeof useViewWebhookLazyQuery
>
export type ViewWebhookQueryResult = ApolloReactCommon.QueryResult<
  ViewWebhookQuery,
  ViewWebhookQueryVariables
>
export const ListWebhooksDocument = gql`
  query ListWebhooks(
    $skip: Int
    $limit: Int
    $sort: SortFindManyWebhookInput
    $filter: FilterFindManyWebhookInput
  ) {
    webhooks: Dashboard__FindWebhooks(
      skip: $skip
      limit: $limit
      sort: $sort
      filter: $filter
    ) {
      ...WebhookFullFragment
    }
  }
  ${WebhookFullFragmentFragmentDoc}
`
export type ListWebhooksComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ListWebhooksQuery,
    ListWebhooksQueryVariables
  >,
  'query'
>

export const ListWebhooksComponent = (props: ListWebhooksComponentProps) => (
  <ApolloReactComponents.Query<ListWebhooksQuery, ListWebhooksQueryVariables>
    query={ListWebhooksDocument}
    {...props}
  />
)

export type ListWebhooksProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ListWebhooksQuery,
    ListWebhooksQueryVariables
  >
} &
  TChildProps
export function withListWebhooks<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ListWebhooksQuery,
    ListWebhooksQueryVariables,
    ListWebhooksProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ListWebhooksQuery,
    ListWebhooksQueryVariables,
    ListWebhooksProps<TChildProps, TDataName>
  >(ListWebhooksDocument, {
    alias: 'listWebhooks',
    ...operationOptions,
  })
}

/**
 * __useListWebhooksQuery__
 *
 * To run a query within a React component, call `useListWebhooksQuery` and pass it any options that fit your needs.
 * When your component renders, `useListWebhooksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListWebhooksQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListWebhooksQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListWebhooksQuery,
    ListWebhooksQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ListWebhooksQuery,
    ListWebhooksQueryVariables
  >(ListWebhooksDocument, baseOptions)
}
export function useListWebhooksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListWebhooksQuery,
    ListWebhooksQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ListWebhooksQuery,
    ListWebhooksQueryVariables
  >(ListWebhooksDocument, baseOptions)
}
export type ListWebhooksQueryHookResult = ReturnType<
  typeof useListWebhooksQuery
>
export type ListWebhooksLazyQueryHookResult = ReturnType<
  typeof useListWebhooksLazyQuery
>
export type ListWebhooksQueryResult = ApolloReactCommon.QueryResult<
  ListWebhooksQuery,
  ListWebhooksQueryVariables
>
export const ViewCategoryDocument = gql`
  query ViewCategory(
    $skip: Int
    $sort: SortFindOneCategoryInput
    $filter: FilterFindOneCategoryInput
  ) {
    category: Dashboard__FindCategory(
      skip: $skip
      sort: $sort
      filter: $filter
    ) {
      _id
      name
    }
  }
`
export type ViewCategoryComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ViewCategoryQuery,
    ViewCategoryQueryVariables
  >,
  'query'
>

export const ViewCategoryComponent = (props: ViewCategoryComponentProps) => (
  <ApolloReactComponents.Query<ViewCategoryQuery, ViewCategoryQueryVariables>
    query={ViewCategoryDocument}
    {...props}
  />
)

export type ViewCategoryProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ViewCategoryQuery,
    ViewCategoryQueryVariables
  >
} &
  TChildProps
export function withViewCategory<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ViewCategoryQuery,
    ViewCategoryQueryVariables,
    ViewCategoryProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ViewCategoryQuery,
    ViewCategoryQueryVariables,
    ViewCategoryProps<TChildProps, TDataName>
  >(ViewCategoryDocument, {
    alias: 'viewCategory',
    ...operationOptions,
  })
}

/**
 * __useViewCategoryQuery__
 *
 * To run a query within a React component, call `useViewCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewCategoryQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useViewCategoryQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ViewCategoryQuery,
    ViewCategoryQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ViewCategoryQuery,
    ViewCategoryQueryVariables
  >(ViewCategoryDocument, baseOptions)
}
export function useViewCategoryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ViewCategoryQuery,
    ViewCategoryQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ViewCategoryQuery,
    ViewCategoryQueryVariables
  >(ViewCategoryDocument, baseOptions)
}
export type ViewCategoryQueryHookResult = ReturnType<
  typeof useViewCategoryQuery
>
export type ViewCategoryLazyQueryHookResult = ReturnType<
  typeof useViewCategoryLazyQuery
>
export type ViewCategoryQueryResult = ApolloReactCommon.QueryResult<
  ViewCategoryQuery,
  ViewCategoryQueryVariables
>
export const ListCategoriesDocument = gql`
  query ListCategories(
    $skip: Int
    $limit: Int
    $sort: SortFindManyCategoryInput
    $filter: FilterFindManyCategoryInput
  ) {
    categories: Dashboard__FindCategories(
      skip: $skip
      limit: $limit
      sort: $sort
      filter: $filter
    ) {
      _id
      name
    }
  }
`
export type ListCategoriesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ListCategoriesQuery,
    ListCategoriesQueryVariables
  >,
  'query'
>

export const ListCategoriesComponent = (
  props: ListCategoriesComponentProps
) => (
  <ApolloReactComponents.Query<
    ListCategoriesQuery,
    ListCategoriesQueryVariables
  >
    query={ListCategoriesDocument}
    {...props}
  />
)

export type ListCategoriesProps<
  TChildProps = {},
  TDataName extends string = 'data'
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    ListCategoriesQuery,
    ListCategoriesQueryVariables
  >
} &
  TChildProps
export function withListCategories<
  TProps,
  TChildProps = {},
  TDataName extends string = 'data'
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ListCategoriesQuery,
    ListCategoriesQueryVariables,
    ListCategoriesProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ListCategoriesQuery,
    ListCategoriesQueryVariables,
    ListCategoriesProps<TChildProps, TDataName>
  >(ListCategoriesDocument, {
    alias: 'listCategories',
    ...operationOptions,
  })
}

/**
 * __useListCategoriesQuery__
 *
 * To run a query within a React component, call `useListCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCategoriesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      sort: // value for 'sort'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListCategoriesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ListCategoriesQuery,
    ListCategoriesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ListCategoriesQuery,
    ListCategoriesQueryVariables
  >(ListCategoriesDocument, baseOptions)
}
export function useListCategoriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ListCategoriesQuery,
    ListCategoriesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ListCategoriesQuery,
    ListCategoriesQueryVariables
  >(ListCategoriesDocument, baseOptions)
}
export type ListCategoriesQueryHookResult = ReturnType<
  typeof useListCategoriesQuery
>
export type ListCategoriesLazyQueryHookResult = ReturnType<
  typeof useListCategoriesLazyQuery
>
export type ListCategoriesQueryResult = ApolloReactCommon.QueryResult<
  ListCategoriesQuery,
  ListCategoriesQueryVariables
>
