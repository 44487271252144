import React, { FC } from 'react'
import 'twin.macro'

import { useFormik } from 'formik'
import { string, object } from 'yup'

import Suspense from '../../../../ui-blocks/suspense'
import ModalAction from '../../../../ui-blocks/modal-action'
import { InputFormik } from '../../../../ui-blocks/input'

import LoadingPage from '../../../../components/loading-page'

import {
  useViewCampaignQuery,
  useUpdateCampaignByIdMutation,
} from '../../../../graphql/components'

import { alertsManager } from '../../../../stores'
import { AlertVariant } from '../../../../stores/alerts-manager'

import { trimValues } from '../../../../utils/data-manipulation'

interface EditCampaignFormikValues {
  name: string
}

const editCampaignValidationSchema = object().shape({
  name: string()
    .trim('Value cannot have leading or trailing white spaces')
    .required('Name is required')
    .strict(true),
})

export interface EditCampaignModalProps {
  open?: boolean
  campaignId: string
  onClose?: () => void
  refetchData?: () => void
}

const EditCampaignModal: FC<EditCampaignModalProps> = ({
  open,
  campaignId,
  onClose,
  refetchData,
}) => {
  const [
    updateCampaign,
    { loading: updatingCampaign },
  ] = useUpdateCampaignByIdMutation()
  const {
    data: campaignData,
    loading: loadingCampaign,
    refetch: refetchCampaign,
  } = useViewCampaignQuery({
    skip: !campaignId,
    variables: { _id: campaignId },
  })

  const campaign = campaignData?.campaign
  const formik = useFormik<EditCampaignFormikValues>({
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: editCampaignValidationSchema,
    initialValues: { name: campaign?.name || '' },
    async onSubmit(values) {
      const newValues = trimValues(values, ['name'])
      try {
        const res = await updateCampaign({
          variables: { _id: campaignId, input: newValues },
        })
        if ((res.errors || []).length > 0) {
          handleError()
        } else {
          refetchData?.()
          refetchCampaign()
          onCloseModal(false)
        }
      } catch {
        handleError()
      }
    },
  })

  const onCloseModal = (reset = true) => {
    if (updatingCampaign) return
    reset && formik.resetForm()
    onClose?.()
  }

  const handleError = () => {
    alertsManager.emit({
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'update-campaign-error-alert',
      message: "We couldn't update this campaign. Please try again later.",
    })
  }

  return (
    <ModalAction
      open={open}
      loading={updatingCampaign}
      onConfirm={formik.submitForm}
      confirmDisabled={!formik.dirty}
      onCancel={() => onCloseModal()}
      onBackdropClick={() => onCloseModal()}
      confirmButtonText="Accept changes"
      width="36rem"
    >
      <Suspense ready={!loadingCampaign} fallback={<LoadingPage />}>
        <form tw="mb-20" onChange={formik.handleChange}>
          <h1 tw="font-light text-3xl text-charcoal leading-tight mb-10">
            Campaign details
          </h1>
          <span tw="font-medium text-charcoal leading-tight block mb-4">
            Information
          </span>
          <InputFormik
            required
            type="text"
            name="name"
            label="Name"
            formik={formik}
          />
        </form>
      </Suspense>
    </ModalAction>
  )
}

export default EditCampaignModal
