import React, { FC, useEffect, Fragment } from 'react'

import _ from 'lodash'
import tw from 'twin.macro'

import { useFormikContext } from 'formik'
import { object, string, array } from 'yup'

import Text from '../../../../ui-blocks/text'
import Search from '../../../../ui-blocks/search'
import Spinner from '../../../../ui-blocks/spinner'
import Suspense from '../../../../ui-blocks/suspense'
import PaginationNav from '../../../../ui-blocks/pagination-nav'

import PlayerSelectCard from '../../../../components/player/select-card'

import usePagination from '../../../../utils/use-pagination'
import { useSearchTerms } from '../../../../utils/use-search'

import {
  useCountQuery,
  useListPlayersQuery,
  useListNetworksQuery,
} from '../../../../graphql/components'

import { CreateCampaignFormikValues } from '..'

export const targetPlayersValidationSchema = object().shape({
  players_ids: array(string()).min(1).required(),
})

const TargetPlayersStep: FC = () => {
  const {
    values,
    setFieldValue,
  } = useFormikContext<CreateCampaignFormikValues>()
  const { data: countData, loading: loadingCount } = useCountQuery({
    fetchPolicy: 'no-cache',
  })

  const playersCount = countData?.players || 0
  const pagination = usePagination(playersCount, 6)

  const { data: networksData, loading: loadingNetworks } = useListNetworksQuery(
    {
      fetchPolicy: 'no-cache',
      skip: !values.networks_ids.length,
      variables: { filter: { _ids: values.networks_ids } },
    }
  )

  const { searchText, setSearchText, searchTextDebounced } = useSearchTerms()
  const { data: playersData, loading: loadingPlayers } = useListPlayersQuery({
    fetchPolicy: 'no-cache',
    variables: {
      limit: pagination.limit,
      offset: pagination.offset,
      filter: { search: searchTextDebounced },
    },
  })

  const allPlayers = playersData?.players || []
  const allNetworkPlayers = (networksData?.networks || []).reduce<string[]>(
    (acc, network) => [
      ...acc,
      ...network.players.map((player) => player?.player_id),
    ],
    []
  )

  const uniqueNetworkPlayers = _.uniq(allNetworkPlayers)
  useEffect(() => {
    if (!loadingNetworks && uniqueNetworkPlayers.length)
      setFieldValue('players_ids', uniqueNetworkPlayers)
  }, [loadingNetworks])

  const handleSelectPlayer = (playerId: string) => {
    if (!values.players_ids.includes(playerId))
      return setFieldValue('players_ids', [...values.players_ids, playerId])
    setFieldValue('players_ids', _.pull([...values.players_ids], playerId))
  }

  const isLoading = loadingPlayers || loadingNetworks
  return (
    <Fragment>
      <div tw="flex flex-wrap items-end justify-between mb-6">
        <p tw="text-charcoal text-xs pt-3 uppercase">
          {loadingCount
            ? 'Loading...'
            : `${playersCount} Players, ${values.players_ids.length} Selected`}
        </p>
        <div style={{ width: 304 }}>
          <Search
            value={searchText}
            loading={loadingPlayers}
            entries={allPlayers
              .map((player) => player.name || '')
              .filter(Boolean)}
            onSelect={(value) =>
              setSearchText(!Array.isArray(value) ? value || '' : '')
            }
          />
        </div>
      </div>
      <Suspense ready={!isLoading} fallback={<Spinner center />}>
        <div tw="flex flex-wrap justify-start -mx-1">
          {allPlayers?.map((player, index) => (
            <div key={index} tw="w-1/2 p-2">
              <PlayerSelectCard
                name="players_ids"
                player={player}
                value={player?._id}
                onClick={() => handleSelectPlayer(player?._id)}
                checked={values.players_ids.includes(player?._id)}
                preChecked={uniqueNetworkPlayers.includes(player?._id)}
              />
            </div>
          ))}
        </div>
      </Suspense>
      <div tw="flex flex-row items-center justify-between mt-6">
        <Text
          as="span"
          preset="p2"
          css={[tw`underline`, 'text-decoration-style: dashed;']}
        >
          NOTE: At least one player MUST be selected! Displays with the dashed
          borders are already linked to the selected networks, but they are NOT
          mandatory! They can be unselected.
        </Text>
        <PaginationNav
          page={pagination.page}
          count={pagination.count}
          limit={pagination.limit}
          setPage={pagination.setPage}
        />
      </div>
    </Fragment>
  )
}

export default TargetPlayersStep

// const ChoosePlayersNoResults: FC<{ searchQuery: string }> = ({
//   searchQuery,
// }) => (
//   <div
//     css={css`
//       ${tw`w-full flex flex-col items-center mt-12`}
//     `}
//   >
//     <img src={PlayersNoResultsIllustration} alt={'Player-no-results'} />

//     <h2
//       css={css`
//         ${tw`text-charcoal font-medium leading-tight text-sm mt-8`}
//       `}
//     >
//       No data to show
//     </h2>
//     <p
//       css={css`
//         ${tw`text-charcoal font-normal pt-2 pb-6 text-center`}
//       `}
//     >
//       {`We can't find any Player for "${searchQuery}". Please check your spelling and try again.`}
//     </p>
//   </div>
// )
