import React, { FC } from 'react'
import ReactPlayer from 'react-player/lazy'
import { UploadFullFragmentFragment } from '../../graphql/components'
// // import tw, { css } from 'twin.macro'

type UploadPartial = Pick<UploadFullFragmentFragment, 'type' | 'versions'>

const UploadPreview: FC<{
  upload?: UploadPartial
}> = ({ upload }) => {
  const versions = upload?.versions || []
  const original = versions.find((v) => v?.name === 'original')

  if (!upload || !original || !original.public_url) {
    return null
  }

  if (upload.type === 'image') {
    return <img src={original.public_url} alt={original.name} />
  }

  if (upload.type === 'video') {
    return <ReactPlayer playing controls url={original.public_url} />
  }

  return null
}

export default UploadPreview
