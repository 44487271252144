import { useState, useCallback } from 'react'

export interface IUseControlledOrInternalValueProps<ValueType> {
  initialValue?: ValueType
  value?: ValueType
  onChange?: (value: ValueType) => void
}

const useControlledOrInternalValue = <ValueType extends any>(
  props: IUseControlledOrInternalValueProps<ValueType>
) => {
  const { value: propsValue, initialValue, onChange: onChangeProps } = props

  // use an internal state if it is not a controlled component
  const [internalValue, setInternalValue] = useState<ValueType | undefined>(
    initialValue
  )
  const value = typeof propsValue !== 'undefined' ? propsValue : internalValue

  const onChange = useCallback(
    (newValue: ValueType) => {
      if (newValue !== value) {
        if (typeof onChangeProps === 'function') {
          // if it is a controlled component, call the onSelect method
          onChangeProps(newValue)
        } else {
          // if it is NOT a controlled component, change the internal state
          if (typeof newValue !== 'object') {
            setInternalValue(newValue)
          } else {
            // @ts-ignore
            setInternalValue(newValue?.target?.value)
          }
        }
      }
    },
    [value, onChangeProps]
  )

  return { value, onChange }
}

export default useControlledOrInternalValue
