import React, { FC } from 'react'
import 'twin.macro'

import StateToggles from './state-toggles'
import ElementSection, { ElementSectionProps } from './element-section'

import Text from '../../../../../ui-blocks/text'
import Spacer from '../../../../../ui-blocks/spacer'
import CodeInput from '../../../../../ui-blocks/code-input'

import useElementStates from '../../../../../utils/use-element-states'

const CodeInputSection: FC<ElementSectionProps> = ({
  id,
  title,
  description,
  last,
}) => {
  const {
    states,
    values: { error, ...values },
  } = useElementStates(['error', 'disabled'])

  return (
    <ElementSection
      id={id}
      last={last}
      title={title}
      description={
        description || `These are the states supported by the ${title} element:`
      }
    >
      <StateToggles toggles={states} />
      <CodeInput
        label="Label"
        error={error ? 'Error message!' : ''}
        {...values}
      />
      <Spacer size="1.5rem" />
      <CodeInput
        label="Label"
        placeholder={['1', '2', '3', '4', '5', '6']}
        error={error ? 'Error message!' : ''}
        {...values}
      />
      <Spacer size="1.5rem" />
      <CodeInput
        label="Label"
        initialValue="654321"
        placeholder={['1', '2', '3', '4', '5', '6']}
        error={error ? 'Error message!' : ''}
        {...values}
      />
      <Spacer size="1.5rem" />
      <CodeInput
        id="code-input-with-custom-label"
        label={
          <Text
            as="label"
            tw="flex flex-row items-center justify-between pb-2"
            htmlFor="code-input-with-custom-label"
          >
            <Text as="span" preset="caption">
              Label
            </Text>
            <Text as="span" preset="caption" transform="lowercase">
              optional
            </Text>
          </Text>
        }
        initialValue="654321"
        placeholder={['1', '2', '3', '4', '5', '6']}
        error={error ? 'Error message!' : ''}
        {...values}
      />
    </ElementSection>
  )
}

export default CodeInputSection
