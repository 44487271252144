import React, { FC } from 'react'
import 'twin.macro'

import {
  SelectCardFormik,
  SelectCardFormikProps,
} from '../../ui-blocks/select-card'

import { ReactComponent as Display } from '../../assets/icon/display.svg'

import { Network } from '../../graphql/components'

interface NetworkSelectCardProps extends SelectCardFormikProps {
  network: Pick<Network, 'name' | 'players'>
}

const NetworkSelectCard: FC<NetworkSelectCardProps> = ({
  network,
  ...props
}) => (
  <SelectCardFormik {...props}>
    <div tw="flex flex-row items-center justify-between">
      <p title={network.name} tw="font-medium text-charcoal truncate">
        {network.name}
      </p>
      <div tw="flex flex-row items-center text-dark-blue-gray ml-2">
        <Display width={17} height={17} tw="mr-1" />
        {network.players?.length || 0}
      </div>
    </div>
  </SelectCardFormik>
)

export default NetworkSelectCard
