import React, { FC, useState } from 'react'
import tw, { css } from 'twin.macro'

import Search from '../../../../../ui-blocks/search'
import { useParams } from 'react-router-dom'
import InvoiceModal from '../components/invoice-modal'
import InvoicesTable from '../components/invoices-table'
import { useViewWorkspaceQuery } from '../../../../../graphql/components'

import { ReactComponent as Invoice } from '../../../../../assets/illustrations/invoice.svg'

export interface Invoice {
  id: string
  created: number
  total: number
  status: string
  invoice_pdf: string
  hosted_invoice_url: string
}

const Invoices: FC = () => {
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null)
  const params = useParams()
  const workspaceId = params?.workspace_id || ''

  const { data } = useViewWorkspaceQuery({
    skip: !workspaceId,
    variables: { _id: workspaceId },
  })

  const invoices = data?.workspace?.invoices?.data || []
  const dueInvoices =
    invoices.filter((invoice) => invoice?.status === 'open') || []
  const totalPaymentsDue = dueInvoices.reduce(
    (acc, value) => acc + (value?.total || 0),
    0
  )

  const renderNoInvoices = () => (
    <div
      css={css`
        ${tw`flex flex-col items-center`}
      `}
    >
      <Invoice />
      <p
        css={css`
          ${tw`font-normal text-charcoal mt-5`}
        `}
      >
        You don’t have any invoice.
      </p>
    </div>
  )

  const renderInvoices = () => (
    <>
      <div
        css={css`
          ${tw`flex flex-row items-end justify-between mb-8`}
        `}
      >
        <span
          css={css`
            ${tw`font-bold text-golden-brown leading-tight`}
          `}
        >
          Payment Due: € {totalPaymentsDue / 100}
        </span>
        <div
          css={css`
            max-width: 15rem;
          `}
        >
          <Search />
        </div>
      </div>
      <InvoicesTable
        invoices={invoices as Invoice[]}
        onSelectInvoice={setSelectedInvoice}
        platform_type={data?.workspace?.platform_type || ''}
      />
    </>
  )

  return (
    <div
      css={css`
        ${tw`mt-6`}
      `}
    >
      <h2
        css={css`
          ${tw`font-medium text-charcoal mb-6`}
        `}
      >
        Invoices information
      </h2>
      {invoices.length > 0 ? renderInvoices() : renderNoInvoices()}
      <InvoiceModal
        invoice={selectedInvoice}
        onClose={() => setSelectedInvoice(null)}
      />
    </div>
  )
}

export default Invoices
