import React, { FC } from 'react'
import 'twin.macro'

import { ReactComponent as NoResults } from '../../../../assets/illustrations/campaign_3-11_new_5_add_creative_no_results.svg'

export interface CreativesNoResultsProps {
  searchQuery?: string
}

const CreativesNoResults: FC<CreativesNoResultsProps> = ({ searchQuery }) => (
  <div tw="flex flex-col items-center">
    <NoResults tw="mb-10" />
    <h2 tw="font-light text-3xl text-charcoal leading-tight">
      No data to show
    </h2>
    <p tw="text-charcoal font-light pt-4 pb-6 w-1/2 text-center">
      {searchQuery
        ? `We can't find any creative for "${searchQuery}". Please check your spelling and try again.`
        : `We can't find any creative.`}
    </p>
  </div>
)

export default CreativesNoResults
