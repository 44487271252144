const $ = process.env

export const NODE_ENV =
  $.REACT_APP_ENVIRONMENT || $.REACT_APP_NODE_ENV || $.NODE_ENV || 'development'

export const API_BASE_URL =
  (NODE_ENV === 'production' && 'https://api.diffuse.tv') ||
  (NODE_ENV === 'staging' && 'https://api.staging.diffuse.tv') ||
  (NODE_ENV === 'next' && 'https://api.staging.diffuse.tv') ||
  'http://localhost:4000'

export const PLAYER_BASE_URL =
  (NODE_ENV === 'production' && 'https://player.diffuse.tv') ||
  (NODE_ENV === 'staging' && 'https://player.staging.diffuse.tv') ||
  (NODE_ENV === 'next' && 'https://next-player.diffuse.tv') ||
  'http://localhost:2000'

export const API_GRAPHQL_URL = `${API_BASE_URL}/graphql`

export const REACT_APP_BASE_URL = $.REACT_APP_BASE_URL || '/'
export const MAPBOX_API_TOKEN =
  'pk.eyJ1IjoicmVhbHRpbWVhZHMiLCJhIjoiY2s3Z2kyOWJsMGIwNzNtbzJrczR1bGd2ZyJ9.mWdUsYnFU4GELKOHjwbfaQ'

export const STRIPE_PUBLISHABLE_KEY =
  'pk_test_51HtHEpJWU1QL33e8vIRcaeWDaXIhycvxTqk2gyH0iuoUbwdqZGKRBxyvUnAurXF8LKqs9q22tPmjKm2AT8nfJ4qY00V5waUiUg'
