import React, { FC, InputHTMLAttributes } from 'react'
import tw, { css } from 'twin.macro'

import findSameValueIndex from '../utils/find-same-value-index'
import { IFormikObject } from '../typings'

import { ReactComponent as CheckmarkIcon } from '../assets/icon/check_tick/checked_gray.svg'

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  error?: string
}

const Checkbox: FC<CheckboxProps> = ({
  id,
  label,
  error,
  checked,
  disabled,
  ...props
}) => {
  const inputId = id || `checkbox-${Math.random().toString(36).substring(2, 9)}`
  return (
    <div tw="w-full">
      <div
        css={css`
          ${tw`flex flex-row items-center`}
          &:hover {
            .input-box {
              ${!disabled && tw`border-purple`}
            }
          }
        `}
      >
        <input
          tw="hidden"
          type="checkbox"
          id={inputId}
          checked={checked}
          disabled={disabled}
          {...props}
        />
        <div
          className="input-box"
          css={[
            tw`flex items-center justify-center w-4 h-4 bg-white rounded border border-dark-blue-gray transition duration-200 hover:cursor-pointer`,
            !!checked && tw`bg-purple border-purple`,
            !!disabled && tw`hover:cursor-not-allowed`,
            !!checked &&
              !!disabled &&
              tw`bg-dark-blue-gray border-dark-blue-gray`,
          ]}
        >
          {!!checked && (
            <CheckmarkIcon className="fill-current" tw="text-white" />
          )}
        </div>
        {!!label && (
          <label
            htmlFor={inputId}
            css={[
              tw`font-normal text-charcoal leading-tight pl-2 hover:cursor-pointer`,
              !!disabled &&
                tw`font-light text-dark-blue-gray hover:cursor-not-allowed`,
            ]}
          >
            {label}
          </label>
        )}
      </div>
      {!!error && (
        <span tw="font-normal text-xs text-brick-red leading-tight block mt-1">
          {error}
        </span>
      )}
    </div>
  )
}

export default Checkbox

export interface CheckboxFormikProps extends CheckboxProps {
  formik?: IFormikObject<any>
}

export const CheckboxFormik: FC<CheckboxFormikProps> = ({
  formik,
  ...props
}) => {
  const meta = formik?.getFieldMeta(props.name || '')
  return (
    <Checkbox
      {...props}
      checked={
        findSameValueIndex(
          (Array.isArray(meta?.value) && meta?.value) || [],
          props.value
        ) > -1
      }
      onChange={(e) => {
        if (!formik || !props.name) {
          return
        }

        if (typeof e.preventDefault === 'function') {
          e.preventDefault()
        }

        if (typeof e.stopPropagation === 'function') {
          e.stopPropagation()
        }

        const { checked } = e.target
        const { value } = props

        const current_array =
          (Array.isArray(formik.values[props.name]) &&
            formik.values[props.name]) ||
          []

        // check if current value has it within
        const index = findSameValueIndex(current_array, value)
        const new_array = [...current_array]

        // logic to ensure it adds or removes
        if (checked) {
          // if we're adding, we're only add it up in case it doesnt exist
          if (index === -1) {
            new_array.push(value)
          }
        } else {
          // if we're removing, we're only remove it in case it exists
          if (index !== -1) {
            new_array.splice(index, 1)
          }
        }

        // set the new array with formik
        formik.setFieldValue(props.name, new_array)
      }}
    />
  )
}
