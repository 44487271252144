import React, { FC, ReactNode, useRef, useMemo, useCallback } from 'react'
import tw, { css } from 'twin.macro'

import Text from './text'

import useControlledOrInternalValue, {
  IUseControlledOrInternalValueProps,
} from '../utils/use-controlled-or-internal-value'

export interface CodeInputProps
  extends IUseControlledOrInternalValueProps<string> {
  id?: string
  length?: number
  placeholder?: string[]
  disabled?: boolean
  label?: string | ReactNode
  error?: string
}

const CodeInput: FC<CodeInputProps> = (props) => {
  const { value, onChange } = useControlledOrInternalValue<string>(props)
  const { id, placeholder, disabled, label, error, length = 6 } = props

  const code: string[] = useMemo(() => {
    const c: string[] = []

    for (let i = 0; i < length; i++) {
      c.push((value && value[i]) || '')
    }

    return c
  }, [length, value])

  const inputRefs: Array<React.RefObject<HTMLInputElement>> = []
  for (let i = 0; i < code.length; i++) {
    inputRefs.push(useRef<HTMLInputElement>(null))
  }

  const setFocus = (index: number): void => {
    const nextRef = inputRefs[index]
    if (!!nextRef && !!nextRef.current) {
      nextRef.current.focus()
    }
  }

  const updateCode = useCallback(
    (char: string, index: number) => {
      const newCode = []

      for (let i = 0; i < length; i++) {
        newCode.push(i === index ? char[0] || ' ' : code[i] || ' ')
      }

      onChange(newCode.join(''))
    },
    [code, length, onChange]
  )

  const isValid = useMemo(() => {
    return code.filter((v) => !!v && v !== ' ').length === length
  }, [code])

  const maxInputWidth = useMemo(() => {
    return inputRefs.length
      ? (inputRefs[0].current?.offsetWidth ?? 48) * length
      : 0
  }, [inputRefs, inputRefs?.[0].current])

  const inputId =
    id || `code-input-${Math.random().toString(36).substring(2, 9)}`
  return (
    <div
      css={css`
        ${tw`block`}
        max-width: calc(${maxInputWidth}px + 0.5rem * ${length - 1});
      `}
    >
      {!!label &&
        (typeof label === 'string' ? (
          <Text as="label" preset="caption" tw="mb-2" htmlFor={inputId}>
            {label}
          </Text>
        ) : (
          label
        ))}
      <div id={inputId}>
        {code.map((value, index) => (
          <input
            key={index}
            value={value}
            ref={inputRefs[index]}
            type="text"
            disabled={disabled}
            placeholder={(!!placeholder && placeholder[index]) || ''}
            onChange={(ev) => {
              const char =
                ev.target.value[0] === code[index]
                  ? ev.target.value[ev.target.value.length - 1]
                  : ev.target.value[0]

              if (char === '' || char === null || char === undefined) {
                updateCode('', index)
                return
              }

              updateCode(char, index)
              setFocus(index + 1)
            }}
            onKeyDown={(ev) => {
              if (ev.key === 'Backspace' && code[index].trim() === '') {
                setFocus(index - 1)
              }
              if (ev.key === 'ArrowLeft' || ev.key === 'ArrowUp') {
                setFocus(index - 1)
              }
              if (ev.key === 'ArrowRight' || ev.key === 'ArrowDown') {
                setFocus(index + 1)
              }
            }}
            css={css`
              ${tw`
                bg-white
                text-charcoal
                text-center
                border
                border-platinum
                rounded
                w-12
                py-2
                px-4
                appearance-none
                leading-normal
                font-medium
              `}
              ${index !== length - 1 && tw`mr-2`}
              &:hover {
                ${(!disabled && tw`border-light-peri`) || ``}
              }
              ${tw`focus:outline-none focus:border-spanish-violet`}
              ${(!error &&
                isValid &&
                tw`border-metallic-seaweed focus:border-metallic-seaweed`) ||
              ``}
              ${(!!disabled && tw`opacity-75 cursor-not-allowed`) || ``}
              ${(!!error &&
                tw`border-brick-red bg-white focus:border-brick-red`) ||
              ``}
              transition: all 100ms ease-in-out;
            `}
          />
        ))}
      </div>
      {!!error && (
        <span tw="font-normal text-xs text-brick-red leading-tight block mt-1">
          {error}
        </span>
      )}
    </div>
  )
}

export default CodeInput
