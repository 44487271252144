import React, { FC } from 'react'
import tw, { css } from 'twin.macro'
import { NavLink } from 'react-router-dom'
import MainLayout from './main'
import Card from '../ui-blocks/card'
import Container from '../ui-blocks/container'
import { authentication } from '../stores'

// interface AccountLayoutParams {}
const AccountPanelLayout: FC = ({ children }) => {
  return (
    <MainLayout>
      <Container>
        <div>
          <Card
            css={css`
              ${tw`text-charcoal flex`}
            `}
          >
            <div
              css={css`
                ${tw`bg-lighter-blue p-8 flex flex-col rounded-tl rounded-bl`}
                width: 19rem;

                a.active {
                  ${tw`text-purple`}
                }
              `}
            >
              <span
                css={css`
                  ${tw`py-2 text-xs uppercase`}
                `}
              >
                {`${authentication.user_data?.name?.first || 'User'}`}'s Account
                Settings
              </span>

              <span
                css={css`
                  ${tw` py-2 text-base`}
                `}
              >
                <NavLink to="/account/personal-information">
                  Personal Information
                </NavLink>
              </span>

              {/* <span
                css={css`
                  ${tw` py-2 text-base`}
                `}
              >
                <NavLink to="/account/billing">Billing</NavLink>
              </span> */}

              <span
                css={css`
                  ${tw` py-2 text-base`}
                `}
              >
                <NavLink to="/account/contacts">Contacts</NavLink>
              </span>

              <span
                css={css`
                  ${tw`py-2 text-base`}
                `}
              >
                <NavLink to="/account/security">Security</NavLink>
              </span>

              {/* <span
                css={css`
                  ${tw`py-2 text-base`}
                `}
              >
                <NavLink to="/account/notifications">Notifications</NavLink>
              </span> */}

              {/* <span
                css={css`
                  ${tw`py-2 text-base`}
                `}
              >
                <NavLink to="/account/workspaces">Workspaces</NavLink>
              </span> */}

              {/* <span
                css={css`
                  ${tw`py-2 text-base`}
                `}
              >
                <NavLink to="/account/dangerzone">Danger Zone</NavLink>
              </span> */}
            </div>

            <div
              css={css`
                ${tw`flex-auto p-8 flex flex-col`}
              `}
            >
              {children}
            </div>
          </Card>
        </div>
      </Container>
    </MainLayout>
  )
}

export default AccountPanelLayout
