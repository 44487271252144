import React, { FC, ReactNode } from 'react'

export interface SuspenseProps {
  ready?: boolean
  fallback: ReactNode
}

const Suspense: FC<SuspenseProps> = ({ ready, fallback, children }) => (
  <>{ready ? children : fallback}</>
)

export default Suspense
