import React, { FC } from 'react'

import { Moment } from 'moment'

import { DatePickerContainer } from './date-picker'

import useDatePicker, { PickerProps } from '../utils/use-date-picker'

export interface DateTimePickerProps extends PickerProps {
  id?: string
  date?: Moment
  minDate?: Moment
  maxDate?: Moment
  onChange?: (startDate?: Moment, endDate?: Moment) => void
}

export const DateTimePicker: FC<DateTimePickerProps> = ({
  id,
  name,
  date,
  label,
  error,
  success,
  disabled,
  minDate,
  maxDate,
  onChange,
}) => {
  const inputId = id || `input-${Math.random().toString(36).substring(2, 9)}`
  const { value, hasValue, inputRef } = useDatePicker(false, {
    parentEl: `#parent-${inputId}`,
    withTime: true,
    format: 'lll',
    startDate: date,
    minDate,
    maxDate,
    onChange,
  })

  return (
    <DatePickerContainer
      id={inputId}
      name={name}
      value={value}
      label={label}
      error={error}
      success={success}
      disabled={disabled}
      clearable={hasValue}
      inputRef={inputRef}
      onClear={() => onChange?.(undefined)}
      placeholder="date"
      maxWidth="16rem"
    />
  )
}

export interface DateTimeRangePickerProps extends PickerProps {
  id?: string
  startDate?: Moment
  endDate?: Moment
  minDate?: Moment
  maxDate?: Moment
  onChange?: (startDate?: Moment, endDate?: Moment) => void
}

export const DateTimeRangePicker: FC<DateTimeRangePickerProps> = ({
  id,
  name,
  label,
  error,
  success,
  disabled,
  startDate,
  endDate,
  minDate,
  maxDate,
  onChange,
}) => {
  const inputId = id || `input-${Math.random().toString(36).substring(2, 9)}`
  const { value, hasValue, inputRef } = useDatePicker(true, {
    parentEl: `#parent-${inputId}`,
    withTime: true,
    format: 'lll',
    startDate,
    endDate,
    minDate,
    maxDate,
    onChange,
  })

  return (
    <DatePickerContainer
      id={inputId}
      name={name}
      value={value}
      label={label}
      error={error}
      success={success}
      disabled={disabled}
      clearable={hasValue}
      inputRef={inputRef}
      onClear={() => onChange?.(undefined)}
      placeholder="start date - end date"
      maxWidth="26rem"
    />
  )
}
