import React, { FC } from 'react'

import 'twin.macro'
import moment from 'moment'

import Card from './card'
import Text from './text'
import Spacer from './spacer'

import { formatMetrics } from '../utils/formaters'
import { KPIStatsInterval } from '../utils/stats-intervals'

import { ReactComponent as Scans } from '../assets/icon/scans.svg'
import { ReactComponent as Impressions } from '../assets/icon/impressions.svg'

export interface KPICardProps {
  title: string
  intervals: KPIStatsInterval[]
}

const KPICard: FC<KPICardProps> = ({ title, intervals, ...props }) =>
  intervals.length ? (
    <Card tw="p-8" {...props}>
      <Text
        as="h3"
        preset="p1"
        transform="uppercase"
        tw="text-dark-blue-gray tracking-wider mb-8"
      >
        {title}
      </Text>
      <div tw="flex flex-row flex-wrap items-center">
        <KPICardStatSection
          metric="reach"
          intervals={intervals}
          tw="mb-8 lg:mb-0"
        />
        <Spacer
          size="1px"
          direction="horizontal"
          tw="bg-platinum mx-5 h-64 hidden lg:block"
        />
        <KPICardStatSection metric="engagement" intervals={intervals} />
      </div>
    </Card>
  ) : null

interface KPICardStatSectionProps {
  metric: 'reach' | 'engagement'
  intervals: KPIStatsInterval[]
}

const KPICardStatSection: FC<KPICardStatSectionProps> = ({
  metric,
  intervals,
  ...props
}) => {
  const todayDate = moment()
  const primaryData = intervals[0]
  const extraData = intervals.slice(1)

  const formatDateRange = (interval: KPIStatsInterval) => {
    const startDate = moment(interval.starts_at)
    const endDate = moment(interval.ends_at)
    const endsToday = todayDate.isSame(endDate, 'date')
    return `${startDate.format('MMM Do')} - ${
      endsToday ? 'Today' : endDate.format('MMM Do')
    }`
  }

  const Icon = metric === 'reach' ? Impressions : Scans
  return (
    <section tw="flex-grow min-w-full lg:min-w-80" {...props}>
      <Icon width={24} height={24} tw="mb-2" />
      <Text as="h4" preset="h4">
        Total {metric === 'reach' ? 'impressions' : 'scans'}
      </Text>
      <Text as="h5" preset="h1">
        {formatMetrics(primaryData[metric] || 0)}
      </Text>
      <Text as="h6" preset="p1" tw="text-dark-blue-gray">
        {formatDateRange(primaryData)}
      </Text>
      <hr tw="bg-platinum my-4" />
      <div tw="flex flex-row items-center justify-between">
        {extraData.map((interval, index) => (
          <div key={`interval#${index}`} tw="flex-grow">
            <Text as="p" preset="p2" weight="medium" tw="mb-1">
              {interval.title}
            </Text>
            <Text as="p" preset="h3">
              {formatMetrics(interval[metric] || 0)}
            </Text>
            <Text as="p" preset="p2">
              {formatDateRange(interval)}
            </Text>
          </div>
        ))}
      </div>
    </section>
  )
}

export default KPICard
