import React, { FC } from 'react'

import tw, { css } from 'twin.macro'
import { Link } from 'react-router-dom'

import { ReactComponent as Remove } from '../../../../assets/icon/remove/remove_gray.svg'

import {
  UserEmail,
  useAccountEmailRemoveMutation,
  useAccountEmailSetPrimaryMutation,
} from '../../../../graphql/components'

import { AlertVariant } from '../../../../stores/alerts-manager'
import { alertsManager, authentication } from '../../../../stores'

export interface EmailCardProps {
  index: number
  last: boolean
  email: UserEmail
  refetchData?: () => void
}

const EmailCard: FC<EmailCardProps> = ({ index, last, email, refetchData }) => {
  const isVerified = !!email.verified_at
  const isPrimary = authentication.user?.primary_email_index === index
  const verifiedDate = new Date(email.verified_at).toLocaleDateString(
    'default',
    { month: 'short', day: 'numeric', year: 'numeric' }
  )

  const [removeEmail] = useAccountEmailRemoveMutation()
  const onRemoveEmail = async () => {
    const res = await removeEmail({ variables: { email: email.address || '' } })
    if (res.data?.removed) refetchData && refetchData()
    else
      handleError(
        'We couldn’t remove the email address. Please try again later.'
      )
  }

  const [setEmailAsPrimary] = useAccountEmailSetPrimaryMutation()
  const onSetEmailAsPrimary = async () => {
    const res = await setEmailAsPrimary({
      variables: { email: email.address || '' },
    })
    if (res.data?.isPrimary) refetchData && refetchData()
    else
      handleError(
        'We couldn’t update your primary email. Please try again later.'
      )
  }

  const handleError = (message: string) => {
    alertsManager.emit({
      message,
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'update-email-error-alert',
    })
  }

  return (
    <div
      css={css`
        ${last ? tw`mb-0` : tw`mb-2`}
        ${tw`p-4 border border-platinum rounded max-w-md w-full`}
      `}
    >
      <p tw="font-normal text-charcoal leading-tight">{email.address}</p>
      {isVerified && (
        <span tw="font-normal text-xs text-dark-blue-gray leading-tight">
          {`Verified ${verifiedDate}`}
        </span>
      )}
      <div tw="flex flex-row items-center justify-between mt-2">
        <div tw="flex flex-row items-center">
          {isVerified ? (
            <>
              <span tw="font-normal text-metallic-seaweed leading-tight">
                Verified
              </span>
              {!isPrimary && (
                <span
                  tw="font-normal text-dark-blue-gray leading-tight cursor-pointer underline ml-4"
                  onClick={onSetEmailAsPrimary}
                >
                  Set as primary email
                </span>
              )}
            </>
          ) : (
            <>
              <span tw="font-normal text-golden-brown leading-tight">
                Unverified
              </span>
              <Link
                to={`/auth/sign-up/verify-email/${index}`}
                tw="font-normal text-dark-blue-gray leading-tight cursor-pointer underline ml-4"
              >
                Verify email
              </Link>
            </>
          )}
        </div>
        {isPrimary ? (
          <span tw="font-normal text-flickr-blue leading-tight">
            Primary email
          </span>
        ) : (
          <div
            tw="flex flex-row items-center cursor-pointer"
            onClick={onRemoveEmail}
          >
            <Remove width={12} height={12} />
            <span tw="font-normal text-dark-blue-gray leading-tight ml-2">
              Remove
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

export default EmailCard
