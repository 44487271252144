import React, { FC, useState } from 'react'

import tw, { css } from 'twin.macro'

import { IconType } from 'react-icons'

export interface IControlOption {
  id: string
  label: string
  icon?: IconType
}

const baseStyle = tw`flex items-center justify-center min-w-32 px-4 py-2 border`

const ViewControls: FC<{
  disabled?: boolean
  initialOptionId?: string
  options: IControlOption[]
  onSelect?: (optionId: string) => void
}> = ({ options, initialOptionId, disabled, onSelect }) => {
  const [selected, setSelected] = useState<string>(
    initialOptionId || options[0].id || ''
  )

  const cursorStyle = () =>
    disabled ? tw`cursor-not-allowed` : tw`cursor-pointer`

  const selectionStyle = (optionId: string) =>
    selected === optionId
      ? tw`border-purple text-purple`
      : tw`border-platinum text-charcoal`

  const cornersStyle = (index: number) => {
    if (index === 0) return tw`rounded-tl rounded-bl`
    else if (index === options.length - 1) return tw`rounded-tr rounded-br`
    return ''
  }

  const handleClick = (optionId: string) => {
    !disabled && setSelected(optionId)
    if (onSelect && !disabled) {
      onSelect(optionId)
    }
  }

  return (
    <div
      css={css`
        ${tw`flex`} ${disabled && tw`opacity-75`}
      `}
    >
      {options.map(({ id, label, icon: Icon }, index) => (
        <span
          key={id}
          onClick={() => handleClick(id)}
          css={css`
            ${baseStyle}
            ${cursorStyle()}
            ${selectionStyle(id)}
            ${cornersStyle(index)}
          `}
        >
          {Icon && (
            <Icon
              size={14}
              css={css`
                ${tw`mr-2`}
              `}
            />
          )}
          {label}
        </span>
      ))}
    </div>
  )
}

export default ViewControls
