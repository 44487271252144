import { action, computed, observable } from 'mobx'
import uuid from 'uuid'
import axios, { CancelTokenSource } from 'axios'
import delay from 'delay'

import {
  PreSignCreativeUploadMutation,
  PreSignCreativeUploadMutationVariables,
  PreSignCreativeUploadDocument,
  EnumCreativeType,
  NotifyCreativeUploadCompletedMutation,
  NotifyCreativeUploadCompletedMutationVariables,
  NotifyCreativeUploadCompletedDocument,
} from '../graphql/components'
import apolloClient from '../graphql/client'

export enum UploadState {
  WAITING = 'WAITING',
  UPLOADING = 'UPLOADING',
  UPLOADED = 'UPLOADED',
  DISMISSED = 'DISMISSED',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
}

export interface Upload {
  id: string
  file: File
  type: EnumCreativeType
  state: UploadState
  progress?: number
  data?: any
  cancel?: CancelTokenSource['cancel']
}

export default class UploadsManager {
  @observable public uploads = new Map<string, Upload>()

  // computed

  @computed public get uploadsByState() {
    const uploadsByState: { [index in UploadState]: Upload[] } = {
      WAITING: [],
      UPLOADING: [],
      UPLOADED: [],
      DISMISSED: [],
      FAILED: [],
      CANCELLED: [],
    }

    this.uploads.forEach((upload) => {
      uploadsByState[upload.state].push(upload)
    })

    return uploadsByState
  }

  @computed public get uploadsBar(): Upload[] {
    const ups = this.uploadsByState
    return [
      ...ups[UploadState.FAILED],
      ...ups[UploadState.UPLOADING],
      ...ups[UploadState.WAITING],
      ...ups[UploadState.UPLOADED],
      ...ups[UploadState.CANCELLED],
    ]
  }

  // methods

  @action public async upload(upl?: Partial<Upload>): Promise<Upload> {
    if (!upl) {
      throw new Error(`Upload argument not provided`)
    }

    if (!upl.file) {
      throw new Error(`Can't find a file to upload`)
    }

    if (!upl.type || !Object.values(EnumCreativeType).includes(upl.type)) {
      throw new Error(`Invalid upload type`)
    }

    const upload: Upload = {
      id: upl.id || uuid.v4(),
      file: upl.file,
      type: upl.type,
      state: UploadState.WAITING,
    }
    this.uploads.set(upload.id, upload)

    await delay(1000)

    try {
      // gwt the url
      const presignRes = await apolloClient.mutate<
        PreSignCreativeUploadMutation,
        PreSignCreativeUploadMutationVariables
      >({
        mutation: PreSignCreativeUploadDocument,
        variables: {
          filename: upload.file.name,
          mimetype: upload.file.type,
          creative_type: upload.type,
        },
      })

      const { presigned_url, creative_id } =
        presignRes.data?.Dashboard__PreSignCreativeUpload || {}

      if (!presigned_url) {
        throw new Error(`Unable to start the upload`)
      }

      const source = axios.CancelToken.source()

      // we're going to initiate the upload
      upload.state = UploadState.UPLOADING
      upload.cancel = source.cancel
      upload.progress = 0
      this.uploads.set(upload.id, upload)

      // execute the axios request
      const res = await axios.put(presigned_url, upload.file, {
        cancelToken: source.token,
        onUploadProgress: (e: any) => {
          const progress = (e?.loaded || 0) / (e?.total || 0)

          if (progress !== upload.progress) {
            upload.progress = progress
            this.uploads.set(upload.id, upload)
          }
        },
      })

      // check if an error came in the graphql response
      if (res?.data?.errors?.length) {
        upload.state = UploadState.FAILED
        console.error('upload error', res.data.errors)
      } else {
        // if it gets here, upload was successful
        upload.state = UploadState.UPLOADED
        upload.data = res.data

        await apolloClient.mutate<
          NotifyCreativeUploadCompletedMutation,
          NotifyCreativeUploadCompletedMutationVariables
        >({
          mutation: NotifyCreativeUploadCompletedDocument,
          variables: {
            creative_id,
          },
        })
      }

      return upload
    } catch (err) {
      // cancelled
      if (axios.isCancel(err)) {
        upload.state = UploadState.CANCELLED

        // failed
      } else {
        upload.state = UploadState.FAILED
      }

      throw err
    } finally {
      delete upload.cancel
      this.uploads.set(upload.id, upload)
    }
  }

  @action public async retry(id: string): Promise<Upload> {
    const upload = this.get(id)
    return this.upload(upload)
  }

  public get(id: string): Upload {
    const upload = this.uploads.get(id)

    if (!upload) {
      throw new Error(`Upload ${id} not found`)
    }

    return upload
  }

  public async cancel(id: string) {
    const upload = this.get(id)

    // TODO: Cancel using axios
    if (upload.cancel) {
      upload.cancel()
      delete upload.cancel
    }

    upload.state = UploadState.CANCELLED
    this.uploads.set(id, upload)

    return false
  }

  public dismiss(id: string) {
    this.setState(id, UploadState.DISMISSED)
  }

  public setState(id: string, state: UploadState) {
    this.uploads.set(id, {
      ...this.get(id),
      state,
    })
  }
}

export const uploadsManager = new UploadsManager()

// mock data

// uploadsManager.uploads.set('waiting-1', {
//   id: `waiting-1`,
//   file: new File([], 'test.jpg'),
//   type: UploadType.AD_CREATIVE,
//   state: UploadState.WAITING,
// })

// uploadsManager.uploads.set('uploading-1', {
//   id: `uploading-1`,
//   file: new File([], 'test.jpg'),
//   type: UploadType.AD_CREATIVE,
//   state: UploadState.UPLOADING,
//   progress: 0.63,
// })

// uploadsManager.uploads.set('uploading-2', {
//   id: `uploading-2`,
//   file: new File([], 'test.jpg'),
//   type: UploadType.AD_CREATIVE,
//   state: UploadState.UPLOADING,
//   progress: 0.73,
// })

// uploadsManager.uploads.set('failed-1', {
//   id: `failed-1`,
//   file: new File([], 'test.jpg'),
//   type: UploadType.AD_CREATIVE,
//   state: UploadState.FAILED,
//   progress: 0.27,
// })

// uploadsManager.uploads.set('cancelled-1', {
//   id: `cancelled-1`,
//   file: new File([], 'test.jpg'),
//   type: UploadType.AD_CREATIVE,
//   state: UploadState.CANCELLED,
//   progress: 0.63,
// })
