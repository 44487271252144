import React, { FC } from 'react'
import 'twin.macro'

import { ReactComponent as NoResultsIllustration } from '../../../../../assets/illustrations/profile_9-1_members_search_no_results.svg'

export interface NoSearchResultsProps {
  searchQuery: string
}

const NoSearchResults: FC<NoSearchResultsProps> = ({ searchQuery }) => (
  <div tw="w-full flex flex-col items-center mt-12">
    <NoResultsIllustration />
    <p tw="text-charcoal">No Matches for &quot;{searchQuery}&quot;</p>
  </div>
)

export default NoSearchResults
