import React, { FC } from 'react'
import 'twin.macro'

import StateToggles from './state-toggles'
import ElementSection, { ElementSectionProps } from './element-section'

import Spacer from '../../../../../ui-blocks/spacer'
import Dropdown from '../../../../../ui-blocks/dropdown'

import useElementStates from '../../../../../utils/use-element-states'

const mockOptions = [
  { value: 'value#1', label: 'Value#1' },
  { value: 'value#2', label: 'Value#2' },
  { value: 'value#3', label: 'Value#3' },
  { value: 'value#4', label: 'Value#4' },
]

const DropdownSection: FC<ElementSectionProps> = ({
  id,
  title,
  description,
  last,
}) => {
  const {
    states,
    values: { error, ...values },
  } = useElementStates(['error', 'success', 'ghost', 'opened', 'disabled'])

  return (
    <ElementSection
      id={id}
      last={last}
      title={title}
      description={
        description || `These are the states supported by the ${title} element:`
      }
    >
      <StateToggles toggles={states} />
      <div tw="flex flex-row flex-wrap">
        <div style={{ width: '20rem', marginBottom: '0.5rem' }}>
          <Dropdown
            options={mockOptions}
            menuIsOpen={values.opened}
            isDisabled={values.disabled}
            error={error ? 'Error message!' : ''}
            {...values}
          />
        </div>
        <Spacer size="1.5rem" direction="horizontal" />
        <div style={{ width: '20rem', marginBottom: '0.5rem' }}>
          <Dropdown
            placeholder="Placeholder..."
            options={mockOptions}
            menuIsOpen={values.opened}
            isDisabled={values.disabled}
            error={error ? 'Error message!' : ''}
            {...values}
          />
        </div>
        <Spacer size="1.5rem" direction="horizontal" />
        <div style={{ width: '20rem', marginBottom: '0.5rem' }}>
          <Dropdown
            defaultValue={{ value: 'value#1', label: 'Value#1' }}
            options={mockOptions}
            menuIsOpen={values.opened}
            isDisabled={values.disabled}
            error={error ? 'Error message!' : ''}
            {...values}
          />
        </div>
      </div>
    </ElementSection>
  )
}

export default DropdownSection
