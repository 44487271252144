import React, { FC } from 'react'
import tw, { css } from 'twin.macro'

import StateToggles from './state-toggles'
import ElementSection, { ElementSectionProps } from './element-section'

import Button from '../../../../../ui-blocks/button'

import { IconType } from 'react-icons'
import { IoMdAdd } from 'react-icons/io'

import useElementStates from '../../../../../utils/use-element-states'

interface ButtonProps {
  label?: string
  primary?: boolean
  secondary?: boolean
  menu?: boolean
  tab?: boolean
  ghost?: boolean
  iconLeft?: IconType
  iconRight?: IconType
}

const buttons: ButtonProps[][] = [
  [
    { primary: true, label: 'Primary Button' },
    { primary: true, iconLeft: IoMdAdd, label: 'Primary Button' },
    { primary: true, iconRight: IoMdAdd, label: 'Primary Button' },
    { primary: true, iconLeft: IoMdAdd },
  ],
  [
    { secondary: true, label: 'Secondary Button' },
    { secondary: true, iconLeft: IoMdAdd, label: 'Secondary Button' },
    { secondary: true, iconRight: IoMdAdd, label: 'Secondary Button' },
    { secondary: true, iconLeft: IoMdAdd },
  ],
  [
    { menu: true, label: 'Menu Button' },
    { menu: true, iconLeft: IoMdAdd, label: 'Menu Button' },
    { menu: true, iconRight: IoMdAdd, label: 'Menu Button' },
    { menu: true, iconLeft: IoMdAdd },
  ],
  [
    { tab: true, label: 'Tab Button' },
    { tab: true, iconLeft: IoMdAdd, label: 'Tab Button' },
    { tab: true, iconRight: IoMdAdd, label: 'Tab Button' },
    { tab: true, iconLeft: IoMdAdd },
  ],
  [
    { ghost: true, label: 'Ghost Button' },
    { ghost: true, iconLeft: IoMdAdd, label: 'Ghost Button' },
    { ghost: true, iconRight: IoMdAdd, label: 'Ghost Button' },
    { ghost: true, iconLeft: IoMdAdd },
  ],
]

const ButtonSection: FC<ElementSectionProps> = ({
  id,
  title,
  description,
  last,
}) => {
  const { states, values } = useElementStates([
    'loading',
    'selected',
    'disabled',
  ])

  return (
    <ElementSection
      id={id}
      last={last}
      title={title}
      description={
        description || `These are the states supported by the ${title} element:`
      }
    >
      <StateToggles toggles={states} />
      {buttons.map((buttonRow, i) => (
        <div
          key={`button-row#${i}`}
          css={css`
            ${i !== buttons.length - 1 && tw`mb-4`}
            ${tw`flex flex-row flex-wrap justify-between`}
          `}
        >
          {buttonRow.map(({ label, ...rest }, j) => (
            <Button key={`button#${i}${j}`} {...values} {...rest}>
              {label}
            </Button>
          ))}
        </div>
      ))}
    </ElementSection>
  )
}

export default ButtonSection
