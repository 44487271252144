import React, { FC, Ref } from 'react'
import tw, { css } from 'twin.macro'
import { IconBaseProps } from 'react-icons'
import { FaStore } from 'react-icons/fa'
import { AiOutlineCheck } from 'react-icons/ai'
import { FiEdit2 } from 'react-icons/fi'
import Tag from '../../ui-blocks/tag'
import Card from '../../ui-blocks/card'
import { Player } from '../../graphql/components'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

type PartialPlayer = Pick<
  Player,
  'name' | 'geographics' | 'demographics' | 'created'
> &
  Partial<Player>

export interface PlayerCardProps {
  inputRef?: Ref<HTMLInputElement>
  player: PartialPlayer
  icon?: FC<IconBaseProps>
  selected?: boolean
  preselected?: boolean
  pending?: boolean
  disabled?: boolean
  onToggle?: (selected: boolean) => void
}

const PlayerCard: FC<PlayerCardProps> = ({
  inputRef,
  player,
  icon,
  selected,
  preselected,
  pending,
  onToggle,
  disabled,
}) => {
  const navigate = useNavigate()

  const selectable = !(disabled || pending)
  const Icon = icon || FaStore

  const { _id, name, geographics, demographics, created } = player || {}

  return (
    <Card
      css={css`
        ${(!pending && tw`cursor-pointer`) || ``}
        ${(preselected && tw`border-dashed`) || ``}
        &:hover {
          ${selectable &&
          `
            transition: grow ease-out once 0.25s;
          `}
          ${(selected && tw`border-purple`) || ``}
        }
        ${(selected && tw`border-purple`) || ``}
      `}
    >
      <div
        onClick={onToggle && (() => onToggle(!selected))}
        css={css`
          ${tw`
              p-4
              relative
              flex
              flex-col
              justify-between
              h-32
            `}
          width: 20rem;
          ${(!!disabled && tw`opacity-75 cursor-not-allowed`) || ``}
        `}
      >
        <input
          ref={inputRef}
          type="checkbox"
          checked={selected}
          readOnly
          css={css`
            ${tw`hidden`}
          `}
        />

        <div>
          <div
            css={css`
              ${tw`text-charcoal font-medium truncate`}
            `}
          >
            {pending && !name ? 'No name' : name}
          </div>
          <p
            css={css`
              ${tw`whitespace-pre-line text-charcoal`}
            `}
          >
            {pending &&
              !!geographics?.address &&
              !!demographics &&
              (!geographics.address
                ? 'No address'
                : `${geographics.address.street}, ${geographics.address.number}
                ${geographics.address.zip} ${geographics.address.city}, ${demographics.country_id}`)}
          </p>
        </div>

        <div
          css={css`
            ${tw`flex`}
          `}
        >
          <div
            css={css`
              ${tw`flex items-end flex-wrap flex-1`}
            `}
          >
            {pending
              ? !!created && (
                  <div
                    css={css`
                      ${tw`text-charcoal text-xs leading-tight py-1`}
                    `}
                  >{`Connected on ${moment(created.at).format('LLL')}`}</div>
                )
              : !!demographics &&
                !!demographics.venue_type_ids &&
                demographics.venue_type_ids.map((tag: string, i: number) => (
                  <Tag key={i} text={tag} />
                ))}
          </div>

          {pending ? (
            <button
              onClick={() => !disabled && navigate(`/players/${_id}`)}
              css={css`
                ${tw`w-12 text-charcoal flex items-center justify-between focus:outline-none`}
                &:hover {
                  ${(!disabled && tw`text-purple`) || `cursor-not-allowed`}
                }
              `}
            >
              <FiEdit2 />
              Edit
            </button>
          ) : (
            <div
              css={css`
                ${tw`w-8 h-8 rounded-full flex flex-none justify-center items-center`}
              `}
            >
              <Icon
                size={16}
                css={css`
                  ${tw`text-purple`}
                `}
              />
            </div>
          )}
        </div>

        {selected && (
          <div
            css={css`
              ${tw`w-4 h-4 bg-purple rounded-full flex justify-center items-center absolute`}
              top: -0.5rem;
              right: -0.5rem;
            `}
          >
            <AiOutlineCheck
              size={8}
              css={css`
                ${tw`text-white`}
              `}
            />
          </div>
        )}
      </div>
    </Card>
  )
}

export default PlayerCard
