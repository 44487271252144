import React, { Fragment, useState } from 'react'

import 'twin.macro'
import _ from 'lodash'

import {
  Row,
  RowCell,
  FullRow,
  TableHeader,
  TableColumn,
  HeaderColumn,
  generateTableComponent,
} from '../../ui-blocks/table'
import Status from '../../ui-blocks/status'
import Switch from '../../ui-blocks/switch'

import NoResults from '../no-results'
import InlineDate from '../inline-date'
import NotDefined from '../not-defined'
import LoadingPage from '../loading-page'

import { ReactComponent as NoResultsIllustration } from '../../assets/illustrations/campaign_3-3_new_3_search_no_results.svg'

import {
  Network,
  EnumNetworkStatus,
  useUpdateNetworkActivationMutation,
} from '../../graphql/components'
import { SortSettings } from '../../typings'

import { alertsManager } from '../../stores'
import { AlertVariant } from '../../stores/alerts-manager'

const COLUMNS: TableColumn[] = [
  { key: 'switch', label: '', isSortable: false, component: Fragment },
  { key: 'name', label: 'Name', isSortable: true, component: Fragment },
  {
    key: 'status',
    label: 'Status',
    isSortable: true,
    prop: 'value',
    component: Status,
  },
  {
    key: 'stats[0].reach',
    label: 'Impressions',
    isSortable: true,
    component: Fragment,
  },
  {
    key: 'stats[0].engagement',
    label: 'Scans',
    isSortable: true,
    component: Fragment,
  },
  {
    key: 'players',
    label: 'Players',
    isSortable: true,
    component: Fragment,
  },
  {
    key: 'created.at',
    label: 'Created On',
    isSortable: true,
    prop: 'date',
    component: InlineDate,
  },
]

type PartialNetwork = Pick<
  Network,
  '_id' | 'name' | 'players' | 'created' | 'status'
>

export const NetworksListTable = generateTableComponent<PartialNetwork>({
  HeaderComponent({ onSort }) {
    const [selectedColumn, setSelectedColumn] = useState<string>('')

    const handleClick = (settings: SortSettings) => {
      setSelectedColumn(settings.column)
      onSort?.(settings)
    }

    return (
      <TableHeader>
        {COLUMNS.map((col, index) => (
          <HeaderColumn
            key={`header-column#${index}`}
            isSortable={col.isSortable}
            isSelected={selectedColumn === col.key}
            onClick={(isAscending) =>
              handleClick({ column: col.key, isAscending })
            }
          >
            {col.label}
          </HeaderColumn>
        ))}
      </TableHeader>
    )
  },

  EmptyComponent({ searchQuery }) {
    return (
      <FullRow>
        <NoResults
          tw="mt-8"
          model="Network"
          searchQuery={searchQuery}
          Illustration={NoResultsIllustration}
        />
      </FullRow>
    )
  },

  LoadingComponent() {
    return (
      <FullRow>
        <LoadingPage />
      </FullRow>
    )
  },

  ErrorComponent() {
    return null
  },

  RowComponent({ datum: network }) {
    const { _id, status } = network

    const handleUpdateError = (activate: boolean) => {
      alertsManager.emit({
        dismissable: true,
        variant: AlertVariant.ERROR,
        id: 'update-network-status-error-alert',
        message: `We couldn't ${
          activate ? 'activate' : 'deactivate'
        } this network. Please try again.`,
      })
    }

    const [updateNetworkActivation] = useUpdateNetworkActivationMutation()
    const onUpdateNetworkActivation = async (activate: boolean) => {
      try {
        const res = await updateNetworkActivation({
          variables: { ids: [_id], is_active: activate },
        })
        if (!res.data?.areUpdated) return handleUpdateError(activate)
        document.dispatchEvent(new CustomEvent('refetch-networks'))
      } catch {
        handleUpdateError(activate)
      }
    }

    return (
      <Row>
        {COLUMNS.map(
          ({ key, prop, transform, component: Component }, index) => {
            const value = _.get(network, key, undefined)
            return (
              <RowCell
                key={`row-cell#${index}`}
                linkTo={
                  key === 'name' && status !== EnumNetworkStatus.Archived
                    ? `/networks/${_id}`
                    : undefined
                }
              >
                {key === 'switch' ? (
                  <Switch
                    disabled={status === EnumNetworkStatus.Archived}
                    initialChecked={status === EnumNetworkStatus.Activated}
                    onSelect={onUpdateNetworkActivation}
                  />
                ) : (
                  <Component
                    {...(!!prop ? { [prop]: value } : {})}
                    {...(!!transform
                      ? { css: [`text-transform: ${transform};`] }
                      : {})}
                  >
                    {!prop &&
                      ((Array.isArray(value) ? value.length : value) ?? (
                        <NotDefined />
                      ))}
                  </Component>
                )}
              </RowCell>
            )
          }
        )}
      </Row>
    )
  },
})
