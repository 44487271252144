import React, { FC, useEffect } from 'react'
import 'twin.macro'

import { string, object } from 'yup'
import { useFormik, FormikHelpers } from 'formik'

import Spacer from '../../../../../ui-blocks/spacer'
import Button from '../../../../../ui-blocks/button'
import { InputFormik } from '../../../../../ui-blocks/input'

export interface WebhookFormikValues {
  url: string
}

const webhookValidationSchema = object().shape({
  url: string()
    .trim('Value cannot have leading or trailing white spaces')
    .url('Must be a valid URL')
    .required('URL is required')
    .strict(true),
})

export interface WebhookFormProps {
  disabled?: boolean
  submitting?: boolean
  initialValues?: WebhookFormikValues
  onSubmit: (
    values: WebhookFormikValues,
    formikHelpers: FormikHelpers<WebhookFormikValues>
  ) => void | Promise<any>
}

const WebhookForm: FC<WebhookFormProps> = ({
  disabled,
  submitting,
  initialValues,
  onSubmit,
}) => {
  const formik = useFormik<WebhookFormikValues>({
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: webhookValidationSchema,
    initialValues: initialValues || { url: '' },
    onSubmit,
  })

  useEffect(() => {
    !!initialValues && formik.setValues(initialValues)
  }, [initialValues])

  return (
    <form onChange={formik.handleChange} onSubmit={formik.handleSubmit}>
      <div tw="max-w-xs">
        <InputFormik
          required
          type="text"
          name="url"
          label="URL"
          placeholder="https://your-domain.com"
          disabled={disabled}
          formik={formik}
        />
      </div>
      <Spacer size="2.5rem" />
      <Button type="submit" loading={submitting} disabled={!formik.dirty}>
        Save
      </Button>
    </form>
  )
}

export default WebhookForm
