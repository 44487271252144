import { useEffect, useMemo, useState } from 'react'

interface Cover {
  photoUrl: string
  photoLink: string
  authorName: string
  authorLink: string
  locationName: string
}

export const covers: Cover[] = [
  {
    photoUrl: require('./D-KrTp0I7YI.jpg'),
    photoLink: 'https://unsplash.com/photos/D-KrTp0I7YI',
    authorName: 'Nuno Alberto',
    authorLink: 'https://unsplash.com/@nunomiguelmendes',
    locationName: 'Shanghai, China',
  },
  {
    photoUrl: require('./BBNZK3z1osQ.jpg'),
    photoLink: 'https://unsplash.com/photos/BBNZK3z1osQ',
    authorName: 'Nuno Alberto',
    authorLink: 'https://unsplash.com/@nunomiguelmendes',
    locationName: 'Shanghai, China',
  },
  {
    photoUrl: require('./TI-4WfrXnJ8.jpg'),
    photoLink: 'https://unsplash.com/photos/TI-4WfrXnJ8',
    authorName: 'Sung Shin',
    authorLink: 'https://unsplash.com/@ironstagram',
    locationName: 'Piccadilly Circus, London, UK',
  },
  {
    photoUrl: require('./frQaCAZjsqI.jpg'),
    photoLink: 'https://unsplash.com/photos/frQaCAZjsqI',
    authorName: 'Étienne Godiard',
    authorLink: 'https://unsplash.com/@etiennegodiard',
    locationName: 'London, UK',
  },
  {
    photoUrl: require('./LEikIOMSxfs.jpg'),
    photoLink: 'https://unsplash.com/photos/LEikIOMSxfs',
    authorName: 'Andre Benz',
    authorLink: 'https://unsplash.com/@trapnation',
    locationName: 'New York, USA',
  },
  {
    photoUrl: require('./X_roZ7toBJY.jpg'),
    photoLink: 'https://unsplash.com/photos/X_roZ7toBJY',
    authorName: 'Joshua Earle',
    authorLink: 'https://unsplash.com/@joshuaearle',
    locationName: 'Times Square, New York, USA',
  },
  {
    photoUrl: require('./Cc4sToR2Oc0.jpg'),
    photoLink: 'https://unsplash.com/photos/Cc4sToR2Oc0',
    authorName: 'Joe Yates',
    authorLink: 'https://unsplash.com/@josephyates_',
    locationName: 'Theater District, New York, USA',
  },
  {
    photoUrl: require('./x7hbiQr9XDM.jpg'),
    photoLink: 'https://unsplash.com/photos/x7hbiQr9XDM',
    authorName: 'Carson Materson',
    authorLink: 'https://unsplash.com/@sonson',
    locationName: 'New York, USA',
  },
  {
    photoUrl: require('./MpQ2gVosfR8.jpg'),
    photoLink: 'https://unsplash.com/photos/MpQ2gVosfR8',
    authorName: 'Akshay Chauhan',
    authorLink: 'https://unsplash.com/@akshayspaceship',
    locationName: 'Toronto Pearson Airport, Mississauga, Canada',
  },
  {
    photoUrl: require('./dDp-6pXrYik.jpg'),
    photoLink: 'https://unsplash.com/photos/dDp-6pXrYik',
    authorName: 'Humphrey Muleba',
    authorLink: 'https://unsplash.com/@good_citizen',
    locationName: 'Faro, Portugal',
  },
  {
    photoUrl: require('./jy41u6_nBmk.jpg'),
    photoLink: 'https://unsplash.com/photos/jy41u6_nBmk',
    authorName: 'Ahmad Shahzad',
    authorLink: 'https://unsplash.com/@iahmadshahzad07',
    locationName: 'Punjab, Pakistan',
  },
  {
    photoUrl: require('./UmaojK7erQo.jpg'),
    photoLink: 'https://unsplash.com/photos/UmaojK7erQo',
    authorName: 'Sigmund',
    authorLink: 'https://unsplash.com/@sigmund',
    locationName: 'Unknown Location',
  },
]

export function getRandomCover() {
  const random = Math.floor(Math.random() * covers.length)
  return covers[random]
}

export function useCover(cover: Cover) {
  const [loaded, setLoaded] = useState<boolean>(false)

  useEffect(() => {
    const img = new Image(0, 0)

    img.src = cover.photoUrl
    img.onload = () => {
      setLoaded(true)
    }

    return () => {
      img.remove()
    }
  }, [cover])

  return { loaded }
}

export function useRandomCover() {
  const cover = useMemo(() => getRandomCover(), [])
  const { loaded } = useCover(cover)
  return { cover, loaded }
}
