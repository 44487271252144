import React, { FC } from 'react'

import NotDefined from './not-defined'

export interface SaturationProps {
  saturation?: number | null
}

const Saturation: FC<SaturationProps> = ({ saturation }) =>
  !!saturation ? (
    <span>{`${saturation}x / ${saturation * 100}%`}</span>
  ) : (
    <NotDefined />
  )

export default Saturation
