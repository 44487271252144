import React, { FC } from 'react'

import tw, { css } from 'twin.macro'

import Container from '../ui-blocks/container'
import ButtonLink from '../ui-blocks/button-link'

import { ReactComponent as NoResultsIllustration } from '../assets/illustrations/campaign_3-7_new_4_search_no_results.svg'

import { authentication } from '../stores'

const NotFound: FC<{ title?: string; description?: string | string[] }> = ({
  title,
  description = [
    "The content you tried to access doesn't exist or we couldn't retrieve it.",
    'Please try again later or check if the page you tried to access is correct',
  ],
}) => (
  <Container>
    <div
      css={css`
        ${tw`flex flex-col items-center justify-center`}
      `}
    >
      <NoResultsIllustration
        css={css`
          ${tw`mb-10`}
        `}
      />
      <h1
        css={css`
          ${tw`font-light text-3xl leading-tight mb-4`}
        `}
      >
        {title || 'This page is unavailable'}
      </h1>
      <div
        css={css`
          ${tw`font-normal text-center leading-tight mb-10`}
        `}
      >
        {Array.isArray(description) ? (
          description.map((line, index) => <p key={`line#${index}`}>{line}</p>)
        ) : (
          <p>{description}</p>
        )}
      </div>
      <ButtonLink
        to={`/workspaces/${authentication.selected_workspace_id}/overview`}
      >
        Back to Workspace Overview
      </ButtonLink>
    </div>
  </Container>
)

export default NotFound
