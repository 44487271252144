import React, { FC } from 'react'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import { IconBaseProps } from 'react-icons'
import tw, { css } from 'twin.macro'

const CircularLoading: FC<IconBaseProps> = () => (
  <AiOutlineLoading3Quarters
    css={css`
      ${tw`inline text-center animate-spin`}
    `}
  />
)

export default CircularLoading
