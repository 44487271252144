import React, { FC, useEffect, useState } from 'react'
import { Router, Navigate, RouteProps } from 'react-router'
import { Route as RRoute, Routes } from 'react-router-dom'
import { observer } from 'mobx-react'

// Stores
import { authentication } from './stores'

// Layouts
import MainLayout from './layouts/main'
import AuthLayout from './layouts/auth'
import WorkspacePanelLayout from './layouts/workspace-panel'
import AccountPanelLayout from './layouts/account-panel'

// Pages
import AccountPersonalInformation from './pages/account/personal-information'
import AccountContacts from './pages/account/contacts'
import AccountSecurity from './pages/account/security'
import AccountNotifications from './pages/account/notifications'
import AccountWorkspaces from './pages/account/workspaces'
import AccountDangerZone from './pages/account/danger-zone'
import AuthSignInPage from './pages/auth/sign-in'
import AuthSignOutPage from './pages/auth/sign-out'
import AuthSignUpPage from './pages/auth/sign-up'
import EmailCodeVerification from './pages/auth/sign-up/verify-email'
import SignUpValidation from './pages/auth/sign-up/validation'
import AuthSignUpInvitesManagement from './pages/auth/invites'
import WorkspaceCreatePage from './pages/workspaces/create'
import WorkspaceOverviewPage from './pages/workspaces/overview'
import ListPlayersPage from './pages/players/list'
import BindPlayerPage from './pages/players/bind'
import ViewPlayerPage from './pages/players/view'
import ListCampaignsPage from './pages/campaigns/list'
import CreateCampaignPage from './pages/campaigns/create'
import ViewCampaignPage from './pages/campaigns/view'
import ViewCampaignAdGroupPage from './pages/campaigns/ad-groups/view'
import ListAdCreativesPage from './pages/creatives/list'
import ViewAdCreativePage from './pages/creatives/view'
import ListNetworksPage from './pages/networks/list'
import CreateNetworkPage from './pages/networks/create'
import ViewNetworkPage from './pages/networks/view'
import AuthForgotPasswordPage from './pages/auth/forgot-password'
import AuthSignUpInviteExpired from './pages/auth/sign-up/invite-expired'
import ComponentsCatalogPage from './pages/dev/catalog'
import ElementsCatalogPage from './pages/dev/catalog/elements'
import CampaignAdGroupCreate from './pages/campaigns/ad-groups/create'
import NoInternetConnection from './components/no-internet-connection'

import WorkspaceSettingsDetailsPage from './pages/workspaces/settings/details'
import WorkspaceSettingsMembersPage from './pages/workspaces/settings/members'
import WorkspaceSettingsAddMemberPage from './pages/workspaces/settings/members/add'
import WorkspaceSettingsBillingPage from './pages/workspaces/settings/billing'
import WorkspaceSettingsPaymentMethodsPage from './pages/workspaces/settings/billing/payment-methods'
import WorkspaceSettingsWebhooksPage from './pages/workspaces/settings/webhooks'
import WorkspaceSettingsAddWebhookPage from './pages/workspaces/settings/webhooks/add'
import WorkspaceSettingsEditWebhookPage from './pages/workspaces/settings/webhooks/edit'

import { Update } from 'history'
import history from './history'
import { NODE_ENV } from './config'

import ScrollToTop from './utils/scroll-to-top'

const BrowserRouter: FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(
    (_: Update, action: Update) => action,
    {
      action: history.action,
      location: history.location,
    }
  )

  React.useLayoutEffect(() => history.listen(dispatch), [history])

  return (
    <Router action={state.action} location={state.location} navigator={history}>
      <ScrollToTop />
      {children}
    </Router>
  )
}

export interface BrowserRouterProps {
  children?: React.ReactNode
  window?: Window
}

const AppRouter: FC = observer(() => {
  const [ready, setReady] = useState<boolean>(false)
  const [hasInternetConnection, setHasInternetConnection] = useState(true)

  const internetConnectionEventListener = () => {
    setHasInternetConnection(navigator.onLine)
  }

  useEffect(() => {
    window.addEventListener('load', internetConnectionEventListener)
    window.addEventListener('online', internetConnectionEventListener)
    window.addEventListener('offline', internetConnectionEventListener)

    return () => {
      window.removeEventListener('load', internetConnectionEventListener)
      window.removeEventListener('online', internetConnectionEventListener)
      window.removeEventListener('offline', internetConnectionEventListener)
    }
  }, [])

  useEffect(() => {
    if (!ready) {
      authentication.ready().then(() => {
        setReady(true)
      })
    }
  })

  if (!ready) {
    return null
  }

  const isAuthenticated = authentication && authentication.is_authenticated

  const Route: FC<
    RouteProps & {
      layout?: any
      component: any
      shielded?: boolean
      publicOnly?: boolean
      checkForConnection?: boolean
    }
  > = ({
    shielded,
    publicOnly,
    checkForConnection,
    layout: Layout,
    component: Component,
    ...routerProps
  }) => {
    let RenderComponent = Component
    if (checkForConnection && !hasInternetConnection) {
      RenderComponent = NoInternetConnection
    }

    const Element: FC = () => (
      <>
        {(((shielded && !isAuthenticated) ||
          (publicOnly && isAuthenticated)) && (
          <Navigate to="/" state={{ from: location.pathname }} />
        )) ||
          (Layout ? (
            <Layout>
              <RenderComponent />
            </Layout>
          ) : (
            <RenderComponent />
          ))}
      </>
    )

    return <RRoute {...routerProps} element={<Element />} />
  }

  return (
    <BrowserRouter>
      <Routes>
        {/* @ts-ignore */}
        {NODE_ENV === 'development' && (
          <>
            <Route
              layout={MainLayout}
              component={ComponentsCatalogPage}
              path="/dev/components"
            />
            <Route
              layout={MainLayout}
              component={ElementsCatalogPage}
              path="/dev/elements"
            />
          </>
        )}
        <Route
          publicOnly
          layout={AuthLayout}
          component={AuthSignInPage}
          path="/auth/sign-in"
        />
        <Route
          publicOnly
          layout={AuthLayout}
          component={AuthSignUpPage}
          path="/auth/sign-up"
        />
        <Route
          publicOnly
          layout={AuthLayout}
          component={AuthForgotPasswordPage}
          path="/auth/forgot-password"
        />
        <Route
          shielded
          layout={AuthLayout}
          component={EmailCodeVerification}
          path="/auth/sign-up/verify-email/:email_index"
        />
        <Route
          shielded
          layout={AuthLayout}
          component={SignUpValidation}
          path="/auth/sign-up/validation"
        />
        <Route
          shielded
          layout={AuthLayout}
          component={AuthSignUpInvitesManagement}
          path="/auth/invites"
        />
        <Route
          publicOnly
          layout={AuthLayout}
          component={AuthSignUpInviteExpired}
          path="/auth/sign-up/invite-expired"
        />
        <Route
          layout={isAuthenticated ? MainLayout : AuthLayout}
          component={AuthSignOutPage}
          path="/auth/sign-out"
        />

        <Route
          shielded
          checkForConnection
          layout={AccountPanelLayout}
          component={AccountPersonalInformation}
          path="/account/personal-information"
        />
        <Route
          shielded
          checkForConnection
          layout={AccountPanelLayout}
          component={AccountContacts}
          path="/account/contacts"
        />
        <Route
          shielded
          checkForConnection
          layout={AccountPanelLayout}
          component={AccountSecurity}
          path="/account/security"
        />
        <Route
          shielded
          checkForConnection
          layout={AccountPanelLayout}
          component={AccountNotifications}
          path="/account/notifications"
        />
        <Route
          shielded
          checkForConnection
          layout={AccountPanelLayout}
          component={AccountWorkspaces}
          path="/account/workspaces"
        />
        <Route
          shielded
          checkForConnection
          layout={AccountPanelLayout}
          component={AccountDangerZone}
          path="/account/dangerzone"
        />
        <RRoute
          path="/account"
          element={<Navigate to="/account/personal-information" />}
        />

        <Route
          shielded
          checkForConnection
          layout={AuthLayout}
          component={WorkspaceCreatePage}
          path="/workspaces/create"
        />
        <Route
          shielded
          checkForConnection
          layout={MainLayout}
          component={WorkspaceOverviewPage}
          path="/workspaces/:workspace_id/overview"
        />
        <Route
          shielded
          checkForConnection
          layout={WorkspacePanelLayout}
          component={WorkspaceSettingsDetailsPage}
          path="/workspaces/:workspace_id/settings/details"
        />
        <Route
          shielded
          checkForConnection
          layout={WorkspacePanelLayout}
          component={WorkspaceSettingsMembersPage}
          path="/workspaces/:workspace_id/settings/members"
        />
        <Route
          shielded
          checkForConnection
          layout={WorkspacePanelLayout}
          component={WorkspaceSettingsAddMemberPage}
          path="/workspaces/:workspace_id/settings/members/add"
        />
        <Route
          shielded
          checkForConnection
          layout={WorkspacePanelLayout}
          component={WorkspaceSettingsBillingPage}
          path="/workspaces/:workspace_id/settings/billing"
        />
        <Route
          shielded
          checkForConnection
          layout={WorkspacePanelLayout}
          component={WorkspaceSettingsPaymentMethodsPage}
          path="/workspaces/:workspace_id/settings/billing/payment-methods"
        />
        <Route
          shielded
          checkForConnection
          layout={WorkspacePanelLayout}
          component={WorkspaceSettingsWebhooksPage}
          path="/workspaces/:workspace_id/settings/webhooks"
        />
        <Route
          shielded
          checkForConnection
          layout={WorkspacePanelLayout}
          component={WorkspaceSettingsAddWebhookPage}
          path="/workspaces/:workspace_id/settings/webhooks/add"
        />
        <Route
          shielded
          checkForConnection
          layout={WorkspacePanelLayout}
          component={WorkspaceSettingsEditWebhookPage}
          path="/workspaces/:workspace_id/settings/webhooks/:webhook_id/edit"
        />
        <RRoute
          path="/workspaces/:workspace_id/settings"
          element={<Navigate to="/workspaces/:workspace_id/settings/details" />}
        />
        <Route
          shielded
          checkForConnection
          layout={MainLayout}
          component={ListPlayersPage}
          path="/players"
        />
        <Route
          shielded
          checkForConnection
          layout={MainLayout}
          component={BindPlayerPage}
          path="/players/bind"
        />
        <Route
          shielded
          checkForConnection
          layout={MainLayout}
          component={ViewPlayerPage}
          path="/players/:player_id"
        />

        <Route
          shielded
          checkForConnection
          layout={MainLayout}
          component={ListCampaignsPage}
          path="/campaigns"
        />
        <Route
          shielded
          checkForConnection
          layout={MainLayout}
          component={CreateCampaignPage}
          path="/campaigns/create"
        />
        <Route
          shielded
          checkForConnection
          layout={MainLayout}
          component={ViewCampaignPage}
          path="/campaigns/:campaign_id"
        />
        <Route
          shielded
          checkForConnection
          layout={MainLayout}
          component={CampaignAdGroupCreate}
          path="/campaigns/:campaign_id/ad-group/create"
        />
        <Route
          shielded
          checkForConnection
          layout={MainLayout}
          component={ViewCampaignAdGroupPage}
          path="/campaigns/:campaign_id/ad-group/:campaign_ad_group_id"
        />
        <Route
          shielded
          checkForConnection
          layout={MainLayout}
          component={ListAdCreativesPage}
          path="/creatives"
        />
        <Route
          shielded
          checkForConnection
          layout={MainLayout}
          component={ViewAdCreativePage}
          path="/creatives/:creative_id"
        />
        <Route
          shielded
          checkForConnection
          layout={MainLayout}
          component={ListNetworksPage}
          path="/networks"
        />
        <Route
          shielded
          checkForConnection
          layout={MainLayout}
          component={CreateNetworkPage}
          path="/networks/create"
        />
        <Route
          shielded
          checkForConnection
          layout={MainLayout}
          component={ViewNetworkPage}
          path="/networks/:network_id"
        />
        <RRoute
          path="*"
          element={
            <Navigate
              to={
                (authentication &&
                  authentication.is_authenticated &&
                  ((authentication.selected_workspace?._id &&
                    `/workspaces/${authentication.selected_workspace._id}/overview`) ||
                    '/workspaces/create')) ||
                '/auth/sign-in'
              }
            />
          }
        />
      </Routes>
    </BrowserRouter>
  )
})

export default AppRouter
