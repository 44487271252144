import React, { FC } from 'react'
import tw, { css } from 'twin.macro'

export interface FieldsRowProps {
  flex?: boolean
}

const FieldsRow: FC<FieldsRowProps> = ({ flex, children }) => (
  <div
    css={css`
      ${tw`mb-6`}
      ${flex && tw`flex flex-row items-center justify-between`}
      .billing-input + .billing-input {
        margin-left: 1rem;
      }
    `}
  >
    {children}
  </div>
)

FieldsRow.defaultProps = {
  flex: true,
}

export default FieldsRow
