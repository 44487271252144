import React, { Fragment, useState, useRef } from 'react'
import { IoMdAdd } from 'react-icons/io'
import _ from 'lodash'
import {
  EnumPlayerStatus,
  EnumNetworkStatus,
  EnumCreativeStatus,
  EnumCampaignStatus,
  EnumCampaignAdGroupStatus,
  EnumWorkspaceMembersRole,
  EnumPlayerSettingsScreen_Orientation,
} from '../../../graphql/components'

import { ReactComponent as CampaignPlayerEmptyState } from '../../../assets/illustrations/campaign_1_display_empty_sate_1.svg'
import { ReactComponent as CampaignCreativeEmptyState } from '../../../assets/illustrations/campaign_1_creative_empty_sate_1.svg'
import Button from '../../../ui-blocks/button'
import Input from '../../../ui-blocks/input'
import Search from '../../../ui-blocks/search'
import { DatePicker, DateRangePicker } from '../../../ui-blocks/date-picker'
// import { HourPicker, HourRangePicker } from '../../../ui-blocks/hour-picker'
import Dropdown from '../../../ui-blocks/dropdown'
import Radio from '../../../ui-blocks/radio'
import Checkbox from '../../../ui-blocks/checkbox'
import Switch from '../../../ui-blocks/switch'
import { Tabs, TabPane } from '../../../ui-blocks/tabs'
import ViewControls from '../../../ui-blocks/view-controls'
import ModalAction from '../../../ui-blocks/modal-action'
import CardResource from '../../../ui-blocks/card-resource'
import NetworkSelectCard from '../../../components/network/select-card'
import PlayerSelectCard from '../../../components/player/select-card'
import PlayerCard from '../../../components/player/card'
import Status from '../../../ui-blocks/status'
import Pill from '../../../ui-blocks/pill'
import Tag from '../../../ui-blocks/tag'
import AlertMessage, { AlertVariant } from '../../../components/alert-component'
import Divider from '../../../ui-blocks/divider'
import CreativeItem from '../../../components/creative/item'
import {
  Informations,
  InformationsSection,
} from '../../../ui-blocks/information'
import KPICard from '../../../ui-blocks/kpi-card'
import PlayersPending from '../../../ui-blocks/players-pending'
import Avatar from '../../../ui-blocks/avatar'
import SetupCard from '../../../ui-blocks/setup-card'
import CodeInput from '../../../ui-blocks/code-input'
import CardResourceRadio from '../../../ui-blocks/card-resource-radio'
import Member from '../../../ui-blocks/member'
import EditNetworkInfoModal from '../../networks/details-modal'
import ManageNetworkPlayersModal from '../../networks/details-modal'
import Map from '../../../ui-blocks/map'
import { PlayersListTable } from '../../../components/player/table'
import { Section, SectionTitle } from './components/sections'
import Container from '../../../ui-blocks/container'
import InlineDate from '../../../components/inline-date'
import EndTrialModal from '../../../components/end-trial-modal'

import { FaRegMap } from 'react-icons/fa'
import { MdViewComfy } from 'react-icons/md'
import { FiAlignJustify } from 'react-icons/fi'
import { DateTimeRangePicker } from '../../../ui-blocks/datetime-picker'

const dummySearch = [
  'Sleep today',
  'Sleeping problems 40',
  'Sleeping problems 42',
  'bacon',
]
const dummyOptions = [
  {
    label: 'Option 1',
    value: 'Option 1',
  },
  {
    label: 'Option 2',
    value: 'Option 2',
  },
  {
    label: 'Option 3',
    value: 'Option 3',
  },
  {
    label: 'Option 4',
    value: 'Option 4',
  },
  {
    label: 'Bacon',
    value: 'Option 5',
  },
]

export default function ComponentsCatalogPage() {
  const [disabled, setDisabled] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  const inputRef = useRef<HTMLInputElement>(null)

  // const [cardResourceSelected, setCardResourceSelected] = useState(false)

  const [modalOpen, setModalOpen] = useState(false)
  const [infoModalopen, setInfoModalOpen] = useState(false)
  const [playersModalopen, setPlayersModalOpen] = useState(false)
  const [endTrialModalOpen, setEndTrialModalOpen] = useState(false)

  const [creativeItemName, setCreativeItemName] = useState(
    "Dr. Murphy's Recipe"
  )
  const creativeSelectRef = useRef<HTMLInputElement>(null)

  const [points, setPoints] = useState<any[]>([])

  const allEnumStatus = [
    EnumPlayerStatus,
    EnumNetworkStatus,
    EnumCreativeStatus,
    EnumCampaignStatus,
    EnumCampaignAdGroupStatus,
  ]

  let allStatuses = allEnumStatus.reduce<string[]>(
    (acc, value) => [...acc, ...Object.values(value)],
    []
  )
  allStatuses = _.uniq(allStatuses)

  return (
    <Container>
      <div style={{ padding: '2em', overflow: 'auto' }}>
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          {[
            { name: 'disabled', prop: disabled, change: setDisabled },
            { name: 'loading', prop: loading, change: setLoading },
            { name: 'success', prop: success, change: setSuccess },
            { name: 'error', prop: error, change: setError },
          ].map(({ name, prop, change }, i) => (
            <Fragment key={i}>
              <input
                type="checkbox"
                name={name}
                checked={prop}
                onChange={() => change(!prop)}
              />
              <label style={{ margin: '0px 24px 0px 4px' }} htmlFor={name}>
                {name}
              </label>
            </Fragment>
          ))}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            marginTop: 24,
            flexWrap: 'wrap',
          }}
        >
          <Button
            primary
            disabled={disabled}
            loading={loading}
            onClick={() => {
              if (inputRef.current) {
                // console.log('text: ', inputRef.current.value);
              }
            }}
          >
            Button
          </Button>
          <Button secondary disabled={disabled} loading={loading}>
            Button
          </Button>
          <Button
            menu
            disabled={disabled}
            onClick={() => {
              if (creativeSelectRef.current) {
                // console.log('creative:', creativeSelectRef.current.checked);
              }
            }}
          >
            Menu Link
          </Button>
          <Button tab disabled={disabled}>
            All
          </Button>
          <Button
            tag="a"
            ghost
            disabled={disabled}
            href="/components"
            onClick={(ev: any) => ev.preventDefault()}
          >
            Link
          </Button>
          <Button secondary ghost disabled={disabled}>
            Link
          </Button>
          <Button
            iconLeft={IoMdAdd}
            primary
            disabled={disabled}
            loading={loading}
          >
            Button
          </Button>
        </div>
        <div style={{ marginTop: 24 }}>
          <Input
            inputRef={inputRef}
            id="myinput"
            placeholder="Type something here!"
            disabled={disabled}
            success={success}
            error={error ? 'Oo0ps' : undefined}
          />
        </div>
        <div style={{ marginTop: 24 }}>
          <Search
            disabled={disabled}
            loading={loading}
            entries={dummySearch}
            onSelect={() => {
              // console.log(value)
            }}
          />
        </div>
        <div style={{ marginTop: 24 }}>
          <Search
            filter
            disabled={disabled}
            loading={loading}
            entries={dummySearch}
            initialFilters={['Sleep today']}
            onSelect={() => {
              // console.log(filters)
            }}
          />
        </div>
        <div
          style={{
            marginTop: 24,
            display: 'flex',
            justifyContent: 'space-evenly',
            flexWrap: 'wrap',
          }}
        >
          <DatePicker success={success} disabled={disabled} />
          <DateRangePicker success={success} disabled={disabled} />
          {/* <div style={{ width: '15rem' }}>
            <HourPicker
              success={success}
              error={error ? 'Oo0ps' : undefined}
              disabled={disabled}
            />
          </div>
          <div style={{ width: '15rem' }}>
            <HourRangePicker
              success={success}
              error={error ? 'Oo0ps' : undefined}
            />
          </div> */}
          <div>
            <DateTimeRangePicker
              success={success}
              error={error ? 'Oo0ps' : undefined}
            />
          </div>
          <div style={{ width: '15rem' }}>
            <Dropdown
              success={success}
              isDisabled={disabled}
              isLoading={loading}
              options={dummyOptions}
              error={error ? 'Oo0ps' : undefined}
            />
          </div>
          <div style={{ width: '15rem' }}>
            <Dropdown
              ghost
              success={success}
              isDisabled={disabled}
              isLoading={loading}
              options={dummyOptions}
              error={error ? 'Oo0ps' : undefined}
            />
          </div>
        </div>
        <div
          style={{
            marginTop: 24,
            display: 'flex',
            justifyContent: 'space-evenly',
            flexWrap: 'wrap',
            alignItems: 'center',
          }}
        >
          <div>
            <Radio disabled={disabled} label="AM" radioGroup="radio" />
            <Radio disabled={disabled} label="FM" radioGroup="radio" />
          </div>
          <div>
            <Checkbox disabled={disabled} name="eyeD" label="beep" />
            <Checkbox disabled={disabled} name="heidi" label="boop" />
          </div>
          <Switch
            disabled={disabled}
            label="Sweet'ch"
            onSelect={() => {
              // console.log('switch ', checked)
            }}
          />
          <Button
            disabled={disabled}
            loading={loading}
            onClick={() => setModalOpen(true)}
          >
            Modal
          </Button>
          <Button
            disabled={disabled}
            loading={loading}
            onClick={() => setEndTrialModalOpen(true)}
          >
            End of trial modal
          </Button>
          <ModalAction
            open={modalOpen}
            title="Modal"
            content="Bacon ipsum dolor amet frankfurter drumstick shank, pork chop pork prosciutto ham. Drumstick beef ribs pork chop, short ribs bacon tri-tip pork loin pork belly hamburger."
            onCancel={() => {
              setModalOpen(false)
            }}
            onConfirm={() => {
              setModalOpen(false)
            }}
          />
          <Button
            disabled={disabled}
            loading={loading}
            onClick={() => setInfoModalOpen(true)}
          >
            Network Info Modal
          </Button>
          <EditNetworkInfoModal
            open={infoModalopen}
            onConfirm={() => setInfoModalOpen(false)}
            onCancel={() => setInfoModalOpen(false)}
          />
          <Button
            disabled={disabled}
            loading={loading}
            onClick={() => setPlayersModalOpen(true)}
          >
            Network Players Modal
          </Button>
          <ManageNetworkPlayersModal
            open={playersModalopen}
            onConfirm={() => setPlayersModalOpen(false)}
            onCancel={() => setPlayersModalOpen(false)}
          />
          <EndTrialModal
            open={endTrialModalOpen}
            onClose={() => setEndTrialModalOpen(false)}
          />
          <div>
            <ViewControls
              disabled={disabled}
              options={[
                { id: 'list-view', label: 'List View', icon: FiAlignJustify },
                { id: 'map-view', label: 'Map View', icon: FaRegMap },
              ]}
            />
            <div style={{ marginTop: 24 }}>
              <ViewControls
                disabled={disabled}
                options={[
                  { id: 'card-view', label: 'Card View', icon: MdViewComfy },
                  { id: 'list-view', label: 'List View', icon: FiAlignJustify },
                ]}
              />
            </div>
            <div style={{ marginTop: 24 }}>
              <ViewControls
                disabled={disabled}
                options={[
                  { id: 'first-option', label: 'First option' },
                  // { id: 'second-option', label: 'Second option' },
                  // { id: 'third-option', label: 'Third option' },
                ]}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: 24,
            display: 'flex',
            justifyContent: 'space-evenly',
            flexWrap: 'wrap',
          }}
        >
          <NetworkSelectCard
            value="network_id"
            network={{
              name: 'AH business',
              players: [],
            }}
          />
          <PlayerSelectCard
            disabled={disabled}
            player={{
              _id: 'dummy',
              name: 'AH Business',
              geographics: {
                address: {
                  street: 'Rua D. João I',
                  number: 'nº256',
                  city: 'Matosinhos',
                  zip: '4450-162',
                },
              },
            }}
            value="player_id"
          />
          <PlayerCard
            preselected
            disabled={disabled}
            player={{
              _id: 'dummy',
              name: 'AH Business',
              geographics: {
                address: {
                  street: 'Rua D. João I',
                  number: 'nº256',
                  city: 'Matosinhos',
                  zip: '4450-162',
                },
              },
              demographics: {
                venue_type_ids: ['Business', 'Office', 'Professional'],
              },
            }}
          />
          {/* <PlayerCard
            pending
            disabled={disabled}
            player={{
              _id: 'dummy',
              created: { at: new Date(2019, 3, 27, 0, 0) },
            }}
          /> */}
        </div>

        <CardResource
          disabled={disabled}
          // selected={cardResourceSelected}
          // setSelected={setCardResourceSelected}
          title="Advertiser"
          description="Agencies, Brands, Media Buyers"
        />
      </div>
      <div
        style={{
          marginTop: 24,
          display: 'flex',
          justifyContent: 'space-evenly',
          flexWrap: 'wrap',
        }}
      >
        {allStatuses.map((v) => (
          <Status key={v} value={v as any}>
            popover content
          </Status>
        ))}
      </div>
      <div
        style={{
          marginTop: 24,
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <Pill
          disabled={disabled}
          text="patricklee@email.com"
          onDelete={() => {
            // console.log('pill delete')
          }}
        />
        <Tag text="office" />
        <div>
          <AlertMessage
            alert={{
              id: 'success-alert',
              variant: AlertVariant.SUCCESS,
              message: 'Success alert message.',
            }}
          />
          <div style={{ marginTop: 12 }}>
            <AlertMessage
              alert={{
                id: 'warning-alert',
                variant: AlertVariant.WARNING,
                message: 'Warning alert message.',
              }}
            />
          </div>
          <div style={{ marginTop: 12 }}>
            <AlertMessage
              alert={{
                id: 'error-alert',
                variant: AlertVariant.ERROR,
                message: 'Error alert message.',
              }}
            />
          </div>
          <div style={{ marginTop: 12 }}>
            <AlertMessage
              alert={{
                id: 'error-alert-with-action',
                variant: AlertVariant.ERROR,
                actionText: 'Click here for action!',
                message: 'Error alert message with action.',
                onAction: () =>
                  alert('some action triggered by an alert message'),
              }}
            />
          </div>
        </div>
      </div>
      <Section>
        <PlayersListTable
          data={[
            {
              _id: 'tst',
              name: 'AH Business - Entrance',
              status: EnumPlayerStatus.Activated,
              geographics: {
                address: {
                  street: 'Rua D. João I',
                  number: 'nº256',
                  city: 'Matosinhos',
                  zip: '4450-162',
                },
              },
              demographics: {
                country_id: 'Portugal',
                area_type_id: 'Indoor',
                venue_type_ids: ['Business', 'Office', 'Professional'],
              },
              settings: {
                screen_orientation:
                  EnumPlayerSettingsScreen_Orientation.Landscape,
              },
              telemetry: {
                scans: 187,
                impressions: 873800,
              },
              created: {
                at: new Date(2019, 3, 27, 20, 0),
              },
            },
          ]}
        />
      </Section>
      <Section>
        <SectionTitle>Divider</SectionTitle>

        <Divider text="2018" />
      </Section>
      <Section>
        <SectionTitle>CreativeItem</SectionTitle>

        <CreativeItem
          id="eyeD"
          edit
          thumbnail="https://picsum.photos/300/200"
          name={creativeItemName}
          impressions={12498093}
          scans={754}
          orientation="landscape"
          duration="47s"
          upload={new Date(0, 9, 21, 22, 30)}
          onSave={(name) => setCreativeItemName(name)}
        />

        <CreativeItem
          id="sel-ect"
          selection
          thumbnail="https://picsum.photos/300/200"
          name="Dr. Murphy's Recipe"
          impressions={12498093}
          scans={754}
          orientation="landscape"
          duration="47s"
          upload={new Date(0, 9, 21, 22, 30)}
          selectionRef={creativeSelectRef}
        />
      </Section>
      <Section>
        <SectionTitle>Information</SectionTitle>

        <Informations
          title="CAMPAIGN INFORMATION"
          onEdit={() => setModalOpen(true)}
        >
          <InformationsSection label="STATUS">
            <Status value={EnumPlayerStatus.Activated} />
          </InformationsSection>
          <InformationsSection label="AD GROUPS">1</InformationsSection>
          <InformationsSection label="START">
            <InlineDate date={new Date(0, 10, 1, 7, 0)} />
          </InformationsSection>
          <InformationsSection label="END">
            <InlineDate date={new Date(0, 11, 24, 20, 0)} />
          </InformationsSection>
          <InformationsSection label="CREATED">
            <InlineDate date={new Date(2019, 9, 30, 20, 0)} />
          </InformationsSection>
        </Informations>
      </Section>
      <Section>
        <SectionTitle>KPICard</SectionTitle>
        <KPICard
          title="Campaign Information"
          intervals={[
            {
              title: 'Total',
              reach: Math.round(Math.random() * 500000),
              engagement: Math.round(Math.random() * 500000),
              starts_at: new Date(),
              ends_at: new Date(),
            },
            {
              title: 'This week',
              reach: Math.round(Math.random() * 500000),
              engagement: Math.round(Math.random() * 500000),
              starts_at: new Date(),
              ends_at: new Date(),
            },
            {
              title: 'Last week',
              reach: Math.round(Math.random() * 500000),
              engagement: Math.round(Math.random() * 500000),
              starts_at: new Date(),
              ends_at: new Date(),
            },
            {
              title: 'Two weeks',
              reach: Math.round(Math.random() * 500000),
              engagement: Math.round(Math.random() * 500000),
              starts_at: new Date(),
              ends_at: new Date(),
            },
          ]}
        />
      </Section>
      <Section>
        <SectionTitle>PlayersPending</SectionTitle>

        <PlayersPending
          cards={
            [
              // {
              //   player: {
              //     _id: 'dummy1',
              //     created: { at: new Date(2019, 3, 27, 0, 0) },
              //   },
              // },
              // {
              //   player: {
              //     _id: 'dummy2',
              //     created: { at: new Date(2019, 3, 27, 0, 0) },
              //   },
              // },
            ]
          }
        />
      </Section>
      <Section>
        <SectionTitle>Avatar</SectionTitle>

        <Avatar initials="AB" />
        <Avatar add />
        <Avatar src="https://picsum.photos/100/200" initials="ZB" />
        <Avatar variant="profile" initials="AB" size="4rem" largeFont />
        <Avatar initials="AB" size="4rem" largeFont />
      </Section>
      <Section>
        <SectionTitle>SetupCard</SectionTitle>

        <SetupCard
          Image={CampaignPlayerEmptyState}
          title="Set up your diffuse box"
          description="Campaigns need a channel to play. Set your Player to get action."
          buttonText="Bind a Player"
          onButtonClick={() => {
            // console.log('Setup card Player bind')
          }}
          disabled={disabled}
        />
        <SetupCard
          Image={CampaignCreativeEmptyState}
          title="Feed us with your amazing content"
          description="Your first step to get ready to create a campaign."
          buttonText="Upload creatives"
          onButtonClick={() => {
            // console.log('Setup card upload creatives')
          }}
          disabled={disabled}
        />
      </Section>
      <Section>
        <SectionTitle>CodeInput</SectionTitle>

        <CodeInput
          onChange={() => {
            // console.log('valid code: ', code);
          }}
        />
      </Section>
      <Section>
        <SectionTitle>CardResourceRadio</SectionTitle>

        <CardResourceRadio
          onChange={() => {
            // console.log(
            //   'card selected: ',
            //   selectedIndex === 0 ? 'Indoor' : 'Outdoor'
            // )
          }}
          cards={[
            {
              disabled,
              name: 'Indoor',
              description: `Explore different locations`,
            },
            {
              disabled,
              name: 'Outdoor',
              description: `Streets and Billboards`,
            },
          ]}
        />
      </Section>
      <Section>
        <SectionTitle>Member</SectionTitle>

        <Member
          avatarSrc="https://picsum.photos/100/200"
          initials="AB"
          name="Ana Brown"
          email="anabrown@email.com"
          accessLevel={EnumWorkspaceMembersRole.Administrator}
          onChangeLevel={() => {
            // console.log('next level! ', lvl)
          }}
          onRemove={() => {
            // console.log('remove member')
          }}
        />
      </Section>
      <Section>
        <SectionTitle>Map</SectionTitle>

        <Map
          points={[
            {
              name: '1',
              lat: 37.69,
              lng: -122.61,
            },
            {
              name: '2',
              lat: 37.89,
              lng: -122.71,
            },
            {
              name: '3',
              lat: 37.89,
              lng: -122.21,
            },
            ...points,
          ]}
          onSetPoint={(point) =>
            setPoints((old) => [...old, { lat: point.lat, lng: point.lng }])
          }
        />
      </Section>

      <Section>
        <SectionTitle>Tabs</SectionTitle>
        <Tabs>
          <TabPane key="a" tab="Testing A">
            testing A
          </TabPane>
          <TabPane key="b" tab="Testing B">
            testing B
          </TabPane>
          <TabPane key="c" tab="Testing C">
            testing C
          </TabPane>
          <TabPane key="d" tab="Testing D">
            testing D
          </TabPane>
        </Tabs>

        <Tabs
          tabBarExtraContent={(props) => (
            <>{props.activeKey === 'players' && 'Players mofoca'}</>
          )}
        >
          <TabPane key="players" tab="Players (0)">
            players table here
          </TabPane>
          <TabPane key="networks" tab="Networks (0)">
            networks table here
          </TabPane>
        </Tabs>
      </Section>
    </Container>
  )
}
