import { ReactNode } from 'react'
import { action, observable } from 'mobx'

export enum AlertVariant {
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success',
}

export interface Alert {
  id: string
  message: string | ReactNode
  timeout?: number
  _timeoutId?: NodeJS.Timeout
  actionText?: string
  dismissable?: boolean
  variant?: AlertVariant
  onAction?: () => void
  onDismiss?: () => void
}

export default class AlertsManager {
  @observable public alerts: Alert[] = []

  @action public emit(alert: Alert) {
    if (alert.timeout === undefined) {
      alert.timeout = 3000
    }

    const alertIndex = this.ensureAlert(alert)
    this.resetTimerByIndex(alertIndex)
  }

  @action ensureAlert(alert: Alert) {
    const alertIndex = this.alerts.findIndex(({ id }) => id === alert.id)
    if (alertIndex !== -1) {
      return alertIndex
    }

    return this.alerts.push(alert) - 1
  }

  @action resetTimerByIndex(alertIndex: number) {
    const alert = this.findAlertByIndex(alertIndex)

    if (alert._timeoutId) {
      clearTimeout(alert._timeoutId)
    }

    if (alert.dismissable && alert.timeout) {
      alert._timeoutId = setTimeout(
        () => this.dismissByIndex(alertIndex),
        alert.timeout
      )
    }
  }

  @action async dismissByIndex(alertIndex: number) {
    const alert = this.findAlertByIndex(alertIndex)

    if (typeof alert.onDismiss == 'function') {
      await alert.onDismiss()
    }

    this.alerts.splice(alertIndex, 1)
  }

  findAlertByIndex(alertIndex: number) {
    const alert = this.alerts[alertIndex]

    if (!alert) {
      throw new Error('alert not found')
    }

    return alert
  }
}

export const alertsManager = new AlertsManager()
