import React, { FC } from 'react'

import { css } from 'twin.macro'

import Logo from '../ui-blocks/logo'
import { useRandomCover } from '../assets/covers'
import { IoMdPerson, IoMdPin } from 'react-icons/io'

const AuthLayout: FC = ({ children }) => {
  const { loaded, cover } = useRandomCover()
  console.log(cover)
  return (
    <div tw="flex bg-white h-full min-h-screen">
      <div tw="w-2/4 flex justify-center">
        <div tw="w-full max-w-3/4 p-6">
          <div tw="inline-block self-start mb-20">
            <Logo />
          </div>
          <div tw="max-w-md">{children}</div>
        </div>
      </div>
      <div
        title="Diffuse.tv"
        tw="relative w-2/4 bg-cover bg-center bg-no-repeat bg-purple"
      >
        <div
          css={css`
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
            min-height: 100vh;
            transition: opacity 1s linear;
            opacity: ${loaded ? 0.7 : 0};
            background-size: cover;
            background-position: center;
            ${loaded && `background-image: url(${cover.photoUrl})`};
          `}
        />
      </div>
      <div
        tw="flex flex-col justify-end items-end "
        css={css`
          position: absolute;
          right: 1rem;
          bottom: 1rem;
          z-index: 2;
        `}
      >
        <a
          href={cover.authorLink}
          target="_blank"
          rel="noopener noreferrer"
          tw="block text-platinum"
        >
          {cover.authorName} <IoMdPerson tw="inline-block" />
        </a>
        <span tw="block text-light-peri">
          {cover.locationName} <IoMdPin tw="inline-block" />
        </span>
      </div>
    </div>
  )
}

export default AuthLayout
