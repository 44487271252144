import React, { FC } from 'react'
import tw, { css } from 'twin.macro'
import Avatar from '../../ui-blocks/avatar'

const UserInfo: FC<{
  initials?: string
  name?: string
  email?: string
}> = ({ initials, name, email }) => (
  <div
    css={css`
      ${tw`w-full flex items-center p-2`}
    `}
  >
    <div
      css={css`
        ${tw`flex-none mr-2`}
      `}
    >
      <Avatar initials={initials} variant="profile" size="64px" largeFont />
    </div>
    <div
      css={css`
        ${tw`w-full flex-auto`}
      `}
    >
      <p
        css={css`
          ${tw`text-charcoal text-sm`}
        `}
      >
        {name}
      </p>
      <p
        css={css`
          ${tw`text-sm text-charcoal`}
        `}
      >
        {email}
      </p>
    </div>
  </div>
)

export default UserInfo
