import React, { FC } from 'react'

import tw, { css } from 'twin.macro'

import Card from '../../../ui-blocks/card'
import ButtonLink from '../../../ui-blocks/button-link'
import { AccordionStep } from '../../../ui-blocks/accordion'
// import Button from '../../../ui-blocks/button'

import { authentication } from '../../../stores'
import { useCountQuery } from '../../../graphql/components'

import { ReactComponent as Illustration } from '../../../assets/illustrations/overview_desk.svg'

export const useOnboardingPage = (count: ReturnType<typeof useCountQuery>) => {
  const { creatives, networks, players, campaigns } = count.data || {}

  return {
    renderOnboardingPage:
      (creatives || 0) === 0 ||
      (networks || 0) === 0 ||
      (players || 0) === 0 ||
      (campaigns || 0) === 0,
  }
}

const WorkspaceOnboardingPage: FC<{
  count: ReturnType<typeof useCountQuery>
}> = ({ count }) => {
  return (
    <div
      className="container"
      css={css`
        ${tw`mt-16 flex justify-center`}
      `}
    >
      <div
        className="content"
        css={css`
          ${tw`mr-12 w-6/12`}
        `}
      >
        <h1
          css={css`
            ${tw`text-4xl font-light mb-4`}
          `}
        >
          Hi {authentication.user_data?.name?.first}! 👋
        </h1>
        <p
          css={css`
            ${tw`mb-16`}
          `}
        >
          Welcome to Diffuse.tv! This is your workspace overview, the place
          where you can find some general stats and recomendations.
        </p>
        <p
          css={css`
            ${tw`mb-2 font-medium`}
          `}
        >
          Give us content, we give you stats!
        </p>
        <p>
          We need you to set up your workspace to get full advantage from
          Diffuse.tv.
        </p>

        <div
          css={css`
            ${tw`flex justify-center my-16`}
          `}
        >
          <Illustration
            css={css`
              ${tw`w-6/12`}
            `}
          />
        </div>
      </div>

      <div
        className="checklist"
        css={css`
          ${tw`w-3/12`}
        `}
      >
        <Card
          css={css`
            ${tw`p-8 w-full`}
          `}
        >
          <h3
            css={css`
              ${tw`text-xl font-medium`}
            `}
          >
            Set up your workspace
          </h3>

          <div
            css={css`
              ${tw`mt-8`}
            `}
          >
            <AccordionStep
              includeHR
              title="Bind your players"
              done={(count.data?.players || 0) > 0}
              expandable={(count.data?.players || 0) === 0}
            >
              <p>
                Campaigns need target channels to play content. Connect your
                displays and set your first player to get to action.
              </p>
              <ButtonLink
                secondary
                to="/players/bind"
                css={css`
                  ${tw`mt-4 w-full`}
                `}
              >
                Add player
              </ButtonLink>
            </AccordionStep>
            <AccordionStep
              includeHR
              title="Build a network"
              done={(count.data?.networks || 0) > 0}
              expandable={(count.data?.networks || 0) === 0}
            >
              <p>
                Networks allows you to group players into target groups so it
                gets easier to setup multiple campaigns to the same set of
                players. Create your first network.
              </p>
              <ButtonLink
                secondary
                to="/networks/create"
                css={css`
                  ${tw`mt-4 w-full`}
                `}
              >
                Create a network
              </ButtonLink>
            </AccordionStep>
            <AccordionStep
              includeHR
              title="Upload your creatives"
              done={(count.data?.creatives || 0) > 0}
              expandable={(count.data?.creatives || 0) === 0}
            >
              <p>
                Campaigns need a set of creative content so it can schedule its
                playback to the target networks and players. Upload some
                creatives.
              </p>
              <ButtonLink
                secondary
                to="/creatives"
                css={css`
                  ${tw`mt-4 w-full`}
                `}
              >
                Upload creatives
              </ButtonLink>
            </AccordionStep>
            <AccordionStep
              title="Create your first campaign"
              done={(count.data?.campaigns || 0) > 0}
              expandable={(count.data?.campaigns || 0) === 0}
            >
              <p>
                Campaigns allows you to decide where a set of content is going
                to get played at. Create your first campaign.
              </p>
              <ButtonLink
                secondary
                to="/campaigns/create"
                css={css`
                  ${tw`mt-4 w-full`}
                `}
              >
                Create Campaign
              </ButtonLink>
            </AccordionStep>
          </div>

          {/* <Button ghost>Dismiss checklist</Button> */}
        </Card>
      </div>
    </div>
  )
}

export default WorkspaceOnboardingPage
