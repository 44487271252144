import React, { FC } from 'react'

import tw, { css } from 'twin.macro'
import { NavLink, useParams } from 'react-router-dom'

import MainLayout from './main'
import Card from '../ui-blocks/card'
import Container from '../ui-blocks/container'

const WorkspacePanelLayout: FC = ({ children }) => {
  const params = useParams()
  const workspace_id = params?.workspace_id || ''

  return (
    <MainLayout>
      <Container>
        <div>
          <Card
            css={css`
              ${tw`text-charcoal flex`}
            `}
          >
            <div
              css={css`
                ${tw`bg-lighter-blue p-8 flex flex-col rounded-tl rounded-bl`}
                width: 19rem;

                a.active {
                  ${tw`text-purple`}
                }
              `}
            >
              <span
                css={css`
                  ${tw`py-2 text-xs`}
                `}
              >
                WORKSPACE SETTINGS
              </span>
              <span
                css={css`
                  ${tw`py-2 text-base`}
                `}
              >
                <NavLink to={`/workspaces/${workspace_id}/settings/details`}>
                  Details
                </NavLink>
              </span>

              <span
                css={css`
                  ${tw`py-2 text-base`}
                `}
              >
                <NavLink to={`/workspaces/${workspace_id}/settings/members`}>
                  Members
                </NavLink>
              </span>

              <span
                css={css`
                  ${tw`py-2 text-base`}
                `}
              >
                <NavLink to={`/workspaces/${workspace_id}/settings/billing`}>
                  Billing & Plans
                </NavLink>
              </span>

              <span
                css={css`
                  ${tw`py-2 text-base`}
                `}
              >
                <NavLink to={`/workspaces/${workspace_id}/settings/webhooks`}>
                  Webhooks
                </NavLink>
              </span>
            </div>

            <div
              css={css`
                ${tw`flex-auto p-8 flex flex-col`}
              `}
            >
              {children}
            </div>
          </Card>
        </div>
      </Container>
    </MainLayout>
  )
}

export default WorkspacePanelLayout
