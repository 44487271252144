import React, { FC, useState } from 'react'
import 'twin.macro'

import delay from 'delay'
import { string, object } from 'yup'
import { useFormik, FormikHelpers } from 'formik'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import Button from '../../../ui-blocks/button'
import { InputFormik } from '../../../ui-blocks/input'

import AlertMessage, { AlertVariant } from '../../../components/alert-component'

import history from '../../../history'
import { authentication } from '../../../stores'

import { trimValues } from '../../../utils/data-manipulation'

interface SignUpFormikValues {
  firstname: string
  lastname: string
  email: string
  password: string
}

const signUpValidationSchema = object().shape({
  firstname: string()
    .trim('Value cannot have leading or trailing white spaces')
    .required('First name is required')
    .strict(true),
  lastname: string()
    .trim('Value cannot have leading or trailing white spaces')
    .required('Last name is required')
    .strict(true),
  email: string()
    .trim('Value cannot have leading or trailing white spaces')
    .email('Must be a valid email address')
    .required('Email is required')
    .strict(true),
  password: string().required('Password is required'),
})

const onSubmit = async (
  values: SignUpFormikValues,
  formikHelpers: FormikHelpers<SignUpFormikValues>,
  setSignUpError: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const { firstname, lastname, email, password } = trimValues(values, [
    'firstname',
    'lastname',
    'email',
  ])
  setSignUpError(false)
  try {
    await authentication.signup(firstname, lastname, email, password)
    await delay(1)
    history.push('/auth/sign-up/verify-email/0')
  } catch (err) {
    setSignUpError(true)
  }
}

const AuthSignInPage: FC = () => {
  const navigate = useNavigate()

  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)

  const [signUpError, setSignUpError] = useState<boolean>(false)
  const formik = useFormik<SignUpFormikValues>({
    validateOnChange: false,
    validationSchema: signUpValidationSchema,
    initialValues: {
      firstname: '',
      lastname: '',
      password: '',
      email: queryParams.get('email') || '',
    },
    onSubmit: (values, helpers) => onSubmit(values, helpers, setSignUpError),
  })

  return (
    <form
      tw="relative"
      onSubmit={formik.handleSubmit}
      onChange={formik.handleChange}
    >
      <h1 tw="text-charcoal m-0 mb-10 font-light leading-tight text-4xl self-start">
        Sign up
      </h1>

      {signUpError && (
        <div tw="mb-8">
          <AlertMessage
            alert={{
              id: 'sign-up-error-alert',
              variant: AlertVariant.ERROR,
              actionText:
                'If you think that might be the case, click here to recover access to it.',
              message:
                'It seems something went wrong with the registration process. This could happen in a couple of scenarios, your information might be incorrect or the system already contains an account with the same email.',
              onAction: () => navigate('/auth/forgot-password'),
            }}
          />
        </div>
      )}

      <div tw="mb-8">
        <InputFormik
          type="text"
          name="firstname"
          label="First Name"
          placeholder="John"
          formik={formik}
        />
      </div>
      <div tw="mb-8">
        <InputFormik
          type="text"
          name="lastname"
          label="Last Name"
          placeholder="Doe"
          formik={formik}
        />
      </div>
      <div tw="mb-8">
        <InputFormik
          type="email"
          name="email"
          label="Email Address"
          placeholder="your-email@domain.eu"
          formik={formik}
        />
      </div>
      <div tw="mb-8">
        <InputFormik
          type="password"
          name="password"
          label="Password"
          placeholder="*************"
          formik={formik}
        />
      </div>
      <div tw="mt-12">
        <Button type="submit" loading={formik.isSubmitting}>
          Create account
        </Button>
      </div>

      <p tw="inline-block align-baseline text-sm mt-8 text-charcoal">
        Have an account?{' '}
        <Link tw="text-purple hover:text-spanish-violet" to="/auth/sign-in">
          Sign in
        </Link>
      </p>
    </form>
  )
}

export default AuthSignInPage
