import React, { FC } from 'react'
import tw from 'twin.macro'

import Card from './card'
import Text from './text'

import { FiEdit2 } from 'react-icons/fi'

interface HeaderProps {
  title: string
  disabled?: boolean
  onEdit?: () => void
}

export const Informations: FC<HeaderProps> = ({
  title,
  disabled,
  onEdit,
  children,
}) => (
  <Card>
    <div tw="p-8 w-full text-charcoal tracking-wide">
      <div tw="w-full flex justify-between mb-8">
        <Text
          as="h3"
          preset="p1"
          transform="uppercase"
          tw="text-dark-blue-gray tracking-wider"
        >
          {title}
        </Text>
        {!!onEdit && (
          <button
            onClick={onEdit}
            disabled={disabled}
            css={[
              tw`text-charcoal flex items-center justify-end focus:outline-none w-16`,
              !disabled && tw`hover:text-purple`,
            ]}
          >
            <FiEdit2 tw="mr-1" />
            Edit
          </button>
        )}
      </div>
      <div tw="w-full flex flex-row">{children}</div>
    </div>
  </Card>
)

export const InformationsSection: FC<{ label?: string }> = ({
  children,
  label,
  ...props
}) => (
  <section tw="flex flex-col flex-grow" {...props}>
    <label tw="font-medium text-xs text-dark-blue-gray mb-2">{label}</label>
    {children}
  </section>
)
