import React, { FC, useEffect, useState } from 'react'

import 'twin.macro'
import { useLocation } from 'react-router-dom'

import Search from '../../../ui-blocks/search'
import Spacer from '../../../ui-blocks/spacer'
import Suspense from '../../../ui-blocks/suspense'
import Container from '../../../ui-blocks/container'
import ButtonLink from '../../../ui-blocks/button-link'
import PaginationNav from '../../../ui-blocks/pagination-nav'

import InitialSetup from './components/initial-setup'
import CampaignsEmpty from './components/campaigns-empty'

import StatusTabs from '../../../components/status-tabs'
import LoadingPage from '../../../components/loading-page'
import { CampaignsListTable } from '../../../components/campaign/table'

import {
  useCountQuery,
  useListCampaignsQuery,
  EnumCampaignStatus,
} from '../../../graphql/components'
import { SortSettings } from '../../../typings'

import usePagination from '../../../utils/use-pagination'
import { useSearchTerms } from '../../../utils/use-search'
import { MIN_EVER, END_OF_DAY } from '../../../utils/stats-intervals'

import { IoMdAdd } from 'react-icons/io'

const ListCampaignsPage: FC = (props) => {
  const location = useLocation()
  const shouldRefetch = (location.state || ({} as any)).refetch as boolean

  const {
    data: countData,
    loading: loadingCount,
    refetch: refetchCount,
  } = useCountQuery({ fetchPolicy: 'no-cache' })
  const campaignsCount = countData?.campaigns || 0
  const pagination = usePagination(campaignsCount, 15)

  const [currentTab, setCurrentTab] = useState<string>('all')
  const [sortBy, setSortBy] = useState<SortSettings>({
    column: '',
    isAscending: false,
  })

  const { searchText, setSearchText, searchTextDebounced } = useSearchTerms()
  const {
    data: campaignsData,
    error: campaignsError,
    loading: loadingCampaigns,
    refetch: refetchCampaigns,
  } = useListCampaignsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      limit: pagination.limit,
      offset: pagination.offset,
      filter: { search: searchTextDebounced },
      intervals: [{ starts_at: MIN_EVER, ends_at: END_OF_DAY }],
    },
  })

  const refetchData = async () => {
    await refetchCount()
    await refetchCampaigns()
  }

  useEffect(() => {
    shouldRefetch && refetchData()
  }, [shouldRefetch])

  useEffect(() => {
    document.addEventListener('refetch-campaigns', refetchData)
    return () => {
      document.removeEventListener('refetch-campaigns', refetchData)
    }
  }, [])

  const hasPlayers = !!(countData?.players || 0)
  const hasCreatives = !!(countData?.creatives || 0)

  const allCampaigns = campaignsData?.campaigns || []
  const filteredCampaigns = allCampaigns.filter((campaign) =>
    currentTab === 'all' ? campaign : campaign.status === currentTab
  )

  return (
    <Container>
      <Suspense ready={!loadingCount} fallback={<LoadingPage />}>
        <Suspense
          ready={hasPlayers && hasCreatives}
          fallback={
            <InitialSetup
              {...props}
              hasPlayers={hasPlayers}
              hasCreatives={hasCreatives}
            />
          }
        >
          <div tw="flex flex-row items-center justify-between mb-10">
            <div tw="flex flex-row items-end">
              <h1 tw="text-charcoal font-light leading-tight text-3xl">
                Campaigns
              </h1>
              {!!campaignsCount && (
                <span tw="text-charcoal text-sm tracking-wide ml-4">
                  {loadingCampaigns ? 'Loading...' : `${campaignsCount} TOTAL`}
                </span>
              )}
            </div>
            {!!campaignsCount && (
              <div tw="flex flex-row items-center">
                <div tw="w-80">
                  <Search
                    value={searchText}
                    loading={loadingCampaigns}
                    entries={filteredCampaigns.map((campaign) => campaign.name)}
                    onSelect={(value) =>
                      setSearchText(!Array.isArray(value) ? value || '' : '')
                    }
                  />
                </div>
                <Spacer size="2.5rem" direction="horizontal" />
                <ButtonLink iconLeft={IoMdAdd} to="/campaigns/create">
                  Add Campaign
                </ButtonLink>
              </div>
            )}
          </div>
          <Suspense ready={!!campaignsCount} fallback={<CampaignsEmpty />}>
            <StatusTabs
              activeTab={currentTab}
              onSelectTab={setCurrentTab}
              tabs={[
                { key: 'all', label: 'All' },
                { key: EnumCampaignStatus.Activated, label: 'Active' },
                {
                  key: EnumCampaignStatus.Deactivated,
                  label: 'Deactivated',
                },
                { key: EnumCampaignStatus.Archived, label: 'Archived' },
              ]}
            />
            <CampaignsListTable
              sortBy={sortBy}
              error={campaignsError}
              data={filteredCampaigns}
              loading={loadingCampaigns}
              searchQuery={searchTextDebounced}
              onSort={setSortBy}
            />
            <PaginationNav
              page={pagination.page}
              count={pagination.count}
              limit={pagination.limit}
              setPage={pagination.setPage}
              tw="mt-8"
            />
          </Suspense>
        </Suspense>
      </Suspense>
    </Container>
  )
}

export default ListCampaignsPage
