import React, { FC } from 'react'

import tw, { css } from 'twin.macro'

import ModalBase from '../ui-blocks/modal-base'
import ButtonLink from '../ui-blocks/button-link'

const EndTrialModal: FC<{ open: boolean; onClose?: () => void }> = ({
  open,
  onClose,
}) => (
  <ModalBase open={open} onBackdropClick={onClose}>
    <div
      css={css`
        ${tw`flex flex-col items-center justify-center`}
      `}
    >
      <h1
        css={css`
          ${tw`font-bold text-charcoal text-3xl leading-tight text-center mb-10`}
        `}
      >
        Upgrade to continue using Diffuse.tv
      </h1>
      <p
        css={css`
          ${tw`font-normal text-charcoal leading-tight text-center mb-14`}
        `}
      >
        Your free trial as reached the end. If you want to take advantage of all
        Diffuse.tv features you need to select a plan.
      </p>
      <ButtonLink to="#">Select Plan</ButtonLink>
    </div>
  </ModalBase>
)

EndTrialModal.defaultProps = {
  open: false,
}

export default EndTrialModal
