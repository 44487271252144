import React, { FC } from 'react'
import useControlledOrInternalValue from '../utils/use-controlled-or-internal-value'
import tw, { css } from 'twin.macro'
import {
  IoIosArrowDown,
  IoIosArrowUp,
  IoIosCheckmarkCircle,
  IoIosCheckmarkCircleOutline,
} from 'react-icons/io'

export const AccordionStep: FC<{
  done?: boolean
  includeHR?: boolean
  expandable?: boolean
  expanded?: boolean
  onExpandedToggle?: () => void
  title: string
}> = ({
  done,
  expandable,
  expanded,
  title,
  children,
  onExpandedToggle,
  includeHR,
}) => {
  const expansion = useControlledOrInternalValue<boolean>({
    value: expanded,
    onChange: onExpandedToggle,
  })

  return (
    <div>
      <div
        className="title"
        css={css`
          ${tw`flex my-3 justify-between`}
          ${(expandable && tw`cursor-pointer`) || ''}
        `}
        onClick={() => expandable && expansion.onChange(!expansion.value)}
      >
        <h3
          css={css`
            ${tw`flex text-base font-medium items-center`}
          `}
        >
          {!!done && (
            <IoIosCheckmarkCircle
              css={css`
                ${tw`text-lg text-metallic-seaweed mr-2`}
              `}
            />
          )}
          {!done && (
            <IoIosCheckmarkCircleOutline
              css={css`
                ${tw`text-lg text-platinum mr-2`}
              `}
            />
          )}

          {title}
        </h3>
        {expandable && !expansion.value && (
          <IoIosArrowDown
            css={css`
              ${tw`text-2xl`}
            `}
          />
        )}
        {expandable && !!expansion.value && (
          <IoIosArrowUp
            css={css`
              ${tw`text-2xl`}
            `}
          />
        )}
      </div>
      {!!expansion.value && (
        <div
          className="content"
          css={css`
            ${tw`mb-4`}
          `}
        >
          {children}
        </div>
      )}
      {!!includeHR && (
        <hr
          css={css`
            ${tw`text-platinum`}
          `}
        />
      )}
    </div>
  )
}
