import React from 'react'
import tw, { css } from 'twin.macro'

import { Link } from 'react-router-dom'
import { ReactComponent as DiffuseLogo } from '../assets/diffuse-logo.svg'

const Logo = () => (
  <Link to="/">
    <DiffuseLogo
      css={css`
        ${tw`h-10`}
      `}
    />
  </Link>
)

export default Logo
