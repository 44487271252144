import React, { FC } from 'react'
import tw, { css } from 'twin.macro'

import RCTabs, {
  TabPaneProps,
  TabsProps as TProps,
  TabPane as RCTabPane,
} from 'rc-tabs'
import { TabNavListProps } from 'rc-tabs/es/TabNavList'
import { TabBarExtraContent } from 'rc-tabs/es/interface'

export interface TabsProps extends TProps {
  tabBarExtraContent?: (props: TabNavListProps) => TabBarExtraContent
}

export const Tabs: FC<TabsProps> = (props) => (
  <RCTabs
    {...props}
    renderTabBar={(navProps, TabNavList) => (
      <TabNavList
        {...navProps}
        extra={
          typeof props.tabBarExtraContent === 'function'
            ? props.tabBarExtraContent(navProps)
            : navProps.extra
        }
      />
    )}
    animated={{
      inkBar: true,
      tabPane: true,
    }}
    css={css`
      .rc-tabs-nav {
        display: flex;
        border-bottom: 1px solid #e7e8eb;
      }

      .rc-tabs-nav-wrap {
        position: relative;
        display: inline-block;
        display: flex;
        flex: auto;
        align-self: stretch;
        overflow: hidden;
        white-space: nowrap;
        transform: translate(0); // Fix chrome render bug

        // >>>>> Ping shadow
        &::before,
        &::after {
          position: absolute;
          z-index: 1;
          opacity: 0;
          transition: opacity 0.3s;
          content: '';
          pointer-events: none;
        }
      }

      .rc-tabs-nav-list {
        position: relative;
        display: flex;
        transition: transform 0.3s;
      }

      .rc-tabs-tab {
        outline: none;
        cursor: pointer;
        ${tw`text-dark-blue-gray`}

        &.rc-tabs-tab-active {
          ${tw`text-purple`}
        }

        .rc-tabs-tab-btn {
          display: flex;
          justify-content: center;
          padding: 0.875rem 1.5rem;
          min-width: 80px;
        }
      }

      .rc-tabs-extra-content {
        display: flex;
        align-items: center;
      }

      // ============================ InkBar ============================
      .rc-tabs-ink-bar {
        position: absolute;
        bottom: 0;
        height: 2px;
        ${tw`bg-purple`}
        pointer-events: none;
        transition: left 0.3s ease-in-out, width 0.3s ease-in-out;
      }

      .rc-tabs-holder {
        flex: auto;
        min-width: 0;
        min-height: 0;
      }

      .rc-tabs-content {
        display: flex;
        width: 100%;
      }

      .rc-tabs-tabpane {
        flex: none;
        width: 100%;
        outline: none;
      }
    `}
  />
)

export const TabPane: FC<TabPaneProps> = (props) => <RCTabPane {...props} />
