import React, { FC } from 'react'

import { css } from 'twin.macro'
import moment, { Moment } from 'moment'

import NotDefined from './not-defined'

import { GoClock } from 'react-icons/go'

const InlineDate: FC<{ date?: Date | number | Moment; showIcon?: boolean }> = ({
  date,
  showIcon,
  ...props
}) => {
  const momDate = (!!date && moment(date)) || undefined
  return !!momDate && momDate.isValid() ? (
    <span title={momDate.format('LLLL')} {...props}>
      {!!showIcon && (
        <GoClock
          css={css`
            display: inline-block;
            vertical-align: text-top;
          `}
        />
      )}
      {momDate.format('lll')}
    </span>
  ) : (
    <NotDefined />
  )
}

export default InlineDate
