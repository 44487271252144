import React, { FC } from 'react'

import tw, { css } from 'twin.macro'

import Container from '../ui-blocks/container'

import { ReactComponent as NoWifiIllustration } from '../assets/illustrations/campagin_0_no_wifi.svg'

const NoInternetConnection: FC = () => (
  <Container>
    <div
      css={css`
        ${tw`flex flex-col items-center justify-center`}
      `}
    >
      <NoWifiIllustration />
      <h2
        css={css`
          ${tw`text-charcoal font-light leading-tight text-3xl mt-10 mb-4`}
        `}
      >
        There seems to be a connection problem
      </h2>
      <p
        css={css`
          ${tw`text-charcoal font-medium text-sm pt-4 pb-6 w-1/2 text-center`}
        `}
      >
        Please check your internet connection. Maecenas varius tortor nibh, sit
        amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam
        hendrerit molestie.
      </p>
    </div>
  </Container>
)

export default NoInternetConnection
