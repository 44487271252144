import React, { FC } from 'react'
import { DropzoneRootProps } from 'react-dropzone'
import tw, { css } from 'twin.macro'
import { TiCloudStorage } from 'react-icons/ti'
import Card from '../../../../ui-blocks/card'

const UploadsArea: FC<{
  rootProps: DropzoneRootProps
  isDragActive: boolean
}> = ({ rootProps, isDragActive }) => {
  return (
    <div
      {...rootProps}
      css={css`
        ${tw`w-full h-full cursor-pointer`}
      `}
    >
      <Card
        css={css`
          ${tw`
          flex
          flex-col
          items-center
          justify-center
          h-full
          p-10
          text-charcoal
          text-center
        `}
          ${isDragActive && tw`border-purple`}
        `}
      >
        <div
          css={css`
            ${tw`
            w-16
            h-16
            bg-cultured
            rounded-full
            flex
            justify-center
            items-center
          `}
          `}
        >
          <TiCloudStorage
            size={48}
            css={css`
              ${tw`text-purple`}
            `}
          />
        </div>
        {isDragActive ? (
          <p>
            Drop the files <Here />
            ...
          </p>
        ) : (
          <p
            css={css`
              ${tw`w-56`}
            `}
          >
            Drag and drop the files here or click <Here /> to browse
          </p>
        )}
      </Card>
    </div>
  )
}

const Here = () => (
  <span
    css={css`
      ${tw`text-purple cursor-pointer`}
    `}
  >
    here
  </span>
)

export default UploadsArea
