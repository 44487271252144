import React, { FC } from 'react'
import 'twin.macro'

import { useFormik } from 'formik'
import { string, object } from 'yup'

import Suspense from '../../../../ui-blocks/suspense'
import ModalAction from '../../../../ui-blocks/modal-action'
import { InputFormik } from '../../../../ui-blocks/input'

import LoadingPage from '../../../../components/loading-page'

import {
  useViewCreativeQuery,
  useUpdateCreativeByIdMutation,
} from '../../../../graphql/components'

import { alertsManager } from '../../../../stores'
import { AlertVariant } from '../../../../stores/alerts-manager'

import { trimValues } from '../../../../utils/data-manipulation'

interface EditNetworkFormikValues {
  name: string
}

const editNetworkValidationSchema = object().shape({
  name: string()
    .trim('Value cannot have leading or trailing white spaces')
    .required('Name is required')
    .strict(true),
})

export interface EditCreativeModalProps {
  open?: boolean
  creativeId: string
  onClose?: () => void
  refetchData?: () => void
}

const EditCreativeModal: FC<EditCreativeModalProps> = ({
  open,
  creativeId,
  onClose,
  refetchData,
}) => {
  const [
    updateCreative,
    { loading: updatingCreative },
  ] = useUpdateCreativeByIdMutation()
  const {
    data: creativeData,
    loading: loadingCreative,
    refetch: refetchCreative,
  } = useViewCreativeQuery({
    skip: !creativeId,
    variables: { _id: creativeId },
  })

  const creative = creativeData?.creative
  const formik = useFormik<EditNetworkFormikValues>({
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: editNetworkValidationSchema,
    initialValues: { name: creative?.name || '' },
    async onSubmit(values) {
      const newValues = trimValues(values, ['name'])
      try {
        const res = await updateCreative({
          variables: { _id: creativeId, input: newValues },
        })
        if ((res.errors || []).length > 0) {
          handleError()
        } else {
          refetchData?.()
          refetchCreative()
          onCloseModal(false)
        }
      } catch {
        handleError()
      }
    },
  })

  const onCloseModal = (reset = true) => {
    if (updatingCreative) return
    reset && formik.resetForm()
    onClose?.()
  }

  const handleError = () => {
    alertsManager.emit({
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'update-network-error-alert',
      message: "We couldn't update this network. Please try again later.",
    })
  }

  return (
    <ModalAction
      open={open}
      loading={updatingCreative}
      onConfirm={formik.submitForm}
      confirmDisabled={!formik.dirty}
      onCancel={() => onCloseModal()}
      onBackdropClick={() => onCloseModal()}
      confirmButtonText="Accept changes"
      width="36rem"
    >
      <Suspense ready={!loadingCreative} fallback={<LoadingPage />}>
        <form tw="mb-20" onChange={formik.handleChange}>
          <h1 tw="font-light text-3xl text-charcoal leading-tight mb-10">
            Creative details
          </h1>
          <span tw="font-medium text-charcoal leading-tight block mb-4">
            Information
          </span>
          <InputFormik
            required
            type="text"
            name="name"
            label="Name"
            formik={formik}
          />
        </form>
      </Suspense>
    </ModalAction>
  )
}

export default EditCreativeModal
