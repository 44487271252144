import React, { FC, useState, useEffect, useMemo } from 'react'

import 'twin.macro'
import { useNavigate, useParams } from 'react-router-dom'

import {
  Informations,
  InformationsSection,
} from '../../../../ui-blocks/information'
import Text from '../../../../ui-blocks/text'
import Button from '../../../../ui-blocks/button'
import Status from '../../../../ui-blocks/status'
import Switch from '../../../../ui-blocks/switch'
import Search from '../../../../ui-blocks/search'
import KPICard from '../../../../ui-blocks/kpi-card'
import Container from '../../../../ui-blocks/container'
import BreadcrumbNav from '../../../../ui-blocks/breadcrumb-nav'
import PaginationNav from '../../../../ui-blocks/pagination-nav'
import { Tabs, TabPane } from '../../../../ui-blocks/tabs'

import EditAdGroupModal from './components/edit-ad-group-modal'

import NotFound from '../../../../components/not-found'
import Saturation from '../../../../components/saturation'
import InlineDate from '../../../../components/inline-date'
import LoadingPage from '../../../../components/loading-page'
import ArchiveWarningModal from '../../../../components/archive-warning-modal'
import ManageCreativesModal from '../../../../components/manage-creatives-modal'
import { CreativesListTable } from '../../../../components/creative/table'

import {
  EnumCampaignAdGroupStatus,
  useViewCampaignAdGroupQuery,
  useArchiveCampaignAdGroupMutation,
  useUpdateCampaignAdGroupActivationMutation,
} from '../../../../graphql/components'
import { SortSettings } from '../../../../typings'

import {
  getKPIIntervals,
  groupStatsWithIntervals,
} from '../../../../utils/stats-intervals'
import usePagination from '../../../../utils/use-pagination'
import { useSearchTerms } from '../../../../utils/use-search'

import { IoIosTrash, IoMdAdd } from 'react-icons/io'

import { alertsManager } from '../../../../stores'
import { AlertVariant } from '../../../../stores/alerts-manager'

const ViewCampaignAdGroupPage: FC = () => {
  const navigate = useNavigate()
  const intervals = useMemo(getKPIIntervals, [])

  const [
    archiveCampaignAdGroup,
    { loading: archivingCampaignAdGroup },
  ] = useArchiveCampaignAdGroupMutation()
  const [
    updateCampaignAdGroupActivation,
  ] = useUpdateCampaignAdGroupActivationMutation()

  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false)
  const [isWarningModalOpen, setIsWarningModalOpen] = useState<boolean>(false)

  const [sortBy, setSortBy] = useState<SortSettings>({
    column: '',
    isAscending: false,
  })
  const [
    isManageCreativesModalOpen,
    setIsManageCreativesModalOpen,
  ] = useState<boolean>(false)

  const { campaign_ad_group_id } = useParams()
  const { searchText, setSearchText, searchTextDebounced } = useSearchTerms()
  const {
    data: campaignAdGroupData,
    loading: loadingCampaignAdGroup,
    refetch: refetchCampaignAdGroup,
  } = useViewCampaignAdGroupQuery({
    skip: !campaign_ad_group_id,
    variables: {
      _id: campaign_ad_group_id,
      intervals: intervals.map(({ starts_at, ends_at }) => ({
        starts_at,
        ends_at,
      })),
    },
  })

  // Don't render a campaign's ad group page if it is archived
  const { campaignAdGroup } = campaignAdGroupData || {}
  useEffect(() => {
    if (campaignAdGroup?.status === EnumCampaignAdGroupStatus.Archived)
      navigate(`/campaigns/${campaignAdGroup.campaign_id}`, { replace: true })
  }, [campaignAdGroup])

  const stats = useMemo(
    () => groupStatsWithIntervals(intervals, campaignAdGroup?.stats || []),
    [intervals, campaignAdGroup]
  )

  const handleUpdateError = (action: 'archive' | 'activate' | 'deactivate') => {
    alertsManager.emit({
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'update-campaign-status-error-alert',
      message: `We couldn't ${action} this campaign's ad group. Please try again later.`,
    })
  }

  const onArchiveCampaignAdGroup = async () => {
    try {
      const res = await archiveCampaignAdGroup({
        variables: { ids: [campaign_ad_group_id] },
      })
      if (!res.data?.areArchived) return handleUpdateError('archive')
      navigate(`/campaigns/${campaignAdGroup?.campaign_id}`, {
        replace: true,
        state: { refetch: true },
      })
    } catch {
      handleUpdateError('archive')
    }
  }

  const onUpdateCampaignAdGroupActivation = async (activate: boolean) => {
    try {
      const res = await updateCampaignAdGroupActivation({
        variables: { ids: [campaign_ad_group_id], is_active: activate },
      })
      if (!res.data?.areUpdated)
        return handleUpdateError(activate ? 'activate' : 'deactivate')
      document.dispatchEvent(new CustomEvent('refetch-campaign-ad-groups'))
      await refetchCampaignAdGroup()
    } catch {
      handleUpdateError(activate ? 'activate' : 'deactivate')
    }
  }

  const allCreatives = useMemo(() => campaignAdGroup?.creatives || [], [
    campaignAdGroup,
  ])
  const filteredCreatives = useMemo(
    () =>
      allCreatives.filter((creative) =>
        searchTextDebounced
          ? new RegExp(searchTextDebounced, 'i').test(creative.name)
          : creative
      ),
    [allCreatives, searchTextDebounced]
  )

  const pagination = usePagination(filteredCreatives.length, 15)
  const isSwitchDisabled =
    campaignAdGroup?.status === EnumCampaignAdGroupStatus.Waiting ||
    campaignAdGroup?.status === EnumCampaignAdGroupStatus.Pending ||
    campaignAdGroup?.status === EnumCampaignAdGroupStatus.Finished ||
    campaignAdGroup?.status === EnumCampaignAdGroupStatus.Archived

  if (loadingCampaignAdGroup) return <LoadingPage />
  if (!campaignAdGroup) return <NotFound />

  return (
    <Container>
      <BreadcrumbNav
        tw="mb-1"
        crumbs={[
          { label: 'Campaigns', linkTo: '/campaigns' },
          {
            label: campaignAdGroup.campaign?.name || '',
            linkTo: `/campaigns/${campaignAdGroup.campaign_id}`,
          },
          { label: campaignAdGroup.name },
        ]}
      />

      <div tw="flex flex-row items-center justify-between mb-6">
        <div tw="flex flex-row items-center">
          <Switch
            disabled={isSwitchDisabled}
            initialChecked={
              campaignAdGroup.status === EnumCampaignAdGroupStatus.Activated
            }
            onSelect={onUpdateCampaignAdGroupActivation}
            tw="-ml-2 mr-2"
          />
          <Text as="h1" preset="h3">
            {campaignAdGroup.name}
          </Text>
        </div>
        <Button
          secondary
          type="button"
          iconLeft={IoIosTrash}
          onClick={() => setIsWarningModalOpen(true)}
        >
          Archive
        </Button>
      </div>

      <div tw="whitespace-pre-line mb-14">
        <Informations
          title="Ad Group Information"
          onEdit={() => setIsEditModalOpen(true)}
        >
          <InformationsSection label="STATUS">
            <Status value={campaignAdGroup.status as EnumCampaignAdGroupStatus}>
              {campaignAdGroup.status === EnumCampaignAdGroupStatus.Pending && (
                <>
                  <p tw="font-medium text-charcoal leading-tight mb-4">
                    Your Ad Group is still pending.
                  </p>
                  <span tw="font-normal text-dark-blue-gray leading-tight block mb-6">
                    You need to add a schedule to your Ad Group to activate it.
                  </span>
                  <Button onClick={() => setIsEditModalOpen(true)}>
                    Edit Ad Group
                  </Button>
                </>
              )}
            </Status>
          </InformationsSection>
          <InformationsSection label="REDIRECT TO">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={campaignAdGroup.redirect_to}
              title={campaignAdGroup.redirect_to}
              tw="text-purple underline"
            >
              URL
            </a>
          </InformationsSection>
          <InformationsSection label="START">
            <InlineDate date={campaignAdGroup.timeframe?.starts_at} />
          </InformationsSection>
          <InformationsSection label="END">
            <InlineDate date={campaignAdGroup.timeframe?.ends_at} />
          </InformationsSection>
          <InformationsSection label="AREA">
            {campaignAdGroup.audience?.area_type}
          </InformationsSection>
          <InformationsSection label="CREATIVES">
            {allCreatives.length}
          </InformationsSection>
          <InformationsSection label="SATURATION">
            <Saturation saturation={campaignAdGroup.saturation} />
          </InformationsSection>
        </Informations>
      </div>

      <KPICard title="Ad Group Analytics" intervals={stats} tw="mb-20" />

      <Tabs
        tabBarExtraContent={() => (
          <div tw="flex">
            <Search
              value={searchText}
              entries={filteredCreatives.map((creative) => creative.name)}
              onSelect={(value) =>
                setSearchText(!Array.isArray(value) ? value || '' : '')
              }
            />
            <Button
              primary
              iconLeft={IoMdAdd}
              onClick={() => setIsManageCreativesModalOpen(true)}
              tw="ml-10 w-72"
            >
              Manage Creatives
            </Button>
          </div>
        )}
      >
        <TabPane key="adgroups" tab={`Creatives (${allCreatives.length})`}>
          <CreativesListTable
            sortBy={sortBy}
            data={filteredCreatives}
            loading={loadingCampaignAdGroup}
            onSort={setSortBy}
          />
          <div tw="flex flex-row items-center justify-between mt-8">
            <Text
              as="span"
              preset="p2"
              decoration="underline"
              css={['text-decoration-style: dashed;']}
            >
              NOTE: The number of impressions and scans on the Creatives table
              refer to the overall total number of impressions and scans per
              creative.
            </Text>
            <PaginationNav
              page={pagination.page}
              count={pagination.count}
              limit={pagination.limit}
              setPage={pagination.setPage}
            />
          </div>
        </TabPane>
      </Tabs>

      <EditAdGroupModal
        open={isEditModalOpen}
        adGroupId={campaign_ad_group_id}
        refetchData={refetchCampaignAdGroup}
        onClose={() => setIsEditModalOpen(false)}
      />
      <ManageCreativesModal
        open={isManageCreativesModalOpen}
        adGroupId={campaign_ad_group_id}
        refetchData={refetchCampaignAdGroup}
        onClose={() => setIsManageCreativesModalOpen(false)}
      />
      <ArchiveWarningModal
        target="campaign's ad group"
        redirectPage="Campaign's Ad Groups"
        open={isWarningModalOpen}
        loading={archivingCampaignAdGroup}
        onConfirm={onArchiveCampaignAdGroup}
        onCancel={() => setIsWarningModalOpen(false)}
      />
    </Container>
  )
}

export default ViewCampaignAdGroupPage
