import React, { FC, Ref, useRef, useState } from 'react'
import tw, { css } from 'twin.macro'
import { IoMdPlay } from 'react-icons/io'
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai'
import { FiEdit2 } from 'react-icons/fi'
import Input from '../../ui-blocks/input'
import Checkbox from '../../ui-blocks/checkbox'
import { formatMetrics } from '../../utils/formaters'
import InlineDate from '../inline-date'

const CreativeItem: FC<{
  id: string
  selection?: boolean
  edit?: boolean
  thumbnail?: string
  name?: string
  impressions?: number
  scans?: number
  orientation?: 'landscape' | 'portrait'
  duration?: string
  upload?: Date
  onMedia?: () => void
  selectionRef?: Ref<HTMLInputElement>
  onSave?: (name: string) => void
  disabled?: boolean
}> = ({
  id,
  selection,
  edit,
  thumbnail,
  name,
  impressions,
  scans,
  orientation = 'landscape',
  duration,
  upload,
  onMedia,
  onSave,
  // selectionRef,
  disabled,
}) => {
  const [editing, setEditing] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const colTemplate = `
    ${(!!selection && `2fr`) || ``}
    2fr 8fr repeat(5, 5fr)
    ${(!!edit && `repeat(2, 2fr)`) || ``}
  `

  return (
    <div
      id={id}
      css={css`
        ${tw`bg-white w-full text-charcoal border border-platinum items-center`}
        ${(!!selection && tw`px-0`) || tw`px-8`}
        height: 4.5rem;
        player: grid;
        grid-template-columns: ${colTemplate};
        justify-items: center;
      `}
    >
      {!!selection && <Checkbox name={`${id}-checkbox`} disabled={disabled} />}
      <div
        onClick={onMedia}
        css={css`
          width: 7.5rem;
        `}
      >
        <div
          onClick={onMedia}
          css={css`
            ${tw`relative`}
            height: calc(4.5rem - 2px);
          `}
        >
          <img
            css={css`
              ${tw`border-0 object-contain h-full`}
            `}
            src={thumbnail}
            alt="logo"
          />
          <IoMdPlay
            size={24}
            css={css`
              ${tw`absolute text-white`}
              top: calc(50% - 0.75rem);
              left: calc(50% - 0.75rem);
            `}
          />
        </div>
      </div>

      <div
        css={css`
          ${tw`w-full`} justify-self: flex-start
        `}
      >
        {editing ? (
          <Input inputRef={inputRef} initialValue={name} placeholder="name" />
        ) : (
          <span
            css={css`
              ${tw`pl-4`}
            `}
          >
            {name}
          </span>
        )}
      </div>

      <div>{(!!impressions && formatMetrics(impressions)) || 0}</div>
      <div>{(!!scans && formatMetrics(scans)) || 0}</div>
      <div>{orientation.charAt(0).toUpperCase() + orientation.slice(1)}</div>
      <div>{duration}</div>
      <InlineDate date={upload} />

      {!!edit && (
        <>
          {editing ? (
            <button
              onClick={() => {
                setEditing(false)
                if (!!onSave && !!inputRef.current) {
                  onSave(inputRef.current.value)
                }
              }}
              css={css`
                ${tw`text-charcoal flex items-center focus:outline-none text-purple w-16 mx-4`}
              `}
            >
              <AiOutlineCheck
                css={css`
                  ${tw`mr-1`}
                `}
              />
              Save
            </button>
          ) : (
            <button
              disabled={disabled}
              onClick={() => setEditing(true)}
              css={css`
                ${tw`text-charcoal flex items-center focus:outline-none w-16 mx-4`}
                &:hover {
                  ${(!disabled && tw`text-purple`) || ``}
                }
              `}
            >
              <FiEdit2
                css={css`
                  ${tw`mr-1`}
                `}
              />
              Edit
            </button>
          )}
          {editing ? (
            <button
              onClick={() => setEditing(false)}
              css={css`
                ${tw`text-charcoal flex items-center focus:outline-none w-16 mx-4`}
              `}
            >
              <AiOutlineClose
                css={css`
                  ${tw`mr-1`}
                `}
              />
              Cancel
            </button>
          ) : (
            <div
              css={css`
                ${tw` w-16 mx-4`}
              `}
            />
          )}
        </>
      )}
    </div>
  )
}

export default CreativeItem
