import React, { FC, useRef } from 'react'
import tw, { css } from 'twin.macro'
import { AiOutlineCheck } from 'react-icons/ai'
import Card from './card'
import { IFormikObject } from '../typings'
import findSameValueIndex from '../utils/find-same-value-index'

interface SelectCardProps {
  name?: string
  value?: string
  checked?: boolean
  preChecked?: boolean
  disabled?: boolean
  selectable?: boolean
  type?: 'radio' | 'checkbox'
  onClick?: () => void
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const SelectCard: FC<SelectCardProps> = ({
  name,
  value,
  checked,
  preChecked,
  disabled,
  selectable = true,
  type,
  onClick,
  onChange,
  children,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <Card
      css={css`
        transition: all 0.15s ease-in-out;
        ${tw`w-full`}
        ${selectable && tw`cursor-pointer`}
        ${checked && selectable && tw`border-purple border-solid`}
        ${preChecked && tw`border-purple border-dashed`}
        ${selectable &&
        `
          &:hover {
            transform: scale(1.05);
            ${disabled ? tw`cursor-not-allowed` : tw`border-purple`}
          }
        `}
      `}
    >
      <div
        onClick={() => {
          inputRef?.current?.click()
          onClick?.()
        }}
        css={css`
          min-height: 6rem;
          ${tw`p-4 relative`}
          ${disabled && tw`opacity-75`}
        `}
        {...props}
      >
        <input
          ref={inputRef}
          name={name}
          type={type || 'checkbox'}
          checked={checked}
          value={value}
          onChange={onChange}
          readOnly
          css={css`
            ${tw`hidden`}
          `}
        />

        {children}

        {checked && selectable && (
          <div
            css={css`
              ${tw`
              w-4
              h-4
              bg-purple
              rounded-full
              flex
              justify-center
              items-center
              absolute
            `}
              top: -0.5rem;
              right: -0.5rem;
            `}
          >
            <AiOutlineCheck
              size={8}
              css={css`
                ${tw`text-white`}
              `}
            />
          </div>
        )}
      </div>
    </Card>
  )
}

export default SelectCard

export interface SelectCardFormikProps extends SelectCardProps {
  formik?: IFormikObject<any>
  name?: string
  selectKey?: string
  selectable?: boolean
}

export const SelectCardFormik: FC<SelectCardFormikProps> = ({
  formik,
  checked,
  selectKey,
  ...props
}) => {
  const meta = formik?.getFieldMeta(props.name || '')

  return (
    <SelectCard
      {...props}
      checked={
        checked ||
        findSameValueIndex(
          (Array.isArray(meta?.value) && meta?.value) || [],
          props.value,
          selectKey
        ) > -1
      }
      onChange={(e) => {
        if (!formik || !props.name) {
          return
        }

        if (typeof e.preventDefault === 'function') {
          e.preventDefault()
        }

        if (typeof e.stopPropagation === 'function') {
          e.stopPropagation()
        }

        const { checked } = e.target
        const { value } = props
        const current_array =
          (Array.isArray(formik.values[props.name]) &&
            formik.values[props.name]) ||
          []

        // check if current value has it within
        const index = findSameValueIndex(current_array, value, selectKey)
        const new_array = [...current_array]

        // logic to ensure it adds or removes
        if (checked) {
          // if we're adding, we're only add it up in case it doesnt exist
          if (index === -1) {
            new_array.push(value)
          }
        } else {
          // if we're removing, we're only remove it in case it exists
          if (index !== -1) {
            new_array.splice(index, 1)
          }
        }

        // set the new array with formik
        formik.setFieldValue(props.name, new_array)
      }}
    />
  )
}
