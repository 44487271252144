import React, { useState, FC, useMemo, useEffect } from 'react'

import 'twin.macro'
import { useLocation, useNavigate } from 'react-router-dom'

import Map from '../../../ui-blocks/map'
import Spacer from '../../../ui-blocks/spacer'
import Search from '../../../ui-blocks/search'
import Suspense from '../../../ui-blocks/suspense'
import Container from '../../../ui-blocks/container'
import ButtonLink from '../../../ui-blocks/button-link'
import ViewControls from '../../../ui-blocks/view-controls'
import PaginationNav from '../../../ui-blocks/pagination-nav'
import PlayersPending from '../../../ui-blocks/players-pending'

import PlayersEmpty from './components/players-empty'

import StatusTabs from '../../../components/status-tabs'
import LoadingPage from '../../../components/loading-page'
import { PlayersListTable } from '../../../components/player/table'

import {
  useCountQuery,
  useListPlayersQuery,
  EnumPlayerStatus,
} from '../../../graphql/components'
import { IMapPoint, SortSettings } from '../../../typings'

import { IoMdAdd } from 'react-icons/io'
import { FaRegMap } from 'react-icons/fa'
import { FiAlignJustify } from 'react-icons/fi'

import usePagination from '../../../utils/use-pagination'
import { useSearchTerms } from '../../../utils/use-search'
import { MIN_EVER, END_OF_DAY } from '../../../utils/stats-intervals'

const ListPlayersPage: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const shouldRefetch = (location.state || ({} as any)).refetch as boolean
  const {
    data: countData,
    loading: loadingCount,
    refetch: refetchCount,
  } = useCountQuery({ fetchPolicy: 'no-cache' })

  const playersCount = countData?.players || 0
  const pagination = usePagination(playersCount, 15)

  const [view, setView] = useState<string>('list-view')
  const [currentTab, setCurrentTab] = useState<string>('all')
  const [sortBy, setSortBy] = useState<SortSettings>({
    column: '',
    isAscending: false,
  })

  const { searchText, setSearchText, searchTextDebounced } = useSearchTerms()
  const {
    data: playersData,
    error: playersError,
    loading: loadingPlayers,
    refetch: refetchPlayers,
  } = useListPlayersQuery({
    fetchPolicy: 'no-cache',
    variables: {
      limit: pagination.limit,
      offset: pagination.offset,
      filter: { search: searchTextDebounced },
      intervals: [{ starts_at: MIN_EVER, ends_at: END_OF_DAY }],
    },
  })

  const refetchData = async () => {
    await refetchCount()
    await refetchPlayers()
  }

  useEffect(() => {
    shouldRefetch && refetchData()
  }, [shouldRefetch])

  useEffect(() => {
    document.addEventListener('refetch-players', refetchData)
    return () => {
      document.removeEventListener('refetch-players', refetchData)
    }
  }, [])

  const allPlayers = useMemo(
    () => (playersData?.players || []).filter((player) => !!player),
    [playersData]
  )

  const { active: activePlayers, pending: pendingPlayers } = allPlayers.reduce<{
    active: any[]
    pending: any[]
  }>(
    (acc, player) => {
      if (player.status === EnumPlayerStatus.Binded) {
        acc.pending = [...acc.pending, player]
      } else {
        acc.active = [...acc.active, player]
      }
      return acc
    },
    { active: [], pending: [] }
  )

  const points = allPlayers
    .map((player) =>
      !player?.geographics?.location?.coordinates
        ? undefined
        : {
            name: player?.name || '',
            lat: player.geographics.location.coordinates[1],
            lng: player.geographics.location.coordinates[0],
          }
    )
    .filter((point) => !!point) as IMapPoint[]

  const filteredPlayers = activePlayers.filter((player) =>
    currentTab === 'all' ? player : player.status === currentTab
  )

  return (
    <Container>
      <Suspense ready={!loadingCount} fallback={<LoadingPage />}>
        <div tw="flex flex-row items-center justify-between mb-10">
          <div tw="flex flex-row items-end">
            <h1 tw="text-charcoal font-light leading-tight text-3xl">
              Players
            </h1>
            {!!playersCount && (
              <span tw="text-charcoal text-sm tracking-wide ml-4">
                {loadingPlayers ? 'Loading...' : `${playersCount} TOTAL`}
              </span>
            )}
          </div>
          {!!playersCount && (
            <div tw="flex flex-row items-center">
              <ViewControls
                initialOptionId="list-view"
                onSelect={setView}
                options={[
                  { id: 'list-view', label: 'List View', icon: FiAlignJustify },
                  { id: 'map-view', label: 'Map View', icon: FaRegMap },
                ]}
              />
              <Spacer size="2.5rem" direction="horizontal" />
              <div tw="w-80">
                <Search
                  value={searchText}
                  loading={loadingPlayers}
                  entries={filteredPlayers.map((player) => player.name || '')}
                  onSelect={(value) =>
                    setSearchText(!Array.isArray(value) ? value || '' : '')
                  }
                />
              </div>
              <Spacer size="2.5rem" direction="horizontal" />
              <ButtonLink iconLeft={IoMdAdd} to="/players/bind">
                Add Player
              </ButtonLink>
            </div>
          )}
        </div>
        <Suspense ready={!!playersCount} fallback={<PlayersEmpty />}>
          {view === 'map-view' && <Map averagePoints points={points} />}
          {view === 'list-view' && (
            <>
              {!!pendingPlayers.length && (
                <div tw="mb-10">
                  <PlayersPending
                    cards={pendingPlayers.map((player) => ({
                      player,
                      onEdit: () => navigate(`/players/bind/${player._id}`),
                    }))}
                  />
                </div>
              )}
              <StatusTabs
                activeTab={currentTab}
                onSelectTab={setCurrentTab}
                tabs={[
                  { key: 'all', label: 'All' },
                  { key: EnumPlayerStatus.Activated, label: 'Active' },
                  {
                    key: EnumPlayerStatus.Deactivated,
                    label: 'Deactivated',
                  },
                  { key: EnumPlayerStatus.Archived, label: 'Archived' },
                ]}
              />
              <PlayersListTable
                sortBy={sortBy}
                error={playersError}
                data={filteredPlayers}
                loading={loadingPlayers}
                searchQuery={searchTextDebounced}
                onSort={setSortBy}
              />
              <PaginationNav
                page={pagination.page}
                count={pagination.count}
                limit={pagination.limit}
                setPage={pagination.setPage}
                tw="mt-8"
              />
            </>
          )}
        </Suspense>
      </Suspense>
    </Container>
  )
}

export default ListPlayersPage
