export const formatMetrics = (value: number) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const formatDay = (date: Date, locale?: string) => {
  const month = date.toLocaleString(locale, { month: 'long' }).slice(0, 3)
  const day = date.getDate()

  return `${month} ${day}`
}

export const formatFileSize = (bytes: number, decimals = 2) => {
  if (bytes === 0) {
    return '0 Bytes'
  }
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(1024))

  return `${parseFloat((bytes / Math.pow(1024, i)).toFixed(dm))} ${sizes[i]}`
}

export const formatDuration = (millis: number) => {
  const hrs = Math.floor(millis / 1000 / 60 / 60)
  const min = Math.floor((millis / 1000 / 60 / 60 - hrs) * 60)
  const sec = Math.floor(((millis / 1000 / 60 / 60 - hrs) * 60 - min) * 60)

  let timeStr = `${min < 10 ? `0${min}` : min}:${sec < 10 ? `0${sec}` : sec}`
  if (!!hrs) timeStr = `${hrs < 10 ? `0${hrs}` : hrs}:${timeStr}`
  return timeStr
}
