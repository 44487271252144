import React, { FC } from 'react'
import tw from 'twin.macro'

import {
  SelectCardFormik,
  SelectCardFormikProps,
} from '../../ui-blocks/select-card'

import UploadThumbnail from '../upload/thumbnail'

import { CreativeListFragmentFragment } from '../../graphql/components'

interface CreativeSelectCardProps extends SelectCardFormikProps {
  value: any
  selectable?: boolean
  creative: Pick<
    CreativeListFragmentFragment,
    '_id' | 'name' | 'upload' | 'stats'
  >
}

const CreativeSelectCard: FC<CreativeSelectCardProps> = ({
  creative,
  ...props
}) => (
  <SelectCardFormik tw="flex flex-row p-0" {...props}>
    <div
      css={[
        'height: 6rem;',
        'max-width: 6rem;',
        tw`flex items-center justify-center w-full overflow-hidden`,
      ]}
    >
      <UploadThumbnail
        compact
        upload={(creative?.upload as any) || undefined}
      />
    </div>
    <div tw="p-4 flex flex-grow flex-col max-w-full overflow-hidden">
      <p
        title={creative.name}
        tw="font-medium text-charcoal leading-tight truncate mb-1"
      >
        {creative.name}
      </p>
      <span tw="font-normal text-xs text-dark-blue-gray">
        {creative.stats?.[0]?.reach || 0} Impressions
      </span>
      <span tw="font-normal text-xs text-dark-blue-gray">
        {creative.stats?.[0]?.engagement || 0} Scans
      </span>
    </div>
  </SelectCardFormik>
)

export default CreativeSelectCard
