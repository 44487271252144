import React, { FC, useState } from 'react'
import tw, { css } from 'twin.macro'
import Button from '../../../ui-blocks/button'
import ModalAction from '../../../ui-blocks/modal-action'

const AccountDangerZone: FC = () => {
  const [openModal, setOpenModal] = useState(false)

  return (
    <>
      <span
        css={css`
          ${tw`text-2xl mb-8 font-light text-charcoal`}
        `}
      >
        Account Danger Zone
      </span>

      <div>
        <Button onClick={() => setOpenModal(true)}>Delete account</Button>
      </div>

      <ModalAction
        open={openModal}
        title="You're about to delete your account"
        content="Bacon ipsum dolor amet frankfurter drumstick shank, pork chop pork prosciutto ham. Drumstick beef ribs pork chop, short ribs bacon tri-tip pork loin pork belly hamburger."
        onCancel={() => {
          setOpenModal(false)
        }}
        onConfirm={() => {
          setOpenModal(false)
        }}
      />
    </>
  )
}

export default AccountDangerZone
