import React, { FC, useRef } from 'react'
import tw, { css } from 'twin.macro'
import { IconBaseProps } from 'react-icons'
import { AiOutlineCheck } from 'react-icons/ai'
import { TiFlashOutline } from 'react-icons/ti'
import Card from './card'
import Text from './text'
import { IFormikObject } from '../typings'
import { isChecked, useOnToggle } from '../utils/formik-helpers'

export interface CardResourceProps {
  type?: 'radio' | 'checkbox'
  value?: string
  checked?: boolean
  onClick?: () => void
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  name?: string
  disabled?: boolean

  title?: string
  description?: string
  icon?: FC<IconBaseProps>
}

const CardResource: FC<CardResourceProps> = ({
  type,
  value,
  disabled,
  name,
  checked,
  onClick,
  onChange,
  title,
  description,
  icon,
}) => {
  const Icon = icon || TiFlashOutline
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <Card
      css={css`
        height: 100%;
        width: 9.25rem;
        transition: border 0.15s ease-in-out, transform 0.15s ease-in-out;
        ${checked && tw`border-purple`}
        &:hover {
          transform: scale(1.05);
          ${!disabled && tw`border-purple cursor-not-allowed`}
        }
      `}
    >
      <div
        onClick={() => {
          inputRef?.current?.click()
          onClick && onClick()
        }}
        css={css`
          ${tw`w-full p-4 relative cursor-pointer`}
          ${!!disabled && tw`opacity-75 cursor-not-allowed`}
        `}
      >
        <input
          ref={inputRef}
          name={name}
          type={type || 'checkbox'}
          checked={checked}
          value={value}
          onChange={onChange}
          readOnly
          css={css`
            ${tw`hidden`}
          `}
        />

        <div
          css={css`
            ${tw`flex flex-col items-center`}
          `}
        >
          <div
            css={css`
              ${tw`
                w-12
                h-12
                bg-cultured
                rounded-full
                flex
                justify-center
                items-center
              `}
            `}
          >
            <Icon
              size={24}
              width={24}
              css={css`
                ${tw`text-purple`}
              `}
            />
          </div>
          <Text as="p" preset="p1" weight="medium" tw="mt-4 mb-2">
            {title}
          </Text>
          <Text as="span" preset="p1" align="center">
            {description}
          </Text>
        </div>

        {checked && (
          <div
            css={css`
              top: -0.5rem;
              right: -0.5rem;
              ${tw`
                w-4
                h-4
                bg-purple
                rounded-full
                flex
                justify-center
                items-center
                absolute
              `}
            `}
          >
            <AiOutlineCheck
              size={8}
              css={css`
                ${tw`text-white`}
              `}
            />
          </div>
        )}
      </div>
    </Card>
  )
}

export default CardResource

export interface CardResourceFormikProps extends CardResourceProps {
  formik: IFormikObject<any>
  name: Exclude<CardResourceProps['name'], undefined>
  value: Exclude<CardResourceProps['value'], undefined>
}

export const CardResourceFormik: FC<CardResourceFormikProps> = ({
  formik,
  ...props
}) => {
  const onToggle = useOnToggle()

  return (
    <CardResource
      {...props}
      checked={isChecked(formik, props)}
      onChange={onToggle}
    />
  )
}
