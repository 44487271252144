import React, { FC } from 'react'

import tw, { css } from 'twin.macro'
import { Link, useLocation } from 'react-router-dom'

const Sidebar: FC<{ anchors: { id: string; title: string }[] }> = ({
  anchors,
}) => {
  const location = useLocation()

  return (
    <aside
      css={css`
        width: 20rem;
        ${tw`fixed bg-white rounded border border-platinum shadow-card p-5`}
      `}
    >
      <Link
        to="/dev/components"
        css={css`
          ${tw`text-sm text-dark-blue-gray font-normal leading-tight uppercase block mb-8`}
        `}
      >
        &lt; Back to the developer catalog
      </Link>
      <h2
        css={css`
          ${tw`text-2xl font-medium leading-tight mb-5`}
        `}
      >
        Elements
      </h2>
      <div
        css={css`
          ${tw`flex flex-col`}
        `}
      >
        {anchors.map((anchor, index) => (
          <a
            key={anchor.id}
            href={`#${anchor.id}`}
            css={css`
              ${tw`font-normal leading-tight block`}
              ${index !== anchors.length - 1 && tw`mb-4`}
              ${`#${anchor.id}` === location.hash && tw`text-purple`}
            `}
          >
            {anchor.title}
          </a>
        ))}
      </div>
    </aside>
  )
}

export default Sidebar
