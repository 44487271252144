import React, { FC, RefObject } from 'react'

import tw from 'twin.macro'
import { Moment } from 'moment'

import Text from './text'

import { ReactComponent as CalendarIcon } from '../assets/icon/calendar.svg'
import { ReactComponent as RemoveIcon } from '../assets/icon/remove/remove_gray.svg'

import useDatePicker, { PickerProps } from '../utils/use-date-picker'

export interface DatePickerContainerProps extends PickerProps {
  value?: string
  maxWidth?: string
  placeholder?: string
  clearable?: boolean
  inputRef: RefObject<HTMLInputElement>
  onClear?: () => void
}

export const DatePickerContainer: FC<DatePickerContainerProps> = ({
  id,
  name,
  label,
  value,
  error,
  success,
  disabled,
  maxWidth,
  inputRef,
  placeholder,
  clearable,
  onClear,
}) => (
  <div tw="w-full relative" id={`parent-${id}`}>
    {!!label && (
      <Text as="label" preset="caption" tw="pb-2" htmlFor={id}>
        {label}
      </Text>
    )}
    <div
      css={[tw`relative`, !!maxWidth ? `max-width: ${maxWidth};` : tw`w-full`]}
    >
      <input
        type="text"
        id={id}
        name={name}
        value={value}
        ref={inputRef}
        disabled={disabled}
        placeholder={placeholder}
        css={[
          tw`block w-full h-10 px-10 py-2 leading-normal transition duration-100 border rounded outline-none cursor-pointer text-charcoal border-platinum`,
          !!disabled && tw`opacity-75 cursor-not-allowed`,
          !disabled && tw`hover:border-light-peri`,
          success && tw`border-metallic-seaweed`,
          !!error && tw`border-brick-red`,
        ]}
      />
      <CalendarIcon
        width={16}
        css={[
          'top: 50%;',
          'transform: translateY(-50%);',
          tw`absolute left-0 ml-4`,
        ]}
      />
      {clearable && (
        <RemoveIcon
          width={14}
          onClick={onClear}
          css={[
            'top: 50%;',
            'transform: translateY(-50%);',
            tw`absolute right-0 mr-4 hover:cursor-pointer`,
          ]}
        />
      )}
    </div>
    {!!error && (
      <span tw="font-normal text-xs text-brick-red leading-tight block mt-1">
        {error}
      </span>
    )}
  </div>
)

export interface DatePickerProps extends PickerProps {
  id?: string
  date?: Moment
  minDate?: Moment
  maxDate?: Moment
  onChange?: (date?: Moment) => void
}

export const DatePicker: FC<DatePickerProps> = ({
  id,
  name,
  date,
  label,
  error,
  success,
  disabled,
  minDate,
  maxDate,
  onChange,
}) => {
  const inputId = id || `input-${Math.random().toString(36).substring(2, 9)}`
  const { value, hasValue, inputRef } = useDatePicker(false, {
    parentEl: `#parent-${inputId}`,
    minDate,
    maxDate,
    startDate: date,
    onChange,
  })

  return (
    <DatePickerContainer
      id={inputId}
      name={name}
      value={value}
      label={label}
      error={error}
      success={success}
      disabled={disabled}
      clearable={hasValue}
      inputRef={inputRef}
      onClear={() => onChange?.(undefined)}
      placeholder="date"
      maxWidth="12rem"
    />
  )
}

export interface DateRangePickerProps extends PickerProps {
  id?: string
  startDate?: Moment
  endDate?: Moment
  minDate?: Moment
  maxDate?: Moment
  onChange?: (startDate?: Moment, endDate?: Moment) => void
}

export const DateRangePicker: FC<DateRangePickerProps> = ({
  id,
  name,
  label,
  error,
  success,
  disabled,
  startDate,
  endDate,
  minDate,
  maxDate,
  onChange,
}) => {
  const inputId = id || `input-${Math.random().toString(36).substring(2, 9)}`
  const { value, hasValue, inputRef } = useDatePicker(true, {
    parentEl: `#parent-${inputId}`,
    startDate,
    endDate,
    minDate,
    maxDate,
    onChange,
  })

  return (
    <DatePickerContainer
      id={inputId}
      name={name}
      value={value}
      label={label}
      error={error}
      success={success}
      disabled={disabled}
      clearable={hasValue}
      inputRef={inputRef}
      onClear={() => onChange?.(undefined)}
      placeholder="start date - end date"
      maxWidth="18rem"
    />
  )
}
