import React, { FC, ReactNode } from 'react'
import tw, { css } from 'twin.macro'
import ModalBase from './modal-base'
import Button from './button'

const ModalAction: FC<{
  /* open?: boolean;
  title?: string;
  content?: string;
  illustration?: string;
  cancelButtonText?: string;
  confirmButtonText?: String;
  onCancel?: () => void;
  onConfirm?: () => void;
  onBackdropClick?: () => void;
  fillContent?: boolean; */
  open?: boolean
  loading?: boolean
  cancelDisabled?: boolean
  confirmDisabled?: boolean
  title?: string
  width?: string
  content?: string | ReactNode
  cancelButtonText?: string
  confirmButtonText?: string
  onCancel?: () => void
  onConfirm?: () => void
  onBackdropClick?: () => void
}> = ({
  open,
  loading,
  cancelDisabled,
  confirmDisabled,
  title,
  width,
  content,
  confirmButtonText,
  cancelButtonText,
  onCancel,
  onConfirm,
  onBackdropClick,
  children,
  /* illustration,
  fillContent */
}) => (
  <ModalBase
    open={open}
    title={title}
    width={width}
    onBackdropClick={onBackdropClick}
  >
    {content ? (
      <p
        css={css`
          ${tw`whitespace-pre-line text-charcoal`}
        `}
      >
        {content}
      </p>
    ) : (
      children
    )}
    <div
      css={css`
        ${tw`flex justify-end flex-wrap mt-4`}
      `}
    >
      {!!onCancel && (
        <Button secondary onClick={onCancel} disabled={cancelDisabled}>
          {(!!cancelButtonText && cancelButtonText) || 'Cancel'}
        </Button>
      )}
      {!!onConfirm && (
        <div
          css={css`
            ${tw`ml-6`}
          `}
        >
          <Button
            loading={loading}
            onClick={onConfirm}
            disabled={confirmDisabled}
          >
            {(!!confirmButtonText && confirmButtonText) || 'Confirm'}
          </Button>
        </div>
      )}
    </div>
  </ModalBase>
)

export default ModalAction
