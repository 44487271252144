import React, { FC } from 'react'
import { css } from 'twin.macro'

export const Section: FC = ({ children }) => (
  <section
    css={css`
      margin: 2rem 1rem;
    `}
  >
    {children}
  </section>
)

export const SectionTitle: FC = ({ children }) => (
  <h2
    css={css`
      font-size: 2rem;
      margin-top: 4rem;
      margin-bottom: 2rem;
    `}
  >
    {children}
  </h2>
)
