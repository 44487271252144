import React, { FC, useEffect, useRef, useState } from 'react'
import tw from 'twin.macro'

import { Upload, EnumUploadType } from '../../graphql/components'

import { ReactComponent as Video } from '../../assets/icon/video.svg'
import { ReactComponent as Image } from '../../assets/icon/image.svg'
import { ReactComponent as Play } from '../../assets/icon/play_button.svg'

import { formatDuration } from '../../utils/formaters'

type UploadPartial = Pick<Upload, 'type' | 'versions'>

const DEFAULT_WIDTH = 318

export interface UploadThumbnailProps {
  compact?: boolean
  iconSize?: number
  upload?: UploadPartial
  onClick?: () => void
}

const UploadThumbnail: FC<UploadThumbnailProps> = ({
  upload,
  compact,
  iconSize,
  onClick,
}) => {
  const versions = upload?.versions || []
  const original = versions.find((v) => v?.name === 'original')
  const thumbnail = versions.find((v) => v?.name === 'thumbnail') || original

  const wrapperRef = useRef<HTMLDivElement>(null)
  const [thumbnailHeight, setThumbnailHeight] = useState<number>(
    DEFAULT_WIDTH * 0.5625
  )

  useEffect(() => {
    if (!!wrapperRef.current) {
      setThumbnailHeight(wrapperRef.current.offsetWidth * 0.5625)
    }
  }, [wrapperRef.current])

  return !!thumbnail ? (
    <div
      ref={wrapperRef}
      onClick={onClick}
      css={[
        tw`bg-platinum`,
        !!onClick && tw`cursor-pointer`,
        upload?.type === EnumUploadType.Video && tw`relative`,
      ]}
    >
      {upload?.type === EnumUploadType.Image && (
        <img
          alt={thumbnail.name}
          src={thumbnail?.public_url || undefined}
          css={[
            tw`w-full object-cover`,
            compact
              ? tw`h-full rounded-tl rounded-bl`
              : `height: ${thumbnailHeight}px;`,
          ]}
        />
      )}
      {upload?.type === EnumUploadType.Video && (
        <>
          <video
            preload="metadata"
            css={[
              tw`w-full`,
              compact ? tw`h-full` : `height: ${thumbnailHeight}px;`,
            ]}
          >
            <source src={thumbnail?.public_url || undefined} type="video/mp4" />
          </video>
          <span
            css={[
              compact ? tw`mr-1 mb-1` : tw`mr-4 mb-2`,
              tw`absolute right-0 bottom-0 text-xs text-white flex flex-row items-center`,
            ]}
          >
            {formatDuration(thumbnail.duration ?? 0)}
            <Play width={8} height={8} tw="ml-1" />
          </span>
        </>
      )}
    </div>
  ) : (
    <div
      ref={wrapperRef}
      css={[
        compact
          ? tw`h-full rounded-tl rounded-bl`
          : `height: ${thumbnailHeight}px;`,
        tw`flex items-center justify-center w-full bg-platinum`,
      ]}
    >
      {upload?.type === EnumUploadType.Image && (
        <Image
          width={iconSize || (compact ? 72 : 144)}
          height={iconSize || (compact ? 72 : 144)}
        />
      )}
      {upload?.type === EnumUploadType.Video && (
        <Video
          width={iconSize || (compact ? 72 : 144)}
          height={iconSize || (compact ? 72 : 144)}
        />
      )}
    </div>
  )
}

export default UploadThumbnail
