import React, { FC, useMemo } from 'react'
import tw from 'twin.macro'

import { useFormik } from 'formik'
import { string, object, array } from 'yup'

import Text from '../ui-blocks/text'
import Search from '../ui-blocks/search'
import Spinner from '../ui-blocks/spinner'
import Suspense from '../ui-blocks/suspense'
import ModalAction from '../ui-blocks/modal-action'
import PaginationNav from '../ui-blocks/pagination-nav'

import LoadingPage from './loading-page'
import PlayerSelectCard from './player/select-card'

import { ReactComponent as NoSearchResults } from '../assets/illustrations/campaign_3-7_new_4_search_no_results.svg'

import {
  useCountQuery,
  useListPlayersQuery,
  useViewNetworkQuery,
  useViewCampaignQuery,
  useListNetworksQuery,
  useUpdateNetworkByIdMutation,
  useUpdateCampaignByIdMutation,
} from '../graphql/components'

import usePagination from '../utils/use-pagination'
import { useSearchTerms } from '../utils/use-search'

import { alertsManager } from '../stores'
import { AlertVariant } from '../stores/alerts-manager'

const QUERIES: { [key: string]: any } = {
  Network: useViewNetworkQuery,
  Campaign: useViewCampaignQuery,
}

const MUTATIONS: { [key: string]: any } = {
  Network: useUpdateNetworkByIdMutation,
  Campaign: useUpdateCampaignByIdMutation,
}

interface ManagePlayersFormikValues {
  players_ids: string[]
}

const managePlayersValidationSchema = object().shape({
  players_ids: array().ensure().of(string()).min(1),
})

export interface ModalAsset {
  id: string
  type: 'Network' | 'Campaign'
}

export interface ManagePlayersModalProps {
  open?: boolean
  asset: ModalAsset
  networkIds?: string[]
  showPreChecked?: boolean
  onClose?: () => void
  refetchData?: () => void
}

const ManagePlayersModal: FC<ManagePlayersModalProps> = ({
  open,
  asset,
  networkIds,
  showPreChecked,
  onClose,
  refetchData,
}) => {
  const counter = useCountQuery({ fetchPolicy: 'no-cache' })
  const playersCount = counter.data?.players || 0
  const pagination = usePagination(playersCount, 9)

  const {
    data: assetData,
    loading: loadingAsset,
    refetch: refetchAsset,
  } = QUERIES[asset.type]({
    skip: !asset.id,
    fetchPolicy: 'no-cache',
    variables: { _id: asset.id },
  })

  const {
    data: networksData,
    loading: loadingNetworks,
    refetch: refetchNetworks,
  } = useListNetworksQuery({
    fetchPolicy: 'no-cache',
    skip: !showPreChecked || !networkIds?.length,
    variables: { filter: { _ids: networkIds } },
  })

  const networkPlayers = (networksData?.networks || []).reduce<string[]>(
    (acc, network) => [
      ...acc,
      ...network.players.map((player) => player?.player_id),
    ],
    []
  )

  const initialPlayersIds: string[] = useMemo(() => {
    if (loadingAsset) return []
    if (asset.type === 'Network') {
      return assetData.network.players.map((player: any) => player?.player?._id)
    } else if (asset.type === 'Campaign') {
      return (assetData.campaign.broadcasts_to?.players || []).map(
        (player: any) => player?.player?._id
      )
    }
  }, [assetData])

  const [updateAsset, { loading: updatingAsset }] = MUTATIONS[asset.type]()
  const formik = useFormik<ManagePlayersFormikValues>({
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: managePlayersValidationSchema,
    initialValues: { players_ids: initialPlayersIds },
    async onSubmit({ players_ids }) {
      let input = {}
      if (asset.type === 'Network') {
        input = {
          players: players_ids.map((player_id) => ({ player_id })),
        }
      } else if (asset.type === 'Campaign') {
        input = {
          broadcasts_to: {
            marketplace: assetData.campaign.broadcasts_to?.marketplace,
            players: players_ids.map((player_id) => ({ player_id })),
            networks: assetData.campaign.broadcasts_to?.networks?.map(
              (network: any) => ({
                network_id: network?.network?._id,
              })
            ),
          },
        }
      }

      try {
        const res = await updateAsset({
          variables: { _id: asset.id, input },
        })
        if ((res.errors || []).length > 0) {
          handleError()
        } else {
          refetchData?.()
          refetchAsset()
          refetchNetworks()
          onCloseModal(false)
        }
      } catch {
        handleError()
      }
    },
  })

  const onCloseModal = (reset = true) => {
    if (updatingAsset) return
    reset && formik.resetForm()
    onClose?.()
  }

  const handleError = () => {
    alertsManager.emit({
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'update-asset-error-alert',
      message: `We couldn't update this ${asset.type}. Please try again later.`,
    })
  }

  const { searchText, setSearchText, searchTextDebounced } = useSearchTerms()
  const { data: playersData, loading: loadingPlayers } = useListPlayersQuery({
    variables: {
      limit: pagination.limit,
      offset: pagination.offset,
      filter: { search: searchTextDebounced },
    },
  })

  const players = playersData?.players || []
  const renderPlayers = () => (
    <div tw="pb-4">
      <div tw="flex flex-row flex-wrap">
        {players.map((player) => (
          <div key={player?._id} tw="w-full max-w-1/3 px-2 mb-4">
            <PlayerSelectCard
              name="players_ids"
              player={player}
              formik={formik}
              value={player?._id}
              preChecked={networkPlayers.includes(player?._id)}
            />
          </div>
        ))}
      </div>
      <div
        css={[
          tw`flex flex-row items-center`,
          showPreChecked ? tw`justify-between` : tw`justify-end`,
        ]}
      >
        {showPreChecked && (
          <Text
            as="span"
            preset="p2"
            css={[tw`underline`, 'text-decoration-style: dashed;']}
          >
            NOTE: Displays with the dashed borders are already linked to the
            selected networks, but they are NOT mandatory! They can be
            unselected.
          </Text>
        )}
        <PaginationNav
          page={pagination.page}
          count={pagination.count}
          limit={pagination.limit}
          setPage={pagination.setPage}
        />
      </div>
    </div>
  )

  const renderNoPlayers = () => (
    <div tw="flex flex-col items-center pb-4">
      <NoSearchResults />
      <p tw="font-medium text-charcoal leading-tight mt-6 mb-2">
        No data to show
      </p>
      <span tw="font-normal text-charcoal leading-tight">
        {!!searchTextDebounced
          ? `We can’t find any player for "${searchTextDebounced}". Please check your spelling and try again.`
          : 'We can’t find any player.'}
      </span>
    </div>
  )

  return (
    <ModalAction
      open={open}
      loading={updatingAsset}
      onConfirm={formik.submitForm}
      confirmDisabled={!formik.dirty}
      onCancel={() => onCloseModal()}
      onBackdropClick={() => onCloseModal()}
      confirmButtonText="Accept changes"
      width="68rem"
    >
      <Suspense
        ready={!loadingAsset && !loadingNetworks}
        fallback={<LoadingPage />}
      >
        <form onChange={formik.handleChange}>
          <h1 tw="font-light text-3xl text-charcoal leading-tight mb-2">
            Manage players
          </h1>
          <p tw="font-normal text-dark-blue-gray leading-tight mb-10">
            Add or remove players.
          </p>
          <div tw="flex flex-row items-end justify-between mb-6">
            <span tw="font-normal text-xs text-dark-blue-gray leading-tight tracking-wider uppercase">
              {`${playersCount} Player(s), ${formik.values.players_ids.length} Selected`}
            </span>
            <div tw="w-full max-w-xs">
              <Search
                value={searchText}
                loading={loadingPlayers}
                entries={players.map((player) => player.name || '')}
                onSelect={(value) =>
                  setSearchText(!Array.isArray(value) ? value || '' : '')
                }
              />
            </div>
          </div>
          <div tw="mb-6">
            <Suspense ready={!loadingPlayers} fallback={<Spinner center />}>
              <Suspense ready={players.length > 0} fallback={renderNoPlayers()}>
                {renderPlayers()}
              </Suspense>
            </Suspense>
          </div>
        </form>
      </Suspense>
    </ModalAction>
  )
}

export default ManagePlayersModal
