import React, { FC, useEffect, useMemo, useState } from 'react'

import tw, { css } from 'twin.macro'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import Spacer from '../../../../ui-blocks/spacer'
import Spinner from '../../../../ui-blocks/spinner'
import ButtonLink from '../../../../ui-blocks/button-link'

import RemoveWebhookModal from './components/remove-webhook-modal'

import { ReactComponent as Pencil } from '../../../../assets/icon/pencil.svg'
import { ReactComponent as Remove } from '../../../../assets/icon/remove/remove_gray.svg'

import { Webhook, useListWebhooksQuery } from '../../../../graphql/components'

const WorkspaceSettingsWebhooksPage: FC = () => {
  const navigate = useNavigate()
  const { state }: any = useLocation()
  const { workspace_id } = useParams()
  const [webhookToRemove, setWebhookToRemove] = useState<Webhook | null>(null)

  const { data: webhooksData, loading, refetch } = useListWebhooksQuery()
  useEffect(() => {
    if (state && state.refetch) refetch()
  }, [state])

  const onEditWebhook = (webhookId: string) => {
    navigate(`/workspaces/${workspace_id}/settings/webhooks/${webhookId}/edit`)
  }

  const onRemoveWebhook = (webhook: Webhook) => {
    setWebhookToRemove(webhook)
  }

  const webhooks = useMemo(
    () =>
      (webhooksData?.webhooks || []).filter(
        (webhook) => !webhook?.archived?.at
      ),
    [webhooksData]
  )

  const renderWebhooks = () =>
    webhooks.map((webhook, index) => (
      <div
        key={webhook._id}
        css={css`
          ${index === 0 && tw`border-t`}
          ${tw`flex flex-row items-center justify-between font-normal leading-tight border-b border-platinum py-4`}
        `}
      >
        <span tw="text-charcoal">{webhook.url}</span>
        <div tw="flex flex-row items-center text-dark-blue-gray">
          <div
            tw="flex flex-row items-center cursor-pointer"
            onClick={() => onEditWebhook(webhook._id)}
          >
            <Pencil width={12} height={12} />
            <span tw="ml-2">Edit</span>
          </div>
          <Spacer size="2rem" direction="horizontal" />
          <div
            tw="flex flex-row items-center cursor-pointer"
            onClick={() => onRemoveWebhook(webhook)}
          >
            <Remove width={12} height={12} />
            <span tw="ml-2">Remove</span>
          </div>
        </div>
      </div>
    ))

  return (
    <div>
      <h2 tw="font-medium text-charcoal leading-tight mb-2">Manage webhooks</h2>
      <p tw="font-normal text-dark-blue-gray leading-tight mb-6">
        Manage webhooks that belong to this workspace.
      </p>
      <div tw="flex flex-row items-end justify-between mb-4">
        <span tw="font-normal text-dark-blue-gray leading-tight">
          {webhooks.length} Webhook(s)
        </span>
        <ButtonLink
          type="button"
          to={`/workspaces/${workspace_id}/settings/webhooks/add`}
        >
          Add webhook
        </ButtonLink>
      </div>
      {loading ? (
        <div tw="w-full flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        renderWebhooks()
      )}
      <RemoveWebhookModal
        refetchData={refetch}
        webhook={webhookToRemove}
        onClose={() => setWebhookToRemove(null)}
      />
    </div>
  )
}

export default WorkspaceSettingsWebhooksPage
