import React, { FC, Fragment } from 'react'

import 'twin.macro'

import { AiOutlineLoading3Quarters } from 'react-icons/ai'

export interface SpinnerProps {
  center?: boolean
}

const Spinner: FC<SpinnerProps> = ({ center }) => {
  const Wrapper = center ? 'div' : Fragment
  return (
    <Wrapper tw="flex items-center justify-center">
      <AiOutlineLoading3Quarters tw="w-12 h-12 animate-spin text-platinum" />
    </Wrapper>
  )
}

Spinner.defaultProps = {
  center: false,
}

export default Spinner
