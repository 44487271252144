import React, { FC } from 'react'
import tw, { css } from 'twin.macro'
import CardResource, { CardResourceProps } from './card-resource'
import useControlledOrInternalValue, {
  IUseControlledOrInternalValueProps,
} from '../utils/use-controlled-or-internal-value'

interface IProps<ValueType>
  extends IUseControlledOrInternalValueProps<ValueType> {
  cards?: CardResourceProps[]
}

const CardResourceRadio: FC<IProps<string>> = ({ cards, ...props }) => {
  const { value, onChange } = useControlledOrInternalValue(props)

  return (
    <div
      css={css`
        ${tw`flex flex-wrap`}
      `}
    >
      {!!cards &&
        cards.map((card, index) => (
          <div
            key={index}
            css={css`
              ${tw`mr-8`}
            `}
          >
            <CardResource
              {...card}
              value={card.value}
              checked={value === card.value}
              onChange={() => onChange(card.value || '')}
            />
          </div>
        ))}
    </div>
  )
}

export default CardResourceRadio
