import React, { FC, Fragment, useEffect, useState } from 'react'

import tw from 'twin.macro'
import moment from 'moment'

import { useFormikContext } from 'formik'
import { object, number, date } from 'yup'

import Input from '../../../../../ui-blocks/input'
import Checkbox from '../../../../../ui-blocks/checkbox'
import Dropdown from '../../../../../ui-blocks/dropdown'
import { DateTimeRangePicker } from '../../../../../ui-blocks/datetime-picker'

import { CreateAdGroupFormikValues } from '..'

export const scheduleValidationSchema = object().shape({
  saturation: number()
    .positive('Must be a number greater than zero')
    .nullable(),
  timeframe: object()
    .shape({
      starts_at: date().required('Start date is required'),
      ends_at: date().required('End date is required'),
    })
    .required(),
})

export const SATURATION_TYPES = {
  MULTIPLIER: 'multiplier',
  PERCENTAGE: 'percentage',
}

export const SATURATION_LABELS = {
  [SATURATION_TYPES.MULTIPLIER]: 'Multiplier',
  [SATURATION_TYPES.PERCENTAGE]: 'Percentage',
}

export const SATURATION_HELPER_TEXTS = {
  [SATURATION_TYPES.MULTIPLIER]:
    'Choose a number based on a multiplier (ex: 1.5x, 1.0x, 0.5x)',
  [SATURATION_TYPES.PERCENTAGE]:
    'Choose a number based on a percentage (ex: 100%, 200%, 50%)',
}

export interface ScheduleStepProps {
  onEnableSaturation?: (value: boolean) => void
}

const ScheduleStep: FC<ScheduleStepProps> = ({ onEnableSaturation }) => {
  const {
    values,
    errors,
    touched,
    initialValues,
    setFieldValue,
  } = useFormikContext<CreateAdGroupFormikValues>()
  const [isSaturationEnabled, setIsSaturationEnabled] = useState<boolean>(false)

  const [multiplierValue, setMultiplierValue] = useState<number | ''>('')
  const [percentageValue, setPercentageValue] = useState<number | ''>('')
  const [selectedSaturation, setSelectedSaturation] = useState<string>(
    SATURATION_TYPES.MULTIPLIER
  )

  const saturationOptions = Object.keys(SATURATION_LABELS).map((type) => ({
    value: type,
    label: SATURATION_LABELS[type],
  }))

  const handleChangeSaturation = (e: React.ChangeEvent<HTMLInputElement>) => {
    let actualValue: number | '' = '',
      percentageValue: number | '' = ''
    if (!!e.target.value) {
      const inputValue = Number(e.target.value)
      actualValue =
        selectedSaturation === SATURATION_TYPES.MULTIPLIER
          ? inputValue
          : inputValue / 100
      percentageValue =
        selectedSaturation === SATURATION_TYPES.MULTIPLIER
          ? actualValue * 100
          : inputValue
    }

    setMultiplierValue(actualValue)
    setPercentageValue(percentageValue)
    setFieldValue('saturation', `${actualValue}`)
  }

  const handleEnableSaturation = (prevSaturation: boolean) => {
    if (prevSaturation) setFieldValue('saturation', initialValues.saturation)
    onEnableSaturation?.(!prevSaturation)
    return !prevSaturation
  }

  useEffect(() => {
    if (!values.saturation) {
      setMultiplierValue('')
      setPercentageValue('')
    }
  }, [values.saturation])

  return (
    <Fragment>
      <p tw="font-medium mb-4">Schedule</p>
      <DateTimeRangePicker
        label="Date"
        minDate={moment()}
        startDate={
          !!values.timeframe.starts_at
            ? moment(values.timeframe.starts_at)
            : undefined
        }
        endDate={
          !!values.timeframe.ends_at
            ? moment(values.timeframe.ends_at)
            : undefined
        }
        success={
          !!touched.timeframe?.starts_at &&
          !!touched.timeframe?.ends_at &&
          !errors.timeframe?.starts_at &&
          !errors.timeframe?.ends_at
        }
        error={
          errors.timeframe?.starts_at?.toString() ||
          errors.timeframe?.ends_at?.toString()
        }
        onChange={(start, end) =>
          setFieldValue('timeframe', { starts_at: start, ends_at: end })
        }
      />

      <hr tw="bg-platinum my-8" />

      <p tw="font-medium mb-2">Saturation</p>
      <p css={['width: 55ch;', tw`mb-4 font-normal text-dark-blue-gray`]}>
        The Ad Group's saturation will define how many times your Ad Group will
        be displayed related to the other Ad Groups inside your campaign. For
        instance, if you choose a multiplier of 2, this means your Ad Group will
        be shown two times more than a Ad Group with a multiplier of 1.
      </p>

      <Checkbox
        checked={isSaturationEnabled}
        onChange={() => setIsSaturationEnabled(handleEnableSaturation)}
        label="Assign saturation"
      />
      <div
        css={[
          tw`mt-4`,
          !isSaturationEnabled && tw`opacity-50 cursor-not-allowed`,
        ]}
      >
        <div tw="flex flex-row items-center mb-2">
          <div css={['max-width: 12.5rem;', tw`flex-grow mr-2`]}>
            <Dropdown
              isClearable={false}
              isSearchable={false}
              placeholder="Choose type"
              options={saturationOptions}
              isDisabled={!isSaturationEnabled}
              success={touched.saturation && !errors.saturation}
              defaultValue={saturationOptions.find(
                (option) => option.value === selectedSaturation
              )}
              onChange={(option) =>
                setSelectedSaturation(option ? option.value : '')
              }
            />
          </div>
          <div css={['max-width: 7.5rem;', tw`flex-grow`]}>
            {selectedSaturation === SATURATION_TYPES.MULTIPLIER && (
              <Input
                step="0.01"
                type="number"
                placeholder="x"
                hideSteppers
                value={multiplierValue}
                disabled={!isSaturationEnabled}
                required={isSaturationEnabled && !!selectedSaturation}
                success={touched.saturation && !errors.saturation}
                onChange={handleChangeSaturation}
              />
            )}
            {selectedSaturation === SATURATION_TYPES.PERCENTAGE && (
              <Input
                step="1"
                type="number"
                placeholder="%"
                hideSteppers
                value={percentageValue}
                disabled={!isSaturationEnabled}
                required={isSaturationEnabled && !!selectedSaturation}
                success={touched.saturation && !errors.saturation}
                onChange={handleChangeSaturation}
              />
            )}
          </div>
        </div>
        <span tw="font-normal text-xs text-dark-blue-gray leading-tight">
          {SATURATION_HELPER_TEXTS[selectedSaturation] ||
            'Choose a number based on a multiplier (ex: 1.5x, 1.0x, 0.5x) or a percentage (ex: 100%, 200%, 50%)'}
        </span>
        {!!errors.saturation && (
          <span tw="font-normal text-xs text-brick-red leading-tight block mt-1">
            {errors.saturation}
          </span>
        )}
      </div>
    </Fragment>
  )
}

export default ScheduleStep
