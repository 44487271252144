import React from 'react'
import tw, { css } from 'twin.macro'
import { Link } from 'react-router-dom'
import Button from '../../../ui-blocks/button'

export default function SignUpInviteExpired() {
  return (
    <div
      css={css`
        ${tw`max-w-sm`}
      `}
    >
      <h1
        css={css`
          ${tw`text-charcoal m-0 mt-16 font-light leading-tight text-4xl self-start`}
        `}
      >
        Your invite expired
      </h1>
      <div
        css={css`
          ${tw`text-charcoal mb-16`}
        `}
      >
        <p>
          Looks like you cannot access this workspace through this link. Your
          team awaits for you, sign up to join them and start to collaborate!
        </p>
      </div>

      <div
        css={css`
          ${tw`mt-12`}
        `}
      >
        <Button primary type="submit">
          Sign up
        </Button>
      </div>
      <div
        css={css`
          ${tw`mt-6`}
        `}
      >
        <Link
          to="/auth/sign-in"
          css={css`
            ${tw`text-purple hover:text-spanish-violet`}
          `}
        >
          Already have an account
        </Link>
      </div>
    </div>
  )
}
