import React, { FC } from 'react'
import tw, { css } from 'twin.macro'

import Text from './text'

import PlayerCard, { PlayerCardProps } from '../components/player/card'

const PlayersPending: FC<{
  cards?: PlayerCardProps[]
}> = ({ cards }) => (
  <div
    css={css`
      height: 15rem;
      ${tw`bg-white rounded border border-platinum w-full flex`}
    `}
  >
    <div tw="bg-lighter-blue h-full p-8 pr-12 rounded-tl rounded-bl w-full max-w-xs">
      <Text as="h1" preset="p2" transform="uppercase" tw="text-charcoal mb-4">
        Pending Players
      </Text>
      <Text
        as="p"
        preset="p1"
        css={css`
          max-height: 7rem;
          ${tw`text-justify relative overflow-hidden -mr-4 pr-4`}

          &:before {
            content: '...';
            ${tw`absolute right-0 bottom-0`}
          }

          &:after {
            content: '';
            margin-top: 0.2rem;
            ${tw`absolute w-4 h-4 right-0 bg-lighter-blue`}
          }
        `}
      >
        You didn't finish setting up all of your Players. Please complete the
        process to make them available.
      </Text>
    </div>
    <div tw="overflow-auto h-full flex items-center">
      {!!cards &&
        cards.map((data, index) => (
          <div
            key={index}
            css={[tw`pl-8`, index === cards.length - 1 && tw`pr-8`]}
          >
            <PlayerCard pending {...data} />
          </div>
        ))}
    </div>
  </div>
)

export default PlayersPending
