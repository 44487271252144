import React, { useState, FC } from 'react'

import delay from 'delay'
import tw, { css } from 'twin.macro'
import { Link, useNavigate, useLocation } from 'react-router-dom'

import { string, object } from 'yup'
import { useFormik, FormikHelpers } from 'formik'

import Button from '../../ui-blocks/button'
import { InputFormik } from '../../ui-blocks/input'

import AlertMessage, { AlertVariant } from '../../components/alert-component'

import history from '../../history'
import { authentication } from '../../stores'

import { trimValues } from '../../utils/data-manipulation'

interface SignInFormikValues {
  email: string
  password: string
}

const signInValidationSchema = object().shape({
  email: string()
    .trim('Value cannot have leading or trailing white spaces')
    .email('Must be a valid email address')
    .required('Email is required')
    .strict(true),
  password: string().required('Password is required'),
})

const onSubmit = async (
  values: SignInFormikValues,
  formikHelpers: FormikHelpers<SignInFormikValues>,
  setSignInError: React.Dispatch<React.SetStateAction<string>>
) => {
  const { email, password } = trimValues(values, ['email'])
  try {
    await authentication.login(email, password)
    if (!authentication.primary_email?.verified_at) {
      await delay(1)
      history.push('/auth/sign-up/verify-email/0')
    }
    if (
      !!authentication.user_data &&
      authentication.user_data.invites.length > 0
    ) {
      await delay(1)
      history.push('/auth/invites')
    }
  } catch (err) {
    setSignInError(err.toString())
  }
}

const AuthSignInPage: FC = () => {
  const navigate = useNavigate()

  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)

  const [signInError, setSignInError] = useState('')
  const formik = useFormik<SignInFormikValues>({
    validateOnChange: false,
    validationSchema: signInValidationSchema,
    initialValues: { email: queryParams.get('email') || '', password: '' },
    onSubmit: (values, helpers) => onSubmit(values, helpers, setSignInError),
  })

  return (
    <form onSubmit={formik.handleSubmit} onChange={formik.handleChange}>
      <h1 tw="text-charcoal m-0 mb-10 font-light leading-tight text-4xl self-start">
        Sign in
      </h1>
      {signInError && (
        <div tw="mb-8">
          {(signInError.match(`This account is not active`) && (
            <AlertMessage
              width="100%"
              alert={{
                id: 'sign-in-error',
                variant: AlertVariant.ERROR,
                message:
                  'It seems like this account is deactivated. Please contact our support!',
              }}
            />
          )) || (
            <AlertMessage
              width="100%"
              alert={{
                id: 'sign-in-error',
                variant: AlertVariant.ERROR,
                message:
                  'You entered an incorrect email or password. Need an account?',
                actionText: 'Sign up',
                onAction: () => navigate(`/auth/sign-up`),
              }}
            />
          )}
        </div>
      )}
      <InputFormik
        type="email"
        name="email"
        label="Email Address"
        placeholder="your-email@domain.eu"
        formik={formik}
      />
      <div tw="mt-8 relative">
        <InputFormik
          type="password"
          name="password"
          label="Password"
          placeholder="*************"
          formik={formik}
        />
        <div
          css={css`
            ${tw`absolute right-0 text-sm text-charcoal hover:text-purple`}
            bottom: 3rem;
          `}
        >
          <Link to="/auth/forgot-password">Forgot Password?</Link>
        </div>
      </div>

      <div tw="mt-12">
        <Button type="submit" loading={formik.isSubmitting}>
          Sign in
        </Button>
      </div>

      <p tw="inline-block align-baseline text-sm mt-8 text-charcoal">
        Don't have an account yet?{' '}
        <Link to="/auth/sign-up" tw="text-purple hover:text-spanish-violet">
          Sign up
        </Link>
      </p>
    </form>
  )
}

export default AuthSignInPage
