import { ForgotPasswordMethod } from '../../../graphql/components'
import { IFormikObject } from '../../../typings'

export enum Step {
  SendCode = 'SEND_CODE',
  VerifyCode = 'VERIFY_CODE',
  ResetPassword = 'RESET_PASSWORD',
  PasswordResetSuccessfully = 'PASSWORD_RESET_SUCCESSFULLY',
}

export interface IFormikValues {
  step: Step
  method: ForgotPasswordMethod
  email?: string
  phoneNumber?: string
  code?: string
  recoveryToken?: string
  password?: string
}

export interface IStepProps {
  formik: IFormikObject<IFormikValues>
}

export interface IRecoveryTokenPayload {
  uid: string
  name: {
    first: string
    last: string
  }
  email: string
  iat: number
  exp: number
}
