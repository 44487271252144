import React, { ComponentType, FC, useState } from 'react'

import _ from 'lodash'
import moment from 'moment'

import tw, { css } from 'twin.macro'
import { Link } from 'react-router-dom'

import Spacer from './spacer'

import { Maybe } from '../graphql/components'
import { SortSettings } from '../typings'

import {
  getCampaignTimeframe,
  getDurationFromUpload,
} from '../utils/data-manipulation'

import { ReactComponent as Caret } from '../assets/icon/caret/caret_gray.svg'

interface TablePropsComponents<DataItem = any> {
  HeaderComponent: FC<{ [key: string]: any }>
  RowComponent: FC<{
    datum: DataItem
    [key: string]: any
  }>
  EmptyComponent: FC<{ [key: string]: any }>
  LoadingComponent: FC<{ [key: string]: any }>
  ErrorComponent: FC<{ error: any; [key: string]: any }>
}
interface TableProps<DataItem = any> {
  id?: string
  error?: any
  loading?: boolean
  searchQuery?: string
  data: Array<Maybe<DataItem>>
  sortBy?: SortSettings
  onSort?: (settings: SortSettings) => void
  [key: string]: any
}
export interface TableColumn {
  key: string
  label: string
  prop?: string
  isSortable: boolean
  component: ComponentType<any>
  transform?: 'uppercase' | 'lowercase' | 'capitalize'
}

export function Table<DataItem>({
  data,
  error,
  sortBy,
  loading = true,
  ...props
}: TableProps<DataItem> & TablePropsComponents<DataItem>) {
  const dataset = data || []
  const hasData = dataset?.length || 0 > 0

  let sortedDataset = dataset
  if (!!dataset.length && !!sortBy?.column) {
    const valueToSort = _.get(dataset[0], sortBy.column, null)

    if (typeof valueToSort === 'string' && !moment(valueToSort).isValid()) {
      sortedDataset = dataset.slice().sort((itemA: any, itemB: any) => {
        const valueA = _.get(itemA, sortBy.column)
        const valueB = _.get(itemB, sortBy.column)
        return valueA.localeCompare(valueB, 'default', { numeric: true })
      })
    } else {
      let sorter: string | ((value: any) => any) = sortBy.column
      if (
        sortBy.column === 'campaign_start' ||
        sortBy.column === 'campaign_end'
      ) {
        // Assumes `value` is a Campaign
        sorter = (value: any) => {
          const [start, end] = getCampaignTimeframe(value.ad_groups)
          return sortBy.column === 'campaign_start' ? start : end
        }
      } else if (sortBy.column === 'upload') {
        // Assumes `value` is a Creative
        sorter = (value: any) => getDurationFromUpload(value[sortBy.column])
      } else if (Array.isArray(valueToSort)) {
        sorter = (value: any) => value[sortBy.column].length
      }
      sortedDataset = _.sortBy(dataset, [sorter])
    }

    if (!sortBy.isAscending) {
      sortedDataset = sortedDataset.reverse()
    }
  }

  return (
    <div tw="w-full overflow-x-auto">
      <table id={props.id} tw="w-full">
        {hasData && (
          <thead>
            <props.HeaderComponent {...props} />
          </thead>
        )}
        <tbody>
          {(loading && <props.LoadingComponent {...props} />) ||
            (!!error && <props.ErrorComponent error={error} {...props} />) ||
            (hasData &&
              sortedDataset.map((datum, i) => {
                if (!datum) {
                  return null
                }
                return <props.RowComponent key={i} datum={datum} {...props} />
              })) || <props.EmptyComponent {...props} />}
        </tbody>
      </table>
    </div>
  )
}

export function generateTableComponent<DataItem>(
  mainProps: TablePropsComponents
) {
  return (props: TableProps) => <Table<DataItem> {...mainProps} {...props} />
}

export const TableHeader: FC = ({ children }) => (
  <>
    <tr tw="w-full text-dark-blue-gray text-xs font-medium tracking-widest border border-transparent">
      {children}
    </tr>
    <Spacer size="0.125rem" />
  </>
)

export interface HeaderColumnProps {
  isSelected?: boolean
  isSortable?: boolean
  onClick?: (isAscending: boolean) => void
}

export const HeaderColumn: FC<HeaderColumnProps> = ({
  isSelected,
  isSortable,
  onClick,
  children,
}) => {
  const [isAscending, setIsAscending] = useState<boolean>(false)

  const handleClick = () => {
    if (!isSortable) return
    isSelected && setIsAscending(!isAscending)
    onClick?.(!!isSelected && !isAscending)
  }

  return (
    <th
      onClick={handleClick}
      css={css`
        ${tw`px-3 py-4 text-center uppercase select-none`}
        ${!!onClick && isSortable && tw`hover:cursor-pointer`}
        ${isSortable &&
        isSelected &&
        tw`flex flex-row items-center justify-center text-charcoal`}
        &:first-of-type {
          ${tw`text-left`}
        }
      `}
    >
      {children}
      {!!children && isSortable && isSelected && (
        <Caret
          width={14}
          className="fill-current"
          css={[
            tw`ml-1 transform transition duration-200`,
            isAscending && 'transform: rotate(-180deg);',
          ]}
        />
      )}
    </th>
  )
}

export const Row: FC = ({ children }) => (
  <>
    <tr
      css={css`
        ${tw`bg-white w-full text-charcoal border border-platinum items-center px-4`}
        height: 4.5rem;
      `}
    >
      {children}
    </tr>
    <Spacer size="0.625rem" />
  </>
)

export const RowCell: FC<{
  linkTo?: string
}> = ({ children, linkTo }) => {
  let content = (
    <div className="cell-wrapper" tw="px-3 py-4">
      {children}
    </div>
  )

  if (linkTo) {
    content = (
      <Link to={linkTo} tw="text-purple">
        {content}
      </Link>
    )
  }

  return (
    <td
      css={css`
        ${tw`text-center`}
        transition: width 350ms ease-in-out;
        &:not(:first-of-type) {
          .cell-wrapper {
            ${tw`flex justify-center`}
          }
        }
        &:first-of-type {
          ${tw`text-left`}
        }
      `}
    >
      {content}
    </td>
  )
}

export const FullRow: FC = ({ children }) => (
  <tr>
    <td colSpan={9999}>{children}</td>
  </tr>
)

export default Table
