import React, { FC } from 'react'
import tw, { css } from 'twin.macro'

import Button from '../../../../../ui-blocks/button'
import ModalBase from '../../../../../ui-blocks/modal-base'

import { Invoice } from '../sections/invoices'

export interface InvoiceModalProps {
  invoice: Invoice | null
  onClose?: () => void
}

const InvoiceModal: FC<InvoiceModalProps> = ({ invoice, onClose }) => (
  <ModalBase
    width="26rem"
    open={!!invoice}
    onBackdropClick={onClose}
    title={`Invoice ${invoice?.id}`}
  >
    <div
      css={css`
        ${tw`mt-6`}
      `}
    >
      <div
        css={css`
          ${tw`mb-8`}
        `}
      >
        <p
          css={css`
            ${tw`font-medium text-xs text-dark-blue-gray tracking-wider uppercase mb-5`}
          `}
        >
          Card Name
        </p>
        <span
          css={css`
            ${tw`font-normal text-charcoal leading-tight block`}
          `}
        >
          {'<<Card name>>'}
        </span>
      </div>
      <div
        css={css`
          ${tw`mb-8`}
        `}
      >
        <p
          css={css`
            ${tw`font-medium text-xs text-dark-blue-gray tracking-wider uppercase mb-5`}
          `}
        >
          Card Number
        </p>
        <span
          css={css`
            ${tw`font-normal text-charcoal leading-tight block`}
          `}
        >
          **** **** **** {'1234'}
        </span>
      </div>
      <div
        css={css`
          ${tw`flex flex-row justify-end`}
        `}
      >
        <Button type="button" onClick={onClose}>
          Close
        </Button>
      </div>
    </div>
  </ModalBase>
)

export default InvoiceModal
