import React, { FC, useState } from 'react'

import tw, { css } from 'twin.macro'
import { useStripe } from '@stripe/react-stripe-js'
import { Link, useParams, useNavigate } from 'react-router-dom'

import { useFormik } from 'formik'
import { string, boolean, object } from 'yup'

import Spacer from '../../../../../ui-blocks/spacer'
import Button from '../../../../../ui-blocks/button'
import CardResource from '../../../../../ui-blocks/card-resource'

import AlertMessage, {
  AlertVariant,
} from '../../../../../components/alert-component'

import PaymentMethodForm from './components/payment-method-form'
import BillingInformationForm from './components/billing-information-form'

import {
  ViewWorkspaceDocument,
  useViewWorkspaceQuery,
  WorkspaceSetupIntentDocument,
  WorkspaceSetupIntentMutation,
  WorkspaceSetupIntentMutationVariables,
  WorkspaceSetDefaultPaymentMethodDocument,
  WorkspaceSetDefaultPaymentMethodMutation,
  WorkspaceSetDefaultPaymentMethodMutationVariables,
} from '../../../../../graphql/components'
import apolloClient from '../../../../../graphql/client'
import { trimValues } from '../../../../../utils/data-manipulation'

export type PaymentMethodType = 'credit-card' | 'stripe'

interface PaymentMethodFormikValues {
  cardName: string
  cardNumber: string
  expMonth: string
  expYear: string
  securityCode: string
  isPrimary: boolean
  paymentMethodType: PaymentMethodType
  company: string
  vatNumber: string
  address: string
  zipCode: string
  city: string
  country: string
}

const paymentMethodValidationSchema = object().shape({
  cardName: string()
    .trim('Value cannot have leading or trailing white spaces')
    .required('Card name is required')
    .strict(true),
  cardNumber: string()
    .trim('Value cannot have leading or trailing white spaces')
    .required('Card number is required')
    .strict(true),
  expMonth: string()
    .trim('Value cannot have leading or trailing white spaces')
    .length(2, 'Value must be exactly 2 digits long')
    .required('Expiration month is required')
    .strict(true),
  expYear: string()
    .trim('Value cannot have leading or trailing white spaces')
    .length(2, 'Value must be exactly 2 digits long')
    .required('Expiration year is required')
    .strict(true),
  securityCode: string()
    .trim('Value cannot have leading or trailing white spaces')
    .min(3, 'Value must be between 3 to 4 digits long')
    .max(4, 'Value must be between 3 to 4 digits long')
    .required('Security code is required')
    .strict(true),
  isPrimary: boolean().default(false),
  paymentMethodType: string().oneOf(['credit-card', 'stripe']).required(),
  company: string()
    .trim('Value cannot have leading or trailing white spaces')
    .required('Company is required')
    .strict(true),
  vatNumber: string()
    .trim('Value cannot have leading or trailing white spaces')
    .notRequired()
    .strict(true),
  address: string()
    .trim('Value cannot have leading or trailing white spaces')
    .required('Address is required')
    .strict(true),
  zipCode: string()
    .trim('Value cannot have leading or trailing white spaces')
    .required('ZIP Code is required')
    .strict(true),
  city: string()
    .trim('Value cannot have leading or trailing white spaces')
    .required('City is required')
    .strict(true),
  country: string()
    .trim('Value cannot have leading or trailing white spaces')
    .required('Country is required')
    .strict(true),
})

const WorkspaceSettingsPaymentMethodsPage: FC = () => {
  const navigate = useNavigate()

  const { workspace_id } = useParams()
  const [error, setError] = useState<string>('')

  const stripe = useStripe()
  const formik = useFormik<PaymentMethodFormikValues>({
    validateOnChange: false,
    validationSchema: paymentMethodValidationSchema,
    initialValues: {
      cardName: '',
      cardNumber: '',
      expMonth: '',
      expYear: '',
      securityCode: '',
      isPrimary: false,
      paymentMethodType: 'credit-card',
      company: '',
      vatNumber: '',
      address: '',
      zipCode: '',
      city: '',
      country: '',
    },
    async onSubmit(values) {
      setError('')
      let setupIntentRes: WorkspaceSetupIntentMutation
      const newValues = trimValues(values, [
        'cardName',
        'cardNumber',
        'expMonth',
        'expYear',
        'securityCode',
        'company',
        'vatNumber',
        'address',
        'zipCode',
        'city',
        'country',
      ])

      try {
        const { data } = await apolloClient.mutate<
          WorkspaceSetupIntentMutation,
          WorkspaceSetupIntentMutationVariables
        >({
          variables: {
            setupIntentArgs: {
              card: {
                card_number: newValues.cardNumber,
                exp_month: parseInt(newValues.expMonth),
                exp_year: parseInt(newValues.expYear),
                cvc: newValues.securityCode,
              },
              billing_details: {
                country: newValues.country,
                city: newValues.city,
                line1: newValues.address,
                postal_code: newValues.zipCode,
                name: newValues.cardName,
                company: newValues.company,
                vat: newValues.vatNumber,
              },
            },
          },
          mutation: WorkspaceSetupIntentDocument,
        })

        if (!data) {
          return
        }
        setupIntentRes = data
      } catch (err) {
        setError(
          'The card you provided was declined. Please try a different card.'
        )
        formik.resetForm({
          values: {
            cardName: '',
            cardNumber: '',
            expMonth: '',
            expYear: '',
            securityCode: '',
            isPrimary: newValues.isPrimary,
            paymentMethodType: 'credit-card',
            company: newValues.company,
            vatNumber: newValues.vatNumber,
            address: newValues.address,
            zipCode: newValues.zipCode,
            city: newValues.city,
            country: newValues.country,
          },
        })
        return
      }

      const cardSetup = await stripe?.confirmCardSetup(
        setupIntentRes?.setupIntent?.client_secret,
        {
          payment_method: setupIntentRes?.setupIntent?.payment_method,
        }
      )

      if (newValues.isPrimary) {
        await apolloClient.mutate<
          WorkspaceSetDefaultPaymentMethodMutation,
          WorkspaceSetDefaultPaymentMethodMutationVariables
        >({
          variables: {
            paymentMethodId: setupIntentRes?.setupIntent.payment_method,
          },
          mutation: WorkspaceSetDefaultPaymentMethodDocument,
          refetchQueries: [
            { query: ViewWorkspaceDocument, variables: { _id: workspace_id } },
          ],
        })
      } else {
        useViewWorkspaceQuery({
          skip: !workspace_id,
          variables: { _id: workspace_id },
        })
      }

      if (!cardSetup?.error) {
        navigate(`/workspaces/${workspace_id}/settings/billing`)
      }
    },
  })

  const onSelectPaymentMethodType = (type: PaymentMethodType) => {
    formik.setValues({
      ...formik.values,
      paymentMethodType: type,
    })
  }

  return (
    <form onChange={formik.handleChange} onSubmit={formik.handleSubmit}>
      {error && (
        <div
          css={css`
            ${tw`mb-6`}
          `}
        >
          <AlertMessage
            alert={{
              variant: AlertVariant.ERROR,
              id: 'payment-method-failure-alert',
              message: error,
            }}
          />
        </div>
      )}
      <h1
        css={css`
          ${tw`font-medium text-xl leading-tight mb-8`}
        `}
      >
        <Link
          to={`/workspaces/${workspace_id}/settings/billing`}
          css={css`
            ${tw`text-purple`}
          `}
        >
          Billing & Plans
        </Link>
        <span
          css={css`
            ${tw`text-charcoal`}
          `}
        >
          {' / Payment Methods'}
        </span>
      </h1>
      <h2
        css={css`
          ${tw`font-medium text-charcoal leading-tight mb-6`}
        `}
      >
        Payment details
      </h2>
      <div
        css={css`
          ${tw`flex flex-row mb-10`}
        `}
      >
        <CardResource
          type="radio"
          title="Credit Card"
          checked={formik.values.paymentMethodType === 'credit-card'}
          onClick={() => onSelectPaymentMethodType('credit-card')}
        />
        <Spacer size="1.25rem" direction="horizontal" />
        <CardResource
          type="radio"
          title="Stripe"
          checked={formik.values.paymentMethodType === 'stripe'}
          onClick={() => onSelectPaymentMethodType('stripe')}
        />
      </div>
      <PaymentMethodForm formik={formik} />
      <hr
        css={css`
          ${tw`bg-platinum my-6`}
        `}
      />
      <h2
        css={css`
          ${tw`font-medium text-charcoal leading-tight mb-6`}
        `}
      >
        Additional billing information
      </h2>
      <BillingInformationForm formik={formik} />
      <Button
        type="submit"
        disabled={!formik.dirty}
        loading={formik.isSubmitting}
      >
        Save
      </Button>
    </form>
  )
}

export default WorkspaceSettingsPaymentMethodsPage
