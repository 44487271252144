import React, { FC } from 'react'

import StateToggles from './state-toggles'
import ElementSection, { ElementSectionProps } from './element-section'

import Switch from '../../../../../ui-blocks/switch'

import useElementStates from '../../../../../utils/use-element-states'

const SwitchSection: FC<ElementSectionProps> = ({
  id,
  title,
  description,
  last,
}) => {
  const { states, values } = useElementStates(['disabled', 'initialChecked'])

  return (
    <ElementSection
      id={id}
      last={last}
      title={title}
      description={
        description || `These are the states supported by the ${title} element:`
      }
    >
      <StateToggles toggles={states} />
      <Switch label="Label" {...values} />
    </ElementSection>
  )
}

export default SwitchSection
