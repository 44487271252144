import React, { FC } from 'react'

import 'twin.macro'
import _ from 'lodash'

import { useFormikContext } from 'formik'
import { object, string, array, number } from 'yup'

import Map from '../../../../ui-blocks/map'
import Radio from '../../../../ui-blocks/radio'
import Input from '../../../../ui-blocks/input'
import Checkbox from '../../../../ui-blocks/checkbox'
import CardResourceRadio from '../../../../ui-blocks/card-resource-radio'
import { CardResourceProps } from '../../../../ui-blocks/card-resource'

import CountryInput from '../../../../components/country-input'

import {
  EnumPointType,
  useListAreaTypesQuery,
  useListVenueTypesQuery,
  EnumPlayerSettingsScreen_Orientation,
} from '../../../../graphql/components'
import { IMapPoint } from '../../../../typings'

import { BindPlayerFormikValues } from '..'

export const editPlayerValidationSchema = object().shape({
  name: string()
    .trim('Value cannot have leading or trailing white spaces')
    .required('Name is required')
    .strict(true),
  settings: object()
    .shape({
      screen_orientation: string()
        .oneOf([
          EnumPlayerSettingsScreen_Orientation.Landscape,
          EnumPlayerSettingsScreen_Orientation.Portrait,
        ])
        .required(),
    })
    .required(),
  geographics: object().shape({
    location: object().shape({
      type: string().oneOf([EnumPointType.Point]).required(),
      coordinates: array().of(number()).required('Location is required'),
    }),
    address: object().shape({
      street: string()
        .trim('Value cannot have leading or trailing white spaces')
        .required('Street name is required')
        .strict(true),
      number: number()
        .positive('Must be a number greater than zero')
        .required('Suite, Apt. is required'),
      city: string()
        .trim('Value cannot have leading or trailing white spaces')
        .required('City is required')
        .strict(true),
      state: string()
        .trim('Value cannot have leading or trailing white spaces')
        .required('State is required')
        .strict(true),
      zip: string()
        .trim('Value cannot have leading or trailing white spaces')
        .required('ZIP Code is required')
        .strict(true),
      country_id: string().required('Country is required'),
    }),
  }),
  demographics: object().shape({
    language_ids: array(string()),
    country_id: string().nullable(),
    area_type_id: string().required(),
    venue_type_ids: array(string()),
  }),
})

const formikCoordinates = (player: BindPlayerFormikValues): IMapPoint[] => {
  const coords = player?.geographics?.location?.coordinates || []
  return coords.length ? [{ lng: coords[0], lat: coords[1] } as IMapPoint] : []
}

const PlayerInformationStep: FC = () => {
  const {
    values,
    errors,
    handleChange,
    setFieldValue,
  } = useFormikContext<BindPlayerFormikValues>()

  const { data: areaTypesData } = useListAreaTypesQuery()
  const { data: venueTypesData } = useListVenueTypesQuery()

  const setCoordinates = (point: IMapPoint) => {
    setFieldValue('geographics.location', {
      ...values.geographics.location,
      type: EnumPointType.Point,
      coordinates: [point.lng, point.lat],
    })
  }

  const setCountry = (countryId: string) => {
    setFieldValue('geographics.address', {
      ...values.geographics.address,
      country_id: countryId,
    })
  }

  const setAreaType = (areaTypeId: string) => {
    setFieldValue('demographics', {
      ...values.demographics,
      area_type_id: areaTypeId,
    })
  }

  const setVenueType = (venueTypeId: string) => {
    const newVenueTypeIds = [...values.demographics.venue_type_ids]
    const idIndex = newVenueTypeIds.findIndex(
      (venue_type_id) => venue_type_id === venueTypeId
    )

    if (idIndex === -1) newVenueTypeIds.push(venueTypeId)
    else newVenueTypeIds.splice(idIndex, 1)

    setFieldValue('demographics', {
      ...values.demographics,
      venue_type_ids: newVenueTypeIds,
    })
  }

  const isVenueTypeChecked = (venueTypeId: string) =>
    (values.demographics?.venue_type_ids || []).includes(venueTypeId)

  const splitVenueTypes = _.chunk(venueTypesData?.venueTypes || [], 4)
  const areaTypes: CardResourceProps[] = (areaTypesData?.areaTypes || []).map(
    (areaType) => ({
      value: areaType._id,
      title: areaType.name || '',
      description: areaType.description || '',
    })
  )

  return (
    <div tw="max-w-xl">
      <section tw="mb-12 max-w-xs">
        <span tw="font-medium text-charcoal leading-tight block mb-4">
          Name
        </span>
        <Input
          type="text"
          name="name"
          value={values.name}
          error={errors.name}
          onChange={handleChange}
        />
      </section>
      <section tw="mb-12">
        <span tw="font-medium text-charcoal leading-tight block mb-4">
          Address
        </span>
        <div tw="w-full flex mb-4">
          <div tw="flex-auto pr-10">
            <Input
              type="text"
              label="Street Name"
              name="geographics.address.street"
              value={values?.geographics?.address?.street || ''}
              error={errors.geographics?.address?.street}
              onChange={handleChange}
            />
          </div>
          <div tw="w-24">
            <Input
              type="text"
              label="Suite, Apt."
              name="geographics.address.number"
              value={values?.geographics?.address?.number || ''}
              error={errors.geographics?.address?.number}
              onChange={handleChange}
            />
          </div>
        </div>
        <div tw="w-full flex mb-4">
          <div tw="flex-auto pr-10">
            <Input
              type="text"
              label="City"
              name="geographics.address.city"
              value={values?.geographics?.address?.city || ''}
              error={errors.geographics?.address?.city}
              onChange={handleChange}
            />
          </div>
          <div tw="w-32 pr-10">
            <Input
              type="text"
              label="State"
              name="geographics.address.state"
              value={values?.geographics?.address?.state || ''}
              error={errors.geographics?.address?.state}
              onChange={handleChange}
            />
          </div>
          <div tw="w-32">
            <Input
              type="text"
              label="ZIP Code"
              name="geographics.address.zip"
              value={values?.geographics?.address?.zip || ''}
              error={errors.geographics?.address?.zip}
              onChange={handleChange}
            />
          </div>
        </div>
        <div tw="w-1/2">
          <CountryInput
            showLabel
            onSelect={setCountry}
            error={errors.geographics?.address?.country_id?.toString() || ''}
            initialValue={values.geographics?.address?.country_id}
          />
        </div>
      </section>
      <section tw="mb-12">
        <span tw="font-medium text-charcoal leading-tight block mb-4">
          Geolocation
        </span>
        <Map
          height="18rem"
          onSetPoint={setCoordinates}
          points={formikCoordinates(values)}
          error={errors.geographics?.location?.coordinates?.toString() || ''}
        />
      </section>
      <section>
        <span tw="font-medium text-charcoal leading-tight block mb-4">
          Settings
        </span>

        <span tw="mb-2 text-charcoal font-medium text-xs tracking-wider block uppercase">
          Orientation
        </span>
        <div tw="mt-2 mb-8">
          {Object.values(EnumPlayerSettingsScreen_Orientation).map(
            (value, index) => (
              <Radio
                value={value}
                label={value}
                key={`radio#${index}`}
                name="settings.screen_orientation"
                checked={values.settings?.screen_orientation === value}
                onChange={() =>
                  setFieldValue('settings.screen_orientation', value)
                }
              />
            )
          )}
        </div>

        <span tw="mb-2 text-charcoal font-medium text-xs tracking-wider block uppercase">
          Area Type
        </span>
        <div tw="text-charcoal text-base mb-6">
          Improve your player's reachability by defining the Area Type.
        </div>
        <CardResourceRadio
          cards={areaTypes}
          onChange={setAreaType}
          value={values.demographics?.area_type_id}
        />
        {!!errors.demographics?.area_type_id && (
          <span tw="font-normal text-xs text-brick-red leading-tight block mt-2">
            {errors.demographics?.area_type_id}
          </span>
        )}

        <span tw="mt-8 mb-2 text-charcoal font-medium text-xs tracking-wider block uppercase">
          Venue Type
        </span>
        <div tw="flex">
          {splitVenueTypes.map((venues, index) => (
            <div tw="w-40" key={`venue-types#${index}`}>
              {venues.map((venue) => (
                <Checkbox
                  value={venue._id}
                  label={venue.name}
                  key={`venue-${venue._id}`}
                  checked={isVenueTypeChecked(venue._id)}
                  onChange={() => setVenueType(venue._id)}
                />
              ))}
            </div>
          ))}
        </div>
      </section>
    </div>
  )
}

export default PlayerInformationStep
