import { useRef, useEffect, useState } from 'react'

import moment, { Moment } from 'moment'

import 'daterangepicker/daterangepicker.css'
import CalendarPicker from 'daterangepicker'

interface DatePickerOptions {
  startDate?: Moment
  endDate?: Moment
  minDate?: Moment
  maxDate?: Moment
  format?: string
  parentEl?: string
  withTime?: boolean
  onChange?: (startDate?: Moment, endDate?: Moment) => void
}

export interface PickerProps {
  id?: string
  name?: string
  label?: string
  error?: string
  success?: boolean
  disabled?: boolean
}

const useDatePicker = (isRange: boolean, options?: DatePickerOptions) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [controller, setController] = useState<CalendarPicker | null>(null)

  const sDate = moment(options?.startDate)
  const eDate = moment(options?.endDate)

  const format = options?.format || 'll'
  useEffect(() => {
    if (!!inputRef.current && !controller) {
      setController(
        new CalendarPicker(
          inputRef.current,
          {
            parentEl: options?.parentEl,
            startDate: options?.startDate,
            endDate: options?.endDate,
            minDate: options?.minDate,
            maxDate: options?.maxDate,
            timePicker: options?.withTime,
            singleDatePicker: !isRange,
            autoUpdateInput: false,
            locale: { format },
          },
          (startDate, endDate) => options?.onChange?.(startDate, endDate)
        )
      )
    }
  }, [inputRef.current])

  const hasValue = isRange
    ? !!options?.startDate && !!options?.endDate
    : !!options?.startDate

  let value = ''
  if (hasValue) {
    value = isRange
      ? `${sDate.format(format)} - ${eDate.format(format)}`
      : sDate.format(format)
  }

  return {
    inputRef,
    hasValue,
    value,
  }
}

export default useDatePicker
