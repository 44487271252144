import React, { FC } from 'react'
import tw, { css } from 'twin.macro'
import Spinner from '../ui-blocks/spinner'

const LoadingPage: FC = () => (
  <div
    css={css`
      ${tw`flex justify-center items-center w-full p-16`}
    `}
  >
    <Spinner />
  </div>
)

export default LoadingPage
