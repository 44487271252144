import React, { FC } from 'react'
import tw, { css } from 'twin.macro'
import { IoMdAdd } from 'react-icons/io'

const Avatar: FC<{
  variant?: 'workspace' | 'profile'
  size?: string
  initials?: string
  add?: boolean
  src?: string
  largeFont?: boolean
}> = ({
  variant = 'workspace',
  size,
  initials,
  add,
  src,
  largeFont,
  ...props
}) => (
  <div
    css={css`
      ${tw`flex items-center justify-center`}
      ${(variant === 'workspace' &&
        tw`border-0 rounded bg-dark-blue-gray text-white`) ||
      tw`border-0 rounded-full bg-cultured text-purple`}
      ${(!!size && `width: ${size}; height: ${size};`) || tw`w-12 h-12`}
      ${(!!largeFont && tw`font-light text-3xl leading-tight`) || ``}
    `}
    {...props}
  >
    {(!!src && (
      <img
        src={src}
        alt={initials}
        css={css`
          ${tw`flex items-center justify-center w-full h-full object-cover border border-platinum bg-white`}
          ${(variant === 'workspace' && tw`rounded`) || tw`rounded-full`}
        `}
      />
    )) ||
      (add ? <IoMdAdd size={largeFont ? 32 : 20} /> : initials)}
  </div>
)

export default Avatar
