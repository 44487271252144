import React, { FC } from 'react'
import tw, { css } from 'twin.macro'

const Card: FC = ({ children, ...props }) => (
  <div
    css={css`
      ${tw`bg-white rounded border border-platinum`}
      box-shadow: 0px 4px 10px rgba(109, 15, 177, 0.03);
    `}
    {...props}
  >
    {children}
  </div>
)

export default Card
