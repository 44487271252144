import React, { Fragment, useState } from 'react'

import 'twin.macro'
import _ from 'lodash'

import {
  Row,
  RowCell,
  FullRow,
  TableHeader,
  TableColumn,
  HeaderColumn,
  generateTableComponent,
} from '../../ui-blocks/table'
import Text from '../../ui-blocks/text'
import Status from '../../ui-blocks/status'

import NoResults from '../no-results'
import InlineDate from '../inline-date'
import NotDefined from '../not-defined'
import LoadingPage from '../loading-page'
import UploadDuration from '../upload/duration'
import UploadThumbnail from '../upload/thumbnail'

import { Creative, EnumCreativeStatus } from '../../graphql/components'
import { SortSettings } from '../../typings'

import { ReactComponent as NoResultsIllustration } from '../../assets/illustrations/campaign_2-1_no_results.svg'

const COLUMNS: TableColumn[] = [
  { key: 'preview', label: 'Preview', isSortable: false, component: Fragment },
  { key: 'name', label: 'Name', isSortable: true, component: Fragment },
  {
    key: 'status',
    label: 'Status',
    isSortable: true,
    prop: 'value',
    component: Status,
  },
  {
    key: 'stats[0].reach',
    label: 'Impressions',
    isSortable: true,
    component: Fragment,
  },
  {
    key: 'stats[0].engagement',
    label: 'Scans',
    isSortable: true,
    component: Fragment,
  },
  {
    key: 'upload.type',
    label: 'Type',
    isSortable: true,
    transform: 'capitalize',
    component: Text,
  },
  {
    key: 'upload',
    label: 'Time',
    isSortable: true,
    prop: 'upload',
    component: UploadDuration,
  },
  {
    key: 'created.at',
    label: 'Uploaded On',
    isSortable: true,
    prop: 'date',
    component: InlineDate,
  },
]

type PartialCreative = Pick<
  Creative,
  '_id' | 'name' | 'upload' | 'status' | 'created'
>

export const CreativesListTable = generateTableComponent<PartialCreative>({
  HeaderComponent({ onSort }) {
    const [selectedColumn, setSelectedColumn] = useState<string>('')

    const handleClick = (settings: SortSettings) => {
      setSelectedColumn(settings.column)
      onSort?.(settings)
    }

    return (
      <TableHeader>
        {COLUMNS.map((col, index) => (
          <HeaderColumn
            key={`header-column#${index}`}
            isSortable={col.isSortable}
            isSelected={selectedColumn === col.key}
            onClick={(isAscending) =>
              handleClick({ column: col.key, isAscending })
            }
          >
            {col.label}
          </HeaderColumn>
        ))}
      </TableHeader>
    )
  },

  EmptyComponent({ searchQuery }) {
    return (
      <FullRow>
        <NoResults
          model="Creative"
          searchQuery={searchQuery}
          Illustration={NoResultsIllustration}
        />
      </FullRow>
    )
  },

  LoadingComponent() {
    return (
      <FullRow>
        <LoadingPage />
      </FullRow>
    )
  },

  ErrorComponent() {
    return null
  },

  RowComponent({ datum: creative, onSelect }) {
    const { _id, upload, status } = creative
    return (
      <Row>
        {COLUMNS.map(
          ({ key, prop, transform, component: Component }, index) => {
            const value = _.get(creative, key, undefined)
            return (
              <RowCell
                key={`row-cell#${index}`}
                linkTo={
                  key === 'name' && status !== EnumCreativeStatus.Archived
                    ? `/creatives/${_id}`
                    : undefined
                }
              >
                {key === 'preview' ? (
                  <div css={['width: 120px;']}>
                    <UploadThumbnail
                      iconSize={72}
                      upload={upload || undefined}
                      onClick={() => onSelect?.(creative)}
                    />
                  </div>
                ) : (
                  <Component
                    {...(!!prop ? { [prop]: value } : {})}
                    {...(!!transform
                      ? { css: [`text-transform: ${transform};`] }
                      : {})}
                  >
                    {!prop &&
                      ((Array.isArray(value) ? value.length : value) ?? (
                        <NotDefined />
                      ))}
                  </Component>
                )}
              </RowCell>
            )
          }
        )}
      </Row>
    )
  },
})
