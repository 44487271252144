import React, { FC } from 'react'
import tw, { css } from 'twin.macro'

export interface ElementSectionProps {
  id: string
  title: string
  description?: string
  last?: boolean
}

const ElementSection: FC<ElementSectionProps> = ({
  id,
  title,
  description,
  last,
  children,
}) => (
  <div
    id={id}
    css={css`
      ${tw`pt-24 -mt-24`}
      ${!last && tw`mb-10`}
    `}
  >
    <h3
      css={css`
        ${tw`text-3xl font-light leading-tight mb-3`}
      `}
    >
      {title}
    </h3>
    <p
      css={css`
        ${tw`font-normal leading-tight mb-5`}
      `}
    >
      {description}
    </p>
    {children}
  </div>
)

export default ElementSection
