import React, { FC } from 'react'
import 'twin.macro'

import Dropdown from '../ui-blocks/dropdown'

import { useListCountriesQuery } from '../graphql/components'

export interface CountryInputProps {
  error?: string
  success?: boolean
  showLabel?: boolean
  initialValue?: string
  onSelect?: (countryId: string) => void
}

const CountryInput: FC<CountryInputProps> = ({
  error,
  success,
  showLabel,
  initialValue,
  onSelect,
}) => {
  const { data, loading } = useListCountriesQuery()
  const countries = (data?.countries || []).map((country) => ({
    value: country._id,
    label: country.name,
  }))

  return (
    <Dropdown
      error={error}
      success={success}
      options={countries}
      isDisabled={loading}
      label={!!showLabel ? 'Country' : ''}
      onChange={(option) => onSelect?.(option?.value || '')}
      value={countries.find((country) => country.value === initialValue)}
      placeholder="Country"
    />
  )
}

export default CountryInput
