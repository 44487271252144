import React, { FC } from 'react'
import 'twin.macro'

import { useNavigate, useParams } from 'react-router-dom'

import WebhookForm, { WebhookFormikValues } from '../components/webhook-form'

import { useCreateWebhookMutation } from '../../../../../graphql/components'

import { alertsManager } from '../../../../../stores'
import { AlertVariant } from '../../../../../stores/alerts-manager'

import { trimValues } from '../../../../../utils/data-manipulation'

const WorkspaceSettingsAddWebhookPage: FC = () => {
  const navigate = useNavigate()
  const { workspace_id } = useParams()

  const [
    createWebhook,
    { loading: creatingWebhook },
  ] = useCreateWebhookMutation()

  const onSubmit = async (values: WebhookFormikValues) => {
    try {
      const newValues = trimValues(values, ['url'])
      const res = await createWebhook({ variables: { input: newValues } })
      if ((res.errors || []).length > 0) return handleError()
      navigate(`/workspaces/${workspace_id}/settings/webhooks`, {
        state: { refetch: true },
      })
    } catch {
      handleError()
    }
  }

  const handleError = () => {
    alertsManager.emit({
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'create-webhook-error-alert',
      message: "We couldn't create this webhook. Please try again later.",
    })
  }

  return (
    <section>
      <h2 tw="font-medium text-charcoal leading-tight mb-2">Add webhook</h2>
      <p tw="font-normal text-xs text-dark-blue-gray leading-tight mb-6">
        Add a new webhook.
      </p>
      <WebhookForm onSubmit={onSubmit} submitting={creatingWebhook} />
    </section>
  )
}

export default WorkspaceSettingsAddWebhookPage
