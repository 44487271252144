import React, { FC } from 'react'
import { css } from 'twin.macro'

export interface SpacerProps {
  size: string
  direction?: 'vertical' | 'horizontal'
}

const Spacer: FC<SpacerProps> = ({ size, direction, ...props }) => (
  <div
    css={css`
      ${direction === 'vertical' ? 'height' : 'width'}: ${size};
    `}
    {...props}
  />
)

Spacer.defaultProps = {
  direction: 'vertical',
}

export default Spacer
