import React, { FC, useState } from 'react'
import tw, { css } from 'twin.macro'
import WorkInProgress from '../../../components/work-in-progress'
import Divider from '../../../ui-blocks/divider'

const AccountNotifications: FC = () => {
  const [emailChecked, setEmailChecked] = useState(false)
  const [smsChecked, setSmsChecked] = useState(false)
  return (
    <>
      <span
        css={css`
          ${tw`text-2xl mb-8 font-light text-charcoal`}
        `}
      >
        Account Notifications
      </span>

      <div
        css={css`
          ${tw`text-charcoal text-base font-medium py-2`}
        `}
      >
        Email
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'baseline',
          marginBottom: '10px',
        }}
      >
        <input
          type="checkbox"
          name={'email'}
          checked={emailChecked}
          onChange={() => setEmailChecked(!emailChecked)}
        />
        <label style={{ margin: '0px 24px 0px 4px' }} htmlFor={'email'}>
          Checkbox to receive system notifications
        </label>
      </div>

      <div
        css={css`
          ${tw`text-charcoal text-base font-medium py-2`}
        `}
      >
        SMS
      </div>
      <div style={{ display: 'flex', alignItems: 'baseline' }}>
        <input
          type="checkbox"
          name={'sms'}
          checked={smsChecked}
          onChange={() => setSmsChecked(!smsChecked)}
        />
        <label style={{ margin: '0px 24px 0px 4px' }} htmlFor={'sms'}>
          Checkbox to receive system notifications
        </label>
      </div>
      <Divider />
    </>
  )
}

export default AccountNotifications
