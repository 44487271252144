import React, { FC, useMemo } from 'react'
import tw, { css } from 'twin.macro'
import { Link } from 'react-router-dom'
import jwtDecode from 'jwt-decode'

import getInitials from '../../../utils/get-initials'
import Button from '../../../ui-blocks/button'
import { InputFormik } from '../../../ui-blocks/input'
import UserInfo from '../user-info'

import { IStepProps, IRecoveryTokenPayload } from './typings'

const StepResetPassword: FC<IStepProps> = ({ formik }) => {
  const user = useMemo(() => {
    const { recoveryToken } = formik.values
    if (!recoveryToken) {
      return
    }

    return jwtDecode<IRecoveryTokenPayload>(recoveryToken)
  }, [formik.values])

  const name = useMemo(() => {
    return `${user?.name?.first} ${user?.name?.last}`
  }, [user])

  return (
    <>
      <div>
        <p
          css={css`
            ${tw`text-4xl font-light`}
          `}
        >
          New Password
        </p>
        <p
          css={css`
            ${tw`text-sm text-charcoal`}
          `}
        >
          Set a new password to your account.
        </p>
      </div>
      <div
        css={css`
          ${tw`my-10`}
        `}
      >
        <UserInfo
          initials={getInitials(name)}
          name={name}
          email={user?.email}
        />
      </div>

      <div
        css={css`
          ${tw`mb-12`}
        `}
      >
        <div
          css={css`
            ${tw`mb-8`}
          `}
        >
          <InputFormik
            formik={formik}
            name="password"
            label="NEW PASSWORD"
            type="password"
            placeholder="********"
          />
        </div>
        <div
          css={css`
            ${tw`mb-4`}
          `}
        >
          <InputFormik
            formik={formik}
            name="passwordConfirmation"
            label="CONFIRM PASSWORD"
            type="password"
            placeholder="*********"
          />
        </div>
        <p
          css={css`
            ${tw`text-xs text-charcoal`}
          `}
        >
          Your password should: <br />
          - have a minimum length of 6 characters;
          <br />
          - have at least a lower case character;
          <br />
          - have at least an upper case character;
          <br />
          - have at least a number;
          <br />
        </p>
      </div>
      <div>
        <div
          css={css`
            ${tw`mb-6`}
          `}
        >
          <Button
            primary
            type="submit"
            css={css`
              ${tw``}width: 200px
            `}
            disabled={!formik.isValid}
            loading={formik.isSubmitting}
          >
            Set new password
          </Button>
        </div>
        <div>
          <Link
            to="/auth/sign-in"
            css={css`
              ${tw`text-purple hover:text-spanish-violet`}
            `}
          >
            Back to sign in
          </Link>
        </div>
      </div>
    </>
  )
}

export default StepResetPassword

// {
//   mockState === 'error' ? (
//     <div
//       css={css`
//       ${tw`mb-12`}
//     `}
//     >
//       <div
//         css={css`
//         ${tw`mb-8`}
//       `}
//       >
//         <Input
//           label="NEW PASSWORD"
//           type="password"
//           placeholder="********"
//           error=" "
//         />
//       </div>
//       <div
//         css={css`
//         ${tw`mb-4`}
//       `}
//       >
//         <Input
//           label="CONFIRM PASSWORD"
//           type="password"
//           placeholder="*********"
//           error="Passwords don’t match."
//         />
//       </div>
//       <p
//         css={css`
//         ${tw`text-xs text-charcoal`}
//       `}
//       >
//         Password restrictions information
//     </p>
//     </div>
//   ) : (
