import React, { FC } from 'react'
import 'twin.macro'

import ButtonLink from '../../../../ui-blocks/button-link'

import { ReactComponent as NoNetworks } from '../../../../assets/illustrations/network_empty_state.svg'

const NetworksEmpty: FC = () => (
  <div tw="flex flex-col items-center">
    <NoNetworks tw="mb-10" />
    <h1 tw="font-light text-3xl text-charcoal leading-tight mb-4">
      Start building networks
    </h1>
    <p tw="font-normal text-charcoal text-center max-w-xl mb-6">
      Link your displays by creating a network.
    </p>
    <ButtonLink to="/networks/create">Start</ButtonLink>
  </div>
)

export default NetworksEmpty
