import React, { FC } from 'react'
import 'twin.macro'

import Avatar from './avatar'

import { AiOutlineClose } from 'react-icons/ai'

import { EnumWorkspaceMembersRole } from '../graphql/components'

export interface MemberProps {
  name?: string
  email?: string
  status?: string
  initials?: string
  avatarSrc?: string
  accessLevel?: EnumWorkspaceMembersRole
  onChangeLevel?: (lvl: EnumWorkspaceMembersRole) => void
  onRemove?: () => void
}

const Member: FC<MemberProps> = ({
  name,
  email,
  status,
  initials,
  avatarSrc,
  // accessLevel,
  // onChangeLevel,
  onRemove,
}) => (
  <div tw="w-full flex items-center py-2 pr-2">
    <div tw="flex-none mr-2">
      <Avatar src={avatarSrc} initials={initials} variant="profile" />
    </div>
    <div tw="w-full flex-auto">
      <p tw="text-charcoal font-medium">
        {`${name}${status ? ` (${status})` : ''}`}
      </p>
      <p tw="text-xs text-charcoal">{email}</p>
    </div>

    {/* <Dropdown
      options={Object.keys(EnumWorkspaceMembersRole).map((level) => ({
        name: level,
        value: level,
      }))}
      initialValue={accessLevel}
      onChange={(value) => {
        if (onChangeLevel) {
          onChangeLevel(value as EnumWorkspaceMembersRole)
        }
      }}
      width="6rem"
      showDropIcon
      ghost
    /> */}

    {email && !status && (
      <button
        onClick={() => onRemove && onRemove()}
        tw="w-20 text-charcoal flex items-center justify-between focus:outline-none ml-12 hover:text-purple"
      >
        <AiOutlineClose />
        Remove
      </button>
    )}
  </div>
)

export default Member
