import React, { FC } from 'react'

import Dots from '../ui-blocks/dots'

import NotDefined from './not-defined'

import { useListCategoriesQuery } from '../graphql/components'

export interface CategoriesProps {
  categoryIds: string[]
}

const Categories: FC<CategoriesProps> = ({ categoryIds }) => {
  const { data: categoriesData, loading } = useListCategoriesQuery({
    skip: categoryIds.length === 0,
    variables: { filter: { _ids: categoryIds } },
  })

  if (loading) return <Dots />
  const categories = categoriesData?.categories || []
  return categories.length ? (
    <span>{categories.map((category) => category.name).join(', ')}</span>
  ) : (
    <NotDefined />
  )
}

export default Categories
