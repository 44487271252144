import React, { FC, useEffect, useState } from 'react'

import { css } from 'twin.macro'
import { useParams, useNavigate } from 'react-router-dom'

import { useFormik } from 'formik'
import { string, object } from 'yup'

import Text from '../../../ui-blocks/text'
import Button from '../../../ui-blocks/button'
import CodeInput from '../../../ui-blocks/code-input'

import AlertComponent, {
  AlertVariant,
} from '../../../components/alert-component'

import {
  useVerifyEmailCodeMutation,
  useSendEmailVerificationCodeMutation,
} from '../../../graphql/components'

import { authentication } from '../../../stores'

interface VerifyEmailFormikValues {
  code: string
  email: string
}

const verifyEmailValidationSchema = object().shape({
  code: string()
    .trim('Value cannot have leading or trailing white spaces')
    .length(6, 'Value must be exactly 6 characters long')
    .required('Verification code is required'),
  email: string()
    .email('Must be a valid email address')
    .required('Email is required'),
})

const VerifyEmailCode: FC = () => {
  const navigate = useNavigate()
  const { email_index } = useParams()

  const [error, setError] = useState<string>('')
  const [emailToVerify, setEmailToVerify] = useState<string>('')

  const [
    sendVerificationEmail,
    { loading: sendingCode },
  ] = useSendEmailVerificationCodeMutation()
  useEffect(() => {
    // Check if the index is a valid integer
    let emailIndex = 0
    try {
      emailIndex = Number.parseInt(email_index)
    } catch {
      return navigate('/')
    }

    // Check if the index is not out of bounds
    const userEmails = authentication.user?.emails || []
    if (emailIndex > userEmails.length - 1) return navigate('/')

    // Check if that email is not already verified
    if (!!userEmails[emailIndex]?.verified_at) return navigate('/')
    const userEmail = userEmails[emailIndex]?.address || ''

    setEmailToVerify(userEmail)
    !!userEmail && onSendCode(userEmail)
  }, [])

  const [
    verifyEmailCode,
    { loading: verfifyingCode },
  ] = useVerifyEmailCodeMutation()
  const formik = useFormik<VerifyEmailFormikValues>({
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: verifyEmailValidationSchema,
    initialValues: { email: emailToVerify, code: '' },
    async onSubmit(values) {
      try {
        const { data } = await verifyEmailCode({ variables: values })
        if (data?.isCodeValid) {
          setError('')
          await authentication.loadUserData()
          if (authentication.workspaces.length === 0) {
            if (
              !!authentication.user_data &&
              authentication.user_data.invites.length > 0
            ) {
              return navigate('/auth/invites') // Go here if the user has no workspaces but has invites
            }
            return navigate('/workspaces/create') // Go here if the user has no workspaces and no invites
          } else {
            return navigate(
              `/workspaces/${authentication.workspaces[0]._id}/overview`
            ) // Go here if the user has at least one workspace
          }
        } else {
          setError(
            'Failed to verify provided code. Please try again and make sure the 6 digit number you typed matches the one provided in the email sent to the specified address.'
          )
        }
      } catch {
        setError(
          "Something went wrong and we couldn't verify your email. Please try again later."
        )
      }
    },
  })

  const onSendCode = async (email?: string) => {
    console.log('sending email...')
    formik.resetForm({ values: { ...formik.values, code: '' } })
    const { data } = await sendVerificationEmail({
      variables: { email: email || emailToVerify },
    })
    if (data?.isCodeSent) setError('')
    else setError('Failed to send code to the specified email address.')
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Text as="h1" preset="h2" tw="mb-2">
        Check your inbox!
      </Text>
      <Text as="p" preset="p1" tw="text-dark-blue-gray">
        {'We emailed a six-digit code to '}
        <span tw="text-charcoal">{emailToVerify}</span>
        {'.'}
      </Text>
      <Text as="p" preset="p1" tw="text-dark-blue-gray mb-10">
        Enter the code bellow to confirm your email address.
      </Text>

      <div tw="mb-8">
        <CodeInput
          id="verify-email-code-input"
          value={formik.values.code}
          error={formik.errors.code}
          onChange={(code) => formik.setValues({ ...formik.values, code })}
          label={
            <div tw="flex flex-row items-center justify-between">
              <Text
                as="label"
                preset="caption"
                transform="uppercase"
                htmlFor="verify-email-code-input"
              >
                Verification Code
              </Text>
              <Button
                ghost
                secondary
                type="button"
                disabled={sendingCode}
                onClick={() => onSendCode()}
                css={css`
                  padding: 0;
                  min-width: unset;
                `}
              >
                Resend Code
              </Button>
            </div>
          }
        />
      </div>
      {!!error && (
        <div tw="mb-8">
          <AlertComponent
            width="100%"
            alert={{
              message: error,
              variant: AlertVariant.ERROR,
              id: 'email-verification-error-alert',
            }}
          />
        </div>
      )}

      <Text as="p" preset="p1" tw="text-dark-blue-gray mt-8 mb-16">
        Make sure to keep this window open while you check your inbox.
      </Text>
      <Button
        tw="mb-16"
        type="submit"
        loading={verfifyingCode}
        disabled={!formik.dirty || formik.values.code.trim().length !== 6}
      >
        Confirm
      </Button>
    </form>
  )
}

export default VerifyEmailCode
