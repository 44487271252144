import React, { FC } from 'react'
import tw, { css } from 'twin.macro'

import Button from '../../../ui-blocks/button'
import CodeInput from '../../../ui-blocks/code-input'

import { IStepProps, Step } from './typings'

const StepVerifyCode: FC<IStepProps> = ({ formik }) => (
  <>
    <h1
      css={css`
        ${tw`text-charcoal m-0 mb-0 font-light leading-tight text-4xl self-start`}
      `}
    >
      Verification Code
    </h1>
    <p
      css={css`
        ${tw`text-charcoal`}
      `}
    >
      We emailed a six-digit code to{' '}
      <span
        css={css`
          ${tw`text-charcoal`}
        `}
      >
        email.
      </span>
    </p>
    <p
      css={css`
        ${tw`text-charcoal`}
      `}
    >
      Enter the code bellow to confirm your email address.
    </p>
    <div
      css={css`
        ${tw`flex-col `}
      `}
    >
      <div
        css={css`
          ${tw`flex justify-between mt-10 text-charcoal`}max-width: 21.5rem
        `}
      >
        <label
          css={css`
            ${tw`mt-2`}
          `}
        >
          VERIFICATION CODE
        </label>
        <Button secondary ghost disabled>
          Resend Code
        </Button>
      </div>
      <div
        css={css`
          ${tw`mb-16`}
        `}
      >
        <CodeInput
          disabled={formik.isSubmitting}
          error={formik.errors.code}
          value={formik.values.code}
          onChange={(code) => formik.setFieldValue('code', code)}
        />
      </div>
    </div>
    <Button secondary disabled={!formik.isValid} loading={formik.isSubmitting}>
      Confirm
    </Button>
    <div
      css={css`
        ${tw`mb-6 mt-6`}
      `}
    >
      <Button
        css={css`
          ${tw`w-auto inline p-0`}
        `}
        ghost
        onClick={(ev: any) => {
          ev.preventDefault()
          formik.setFieldValue('step', Step.SendCode)
        }}
      >
        Back
      </Button>
    </div>
    <div
      css={css`
        ${tw`text-xs text-charcoal`}
      `}
    >
      <p>
        Make sure the email address you provided is linked to an account. If you
      </p>
      <p>don’t receive any email in the next minutes try a differente email</p>
      <p>account or the phone number recovery method.</p>
    </div>
  </>
)

export default StepVerifyCode
