import React, { FC, Fragment } from 'react'

import 'twin.macro'
import moment from 'moment'
import { Link } from 'react-router-dom'

import { Invoice } from '../../settings/billing/sections/invoices'

import AlertMessage, {
  AlertVariant,
} from '../../../../components/alert-component'

export interface PaymentFailAlertProps {
  workspaceId: string
  invoices: Invoice[]
}

const PaymentFailAlert: FC<PaymentFailAlertProps> = ({
  workspaceId,
  invoices,
}) => {
  const hasFailedPayments = invoices.some(
    (invoice) => invoice.status === 'open'
  )

  return hasFailedPayments ? (
    <div tw="mb-4">
      <AlertMessage
        width="100%"
        alert={{
          id: 'payment-failed-alert',
          variant: AlertVariant.ERROR,
          message: (
            <Fragment>
              <b>{'Payment Failed. '}</b>
              <span>
                {
                  'In order to keep your subscription, update your payment details '
                }
                <Link
                  tw="underline cursor-pointer"
                  to={`/workspaces/${workspaceId}/settings/billing`}
                >
                  here
                </Link>
                {' until '}
                <b>{moment().endOf('month').format('LL')}</b>.
              </span>
            </Fragment>
          ),
        }}
      />
    </div>
  ) : null
}

export default PaymentFailAlert
