import React, { FC, useState } from 'react'
import 'twin.macro'

import { useFormik } from 'formik'
import { string, object } from 'yup'

import Button from '../../../ui-blocks/button'
import { InputFormik } from '../../../ui-blocks/input'

import EmailCard from './components/email-card'
import AlertMessage, { AlertVariant } from '../../../components/alert-component'

import {
  UserEmail,
  useAccountEmailAddMutation,
} from '../../../graphql/components'
import { MutationResult } from '../../../typings'

import { trimValues } from '../../../utils/data-manipulation'

import { authentication } from '../../../stores'

interface AddEmailFormikValues {
  email: string
}

const addEmailValidationSchema = object().shape({
  email: string()
    .trim('Value cannot have leading or trailing white spaces')
    .email('Must be a valid email address')
    .required('Email is required')
    .strict(true),
})

const AccountContacts: FC = () => {
  const [alertMsg, setAlertMsg] = useState<string>('')
  const [mutationResult, setMutationResult] = useState<MutationResult>(null)

  const [addEmail] = useAccountEmailAddMutation()
  const formik = useFormik<AddEmailFormikValues>({
    validateOnChange: false,
    validationSchema: addEmailValidationSchema,
    initialValues: { email: '' },
    async onSubmit(values) {
      const { email } = trimValues(values, ['email'])
      setMutationResult(null)
      try {
        const res = await addEmail({ variables: { email } })
        if (res.data?.added) {
          setMutationResult('success')
          setAlertMsg('Email address added successfully.')
          await refetchUserData()
        } else {
          handleAddEmailError()
        }
      } catch {
        handleAddEmailError()
      }
    },
  })

  const refetchUserData = async () => await authentication.loadUserData()

  const handleAddEmailError = () => {
    setMutationResult('error')
    setAlertMsg("We couldn't add this email address. Please try again later.")
  }

  const emails = authentication.user_data?.emails || []
  return (
    <div>
      {!!mutationResult && (
        <div tw="mb-4">
          <AlertMessage
            alert={{
              id: 'add-email-alert',
              message: alertMsg,
              variant:
                mutationResult === 'error'
                  ? AlertVariant.ERROR
                  : AlertVariant.SUCCESS,
            }}
          />
        </div>
      )}
      <h2 tw="font-medium text-charcoal leading-tight mb-2">Manage emails</h2>
      <p tw="font-normal text-xs text-dark-blue-gray leading-tight mb-6">
        Control emails linked to your account
      </p>
      {emails.map((email, index) => (
        <EmailCard
          key={`email#${index}`}
          index={index}
          email={email as UserEmail}
          last={index === emails.length - 1}
          refetchData={refetchUserData}
        />
      ))}
      <hr tw="bg-platinum my-6" />
      <h2 tw="font-medium text-charcoal leading-tight mb-6">
        Add email address
      </h2>
      <form onChange={formik.handleChange} onSubmit={formik.handleSubmit}>
        <div tw="max-w-xs mb-10">
          <InputFormik
            required
            type="email"
            name="email"
            label="Email"
            formik={formik}
          />
        </div>
        <Button
          type="submit"
          disabled={!formik.dirty}
          loading={formik.isSubmitting}
        >
          Add email
        </Button>
      </form>
    </div>
  )
}

export default AccountContacts
