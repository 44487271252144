import React, { FC, useState } from 'react'

import tw, { css } from 'twin.macro'
import OutsideClickHandler from 'react-outside-click-handler'

import Card from './card'

import {
  EnumPlayerStatus,
  EnumNetworkStatus,
  EnumCreativeStatus,
  EnumCampaignStatus,
  EnumCampaignAdGroupStatus,
} from '../graphql/components'

import { ReactComponent as Remove } from '../assets/icon/remove/remove_gray.svg'

const BLUE_STATUS = [
  EnumPlayerStatus.Activated,
  EnumNetworkStatus.Activated,
  EnumCreativeStatus.Activated,
  EnumCreativeStatus.Approved,
  EnumCampaignStatus.Activated,
  EnumCampaignAdGroupStatus.Activated,
]

const RED_STATUS = [
  EnumPlayerStatus.Binded,
  EnumCreativeStatus.Rejected,
  EnumCampaignStatus.Pending,
  EnumCampaignAdGroupStatus.Pending,
]

const GREEN_STATUS = [
  EnumPlayerStatus.Registered,
  EnumCreativeStatus.Submitted,
  EnumCampaignStatus.Waiting,
  EnumCampaignAdGroupStatus.Waiting,
]

const YELLOW_STATUS = [
  EnumPlayerStatus.Deactivated,
  EnumNetworkStatus.Deactivated,
  EnumCreativeStatus.Deactivated,
  EnumCreativeStatus.Uploaded,
  EnumCampaignStatus.Deactivated,
  EnumCampaignAdGroupStatus.Deactivated,
]

const BLACK_STATUS = [
  EnumPlayerStatus.Archived,
  EnumNetworkStatus.Archived,
  EnumCreativeStatus.Archived,
  EnumCampaignStatus.Finished,
  EnumCampaignStatus.Archived,
  EnumCampaignAdGroupStatus.Finished,
  EnumCampaignAdGroupStatus.Archived,
]

export interface StatusProps {
  id?: string
  compact?: boolean
  value:
    | EnumPlayerStatus
    | EnumNetworkStatus
    | EnumCreativeStatus
    | EnumCampaignStatus
    | EnumCampaignAdGroupStatus
}

const Status: FC<StatusProps> = ({ id, compact, children, ...props }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)

  const value = props.value as any
  const colors =
    (BLUE_STATUS.includes(value) && tw`bg-light-blue text-blue`) ||
    (RED_STATUS.includes(value) && tw`bg-light-red text-dark-red`) ||
    (GREEN_STATUS.includes(value) && tw`bg-light-green text-green`) ||
    (YELLOW_STATUS.includes(value) && tw`bg-light-yellow text-dark-yellow`) ||
    (BLACK_STATUS.includes(value) && tw`bg-light-charcoal text-charcoal`) ||
    tw`bg-light-yellow text-dark-yellow`

  return (
    <div tw="relative">
      <div
        id={id}
        onClick={() => !!children && setIsPopoverOpen(true)}
        css={[
          colors,
          !!children && tw`hover:cursor-pointer`,
          tw`font-medium text-xs border-0 uppercase tracking-wider select-none`,
          !compact ? tw`w-24 py-3 rounded text-center` : tw`bg-transparent`,
        ]}
      >
        {value}
      </div>
      {isPopoverOpen && (
        <OutsideClickHandler onOutsideClick={() => setIsPopoverOpen(false)}>
          <div css={[tw`absolute mt-6`, 'width: 25rem;', 'z-index: 100;']}>
            <Card
              css={css`
                ${tw`relative p-4`}
                &::after, &::before {
                  content: '';
                  left: 3rem;
                  bottom: 100%;
                  border: solid transparent;
                  ${tw`w-0 h-0 absolute pointer-events-none`}
                }
                &::after {
                  border-width: 0.875rem;
                  margin-left: -0.875rem;
                  border-color: transparent;
                  border-bottom-color: white;
                }
                &::before {
                  border-width: 1rem;
                  margin-left: -1rem;
                  border-color: transparent;
                  border-bottom-color: #e7e8eb;
                }
              `}
            >
              <div tw="w-full block flex flex-row items-center justify-end pb-2">
                <Remove
                  width={12}
                  height={12}
                  onClick={() => setIsPopoverOpen(false)}
                  tw="hover:cursor-pointer"
                />
              </div>
              <div tw="px-6 pb-4">{children}</div>
            </Card>
          </div>
        </OutsideClickHandler>
      )}
    </div>
  )
}

export default Status
