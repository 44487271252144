import React, { FC, useState } from 'react'

import { Moment } from 'moment'

import Spacer from '../../../../../ui-blocks/spacer'
import {
  HourPicker,
  HourRangePicker,
} from '../../../../../ui-blocks/hour-picker'

import StateToggles from './state-toggles'
import ElementSection, { ElementSectionProps } from './element-section'

import useElementStates from '../../../../../utils/use-element-states'

const HourPickerSection: FC<ElementSectionProps> = ({
  id,
  title,
  description,
  last,
}) => {
  const {
    states,
    values: { error, ...values },
  } = useElementStates(['error', 'success', 'disabled'])

  const [singleHour, setSingleHour] = useState<Moment | undefined>(undefined)
  const [hourRange, setHourRange] = useState<{
    startTime?: Moment
    endTime?: Moment
  }>({})

  return (
    <ElementSection
      id={id}
      last={last}
      title={title}
      description={
        description || `These are the states supported by the ${title} element:`
      }
    >
      <StateToggles toggles={states} />
      <HourPicker
        time={singleHour}
        onChange={setSingleHour}
        error={error ? 'Error message!' : ''}
        {...values}
      />
      <Spacer size="1.5rem" />
      <HourRangePicker
        startTime={hourRange.startTime}
        endTime={hourRange.endTime}
        onChange={(startTime, endTime) =>
          setHourRange({
            startTime,
            endTime,
          })
        }
        error={error ? 'Error message!' : ''}
        {...values}
      />
    </ElementSection>
  )
}

export default HourPickerSection
