import React, { FC, useState } from 'react'
import 'twin.macro'

import { useFormik } from 'formik'
import { string, object } from 'yup'

import Button from '../../../../../ui-blocks/button'
import { InputFormik } from '../../../../../ui-blocks/input'

import AlertMessage, {
  AlertVariant,
} from '../../../../../components/alert-component'

import { useInviteToWorkspaceMutation } from '../../../../../graphql/components'
import { MutationResult } from '../../../../../typings'

import { trimValues } from '../../../../../utils/data-manipulation'

interface InviteMembersFormikValues {
  email: string
}

const inviteMembersValidationSchema = object().shape({
  email: string()
    .trim('Value cannot have leading or trailing white spaces')
    .email('Must be a valid email address')
    .required('Email is required')
    .strict(true),
})

const WorkspaceSettingsAddMemberPage: FC = () => {
  const [mutationResult, setMutationResult] = useState<MutationResult>(null)

  const [inviteToWorkspace, { loading }] = useInviteToWorkspaceMutation()
  const formik = useFormik<InviteMembersFormikValues>({
    validateOnChange: false,
    initialValues: { email: '' },
    validationSchema: inviteMembersValidationSchema,
    async onSubmit(values) {
      const { email } = trimValues(values, ['email'])
      setMutationResult(null)
      try {
        const res = await inviteToWorkspace({ variables: { emails: [email] } })
        if (!res.data?.isInviteSent) return setMutationResult('error')
        setMutationResult('success')
      } catch {
        setMutationResult('error')
      }
    },
  })

  return (
    <div>
      {mutationResult && (
        <div tw="mb-6">
          <AlertMessage
            alert={{
              id: 'add-member-update-alert',
              variant:
                mutationResult === 'error'
                  ? AlertVariant.ERROR
                  : AlertVariant.SUCCESS,
              message:
                mutationResult === 'error'
                  ? "We couldn't send this invite. Please try again later."
                  : 'Invite sent successfully!',
            }}
          />
        </div>
      )}
      <h2 tw="font-medium text-charcoal leading-tight mb-2">Invite members</h2>
      <p tw="font-normal text-dark-blue-gray leading-tight mb-6">
        Invite members to collaborate in this workspace.
      </p>
      <form onSubmit={formik.handleSubmit} onChange={formik.handleChange}>
        <div tw="max-w-xs mb-10">
          <InputFormik
            required
            type="email"
            name="email"
            label="Email Address"
            placeholder="email@domain.com"
            formik={formik}
          />
        </div>
        <Button type="submit" loading={loading} disabled={!formik.dirty}>
          Invite
        </Button>
      </form>
    </div>
  )
}

export default WorkspaceSettingsAddMemberPage
