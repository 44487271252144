import 'rc-switch/assets/index.css'

import React, { FC, useState, useEffect } from 'react'
import tw, { css } from 'twin.macro'
import RCSwitch from 'rc-switch'
import Text from './text'

export interface SwitchProps {
  id?: string
  label?: string
  disabled?: boolean
  initialChecked?: boolean
  onSelect?: (checked: boolean) => void
}

const Switch: FC<SwitchProps> = ({
  id,
  label,
  disabled,
  initialChecked,
  onSelect,
  ...props
}) => {
  const [checked, setChecked] = useState(initialChecked || false)
  useEffect(() => setChecked(initialChecked || false), [initialChecked])

  const onClickLabel = () => {
    setChecked(!checked)
    onSelect?.(!checked)
  }

  return (
    <div
      id={id}
      css={css`
        .rc-switch {
          ${tw`border-dark-blue-gray bg-dark-blue-gray rounded-full leading-3 m-2 h-4 w-8`}
        }

        .rc-switch:after {
          ${tw`w-3 h-3`}
        }

        .rc-switch:focus {
          ${tw`shadow-none`}
        }

        .rc-switch-checked {
          ${tw`border-metallic-seaweed bg-metallic-seaweed`}
        }

        .rc-switch-checked:after {
          left: 1rem;
        }
      `}
      {...props}
    >
      <RCSwitch
        checked={checked}
        onClick={onSelect}
        disabled={disabled}
        onChange={setChecked}
      />
      <Text
        as="label"
        preset="p1"
        tw="cursor-pointer"
        onClick={onClickLabel}
        htmlFor={id}
      >
        {label}
      </Text>
    </div>
  )
}

export default Switch
