import React, { useState, useRef } from 'react'
import tw, { css } from 'twin.macro'
import Modal from '../../ui-blocks/modal-base'
import Input from '../../ui-blocks/input'
// import Divider from '../../ui-blocks/divider'
import Button from '../../ui-blocks/button'

interface Props {
  open: boolean
  onCancel: () => any
  onConfirm: () => any
}

export default function EditNetworkInfoModal(props: Props) {
  const { open, onConfirm, onCancel } = props
  const nameRef = useRef<HTMLInputElement>(null)
  const streetRef = useRef<HTMLInputElement>(null)
  const suiteRef = useRef<HTMLInputElement>(null)
  const cityRef = useRef<HTMLInputElement>(null)
  const stateRef = useRef<HTMLInputElement>(null)
  const zipRef = useRef<HTMLInputElement>(null)
  const countryRef = useRef<HTMLInputElement>(null)

  const [disabled] = useState<boolean>(false)
  const [loading] = useState<boolean>(false)
  const [errors, setErrors] = useState<{ [field: string]: boolean }>({})
  const clearError = (field: string) => {
    setErrors({ ...errors, [field]: false })
  }

  const errorMessage = (field: string) => (errors[field] ? `*required` : ``)

  return (
    <Modal open={open}>
      <h1
        css={css`
          ${tw` text-charcoal font-thin leading-tight text-3xl mb-10`}
        `}
      >
        Network details
      </h1>
      <div
        css={css`
          ${tw`flex flex-col`} width: 31rem;
        `}
      >
        <section>
          <h1>Name</h1>

          <div
            css={css`
              ${tw`w-1/2 h-16 mt-4`}
            `}
          >
            <Input
              inputRef={nameRef}
              error={errorMessage('name')}
              onFocus={() => clearError('name')}
            />
          </div>
        </section>
        <section
          css={css`
            ${tw`mt-6`}
          `}
        >
          <h1
            css={css`
              ${tw`mb-4`}
            `}
          >
            Address
          </h1>

          <div
            css={css`
              ${tw`w-full h-24 flex`}
            `}
          >
            <div
              css={css`
                ${tw`flex-auto pr-10`}
              `}
            >
              <Input
                inputRef={streetRef}
                label="STREET NAME"
                error={errorMessage('street')}
                onFocus={() => clearError('street')}
              />
            </div>
            <div
              css={css`
                ${tw`w-24`}
              `}
            >
              <Input
                inputRef={suiteRef}
                label="SUITE, APT."
                error={errorMessage('suite')}
                onFocus={() => clearError('suite')}
              />
            </div>
          </div>

          <div
            css={css`
              ${tw`w-full h-24 flex`}
            `}
          >
            <div
              css={css`
                ${tw`w-32 pr-10`}
              `}
            >
              <Input
                inputRef={cityRef}
                label="CITY"
                error={errorMessage('city')}
                onFocus={() => clearError('city')}
              />
            </div>
            <div
              css={css`
                ${tw`flex-auto pr-10`}
              `}
            >
              <Input
                inputRef={stateRef}
                label="STATE"
                onFocus={() => clearError('state')}
              />
            </div>
            <div
              css={css`
                ${tw`w-32`}
              `}
            >
              <Input
                inputRef={zipRef}
                label="ZIP CODE"
                error={errorMessage('zip')}
                onFocus={() => clearError('zip')}
              />
            </div>
          </div>

          <div
            css={css`
              ${tw`w-1/2  mb-10`}
            `}
          >
            <Input
              inputRef={countryRef}
              label="COUNTRY"
              error={errorMessage('country')}
              onFocus={() => clearError('country')}
            />
          </div>
        </section>
      </div>
      <hr
        css={css`
          ${tw`text-platinum`}
        `}
      />
      <div
        css={css`
          ${tw`flex flex-wrap w-auto justify-end mt-6`}
        `}
      >
        <Button
          css={css`
            ${tw`mr-4`}
          `}
          secondary
          disabled={disabled}
          loading={loading}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          primary
          disabled={disabled}
          loading={loading}
          onClick={onConfirm}
        >
          Accept changes
        </Button>
      </div>
    </Modal>
  )
}
