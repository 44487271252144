import React, { Fragment, useState } from 'react'

import 'twin.macro'
import _ from 'lodash'

import {
  Row,
  RowCell,
  FullRow,
  TableHeader,
  TableColumn,
  HeaderColumn,
  generateTableComponent,
} from '../../ui-blocks/table'
import Status from '../../ui-blocks/status'
import Switch from '../../ui-blocks/switch'

import NoResults from '../no-results'
import Saturation from '../saturation'
import InlineDate from '../inline-date'
import NotDefined from '../not-defined'
import LoadingPage from '../loading-page'

import { ReactComponent as NoResultsIllustration } from '../../assets/illustrations/campaign_2-1_no_results.svg'

import {
  CampaignAdGroup,
  EnumCampaignAdGroupStatus,
  useUpdateCampaignAdGroupActivationMutation,
} from '../../graphql/components'
import { SortSettings } from '../../typings'

import { alertsManager } from '../../stores'
import { AlertVariant } from '../../stores/alerts-manager'

const COLUMNS: TableColumn[] = [
  { key: 'switch', label: '', isSortable: false, component: Fragment },
  { key: 'name', label: 'Name', isSortable: true, component: Fragment },
  {
    key: 'status',
    label: 'Status',
    isSortable: true,
    prop: 'value',
    component: Status,
  },
  {
    key: 'stats[0].reach',
    label: 'Impressions',
    isSortable: true,
    component: Fragment,
  },
  {
    key: 'stats[0].engagement',
    label: 'Scans',
    isSortable: true,
    component: Fragment,
  },
  {
    key: 'creatives_ids',
    label: 'Creatives',
    isSortable: true,
    component: Fragment,
  },
  {
    key: 'saturation',
    label: 'Saturation',
    isSortable: true,
    prop: 'saturation',
    component: Saturation,
  },
  {
    key: 'audience.area_type',
    label: 'Area Type',
    isSortable: true,
    component: Fragment,
  },
  {
    key: 'timeframe.starts_at',
    label: 'Start Date',
    isSortable: true,
    prop: 'date',
    component: InlineDate,
  },
  {
    key: 'timeframe.ends_at',
    label: 'End Date',
    isSortable: true,
    prop: 'date',
    component: InlineDate,
  },
  {
    key: 'created.at',
    label: 'Created On',
    isSortable: true,
    prop: 'date',
    component: InlineDate,
  },
]

type PartialCampaignAdGroup = Pick<
  CampaignAdGroup,
  | '_id'
  | 'campaign_id'
  | 'is_active'
  | 'name'
  | 'created'
  | 'timeframe'
  | 'status'
  | 'saturation'
>

export const CampaignAdGroupsListTable = generateTableComponent<PartialCampaignAdGroup>(
  {
    HeaderComponent({ onSort }) {
      const [selectedColumn, setSelectedColumn] = useState<string>('')

      const handleClick = (settings: SortSettings) => {
        setSelectedColumn(settings.column)
        onSort?.(settings)
      }

      return (
        <TableHeader>
          {COLUMNS.map((col, index) => (
            <HeaderColumn
              key={`header-column#${index}`}
              isSortable={col.isSortable}
              isSelected={selectedColumn === col.key}
              onClick={(isAscending) =>
                handleClick({ column: col.key, isAscending })
              }
            >
              {col.label}
            </HeaderColumn>
          ))}
        </TableHeader>
      )
    },

    EmptyComponent({ searchQuery }) {
      return (
        <FullRow>
          <NoResults
            tw="mt-8"
            model="Campaign AdGroup"
            searchQuery={searchQuery}
            Illustration={NoResultsIllustration}
          />
        </FullRow>
      )
    },

    LoadingComponent() {
      return (
        <FullRow>
          <LoadingPage />
        </FullRow>
      )
    },

    ErrorComponent() {
      return null
    },

    RowComponent({ datum: campaignAdGroup }) {
      const { _id, campaign_id, status } = campaignAdGroup

      const handleUpdateError = (activate: boolean) => {
        alertsManager.emit({
          dismissable: true,
          variant: AlertVariant.ERROR,
          id: 'update-ad-group-status-error-alert',
          message: `We couldn't ${
            activate ? 'activate' : 'deactivate'
          } this ad group. Please try again.`,
        })
      }

      const [
        updateCampaignAdGroupActivation,
      ] = useUpdateCampaignAdGroupActivationMutation()
      const onUpdateCampaignAdGroupActivation = async (activate: boolean) => {
        try {
          const res = await updateCampaignAdGroupActivation({
            variables: { ids: [_id], is_active: activate },
          })
          if (!res.data?.areUpdated) return handleUpdateError(activate)
          document.dispatchEvent(new CustomEvent('refetch-campaign-ad-groups'))
        } catch {
          handleUpdateError(activate)
        }
      }

      const isSwitchDisabled =
        status === EnumCampaignAdGroupStatus.Waiting ||
        status === EnumCampaignAdGroupStatus.Pending ||
        status === EnumCampaignAdGroupStatus.Finished ||
        status === EnumCampaignAdGroupStatus.Archived
      return (
        <Row>
          {COLUMNS.map(
            ({ key, prop, transform, component: Component }, index) => {
              const value = _.get(campaignAdGroup, key, undefined)
              return (
                <RowCell
                  key={`row-cell#${index}`}
                  linkTo={
                    key === 'name' &&
                    status !== EnumCampaignAdGroupStatus.Archived
                      ? `/campaigns/${campaign_id}/ad-group/${_id}`
                      : undefined
                  }
                >
                  {key === 'switch' ? (
                    <Switch
                      disabled={isSwitchDisabled}
                      initialChecked={
                        status === EnumCampaignAdGroupStatus.Activated
                      }
                      onSelect={onUpdateCampaignAdGroupActivation}
                    />
                  ) : (
                    <Component
                      {...(!!prop ? { [prop]: value } : {})}
                      {...(!!transform
                        ? { css: [`text-transform: ${transform};`] }
                        : {})}
                    >
                      {!prop &&
                        ((Array.isArray(value) ? value.length : value) ?? (
                          <NotDefined />
                        ))}
                    </Component>
                  )}
                </RowCell>
              )
            }
          )}
        </Row>
      )
    },
  }
)
