import React, { FC } from 'react'

import tw, { css } from 'twin.macro'
import { IoIosCheckmarkCircle, IoIosAlert } from 'react-icons/io'

import { Alert, AlertVariant as Variant } from '../stores/alerts-manager'

export { Variant as AlertVariant }

const AlertComponent: FC<{
  alert: Alert
  width?: string
}> = ({ alert, width }) => {
  const { variant = Variant.SUCCESS, message, actionText, onAction } = alert

  let Icon = IoIosCheckmarkCircle
  if (variant === Variant.ERROR || variant === Variant.WARNING) {
    Icon = IoIosAlert
  }

  let colors = tw`text-metallic-seaweed bg-lighter-green border-metallic-seaweed`
  if (variant === Variant.WARNING) {
    colors = tw`text-golden-brown bg-lighter-yellow border-golden-brown`
  } else if (variant === Variant.ERROR) {
    colors = tw`text-brick-red bg-lighter-red border-brick-red`
  }

  return (
    <div
      css={css`
        ${colors}
        width: ${width || '25rem'};
        ${tw`flex items-start border rounded py-2 px-4`}
      `}
    >
      <div tw="pt-1">
        <Icon />
      </div>
      <div tw="pl-2">
        {message}
        {!!actionText && (
          <span onClick={onAction} tw="pl-1 text-charcoal cursor-pointer">
            {actionText}
          </span>
        )}
      </div>
    </div>
  )
}

export default AlertComponent
