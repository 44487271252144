import _ from 'lodash'
import { CampaignAdGroup, Upload } from '../graphql/components'

export const trimValues = <T extends {}>(obj: T, keys: string[]) => {
  const newObj = { ...obj }
  for (const key of keys) {
    const oldValue = _.get(newObj, key, null)
    if (!!oldValue && typeof oldValue === 'string') {
      _.set(newObj, key, oldValue.trim())
    }
  }
  return newObj
}

export const getCampaignTimeframe = (adGroups: CampaignAdGroup[]) => {
  const campaignStart = adGroups.length
    ? adGroups
        .map((adGroup) => new Date(adGroup.timeframe?.starts_at))
        .reduce((acc, curr) => (acc < curr ? acc : curr))
    : undefined
  const campaignEnd = adGroups.length
    ? adGroups
        .map((adGroup) => new Date(adGroup.timeframe?.ends_at))
        .reduce((acc, curr) => (acc > curr ? acc : curr))
    : undefined
  return [campaignStart, campaignEnd]
}

export const getDurationFromUpload = (upload: Upload) => {
  const versions = upload?.versions || []
  const original = versions.find((v) => v?.name === 'original')
  const thumbnail = versions.find((v) => v?.name === 'thumbnail') || original
  return thumbnail?.duration ?? 0
}

export function cleanGraphqlTypenames<F extends any>(
  content: F,
  recursive = true
): F {
  if (!!content && typeof content === 'object' && !Array.isArray(content)) {
    const newContent = {}

    // @ts-ignore
    for (const [key, value] of Object.entries(content)) {
      if (key === '__typename') {
        continue
      }

      if (typeof value === 'object' && recursive) {
        // @ts-ignore
        newContent[key] = cleanGraphqlTypenames(value, recursive)
      } else {
        // @ts-ignore
        newContent[key] = value
      }
    }

    // @ts-ignore
    return newContent
  }

  return content
}
