import React, { FC, useMemo } from 'react'
import 'twin.macro'

import {
  SelectCardFormik,
  SelectCardFormikProps,
} from '../../ui-blocks/select-card'
import Spinner from '../../ui-blocks/spinner'
import Suspense from '../../ui-blocks/suspense'

import Address from '../../components/address'
import VenueTypes from '../../components/venue-types'

import {
  Player,
  useViewPlayerQuery,
  useViewAreaTypeQuery,
} from '../../graphql/components'

import { ReactComponent as Billboard } from '../../assets/icon/billboard.svg'
import { ReactComponent as Indoor } from '../../assets/icon/indoor-audience.svg'
import { ReactComponent as Outdoor } from '../../assets/icon/outdoor-audience.svg'

interface PlayerSelectCardProps extends SelectCardFormikProps {
  value: any
  player: Pick<Player, '_id' | 'name' | 'geographics' | 'demographics'>
}

const PlayerSelectCard: FC<PlayerSelectCardProps> = ({ player, ...props }) => {
  const { data: playerData, loading: loadingPlayer } = useViewPlayerQuery({
    skip: !player._id,
    variables: { _id: player._id },
  })

  const { data: areaTypeData, loading: loadingAreaType } = useViewAreaTypeQuery(
    {
      skip: !player.demographics?.area_type_id,
      variables: { _id: player.demographics?.area_type_id },
    }
  )

  const Icon = useMemo(
    () =>
      (areaTypeData?.areaType?.name?.toLowerCase()?.includes('indoor') &&
        Indoor) ||
      (areaTypeData?.areaType?.name?.toLowerCase()?.includes('outdoor') &&
        Outdoor) ||
      Billboard,
    [areaTypeData]
  )

  return (
    <SelectCardFormik tw="flex flex-col justify-between" {...props}>
      <Suspense
        ready={!loadingPlayer || loadingAreaType}
        fallback={<Spinner center />}
      >
        <p
          title={player?.name || ''}
          tw="font-medium text-charcoal truncate mb-1"
        >
          {player.name}
        </p>
        <Address
          address={playerData?.player?.geographics?.address as any}
          tw="text-dark-blue-gray"
        />
        <div tw="flex flex-row items-center justify-between mt-1">
          <VenueTypes
            asPills
            venueTypeIds={
              playerData?.player?.demographics?.venue_type_ids || []
            }
          />
          <Icon
            width={24}
            height={24}
            title={areaTypeData?.areaType?.name || 'Both'}
          />
        </div>
      </Suspense>
    </SelectCardFormik>
  )
}

export default PlayerSelectCard
