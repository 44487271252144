import React, { FC } from 'react'
import 'twin.macro'

import { useFormik } from 'formik'
import { string, object, array } from 'yup'

import Search from '../ui-blocks/search'
import Spinner from '../ui-blocks/spinner'
import Suspense from '../ui-blocks/suspense'
import ModalAction from '../ui-blocks/modal-action'
import PaginationNav from '../ui-blocks/pagination-nav'

import LoadingPage from './loading-page'
import NetworkSelectCard from './network/select-card'

import { ReactComponent as NoSearchResults } from '../assets/illustrations/campaign_3-3_new_3_search_no_results.svg'

import {
  useCountQuery,
  useListNetworksQuery,
  useViewCampaignQuery,
  useUpdateCampaignByIdMutation,
} from '../graphql/components'

import usePagination from '../utils/use-pagination'
import { useSearchTerms } from '../utils/use-search'

import { alertsManager } from '../stores'
import { AlertVariant } from '../stores/alerts-manager'

interface ManageNetworksFormikValues {
  networks_ids: string[]
}

const manageNetworksValidationSchema = object().shape({
  networks_ids: array().ensure().of(string()),
})

export interface ManageNetworksModalProps {
  open?: boolean
  campaignId: string
  onClose?: () => void
  refetchData?: () => void
}

const ManageNetworksModal: FC<ManageNetworksModalProps> = ({
  open,
  campaignId,
  onClose,
  refetchData,
}) => {
  const counter = useCountQuery({ fetchPolicy: 'no-cache' })
  const networksCount = counter.data?.networks || 0
  const pagination = usePagination(networksCount, 9)

  const [
    updateCampaign,
    { loading: updatingCampaign },
  ] = useUpdateCampaignByIdMutation()
  const {
    data: campaignData,
    loading: loadingCampaign,
    refetch: refetchCampaign,
  } = useViewCampaignQuery({
    skip: !campaignId,
    fetchPolicy: 'no-cache',
    variables: { _id: campaignId },
  })

  const campaign = campaignData?.campaign
  const formik = useFormik<ManageNetworksFormikValues>({
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: manageNetworksValidationSchema,
    initialValues: {
      networks_ids: (campaign?.broadcasts_to?.networks || []).map(
        (network) => network?.network?._id
      ),
    },
    async onSubmit({ networks_ids }) {
      try {
        const res = await updateCampaign({
          variables: {
            _id: campaignId,
            input: {
              broadcasts_to: {
                marketplace: campaign?.broadcasts_to?.marketplace,
                networks: networks_ids.map((network_id) => ({ network_id })),
                players: campaign?.broadcasts_to?.players?.map((player) => ({
                  player_id: player?.player?._id,
                })),
              },
            },
          },
        })
        if ((res.errors || []).length > 0) {
          handleError()
        } else {
          refetchData?.()
          refetchCampaign()
          onCloseModal(false)
        }
      } catch {
        handleError()
      }
    },
  })

  const onCloseModal = (reset = true) => {
    if (updatingCampaign) return
    reset && formik.resetForm()
    onClose?.()
  }

  const handleError = () => {
    alertsManager.emit({
      dismissable: true,
      variant: AlertVariant.ERROR,
      id: 'update-campaign-error-alert',
      message: "We couldn't update this campaign. Please try again later.",
    })
  }

  const { searchText, setSearchText, searchTextDebounced } = useSearchTerms()
  const { data: networksData, loading: loadingNetworks } = useListNetworksQuery(
    {
      variables: {
        limit: pagination.limit,
        offset: pagination.offset,
        filter: { search: searchTextDebounced },
      },
    }
  )

  const networks = networksData?.networks || []
  const renderNetworks = () => (
    <div tw="pb-4">
      <div tw="flex flex-row flex-wrap">
        {networks.map((network) => (
          <div key={network?._id} tw="w-full max-w-1/3 px-2 mb-4">
            <NetworkSelectCard
              value={network?._id}
              network={network}
              formik={formik}
              name="networks_ids"
            />
          </div>
        ))}
      </div>
      <PaginationNav
        page={pagination.page}
        count={pagination.count}
        limit={pagination.limit}
        setPage={pagination.setPage}
      />
    </div>
  )

  const renderNoNetworks = () => (
    <div tw="flex flex-col items-center pb-4">
      <NoSearchResults />
      <p tw="font-medium text-charcoal leading-tight mt-6 mb-2">
        No data to show
      </p>
      <span tw="font-normal text-charcoal leading-tight">
        {!!searchTextDebounced
          ? `We can’t find any network for "${searchTextDebounced}". Please check your spelling and try again.`
          : 'We can’t find any network.'}
      </span>
    </div>
  )

  return (
    <ModalAction
      open={open}
      loading={updatingCampaign}
      onConfirm={formik.submitForm}
      confirmDisabled={!formik.dirty}
      onCancel={() => onCloseModal()}
      onBackdropClick={() => onCloseModal()}
      confirmButtonText="Accept changes"
      width="68rem"
    >
      <Suspense ready={!loadingCampaign} fallback={<LoadingPage />}>
        <form onChange={formik.handleChange}>
          <h1 tw="font-light text-3xl text-charcoal leading-tight mb-2">
            Manage networks
          </h1>
          <p tw="font-normal text-dark-blue-gray leading-tight mb-10">
            Add or remove networks.
          </p>
          <div tw="flex flex-row items-end justify-between mb-6">
            <span tw="font-normal text-xs text-dark-blue-gray leading-tight tracking-wider uppercase">
              {`${networksCount} Network(s), ${formik.values.networks_ids.length} Selected`}
            </span>
            <div tw="w-full max-w-xs">
              <Search
                value={searchText}
                loading={loadingNetworks}
                entries={networks.map((network) => network.name)}
                onSelect={(value) =>
                  setSearchText(!Array.isArray(value) ? value || '' : '')
                }
              />
            </div>
          </div>
          <div tw="mb-6">
            <Suspense ready={!loadingNetworks} fallback={<Spinner center />}>
              <Suspense
                ready={networks.length > 0}
                fallback={renderNoNetworks()}
              >
                {renderNetworks()}
              </Suspense>
            </Suspense>
          </div>
        </form>
      </Suspense>
    </ModalAction>
  )
}

export default ManageNetworksModal
