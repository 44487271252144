import React, { FC } from 'react'
import tw, { css } from 'twin.macro'

const Divider: FC<{ text?: string }> = ({ text }) => (
  <div
    css={css`
      ${tw`w-full my-8 text-platinum flex items-center`}
    `}
  >
    <hr
      css={css`
        ${tw`w-full`}
      `}
    />
    {!!text && (
      <>
        <span
          css={css`
            ${tw`select-none px-12 bg-cultured`}
          `}
        >
          {text}
        </span>
        <hr
          css={css`
            ${tw`w-full`}
          `}
        />
      </>
    )}
  </div>
)

export default Divider
