import React, { FC, useState } from 'react'
import 'twin.macro'

import { useFormik } from 'formik'
import { string, object } from 'yup'
import { useNavigate } from 'react-router-dom'

import Button from '../../../ui-blocks/button'
import { InputFormik } from '../../../ui-blocks/input'
// import CardResourceRadio from '../../../ui-blocks/card-resource-radio'

import AlertMessage, { AlertVariant } from '../../../components/alert-component'

import {
  useSetupWorkspaceMutation,
  // EnumWorkspacePlatform_Type,
} from '../../../graphql/components'

import { IoIosArrowBack } from 'react-icons/io'

import { authentication } from '../../../stores'
import { trimValues } from '../../../utils/data-manipulation'

// const WORKSPACE_TYPES = [
//   {
//     disabled: false,
//     value: EnumWorkspacePlatform_Type.Advertiser,
//     name: 'Advertiser',
//     description: 'Advertise on all the available Players',
//   },
//   {
//     disabled: false,
//     value: EnumWorkspacePlatform_Type.Venue,
//     name: 'Venue',
//     description: 'Join Players to the Diffuse.tv network',
//   },
//   {
//     disabled: false,
//     value: EnumWorkspacePlatform_Type.ContentCreator,
//     name: 'Content Creator',
//     description: 'Upload editorial content that everyone will see',
//   },
//   {
//     disabled: false,
//     value: EnumWorkspacePlatform_Type.Enterprise,
//     name: 'Enterprise SaaS',
//     description: 'Take the most of our capabilities at your business',
//   },
// ]

interface CreateWorkspaceFormikValues {
  name: string
  // platform_type: EnumWorkspacePlatform_Type
}

const createWorkspaceValidationSchema = object().shape({
  name: string()
    .trim('Value cannot have leading or trailing white spaces')
    .required('Name is required')
    .strict(true),
  // platform_type: string()
  //   .oneOf([
  //     EnumWorkspacePlatform_Type.Advertiser,
  //     EnumWorkspacePlatform_Type.ContentCreator,
  //     EnumWorkspacePlatform_Type.Enterprise,
  //     EnumWorkspacePlatform_Type.Venue,
  //   ])
  //   .required(),
})

const AuthSetupWorkspacePage: FC = () => {
  const navigate = useNavigate()

  const [loggingOut, setLoggingOut] = useState<boolean>(false)
  const [setupError, setSetupError] = useState<boolean>(false)

  const [setupWorkspace] = useSetupWorkspaceMutation()
  const formik = useFormik<CreateWorkspaceFormikValues>({
    validateOnChange: false,
    validationSchema: createWorkspaceValidationSchema,
    initialValues: {
      name: '',
      // platform_type: EnumWorkspacePlatform_Type.Advertiser,
    },
    async onSubmit(values) {
      const newValues = trimValues(values, ['name'])
      setSetupError(false)

      try {
        const res = await setupWorkspace({ variables: newValues })
        if ((res.errors || []).length > 0) return setSetupError(true)

        await authentication.selectWorkspace(res.data?.workspace?._id)
        await authentication.loadUserData()
        navigate('/')
      } catch {
        setSetupError(true)
      }
    },
  })

  const logout = async () => {
    setLoggingOut(true)
    await authentication.logout()
    setLoggingOut(false)
    navigate('/')
  }

  const workspaces = authentication?.user_data?.workspaces ?? []
  const userHasWorkspaces = workspaces.length > 0

  return (
    <div>
      {userHasWorkspaces ? (
        <Button
          ghost
          tw="px-0 justify-start"
          iconLeft={IoIosArrowBack}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
      ) : null}

      <h1 tw="text-charcoal font-light leading-tight text-4xl self-start">
        Create your workspace
      </h1>
      <p tw="text-charcoal m-0 mb-10">
        Create a workspace that best suits your needs.
      </p>
      <form onChange={formik.handleChange} onSubmit={formik.handleSubmit}>
        {setupError && (
          <div tw="mb-4">
            <AlertMessage
              alert={{
                variant: AlertVariant.ERROR,
                id: 'create-workspace-error-alert',
                message: "We couldn't create this workspace. Please try again.",
              }}
            />
          </div>
        )}
        <label tw="block mb-2 text-charcoal font-medium text-sm tracking-wider">
          Name
        </label>
        <InputFormik
          required
          type="text"
          name="name"
          placeholder="ACME, ltd"
          label="You can use your name (best suited for solo) or the name of your company."
          formik={formik}
        />

        {/* <label tw="font-medium text-sm text-black tracking-wider block mt-8 mb-6">
          Type of Organization
        </label>
        <CardResourceRadio
          cards={WORKSPACE_TYPES}
          value={formik.values.platform_type}
          onChange={(value) =>
            formik.setValues({
              ...formik.values,
              platform_type: value as EnumWorkspacePlatform_Type,
            })
          }
        /> */}

        <Button
          primary
          tw="mt-6"
          type="submit"
          disabled={!formik.dirty}
          loading={formik.isSubmitting}
        >
          {userHasWorkspaces ? 'Create Workspace' : 'Continue'}
        </Button>
      </form>
      <p tw="text-charcoal mt-10 mb-5">
        You are logged in as <b>{authentication.user?.emails[0]?.address}</b>.
        If you don’t wish to set up a new account, you can sign in with other
        email.
      </p>
      {!userHasWorkspaces && (
        <Button
          ghost
          onClick={logout}
          loading={loggingOut}
          disabled={formik.isSubmitting}
          tw="px-0 justify-start"
        >
          Sign out
        </Button>
      )}
    </div>
  )
}

export default AuthSetupWorkspacePage
