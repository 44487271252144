import 'rc-dialog/assets/index.css'

import React, { FC } from 'react'
import Dialog from 'rc-dialog'
import tw, { css } from 'twin.macro'
import Text from './text'

const fontFamily = 'Fira Sans'
const ModalBase: FC<{
  open?: boolean
  title?: string
  width?: string
  fillContent?: boolean
  onBackdropClick?: () => void
}> = ({ open, title, onBackdropClick, fillContent, width, children }) => (
  <Dialog
    visible={open}
    closable={false}
    animation="zoom"
    maskAnimation="fade"
    onClose={onBackdropClick}
    focusTriggerAfterClose={false}
    bodyStyle={fillContent ? { padding: 0, fontFamily } : { fontFamily }}
    style={{ width, maxWidth: '100%' }}
  >
    <div
      css={css`
        ${fillContent ? `` : tw`px-8 pt-4 pb-2`}
      `}
    >
      {!!title && (
        <Text as="h1" preset="h4" tw="truncate mb-2">
          {title}
        </Text>
      )}
      {children}
    </div>
  </Dialog>
)

export default ModalBase
