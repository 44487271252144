export default function findSameValueIndex(
  array: any[],
  value: any,
  selectKey: string | undefined = undefined
) {
  const stringifiedValue = JSON.stringify(value)
  const index = array.findIndex((val: any) => {
    if (selectKey) {
      return val[selectKey] === value[selectKey]
    }
    return JSON.stringify(val) === stringifiedValue
  })

  return index
}
