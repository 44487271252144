import React from 'react'
import { useFormik } from 'formik'
import { onSubmit } from './handlers'
import { ForgotPasswordMethod } from '../../../graphql/components'

import { IFormikValues, Step } from './typings'
import validationSchema from './validation-schema'
import StepSendCode from './step-send-code'
import StepVerifyCode from './step-verify-code'
import StepResetPassword from './step-reset-password'
import StepPasswordResetSuccessfully from './step-password-reset-successfully'

export default function AuthForgotPasswordPage() {
  const formik = useFormik<IFormikValues>({
    initialValues: {
      step: Step.SendCode,
      method: ForgotPasswordMethod.Email,
    },
    validationSchema,
    onSubmit,
    validateOnMount: false,
    validateOnChange: true,
  })

  return (
    <form onSubmit={formik.handleSubmit} onChangeCapture={formik.handleChange}>
      {formik.values.step === Step.SendCode && <StepSendCode {...{ formik }} />}
      {formik.values.step === Step.VerifyCode && (
        <StepVerifyCode {...{ formik }} />
      )}
      {formik.values.step === Step.ResetPassword && (
        <StepResetPassword {...{ formik }} />
      )}
      {formik.values.step === Step.PasswordResetSuccessfully && (
        <StepPasswordResetSuccessfully {...{ formik }} />
      )}
    </form>
  )
}
