import React, { FC } from 'react'

import tw from 'twin.macro'
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai'

import Button from '../../../../ui-blocks/button'

export interface WorkspaceInviteProps {
  logo?: string
  name?: string
  members?: number
  originEmail?: string
  loadingAccept?: boolean
  loadingDecline?: boolean
  onAccept?: () => void
  onDecline?: () => void
}

const WorkspaceInvite: FC<WorkspaceInviteProps> = ({
  logo,
  name,
  members,
  originEmail,
  loadingAccept,
  loadingDecline,
  onAccept,
  onDecline,
}) => (
  <div
    css={[
      'width: 22.5rem;',
      tw`px-4 pt-4 pb-2 bg-white border rounded border-platinum`,
    ]}
  >
    <div tw="flex items-center">
      {!!logo && (
        <img tw="w-16 h-16 border-0 rounded mr-4" src={logo} alt="logo" />
      )}
      <div>
        <div tw="text-charcoal font-medium">{name}</div>
        <div tw="whitespace-pre-line text-charcoal text-xs">
          {`${members || 0} Member(s)`}
        </div>
        {originEmail && (
          <div tw="whitespace-pre-line text-charcoal text-xs">
            {`Sent by ${originEmail}`}
          </div>
        )}
      </div>
    </div>
    <div tw="mt-6 mb-2 flex">
      {!!onAccept && (
        <Button
          type="button"
          loading={loadingAccept}
          iconLeft={AiOutlineCheck}
          disabled={loadingAccept || loadingDecline}
          onClick={onAccept}
        >
          Accept
        </Button>
      )}
      {!!onDecline && (
        <span tw="ml-4">
          <Button
            secondary
            type="button"
            loading={loadingDecline}
            iconLeft={AiOutlineClose}
            disabled={loadingAccept || loadingDecline}
            onClick={onDecline}
          >
            Decline
          </Button>
        </span>
      )}
    </div>
  </div>
)

export default WorkspaceInvite
