import React, { FC } from 'react'

import { useParams } from 'react-router-dom'

import ModalAction from '../../../../../ui-blocks/modal-action'

import {
  ViewWorkspaceDocument,
  useRemoveWorkspaceMemberMutation,
} from '../../../../../graphql/components'

import { MutationResult } from '../../../../../typings'

export interface RemoveMemberModalProps {
  open: boolean
  memberEmail: string
  onClose: (result: MutationResult) => void
}

const RemoveMemberModal: FC<RemoveMemberModalProps> = ({
  open,
  onClose,
  memberEmail,
}) => {
  const params = useParams()
  const workspaceId = params?.workspace_id || ''
  const [removeWorkspaceMember, { loading }] = useRemoveWorkspaceMemberMutation(
    {
      awaitRefetchQueries: true,
      variables: { email: memberEmail },
      refetchQueries: [
        { query: ViewWorkspaceDocument, variables: { _id: workspaceId } },
      ],
    }
  )

  const removeMember = async () => {
    const res = await removeWorkspaceMember()
    onClose(res.data?.removedMember ? 'success' : 'error')
  }

  return (
    <ModalAction
      open={open}
      loading={loading}
      title="Remove member?"
      cancelButtonText="Keep member"
      confirmButtonText="Remove member"
      onCancel={() => onClose(null)}
      onConfirm={removeMember}
    >
      <p>
        Are you sure you want to remove member <b>{memberEmail}</b>? This person
        will lose access to the workspace.
      </p>
    </ModalAction>
  )
}

export default RemoveMemberModal
